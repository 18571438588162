import React, { memo } from "react";
import { Box } from "@mui/material";
import { Status as StatusProp } from "./types";
import { Status } from "./Status";

type StatusBarProps = {
  statuses: StatusProp[];
  current: string;
};

export const StatusBar = memo(({ statuses, current }: StatusBarProps) => {
  const content = statuses.map((status) => {
    return (
      <Status
        key={status.status}
        status={status}
        statuses={statuses}
        current={current}
      />
    );
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      flexGrow="1"
      sx={{ width: "100%", padding: "40px 30px 50px 30px" }}
    >
      {content}
    </Box>
  );
});
