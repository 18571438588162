import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "../../../components/wideDrawer/Form";
import { useAddInquiryMutation } from "../../../slices/inquiries/api";
import { InquiryForm, inquiryFormSchemaCreator } from "./InquiryForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddInquiryForm } from "./types";
import { useYup } from "../../../utils/validation";

export const Add: React.FC = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(inquiryFormSchemaCreator);
  const formMethods = useForm<AddInquiryForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addInquiry, { isLoading }] = useAddInquiryMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("inquiries.errors.add")
  );

  const onSubmit = useCallback(
    ({ dueDate, ...form }: AddInquiryForm) => {
      addInquiry({
        ...form,
        dueDate: dueDate && dueDate.setHours(12, 0, 0, 0).valueOf(),
      })
        .unwrap()
        .then(({ id }) => {
          const inquiryUrl = `/inquiries/${id}/details`;

          feedSuccess(t("inquiries.successes.add"), inquiryUrl);
          history.push(`${inquiryUrl}/details`);
          reset();
        })
        .catch(catchFormError);
    },
    [addInquiry, catchFormError, feedSuccess, history, reset, t]
  );

  return (
    <Form
      heading={t("inquiries.add")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <InquiryForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
