import React from "react";
import {
  SubmitSpinnerButton,
  SubmitSpinnerButtonProps,
} from "../SubmitSpinnerButton";
import { Alert, Box, Collapse, Paper, Stack, Typography } from "@mui/material";
import { FormErrorState } from "../../utils/formHelpers";
import { useTranslation } from "react-i18next";

type FormProps = SubmitSpinnerButtonProps & {
  heading: string;
  onSubmit: () => void;
  formError: FormErrorState;
};

/**
 * Replace with NewDesignForm. This is component for old design.
 * @deprecated
 */

export const Form: React.FC<FormProps> = ({
  children,
  loading,
  onSubmit,
  heading,
  formError,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 2 }}>
      <Paper sx={{ padding: 2 }}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <Typography variant="h5" component="h3">
              {heading}
            </Typography>
            {formError && (
              <Collapse in={!!formError}>
                <Alert severity="error">{formError}</Alert>
              </Collapse>
            )}
            {children}
            <SubmitSpinnerButton loading={loading}>
              {t("actions.submit", { ns: "common" })}
            </SubmitSpinnerButton>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};
