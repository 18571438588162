import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { useDeleteMainOrderMutation } from "../../../slices/mainOrders/api";
import { GridRowId } from "@mui/x-data-grid";

type ActionsProps = {
  id: GridRowId;
};

export const Actions: React.FC<ActionsProps> = ({ id }) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const can = usePermissions();
  const [deleteMainOrder] = useDeleteMainOrderMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onDelete = useCallback(() => {
    deleteMainOrder(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("mainOrders.successes.delete"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("mainOrders.error.delete"));
      });
  }, [deleteMainOrder, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationTitle={t("mainOrders.confirmDeleteTitle")}
      confirmationDescription={t("mainOrders.confirmDeleteDescription")}
      onDeleteClick={onDelete}
      canDelete={can("deleteMainOrder")}
    />
  );
};
