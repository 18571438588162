import React, { useCallback, useEffect, useState } from "react";
import { CartItem } from "../../../../slices/shop/types";
import { Item } from "./Item/Item";
import { useMessageHandlers } from "../../../../utils/useMessageHandlers";
import { useSelector } from "../../../../store";
import { selectShopList } from "../../../../slices/shop/selectors";
import { useCurrentAssignmentId } from "../../../../slices/shop/hooks";
import { NumericFieldProps } from "../../../../components/fields/NumericField";
import {
  useAddGeneralToCartMutation,
  useRemoveGeneralFromCartMutation,
} from "../../../../slices/shop/api";

type RequestedItemProps = {
  item: CartItem;
};

export const RequestedItem = ({
  item: {
    product: { id, images, name, retailCurrency },
    quantity,
    totalAmount,
    requestedItemSchoolStatus,
    assignmentSchoolStatus,
  },
}: RequestedItemProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const { inquiryId, departmentId } = useSelector(selectShopList);
  const assignmentId = useCurrentAssignmentId();

  const [localQuantity, setLocalQuantity] =
    useState<NumericFieldProps["value"]>(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const [addToCart, { isLoading: isAdding }] = useAddGeneralToCartMutation();
  const [removeFromCart, { isLoading: isRemoving }] =
    useRemoveGeneralFromCartMutation();

  const changeQuantityHandler = useCallback(
    (currentQuantity: number, newQuantity: number) => {
      if (name === null) {
        return;
      }

      if (currentQuantity > newQuantity) {
        removeFromCart({
          inquiryId,
          departmentInquiryId: departmentId,
          assignmentId,
          name,
          quantity: currentQuantity - newQuantity,
        })
          .unwrap()
          .then(handleSuccess)
          .catch(handleError);
      }

      if (currentQuantity < newQuantity) {
        addToCart({
          inquiryId,
          departmentInquiryId: departmentId,
          assignmentId,
          name,
          quantity: newQuantity - currentQuantity,
        })
          .unwrap()
          .then(handleSuccess)
          .catch(handleError);
      }
    },
    [
      addToCart,
      assignmentId,
      departmentId,
      handleError,
      handleSuccess,
      inquiryId,
      name,
      removeFromCart,
    ]
  );

  const [
    removeFromCartCompletely,
    { isLoading: isRemovingFromCartCompletely },
  ] = useRemoveGeneralFromCartMutation();

  const removeHandler = useCallback(() => {
    if (name === null) {
      return;
    }

    removeFromCartCompletely({
      inquiryId: inquiryId,
      departmentInquiryId: departmentId,
      assignmentId,
      name,
      quantity,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentId,
    departmentId,
    handleError,
    handleSuccess,
    inquiryId,
    name,
    quantity,
    removeFromCartCompletely,
  ]);

  return (
    <Item
      {...{
        id,
        images,
        name,
        totalAmount,
        retailCurrency,
        quantity,
        requestedItemSchoolStatus,
        assignmentSchoolStatus,
      }}
      quantityInputValue={localQuantity}
      onQuantityInputValueChange={setLocalQuantity}
      onQuantityChange={changeQuantityHandler}
      isChangingQuantity={isAdding || isRemoving}
      onRemoveItem={removeHandler}
      isRemoving={isRemovingFromCartCompletely}
    />
  );
};
