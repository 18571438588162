import React from "react";
import { Helmet } from "react-helmet";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { DateTable } from "./DataTable/DateTable";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { Add } from "./Drawers/Add";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { usePermissions } from "../../utils/usePermissions";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";

export const Inquiries: React.FC = () => {
  const { t } = useTranslation("pages");

  const { addDrawerOpen, closeDrawer } = useTableUrlTools("/inquiries");
  const can = usePermissions();

  const title = t("inquiries.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getInquiries") && <DateTable />}
      {can("addInquiry") && (
        <WideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add />
        </WideDrawer>
      )}
    </>
  );
};
