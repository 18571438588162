import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ControlledCustomField } from "../../../../../components/controlledFields/ControlledCustomField";
import {
  AddProductRequestDto,
  allSchoolLevels,
  EditProductRequestDto,
  SchoolLevel,
} from "../../../../../slices/products/types";
import { FormProps } from "../Form";

export const SchoolLevelCheckboxGroup = ({
  fieldsErrors,
  defaultValues,
}: Pick<FormProps, "fieldsErrors"> & { defaultValues: SchoolLevel[] }) => {
  const { control } = useFormContext<
    AddProductRequestDto | EditProductRequestDto
  >();
  const { t } = useTranslation("pages");

  const [currentState, setCurrentState] = useState<{
    [key in SchoolLevel]: boolean;
  }>(
    Object.fromEntries(
      allSchoolLevels.map((k: SchoolLevel) => [k, !!defaultValues.includes(k)])
    ) as { [key in SchoolLevel]: boolean }
  );

  const handleChange =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (level: string, onChange: (val: any) => void) => (e: any) => {
      const checked = !!e.target.checked;
      const newState = { ...currentState, [level]: checked };
      setCurrentState(newState);
      onChange(allSchoolLevels.filter((value) => newState[value]));
    };

  return (
    <Grid item gridColumn="span 12">
      <Typography>{t("products.fields.schoolLevel")}</Typography>
      <ControlledCustomField
        control={control}
        name="schoolLevel"
        fieldsErrors={fieldsErrors}
        render={(errorMessage, { field: { onChange } }) => {
          return (
            <FormGroup row>
              {allSchoolLevels.map((level) => (
                <FormControlLabel
                  key={`school-level-${level}`}
                  control={<Checkbox name="level" />}
                  label={t(`schoolLevel.${level}`, { ns: "common" })}
                  onClick={handleChange(level, onChange)}
                  checked={!!currentState[level]}
                />
              ))}
            </FormGroup>
          );
        }}
      />
    </Grid>
  );
};
