import React from "react";
import { SubmitSpinnerButtonProps } from "../SubmitSpinnerButton";
import {
  Alert,
  Box,
  Collapse,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { FormErrorState } from "../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import { NewDesignSubmitSpinnerButton } from "../NewDesignSubmitSpinnerButton";

type NewDesignFormProps = SubmitSpinnerButtonProps & {
  heading: string;
  onSubmit: () => void;
  formError: FormErrorState;
  submitText?: string;
  disabledSubmit?: boolean;
};

export const NewDesignForm: React.FC<NewDesignFormProps> = ({
  children,
  loading,
  onSubmit,
  heading,
  formError,
  submitText,
  disabledSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 2 }}>
      <Paper sx={{ padding: 2 }}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <StyledHeader variant="h5">{heading}</StyledHeader>
            {formError && (
              <Collapse in={!!formError}>
                <Alert severity="error">{formError}</Alert>
              </Collapse>
            )}
            {children}
            <NewDesignSubmitSpinnerButton
              loading={loading}
              fullWidth
              disabled={disabledSubmit}
            >
              {submitText || t("actions.submit", { ns: "common" })}
            </NewDesignSubmitSpinnerButton>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: 2,
  color: theme.palette.primary.dark,
  fontSize: "24px",
}));
