import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useIsSchoolTeam } from "../../utils/useIsSchoolTeam";

type InvitationDialogProps = {
  open: boolean;
  handleProceed: () => void;
  firstName?: string;
};

// TODO remove if not used

export const InvitationDialog = ({
  firstName,
  open,
  handleProceed,
}: InvitationDialogProps) => {
  const { t } = useTranslation();
  const isSchoolTeam = useIsSchoolTeam();

  return (
    <Dialog open={isSchoolTeam ? false : open}>
      <DialogTitle>
        {firstName
          ? t("invitation.titleWN", { name: firstName })
          : t("invitation.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentTextStyled>
          {t("invitation.message1")}
          <br />
          {t("invitation.message2")}
        </DialogContentTextStyled>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleProceed}
          loading={false}
          variant="outlined"
        >
          {t("invitation.proceed")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const DialogContentTextStyled = styled(DialogContentText)({
  color: "#263238",
});
