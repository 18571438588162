import React, { useCallback } from "react";
import { Details } from "./Details";
import { useHistory, useRouteMatch } from "react-router-dom";
import { parseNumericParam } from "../../../utils/parseNumericParam";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { UserDetails } from "./UserDetails";

type MatchesParams = {
  id: string;
};

export const Drawers = () => {
  const editDrawer = useRouteMatch<MatchesParams>(`/searches/:id`);
  const userDetails = useRouteMatch<MatchesParams>(`/searches/:id/user`);

  const history = useHistory();

  const closeDrawerHandler = useCallback(() => {
    history.push("/searches");
  }, [history]);

  return (
    <>
      <WideDrawer open={!!editDrawer} close={closeDrawerHandler}>
        <Details
          id={parseNumericParam(editDrawer?.params.id)}
          onClose={closeDrawerHandler}
        />
      </WideDrawer>
      <WideDrawer open={!!userDetails} close={closeDrawerHandler}>
        <UserDetails
          id={parseNumericParam(editDrawer?.params.id)}
          onClose={closeDrawerHandler}
        />
      </WideDrawer>
    </>
  );
};
