import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddCommentRequestDto,
  Comment,
  GetCommentsRequestDto,
  GetCommentsResponseDto,
} from "./types";

export const commentsApi = createApi({
  reducerPath: "commentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/comments"),
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getComments: builder.query<GetCommentsResponseDto, GetCommentsRequestDto>({
      query: (req) => {
        const countFrom = req.countFrom || 0;
        return `?commentableType=${req.commentableType}&commentableId=${req.commentableId}&countFrom=${countFrom}`;
      },
    }),
    addComment: builder.mutation<Comment, AddCommentRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
    }),
    deleteComment: builder.mutation<{ messsage: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
} = commentsApi;
