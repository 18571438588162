import React, { useCallback, useMemo, useState } from "react";
import {
  Chip,
  IconButton,
  Link as MaterialLink,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useGetInquiryDetailsQuery } from "../../../slices/inquiries/api";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { propToString } from "../../../utils/propToString";
import { DepartmentInquiryFlattened } from "../../../slices/inquiries/types";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
// Hidden in PC-409
// import { InquiryData } from "../InquiryData";
import { StatisticsBar } from "../StatisticsBar";
import {
  downloadFileFromServer,
  saveBlob,
} from "../../../utils/downloadFileFromServer";
import { INQUIRY_STATUS_COLORS } from "../../../consts";
import { Actions } from "./Actions";
import { GroupsSwitch } from "../GroupsSwitch";
import { useGenerateCOMutation } from "../../../slices/commercialOffers/api";
import { useSelector } from "../../../store";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { useGenerateDNMutation } from "../../../slices/deliveryNotes/api";
import { FileDownloadOutlined } from "@mui/icons-material";
import { CircleButton } from "../../../components/toolbar/CircleButton";
import { getDownloadUrl } from "../../../utils/S3UploadFile";
import { useInquiryDetailsUrlTools } from "../hooks";
import { useLocalStorage } from "usehooks-ts";
import { FilteredCustomDataGrid } from "./FilteredCustomDataGrid";
import { useHeaderTitle } from "../../../slices/ui/useHeaderTitle";

export const Details = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const { inquiryId, baseUrl } = useInquiryDetailsUrlTools();
  const can = usePermissions();
  const userSelfData = useSelector((state) => state.auth.user);
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const [statusesFilter, setStatusesFilter] = useLocalStorage<string[]>(
    `filter.depInquiryByStatus-${inquiryId}`,
    []
  );

  const { isLoading, isFetching, data, error, refetch } =
    useGetInquiryDetailsQuery(inquiryId);

  useHeaderTitle(data?.name || t("inquiryDetails.title"));

  const [newItemsCount, submittedItemsCount, warehouseItemsCount] =
    useMemo(() => {
      const allDeps = data?.relatedDepartmentsInquiries;
      const selectedDeps =
        selectedIds.length > 0
          ? allDeps?.filter(({ id }) => selectedIds.includes(id))
          : allDeps;

      const newCount = selectedDeps
        ?.map(({ newItemsCount }) => newItemsCount)
        .reduce((prev, curr) => prev + curr, 0);
      const submittedCount = selectedDeps
        ?.map(({ submittedItemsCount }) => submittedItemsCount)
        .reduce((prev, curr) => prev + curr, 0);
      const warehouseCount = selectedDeps
        ?.map(({ warehouseItemsCount }) => warehouseItemsCount)
        .reduce((prev, curr) => prev + curr, 0);
      return [newCount || 0, submittedCount || 0, warehouseCount || 0];
    }, [data?.relatedDepartmentsInquiries, selectedIds]);

  const [generateCO] = useGenerateCOMutation();
  const [generateDN] = useGenerateDNMutation();

  const onExportClick = useCallback(
    () => downloadFileFromServer(inquiryId),
    [inquiryId]
  );

  const isCOFull = useCallback(
    () =>
      ((!!data?.shippingCost && !!data?.shippingCostCurrency) ||
        !!data?.shippingCostMessage) &&
      !!data?.paymentTerms &&
      !!data?.deliveryTerms &&
      !!data?.leadTime &&
      !!data?.validityOffer,
    [data]
  );

  const downloadFile = (intId: number, type: "origco" | "origdn") => {
    let filename: string;
    getDownloadUrl(intId, type)
      .then((response) => response.json())
      .then((resData) => {
        filename = resData.filename;
        return fetch(resData.url, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        });
      })
      .then((fileResponse) => fileResponse.blob())
      .then((blob) => saveBlob(blob, filename));
  };

  const onGenerateCOClick = useCallback(async () => {
    const departmentInquiriesIds =
      selectedIds.length > 0
        ? selectedIds
        : data?.relatedDepartmentsInquiries.map(({ id }) => id) || [];

    generateCO({
      inquiryId,
      departmentInquiriesIds,
      userId: userSelfData ? userSelfData.id : 0,
    })
      .unwrap()
      .then((res) => {
        if (res.originalFile || res.id) {
          downloadFile(res.id, "origco");
        }
        feedSuccess(t("commercialOffers.successes.add"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("commercialOffers.errors.add"));
      });
  }, [
    selectedIds,
    data?.relatedDepartmentsInquiries,
    generateCO,
    inquiryId,
    userSelfData,
    feedSuccess,
    t,
    feedError,
  ]);

  const onGenerateDNClick = useCallback(async () => {
    const departmentInquiriesIds =
      selectedIds.length > 0
        ? selectedIds
        : data?.relatedDepartmentsInquiries.map(({ id }) => id) || [];

    generateDN({
      inquiryId,
      departmentInquiriesIds,
      userId: userSelfData ? userSelfData.id : 0,
    })
      .unwrap()
      .then((res) => {
        if (res.originalFile || res.id) {
          downloadFile(res.id, "origdn");
        }
        feedSuccess(t("deliveryNotes.successes.add"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("deliveryNotes.errors.add"));
      });
  }, [
    selectedIds,
    data?.relatedDepartmentsInquiries,
    generateDN,
    inquiryId,
    userSelfData,
    feedSuccess,
    t,
    feedError,
  ]);

  const buttons = useMemo(() => {
    const exportButton = (
      <Tooltip title={t("inquiryDetails.export") as string}>
        <IconButton disabled={!can("exportInquiries")} onClick={onExportClick}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
    );

    const generateCommercialOffer = (
      <CircleButton
        tooltip={t("inquiryDetails.generateCO.tooltip")}
        confirmation={{
          title: t("inquiryDetails.generateCO.confirmationTitle"),
          description: isCOFull()
            ? t("inquiryDetails.generateCO.confirmationDescription", {
                count: submittedItemsCount,
              }) +
              (newItemsCount !== 0
                ? t("inquiryDetails.generateCO.additionalDescription", {
                    count: newItemsCount,
                  })
                : "")
            : t("inquiryDetails.generateCO.notFullCoDetails"),
          proceedButtonText: isCOFull()
            ? ""
            : t("inquiryDetails.generateCO.goToDetails"),
        }}
        onClick={
          isCOFull()
            ? onGenerateCOClick
            : () => {
                history.push(`${baseUrl}/details`);
              }
        }
        disabled={!can("addCommercialOffer")}
      >
        {t("inquiryDetails.generateCO.button")}
      </CircleButton>
    );

    const generateDeliveryNote = (
      <CircleButton
        tooltip={t("inquiryDetails.generateDN.tooltip")}
        confirmation={{
          title: t("inquiryDetails.generateDN.confirmationTitle"),
          description: t("inquiryDetails.generateDN.confirmationDescription", {
            count: warehouseItemsCount,
          }),
        }}
        onClick={onGenerateDNClick}
        disabled={!can("addDeliveryNote")}
      >
        {t("inquiryDetails.generateDN.button")}
      </CircleButton>
    );

    if (selectedIds.length > 0) {
      return (
        <Stack direction="row" gap={"5px"}>
          {generateCommercialOffer}
          {generateDeliveryNote}
        </Stack>
      );
    }

    return (
      <Stack direction="row" gap={"5px"}>
        {exportButton}
        {generateCommercialOffer}
        {generateDeliveryNote}
      </Stack>
    );
  }, [
    baseUrl,
    can,
    history,
    isCOFull,
    newItemsCount,
    onExportClick,
    onGenerateCOClick,
    onGenerateDNClick,
    selectedIds.length,
    submittedItemsCount,
    t,
    warehouseItemsCount,
  ]);

  const columns = useMemo<GridColDef<DepartmentInquiryFlattened>[]>(
    () => [
      {
        field: "name",
        headerName: t("inquiryDetails.fields.name"),
        flex: 1,
        renderCell: ({ row }) => {
          let tableName = null;

          if (can("getItemsForOrdering")) {
            tableName = "ordering";
          }

          if (can("getItemsForMatching")) {
            tableName = "matching";
          }

          if (!can("getItems") || !tableName) {
            return <>{row.name}</>;
          }
          return (
            <MaterialLink
              component={Link}
              to={`${baseUrl}/${row.id}/items/${tableName}`}
            >
              {row.name}
            </MaterialLink>
          );
        },
      },
      {
        field: propToString<DepartmentInquiryFlattened>().status,
        headerName: t("inquiryDetails.fields.status"),
        flex: 1,
        renderCell: ({ row }) => (
          <Chip
            label={<b>{t(`inquiries.statuses.${row.status}`)}</b>}
            sx={{
              backgroundColor: INQUIRY_STATUS_COLORS[row.status],
              color: "white",
            }}
          />
        ),
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: ({ row }) => (
          <Actions
            inquiryId={inquiryId}
            departmentId={row.id}
            status={row.status}
          />
        ),
      },
    ],
    [baseUrl, can, inquiryId, t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  const {
    relatedDepartmentsInquiries: departmentInquiriesData,
    statistics,
    ...inquiryData
  } = data;

  return (
    <PaperPage
      breadcrumbs={{
        paths: [{ href: "/inquiries", text: t("inquiries.title") }],
        current: inquiryData.name,
        endAdornment: <GroupsSwitch />,
      }}
    >
      {/* Hidden in PC-409 */}
      {/* <InquiryData data={inquiryData} /> */}
      <StatisticsBar
        statistics={statistics}
        setStatusesFilter={setStatusesFilter}
      />
      <FilteredCustomDataGrid
        tableName={`department-inquiries-${inquiryId}`}
        columns={columns}
        hideAddButtonPlusSymbol
        rows={departmentInquiriesData}
        disableSelectionOnClick
        buttons={buttons}
        searchFields={["id", "name"]}
        onSelectionModelChange={(ids) => setSelectedIds(ids as number[])}
        checkboxSelection
        statusesFilter={statusesFilter}
        setStatusesFilter={setStatusesFilter}
      />
    </PaperPage>
  );
};
