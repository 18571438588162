import React, { memo } from "react";
import { ContextMenu } from "../../../components/ContextMenu";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { useLoseDepartmentInquiryMutation } from "../../../slices/inquiries/api";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { InquiryStatus } from "../../../types";

type ActionsProps = {
  departmentId: number;
  inquiryId: number;
  status: InquiryStatus;
};

export const Actions = memo(
  ({ departmentId, inquiryId, status }: ActionsProps) => {
    const { t } = useTranslation("pages");
    const can = usePermissions();
    const feedSuccess = useFeedSuccess();
    const feedError = useFeedError();
    const [loseDepartmentInquiry] = useLoseDepartmentInquiryMutation();

    return (
      <ContextMenu
        buttons={[
          {
            label: t("inquiryDetails.actions.lose.label"),
            handler: () => {
              loseDepartmentInquiry({ departmentId, inquiryId })
                .unwrap()
                .then(() => {
                  feedSuccess(t("inquiryDetails.successes.lost"));
                })
                .catch((error) => {
                  feedError(
                    error?.data?.message ?? t("inquiryDetails.errors.lose")
                  );
                });
            },
            disabled:
              !can("loseDepartmentInquiry") ||
              (status !== "new" &&
                status !== "underProcess" &&
                status !== "submitted"),
            confirmation: {
              title: t("inquiryDetails.actions.lose.confirmationTitle"),
              description: t(
                "inquiryDetails.actions.lose.confirmationDescription"
              ),
            },
          },
        ]}
      />
    );
  }
);
