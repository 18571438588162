import { useEffect } from "react";
import { useDispatch } from "../../store";
import { setTitle } from "./slice";

export const useHeaderTitle = (title: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(title));

    return () => {
      dispatch(setTitle(null));
    };
  }, [dispatch, title]);
};
