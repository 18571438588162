import React, { Fragment } from "react";
import { Box, Divider } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { useGetCartQuery } from "../../../../slices/shop/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCurrentAssignmentId } from "../../../../slices/shop/hooks";
import { RequestedItem } from "./RequestedItem";
import { RegularItem } from "./RegularItem";

export const Items = () => {
  const assignmentId = useCurrentAssignmentId();
  const { data: items } = useGetCartQuery(assignmentId ?? skipToken);

  if (!items) {
    return null;
  }

  // Fragment key must be changed at some point, but as of right now "product"
  // may have no id, which requires a backend fix.
  return (
    <Box sx={{ flexGrow: 1, flexShrink: 1, overflowY: "auto" }}>
      {items.map((item, index) => (
        <Fragment key={`${index}-${item.product.id}-${item.product.name}`}>
          {!item.product.id && !item.product.supplierProductCode ? (
            <RequestedItem item={item} />
          ) : (
            <RegularItem item={item} />
          )}
          {items.length !== ++index && (
            <Divider
              sx={{
                marginRight: "16px",
                marginLeft: "16px",
                borderColor: colors.dustBlue5,
              }}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
};
