import React from "react";
import { Add } from "./Add";
import { Edit } from "./Edit";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { Details } from "./Details/Details";

export const Drawers = () => {
  const { addDrawerOpen, editDrawerOpen, detailsDrawerOpen, closeDrawer } =
    useTableUrlTools("/inventory");

  return (
    <>
      <WideDrawer open={addDrawerOpen} close={closeDrawer}>
        <Add />
      </WideDrawer>
      <WideDrawer open={editDrawerOpen} close={closeDrawer}>
        <Edit />
      </WideDrawer>
      <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
        <Details />
      </WideDrawer>
    </>
  );
};
