import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import {
  useChangeCountryActivityMutation,
  useDeleteCountryMutation,
} from "../../../slices/countries/api";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { usePermissions } from "../../../utils/usePermissions";

type ActionsProps = {
  id: GridRowId;
  active: boolean;
};

export const Actions: React.FC<ActionsProps> = ({ id, active }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeCountryActivity] = useChangeCountryActivityMutation();
  const can = usePermissions();
  const [deleteCountry] = useDeleteCountryMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onEdit = useCallback(() => {
    history.push(`/countries/${intId}/edit`);
  }, [history, intId]);
  const onChangeCountryActivity = useCallback(() => {
    changeCountryActivity({ id: intId, active: !active })
      .unwrap()
      .then(() => {
        feedSuccess(t("countries.changeActivitySuccess"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("countries.errors.changeActivity"));
      });
  }, [changeCountryActivity, feedError, feedSuccess, intId, t, active]);
  const onDelete = useCallback(() => {
    deleteCountry(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("countries.deleteSuccess"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("countries.errors.delete"));
      });
  }, [deleteCountry, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationChangeActivityTitle={t(
        "countries.confirmChangeActivityTitle"
      )}
      confirmationChangeActivityDescription={t(
        "countries.confirmChangeActivityDescription"
      )}
      confirmationTitle={t("countries.confirmDeleteTitle")}
      confirmationDescription={t("countries.confirmDeleteDescription")}
      onEditClick={onEdit}
      onChangeActivityClick={onChangeCountryActivity}
      changeActivityButtonText={
        active ? t("activities.deactivate") : t("activities.activate")
      }
      onDeleteClick={onDelete}
      canEdit={can("editCountry")}
      canDelete={can("editCountry")}
      canChangeActivity={can("changeCountryActive")}
    />
  );
};
