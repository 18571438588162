import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

type ConfirmationDialogProps = {
  title: string;
  description: string;
  open: boolean;
  handleCancel: () => void;
  handleProceed?: () => void;
  proceedButtonText?: string;
  loading?: boolean;
};

export const ConfirmationDialog = ({
  title,
  description,
  open,
  handleCancel,
  handleProceed,
  proceedButtonText,
  loading,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} sx={{ zIndex: "15000" }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {handleProceed ? (
          <>
            <Button variant="outlined" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <LoadingButton
              onClick={handleProceed}
              loading={loading}
              variant="outlined"
            >
              {proceedButtonText || t("proceed")}
            </LoadingButton>
          </>
        ) : (
          <LoadingButton variant="outlined" onClick={handleCancel}>
            {proceedButtonText || t("ok")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
