import React, { useCallback } from "react";
import { Chip, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useChangeItemOrderStatusMutation } from "../../../../slices/items/api";
import {
  ItemOrderStatus,
  itemOrderStatuses,
} from "../../../../slices/items/types";
import { useFeedError, useFeedSuccess } from "../../../../utils/feedHooks";
import { capitalize } from "../../../../utils/capitalize";
import { ITEM_ORDER_STATUS_COLORS } from "../../../../consts";
import {
  canSetStatusManually,
  orderStatusesPaths,
} from "../../../../slices/items/utils";

type SelectItemOrderStatusCellProps = {
  gridCellProps: GridRenderCellParams;
  inquiryId: number;
};

export const SelectItemOrderStatusCell = ({
  gridCellProps,
  inquiryId,
}: SelectItemOrderStatusCellProps) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeOrderStatus] = useChangeItemOrderStatusMutation();

  const onStatusChanged = useCallback(
    (event: SelectChangeEvent<ItemOrderStatus>) => {
      const status = event.target.value as ItemOrderStatus;

      changeOrderStatus({
        id: gridCellProps.row.id as number,
        status,
        inquiryId,
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("items.successes.changeItemOrderStatus"));
        })
        .catch((error: { data?: { message?: string } }) => {
          feedError(
            error?.data?.message ?? t("items.errors.changeItemOrderStatus")
          );
        });
    },
    [
      changeOrderStatus,
      feedError,
      feedSuccess,
      gridCellProps.row.id,
      inquiryId,
      t,
    ]
  );

  return (
    <Select
      id={`select-cell-item-inquiry-status-${gridCellProps.row.id}`}
      value={gridCellProps.value}
      onChange={onStatusChanged}
      size="small"
      variant="standard"
      autoWidth={false}
      fullWidth={true}
    >
      {itemOrderStatuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          disabled={
            !canSetStatusManually(
              orderStatusesPaths,
              gridCellProps.value,
              status
            )
          }
        >
          <Chip
            label={<b>{capitalize(status)}</b>}
            size="small"
            variant={"filled"}
            sx={{
              backgroundColor: ITEM_ORDER_STATUS_COLORS[status],
              color: "white",
              "&:hover": { cursor: "pointer" },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
