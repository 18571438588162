import { alpha, Box, ButtonBase, Typography, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { ShoppingCartLight } from "../../../components/astraIcons/ShoppingCart";
import { useDispatch, useSelector } from "../../../store";
import { selectIsCartOpen } from "../../../slices/shop/selectors";
import { toggleCart } from "../../../slices/shop/slice";
import { useShopList } from "../../../slices/shop/hooks";

export const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isCartOpen = useSelector(selectIsCartOpen);

  const list = useShopList();

  const headerClickHandler = useCallback(() => {
    dispatch(toggleCart());
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "95px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "primary.main",
        backgroundColor: isCartOpen
          ? alpha(theme.palette.primary.main, 0.05)
          : alpha(theme.palette.primary.main, 0.1),
        transition: isCartOpen
          ? theme.transitions.create("backgroundColor", {
              duration: theme.transitions.duration.enteringScreen,
            })
          : theme.transitions.create("backgroundColor", {
              duration: theme.transitions.duration.leavingScreen,
            }),
      }}
    >
      <ButtonBase
        onClick={headerClickHandler}
        sx={{
          borderRightWidth: isCartOpen ? "1px" : 0,
          borderRightStyle: "solid",
          borderRightColor: "primary.main",
          width: isCartOpen ? "97px" : "100%",
          height: "100%",
          flexGrow: 0,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ShoppingCartLight
          viewBox="0 0 41 40"
          sx={{
            width: "40px",
            height: "40px",
          }}
        />
      </ButtonBase>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          paddingTop: "32px",
          paddingRight: "32px",
          paddingBottom: "30px",
          paddingLeft: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "12px",
            lineHeight: "14px",
            color: "primary.dark",
            opacity: 0.5,
          }}
        >
          {list?.inquiry?.name ?? ""}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "500",
            color: "primary.dark",
          }}
        >
          {list?.department?.name ?? ""}
        </Typography>
      </Box>
    </Box>
  );
};
