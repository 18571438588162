import React, { useMemo } from "react";
import { useGetCategoriesQuery } from "../../slices/categories/api";
import { Autocomplete, FormControlProps, TextField } from "@mui/material";
import { PaperPageSelect } from "../layout/PaperPage";
import { useTranslation } from "react-i18next";
import { Spinner } from "../wideDrawer/Spinner";

type Option = {
  id: number;
  label: string;
};

type SelectCategoryProps = Omit<
  FormControlProps,
  "id" | "onChange" | "value"
> & {
  id: string;
  label: string;
  value?: number;
  onChange?: (categoryId: number) => void;
  error?: boolean;
  helperText?: string;
};

export const SelectCategory = ({
  id,
  label,
  onChange,
  error,
  helperText,
  value,
}: SelectCategoryProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCategoriesQuery();

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .filter(({ level }) => level === "1")
      .map((category) => {
        if (category) {
          return {
            id: category.id,
            label: category.name,
          };
        }
      })
      .filter((category): category is Option => !!category);
  }, [data]);

  const chosenOption = useMemo(() => {
    return options.filter(({ id }) => value === id)[0];
  }, [options, value]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Autocomplete
      PaperComponent={PaperPageSelect}
      loading={isLoading}
      loadingText={t("loading", { ns: "common" })}
      id={`autocomplete-${id}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      options={options}
      noOptionsText={t("emptySelect", { ns: "common" })}
      onChange={(event, item) => {
        if (onChange && item) {
          onChange(item.id);
        }
      }}
      value={chosenOption}
    />
  );
};
