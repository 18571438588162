import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ApprovalOutlined, ErrorOutlined } from "@mui/icons-material";
import { useDisplayPrice } from "../utils/useDisplayPrice";

type ShortProductInformationProps = {
  name?: string | null;
  supplierName: string;
  supplierProductCode: string;
  retailPrice?: number | null;
  retailCurrency?: string | null;
  commodityCodeRequiresApproval?: boolean;
  disableAssignPrice?: boolean;
};

export const ShortProductInformation = ({
  name,
  supplierName,
  supplierProductCode,
  retailPrice,
  retailCurrency,
  commodityCodeRequiresApproval,
  disableAssignPrice,
}: ShortProductInformationProps) => {
  const { t } = useTranslation("common");
  const displayPrice = useDisplayPrice();

  const na = t("na");

  return (
    <>
      <Typography variant="body1">
        {supplierName} {supplierProductCode}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        dir="auto"
        sx={{
          wordBreak: "break-word",
        }}
      >
        {name ?? na}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">
          {displayPrice(retailPrice) ?? na} {retailCurrency ?? na}
        </Typography>
        {commodityCodeRequiresApproval && (
          <Tooltip title={<>{t("commodityCodeRequiresApproval")}</>}>
            <ApprovalOutlined
              color="warning"
              fontSize="small"
              sx={{ marginLeft: 0.5 }}
            />
          </Tooltip>
        )}
        {disableAssignPrice && (
          <Tooltip
            title={<>{t("itemDetails.cantAssignPrice", { ns: "pages" })}</>}
          >
            <ErrorOutlined
              color="error"
              fontSize="small"
              sx={{ marginLeft: 0.5 }}
            />
          </Tooltip>
        )}
      </Box>
    </>
  );
};
