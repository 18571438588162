import React, { useCallback, useMemo } from "react";
import { Row } from "./Row/Row";
import {
  ListColumn,
  ListSelectedIds,
  ListSelectedIdsChangeHandler,
  RowBase,
} from "../types";
import { RowSkeleton } from "./RowSkeleton";
import { Empty } from "./Empty";

type RowsProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  rows: Row[];
  selectedIds?: ListSelectedIds;
  onSelectedIdsChange?: ListSelectedIdsChangeHandler;
  displaySkeleton?: boolean;
  subRowsKey?: SubRowsKey;
};

export const Rows = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  rows,
  selectedIds,
  onSelectedIdsChange,
  displaySkeleton,
  subRowsKey,
}: RowsProps<Row, SubRow, SubRowsKey>) => {
  const displayExpandableRows = typeof subRowsKey === "string";

  const selectRowHandler = useCallback(
    (id: number) => {
      if (onSelectedIdsChange && selectedIds) {
        onSelectedIdsChange(
          selectedIds?.includes(id)
            ? selectedIds.filter((listedId) => listedId !== id)
            : [...selectedIds, id]
        );
      }
    },
    [onSelectedIdsChange, selectedIds]
  );

  const skeleton = useMemo(() => {
    if (!displaySkeleton) {
      return null;
    }

    return Array.from(new Array(100)).map((_value, index) => (
      <RowSkeleton
        key={index}
        columns={columns}
        checkbox={!!selectedIds}
        expandableRows={displayExpandableRows}
      />
    ));
  }, [columns, displayExpandableRows, displaySkeleton, selectedIds]);

  const content = useMemo(() => {
    if (displaySkeleton) {
      return null;
    }

    if (rows.length === 0) {
      return (
        <Empty
          columns={columns}
          isSelectionOn={!!selectedIds}
          subRows={!!subRowsKey}
        />
      );
    }

    return rows.map((row, index) => (
      <Row
        key={row.id}
        first={index === 0}
        columns={columns}
        row={row}
        selected={selectedIds?.includes(row.id)}
        onSelectedChange={selectRowHandler}
        subRowsKey={subRowsKey}
      />
    ));
  }, [
    columns,
    displaySkeleton,
    subRowsKey,
    rows,
    selectRowHandler,
    selectedIds,
  ]);

  return (
    <>
      {skeleton}
      {content}
    </>
  );
};
