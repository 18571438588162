import React, { useCallback } from "react";
import { useColumns } from "./useColumns";
import {
  PaperPage,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetInventoryEntriesQuery } from "../../../slices/inventory/api";
import { CustomDataGridServerSide } from "../../../components/CustomDataGrid/CustomDataGridServerSide";
import { useTableTools } from "../../../utils/useContentTools";
import { useHistory } from "react-router-dom";
import { useFilters } from "./useFilters";

export const DataTable = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();

  const can = usePermissions();

  const { filters, countriesIds } = useFilters();

  const {
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    search,
    debouncedSearch,
    setSearch,
    sortModel,
    sortModelChangeHandler,
    resetHandler,
  } = useTableTools("inventory");

  const addClickHandler = useCallback(() => {
    history.push("/inventory/add");
  }, [history]);

  const {
    data: inventoryEntries,
    isLoading: areInventoryEntriesLoading,
    isFetching: areInventoryEntriesFetching,
  } = useGetInventoryEntriesQuery({
    page: page + 1,
    pageSize,
    search: debouncedSearch,
    sortModel,
    countriesIds,
  });

  const columns = useColumns();

  if (areInventoryEntriesLoading) {
    return <PaperPageSpinner />;
  }

  if (!inventoryEntries) {
    return null;
  }

  return (
    <PaperPage>
      <CustomDataGridServerSide
        autoHeight
        addButtonText={t("inventory.addInventory")}
        addButtonDisabled={!can("addInventory")}
        onAddClick={addClickHandler}
        columns={columns}
        search={search}
        filters={filters}
        setSearch={setSearch}
        page={page}
        onPageChange={pageChangeHandler}
        pageSize={pageSize}
        onPageSizeChange={pageSizeChangeHandler}
        rows={inventoryEntries.data}
        loading={areInventoryEntriesFetching}
        sortModel={sortModel}
        onSortModelChange={sortModelChangeHandler}
        rowCount={inventoryEntries.count}
        disableSelectionOnClick
        disableColumnMenu
        activeFilterIsUsed={false}
        resetCustomFilters={resetHandler}
      />
    </PaperPage>
  );
};
