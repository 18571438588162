import React, { memo, useCallback } from "react";
import { Box, ButtonGroup, Typography, useTheme } from "@mui/material";
import { WysiwygFieldProps } from "../WysiwygField";
import { useTranslation } from "react-i18next";
import { Editor } from "@tiptap/react";
import { ActionButton } from "./ActionButton";
import { TableControls } from "./TableControls";

type TopBarProps = Pick<WysiwygFieldProps, "label" | "error"> & {
  focused?: boolean;
  editor: Editor;
  tableEditing?: boolean;
};

export const TopBar = memo(
  ({ label, error, focused, editor, tableEditing }: TopBarProps) => {
    const theme = useTheme();
    const { t } = useTranslation("components");

    const topBarClickHandler = useCallback(
      (event) => {
        if (event.target === event.currentTarget) {
          editor.chain().focus().run();
        }
      },
      [editor]
    );

    const padding = focused
      ? `calc(${theme.spacing(2)} - 1px)`
      : theme.spacing(2);

    return (
      <Box
        onClick={topBarClickHandler}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 9999,
          backgroundColor: "#fff",
          borderRadius: "4px",
          paddingTop: padding,
          paddingLeft: padding,
          paddingRight: padding,
          paddingBottom: padding,
          display: "flex",
          alignItems: "center",
          gap: 1,
          borderBottom: `solid 1px ${theme.palette.grey[100]}`,
        }}
      >
        {label && (
          <Typography
            variant="body2"
            onClick={topBarClickHandler}
            sx={{
              color: error
                ? theme.palette.error.main
                : theme.palette.text.secondary,
              cursor: "default",
            }}
          >
            {label}
          </Typography>
        )}
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label={t("wysiwygField.formattingButtons")}
        >
          <ActionButton
            editor={editor}
            name="bold"
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            {t("wysiwygField.formatting.bold")}
          </ActionButton>
          <ActionButton
            editor={editor}
            name="italic"
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            {t("wysiwygField.formatting.italic")}
          </ActionButton>
          <ActionButton
            editor={editor}
            name="strike"
            onClick={() => editor.chain().focus().toggleStrike().run()}
          >
            {t("wysiwygField.formatting.strike")}
          </ActionButton>
        </ButtonGroup>
        {tableEditing && <TableControls editor={editor} />}
      </Box>
    );
  }
);
