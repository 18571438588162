import React, { useCallback, useState } from "react";
import { useSearchDebounce } from "../../../../utils/useSearchDebounce";

export const useSearchParams = () => {
  const [suppliersIds, setSuppliersIds] = useState<string[]>([]);

  const suppliersIdsChangeHandler = useCallback((value: string[]) => {
    setSuppliersIds(value);
  }, []);

  const [search, setSearch] = useState("");
  const debouncedSearch = useSearchDebounce(search);

  const searchChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  return {
    search,
    debouncedSearch,
    searchChangeHandler,
    suppliersIds,
    suppliersIdsChangeHandler,
  };
};
