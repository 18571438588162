import React, { useMemo } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PaperBase } from "../../../../components/layout/PaperBase";
import { useGetMatchedProductsListQuery } from "../../../../slices/items/api";
import { useItemDetailsUrlTools } from "../../useItemDetailsUrlTools";
import { Circular } from "../../../../components/spinners/Circular";
import { CenteringBox } from "../CenteringBox";
import { MatchingProduct } from "./MatchingProduct";

export const MatchingProducts = () => {
  const { t } = useTranslation("pages");
  const { itemId } = useItemDetailsUrlTools();

  const { data, isLoading } = useGetMatchedProductsListQuery(itemId);

  const loader = useMemo(() => {
    if (!isLoading) {
      return null;
    }

    return (
      <CenteringBox>
        <Circular />
      </CenteringBox>
    );
  }, [isLoading]);

  const results = useMemo(() => {
    if (!data || isLoading) {
      return null;
    }

    const matchA = data["A"];
    const matchB = data["B"];

    const noMatchesElement = (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="subtitle2">
          {t("itemDetails.noMatches")}
        </Typography>
      </Box>
    );

    return (
      <>
        <Grid item xs={6}>
          {matchA.length > 0 ? (
            <Stack spacing={2}>
              {matchA.map((matchingProduct) => (
                <MatchingProduct
                  key={matchingProduct.id}
                  product={matchingProduct}
                  match="A"
                />
              ))}
            </Stack>
          ) : (
            noMatchesElement
          )}
        </Grid>
        <Grid item xs={6}>
          {matchB.length > 0 ? (
            <Stack spacing={2}>
              {matchB.map((matchingProduct) => (
                <MatchingProduct
                  key={matchingProduct.id}
                  product={matchingProduct}
                  match="B"
                />
              ))}
            </Stack>
          ) : (
            noMatchesElement
          )}
        </Grid>
      </>
    );
  }, [data, isLoading, t]);

  return (
    <PaperBase
      sx={{
        marginTop: 2,
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle2">
              {t("itemDetails.matchA")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle2">
              {t("itemDetails.matchB")}
            </Typography>
          </Box>
        </Grid>
        {results}
      </Grid>
      {loader}
    </PaperBase>
  );
};
