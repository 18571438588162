import React from "react";
import { useTranslation } from "react-i18next";
import { Block } from "./Block";
import { useGetSuppliersQuery } from "../../../slices/suppliers/api";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { SuppliersFilterValue } from "../types";

export type SuppliersProps = {
  value: SuppliersFilterValue;
  onChange: (value: SuppliersFilterValue) => void;
};

export const Suppliers = ({ value, onChange }: SuppliersProps) => {
  const { t } = useTranslation("pages");

  const { data } = useGetSuppliersQuery({
    publicOnly: true,
  });

  if (!data) {
    return null;
  }

  return (
    <Block label={t("shopCatalog.filters.suppliers")}>
      <FormGroup sx={{ marginTop: "-8px" }}>
        {data
          .filter((supplier) => supplier.public)
          .map(({ id, name }) => (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={value[id] ?? false}
                  onClick={() => onChange({ ...value, [id]: !value[id] })}
                />
              }
              label={name}
            />
          ))}
      </FormGroup>
    </Block>
  );
};
