import React from "react";
import { Box } from "@mui/material";
import { useSchoolInquiryItemsTools } from "../../useSchoolInquiryItemsTools";
import { Assignment } from "./Assignment/Assignment";
import { Department } from "./Department/Department";

export const Controls = () => {
  const { assignmentId } = useSchoolInquiryItemsTools();

  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        gap: "20px",
      }}
    >
      {assignmentId ? <Assignment /> : <Department />}
    </Box>
  );
};
