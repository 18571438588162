import React from "react";
import { Box, Button, Paper, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type DisplayContainerProps = {
  heading: string;
  editHref?: string;
  editLabel?: string;
  editingDisabled?: boolean;
};

/**
 * Replace with NewDesignDisplayContainer. This is a component of the old design.
 * @deprecated
 */
export const DisplayContainer: React.FC<DisplayContainerProps> = ({
  children,
  heading,
  editHref,
  editLabel,
  editingDisabled,
}) => (
  <PaperContainer>
    <Typography dir="auto" variant="h5" component="h3" sx={{ marginBottom: 2 }}>
      {heading}
    </Typography>
    {children}
    {editHref && editLabel && (
      <ButtonBox>
        <EditButton
          component={Link}
          to={editHref}
          variant="outlined"
          disabled={editingDisabled}
        >
          {editLabel}
        </EditButton>
      </ButtonBox>
    )}
  </PaperContainer>
);

const PaperContainer = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const EditButton = styled(Button, {
  shouldForwardProp: () => true,
})<Record<string, unknown>>(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
