import React, { memo } from "react";
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Circular } from "./spinners/Circular";

export type SubmitSpinnerButtonProps = Omit<ButtonProps, "type" | "variant"> & {
  loading?: boolean;
};
/*
 * This is component for old design. Replace with NewDesignContextMenu if possible
 * @deprecated
 */

export const SubmitSpinnerButton = memo<SubmitSpinnerButtonProps>(
  ({ loading, disabled, children, ...props }) => {
    return (
      <ButtonBox>
        <Button
          variant="outlined"
          type="submit"
          disabled={loading || disabled}
          {...props}
        >
          {children}
        </Button>
        {loading && (
          <SpinnerBox>
            <Circular size={25} thickness={175} />
          </SpinnerBox>
        )}
      </ButtonBox>
    );
  }
);

const ButtonBox = styled(Box)({
  display: "flex",
});

const SpinnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(1),
}));
