import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import {
  Image,
  ImageSliderFixedHeight,
} from "../../../../components/ImageSliderFixedHeight";

type MatchCardProps = {
  label: string;
  images?: Image[] | null | undefined;
  actions: React.ReactNode;
  children: React.ReactNode;
};

export const MatchCard = ({
  label,
  actions,
  children,
  images,
}: MatchCardProps) => {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="subtitle2" sx={{ fontSize: 14 }} gutterBottom>
          {label}
        </Typography>
      </Box>
      <Card
        variant="outlined"
        sx={{ height: "90%", display: "flex", flexDirection: "column" }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          {images && images.length > 0 ? (
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                {children}
              </Box>
              <ImageSliderFixedHeight
                sx={{
                  width: "151px",
                  height: "151px",
                  marginLeft: "auto",
                  flexGrow: 0,
                  flexShrink: 0,
                }}
                images={images}
                height={151}
              />
            </Box>
          ) : (
            children
          )}
        </CardContent>
        <CardActions sx={{ padding: 2 }}>{actions}</CardActions>
      </Card>
    </>
  );
};
