import { useLocalStorage } from "usehooks-ts";
import { useCallback } from "react";

export const useHelpActive = () => {
  const [helpActiveState, setHelpActiveState] = useLocalStorage(
    "helpActive",
    false
  );

  const swapHelpActive = useCallback(
    () => setHelpActiveState(!helpActiveState),
    [helpActiveState, setHelpActiveState]
  );

  return {
    helpActiveState,
    swapHelpActive,
  };
};
