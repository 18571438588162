import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { ItemForm, itemFormSchemaCreator } from "./ItemForm";
import { EditItemRequestDto } from "../../../slices/items/types";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useEditItemMutation,
  useGetItemQuery,
} from "../../../slices/items/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { useGetSuppliersQuery } from "../../../slices/suppliers/api";
import { baseUrl } from "../NewItems";

export const Edit = () => {
  const { t } = useTranslation("pages");
  const { id, closeDrawer } = useTableUrlTools(baseUrl);
  const feedSuccess = useFeedSuccess();
  const schema = useYup(itemFormSchemaCreator);
  const formMethods = useForm<EditItemRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isFetching: isItemsDataLoading,
    data: item,
    error: itemsDataLoadingError,
  } = useGetItemQuery(id ?? skipToken);
  const {
    data: supplierData,
    isLoading: isSupplierDataLoading,
    error: supplierDataLoadingError,
  } = useGetSuppliersQuery({});
  const [editItem, { isLoading: isEditLoading }] = useEditItemMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("items.errors.edit")
  );

  const formattedItemData = useMemo(() => {
    if (!item || !supplierData || !id) {
      return null;
    }
    return {
      ...item,
      supplierId: item.supplier?.id ?? null,
      departmentInquiryId: item.departmentInquiry?.id,
    };
  }, [item, supplierData, id]);

  const onSubmit = useCallback(
    (form: EditItemRequestDto) => {
      editItem(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("items.successes.edit"), `${baseUrl}/${id}/details`);
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [editItem, catchFormError, feedSuccess, t, id, closeDrawer]
  );

  useEffect(() => {
    if (itemsDataLoadingError || supplierDataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, itemsDataLoadingError, supplierDataLoadingError]);

  if (isItemsDataLoading || isSupplierDataLoading) {
    return <Spinner />;
  }

  if (!formattedItemData) {
    return null;
  }

  return (
    <Form
      heading={t("items.edit")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <ItemForm
          itemData={formattedItemData}
          fieldsErrors={fieldsErrors}
          departmentInquiryId={formattedItemData.departmentInquiryId}
        />
      </FormProvider>
    </Form>
  );
};
