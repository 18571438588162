import React from "react";
import { useSchoolInquiryItemsTools } from "../../useSchoolInquiryItemsTools";
import {
  useGetAssignmentQuery,
  useGetSchoolDepartmentInquiryQuery,
} from "../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DetailsSkeleton } from "./DetailsSkeleton";
import { Budget } from "./Budget/Budget";
import {
  ASSIGNMENT_STATUS_CHIP_STYLES,
  DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES,
} from "../../../../consts";
import { useTranslation } from "react-i18next";
import { Status } from "./Status";
import { useDisplayAssignmentSchoolStatus } from "../../../../slices/schoolInquiries/hooks";

export const Details = () => {
  const { t } = useTranslation("pages");

  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();

  const { inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  // This data fetching requires fixing, I've just copied it from the previous implementation
  const { data: assignment, isLoading: isAssignmentLoading } =
    useGetAssignmentQuery(
      departmentInquiryId && assignmentId
        ? { inquiryId, departmentInquiryId, assignmentId }
        : skipToken
    );

  const { data: departmentInquiry, isLoading: isDepartmentInquiryLoading } =
    useGetSchoolDepartmentInquiryQuery(
      departmentInquiryId && !assignmentId
        ? { inquiryId, departmentInquiryId }
        : skipToken
    );

  if (isAssignmentLoading || isDepartmentInquiryLoading) {
    return <DetailsSkeleton />;
  }

  if (!assignmentId && departmentInquiry) {
    return (
      <>
        <Status
          chipStyle={
            DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[
              departmentInquiry.schoolStatus
            ]
          }
          label={t(
            `schoolDepartmentInquiryStatuses.${departmentInquiry.schoolStatus}`,
            {
              ns: "common",
            }
          )}
        />
        <Budget
          spentBudget={departmentInquiry.totalAmount}
          assignedBudget={
            departmentInquiry.isBudget ? departmentInquiry.budget : null
          }
          // This requires fixing too, should be done on the backend
          itemsWithoutPrice={departmentInquiry.items.reduce((prev, curr) => {
            if (!curr.totalAmount) {
              return prev + curr.quantity;
            }
            return prev;
          }, 0)}
        />
      </>
    );
  }

  if (assignmentId && assignment) {
    return (
      <>
        <Status
          chipStyle={ASSIGNMENT_STATUS_CHIP_STYLES[assignment.schoolStatus]}
          label={displayAssignmentSchoolStatus({
            schoolStatus: assignment.schoolStatus,
            user: assignment.user,
          })}
        />
        <Budget
          spentBudget={assignment.totalAmount}
          assignedBudget={assignment.budget}
          itemsWithoutPrice={assignment.items.reduce((prev, curr) => {
            if (!curr.totalAmount) {
              return prev + curr.quantity;
            }
            return prev;
          }, 0)}
        />
      </>
    );
  }

  return null;
};
