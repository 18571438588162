import React from "react";
import { useItemDetailsUrlTools } from "../../useItemDetailsUrlTools";
import { useGetItemQuery } from "../../../../slices/items/api";
import { Grid } from "@mui/material";
import { ItemData } from "./ItemData";
import { MatchedProduct } from "./MatchedProduct";
import { PaperBase } from "../../../../components/layout/PaperBase";

export const Item = () => {
  const { itemId } = useItemDetailsUrlTools();
  const { data } = useGetItemQuery(itemId);

  if (!data) {
    return null;
  }

  return (
    <PaperBase>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ItemData item={data} />
        </Grid>
        <Grid item xs={6}>
          <MatchedProduct item={data} />
        </Grid>
      </Grid>
    </PaperBase>
  );
};
