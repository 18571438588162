import React from "react";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useGetSuppliersQuery } from "../../slices/suppliers/api";
import { LoadingTextField } from "../LoadingTextField";
import { useTranslation } from "react-i18next";

type SelectSupplierProps = Omit<TextFieldProps, "select"> & {
  publicOnly?: boolean;
};

export const SelectSupplier = ({
  publicOnly,
  value = "",
  ...props
}: SelectSupplierProps) => {
  const { t } = useTranslation();

  const { data: suppliers = [], isLoading } = useGetSuppliersQuery({
    publicOnly,
  });

  return (
    <LoadingTextField loading={isLoading} value={value} select {...props}>
      {suppliers.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
      {suppliers.length < 1 && (
        <MenuItem key={"empty-list"} disabled>
          {t("emptySelect", { ns: "common" })}
        </MenuItem>
      )}
    </LoadingTextField>
  );
};
