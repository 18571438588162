import { Container, ContainerProps } from "@mui/material";
import React from "react";

export const ContainerBase = ({
  children,
  maxWidth = "lg",
  sx,
  ...props
}: ContainerProps) => (
  <Container
    sx={{
      flexGrow: 1,
      paddingTop: 2,
      paddingBottom: 2,
      display: "flex",
      flexDirection: "column",
      ...sx,
    }}
    maxWidth={maxWidth}
    {...props}
  >
    {children}
  </Container>
);
