import React from "react";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { useGetMainOrdersQuery } from "../../../slices/mainOrders/api";
import { MainOrderDetailsGrid } from "../../../components/MainOrderDetailsGrid";
import { Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SpinnerBase } from "../../../components/layout/SpinnerBase";
import { PaperBase } from "../../../components/layout/PaperBase";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetItemQuery } from "../../../slices/items/api";
import { Link } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const MainOrder = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { baseUrl, itemId } = useItemDetailsUrlTools();

  const { data: item, isLoading: isItemLoading } = useGetItemQuery(itemId);
  const { data: mainOrders = [], isLoading: areMainOrdersLoading } =
    useGetMainOrdersQuery(item?.departmentInquiry.inquiry.id ?? skipToken);

  if (isItemLoading || areMainOrdersLoading) {
    return (
      <Box>
        <SpinnerBase />
      </Box>
    );
  }

  const canAssignMainOrder =
    can("assignItemMainOrder") &&
    (item?.orderStatus === "new" || item?.orderStatus === "placed");

  const mainOrder = mainOrders.find(({ id }) => id === item?.mainOrder?.id);

  if (!canAssignMainOrder && !mainOrder) {
    return null;
  }

  return (
    <PaperBase sx={{ marginTop: 2 }}>
      <Typography variant="h5" component="h3" sx={{ marginBottom: 2 }}>
        {t("itemDetails.mainOrder")}
      </Typography>
      {!mainOrder && canAssignMainOrder && (
        <Button
          component={Link}
          to={`${baseUrl}/assignMainOrder?ids=${itemId}`}
        >
          {t("itemDetails.assign")}
        </Button>
      )}
      {mainOrder && <MainOrderDetailsGrid mainOrder={mainOrder} />}
    </PaperBase>
  );
};

const Box = styled(PaperBase)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
