import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { TableControls } from "./TableControls";
import { useListTools } from "../../../../../components/List/useListTools";
import { useGetSchoolDepartmentInquiryQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { List } from "../../../../../components/List/List";
import { ItemSchoolStatus } from "../../../../../slices/items/types";
import { useSchoolInquiryItemsListColumns } from "../useSchoolInquiryItemsListColumns";
import { SelectStatus } from "./SelectStatus";
import { GetSchoolDepartmentInquiryResponseDto } from "../../../../../slices/schoolInquiries/types";
import { useDisplayTotalAmount } from "../useDisplayTotalAmount";
import { RequestedItemQuantityField } from "../RequestedItemQuantityField";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { SelectRequestedItemStatus } from "../SelectRequestedItemStatus";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";

export const DepartmentInquiryItems = () => {
  const { t } = useTranslation("pages");

  const canBulkSubmit = useCanBulkSubmit();
  const displayTotalAmount = useDisplayTotalAmount();
  const preActionCheck = usePreActionCheck();

  const { selectedIds, selectedIdsChangeHandler } = useListTools();

  const self = useSelector(selectSelf);

  const {
    inquiryResult: { data: inquiry },
    baseUrl,
    inquiryId,
    departmentInquiryId,
  } = useSchoolInquiryItemsTools();

  const {
    data: departmentInquiry,
    isLoading: isDepartmentInquiryLoading,
    isFetching: isDepartmentInquiryFetching,
  } = useGetSchoolDepartmentInquiryQuery(
    departmentInquiryId ? { inquiryId, departmentInquiryId } : skipToken
  );

  const departmentInquiryShort = useMemo(
    () =>
      inquiry?.departmentinquiries.find(
        (departmentInquiry) => departmentInquiry.id === departmentInquiryId
      ),
    [departmentInquiryId, inquiry?.departmentinquiries]
  );

  const columns = useSchoolInquiryItemsListColumns<
    {
      schoolStatus: ItemSchoolStatus;
      requestedItems: GetSchoolDepartmentInquiryResponseDto["items"][number]["requestedItems"];
    },
    GetSchoolDepartmentInquiryResponseDto["items"][number]["requestedItems"][number],
    "requestedItems"
  >({
    baseUrl,
    columns: useMemo(
      () => [
        {
          order: 3,
          column: {
            field: "sourceList",
            width: "150px",
            name: t("schoolInquiryDetails.departmentColumns.sourceList"),
            renderCell: (row) =>
              row.requestedItems.length === 1
                ? row.requestedItems[0].sourceList
                : "",
          },
        },
        {
          order: 4,
          column: {
            field: "quantity",
            width: "150px",
            name: t("schoolInquiryDetails.departmentColumns.quantity"),
            renderCell: (row) => row.quantity,
            renderSubRowCell: (requestedItem) =>
              departmentInquiryId && (
                <RequestedItemQuantityField
                  id={requestedItem.id}
                  value={requestedItem.quantity}
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={requestedItem.assignmentId}
                  requestedItemSchoolStatus={requestedItem.schoolStatus}
                  assignmentSchoolStatus={requestedItem.assignmentSchoolStatus}
                  assignmentUserId={requestedItem.assignmentUserId}
                  loading={isDepartmentInquiryFetching}
                  disabled={
                    requestedItem.departmentInquirySchoolStatus ===
                      "cancelled" ||
                    !inquiry ||
                    preActionCheck(inquiry.schoolStatus)
                  }
                />
              ),
          },
        },
        {
          order: 8,
          column: {
            field: "totalAmount",
            name: t("schoolInquiryDetails.departmentColumns.totalAmount"),
            renderCell: ({
              supplierProductCode,
              productRetailPrice,
              productRetailCurrency,
              totalAmount,
            }) =>
              displayTotalAmount({
                supplierProductCode,
                productRetailPrice,
                productRetailCurrency,
                totalAmount,
              }),
            renderSubRowCell: (requestedItem, item) =>
              displayTotalAmount({
                supplierProductCode: item.supplierProductCode,
                productRetailPrice: item.productRetailPrice,
                productRetailCurrency: item.productRetailCurrency,
                totalAmount: requestedItem.totalAmount,
              }),
          },
        },
        {
          order: 9,
          column: {
            field: "schoolStatus",
            name: t("schoolInquiryDetails.departmentColumns.status"),
            width: "250px",
            renderCell: (row) => (
              <SelectStatus
                inquiryId={inquiryId}
                inquirySchoolStatus={inquiry?.schoolStatus}
                departmentInquiry={departmentInquiryShort}
                assignments={departmentInquiryShort?.assignments}
                item={row}
                isBulkSelected={selectedIds.includes(row.id)}
              />
            ),
            renderSubRowCell: (requestedItem) => (
              <SelectRequestedItemStatus
                inquiryId={inquiryId}
                inquirySchoolStatus={inquiry?.schoolStatus}
                departmentInquiryId={requestedItem.departmentInquiryId}
                departmentInquirySchoolStatus={
                  requestedItem.departmentInquirySchoolStatus
                }
                assignmentId={requestedItem.assignmentId}
                assignmentSchoolStatus={requestedItem.assignmentSchoolStatus}
                assignmentUserId={requestedItem.assignmentUserId}
                requestedItemId={requestedItem.id}
                requestedItemSchoolStatus={requestedItem.schoolStatus}
              />
            ),
          },
        },
      ],
      [
        departmentInquiryId,
        departmentInquiryShort,
        displayTotalAmount,
        inquiry,
        inquiryId,
        isDepartmentInquiryFetching,
        preActionCheck,
        selectedIds,
        t,
      ]
    ),
  });

  const displaySubRows = self?.role === "headOfDepartment";

  return (
    <>
      <TableControls selectedIds={selectedIds} />
      <List
        selectedIds={canBulkSubmit ? selectedIds : undefined}
        onSelectedIdsChange={selectedIdsChangeHandler}
        columns={columns}
        rows={departmentInquiry?.items ?? []}
        displaySkeleton={isDepartmentInquiryLoading}
        subRowsKey={displaySubRows ? "requestedItems" : undefined}
      />
    </>
  );
};
