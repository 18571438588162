import React, { useCallback } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useDeleteCommentMutation } from "../../../slices/comments/api";
import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { Delete } from "@mui/icons-material";
import { ClickableWithConfirmation } from "../../ClickableWithConfirmation";
import { Box } from "@mui/system";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { User } from "../../../entities";

type OneCommentProps = {
  id: number;
  user: User;
  createdAt: string;
  text: string;
  canDelete: boolean;
  onDeleteComment: (count: number) => void;
};

export const OneComment: React.FC<OneCommentProps> = ({
  id,
  user,
  createdAt,
  text,
  canDelete,
  onDeleteComment,
}) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const displayDate = useDisplayDateTime();

  const [deleteComment] = useDeleteCommentMutation();

  const onDelete = useCallback(() => {
    deleteComment(id)
      .unwrap()
      .then(() => {
        feedSuccess(t("comments.successes.deleted"));
        onDeleteComment(id);
      })
      .catch((error) => {
        feedError(
          error?.data?.message?.text[0] || t("comments.errors.deleted")
        );
      });
  }, [deleteComment, id, feedSuccess, feedError, onDeleteComment, t]);

  return (
    <Grid
      item
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      sx={{ marginTop: "10px", height: "fit-content" }}
    >
      <Grid item gridColumn="span 11">
        <Typography variant="subtitle2">{`${user.firstName} ${user.lastName}`}</Typography>
      </Grid>
      <Grid item gridColumn="span 1">
        {canDelete && (
          <ClickableWithConfirmation
            confirmationTitle={t("comments.deleteConfirmationTitle")}
            confirmationDescription={t(
              "comments.deleteConfirmationDescription"
            )}
            component={Box}
            onClick={onDelete}
          >
            <Button
              variant="text"
              sx={{ minWidth: "20px", padding: 0, paddingBottom: "10px" }}
            >
              <Delete color="disabled" fontSize="small" />
            </Button>
          </ClickableWithConfirmation>
        )}
      </Grid>
      <Grid item gridColumn="span 12" sx={{ marginBottom: "10px" }}>
        <Typography variant="body2">
          {displayDate(createdAt.toString())}
        </Typography>
      </Grid>
      <Grid
        item
        gridColumn="span 12"
        sx={{ marginRight: "10px", marginBottom: "10px" }}
      >
        <Typography
          variant="body1"
          style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 12">
        <Divider />
      </Grid>
    </Grid>
  );
};
