import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UiState } from "./types";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    title: null,
    changePasswordDrawer: false,
  } as UiState,
  reducers: {
    setTitle: (state, { payload }: PayloadAction<string | null>) => ({
      ...state,
      title: payload,
    }),
    setChangePasswordDrawer: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      changePasswordDrawer: payload,
    }),
  },
});

export const {
  actions: { setTitle, setChangePasswordDrawer },
} = uiSlice;
