import React, { memo } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  StandardTextFieldProps,
  TextField,
  styled,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Circular } from "../spinners/Circular";
import { LoadingButton } from "@mui/lab";

export type NewToolbarProps = {
  addButtonText?: string;
  addButtonDisabled?: boolean;
  onAddClick?: () => void;
  additionalButtonText?: string;
  additionalButtonDisabled?: boolean;
  additionalButtonDownloadInProcess?: boolean;
  onAdditionalClick?: () => void;
  hideAddButtonPlusSymbol?: boolean;
  wideSearch?: boolean;
  searchValue: string;
  searchLabel?: string;
  onSearchChange: StandardTextFieldProps["onChange"];
  onReset: () => void;
  filters?: React.ReactNode;
  buttons?: React.ReactNode;
  contentLoading?: boolean;
};

export const NewToolbar = memo<NewToolbarProps>(
  ({
    addButtonText,
    addButtonDisabled,
    onAddClick,
    additionalButtonText,
    additionalButtonDisabled,
    additionalButtonDownloadInProcess,
    onAdditionalClick,
    searchValue,
    searchLabel,
    hideAddButtonPlusSymbol,
    onSearchChange,
    onReset,
    filters,
    buttons,
    wideSearch,
    contentLoading,
  }) => {
    const { t } = useTranslation("components");

    return (
      <>
        {((addButtonText && onAddClick) ||
          (additionalButtonText && onAdditionalClick)) && (
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginBottom: 3 }}
            justifyContent={"right"}
          >
            {addButtonText && onAddClick && (
              <NewDesignButton
                startIcon={hideAddButtonPlusSymbol ? null : <Add />}
                variant="outlined"
                onClick={onAddClick}
                disabled={addButtonDisabled}
              >
                {addButtonText}
              </NewDesignButton>
            )}

            {additionalButtonText &&
              onAdditionalClick &&
              (!additionalButtonDownloadInProcess ? (
                <NewDesignButton
                  variant="outlined"
                  onClick={onAdditionalClick}
                  disabled={additionalButtonDisabled}
                >
                  {additionalButtonText}
                </NewDesignButton>
              ) : (
                <LoadingButton loading variant="outlined" />
              ))}
          </Stack>
        )}

        <Stack direction="row" spacing={3} sx={{ marginBottom: 2 }}>
          {buttons}
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-end", flexGrow: 1, gap: "10px" }}
            spacing={2}
          >
            <Box
              sx={{
                width: "55%",
                display: "flex",
                flexGrow: 1,
              }}
            >
              {filters}
            </Box>
            <TextField
              variant="outlined"
              size="small"
              label={searchLabel || t("toolbar.search")}
              value={searchValue}
              onChange={onSearchChange}
              sx={{
                flexGrow: wideSearch ? 1 : 0,
                marginRight: "-25px !important",
              }}
            />
            {contentLoading ? (
              <Box
                sx={{
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Circular size={25} thickness={175} />
              </Box>
            ) : (
              <IconButton onClick={onReset}>
                <Close />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </>
    );
  }
);

// TODO: move to theme, if necessary
const NewDesignButton = styled(Button)({
  textTransform: "none",
  fontSize: "13px",
  padding: "6px 20px",
  color: "#3390FF",
  backgroundColor: "#F1F7FF",
  "&:hover": {
    backgroundColor: "#E7F1FF",
  },
});
