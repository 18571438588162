import React, { memo } from "react";
import { GridRowId } from "@mui/x-data-grid";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import {
  useDeleteInquiryMutation,
  useLoseInquiryMutation,
} from "../../../slices/inquiries/api";
import { ContextMenu } from "../../../components/ContextMenu";
import { InquiryStatus } from "../../../types";

type ActionsProps = {
  id: GridRowId;
  status: InquiryStatus;
};

export const Actions = memo(({ id, status }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const intId = typeof id === "string" ? parseInt(id) : id;
  const can = usePermissions();
  const [deleteInquiry] = useDeleteInquiryMutation();
  const [loseInquiry] = useLoseInquiryMutation();

  return (
    <ContextMenu
      buttons={[
        {
          label: t("inquiries.actions.lose.label"),
          handler: () => {
            loseInquiry(intId)
              .unwrap()
              .then(() => {
                feedSuccess(t("inquiries.successes.lost"));
              })
              .catch((error) => {
                feedError(error?.data?.message ?? t("inquiries.errors.delete"));
              });
          },
          disabled:
            !can("loseInquiry") ||
            (status !== "new" &&
              status !== "underProcess" &&
              status !== "submitted"),
          confirmation: {
            title: t("inquiries.actions.lose.confirmationTitle"),
            description: t("inquiries.actions.lose.confirmationDescription"),
          },
        },
        {
          label: t("inquiries.actions.delete.label"),
          handler: () => {
            deleteInquiry(intId)
              .unwrap()
              .then(() => {
                feedSuccess(t("inquiries.successes.delete"));
              })
              .catch((error) => {
                feedError(error?.data?.message ?? t("inquiries.errors.delete"));
              });
          },
          disabled: !can("deleteInquiry"),
          confirmation: {
            title: t("inquiries.actions.delete.confirmationTitle"),
            description: t("inquiries.actions.delete.confirmationDescription"),
          },
        },
      ]}
    />
  );
});
