import React, { useCallback, useMemo } from "react";
import { DataGrid, DataGridProps, GridSortModel } from "@mui/x-data-grid";
import { Toolbar, ToolbarProps } from "../toolbar/Toolbar";
import { escapeRegEx } from "../../utils/escapeRegEx";
import { useLocalStorage } from "usehooks-ts";
import { DEFAULT_ACTIVE_FILTER, DEFAULT_SORT_MODEL } from "../../consts";
import { ActiveFilter } from "./StandaloneFilterComponents/ActiveFilter";
import { useTableTools } from "../../utils/useContentTools";

export type CustomDataGridProps = Omit<
  DataGridProps,
  | "pageSize"
  | "onPageSizeChange"
  | "pagination"
  | "sortModel"
  | "onSortModelChange"
  | "page"
  | "onPageChange"
> &
  Pick<
    ToolbarProps,
    | "addButtonText"
    | "addButtonDisabled"
    | "onAddClick"
    | "hideAddButtonPlusSymbol"
    | "additionalButtonText"
    | "additionalButtonDisabled"
    | "onAdditionalClick"
    | "buttons"
  > & {
    tableName: string;
    filters?: React.ReactNode;
    resetCustomFilters?: () => void;
    searchFields?: string[];
    activeFilterIsUsed?: boolean;
    defaultSortModel?: GridSortModel;
    toolbar?: boolean;
  };

/**
 * Replace with NewDesignCustomDataGrid. This is component for old design.
 * @deprecated
 */

export const CustomDataGrid = ({
  addButtonText,
  addButtonDisabled,
  onAddClick,
  additionalButtonText,
  additionalButtonDisabled,
  onAdditionalClick,
  hideAddButtonPlusSymbol,
  tableName,
  rows,
  rowsPerPageOptions = [5, 10, 25, 50, 100],
  filters,
  buttons,
  resetCustomFilters,
  searchFields = ["id"],
  activeFilterIsUsed = false,
  defaultSortModel = DEFAULT_SORT_MODEL,
  toolbar = true,
  ...props
}: CustomDataGridProps) => {
  const [activitiesFilter, setActivitiesFilter] = useLocalStorage<string[]>(
    `table-${tableName}.filter.activitiesFilter`,
    DEFAULT_ACTIVE_FILTER
  );
  const onActivitiesFilterChange = useCallback(
    (newActivitiesFilter: string[]) => setActivitiesFilter(newActivitiesFilter),
    [setActivitiesFilter]
  );

  const completeFilters = useMemo(
    () => (
      <>
        {activeFilterIsUsed && (
          <ActiveFilter
            onActivitiesFilterChange={onActivitiesFilterChange}
            activitiesFilter={activitiesFilter}
          />
        )}
        {filters}
      </>
    ),
    [filters, onActivitiesFilterChange, activitiesFilter, activeFilterIsUsed]
  );

  const resets = useCallback(() => {
    onActivitiesFilterChange(DEFAULT_ACTIVE_FILTER);

    if (resetCustomFilters) {
      resetCustomFilters();
    }
  }, [onActivitiesFilterChange, resetCustomFilters]);

  const [page, setPage] = useLocalStorage(`${tableName}.page`, 0);
  const pageChangeHandler = useCallback(
    (newPage: number) => setPage(newPage),
    [setPage]
  );

  const {
    sortModel,
    sortModelChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    search,
    searchChangeHandler,
    resetHandler,
  } = useTableTools(tableName, {
    defaultSortModel,
    resets,
  });

  const filteredRows = useMemo(() => {
    const searchRegEx = new RegExp(escapeRegEx(search), "i");

    return rows
      .filter((row) =>
        Object.keys(row).some((field) => {
          if (!searchFields.includes(field) || !row[field]) {
            return false;
          }

          if (searchRegEx.test(JSON.stringify(row[field]))) {
            return true;
          }

          if (row.firstName && row.lastName) {
            const fullName = `${row.firstName} ${row.lastName}`;
            if (searchRegEx.test(JSON.stringify(fullName))) {
              return true;
            }
          }

          return false;
        })
      )
      .filter((row) => {
        if (activeFilterIsUsed && activitiesFilter.length > 0) {
          return (
            (activitiesFilter.includes("Active") && !!row.active) ||
            (activitiesFilter.includes("Inactive") && !row.active)
          );
        }
        return true;
      });
  }, [rows, search, searchFields, activitiesFilter, activeFilterIsUsed]);

  return (
    <>
      {toolbar && (
        <Toolbar
          addButtonText={addButtonText}
          addButtonDisabled={addButtonDisabled}
          onAddClick={onAddClick}
          additionalButtonText={additionalButtonText}
          additionalButtonDisabled={additionalButtonDisabled}
          onAdditionalClick={onAdditionalClick}
          searchValue={search}
          hideAddButtonPlusSymbol={hideAddButtonPlusSymbol}
          onSearchChange={searchChangeHandler}
          onReset={resetHandler}
          buttons={buttons}
          filters={completeFilters}
          wideSearch
        />
      )}
      <DataGrid
        sx={{ minHeight: "360px" }}
        pageSize={pageSize}
        onPageSizeChange={pageSizeChangeHandler}
        pagination
        rowsPerPageOptions={rowsPerPageOptions}
        sortModel={sortModel}
        onSortModelChange={sortModelChangeHandler}
        page={page}
        onPageChange={pageChangeHandler}
        rows={filteredRows}
        {...props}
      />
    </>
  );
};
