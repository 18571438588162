import React, { useCallback } from "react";
import { usePermissions } from "../../../utils/usePermissions";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/wideDrawer/Form";
import { useAddInventoryEntryMutation } from "../../../slices/inventory/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useYup } from "../../../utils/validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form as InventoryForm } from "../form/Form";
import { InventoryEntryForm } from "../form/types";
import { inventoryEntrySchema } from "../form/schema";

export const Add = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("inventory.errors.add")
  );

  const { control, handleSubmit } = useForm<InventoryEntryForm>({
    resolver: yupResolver(useYup(inventoryEntrySchema)),
    defaultValues: {
      quantity: 0,
    },
  });

  const { closeDrawer } = useTableUrlTools("/inventory");

  const [addInventoryEntry, { isLoading: isInventoryEntryAdding }] =
    useAddInventoryEntryMutation();

  const onSubmit = useCallback(
    ({
      supplierId,
      supplierProductCode,
      countryId,
      quantity,
      comment,
    }: InventoryEntryForm) => {
      if (
        typeof supplierId === "number" &&
        typeof supplierProductCode === "string" &&
        typeof countryId === "number" &&
        typeof quantity === "number"
      ) {
        addInventoryEntry({
          supplierId,
          supplierProductCode,
          countryId,
          quantity,
          comment: comment && comment.length > 0 ? comment : null,
        })
          .unwrap()
          .then(() => {
            feedSuccess(t("inventory.successes.add"));
            closeDrawer();
          })
          .catch(catchFormError);
      }
    },
    [addInventoryEntry, catchFormError, closeDrawer, feedSuccess, t]
  );

  if (!can("addInventory")) {
    return null;
  }

  return (
    <Form
      heading={t("inventory.addInventory")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isInventoryEntryAdding}
      formError={formError}
    >
      <InventoryForm control={control} fieldsErrors={fieldsErrors} />
    </Form>
  );
};
