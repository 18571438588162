import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useQueryIds = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const ids = searchParams.get("ids");
    return ids?.split(",").map((idString) => parseInt(idString));
  }, [search]);
};
