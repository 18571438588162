import React, { useMemo } from "react";
import { PaperBase } from "../../../../components/layout/PaperBase";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Result } from "../Result";
import { Circular } from "../../../../components/spinners/Circular";
import { CenteringBox } from "../CenteringBox";
import { useItemDetailsUrlTools } from "../../useItemDetailsUrlTools";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSearchParams } from "./useSearchParams";
import { SelectSuppliers } from "../../../../components/prefilledSelectors/SelectSuppliers";
import { useGetProductsToAssignQuery } from "../../../../slices/products/api";

export const Search = () => {
  const { t } = useTranslation("pages");

  const { itemId } = useItemDetailsUrlTools();

  const {
    debouncedSearch,
    searchChangeHandler,
    suppliersIds,
    suppliersIdsChangeHandler,
  } = useSearchParams();

  const { data: products, isFetching: areProductsFetching } =
    useGetProductsToAssignQuery(
      debouncedSearch.length > 0
        ? {
            itemId,
            search: debouncedSearch,
            suppliersIds,
          }
        : skipToken
    );

  const results = useMemo(() => {
    if (areProductsFetching) {
      return (
        <CenteringBox>
          <Circular />
        </CenteringBox>
      );
    }

    if (!products || products.length === 0) {
      return (
        <CenteringBox>
          <Typography variant="subtitle2">
            {t("itemDetails.noResults")}
          </Typography>
        </CenteringBox>
      );
    }

    return (
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        {products.map((product) => (
          <Grid key={product.id} item xs={6}>
            <Result data={product} />
          </Grid>
        ))}
      </Grid>
    );
  }, [areProductsFetching, products, t]);

  return (
    <PaperBase
      sx={{
        marginTop: 2,
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <SelectSuppliers
          value={suppliersIds}
          onChange={suppliersIdsChangeHandler}
        />
        <TextField
          label={t("itemDetails.searchLabel")}
          fullWidth
          onChange={searchChangeHandler}
        />
      </Box>
      {results}
    </PaperBase>
  );
};
