import React from "react";
import { Box, Typography } from "@mui/material";
import { ListColumn, RowBase } from "../types";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/astra/colors";

type EmptyProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  isSelectionOn?: boolean;
  subRows?: boolean;
};

export const Empty = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  isSelectionOn,
  subRows,
}: EmptyProps<Row, SubRow, SubRowsKey>) => {
  const { t } = useTranslation("components");

  let span = columns.length;

  if (isSelectionOn) {
    span += 1;
  }

  if (subRows) {
    span += 1;
  }

  return (
    <Box
      sx={{
        width: "100%",
        gridColumn: `1 / span ${span}`,
        minHeight: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: "12px",
          color: colors.darkBlue,
        }}
      >
        {t("list.noRows")}
      </Typography>
    </Box>
  );
};
