import React, { useCallback, useEffect } from "react";
import { usePermissions } from "../../../utils/usePermissions";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useForm } from "react-hook-form";
import { InventoryEntryForm } from "../form/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { inventoryEntrySchema } from "../form/schema";
import {
  useEditInventoryEntryMutation,
  useGetInventoryEntryQuery,
} from "../../../slices/inventory/api";
import { Form } from "../../../components/wideDrawer/Form";
import { Form as InventoryForm } from "../form/Form";
import { skipToken } from "@reduxjs/toolkit/query";
import { Spinner } from "../../../components/wideDrawer/Spinner";

export const Edit = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("inventory.errors.edit")
  );

  const { control, handleSubmit, reset } = useForm<InventoryEntryForm>({
    resolver: yupResolver(useYup(inventoryEntrySchema)),
  });

  const { closeDrawer, id } = useTableUrlTools("/inventory");

  const {
    data: inventoryEntry,
    isLoading: isInventoryEntryLoading,
    isError: inventoryEntryLoadingError,
  } = useGetInventoryEntryQuery(typeof id === "number" ? { id } : skipToken);

  useEffect(() => {
    if (inventoryEntryLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, inventoryEntryLoadingError]);

  useEffect(() => {
    if (inventoryEntry) {
      reset({
        supplierId: inventoryEntry.supplierId,
        supplierProductCode: inventoryEntry.supplierProductCode,
        countryId: inventoryEntry.countryId,
        quantity: inventoryEntry.quantity,
        comment: inventoryEntry.comment,
      });
    }
  }, [inventoryEntry, reset]);

  const [editInventoryEntry, { isLoading: isInventoryEntryBeingEdited }] =
    useEditInventoryEntryMutation();

  const onSubmit = useCallback(
    ({ quantity, comment }: InventoryEntryForm) => {
      if (typeof id === "number" && typeof quantity === "number") {
        editInventoryEntry({
          id,
          quantity,
          comment: comment && comment.length > 0 ? comment : null,
        })
          .unwrap()
          .then(() => {
            feedSuccess(t("inventory.successes.edit"));
            closeDrawer();
          })
          .catch(catchFormError);
      }
    },
    [catchFormError, closeDrawer, editInventoryEntry, feedSuccess, id, t]
  );

  if (!can("editInventory") || inventoryEntryLoadingError) {
    return null;
  }

  if (isInventoryEntryLoading) {
    return <Spinner />;
  }

  return (
    <Form
      heading={t("inventory.editInventory")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isInventoryEntryBeingEdited}
      formError={formError}
    >
      <InventoryForm control={control} fieldsErrors={fieldsErrors} editing />
    </Form>
  );
};
