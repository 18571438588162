import { UseFormReset } from "react-hook-form";
import { useGetCurrenciesQuery } from "../slices/currencies/api";
import { useEffect } from "react";
import { useSelector } from "../store";
import {
  selectSelfRole,
  selectSelfSchoolCurrency,
} from "../slices/auth/selectors";
import { useLocalStorage } from "usehooks-ts";
import { useGetSchoolQuery } from "../slices/schools/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const useSetDefaultCurrency = (
  reset: UseFormReset<{ budgetCurrencyId?: number }>
) => {
  const result = useGetCurrenciesQuery();

  const selfRole = useSelector(selectSelfRole);
  const selfSchoolCurrency = useSelector(selectSelfSchoolCurrency);
  const [schoolId] = useLocalStorage<number | undefined>(
    "adminSchoolInquiriesSchoolId",
    undefined
  );
  const { data: school } = useGetSchoolQuery(
    (selfRole === "admin" && schoolId) || skipToken
  );

  const schoolCurrency =
    selfRole === "admin" ? school?.currency : selfSchoolCurrency;

  useEffect(() => {
    const { data } = result;

    if (data) {
      reset({
        budgetCurrencyId: data.find(({ code }) =>
          schoolCurrency ? code === schoolCurrency.code : code === "QAR"
        )?.id,
      });
    }
  }, [reset, result, schoolCurrency]);

  return result;
};
