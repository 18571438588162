import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import {
  AddDepartmentRequestDto,
  EditDepartmentRequestDto,
} from "../../../slices/departments/types";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { usePermissions } from "../../../utils/usePermissions";
import { createYupSchema } from "../../../utils/validation";
import { CategoryRelevances } from "./CategoryRelevances";
import { TagRelevances } from "./TagRelevances";

export const departmentFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    name: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    active: yup.boolean(),
    relevancesCategory: yup
      .array()
      .of(
        yup.object().shape({
          relevance: yup
            .number()
            .typeError(t("shouldBeInt"))
            .integer(t("shouldBeInt"))
            .min(0, t("shouldBeInt"))
            .max(100, t("numMustBeLess", { maxNum: 100 }))
            .required(t("required")),
          categoryId: yup
            .number()
            .typeError(t("shouldBeInt"))
            .required(t("required")),
        })
      )
      .test("unique", t("categoriesMustBeUnique"), (value) => {
        if (!value) {
          return true;
        }

        return (
          value.length === new Set(value.map((rel) => rel?.categoryId)).size
        );
      }),
    relevancesTag: yup
      .array()
      .of(
        yup.object().shape({
          relevance: yup
            .number()
            .typeError(t("shouldBeInt"))
            .integer(t("shouldBeInt"))
            .min(0, t("shouldBeInt"))
            .max(100, t("numMustBeLess", { maxNum: 100 }))
            .required(t("required")),
          tagName: yup
            .string()
            .typeError(t("shouldBeInt"))
            .required(t("required")),
        })
      )
      .test("unique", t("tagsMustBeUnique"), (value) => {
        if (!value) {
          return true;
        }

        return value.length === new Set(value.map((rel) => rel?.tagName)).size;
      }),
  })
);

export type DepartmentFormProps = {
  departmentData?: EditDepartmentRequestDto;
  fieldsErrors?: FieldsErrorState;
  isActiveCheckboxShown?: boolean;
};

export const DepartmentForm: React.FC<DepartmentFormProps> = ({
  departmentData,
  fieldsErrors,
  isActiveCheckboxShown = false,
}) => {
  const { t } = useTranslation();
  const can = usePermissions();
  const { register, control, formState } = useFormContext<
    AddDepartmentRequestDto | EditDepartmentRequestDto
  >();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {departmentData && (
        <input
          type="hidden"
          defaultValue={departmentData.id}
          {...register("id", {
            valueAsNumber: true,
          })}
        />
      )}
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          defaultValue={departmentData && departmentData.name}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <CategoryRelevances
        fieldsErrors={fieldsErrors}
        helperText={
          (formState?.errors?.relevancesCategory as Partial<FieldError>)
            ?.message
        }
      />
      <TagRelevances
        fieldsErrors={fieldsErrors}
        helperText={
          (formState?.errors?.relevancesTag as Partial<FieldError>)?.message
        }
      />
      <Grid item gridColumn="span 6">
        <Controller
          control={control}
          name="public"
          defaultValue={departmentData ? departmentData.public : true}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={departmentData ? departmentData.public : true}
                />
              }
              label={t("departments.publicStatus") as string}
              {...field}
            />
          )}
        />
      </Grid>
      {isActiveCheckboxShown && (
        <Grid item gridColumn="span 6">
          <Controller
            control={control}
            name="active"
            defaultValue={departmentData ? departmentData.active : true}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      departmentData ? departmentData.active : true
                    }
                  />
                }
                disabled={!can("changeDepartmentActive")}
                label={t("departments.activeStatus") as string}
                {...field}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
