import React, { useCallback, useState } from "react";
import {
  NumericField,
  NumericFieldProps,
} from "../../../../../components/fields/NumericField";
import { Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAddToCartMutation } from "../../../../../slices/shop/api";
import { useCurrentAssignmentId } from "../../../../../slices/shop/hooks";
import { useSelector } from "../../../../../store";
import { selectShopList } from "../../../../../slices/shop/selectors";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";

type AddProps = {
  id: number;
};

export const Add = ({ id }: AddProps) => {
  const theme = useTheme();
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const { inquiryId, departmentId } = useSelector(selectShopList);
  const assignmentId = useCurrentAssignmentId();

  const [addToCart] = useAddToCartMutation();

  const [quantity, setQuantity] = useState<NumericFieldProps["value"]>(1);

  const addClickHandler = useCallback(() => {
    if (!quantity) {
      return null;
    }

    addToCart({
      inquiryId,
      departmentInquiryId: departmentId,
      assignmentId,
      productId: id,
      quantity: typeof quantity === "string" ? parseInt(quantity) : quantity,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    addToCart,
    assignmentId,
    departmentId,
    handleError,
    handleSuccess,
    id,
    inquiryId,
    quantity,
  ]);

  return (
    <>
      <NumericField
        name={`product-${id}-quantity`}
        value={quantity}
        onChange={setQuantity}
        positiveOnly
        sx={{
          flexBasis: "110px",
          flexGrow: 0,
          flexShrink: 0,
          position: "relative",

          ".MuiOutlinedInput-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },

          ".MuiInputBase-input": {
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "500",
            height: "38px",
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },

          ".MuiIconButton-root": {
            width: "30px",
            height: "30px",

            svg: {
              color: `${theme.palette.primary.main} !important`,
            },

            "&.Mui-disabled svg": {
              opacity: 0.5,
            },
          },

          ".MuiInputAdornment-root": {
            position: "absolute",
          },

          ".MuiInputAdornment-positionStart": {
            left: "6px",
          },

          ".MuiInputAdornment-positionEnd": {
            right: "6px",
          },
        }}
      />
      <Button
        onClick={addClickHandler}
        variant="outlined"
        sx={{
          flexGrow: 1,
          marginLeft: "-1px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          fontSize: "13px",
          lineHeight: "24px",
          fontWeight: "500",
          textTransform: "none",
        }}
      >
        + {t("add", { ns: "common" })}
      </Button>
    </>
  );
};
