import React, { useCallback, useMemo, useState } from "react";
import { useGetTagsQuery } from "../../../slices/tags/api";
import { useSearchDebounce } from "../../../utils/useSearchDebounce";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { PaperPageSelect } from "../../../components/layout/PaperPage";
import { FieldsErrorState } from "../../../utils/formHelpers";
import {
  AddDepartmentRequestDto,
  EditDepartmentRequestDto,
} from "../../../slices/departments/types";

type SelectTagProps = {
  fieldsErrors?: FieldsErrorState;
  index: number;
};

export const SelectTag = ({ fieldsErrors, index }: SelectTagProps) => {
  const { t } = useTranslation("pages");
  const { control, formState } = useFormContext<
    AddDepartmentRequestDto | EditDepartmentRequestDto
  >();
  const [search, setSearch] = useState("");
  const debouncedSearch = useSearchDebounce(search);
  const { data, isFetching } = useGetTagsQuery(debouncedSearch);

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map(({ name }) => name);
  }, [data]);

  const inputChangeHandler = useCallback((event, value: string) => {
    setSearch(value);
  }, []);

  const tagError = useMemo(() => {
    const fieldErrors = formState.errors.relevancesTag?.[index]?.tagName;

    if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
      return fieldErrors.find(
        (error) => typeof error === "object" && "message" in error
      )?.message;
    }
  }, [formState.errors.relevancesTag, index]);

  return (
    <Grid item gridColumn="span 12">
      <ControlledCustomField
        control={control}
        name={`relevancesTag.${index}.tagName` as const}
        fieldsErrors={fieldsErrors}
        render={(errorMessage, { field: { onChange, value } }) => (
          <Autocomplete
            PaperComponent={PaperPageSelect}
            onChange={(event, value) => onChange(value)}
            value={value?.toString()}
            onInputChange={inputChangeHandler}
            inputValue={search}
            options={options}
            noOptionsText={t("emptySelect", { ns: "common" })}
            selectOnFocus
            handleHomeEndKeys
            loading={isFetching}
            loadingText={t("loading", { ns: "common" })}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t("departments.fields.tag")} ${index + 1} *`}
                error={!!errorMessage || !!tagError}
                helperText={errorMessage ?? tagError}
                fullWidth
              />
            )}
          />
        )}
      />
    </Grid>
  );
};
