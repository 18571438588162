import React, { useCallback, useMemo, useRef } from "react";
import { Box, styled, Typography } from "@mui/material";
import { Product } from "./Product/Product";
import { Pagination } from "./Pagination";
import { ShopProductCard } from "../../../slices/shop/types";
import { ProductSkeleton } from "./ProductSkeleton";
import { SHOP_PAGE_SIZE } from "../consts";
import { useTranslation } from "react-i18next";

export type ContentProps = {
  products?: ShopProductCard[];
  isLoading?: boolean;
  isFetching?: boolean;
  count?: number;
  page: number;
  onPageChange: (page: number) => void;
};

export const Content = ({
  isLoading,
  isFetching,
  products,
  count,
  page,
  onPageChange,
}: ContentProps) => {
  const { t } = useTranslation("pages");

  const containerRef = useRef<HTMLDivElement>(null);

  const pageChangeHandler = useCallback(
    (newPage: number) => {
      if (containerRef.current && newPage !== page) {
        window.scrollTo({
          top: containerRef.current.offsetTop - 150,
        });
      }

      onPageChange(newPage);
    },
    [onPageChange, page]
  );

  const areSkeletonsShowing = isLoading || isFetching;

  const skeletons = useMemo(() => {
    if (!areSkeletonsShowing) {
      return null;
    }

    return Array.from(new Array(SHOP_PAGE_SIZE)).map((_value, index) => (
      <ProductSkeleton key={index} />
    ));
  }, [areSkeletonsShowing]);

  const content = useMemo(() => {
    if (!products || areSkeletonsShowing) {
      return null;
    }

    if (products.length === 0) {
      return (
        <Box
          sx={{
            width: "100%",
            gridColumn: "1 / 5",
            textAlign: "center",
            marginTop: "32px",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: "20px",
              lineHeight: "24px",
              color: "primary.dark",
              fontWeight: "500",
            }}
          >
            {t("shopCatalog.nothingFound")}
          </Typography>
        </Box>
      );
    }

    return products.map((product) => (
      <Product key={product.id} product={product} />
    ));
  }, [areSkeletonsShowing, products, t]);

  return (
    <>
      <ContainerBox ref={containerRef}>
        {skeletons}
        {content}
      </ContainerBox>
      <Pagination count={count} page={page} onChange={pageChangeHandler} />
    </>
  );
};

const ContainerBox = styled(Box)(({ theme }) => ({
  marginTop: "32px",
  display: "grid",
  columnGap: "64px",
  rowGap: "33px",
  gridTemplateColumns: "repeat(4, 1fr)",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
}));
