import React from "react";
import {
  NewDesignStatusChip,
  NewDesignStatusChipProps,
} from "../../../../components/NewDesignStatusChip";
import { useSelector } from "../../../../store";
import { selectSelf } from "../../../../slices/auth/selectors";

type StatusProps = Omit<NewDesignStatusChipProps, "sx">;

export const Status = (props: StatusProps) => {
  const self = useSelector(selectSelf);

  if (self?.role === "headOfDepartment") {
    return null;
  }

  return <NewDesignStatusChip sx={{ marginLeft: "24px" }} {...props} />;
};
