import React, { memo, useState } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { LoadingIconButton } from "../LoadingIconButton";

type ConfirmationProp = {
  title: string;
  description: string;
  proceedButtonText?: string;
};

type IconButtonWithConfirmation = {
  disabled?: boolean;
  tooltip: string;
  onClick: () => void;
  loading?: boolean;
  children: React.ReactNode;
  confirmation?: ConfirmationProp;
};

export const CircleButton = memo(
  ({
    disabled,
    tooltip,
    confirmation,
    onClick,
    loading,
    children,
  }: IconButtonWithConfirmation) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const confirmHandler = () => {
      setShowConfirmation(false);
      onClick();
    };

    const clickHandler = () => {
      if (confirmation) {
        return setShowConfirmation(true);
      }

      onClick();
    };

    const isText = typeof children === "string" || typeof children === "number";
    const size = isText || loading ? "40px" : "auto";

    const content = isText ? (
      <Typography variant="button">{children}</Typography>
    ) : (
      children
    );

    return (
      <>
        <Tooltip title={tooltip}>
          <Stack direction="row" alignItems="center">
            <LoadingIconButton
              disabled={disabled}
              onClick={clickHandler}
              loading={loading}
              sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                backgroundColor: "rgba(0, 0, 0, 0)",
                color: "#757575",
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              {content}
            </LoadingIconButton>
          </Stack>
        </Tooltip>
        {confirmation && (
          <ConfirmationDialog
            open={showConfirmation}
            title={confirmation.title}
            description={confirmation.description}
            handleCancel={() => setShowConfirmation(false)}
            handleProceed={confirmHandler}
            proceedButtonText={confirmation.proceedButtonText}
          />
        )}
      </>
    );
  }
);
