import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  AddProductForm,
  EditProductForm,
} from "../../../../../slices/products/types";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { FieldsArrayAccordion } from "../../../../../components/wideDrawer/FieldsArrayAccordion";
import { Remove, UploadFile } from "@mui/icons-material";
import { Preview } from "./Preview";
import { useGetProductPreviewsQuery } from "../../../../../slices/products/api";
import { useTableUrlTools } from "../../../../../utils/useTableUrlTools";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const Images = () => {
  const { t } = useTranslation("pages");

  const { id } = useTableUrlTools("/products");

  const { control, register, formState, watch } = useFormContext<
    AddProductForm | EditProductForm
  >();

  const { append, remove } = useFieldArray({
    control,
    name: "images",
  });

  const addClickHandler = useCallback(() => {
    append({
      s3key: null,
      fileList: null,
    });
  }, [append]);

  const { data: previews = [], isLoading: arePreviewsLoading } =
    useGetProductPreviewsQuery(id ?? skipToken);

  const values = watch("images") ?? [];

  const fieldsElements = (() =>
    values.map(({ s3key, fileList }, index) => {
      const error = formState.errors.images?.[index];
      const name = `images.${index}.fileList` as const;

      const label = (() => {
        if (s3key) {
          return `${t("products.fields.images.singular")} ${index + 1}`;
        }

        if (fileList) {
          return fileList[0].name;
        }

        return t("products.fields.images.choose");
      })();

      const previewUrl = previews.find((preview) => preview.s3Key === s3key)
        ?.url as string;

      return (
        <Box
          key={name}
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <HiddenFileInput
            id={name}
            accept="image/*"
            type="file"
            {...register(name)}
          />
          {!s3key ? (
            <IconButton
              component="label"
              htmlFor={name}
              sx={{ width: "40px", flexGrow: 0, flexShrink: 0 }}
              color={error ? "error" : "default"}
              disabled={!!s3key}
            >
              <UploadFile />
            </IconButton>
          ) : (
            <Preview isLoading={arePreviewsLoading} url={previewUrl} />
          )}
          <Typography variant="caption" color={error ? "error" : "default"}>
            {label}
          </Typography>
          <IconButton onClick={() => remove(index)} sx={{ marginLeft: "auto" }}>
            <Remove />
          </IconButton>
        </Box>
      );
    }))();

  return (
    <Grid item gridColumn="span 12">
      <FieldsArrayAccordion
        id="product-form-images"
        label={t("products.fields.images.plural")}
        addLabel={t("products.fields.images.add")}
        addClickHandler={addClickHandler}
        error={!!formState.errors.images}
      >
        <Stack spacing={2} sx={{ marginBottom: 2 }}>
          {fieldsElements}
        </Stack>
      </FieldsArrayAccordion>
    </Grid>
  );
};

const HiddenFileInput = styled("input")({
  display: "none",
});
