import React from "react";
import { ShopProductCard } from "../../../../slices/shop/types";
import photoPlaceholder from "../../../../assets/no-photo.svg";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ImageSliderFixedHeight } from "../../../../components/ImageSliderFixedHeight";
import { useCalculateHeight } from "../../../../utils/useCalculateHeight";

type ImageProps = Pick<
  ShopProductCard,
  "images" | "name" | "supplierProductCode"
>;

export const Image = ({ images, name, supplierProductCode }: ImageProps) => {
  const { t } = useTranslation("pages");

  const { ref, calculatedHeight } = useCalculateHeight();

  if (images.length === 0) {
    return (
      <Box
        sx={{
          width: "100%",
          aspectRatio: "1 / 1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          img: {
            marginTop: "-20px",
            width: "163px",
            height: "160px",
          },
        }}
      >
        <img
          src={photoPlaceholder}
          alt={t("shopCatalog.list.noImageAlt", {
            product: name ?? supplierProductCode,
          })}
        />
      </Box>
    );
  }

  return (
    <Box ref={ref}>
      {calculatedHeight !== null && (
        <ImageSliderFixedHeight
          images={images.map((url) => ({
            src: url,
            alt: name ?? t("products.imgAlt", { supplierProductCode }),
          }))}
          height={calculatedHeight}
        />
      )}
    </Box>
  );
};
