import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import {
  useChangeUserActivityMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../../slices/users/api";
import { usePermissions } from "../../../utils/usePermissions";
import { useSelector } from "../../../store";
import { NewDesignContextMenu } from "../../../components/NewDesignContextMenu";
import {
  selectSelfId,
  selectSelfSchoolId,
} from "../../../slices/auth/selectors";

type ActionsProps = {
  id: number;
  active: boolean;
};

export const Actions = memo(({ id, active }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeUserActivity] = useChangeUserActivityMutation();
  const can = usePermissions();
  const [deleteUser] = useDeleteUserMutation();
  const selfId = useSelector(selectSelfId);
  const selfSchoolId = useSelector(selectSelfSchoolId);
  const { data } = useGetUsersQuery();

  const permissions = useMemo(() => {
    const user = data?.find((user) => user.id === id);

    if (!user) {
      return false;
    }

    const sameSchool = selfSchoolId === user.school?.id;

    const canEditAll = can("editUsers");
    const canEditSelf = can("editSelf") && id === selfId;
    const canEditOfOwnSchool = can("editUsersOfOwnSchool") && sameSchool;
    const canEditTeachersOfOwnSchool =
      can("editTeachersOfOwnSchool") && sameSchool && user.role === "teacher";

    const canChangeStatus = can("changeUserActive");
    const canChangeStatusForUsersOfOwnSchool =
      can("changeUserOfOwnSchoolStatus") && sameSchool;
    const canChangeStatusForTeachersOfOwnSchool =
      can("changeTeacherOfOwnSchoolStatus") &&
      sameSchool &&
      user.role === "teacher";

    return {
      canEdit: canEditAll || canEditOfOwnSchool || canEditTeachersOfOwnSchool,
      canChangePassword: canEditAll || canEditSelf,
      canChangeStatus:
        canChangeStatus ||
        canChangeStatusForUsersOfOwnSchool ||
        canChangeStatusForTeachersOfOwnSchool,
    };
  }, [can, data, id, selfId, selfSchoolId]);

  if (!permissions) {
    return null;
  }

  const { canEdit, canChangePassword, canChangeStatus } = permissions;

  return (
    <NewDesignContextMenu
      buttons={[
        {
          label: t("users.actions.edit.label"),
          handler: () => {
            history.push(`/users/${id}/edit`);
          },
          disabled: !canEdit,
        },
        {
          label: t("users.actions.changePassword.label"),
          handler: () => {
            history.push(`/users/${id}/changepassword`);
          },
          disabled: !canChangePassword,
        },
        {
          label: active
            ? t("actions.deactivate", { ns: "common" })
            : t("actions.activate", { ns: "common" }),
          confirmation: {
            title: t("users.actions.changeActivity.confirmationTitle"),
            description: t(
              "users.actions.changeActivity.confirmationDescription"
            ),
          },
          handler: () => {
            changeUserActivity({ id: id, active: !active })
              .unwrap()
              .then(() => {
                feedSuccess(t("users.changeActivitySuccess"));
              })
              .catch((error) => {
                feedError(
                  error?.data?.message ?? t("users.errors.changeActivity")
                );
              });
          },
          disabled: !canChangeStatus,
        },
        {
          label: t("users.actions.delete.label"),
          confirmation: {
            title: t("users.actions.delete.confirmationTitle"),
            description: t("users.actions.delete.confirmationDescription"),
          },
          handler: () => {
            deleteUser(id)
              .unwrap()
              .then(() => {
                feedSuccess(t("users.deleteSuccess"));
              })
              .catch((error) => {
                feedError(error?.data?.message ?? t("users.errors.delete"));
              });
          },
          disabled: !can("deleteUsers"),
        },
      ]}
    />
  );
});
