import React from "react";
import { ContainerBase } from "../../components/layout/ContainerBase";
import { FeaturedTags } from "./FeaturedTags/FeaturedTags";
import { CurrentList } from "./CurrentList/CurrentList";
import { Filters } from "./Filters/Filters";
import { Content } from "./Content/Content";
import { CantFindProduct } from "../../components/CantFindProduct/CantFindProduct";
import { useShopCatalogParams } from "./useShopCatalogParams";
import { useShopCatalogUrlTools } from "./useShopCatalogUrlTools";
import { useGetShopProductsQuery } from "../../slices/shop/api";
import { useCurrentList } from "./useCurrentList";
import { SHOP_PAGE_SIZE } from "./consts";
import { useScrollToTop } from "../../utils/useScrollToTop";

export const ShopCatalog = () => {
  const {
    search,
    uiSearch,
    searchChangeHandler,
    page,
    pageChangeHandler,
    minPrice,
    minPriceChangeHandler,
    maxPrice,
    maxPriceChangeHandler,
    tags,
    tagsChangeHandler,
    suppliers,
    suppliersChangeHandler,
    schoolLevels,
    schoolLevelsChangeHandler,
    resetFiltersHandler,
  } = useShopCatalogParams();

  const { currentCategoryId } = useShopCatalogUrlTools();

  const {
    data: productsResponse,
    isLoading: areProductsLoading,
    isFetching: areProductsFetching,
  } = useGetShopProductsQuery({
    page,
    pageSize: SHOP_PAGE_SIZE,
    search,
    currentCategoryId,
    tags,
    minPrice,
    maxPrice,
    checkedSuppliers: suppliers,
    checkedSchoolLevel: schoolLevels,
  });

  useCurrentList();

  useScrollToTop();

  return (
    <>
      <FeaturedTags selectedTags={tags} onChange={tagsChangeHandler} />
      <ContainerBase
        sx={{ paddingTop: 0, paddingBottom: "195px" }}
        maxWidth="xl"
      >
        <CurrentList />
        <Filters
          onResetFilters={resetFiltersHandler}
          TagsFieldProps={{
            value: tags,
            onChange: tagsChangeHandler,
          }}
          SearchFieldProps={{
            value: uiSearch,
            onChange: searchChangeHandler,
          }}
          SchoolLevelsProps={{
            value: schoolLevels,
            onChange: schoolLevelsChangeHandler,
          }}
          PriceRangeProps={{
            minPrice: {
              value: minPrice,
              onChange: minPriceChangeHandler,
            },
            maxPrice: {
              value: maxPrice,
              onChange: maxPriceChangeHandler,
            },
          }}
          SuppliersProps={{
            value: suppliers,
            onChange: suppliersChangeHandler,
          }}
        />
        <Content
          isFetching={areProductsFetching}
          isLoading={areProductsLoading}
          products={productsResponse?.data}
          count={productsResponse?.count}
          page={page}
          onPageChange={pageChangeHandler}
        />
        <CantFindProduct sx={{ marginTop: "92px", alignSelf: "center" }} />
      </ContainerBase>
    </>
  );
};
