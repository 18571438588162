import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../../components/CustomDataGrid/CustomDataGridRowActions";
import { useDeletePurchaseOrderMutation } from "../../../../slices/purchaseOrders/api";
import { GridRowId } from "@mui/x-data-grid";
import { usePermissions } from "../../../../utils/usePermissions";

type ActionsProps = {
  id: GridRowId;
};

export const Actions: React.FC<ActionsProps> = ({ id }) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const can = usePermissions();
  const [deletePurchaseOrder] = useDeletePurchaseOrderMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onDelete = useCallback(() => {
    deletePurchaseOrder(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("purchaseOrders.successes.delete"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("purchaseOrders.error.delete"));
      });
  }, [deletePurchaseOrder, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationTitle={t("purchaseOrders.confirmDeleteTitle")}
      confirmationDescription={t("purchaseOrders.confirmDeleteDescription")}
      onDeleteClick={onDelete}
      canDelete={can("deletePurchaseOrder")}
    />
  );
};
