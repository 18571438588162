import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useAddCommoditycodeMutation } from "../../../slices/commoditycodes/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import {
  CommoditycodeForm,
  commoditycodeFormSchemaCreator,
} from "./CommoditycodeForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";

type AddCommoditycodeForm = {
  code: string;
  description: string;
  requireApproval: boolean;
};

export const Add: React.FC = () => {
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(commoditycodeFormSchemaCreator);
  const formMethods = useForm<AddCommoditycodeForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addCommoditycode, { isLoading }] = useAddCommoditycodeMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("commoditycodes.errors.add")
  );

  const { closeDrawer } = useTableUrlTools("/commoditycodes");
  const onSubmit = useCallback(
    (form: AddCommoditycodeForm) =>
      addCommoditycode({ ...form })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("commoditycodes.addSuccess"), `/commoditycodes/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError),
    [addCommoditycode, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <Form
      heading={t("commoditycodes.addCommoditycode")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <CommoditycodeForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
