import React, { useCallback, useState } from "react";
import { Box, ButtonBase, Typography, useTheme } from "@mui/material";

type TagProps = {
  icon: string | null;
  iconHover: string | null;
  iconSelected: string | null;
  label: string;
  selected?: boolean;
  onClick?: () => void;
};

export const Tag = ({
  icon,
  iconHover,
  iconSelected,
  label,
  selected,
  onClick,
}: TagProps) => {
  const theme = useTheme();

  const [isHover, setIsHover] = useState(false);

  const mouseEnterHandler = useCallback(() => {
    setIsHover(true);
  }, []);

  const mouseLeaveHandler = useCallback(() => {
    setIsHover(false);
  }, []);

  if (!icon || !iconHover || !iconSelected) {
    return null;
  }

  const unselectedIcon = isHover ? iconHover : icon;

  return (
    <ButtonBase
      onClick={onClick}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      sx={{
        minWidth: "120px",
        flexGrow: 1,
        flexShrink: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: selected ? theme.palette.pink.main : theme.palette.primary.dark,
        paddingRight: "15px",
        paddingLeft: "15px",

        ":hover": {
          color: theme.palette.pink.main,
        },

        svg: {
          width: "21px",
          height: "20px",
        },
      }}
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: selected ? iconSelected : unselectedIcon,
        }}
      />
      <Typography
        component="span"
        sx={{ fontSize: "12px", lineHeight: "24px" }}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
};
