import React from "react";

import { getDownloadUrl } from "../../../utils/S3UploadFile";
import { GridRowId } from "@mui/x-data-grid";
import { saveBlob } from "../../../utils/downloadFileFromServer";
import { FileIcon } from "../../../components/FileIcon";
import { CircleButton } from "../../../components/toolbar/CircleButton";
import { useTranslation } from "react-i18next";

export const DownloadFileCellBtn: React.FC<{
  mo_id: GridRowId;
  filekey?: string;
}> = ({ mo_id, filekey }) => {
  const { t } = useTranslation("common");
  const intId = typeof mo_id === "string" ? parseInt(mo_id) : mo_id;

  const onDownloadClick = () => {
    let filename: string;
    getDownloadUrl(intId, "mo")
      .then((response) => response.json())
      .then((resData) => {
        filename = resData.filename;
        return fetch(resData.url, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        });
      })
      .then((fileResponse) => fileResponse.blob())
      .then((blob) => saveBlob(blob, filename));
  };
  return (
    <CircleButton onClick={onDownloadClick} tooltip={t("downloadFile")}>
      <FileIcon filename={filekey} />
    </CircleButton>
  );
};
