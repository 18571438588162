import React from "react";
import { Box, Grid } from "@mui/material";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { ControlledNumericCurrencyField } from "../../../../components/controlledFields/ControlledNumericCurrencyField";
import { SelectCurrency } from "../../../../components/prefilledSelectors/SelectCurrency";
import { Control } from "react-hook-form";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import {
  AddAssignmentForm,
  AssignmentFormBase,
  EditAssignmentForm,
} from "../../../../slices/schoolInquiries/types";
import { NewDesignSelectTeacher } from "../../../../components/prefilledSelectors/NewDesignSelectTeacher";
import { StyledFieldTitle } from "../../../SignIn/StyledComponents";

type AssignmentFormProps = {
  fieldsErrors?: FieldsErrorState;
  schoolId?: number;
  editing?: boolean;
  isBudget: boolean;
} & (
  | { editing?: false; control: Control<AddAssignmentForm> }
  | { editing: true; control: Control<EditAssignmentForm> }
);

const teacherFieldGuard = (
  control: AssignmentFormProps["control"],
  editing?: boolean
): control is Control<AddAssignmentForm> => !editing;

const dummyGuard = (
  control: AssignmentFormProps["control"]
): control is Control<AssignmentFormBase> => true;

export const AssignmentForm = ({
  control,
  fieldsErrors,
  schoolId,
  editing,
  isBudget,
}: AssignmentFormProps) => {
  const { t } = useTranslation("pages");

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {teacherFieldGuard(control, editing) && (
        <Grid item gridColumn="span 12">
          <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
            {`${t("schoolInquiryDetails.assignmentFields.teacher")} *`}
          </StyledFieldTitle>
          <ControlledCustomField
            control={control}
            fieldsErrors={fieldsErrors}
            name="teacherId"
            render={(errorMessage, { field }) => (
              <NewDesignSelectTeacher
                id="add-assignment"
                schoolId={schoolId}
                fullWidth
                label={`${t(
                  "schoolInquiryDetails.assignmentFields.teacher"
                )} *`}
                error={!!errorMessage}
                helperText={errorMessage}
                selectProps={field}
                size="small"
              />
            )}
          />
        </Grid>
      )}
      {dummyGuard(control) && isBudget && (
        <Grid item gridColumn="span 12">
          <StyledFieldTitle
            sx={{
              marginTop: "0px !important",
              color: !isBudget ? "#a3a3a3" : "#5E7DA0",
            }}
          >
            {`${t("schoolInquiryDetails.assignmentFields.budget")}`}
          </StyledFieldTitle>
          <Box sx={{ display: "flex", gap: 0 }}>
            <ControlledNumericCurrencyField
              fullWidth
              control={control}
              fieldsErrors={fieldsErrors}
              disabled={!isBudget}
              name="budget"
              positiveOnly
              disableLabel
              placeholder={t("schoolInquiryDetails.assignmentFields.budget")}
              sx={{
                flex: "3",
                ".MuiOutlinedInput-root": {
                  height: "36px",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "4px 0 0 4px",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: "4px 0 0 4px",
                },
              }}
            />
            <ControlledCustomField
              control={control}
              name="budgetCurrencyId"
              render={(errorMessage, { field }) => (
                <SelectCurrency
                  onlySchoolCurrency
                  placeholder={`${t(
                    "schoolInquiryDetails.assignmentFields.budgetCurrency"
                  )}`}
                  fullWidth
                  disabled={!isBudget}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  {...field}
                  size="small"
                  sx={{
                    minWidth: "100px",
                    flex: "1",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0 4px 4px 0",
                    },
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
