import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import {
  useEditDepartmentMutation,
  useGetDepartmentsQuery,
} from "../../../slices/departments/api";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { DepartmentForm, departmentFormSchemaCreator } from "./DepartmentForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";

type EditDepartmentForm = {
  id: number;
  name: string;
  active: boolean;
  public: boolean;
  relevancesCategory: {
    relevance: number;
    categoryId: number;
  }[];
  relevancesTag: {
    relevance: number;
    tagName: string;
  }[];
};

export const Edit: React.FC = () => {
  const { id, closeDrawer } = useTableUrlTools("/departments");
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(departmentFormSchemaCreator);
  const formMethods = useForm<EditDepartmentForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setValue } = formMethods;
  const {
    isLoading: isDataLoading,
    data,
    error: dataLoadingError,
  } = useGetDepartmentsQuery({});
  const [editDepartment, { isLoading: isEditLoading }] =
    useEditDepartmentMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("departments.errors.edit")
  );

  const departmentData = useMemo(
    () => (data && id ? data.find((department) => department.id === id) : null),
    [data, id]
  );

  const onSubmit = useCallback(
    (form: EditDepartmentForm) =>
      editDepartment(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("departments.editSuccess"), `/departments/${id}`);
          closeDrawer();
        })
        .catch(catchFormError),
    [catchFormError, closeDrawer, editDepartment, feedSuccess, id, t]
  );

  useEffect(() => {
    if (dataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, dataLoadingError]);

  useEffect(() => {
    if (departmentData?.relevancesCategory) {
      setValue(
        "relevancesCategory",
        departmentData?.relevancesCategory.map((rel) => ({
          relevance: rel.relevance,
          categoryId: rel.categoryId,
        }))
      );
    }
    if (departmentData?.relevancesTag) {
      setValue(
        "relevancesTag",
        departmentData?.relevancesTag.map((rel) => ({
          relevance: rel.relevance,
          tagName: rel.tagName,
        }))
      );
    }
  }, [
    setValue,
    departmentData?.relevancesCategory,
    departmentData?.relevancesTag,
  ]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!departmentData) {
    return null;
  }

  return (
    <Form
      heading={t("departments.editDepartment")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <DepartmentForm
          departmentData={departmentData}
          fieldsErrors={fieldsErrors}
          isActiveCheckboxShown={true}
        />
      </FormProvider>
    </Form>
  );
};
