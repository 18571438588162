import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { FORGOT_PASSWORD_CODE_LENGTH } from "../../../consts";
import { EmailForm } from "./EmailForm";
import { CodeForm } from "./CodeForm";
import { SignInBackground } from "../SignInBackground";

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const isCodeSent = () => {
    return code.length === FORGOT_PASSWORD_CODE_LENGTH;
  };

  return (
    <Container maxWidth="sm">
      <SignInBackground />
      <Box sx={{ margin: 0, padding: 0, zIndex: "10", position: "sticky" }}>
        {!isCodeSent() && !email.length && <EmailForm setEmail={setEmail} />}
        {!isCodeSent() && email.length > 0 && (
          <CodeForm email={email} setCode={setCode} />
        )}
        {isCodeSent() && <ResetPasswordForm userEmail={email} code={code} />}
      </Box>
    </Container>
  );
};
