import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  GetRecordRequestDto,
  GetRecordResponseDto,
  GetSearchRecordRequestDto,
  GetSearchRecordResponseDto,
} from "./types";

export const searchesApi = createApi({
  reducerPath: "searchesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/searches"),
    prepareHeaders,
  }),
  tagTypes: ["SearchRecordRow", "SearchRecord"],
  endpoints: (builder) => ({
    getSearchRecords: builder.query<GetRecordResponseDto, GetRecordRequestDto>({
      query: ({ page, pageSize, from, to, field, sort, schools, users }) => {
        const urlSearchParams = new URLSearchParams({
          page: `${page}`,
          pageSize: `${pageSize}`,
        });

        if (from) {
          urlSearchParams.set("from", from);
        }

        if (to) {
          urlSearchParams.set("to", to);
        }

        if (sort && field) {
          urlSearchParams.set("field", field);
          urlSearchParams.set("sort", sort);
        }

        if (users) {
          urlSearchParams.set("users", users);
        }

        if (schools) {
          urlSearchParams.set("schools", schools);
        }

        return `?${urlSearchParams.toString()}`;
      },
      providesTags: (result, error, arg) => [
        { type: "SearchRecordRow", ...arg },
      ],
    }),
    getSearchRecord: builder.query<
      GetSearchRecordResponseDto,
      GetSearchRecordRequestDto
    >({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "SearchRecord", id }],
    }),
  }),
});

export const { useGetSearchRecordsQuery, useGetSearchRecordQuery } =
  searchesApi;
