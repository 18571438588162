import React, { forwardRef } from "react";
import { MenuItem } from "@mui/material";
import { useGetSchoolsQuery } from "../../slices/schools/api";
import { LoadingTextField, LoadingTextFieldProps } from "../LoadingTextField";
import { useSelector } from "../../store";
import { useTranslation } from "react-i18next";
import { selectSelfRole } from "../../slices/auth/selectors";

export const SelectSchool = forwardRef(
  (
    {
      value = "",
      disabled,
      ...props
    }: Omit<LoadingTextFieldProps, "select" | "loading" | "ref">,
    ref: LoadingTextFieldProps["ref"]
  ) => {
    const { data = [], isLoading } = useGetSchoolsQuery();
    const selfRole = useSelector(selectSelfRole);
    const { t } = useTranslation();

    const activeSchools = data.filter((school) => school.active);

    return (
      <LoadingTextField
        select
        loading={isLoading}
        disabled={
          disabled ||
          (selfRole === "principal" && activeSchools.length === 1) ||
          selfRole === "headOfDepartment"
        }
        value={value}
        ref={ref}
        {...props}
      >
        {activeSchools.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
        {activeSchools.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </LoadingTextField>
    );
  }
);
