import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { INQUIRY_STATUS_COLORS } from "../../../consts";
import { Statistics } from "../../../components/Statistics";
import { InquiryStatistics } from "../../../slices/inquiries/types";
import { InquiryStatus, inquiryStatuses } from "../../../types";

type StatisticsBarProps = {
  statistics: InquiryStatistics;
  setStatusesFilter: (filters: string[]) => void;
};

export const StatisticsBar: React.FC<StatisticsBarProps> = ({
  statistics,
  setStatusesFilter,
}) => {
  const { t } = useTranslation("pages");

  const setFilterByClick = useCallback(
    (value: InquiryStatus) => {
      setStatusesFilter([value]);
    },
    [setStatusesFilter]
  );

  return (
    <Statistics
      stackProps={{ sx: { marginBottom: 2 } }}
      blocks={inquiryStatuses.map((status) => ({
        label: t(`inquiries.statuses.${status}`),
        percentage: statistics[status],
        onClick: () => setFilterByClick(status),
        color: INQUIRY_STATUS_COLORS[status],
      }))}
    />
  );
};
