import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InquiryFlattened } from "../../../slices/inquiries/types";
import { capitalize } from "../../../utils/capitalize";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";

type InquiryDataProps = {
  data: Partial<InquiryFlattened>;
};

export const InquiryData: React.FC<InquiryDataProps> = ({ data }) => {
  const { t } = useTranslation("pages");
  const displayDate = useDisplayDateTime(false);

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      sx={{ alignSelf: "left", marginBottom: 1 }}
    >
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.name")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.name}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.school")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.school?.name} ({data.school?.currency.code})
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.priority")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {capitalize(data.priority || t("na", { ns: "common" }))}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.dueDate")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.dueDate ? displayDate(data.dueDate) : t("na")}
        </Typography>
      </Grid>
    </Grid>
  );
};
