import React from "react";
import { Box } from "@mui/material";

type CenteringBoxProps = {
  children: React.ReactNode;
};

export const CenteringBox = ({ children }: CenteringBoxProps) => (
  <Box
    sx={{
      marginTop: 1,
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {children}
  </Box>
);
