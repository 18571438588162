import React, { useMemo } from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Entity } from "../entities";

type CustomDisplays<T> = {
  [P in keyof T]: (value: T[P]) => React.ReactNode;
};

type EntityDetailsTableProps<E> = {
  entity: E;
  excludeFields: Array<keyof E>;
  customDisplays?: Partial<CustomDisplays<E>>;
};

export function EntityDetailsTable<E extends Entity>({
  entity,
  excludeFields,
  customDisplays,
}: EntityDetailsTableProps<E>) {
  const keys = Object.keys(entity) as Array<keyof E>;

  const table = useMemo(
    () =>
      keys.map((key) => {
        if (excludeFields.includes(key)) {
          return null;
        }

        const value = entity[key];
        const customDisplay = customDisplays && customDisplays[key];

        return (
          <TableRow key={key.toString()}>
            <TableCell>{key}</TableCell>
            <TableCell>
              {customDisplay ? customDisplay(value) : value}
            </TableCell>
          </TableRow>
        );
      }),
    [customDisplays, entity, excludeFields, keys]
  );

  return (
    <Table>
      <TableBody>{table}</TableBody>
    </Table>
  );
}
