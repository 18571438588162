import { useLocalStorage } from "usehooks-ts";
import { useCallback } from "react";

export const useDrawerOpen = () => {
  const [drawerOpenState, setDrawerOpenState] = useLocalStorage(
    "drawerOpen",
    true
  );

  const openDrawer = useCallback(
    () => setDrawerOpenState(true),
    [setDrawerOpenState]
  );
  const closeDrawer = useCallback(
    () => setDrawerOpenState(false),
    [setDrawerOpenState]
  );

  return {
    drawerOpenState,
    openDrawer,
    closeDrawer,
  };
};
