// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const propToString = <T>(obj?: T): T => {
  return new Proxy(
    {},
    {
      // eslint-disable-next-line no-empty-pattern
      get({}, prop) {
        return prop;
      },
    }
  ) as T;
};
