import { Role } from "../permissions/permissions";
import { useSelector } from "../store";
import { selectSelfRole } from "../slices/auth/selectors";

export const abilityCheckCreator = (allowedFor: Role[]) => {
  return () => {
    const role = useSelector(selectSelfRole);

    if (!role) {
      return false;
    }

    return allowedFor.includes(role);
  };
};
