import {
  CommercialOffer,
  DeleteRequestDto,
  GenerateCORequestDto,
  GetCOResponseDto,
  GetCOsResponseDto,
  UploadSignedFileRequestDto,
} from "./types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";

export const commercialOffersApi = createApi({
  reducerPath: "commercialOfferApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/commercialOffers"),
    prepareHeaders,
  }),
  tagTypes: ["CommercialOffer"],
  endpoints: (builder) => ({
    getCOs: builder.query<GetCOsResponseDto, number>({
      query: (inquiryId) => ({
        url: `${inquiryId}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "CommercialOffer", id: "list" }],
    }),
    getCO: builder.query<GetCOResponseDto, number>({
      query: (id) => ({
        url: `one/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "CommercialOffer", id }],
      transformResponse: (res: CommercialOffer) => {
        return {
          ...res,
          inquiry: res?.inquiry?.name || "",
          inquiryId: res?.inquiry?.id || 0,
          generatedBy:
            (res?.generatedBy?.firstName || "") +
            " " +
            (res?.generatedBy?.lastName || ""),
          uploadedBy:
            (res?.uploadedBy?.firstName || "") +
            " " +
            (res?.uploadedBy?.lastName || ""),
        };
      },
    }),
    generateCO: builder.mutation<CommercialOffer, GenerateCORequestDto>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: () => [{ type: "CommercialOffer", id: "list" }],
    }),
    uploadSignedFile: builder.mutation<
      CommercialOffer,
      UploadSignedFileRequestDto
    >({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "CommercialOffer", id: "list" },
        { type: "CommercialOffer", id },
      ],
      transformResponse: (response: CommercialOffer) => {
        return response;
      },
    }),
    deleteCO: builder.mutation<{ messsage: string }, DeleteRequestDto>({
      query: ({ id }) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "CommercialOffer", id: "list" }],
    }),
  }),
});

export const {
  useGetCOsQuery,
  useGetCOQuery,
  useGenerateCOMutation,
  useUploadSignedFileMutation,
  useDeleteCOMutation,
} = commercialOffersApi;
