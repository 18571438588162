import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddCommoditycodeRequestDto,
  Commoditycode,
  EditCommoditycodeRequestDto,
} from "./types";

export const commoditycodesApi = createApi({
  reducerPath: "commoditycodesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/commoditycodes"),
    prepareHeaders,
  }),
  tagTypes: ["Commoditycode"],
  endpoints: (builder) => ({
    getCommoditycodes: builder.query<Commoditycode[], void>({
      query: () => "",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Commoditycode" as const,
                id,
              })),
              { type: "Commoditycode", id: "list" },
            ]
          : [{ type: "Commoditycode", id: "list" }],
    }),
    getCommoditycode: builder.query<Commoditycode, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "Commoditycode", id }],
    }),
    addCommoditycode: builder.mutation<
      Commoditycode,
      AddCommoditycodeRequestDto
    >({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [{ type: "Commoditycode", id: "list" }],
    }),
    editCommoditycode: builder.mutation<
      Commoditycode,
      EditCommoditycodeRequestDto
    >({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Commoditycode", id: "list" },
        { type: "Commoditycode", id },
      ],
    }),
    deleteCommoditycode: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "Commoditycode", id: "list" },
        { type: "Commoditycode", id },
      ],
    }),
  }),
});

export const {
  useGetCommoditycodesQuery,
  useGetCommoditycodeQuery,
  useAddCommoditycodeMutation,
  useEditCommoditycodeMutation,
  useDeleteCommoditycodeMutation,
} = commoditycodesApi;
