import React from "react";
import { Box, Divider, Typography } from "@mui/material";

type BlockProps = {
  label: string;
  value: string;
};

export const Block = ({ label, value }: BlockProps) => {
  return (
    <>
      <Divider
        sx={{
          height: "37px",
          alignSelf: "flex-end",
        }}
        orientation="vertical"
      />
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "4px" }}>
        <Typography
          component="span"
          sx={{
            fontSize: "10px",
            lineHeight: "14px",
            color: "secondary.main",
          }}
        >
          {label}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: "14px",
            lineHeight: "24px",
            color: "secondary.dark",
          }}
        >
          {value}
        </Typography>
      </Box>
    </>
  );
};
