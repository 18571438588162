import React, { memo } from "react";
import {
  Box,
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  styled,
} from "@mui/material";
import { SHOP_PAGE_SIZE } from "../consts";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";

export type PaginationProps = {
  count?: number;
  page: number;
  onChange: (page: number) => void;
};

export const Pagination = memo(({ count, page, onChange }: PaginationProps) => {
  const changeHandler: MuiPaginationProps["onChange"] = (_event, page) => {
    onChange(page);
  };

  if (typeof count !== "number") {
    return (
      <Container
        sx={{
          display: "flex",
          gap: "16px",
        }}
      >
        {Array.from(new Array(8)).map((_value, index) => (
          <EnhancedSkeleton
            key={index}
            variant="rectangular"
            sx={{ borderRadius: "4px", width: "32px", height: "32px" }}
          />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <MuiPagination
        count={Math.ceil(count / SHOP_PAGE_SIZE)}
        page={page}
        onChange={changeHandler}
        shape="rounded"
        variant="outlined"
        hidePrevButton
        hideNextButton
        // This may belong to the theme. I couldn't find other usages
        // of pagination that looked like this in Figma though, so I
        // only added these styles here.
        sx={{
          ".MuiPagination-ul": {
            gap: "16px",
          },

          ".MuiPaginationItem-root": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
            color: "secondary.main",
            border: "none",
            borderRadius: "4px",
            margin: 0,
          },

          ".MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "primary.light",
            color: "primary.main",
            borderColor: "primary.main",
            borderWidth: "1px",
            borderStyle: "solid",
          },

          ".MuiPaginationItem-root:hover": {
            backgroundColor: "transparent",
            color: "primary.dark",
          },
        }}
      />
    </Container>
  );
});

const Container = styled(Box)(({ theme }) => ({
  borderTopWidth: "1px",
  borderTopColor: theme.palette.secondary.light,
  borderTopStyle: "solid",
  borderBottomWidth: "1px",
  borderBottomColor: theme.palette.secondary.light,
  borderBottomStyle: "solid",
  paddingTop: "16px",
  paddingBottom: "16px",
  marginTop: "103px",
}));
