import React from "react";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
} from "@mui/material";
import { AutocompleteCountry } from "../../../../components/prefilledSelectors/AutocompleteCountry";
import {
  AddProductForm,
  EditProductForm,
  GetProductResponseDto,
} from "../../../../slices/products/types";
import { ControlledTextField } from "../../../../components/controlledFields/ControlledTextField";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { ControlledNumericField } from "../../../../components/controlledFields/ControlledNumericField";
import { ControlledColorField } from "../../../../components/controlledFields/ControlledColorField";
import { SelectCommodityCode } from "../../../../components/prefilledSelectors/SelectCommodityCode";
import { WysiwygField } from "../../../../components/fields/WysiwygField/WysiwygField";
import { Matches } from "./Matches";
import { Images } from "./Images/Images";
import { AutocompleteCategory } from "../../../../components/prefilledSelectors/AutocompleteCategory";
import { SelectCurrency } from "../../../../components/prefilledSelectors/SelectCurrency";
import { SelectTags } from "./customFields/SelectTags";
import { SelectSupplier } from "../../../../components/prefilledSelectors/SelectSupplier";
import { FreeAutocompleteSupplierCode } from "../../../../components/prefilledSelectors/FreeAutocompleteSupplierCode";
import { usePermissions } from "../../../../utils/usePermissions";
import { ControlledNumericCurrencyField } from "../../../../components/controlledFields/ControlledNumericCurrencyField";
import { SchoolLevelCheckboxGroup } from "./customFields/SchoolLevelCheckboxGroup";

export type FormProps = {
  data?: GetProductResponseDto;
  fieldsErrors?: FieldsErrorState;
  loadingMatches?: boolean;
  isActiveCheckboxShown?: boolean;
};

export const Form = ({
  data,
  fieldsErrors,
  loadingMatches,
  isActiveCheckboxShown = false,
}: FormProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { register, control } = useFormContext<
    AddProductForm | EditProductForm
  >();

  const watchSupplierIdSelect = useWatch({
    control,
    defaultValue: data?.supplier?.id,
    name: "supplierId",
  });

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {data && (
        <input
          type="hidden"
          {...register("id", {
            valueAsNumber: true,
          })}
        />
      )}
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="supplierId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <SelectSupplier
              label={`${t("products.fields.supplier")} *`}
              error={!!errorMessage}
              helperText={errorMessage}
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="supplierProductCode"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, onChange } }) => (
            <FreeAutocompleteSupplierCode
              id="item"
              onChange={onChange}
              value={(value as string) ?? ""}
              supplierId={watchSupplierIdSelect}
              error={!!errorMessage}
              helperText={errorMessage}
              label={`${t("products.fields.supplierProductCode")} *`}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledTextField
          control={control}
          name="name"
          label={t("products.fields.name")}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="description"
          render={(errorMessage, { field }) => (
            <WysiwygField
              label={t("products.fields.description")}
              tableEditing
              error={!!errorMessage}
              defaultValue={(data && data.description) ?? undefined}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="categoriesIds"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, ...field } }) => (
            <AutocompleteCategory
              id="product-category"
              label={t("products.fields.category")}
              error={!!errorMessage}
              helperText={errorMessage}
              value={Array.isArray(value) ? (value as number[]) : []}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 4">
        <ControlledNumericField
          control={control}
          name="packSize"
          label={t("products.fields.packSize")}
          fieldsErrors={fieldsErrors}
          positiveOnly
        />
      </Grid>
      <Grid item gridColumn="span 8">
        <ControlledColorField
          control={control}
          name="color"
          label={t("products.fields.color")}
          fieldsErrors={fieldsErrors}
          multiple={false}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="countryId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { onChange, value } }) => (
            <AutocompleteCountry
              id="school"
              label={t("products.fields.country")}
              onChange={onChange}
              value={value as number}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="commodityCodeId"
          render={(errorMessage, { field: { value, ...field } }) => (
            <SelectCommodityCode
              label={t("products.fields.commodityCode")}
              autocompleteProps={{
                value: (value as number) || -1,
                ...field,
              }}
              textFieldProps={{
                error: !!errorMessage,
                helperText: errorMessage,
              }}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledTextField
          control={control}
          name="replacementCommodityCode"
          label={t("products.fields.replacementCommodityCode")}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledNumericCurrencyField
          fullWidth
          control={control}
          name="supplierPrice"
          label={t("products.fields.supplierPrice")}
          fieldsErrors={fieldsErrors}
          positiveOnly
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="supplierCurrencyId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <SelectCurrency
              id="product-supplier-currency"
              label={t("products.fields.supplierCurrency")}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledNumericCurrencyField
          fullWidth
          control={control}
          name="retailPrice"
          label={t("products.fields.retailPrice")}
          fieldsErrors={fieldsErrors}
          positiveOnly
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="retailCurrencyId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <SelectCurrency
              id="product-retail-currency"
              label={t("products.fields.retailCurrency")}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledTextField
          control={control}
          name="weight"
          label={t("products.fields.weight")}
          fieldsErrors={fieldsErrors}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t("products.units.weight")}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledTextField
          control={control}
          name="volume"
          label={t("products.fields.volume")}
          fieldsErrors={fieldsErrors}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t("products.units.volume")}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item gridColumn="span 4">
        <ControlledTextField
          control={control}
          name="sizeW"
          label={t("products.fields.sizeW")}
          fieldsErrors={fieldsErrors}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t("products.units.WDH")}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item gridColumn="span 4">
        <ControlledTextField
          control={control}
          name="sizeD"
          label={t("products.fields.sizeD")}
          fieldsErrors={fieldsErrors}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t("products.units.WDH")}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item gridColumn="span 4">
        <ControlledTextField
          control={control}
          name="sizeH"
          label={t("products.fields.sizeH")}
          fieldsErrors={fieldsErrors}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t("products.units.WDH")}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <SelectTags fieldsErrors={fieldsErrors} />
      <SchoolLevelCheckboxGroup
        fieldsErrors={fieldsErrors}
        defaultValues={data?.schoolLevel || []}
      />
      <Matches fieldsErrors={fieldsErrors} loading={loadingMatches} />
      <Images />
      {isActiveCheckboxShown && (
        <Grid item gridColumn="span 3">
          <Controller
            control={control}
            name="availability"
            defaultValue={data ? data.availability : true}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={data ? data.availability : true} />
                }
                disabled={!can("changeProductActive")}
                label={t("products.fields.availability") as string}
                {...field}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
