import { useEffect, useState } from "react";

export const useDebounce = <T>(
  value: T,
  delay = 500,
  callback?: () => void
): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value === debouncedValue) {
        return;
      }

      setDebouncedValue(value);

      if (callback) {
        callback();
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, callback, debouncedValue]);

  return debouncedValue;
};
