import { Box } from "@mui/material";
import React from "react";

export const SignInBackground: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: "#FFFFFF",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          opacity: "3%",
          background:
            "linear-gradient(0deg, rgba(255,74,140,1) 0%, rgba(197,61,193,1) 33.3%, rgba(128,46,255,1) 66.7%, rgba(8,230,255,1) 100%)",
        }}
      />
    </>
  );
};
