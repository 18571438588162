import React from "react";

import { getDownloadUrl } from "../../../../utils/S3UploadFile";
import { GridRowId } from "@mui/x-data-grid";
import { saveBlob } from "../../../../utils/downloadFileFromServer";
import { FileIcon } from "../../../../components/FileIcon";
import { CircleButton } from "../../../../components/toolbar/CircleButton";
import { useTranslation } from "react-i18next";

export const DownloadFileCellBtn: React.FC<{
  po_id: GridRowId;
}> = ({ po_id }) => {
  const intId = typeof po_id === "string" ? parseInt(po_id) : po_id;
  const { t } = useTranslation("common");

  const onDownloadClick = () => {
    let filename: string;
    getDownloadUrl(intId, "po")
      .then((response) => response.json())
      .then((resData) => {
        filename = resData.filename;
        return fetch(resData.url, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        });
      })
      .then((fileResponse) => fileResponse.blob())
      .then((blob) => saveBlob(blob, filename));
  };
  return (
    <CircleButton onClick={onDownloadClick} tooltip={t("downloadFile")}>
      <FileIcon filename="pdf" />
    </CircleButton>
  );
};
