import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Autocomplete, FormControlProps, TextField } from "@mui/material";
import { PaperAutocompleteWithOptionalBorder } from "../layout/PaperPage";
import { getDefaultHeaders, getUrl } from "../../utils/api";
import { useSearchDebounce } from "../../utils/useSearchDebounce";
import { useTranslation } from "react-i18next";

type RelatedCode = {
  code: string;
  product: string;
};

type NewDesignFreeAutocompleteSupplierCodeProps = Omit<
  FormControlProps,
  "id" | "onChange" | "value"
> & {
  id: string;
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  setName?: (name: string) => void;
  supplierId: number | null;
  error?: boolean;
  helperText?: string;
};

export const NewDesignFreeAutocompleteSupplierCode: React.FC<NewDesignFreeAutocompleteSupplierCodeProps> =
  ({
    id,
    label,
    supplierId,
    error = false,
    helperText,
    onChange,
    setName,
    value,
  }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [relatedCodes, setRelatedCodes] = useState<RelatedCode[] | null>();

    const debouncedValue = useSearchDebounce(value || "");

    useEffect(() => {
      if (typeof supplierId === "number") {
        setLoading(true);
        fetch(
          getUrl(
            `/suppliers/${supplierId}/relatedCodes?${new URLSearchParams({
              search: debouncedValue,
            })}`
          ),
          {
            headers: getDefaultHeaders(true),
          }
        )
          .then((data) => data.json())
          .then((data: RelatedCode[]) => {
            setRelatedCodes(data);
            setLoading(false);
          });
      }
    }, [supplierId, debouncedValue]);

    const options = useMemo(() => {
      if (!relatedCodes) {
        return [];
      }

      return relatedCodes.map(({ code }) => code);
    }, [relatedCodes]);

    const onSelectItem =
      (relatedCode?: RelatedCode): MouseEventHandler =>
      () => {
        if (onChange) {
          onChange(relatedCode?.code ? relatedCode.code.toUpperCase() : "");
        }
        if (setName) {
          const relatedProductName = relatedCode?.product;

          if (relatedProductName) {
            setName(relatedProductName);
          }
        }
      };

    const inputChangeHandler = useCallback(
      (event, value: string | null) => {
        if (onChange) {
          onChange(value ? value.toUpperCase() : "");
        }
      },
      [onChange]
    );

    return (
      <Autocomplete
        freeSolo
        fullWidth
        id={`select-supplier-product-code-${id}`}
        PaperComponent={(props) => (
          <PaperAutocompleteWithOptionalBorder
            {...props}
            isOptions={options?.length > 0}
            inLoading={loading}
          />
        )}
        options={options}
        noOptionsText={t("emptySelect", { ns: "common" })}
        value={value}
        onInputChange={inputChangeHandler}
        loading={loading}
        loadingText={t("loading", { ns: "common" })}
        size="small"
        renderOption={(props, option) => {
          const relatedCode = relatedCodes?.find(
            (relatedCode) => relatedCode.code === option
          );
          return (
            <li {...props} key={option} style={{ ...props.style, padding: 0 }}>
              <p
                onClick={onSelectItem(relatedCode)}
                style={{
                  margin: 0,
                  padding: "6px 16px 6px 16px",
                  width: "100%",
                }}
              >
                {relatedCode?.product
                  ? `${relatedCode.code} - ${relatedCode.product}`
                  : relatedCode?.code || option}
              </p>
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={label}
              error={error}
              helperText={helperText}
            />
          );
        }}
      />
    );
  };
