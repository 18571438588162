import React from "react";
import { FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { deliveryTerms } from "../../../../types";
import { Control } from "react-hook-form";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { SendToAstraForm } from "../../../../slices/schoolInquiries/types";
import { StyledFieldTitle } from "../../../SignIn/StyledComponents";

type SelectDeliveryTermsProps = {
  control: Control<SendToAstraForm>;
  fieldsErrors?: FieldsErrorState;
};

export const SelectDeliveryTerms = ({
  fieldsErrors,
  control,
}: SelectDeliveryTermsProps) => {
  const { t } = useTranslation("pages");

  return (
    <Grid item gridColumn="span 12">
      <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
        {`${t(
          "schoolInquiryDetails.inquiryControls.drawer.fields.deliveryTerms"
        )}`}
      </StyledFieldTitle>
      <ControlledCustomField
        control={control}
        name="deliveryTerms"
        fieldsErrors={fieldsErrors}
        render={(errorMessage, { field }) => (
          <>
            <Select
              placeholder={t(
                "schoolInquiryDetails.inquiryControls.drawer.fields.deliveryTerms"
              )}
              {...field}
              size="small"
              fullWidth
            >
              {deliveryTerms.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errorMessage && (
              <FormHelperText error={!!errorMessage}>
                {errorMessage}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Grid>
  );
};
