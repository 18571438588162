import { createSlice } from "@reduxjs/toolkit";
import { AuthSliceState } from "./types";
import { fetchPermissions, logOut, signIn, validateToken } from "./actions";
import { SCHOOL_USER_ROLES } from "../../consts";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    user: null,
    permissions: null,
    isGreetingShown: false,
  } as AuthSliceState,
  reducers: {
    closeInvitation(state) {
      state.isGreetingShown = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateToken.fulfilled, (state, { payload }) => {
      if (!payload) {
        state.token = null;
        state.user = null;
      } else {
        state.token = payload.token;
        state.user = payload.user;
      }
    });

    builder.addCase(fetchPermissions.fulfilled, (state, { payload }) => {
      state.permissions = payload;
    });

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.user;
      state.isGreetingShown = SCHOOL_USER_ROLES.includes(payload.user.role);
    });

    builder.addCase(logOut.pending, (state) => {
      state.token = null;
      state.user = null;
      state.isGreetingShown = false;
    });
  },
});

export const {
  actions: { closeInvitation },
} = authSlice;
