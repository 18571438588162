import React from "react";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useGetCurrenciesQuery } from "../../slices/currencies/api";
import { LoadingTextField } from "../LoadingTextField";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store";
import {
  selectSelfRole,
  selectSelfSchoolCurrency,
} from "../../slices/auth/selectors";

export const SelectCurrency = ({
  qarOnly,
  onlySchoolCurrency,
  value = "",
  ...props
}: Omit<TextFieldProps, "select"> & {
  // Temporary measure
  qarOnly?: boolean;
  onlySchoolCurrency?: boolean;
}) => {
  const { data = [], isLoading } = useGetCurrenciesQuery();
  const { t } = useTranslation();

  const selfRole = useSelector(selectSelfRole);
  const selfSchoolCurrency = useSelector(selectSelfSchoolCurrency);

  return (
    <LoadingTextField select loading={isLoading} value={value} {...props}>
      {data
        .filter(({ code }) =>
          onlySchoolCurrency
            ? selfRole === "admin"
              ? true
              : code === selfSchoolCurrency?.code
            : qarOnly
            ? code === "QAR"
            : true
        )
        .map(({ id, code }) => (
          <MenuItem key={id} value={id}>
            {code}
          </MenuItem>
        ))}
      {data.length < 1 && (
        <MenuItem key={"empty-list"} disabled>
          {t("emptySelect", { ns: "common" })}
        </MenuItem>
      )}
    </LoadingTextField>
  );
};
