import { useDebounce } from "./useDebounce";

export const useSearchDebounce = (
  search: string,
  callback?: () => void
): string => {
  return useDebounce<string>(search, 500, callback);
};

export const useNumberSearchDebounce = (
  search: number,
  time?: number,
  callback?: () => void
): number => {
  return useDebounce<number>(search, time || 500, callback);
};
