import React from "react";
import { ContextMenu } from "../../../components/ContextMenu";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";

type ActionsProps = {
  id: number;
};

export const Actions = ({ id }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory();

  const can = usePermissions();

  return (
    <ContextMenu
      buttons={[
        {
          label: t("actions.edit", { ns: "common" }),
          handler: () => {
            history.push(`/inventory/${id}/edit`);
          },
          disabled: !can("editInventory"),
        },
      ]}
    />
  );
};
