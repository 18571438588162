import React, { useCallback, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useGetCountriesQuery } from "../../slices/countries/api";
import { useTranslation } from "react-i18next";

type CountriesAutocompleteProps = AutocompleteProps<number, true, false, false>;

type SelectCountriesProps = Omit<
  CountriesAutocompleteProps,
  | "value"
  | "onChange"
  | "options"
  | "renderInput"
  | "loading"
  | "loadingText"
  | "noOptionsText"
  | "multiple"
> &
  Pick<TextFieldProps, "label" | "error" | "helperText"> & {
    value: number[];
    onChange: (countriesIds: number[]) => void;
  };

export const SelectCountries = ({
  value,
  onChange,
  label,
  error,
  helperText,
  ...props
}: SelectCountriesProps) => {
  const { t } = useTranslation("components");

  const { data: countries = [], isLoading: areCountriesLoading } =
    useGetCountriesQuery();

  const options = useMemo(() => {
    if (!countries) {
      return [];
    }

    return countries.filter((country) => country.active).map(({ id }) => id);
  }, [countries]);

  const changeHandler = useCallback<
    NonNullable<CountriesAutocompleteProps["onChange"]>
  >(
    (_e, value) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  const getOptionLabel = useCallback<
    NonNullable<CountriesAutocompleteProps["getOptionLabel"]>
  >(
    (option) => countries?.find((country) => country.id === option)?.name ?? "",
    [countries]
  );

  const renderInput = useCallback<
    NonNullable<CountriesAutocompleteProps["renderInput"]>
  >(
    (params) => (
      <TextField
        {...params}
        label={label}
        error={error}
        helperText={helperText}
      />
    ),
    [error, helperText, label]
  );

  return (
    <Autocomplete
      value={value}
      onChange={changeHandler}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      loading={areCountriesLoading}
      loadingText={t("loading", { ns: "common" })}
      noOptionsText={t("emptySelect", { ns: "common" })}
      multiple
      {...props}
    />
  );
};
