export const displayFullName = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}) =>
  lastName && firstName
    ? `${firstName} ${lastName}`
    : lastName || firstName || "";
