import React, { memo } from "react";
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Link as MaterialLink,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { getShortText } from "../../utils/getShortText";

type PaperBreadcrumb = {
  href: LinkProps["to"];
  text: string;
};

export type PaperBreadcrumbsProps = {
  paths?: PaperBreadcrumb[];
  current?: string;
  paperProps?: PaperProps;
  breadcrumbsProps?: BreadcrumbsProps;
  endAdornment?: React.ReactNode;
};

export const PaperBreadcrumbs = memo(
  ({
    current,
    paths,
    paperProps: { sx: paperSx, ...paperProps } = {},
    breadcrumbsProps = {},
    endAdornment,
  }: PaperBreadcrumbsProps) => {
    return (
      <Paper
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          ...paperSx,
        }}
        {...paperProps}
      >
        <Breadcrumbs aria-label="breadcrumb" {...breadcrumbsProps}>
          {paths &&
            paths.map(({ href, text }, index) => (
              <MaterialLink component={Link} key={index} to={href}>
                {getShortText(text, 30)}
              </MaterialLink>
            ))}
          {current && <Typography>{getShortText(current, 30)}</Typography>}
        </Breadcrumbs>
        {endAdornment}
      </Paper>
    );
  }
);
