import React from "react";
import { useAdminSchoolInquiryTools } from "../hooks";
import { useSelector } from "../../../store";
import { selectSelfRole } from "../../../slices/auth/selectors";
import { HeadsList } from "./HeadsList/HeadsList";
import { TeachersList } from "./TeachersList/TeachersList";
import { PrincipalsList } from "./PrincipalsList/PrincipalsList";

export const Content = () => {
  const adminTool = useAdminSchoolInquiryTools();
  const selfRole = useSelector(selectSelfRole);

  const currentRole = adminTool?.userRole || selfRole;

  if (currentRole === "headOfDepartment") {
    return <HeadsList />;
  }

  if (currentRole === "teacher") {
    return <TeachersList />;
  }

  return <PrincipalsList />;
};
