import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFeedSuccess } from "../../../../../utils/feedHooks";
import { useYup } from "../../../../../utils/validation";
import { AddPurchaseOrderForm } from "../../../../../slices/purchaseOrders/types";
import {
  PurchaseOrderForm,
  purchaseOrderFormSchemaCreator,
} from "./PurchaseOrderForm/PurchaseOrderForm";
import { useAddPurchaseOrderMutation } from "../../../../../slices/purchaseOrders/api";
import { useSubmitFormErrorHander } from "../../../../../utils/formHelpers";
import { Form } from "../../../../../components/wideDrawer/Form";
import { useSelector } from "../../../../../store";
import {
  GetSigndedLinkResponseDto,
  getSignedLink,
  uploadFile,
} from "../../../../../utils/S3UploadFile";

type AddProps = {
  inquiryId: number;
};

export const Add: React.FC<AddProps> = ({ inquiryId }) => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(purchaseOrderFormSchemaCreator);
  const formMethods = useForm<AddPurchaseOrderForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addPurchaseOrder, { isLoading }] = useAddPurchaseOrderMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("purchaseOrders.errors.add")
  );

  const [lockSubmit, setLockSubmit] = useState(false);

  const closeDrawer = useCallback(
    () => history.push(`/inquiries/${inquiryId}/details/po`),
    [history, inquiryId]
  );
  const onSubmit = useCallback(
    ({ date, uploadedFile, ...form }: AddPurchaseOrderForm) => {
      if (!uploadedFile.file) {
        return;
      }

      setLockSubmit(true);

      getSignedLink(uploadedFile.file.name, uploadedFile.file.type, "po")
        .then((res: Response) => {
          if (!res.ok) {
            return Promise.reject();
          }
          return res.json();
        })
        .then((res: GetSigndedLinkResponseDto) => {
          if (!uploadedFile?.file || !res.key) {
            return Promise.reject();
          }
          return Promise.all([
            uploadFile(uploadedFile.file, res.signedUrl),
            Promise.resolve(res.key),
          ]);
        })
        .then(([res, key]: [Response, string]) => {
          if (!res.ok) {
            return Promise.reject();
          }
          if (!date) {
            date = new Date();
          }
          return addPurchaseOrder({
            date: date.setHours(12, 0, 0, 0).valueOf(),
            uploadedFileKey: key,
            ...form,
          }).unwrap();
        })
        .then(({ id }) => {
          feedSuccess(
            t("purchaseOrders.successes.add"),
            `/inquiries/${inquiryId}/details/po/${id}`
          );
          setLockSubmit(false);
          closeDrawer();
          reset();
        })
        .catch((error) => {
          setLockSubmit(false);
          catchFormError(error);
        });
    },
    [
      addPurchaseOrder,
      catchFormError,
      closeDrawer,
      feedSuccess,
      inquiryId,
      reset,
      t,
    ]
  );
  const userId = useSelector((state) => state.auth.user?.id);

  return (
    <Form
      heading={t("purchaseOrders.addPurchaseOrder")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading || lockSubmit}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <PurchaseOrderForm
          fieldsErrors={fieldsErrors}
          inquiryId={inquiryId}
          userId={userId || -1}
        />
      </FormProvider>
    </Form>
  );
};
