import React, { useMemo } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store";
import {
  selectIsCartOpen,
  selectShopList,
} from "../../../slices/shop/selectors";
import { ArrowRight } from "../../../components/astraIcons/ArrowRight";
import {
  useAssignmentBudget,
  useCurrentAssignmentId,
  useTotalAmount,
} from "../../../slices/shop/hooks";
import {
  selectSelfRole,
  selectSelfSchoolCurrency,
} from "../../../slices/auth/selectors";
import { Link } from "react-router-dom";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";
import { PriceCurrency } from "../../../components/PriceCurrency";

export const Checkout = () => {
  const { t } = useTranslation("layouts");

  const selfRole = useSelector(selectSelfRole);
  const { inquiryId, departmentId } = useSelector(selectShopList);
  const isCartOpen = useSelector(selectIsCartOpen);
  const budget = useAssignmentBudget();
  const { result: totalAmount, isLoading: isTotalAmountLoading } =
    useTotalAmount();
  const assignmentId = useCurrentAssignmentId();
  const schoolCurrency = useSelector(selectSelfSchoolCurrency);

  const currency = schoolCurrency
    ? schoolCurrency?.code?.toUpperCase()
    : t("currencies.qar", { ns: "common" });
  const verticalPadding = isCartOpen ? "20px" : "8px";

  const reviewLink = useMemo(() => {
    return `/schoolinquiries/${inquiryId}/details/${departmentId}/${
      selfRole === "teacher" ? "items" : `assignments/${assignmentId}`
    }`;
  }, [assignmentId, departmentId, inquiryId, selfRole]);

  return (
    <Box
      sx={{
        paddingTop: verticalPadding,
        paddingRight: "16px",
        paddingBottom: verticalPadding,
        paddingLeft: "16px",
        backgroundColor: "common.white",
        zIndex: 1,
        position: "relative",
        marginTop: "auto",
      }}
    >
      {isCartOpen ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Row>
            <Label as="span">{t("shop.yourBudget")}:</Label>
            <PriceCurrency
              price={budget}
              currency={currency}
              sx={{ color: "secondary.dark" }}
            />
          </Row>
          <Row>
            <Label
              as="span"
              sx={{
                color: "primary.dark",
                fontSize: "12px",
                lineHeight: "24px",
              }}
            >
              {t("shop.totalAmount")}:
            </Label>
            <PriceCurrency
              price={totalAmount}
              currency={currency}
              sx={{ color: "primary.dark" }}
              priceProps={{ sx: { fontSize: "20px" } }}
              currencyProps={{ sx: { fontSize: "14px", lineHeight: "24px" } }}
              showPriceZero
            />
          </Row>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Label as="span">{t("shop.total")}</Label>
          {isTotalAmountLoading ? (
            <EnhancedSkeleton
              variant="text"
              sx={{ lineHeight: "33px" }}
              width={[50, 80, "%"]}
            />
          ) : (
            <PriceCurrency
              sx={{
                color: "primary.dark",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "3px",
                textAlign: "center",
              }}
              price={totalAmount}
              currency={currency}
              currencyProps={{
                sx: {
                  lineHeight: "10px",
                },
              }}
              priceProps={{
                sx: {
                  whiteSpace: "nowrap",
                  width: "80px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              }}
              showPriceZero
              tooltip
            />
          )}
        </Box>
      )}
      <Button
        variant="contained"
        sx={{
          marginTop: "18px",
          width: isCartOpen ? "100%" : "64px",
          height: "64px",

          ".MuiButton-startIcon": {
            ...(isCartOpen
              ? {
                  position: "absolute",
                  left: "20px",
                }
              : {
                  margin: 0,
                }),

            svg: {
              width: "24px",
              height: "24px",
            },
          },
        }}
        startIcon={<ArrowRight fontSize="medium" />}
        component={Link}
        to={{
          pathname: reviewLink,
          state: {
            scrollToTop: true,
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            display: isCartOpen ? "inline" : "none",
          }}
        >
          {t("shop.reviewAndSubmit")}
        </Typography>
      </Button>
    </Box>
  );
};

const Row = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "10px",
  lineHeight: "24px",
}));
