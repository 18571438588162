import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { Currency, CurrencyExchangeRate } from "./types";

export const currenciesApi = createApi({
  reducerPath: "currenciesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/currency"),
    prepareHeaders,
  }),
  refetchOnMountOrArgChange: 4 * 60 * 60, // 4 hours in seconds
  endpoints: (builder) => ({
    getCurrencies: builder.query<Currency[], void>({
      query: () => "",
    }),
    getCurrencyExchangeRates: builder.query<CurrencyExchangeRate[], void>({
      query: () => "/exchangeRates",
    }),
  }),
});

export const { useGetCurrenciesQuery, useGetCurrencyExchangeRatesQuery } =
  currenciesApi;
