import React, { useCallback } from "react";
import { ListItemIcon, Menu, MenuItem, styled, SvgIcon } from "@mui/material";
import {
  AccountTree,
  BusinessCenter,
  CurrencyExchange,
  Public,
  QrCode2,
  School,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../utils/usePermissions";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ReactComponent as DepartmentSvg } from "../../assets/department.svg";

type GearOptionsProps = {
  open: boolean;
  anchorEl: null | HTMLElement;
  close: () => void;
};

export const GearOptions: React.FC<GearOptionsProps> = ({
  open,
  anchorEl,
  close,
}) => {
  const { t: pagesTranslation } = useTranslation("pages");
  const can = usePermissions();
  const history = useHistory();

  const pathMatcher = useRouteMatch<{ path: string }>("/:path");
  const path = pathMatcher?.params?.path;

  const onClick = useCallback(
    (path: string) => {
      close();
      history.push(path);
    },
    [history, close]
  );

  return (
    <Menu
      id="row-actions-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={close}
      style={{ marginTop: 5 }}
    >
      {can("getSchools") && (
        <StyledMenuItem
          onClick={() => onClick("/schools")}
          selected={path === "schools"}
        >
          <ListItemIcon>
            <School />
          </ListItemIcon>
          {pagesTranslation("schools.title")}
        </StyledMenuItem>
      )}
      {can("getDepartments") && can("viewDepartmentsMenu") && (
        <StyledMenuItem
          onClick={() => onClick("/departments")}
          selected={path === "departments"}
        >
          <ListItemIcon>
            <SvgIcon component={DepartmentSvg} viewBox="0 0 100 100" />
          </ListItemIcon>
          {pagesTranslation("departments.title")}
        </StyledMenuItem>
      )}
      {can("getSuppliers") && can("viewSupplierMenu") && (
        <StyledMenuItem
          onClick={() => onClick("/suppliers")}
          selected={path === "suppliers"}
        >
          <ListItemIcon>
            <BusinessCenter />
          </ListItemIcon>
          {pagesTranslation("suppliers.title")}
        </StyledMenuItem>
      )}
      {can("getCategories") && can("showCategories") && (
        <StyledMenuItem
          onClick={() => onClick("/categories")}
          selected={path === "categories"}
        >
          <ListItemIcon>
            <AccountTree />
          </ListItemIcon>
          {pagesTranslation("categories.title")}
        </StyledMenuItem>
      )}
      {can("getCommoditycodes") && (
        <StyledMenuItem
          onClick={() => onClick("/commoditycodes")}
          selected={path === "commoditycodes"}
        >
          <ListItemIcon>
            <QrCode2 />
          </ListItemIcon>
          {pagesTranslation("commoditycodes.title")}
        </StyledMenuItem>
      )}
      {can("getCountries") && (
        <StyledMenuItem
          onClick={() => onClick("/countries")}
          selected={path === "countries"}
        >
          <ListItemIcon>
            <Public />
          </ListItemIcon>
          {pagesTranslation("countries.title")}
        </StyledMenuItem>
      )}
      {can("viewCurrencies") && (
        <StyledMenuItem
          onClick={() => onClick("/currencies")}
          selected={path === "currencies"}
        >
          <ListItemIcon>
            <CurrencyExchange />
          </ListItemIcon>
          {pagesTranslation("currencies.title")}
        </StyledMenuItem>
      )}
    </Menu>
  );
};

const StyledMenuItem = styled(MenuItem)({
  paddingTop: "10px",
  paddingBottom: "10px",
});
