import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { Button } from "@mui/material";
import { useSendSchoolDepartmentInquiryToReviewMutation } from "../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useSelector } from "../../../../../store";
import { Hint } from "../../../../../components/hints/Hint";
import { selectSelfRole } from "../../../../../slices/auth/selectors";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";

export const NeedReview = () => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const selfRole = useSelector(selectSelfRole);

  const {
    inquiryResult: { data: inquiry },
    inquiryId,
    departmentInquiryId,
  } = useSchoolInquiryItemsTools();

  const departmentInquiry = useMemo(
    () =>
      inquiry?.departmentinquiries.find(({ id }) => id === departmentInquiryId),
    [departmentInquiryId, inquiry?.departmentinquiries]
  );

  const [sendToReview, { isLoading: isSending }] =
    useSendSchoolDepartmentInquiryToReviewMutation();

  const clickHandler = useCallback(() => {
    if (departmentInquiryId) {
      sendToReview({ inquiryId, departmentInquiryId })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    sendToReview,
  ]);

  const disabled = useMemo(() => {
    return (
      departmentInquiry?.schoolStatus !== "approvedByPrincipal" &&
      departmentInquiry?.schoolStatus !== "approvedByHead"
    );
  }, [departmentInquiry?.schoolStatus]);

  if (selfRole !== "principal") {
    return null;
  }

  return (
    <Hint
      helpText={t("hints.schoolInquiryDetails.departmentControls.needReview")}
      hide={
        !departmentInquiry ||
        !inquiry ||
        departmentInquiry.schoolStatus === "cancelled" ||
        inquiry.schoolStatus === "sentToAstra"
      }
    >
      <ConfirmationButton
        component={Button}
        variant="outlined"
        onClick={clickHandler}
        confirmationTitle={t(
          "schoolInquiryDetails.departmentControls.needReview.confirmationTitle"
        )}
        confirmationDescription={t("noUndo", { ns: "common" })}
        loading={isSending}
        disabled={disabled}
        sx={{
          textTransform: "capitalize",
          fontSize: "13px",
        }}
      >
        {t("schoolInquiryDetails.departmentControls.needReview.label")}
      </ConfirmationButton>
    </Hint>
  );
};
