import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "../../../components/wideDrawer/Form";
import { useAddItemMutation } from "../../../slices/items/api";
import { ItemForm, itemFormSchemaCreator } from "./ItemForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { AddItemRequestDto } from "../../../slices/items/types";
import { useItemsTableBaseUrl } from "../hooks";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";

export const Add = () => {
  const { t } = useTranslation("pages");
  const { baseUrl, inquiryId, detailId } = useItemsTableBaseUrl();
  const { closeDrawer } = useTableUrlTools(baseUrl);
  const feedSuccess = useFeedSuccess();
  const schema = useYup(itemFormSchemaCreator);
  const formMethods = useForm<AddItemRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addItem, { isLoading }] = useAddItemMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("items.errors.add")
  );

  const onSubmit = useCallback(
    (form: AddItemRequestDto) => {
      addItem({ ...form, inquiryId })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("items.successes.add"), `${baseUrl}/${id}/details`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [
      addItem,
      baseUrl,
      catchFormError,
      closeDrawer,
      feedSuccess,
      inquiryId,
      reset,
      t,
    ]
  );

  return (
    <Form
      heading={t("items.add")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <ItemForm fieldsErrors={fieldsErrors} departmentInquiryId={detailId} />
      </FormProvider>
    </Form>
  );
};
