import React from "react";
import { useGetInquiryDetailsQuery } from "../../../slices/inquiries/api";
import { useTranslation } from "react-i18next";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { GroupsSwitch } from "../GroupsSwitch";
import { useInquiryDetailsUrlTools } from "../hooks";
import { InquiryData } from "./InquiryData";
import { Button, Typography } from "@mui/material";
import { useBasicDetailsUrlTools } from "./useBasicDetailsUrlTools";
import { Box } from "@mui/system";
import { usePermissions } from "../../../utils/usePermissions";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { Edit } from "./Drawers/Edit";
import { EditCO } from "./Drawers/EditCO";
import { Link } from "react-router-dom";
import { InquiryCOData } from "./InquiryCOData";
import { useHeaderTitle } from "../../../slices/ui/useHeaderTitle";
import { displayFullName } from "../../../utils/displayFullName";

export const BasicDetails = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { inquiryId, baseUrl } = useInquiryDetailsUrlTools();

  const { editCODrawerOpen, editDrawerOpen, closeDrawer } =
    useBasicDetailsUrlTools(`${baseUrl}/details`);

  const {
    isLoading,
    isFetching,
    data: inquiryData,
    error,
    refetch,
  } = useGetInquiryDetailsQuery(inquiryId);

  useHeaderTitle(inquiryData?.name ?? t("inquiryDetails.title"));

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (isLoading || !inquiryData) {
    return <PaperPageSpinner />;
  }

  return (
    <>
      <PaperPage
        breadcrumbs={{
          paths: [
            { href: "/inquiries", text: t("inquiries.title") },
            { href: baseUrl, text: inquiryData.name },
          ],
          current: t("inquiryDetails.groups.details.label"),
          endAdornment: <GroupsSwitch />,
        }}
      >
        <Typography variant="body1" gutterBottom>
          <b>{t("inquiryDetails.basicDetails")}</b>
        </Typography>
        <InquiryData data={inquiryData} />
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiryDetails.departments")}
        </Typography>
        <Box sx={{ marginBottom: "8px" }}>
          {inquiryData.relatedDepartmentsInquiries
            .map((department) => department.name)
            .join(", ")}
        </Box>
        {inquiryData.inquiryManagers.length > 0 && (
          <>
            <Typography variant="subtitle2" gutterBottom>
              {t("inquiryDetails.inquiryManagers")}
            </Typography>
            <Box sx={{ marginBottom: "8px" }}>
              {inquiryData.inquiryManagers
                .map(({ firstName, lastName }) =>
                  displayFullName({ firstName, lastName })
                )
                .join(", ")}
            </Box>
          </>
        )}
        <Button
          sx={{ width: "100px", marginBottom: "8px" }}
          component={Link}
          to={`${baseUrl}/details/edit`}
          disabled={!can("addInquiry")}
        >
          {t("actions.edit", { ns: "common" })}
        </Button>
        <Typography variant="body1" gutterBottom>
          <b>{t("inquiryDetails.codetails")}</b>
        </Typography>
        <InquiryCOData data={inquiryData} />
        <Button
          sx={{ width: "100px", marginBottom: "8px" }}
          component={Link}
          to={`${baseUrl}/details/editco`}
          disabled={!can("editInquiry")}
        >
          {t("actions.edit", { ns: "common" })}
        </Button>
        {inquiryData.note && (
          <>
            <Typography variant="body1" gutterBottom>
              <b>{t("inquiryDetails.principalNote.title")}</b>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {t("inquiryDetails.principalNote.noteSubtitle")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-line",
                "& p:first-child": { marginTop: 0 },
              }}
              dangerouslySetInnerHTML={{ __html: inquiryData.note }}
            />
          </>
        )}
      </PaperPage>
      {can("editInquiry") && (
        <>
          <WideDrawer open={editDrawerOpen} close={closeDrawer}>
            <Edit />
          </WideDrawer>
          <WideDrawer open={editCODrawerOpen} close={closeDrawer}>
            <EditCO />
          </WideDrawer>
        </>
      )}
    </>
  );
};
