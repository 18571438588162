import React, { forwardRef, useEffect, useState } from "react";
import { useGetCategoriesQuery } from "../../slices/categories/api";
import {
  Autocomplete,
  FormControl,
  FormControlProps,
  FormHelperText,
  TextField,
} from "@mui/material";
import { CategoryLevel } from "../../slices/categories/types";
import { PaperPageSelect } from "../layout/PaperPage";
import { useTranslation } from "react-i18next";

const EMPTY_VALUE = {
  id: -1,
  name: "",
};

type SelectParentCategoryProps = FormControlProps & {
  id: string;
  label: string;
  helperText?: string;
  itemLevel: CategoryLevel;
  utilOnChange: (value: number) => void;
  defaultValue: number;
  currentId?: number;
};

export const AutocompleteParentCategory: React.FC<SelectParentCategoryProps> =
  forwardRef(
    (
      {
        id,
        label,
        helperText,
        utilOnChange,
        itemLevel,
        currentId,
        defaultValue,
        ...props
      },
      ref
    ) => {
      const { t } = useTranslation();
      const { data = [], isLoading } = useGetCategoriesQuery();

      const [selectedValue, setSelectedValue] = useState<{
        id: number;
        name: string;
      }>(EMPTY_VALUE);

      useEffect(() => {
        utilOnChange(-1);
        setSelectedValue(EMPTY_VALUE);
        // Do not erase the eslint-disable-next-line comment below. Linter wants to add utilOnChange as a dependency,
        // but this will cause the field to be overwritten after changing the field itself
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [itemLevel, setSelectedValue]);

      useEffect(() => {
        const defaultCategory = data.find(({ id }) => id === defaultValue);
        if (!defaultCategory) {
          setSelectedValue(EMPTY_VALUE);
          utilOnChange(-1);
        } else {
          const { name, parentCategory } = defaultCategory;
          setSelectedValue({
            id: defaultValue,
            name: parentCategory ? `${parentCategory} / ${name}` : name,
          });
          utilOnChange(defaultValue);
        }
        // Do not erase the eslint-disable-next-line comment below. Linter wants to add utilOnChange as a dependency,
        // but this will cause the field to be overwritten after changing the field itself
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue, setSelectedValue]);

      const handleChange = (
        e: React.SyntheticEvent<Element, Event>,
        value: {
          id: number;
          name: string;
        } | null
      ) => {
        if (!value) {
          return null;
        }
        setSelectedValue(value);
        utilOnChange(value.id);
      };

      const allowedLevel = ((
        itemLevel: CategoryLevel
      ): CategoryLevel | undefined => {
        if (itemLevel === "2") {
          return "1";
        }
      })(itemLevel);

      const allowedCategories = data
        .filter(
          (category) =>
            allowedLevel &&
            category.level === allowedLevel &&
            category.id !== currentId
        )
        .map(({ name, id, parentCategory }) => {
          return {
            name: parentCategory ? `${parentCategory} / ${name}` : name,
            id,
          };
        });

      return (
        <FormControl fullWidth ref={ref} {...props}>
          <Autocomplete
            PaperComponent={PaperPageSelect}
            id={`select-parent-categorie-${id}`}
            size="small"
            loading={isLoading}
            loadingText={t("loading", { ns: "common" })}
            options={allowedCategories}
            noOptionsText={t("emptySelect", { ns: "common" })}
            value={selectedValue.id !== -1 ? selectedValue : null}
            onChange={handleChange}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => {
              return <TextField {...params} label={label} />;
            }}
          />
          {helperText && (
            <FormHelperText error={props.error}>{helperText}</FormHelperText>
          )}
        </FormControl>
      );
    }
  );
