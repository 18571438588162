import React, { memo, useCallback } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { IconButton } from "../astraComponents/IconButton";
import { Minus } from "../astraIcons/Minus";
import { Plus } from "../astraIcons/Plus";

export type ChangeType = "click" | "type";

export type NumericFieldProps = Omit<TextFieldProps, "onChange" | "value"> & {
  value: number | string | null;
  onChange: (value: number | string | null, type: ChangeType) => void;
  name: string;
  helperText?: string;
  positiveOnly?: boolean;
};

export const NumericField = memo(
  ({
    value,
    onChange,
    name,
    error,
    helperText,
    label,
    positiveOnly,
    onBlur,
    disabled,
    size,
    ...props
  }: NumericFieldProps) => {
    const addHandler = useCallback(() => {
      onChange(
        typeof value !== "number" || isNaN(value) ? 1 : value + 1,
        "click"
      );
    }, [onChange, value]);

    const subtractHandler = useCallback(() => {
      const newValue =
        typeof value !== "number" || isNaN(value) ? -1 : value - 1;

      if (positiveOnly && newValue < 0) {
        return;
      }

      onChange(newValue, "click");
    }, [onChange, positiveOnly, value]);

    const changeHandler = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (event) => {
        const newValue = event.target.value;

        if (newValue === "") {
          return onChange(null, "type");
        }

        const parsedNewValue = parseFloat(newValue);

        if (positiveOnly && parsedNewValue < 0) {
          return;
        }

        onChange(newValue, "type");
      },
      [onChange, positiveOnly]
    );

    return (
      <TextField
        inputMode="numeric"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                variant="transparent"
                onClick={subtractHandler}
                disabled={
                  (positiveOnly && typeof value === "number" && value < 2) ||
                  (positiveOnly && value === "") ||
                  (positiveOnly && value === null) ||
                  disabled
                }
              >
                <Minus />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                variant="transparent"
                onClick={addHandler}
                disabled={disabled}
              >
                <Plus />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        value={value ?? ""}
        onChange={changeHandler}
        error={error}
        helperText={helperText}
        name={name}
        label={label}
        onBlur={onBlur}
        size={size}
        {...props}
      />
    );
  }
);

/**
 * @deprecated
 */
export type LegacyNumericFieldProps = Omit<
  TextFieldProps,
  "onChange" | "value"
> & {
  value: number | string | null;
  onChange: (value: number | string | null, type: ChangeType) => void;
  name: string;
  helperText?: string;
  positiveOnly?: boolean;
};

/**
 * @deprecated
 */
export const LegacyNumericField = memo(
  ({
    value,
    onChange,
    name,
    error,
    helperText,
    label,
    positiveOnly,
    onBlur,
    disabled,
    size,
    ...props
  }: LegacyNumericFieldProps) => {
    const addHandler = useCallback(() => {
      onChange(
        typeof value !== "number" || isNaN(value) ? 1 : value + 1,
        "click"
      );
    }, [onChange, value]);

    const subtractHandler = useCallback(() => {
      const newValue =
        typeof value !== "number" || isNaN(value) ? -1 : value - 1;

      if (positiveOnly && newValue < 0) {
        return;
      }

      onChange(newValue, "click");
    }, [onChange, positiveOnly, value]);

    const changeHandler = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (event) => {
        const newValue = event.target.value;

        if (newValue === "") {
          return onChange(null, "type");
        }

        const parsedNewValue = parseFloat(newValue);

        if (positiveOnly && parsedNewValue < 0) {
          return;
        }

        onChange(newValue, "type");
      },
      [onChange, positiveOnly]
    );

    return (
      <TextField
        inputMode="numeric"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                size={size}
                onClick={subtractHandler}
                variant="transparent"
                disabled={
                  (positiveOnly && typeof value === "number" && value < 2) ||
                  (positiveOnly && value === "") ||
                  (positiveOnly && value === null) ||
                  disabled
                }
              >
                <Remove />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                variant="transparent"
                size={size}
                onClick={addHandler}
                disabled={disabled}
              >
                <Add />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        value={value ?? ""}
        onChange={changeHandler}
        error={error}
        helperText={helperText}
        name={name}
        label={label}
        onBlur={onBlur}
        size={size}
        {...props}
      />
    );
  }
);
