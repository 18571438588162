import { Box } from "@mui/material";
import React from "react";
import { StyledLink, StyledTextBeforeLink } from "../StyledComponents";
import { useTranslation } from "react-i18next";

export const BackToSignIn: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        flexWrap: "nowrap",
        marginTop: "0px !important",
        marginBottom: "-15px !important",
        justifyContent: "right",
      }}
    >
      <StyledTextBeforeLink>
        {t("forgotpassword.rememberpassword", { ns: "pages" })}
      </StyledTextBeforeLink>
      <StyledLink to={`/`}>
        {t("forgotpassword.rememberpasswordLink", { ns: "pages" })}
      </StyledLink>
    </Box>
  );
};
