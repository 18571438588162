import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { enGB } from "date-fns/locale";
import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/lab";
import { TextField } from "@mui/material";

export type DateFieldProps = Omit<
  DatePickerProps<Date>,
  "PopperProps" | "renderInput"
> & {
  helperText?: string;
  error?: boolean;
};

export const DateField = ({ helperText, error, ...props }: DateFieldProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
    <DatePicker
      PopperProps={{ placement: "bottom-start" }}
      renderInput={({ ...params }) => {
        return (
          <TextField
            helperText={helperText}
            fullWidth
            size="small"
            error={error}
            {...params}
          />
        );
      }}
      {...props}
    />
  </LocalizationProvider>
);
