import React, { memo } from "react";
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Circular } from "./spinners/Circular";

export type NewDesignSubmitSpinnerButtonProps = Omit<
  ButtonProps,
  "type" | "variant"
> & {
  loading?: boolean;
};

export const NewDesignSubmitSpinnerButton =
  memo<NewDesignSubmitSpinnerButtonProps>(
    ({ loading, disabled, children, ...props }) => {
      return (
        <ButtonBox sx={{ marginTop: "60px !important" }}>
          <Button
            variant="contained"
            type="submit"
            disabled={loading || disabled}
            {...props}
          >
            {children}
          </Button>
          {loading && (
            <SpinnerBox>
              <Circular size={25} thickness={175} />
            </SpinnerBox>
          )}
        </ButtonBox>
      );
    }
  );

const ButtonBox = styled(Box)({
  display: "flex",
});

const SpinnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(1),
}));
