import React, { forwardRef, useMemo } from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useGetDepartmentsQuery } from "../../slices/departments/api";
import { useTranslation } from "react-i18next";

type NewDesignSelectDepartmentProps = Omit<
  FormControlProps,
  "id" | "onChange" | "ref"
> & {
  label: string;
  selectProps: SelectProps;
  id: string;
  schoolId?: number;
  helperText?: string;
  publicOnly?: boolean;
};

export const NewDesignSelectDepartment = forwardRef(
  (
    {
      label,
      selectProps,
      id,
      helperText,
      publicOnly,
      ...props
    }: NewDesignSelectDepartmentProps,
    ref: FormControlProps["ref"]
  ) => {
    const { t } = useTranslation();

    const { data: departments = [], isLoading } = useGetDepartmentsQuery({
      publicOnly,
    });

    const list = useMemo(
      () =>
        [...departments].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        }),
      [departments]
    );

    return (
      <FormControl ref={ref} {...props}>
        <Select
          labelId={`custom-department-select-${id}-label`}
          id={`custom-department-select-${id}`}
          placeholder={label}
          disabled={isLoading}
          {...selectProps}
        >
          {list.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
          {list.length < 1 && (
            <MenuItem key={"empty-list"} disabled>
              {t("emptySelect", { ns: "common" })}
            </MenuItem>
          )}
        </Select>
        {helperText && (
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
