import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddCategoryRequestDto,
  Category,
  CategoryFlattened,
  EditCategoryRequestDto,
} from "./types";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/categories"),
    prepareHeaders,
  }),
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    getCategories: builder.query<CategoryFlattened[], void>({
      query: () => "",
      transformResponse: (response: Category[]) =>
        response.map((category) => {
          const parentCategory = category?.parentCategory?.name || "";
          const firstParentCategory =
            category?.parentCategory?.parentCategory?.name || "";
          return {
            ...category,
            parentCategory,
            parentCategoryId: category?.parentCategory?.id || -1,
            firstParentCategory,
            firstParentCategoryId:
              category?.parentCategory?.parentCategory?.id || -1,
            nameForSort: `${firstParentCategory}${parentCategory}${category.name}`,
          };
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Category" as const,
                id,
              })),
              { type: "Category", id: "list" },
            ]
          : [{ type: "Category", id: "list" }],
    }),
    getCategory: builder.query<CategoryFlattened, number>({
      query: (id) => `/${id}`,
      transformResponse: (category: Category) => ({
        ...category,
        parentCategory: category?.parentCategory?.name || "",
        firstParentCategory:
          category?.parentCategory?.parentCategory?.name || "",
        nameForSort: category.name,
        parentCategoryId: category?.parentCategory?.id ?? -1,
      }),
      providesTags: (result, error, id) => [{ type: "Category", id }],
    }),
    addCategory: builder.mutation<Category, AddCategoryRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [{ type: "Category", id: "list" }],
    }),
    editCategory: builder.mutation<Category, EditCategoryRequestDto>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Category", id: "list" },
        { type: "Category", id },
      ],
    }),
    deleteCategory: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "Category", id: "list" },
        { type: "Category", id },
      ],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
