import React, { useCallback, useEffect, useState } from "react";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form as ProductForm } from "./Form/Form";
import {
  useEditProductMutation,
  useGetProductQuery,
} from "../../../slices/products/api";
import {
  EditProductForm,
  GetProductResponseDto,
} from "../../../slices/products/types";
import { editProductFormSchemaCreator } from "./Form/schema";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { mapMatches, uploadProductImages } from "./utils";

export const Edit: React.FC = () => {
  const { id, closeDrawer } = useTableUrlTools("/products");
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(editProductFormSchemaCreator);
  const formMethods = useForm<EditProductForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const {
    isLoading: isDataLoading,
    data,
    error: dataLoadingError,
  } = useGetProductQuery(id ? { id, rawImages: true } : skipToken);
  const [editProduct, { isLoading: isEditLoading }] = useEditProductMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("products.errors.edit")
  );

  useEffect(() => {
    if (data) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        createdAt,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updatedAt,
        supplier,
        supplierProductCode,
        categories,
        country,
        commodityCode,
        images,
        supplierCurrency,
        retailCurrency,
        tags,
        matches,
        retailPrice,
        supplierPrice,
        ...values
      }: GetProductResponseDto = data;

      reset({
        supplierId: supplier.id,
        matches: mapMatches(matches, supplier.id, supplierProductCode),
        categoriesIds: categories.map(({ id }) => id),
        countryId: country?.id,
        commodityCodeId: commodityCode?.id,
        images: images.map((url) => ({
          s3key: url,
          fileList: null,
        })),
        supplierCurrencyId: supplierCurrency?.id,
        retailCurrencyId: retailCurrency?.id,
        tagsNames: tags.map(({ name }) => name),
        supplierProductCode,
        retailPrice: retailPrice && retailPrice / 100,
        supplierPrice: supplierPrice && supplierPrice / 100,
        ...values,
      });
    }
  }, [data, reset]);

  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const onSubmit = useCallback(
    ({ images, ...form }: EditProductForm) => {
      // The loop is clearly redundant, but it's harder to type it's properly
      // and there's never gonna be enough entries for this to become an issue
      // anyway
      const files = images
        .map(({ fileList }) => fileList)
        .filter((fileList): fileList is FileList => !!fileList)
        .map((fileList) => fileList[0]);

      const existingS3Keys = images
        .map(({ s3key }) => s3key)
        .filter((s3key): s3key is string => !!s3key);

      setIsUploadingImages(true);
      uploadProductImages(files)
        .then((newS3Keys) => {
          setIsUploadingImages(false);
          return editProduct({
            images: [...existingS3Keys, ...newS3Keys],
            ...form,
          }).unwrap();
        })
        .then(() => {
          feedSuccess(t("products.successes.edit"), `/products/${id}`);
          closeDrawer();
        })
        .catch((error) => {
          setIsUploadingImages(false);
          catchFormError(error);
        });
    },
    [catchFormError, closeDrawer, editProduct, feedSuccess, id, t]
  );

  useEffect(() => {
    if (dataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, dataLoadingError]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }

  const isSubmitting = isUploadingImages || isEditLoading;

  return (
    <Form
      heading={t("products.edit")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <ProductForm
          data={data}
          fieldsErrors={fieldsErrors}
          isActiveCheckboxShown={true}
        />
      </FormProvider>
    </Form>
  );
};
