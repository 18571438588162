import React, { useCallback, useEffect } from "react";
import { createYupSchema, useYup } from "../../utils/validation";
import * as yup from "yup";
import {
  useAssignMainOrderMutation,
  useGetItemQuery,
} from "../../slices/items/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useFeedSuccess } from "../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../utils/formHelpers";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { AssignItemsMainOrderRequestDto } from "../../slices/items/types";
import { MessageResponseDto, Noop } from "../../types";
import { Spinner } from "../../components/wideDrawer/Spinner";
import { Form } from "../../components/wideDrawer/Form";
import { Grid } from "@mui/material";
import { SelectMainOrder } from "./SelectMainOrder";
import { useQueryIds } from "../../utils/useQueryIds";

const schemaCreator = createYupSchema((t) =>
  yup.object({
    mainOrderId: yup.number().positive(t("required")).required(t("required")),
  })
);

export type AssignMainOrderForm = AssignItemsMainOrderRequestDto;

type AssignMainOrderProps = {
  inquiryId: number;
  closeDrawer: Noop;
};

export const AssignMainOrder = ({
  inquiryId,
  closeDrawer,
}: AssignMainOrderProps) => {
  const { t } = useTranslation("pages");
  const ids = useQueryIds();

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("items.errors.assignMainOrder")
  );
  const schema = useYup(schemaCreator);
  const { control, handleSubmit, reset } = useForm<AssignMainOrderForm>({
    resolver: yupResolver(schema),
  });

  const [assign, { isLoading: isAssigning }] = useAssignMainOrderMutation();
  const { data: item } = useGetItemQuery(
    !ids || ids.length > 1 ? skipToken : ids[0]
  );

  useEffect(() => {
    if (ids && ids.length === 1 && item && item.mainOrder) {
      reset({
        mainOrderId: item.mainOrder.id,
      });
    }
  }, [ids, item, reset]);

  const onSubmit = useCallback(
    ({ mainOrderId }: AssignMainOrderForm) => {
      if (!ids || ids.length === 0) {
        return null;
      }

      assign({ itemsIds: ids, inquiryId, mainOrderId })
        .unwrap()
        .then((res: MessageResponseDto) => {
          feedSuccess(res.message);
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [assign, catchFormError, closeDrawer, feedSuccess, ids, inquiryId]
  );

  if (!item && ids && ids.length <= 1) {
    return <Spinner />;
  }

  if (!ids) {
    return null;
  }

  return (
    <Form
      heading={t("items.assignMainOrder")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isAssigning}
      formError={formError}
    >
      <Grid
        container
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
      >
        <SelectMainOrder
          control={control}
          fieldsErrors={fieldsErrors}
          inquiryId={inquiryId}
        />
      </Grid>
    </Form>
  );
};
