import React, { useCallback, useMemo } from "react";
import { Grid, IconButton } from "@mui/material";
import { Remove } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  AddProductRequestDto,
  EditProductRequestDto,
} from "../../../../slices/products/types";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { SelectSupplier } from "../../../../components/prefilledSelectors/SelectSupplier";
import { FormProps } from "./Form";
import { SelectAlternativeCodeMatch } from "../../../../components/prefilledSelectors/SelectAlternativeCodeMatch";
import { FieldsArrayAccordion } from "../../../../components/wideDrawer/FieldsArrayAccordion";
import { FreeAutocompleteSupplierCode } from "../../../../components/prefilledSelectors/FreeAutocompleteSupplierCode";

type MatchesProps = Pick<FormProps, "fieldsErrors"> & {
  loading?: boolean;
};

export const Matches = ({ fieldsErrors, loading }: MatchesProps) => {
  const { t } = useTranslation("pages");
  const { control, watch } = useFormContext<
    AddProductRequestDto | EditProductRequestDto
  >();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "matches",
  });

  const matchData = watch("matches");

  const addClickHandler = useCallback(() => {
    append({
      match: "A",
    });
  }, [append]);

  const additionalCodes = useMemo(
    () =>
      fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <ControlledCustomField
              control={control}
              name={`matches.${index}.match` as const}
              fieldsErrors={fieldsErrors}
              render={(errorMessage, { field }) => (
                <SelectAlternativeCodeMatch
                  id={`match-${index}`}
                  label={`${t("products.fields.matches.singular")} ${
                    index + 1
                  } *`}
                  size="small"
                  selectProps={field}
                  error={!!errorMessage}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledCustomField
              control={control}
              name={`matches.${index}.supplierId` as const}
              fieldsErrors={fieldsErrors}
              render={(errorMessage, { field }) => (
                <SelectSupplier
                  label={`${t("products.fields.supplier")} ${index + 1} *`}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={7} sx={{ display: "flex", alignItems: "flex-start" }}>
            <ControlledCustomField
              control={control}
              name={`matches.${index}.supplierProductCode` as const}
              fieldsErrors={fieldsErrors}
              render={(errorMessage, { field: { onChange, value } }) => (
                <FreeAutocompleteSupplierCode
                  id="matches.${index}"
                  supplierId={matchData[index].supplierId}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  label={`${t("products.fields.supplierProductCode")} ${
                    index + 1
                  } *`}
                  onChange={onChange}
                  value={(value as string) ?? ""}
                />
              )}
            />
            <IconButton sx={{ marginLeft: 1 }} onClick={() => remove(index)}>
              <Remove />
            </IconButton>
          </Grid>
        </React.Fragment>
      )),
    [control, fields, fieldsErrors, matchData, remove, t]
  );

  return (
    <Grid item gridColumn="span 12">
      <FieldsArrayAccordion
        id="product-form-alternative-codes"
        label={t("products.fields.matches.plural")}
        addLabel={t("products.fields.matches.add")}
        addClickHandler={addClickHandler}
        loading={loading}
      >
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {additionalCodes}
        </Grid>
      </FieldsArrayAccordion>
    </Grid>
  );
};
