import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { usePermissions } from "../../../utils/usePermissions";
import {
  useChangeSchoolActiveStatusMutation,
  useDeleteSchoolMutation,
} from "../../../slices/schools/api";

type ActionsProps = {
  id: GridRowId;
  active: boolean;
};

export const Actions: React.FC<ActionsProps> = ({ id, active }) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("pages");
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeActiveStatus] = useChangeSchoolActiveStatusMutation();
  const can = usePermissions();
  const [deleteSchool] = useDeleteSchoolMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onEdit = useCallback(() => {
    history.push(`/schools/${intId}/edit`);
  }, [history, intId]);
  const onChangeDepartmentActivity = useCallback(() => {
    changeActiveStatus({ id: intId, active: !active })
      .unwrap()
      .then(() => {
        feedSuccess(t("schools.successes.changeActiveStatus"));
      })
      .catch((error) => {
        feedError(
          error?.data?.message ?? t("schools.errors.changeActiveStatus")
        );
      });
  }, [changeActiveStatus, feedError, feedSuccess, intId, t, active]);
  const onDelete = useCallback(() => {
    deleteSchool(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("schools.successes.delete"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("schools.errors.delete"));
      });
  }, [deleteSchool, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationChangeActivityTitle={t("schools.changeActiveStatusTitle")}
      confirmationChangeActivityDescription={t(
        "schools.changeActiveStatusDescription"
      )}
      confirmationTitle={t("schools.confirmDeleteTitle")}
      confirmationDescription={t("schools.confirmDeleteDescription")}
      onEditClick={onEdit}
      onChangeActivityClick={onChangeDepartmentActivity}
      changeActivityButtonText={
        active ? tCommon("deactivate") : tCommon("activate")
      }
      onDeleteClick={onDelete}
      canEdit={can("editSchool")}
      canDelete={can("deleteSchool")}
      canChangeActivity={can("changeSchoolActiveStatus")}
    />
  );
};
