import React, { memo, useCallback } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { numberPriceFromSample } from "../../utils/numberPriceFromSample";
import { Add, Remove } from "@mui/icons-material";
import { useDisplayPrice } from "../../utils/useDisplayPrice";

export type ChangeType = "click" | "type";

export type NumericFieldCurrencyProps = Omit<
  TextFieldProps,
  "onChange" | "value" | "type" | "defaultValue"
> & {
  value: number | string | null;
  onChange: (value: number | string | null, type: ChangeType) => void;
  name: string;
  helperText?: string;
  positiveOnly?: boolean;
};

export const NumericCurrencyField = memo(
  ({
    value,
    onChange,
    name,
    error,
    helperText,
    label,
    positiveOnly,
    onBlur,
    disabled,
    ...props
  }: NumericFieldCurrencyProps) => {
    const displayPrice = useDisplayPrice();

    const addHandler = useCallback(() => {
      onChange(
        typeof value !== "number" || isNaN(value) ? 1 : value + 1,
        "click"
      );
    }, [onChange, value]);

    const subtractHandler = useCallback(() => {
      const newValue =
        typeof value !== "number" || isNaN(value) ? -1 : value - 1;

      if (positiveOnly && newValue < 0) {
        return;
      }

      onChange(newValue, "click");
    }, [onChange, positiveOnly, value]);

    const changeHandler = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (event) => {
        const newValue = event.target.value;

        if (newValue === "") {
          return onChange(null, "type");
        }

        const parsedNewValue = numberPriceFromSample(newValue);

        if (positiveOnly && parsedNewValue < 0) {
          return;
        }

        onChange(parsedNewValue, "type");
      },
      [onChange, positiveOnly]
    );

    return (
      <NumericFormat
        value={value ?? ""}
        customInput={TextField}
        thousandSeparator=" "
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
        renderText={(value) => displayPrice(value)}
        onChange={changeHandler}
        error={error}
        onBlur={onBlur}
        helperText={helperText}
        name={name}
        label={label}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={subtractHandler}
                disabled={
                  (positiveOnly && typeof value === "number" && value < 2) ||
                  (positiveOnly && value === "") ||
                  (positiveOnly && value === null) ||
                  disabled
                }
              >
                <Remove />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={addHandler} disabled={disabled}>
                <Add />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    );
  }
);
