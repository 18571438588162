import React, { useCallback, useMemo } from "react";
import {
  CustomDataGrid,
  CustomDataGridProps,
} from "../../../components/CustomDataGrid/CustomDataGrid";
import { MultipleSelectFilter } from "../../../components/toolbar/MultipleSelectFilter";
import { useTranslation } from "react-i18next";
import { inquiryStatuses } from "../../../types";

type CustomDataGridWithFiltersProps = Omit<CustomDataGridProps, "filters"> & {
  statusesFilter: string[];
  setStatusesFilter: (filters: string[]) => void;
};

export const FilteredCustomDataGrid: React.FC<CustomDataGridWithFiltersProps> =
  ({ rows, statusesFilter, setStatusesFilter, ...props }) => {
    const { t } = useTranslation("pages");

    const filters = useMemo(
      () => (
        <MultipleSelectFilter
          size="small"
          id="dep-inquiry-by-inquiry-statuses"
          label={t("inquiries.filters.status")}
          options={inquiryStatuses.map((status) => ({
            label: t(`inquiries.statuses.${status}`),
            value: status,
          }))}
          onChange={(value) => setStatusesFilter(value)}
          value={statusesFilter}
        />
      ),
      [t, setStatusesFilter, statusesFilter]
    );

    const filteredRowsByRole = useMemo(
      () =>
        rows.filter((row) => {
          if (statusesFilter.length > 0) {
            return statusesFilter.includes(row.status);
          }
          return true;
        }),
      [rows, statusesFilter]
    );

    const resetFilters = useCallback(() => {
      setStatusesFilter([]);
    }, [setStatusesFilter]);

    return (
      <CustomDataGrid
        rows={filteredRowsByRole}
        filters={filters}
        resetCustomFilters={resetFilters}
        {...props}
      />
    );
  };
