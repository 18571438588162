import React, { memo } from "react";
import { ReactComponent as PDFIcon } from "../assets/filesLogos/pdf.svg";
import { ReactComponent as XLSXIcon } from "../assets/filesLogos/xls.svg";
import { ReactComponent as BlancIcon } from "../assets/filesLogos/blanc.svg";

type FileIconProps = {
  filename?: string;
  filetype?: string;
  scaleWidth?: number;
  scaleHeight?: number;
};

export const FileIcon = memo(
  ({ filename, filetype, scaleWidth, scaleHeight }: FileIconProps) => {
    if (!filetype && filename) {
      const fileNameSplit = filename.split(".");
      filetype = fileNameSplit[fileNameSplit.length - 1];
    }

    const imageWidth = scaleWidth || 27;
    const imageHeight = scaleHeight || 27;

    switch (filetype) {
      case "pdf":
        return <PDFIcon width={imageWidth} height={imageHeight} />;
      case "xlsx":
      case "xls":
        return <XLSXIcon width={imageWidth} height={imageHeight} />;
      default:
        return <BlancIcon width={imageWidth} height={imageHeight} />;
    }
  }
);
