import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

import { capitalize } from "../../utils/capitalize";
import { inquiryPriorities } from "../../types";
import { useTranslation } from "react-i18next";

type SelectInquiryPriorityProps = Omit<FormControlProps, "id" | "onChange"> & {
  id: string;
  label: string;
  selectProps: SelectProps;
  helperText?: string;
};

export const SelectInquiryPriority: React.FC<SelectInquiryPriorityProps> = ({
  id,
  label,
  helperText,
  selectProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth {...props}>
      <InputLabel id={`select-inquiry-priority-${id}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`select-inquiry-priority-${id}-label`}
        id={`select-inquiry-priority-${id}`}
        label={label}
        {...selectProps}
      >
        {inquiryPriorities.map((type) => (
          <MenuItem key={type} value={type}>
            {capitalize(type)}
          </MenuItem>
        ))}
        {inquiryPriorities.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
