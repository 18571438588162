import React, { useCallback, useMemo } from "react";
import { Link as MaterialLink, Typography } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useGetCategoriesQuery } from "../../../slices/categories/api";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { propToString } from "../../../utils/propToString";
import { CategoryFlattened } from "../../../slices/categories/types";
import { Actions } from "./Actions";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";

export const DataTable: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const can = usePermissions();
  const { isLoading, isFetching, data, error, refetch } =
    useGetCategoriesQuery();

  const onAddClick = useCallback(
    () => history.push("/categories/add"),
    [history]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: propToString<CategoryFlattened>().id.toString(),
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.1,
      },
      {
        field: propToString<CategoryFlattened>().nameForSort,
        headerName: t("columns.name", { ns: "common" }),
        flex: 1,
        renderCell: (params) => {
          const firstParentName = params.row.firstParentCategory;
          const firstParentId = params.row.firstParentCategoryId;

          const parentName = params.row.parentCategory;
          const parentId = params.row.parentCategoryId;
          return (
            <>
              {firstParentName && (
                <>
                  <MaterialLink
                    component={Link}
                    to={`/categories/${firstParentId}`}
                  >
                    {firstParentName}
                  </MaterialLink>
                  <Typography marginLeft={1} marginRight={1}>
                    {"/"}
                  </Typography>
                </>
              )}
              {parentName && (
                <>
                  <MaterialLink component={Link} to={`/categories/${parentId}`}>
                    {parentName}
                  </MaterialLink>
                  <Typography marginLeft={1} marginRight={1}>
                    {"/"}
                  </Typography>
                </>
              )}
              <MaterialLink
                component={Link}
                to={`/categories/${params.row.id}`}
              >
                {params.row.name}
              </MaterialLink>
            </>
          );
        },
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => <Actions id={params.id} />,
      },
    ],
    [t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("categories.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <CustomDataGrid
        addButtonText={t("categories.addCategory")}
        addButtonDisabled={!can("addCategory")}
        onAddClick={onAddClick}
        tableName="categories"
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={[
          propToString<CategoryFlattened>().id.toString(),
          propToString<CategoryFlattened>().name,
          propToString<CategoryFlattened>().parentCategory,
          propToString<CategoryFlattened>().firstParentCategory,
        ]}
        defaultSortModel={[
          {
            field: "id",
            sort: "desc",
          },
        ]}
      />
    </PaperPage>
  );
};
