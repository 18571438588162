import React, { useMemo } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from "@mui/material";
import {
  allowedProductColors,
  ProductColors,
} from "../../slices/products/types";
import { Square } from "@mui/icons-material";
import { ColoredChip, colorsMap } from "../ColoredChip";

export type ColorFieldProps = Omit<
  SelectProps<ProductColors>,
  "id" | "labelId" | "label" | "name" | "input" | "renderValue" | "size"
> & {
  name: string;
  label: string;
  helperText?: string;
  colorsWhitelist?: ProductColors[] | null;
};

export const ColorField = ({
  name,
  label,
  helperText,
  error,
  colorsWhitelist,
  ...props
}: ColorFieldProps) => {
  const id = `color-field-${name}`;

  const colors = useMemo(() => {
    if (!colorsWhitelist) {
      return allowedProductColors;
    }

    return allowedProductColors.filter((color) =>
      colorsWhitelist.includes(color)
    );
  }, [colorsWhitelist]);

  return (
    <FormControl fullWidth size="small" error={error}>
      <InputLabel id={`${id}-label`} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={name}
        input={<OutlinedInput id={id} label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <ColoredChip colorIcon={selected} />
          </Box>
        )}
        {...props}
        value={props.value || ""}
      >
        {colors.map((color) => (
          <MenuItem key={color} value={color}>
            <ListItemIcon>
              <Square sx={{ color: colorsMap[color] }} fontSize="small" />
            </ListItemIcon>
            <ListItemText>{color}</ListItemText>
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
