import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import { useGetMainOrdersQuery } from "../../../../slices/mainOrders/api";
import { usePermissions } from "../../../../utils/usePermissions";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { MainOrderDetailsGrid } from "../../../../components/MainOrderDetailsGrid";
import { Spinner } from "../../../../components/wideDrawer/Spinner";

type DetailsProps = {
  inquiryId: number;
};

export const Details: React.FC<DetailsProps> = ({ inquiryId }) => {
  const { t } = useTranslation("pages");
  const { id, closeDrawer } = useTableUrlTools(
    `/inquiries/${inquiryId}/details/mo`
  );
  const can = usePermissions();

  const { data, isLoading, error } = useGetMainOrdersQuery(inquiryId);

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  const mainOrder = useMemo(
    () => data && data.find((entry) => entry.id === id),
    [data, id]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!mainOrder || !can("getMainOrder")) {
    return null;
  }

  return (
    <DisplayContainer
      heading={`${t("mainOrders.detailsTitle")}: ${mainOrder.referenceNumber}`}
    >
      <MainOrderDetailsGrid mainOrder={mainOrder} />
    </DisplayContainer>
  );
};
