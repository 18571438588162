import React from "react";
import { Status as StatusProp } from "./types";
import {
  alpha,
  Box,
  Divider,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { Link } from "react-router-dom";

type StatusProps = {
  status: StatusProp;
  statuses: StatusProp[];
  current: string;
};

export const Status = ({
  status: { status, label, link, colors: statusColors },
  statuses,
  current,
}: StatusProps) => {
  if (current === "cancelled" && status !== "new" && status !== "cancelled") {
    return null;
  }

  const index = statuses.findIndex(
    ({ status: localStatus }) => localStatus === status
  );

  const currentIndex = statuses.findIndex(({ status }) => status === current);

  const active = index === currentIndex;

  if (!active && status === "cancelled") {
    return null;
  }

  const color = (() => {
    if (active) {
      return statusColors.color;
    }

    if (index > currentIndex) {
      return colors.dustBlue4;
    }

    return colors.dustBlue1;
  })();

  const backgroundColor = (() => {
    if (active) {
      return alpha(statusColors.color, 0.1);
    }

    if (index > currentIndex) {
      return colors.dustBlue4;
    }

    return colors.dustBlue2;
  })();

  return (
    <>
      {index > 0 && (
        <Divider
          flexItem
          variant="fullWidth"
          sx={{
            borderBottomWidth: "1px",
            flexGrow: "1",
            margin: "8px 6px 8px 6px",
            borderColor: `${
              index - 1 === currentIndex
                ? statuses[index - 1].colors.color
                : color
            } !important`,
          }}
        />
      )}
      <Box
        sx={{
          width: "19px",
          height: "19px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "100%",
            border: `1px solid ${color}`,
            background: active ? backgroundColor : color,
            boxSizing: "border-box",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: color,
              fontSize: "12px",
              lineHeight: "23px",
              fontWeight: 500,
            }}
            component="span"
          >
            {label}
          </Typography>
          {link && (
            <MuiLink
              component={Link}
              to={{
                pathname: link.to,
                state: {
                  justGoBack: true,
                  scrollToTop: true,
                },
              }}
              sx={{
                marginTop: "6px",
                fontSize: "10px",
                lineHeight: "24px",
                whiteSpace: "nowrap",
                color: color,
                textDecoration: "underline",
                textDecorationColor: color,

                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              {link.label}
            </MuiLink>
          )}
        </Box>
      </Box>
    </>
  );
};
