import React, { useCallback } from "react";
import { PriceRangeProps } from "../PriceRange";
import { FilterChip } from "./FilterChip";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";
import { useSelector } from "../../../../store";
import { selectSelfSchoolCurrency } from "../../../../slices/auth/selectors";

export const PriceRangeChip = ({ minPrice, maxPrice }: PriceRangeProps) => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();
  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const removeHandler = useCallback(() => {
    minPrice.onChange(null);
    maxPrice.onChange(null);
  }, [maxPrice, minPrice]);

  const toDisplay: string[] = [];

  if (minPrice.value !== null && minPrice.value !== 0) {
    toDisplay.push(
      `${t("from", { ns: "common" })} ${displayPrice(minPrice.value)}`
    );
  }

  if (maxPrice.value !== null) {
    toDisplay.push(
      `${t("to", {
        ns: "common",
      })} ${displayPrice(maxPrice.value)}`
    );
  }

  if (toDisplay.length === 0) {
    return null;
  }

  return (
    <FilterChip
      name={`${t("shopCatalog.filters.priceRange")}, ${
        selfCurrency?.code || "QAR"
      }:`}
      value={toDisplay.join(" ")}
      onRemove={removeHandler}
    />
  );
};
