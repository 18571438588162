import React from "react";
import { PurchaseOrderWithStatistics } from "../slices/purchaseOrders/types";
import { useTranslation } from "react-i18next";
import { EntityDetailsGrid } from "./EntityDetailsGrid";
import { displayFullName } from "../utils/displayFullName";
import { useDisplayDateTime } from "../utils/useDisplayDateTime";
import { STANDART_PO_CURRENCY } from "../consts";
import { useDisplayPrice } from "../utils/useDisplayPrice";

type PurchaseOrderDetailsGridProps = {
  purchaseOrder: PurchaseOrderWithStatistics;
};

export const PurchaseOrderDetailsGrid = ({
  purchaseOrder,
}: PurchaseOrderDetailsGridProps) => {
  const { t } = useTranslation("pages");
  const displayDateTime = useDisplayDateTime();
  const displayDate = useDisplayDateTime(false);
  const displayPrice = useDisplayPrice();

  return (
    <EntityDetailsGrid
      blocks={[
        [
          {
            label: t("columns.id", { ns: "common" }),
            value: purchaseOrder.id.toString(),
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.inquiry"),
            value: purchaseOrder.inquiry.name,
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.date"),
            value: displayDate(purchaseOrder.date),
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.uploadedBy"),
            value: displayFullName(purchaseOrder.uploadedBy),
            xs: 3,
          },
        ],
        [
          {
            label: t("purchaseOrders.fields.items"),
            value: purchaseOrder.POItems.toString(),
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.value"),
            value: `${displayPrice(purchaseOrder.POValue)} ${
              purchaseOrder.inquiry?.school?.currency?.code ||
              STANDART_PO_CURRENCY
            }`,
            xs: 9,
          },
        ],
        [
          {
            label: t("purchaseOrders.fields.recievedItemsByNH"),
            value: purchaseOrder.recievedItemsByNH.toString(),
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.valueOfRecievedItemsByNH"),
            value: `${displayPrice(purchaseOrder.valueOfRecievedItemsByNH)} ${
              purchaseOrder.inquiry?.school?.currency?.code ||
              STANDART_PO_CURRENCY
            }`,
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.itemsCompletionRateByNH"),
            value: `${purchaseOrder.itemsCompletionRateByNH * 100}%`,
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.valueCompletionRateByNH"),
            value: `${purchaseOrder.valueCompletionRateByNH * 100}%`,
            xs: 3,
          },
        ],
        [
          {
            label: t("purchaseOrders.fields.recievedItemsByClient"),
            value: purchaseOrder.recievedItemsByClient.toString(),
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.valueRecievedItemsByClient"),
            value: `${displayPrice(purchaseOrder.valueRecievedItemsByClient)} ${
              purchaseOrder.inquiry?.school?.currency?.code ||
              STANDART_PO_CURRENCY
            }`,
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.itemsCompletionRateByClient"),
            value: `${purchaseOrder.itemsCompletionRateByClient * 100}%`,
            xs: 3,
          },
          {
            label: t("purchaseOrders.fields.valueCompletionRateByClient"),
            value: `${purchaseOrder.valueCompletionRateByClient * 100}%`,
            xs: 3,
          },
        ],
        "divider",
        [
          {
            label: t("columns.createdAt", { ns: "common" }),
            value: displayDateTime(purchaseOrder.createdAt),
            xs: 3,
          },
          {
            label: t("columns.updatedAt", { ns: "common" }),
            value: displayDateTime(purchaseOrder.updatedAt),
            xs: 9,
          },
        ],
      ]}
    />
  );
};
