import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useAddCategoryMutation } from "../../../slices/categories/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { CategoryForm, categoryFormSchemaCreator } from "./CategoryForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryLevel } from "../../../slices/categories/types";
import { useYup } from "../../../utils/validation";

type AddCategoryForm = {
  name: string;
  level: CategoryLevel;
  description: string;
  parentCategoryId: number;
  requireApproval: boolean;
  color: string;
};

export const Add: React.FC = () => {
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(categoryFormSchemaCreator);
  const formMethods = useForm<AddCategoryForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("categories.errors.add")
  );

  const { closeDrawer } = useTableUrlTools("/categories");
  const onSubmit = useCallback(
    (form: AddCategoryForm) => {
      return addCategory({ ...form })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("categories.addSuccess"), `/categories/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [addCategory, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <Form
      heading={t("categories.addCategory")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <CategoryForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
