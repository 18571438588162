import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ItemInquiryStatistics,
  ItemInquiryStatus,
  itemInquiryStatuses,
} from "../../../../slices/items/types";
import { Statistics } from "../../../../components/Statistics";
import { ITEM_INQUIRY_STATUS_COLORS } from "../../../../consts";

type InquiryStatisticsProps = {
  statistics: ItemInquiryStatistics;
  setInquiryStatusesFilter: (filters: string[]) => void;
};

export const InquiryStatistics: React.FC<InquiryStatisticsProps> = ({
  statistics,
  setInquiryStatusesFilter,
}) => {
  const { t } = useTranslation("pages");

  const setFilterByClick = useCallback(
    (value: ItemInquiryStatus) => {
      setInquiryStatusesFilter([value]);
    },
    [setInquiryStatusesFilter]
  );

  return (
    <Statistics
      stackProps={{ sx: { marginBottom: 2 } }}
      blocks={itemInquiryStatuses.map((status) => ({
        label: t(`items.statuses.inquiry.${status}`),
        percentage: statistics[status],
        onClick: () => setFilterByClick(status),
        color: ITEM_INQUIRY_STATUS_COLORS[status],
      }))}
    />
  );
};
