import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import {
  AddCommoditycodeRequestDto,
  EditCommoditycodeRequestDto,
} from "../../../slices/commoditycodes/types";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { createYupSchema } from "../../../utils/validation";

export const commoditycodeFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    code: yup
      .string()
      .required(t("required"))
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 })),
    description: yup
      .string()
      .required(t("required"))
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 })),
    requireApproval: yup.boolean(),
  })
);

type CommoditycodeFormProps = {
  commoditycodeData?: EditCommoditycodeRequestDto;
  fieldsErrors?: FieldsErrorState;
};

export const CommoditycodeForm: React.FC<CommoditycodeFormProps> = ({
  commoditycodeData,
  fieldsErrors,
}) => {
  const { t } = useTranslation();
  const { register, control } = useFormContext<
    AddCommoditycodeRequestDto | EditCommoditycodeRequestDto
  >();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {commoditycodeData && (
        <input
          type="hidden"
          defaultValue={commoditycodeData.id}
          {...register("id", {
            valueAsNumber: true,
          })}
        />
      )}
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="code"
          defaultValue={commoditycodeData && commoditycodeData.code}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "code",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("code")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="description"
          defaultValue={
            (commoditycodeData && commoditycodeData.description) || ""
          }
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "description",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("description")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="requireApproval"
          defaultValue={
            commoditycodeData ? commoditycodeData.requireApproval : false
          }
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={
                    commoditycodeData
                      ? commoditycodeData.requireApproval
                      : false
                  }
                />
              }
              label={t("commoditycodes.requireApproval") as string}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
