import React from "react";
import { createSvgIcon } from "@mui/material";

export const ChevronDown = createSvgIcon(
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.239665 0.200408C0.543198 -0.081444 1.01775 -0.0638681 1.2996 0.239665L4 3.14779L6.70041 0.239665C6.98226 -0.0638681 7.45681 -0.081444 7.76034 0.200408C8.06388 0.48226 8.08145 0.956809 7.7996 1.26034L4.5496 4.76034C4.40769 4.91317 4.20856 5 4 5C3.79145 5 3.59232 4.91317 3.45041 4.76034L0.200408 1.26034C-0.081444 0.956809 -0.0638681 0.48226 0.239665 0.200408Z"
      fill="currentColor"
    />
  </svg>,
  "ChevronDown"
);
