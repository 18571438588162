import React, { useCallback, useState } from "react";
import { Box, Button, Drawer as MuiDrawer, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SettingsSliders } from "../../../../components/astraIcons/SettingsSliders";
import { PriceRangeProps } from "../PriceRange";
import { SchoolLevelsProps } from "../SchoolLevels";
import { SuppliersProps } from "../Suppliers";
import { SchoolLevelsChip } from "./SchoolLevelsChip";
import { PriceRangeChip } from "./PriceRangeChip";
import { SuppliersChip } from "./SuppliersChip";

type DrawerProps = {
  children?: React.ReactNode;
  onResetFilters?: () => void;
  SchoolLevelsProps: SchoolLevelsProps;
  PriceRangeProps: PriceRangeProps;
  SuppliersProps: SuppliersProps;
};

export const Drawer = ({
  children,
  onResetFilters,
  SchoolLevelsProps,
  PriceRangeProps,
  SuppliersProps,
}: DrawerProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "shopCatalog.filters" });

  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);

  const openFiltersDrawer = useCallback(() => {
    setFiltersDrawerOpen(true);
  }, []);

  const closeFiltersDrawer = useCallback(() => {
    setFiltersDrawerOpen(false);
  }, []);

  const clearAllClickHandler = useCallback(() => {
    closeFiltersDrawer();

    if (onResetFilters) {
      onResetFilters();
    }
  }, [closeFiltersDrawer, onResetFilters]);

  return (
    <>
      <Box
        sx={{
          marginTop: "48px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Button
          sx={{ marginLeft: "-24px" }}
          onClick={openFiltersDrawer}
          startIcon={<SettingsSliders />}
        >
          {t("filter")}
        </Button>
        <SchoolLevelsChip {...SchoolLevelsProps} />
        <PriceRangeChip {...PriceRangeProps} />
        <SuppliersChip {...SuppliersProps} />
      </Box>
      <MuiDrawer
        open={filtersDrawerOpen}
        onClose={closeFiltersDrawer}
        sx={{
          width: "285px",

          "& .MuiDrawer-paper": {
            width: "285px",
            paddingTop: "16px",
            paddingRight: "24px",
            paddingBottom: "16px",
            paddingLeft: "24px",
            backgroundColor: "whitishGray.main",
            display: "flex",
            flexDirection: "column",
            gap: "38px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            component="h4"
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              color: "primary.dark",
            }}
          >
            {t("filters")}
          </Typography>
          <Button
            sx={{
              height: "24px",
              fontSize: "12px",
              lineHeight: "24px",
              fontWeight: "400",
              color: "secondary.main",
              paddingRight: "12px",
              paddingLeft: "12px",
              marginRight: "-12px",
            }}
            onClick={clearAllClickHandler}
          >
            {t("clearAll")}
          </Button>
        </Box>
        {children}
      </MuiDrawer>
    </>
  );
};
