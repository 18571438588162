import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ItemOrderStatistics,
  ItemOrderStatus,
  itemOrderStatuses,
} from "../../../../slices/items/types";
import { Statistics } from "../../../../components/Statistics";
import { ITEM_ORDER_STATUS_COLORS } from "../../../../consts";

type OrderStatisticsProps = {
  statistics: ItemOrderStatistics;
  setOrderStatusesFilter: (filters: string[]) => void;
};

export const OrderStatistics: React.FC<OrderStatisticsProps> = ({
  statistics,
  setOrderStatusesFilter,
}) => {
  const { t } = useTranslation("pages");

  const setFilterByClick = useCallback(
    (value: ItemOrderStatus) => {
      setOrderStatusesFilter([value]);
    },
    [setOrderStatusesFilter]
  );

  return (
    <Statistics
      stackProps={{ sx: { marginBottom: 2 } }}
      blocks={itemOrderStatuses.map((status) => ({
        label: t(`items.statuses.order.${status}`),
        percentage: statistics[status],
        onClick: () => setFilterByClick(status),
        color: ITEM_ORDER_STATUS_COLORS[status],
      }))}
    />
  );
};
