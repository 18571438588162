import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { useFormContext } from "react-hook-form";
import { AddInquiryForm } from "../types";
import { InquiryFormProps } from "../InquiryForm";
import { deliveryTerms } from "../../../../types";

export const SelectDeliveryTerms = ({
  fieldsErrors,
  inquiryData,
}: InquiryFormProps) => {
  const { t } = useTranslation("pages");
  const { control } = useFormContext<AddInquiryForm>();

  return (
    <Grid item gridColumn="span 6">
      <ControlledCustomField
        control={control}
        name="deliveryTerms"
        defaultValue={inquiryData?.deliveryTerms}
        fieldsErrors={fieldsErrors}
        render={(errorMessage, { field }) => (
          <TextField
            fullWidth
            label={t("inquiries.fields.deliveryTerms")}
            error={!!errorMessage}
            helperText={errorMessage}
            select
            {...field}
          >
            {deliveryTerms.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );
};
