import React, { memo } from "react";
import { ContextMenu } from "../../../components/ContextMenu";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { useDeleteDNMutation } from "../../../slices/deliveryNotes/api";
import { useHistory } from "react-router-dom";

type ActionsProps = {
  inquiryId: number;
  dnId: number;
};

export const Actions = memo(({ inquiryId, dnId }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const history = useHistory();
  const [deleteDN] = useDeleteDNMutation();

  return (
    <ContextMenu
      buttons={[
        {
          label: t("deliveryNotes.actions.upload.label"),
          handler: () => {
            history.push(`/inquiries/${inquiryId}/details/dn/${dnId}/edit`);
          },
          disabled: !can("editDeliveryNote"),
        },
        {
          label: t("deliveryNotes.actions.delete.label"),
          handler: () => {
            deleteDN({ id: dnId })
              .unwrap()
              .then(() => {
                feedSuccess(t("deliveryNotes.successes.deleted"));
              })
              .catch((error) => {
                feedError(
                  error?.data?.message ?? t("deliveryNotes.errors.deleted")
                );
              });
          },
          disabled: !can("deleteDeliveryNote"),
          confirmation: {
            title: t("deliveryNotes.actions.delete.confirmationTitle"),
            description: t(
              "deliveryNotes.actions.delete.confirmationDescription"
            ),
          },
        },
      ]}
    />
  );
});
