import React from "react";
import {
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  styled,
  Toolbar,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { backgroundImage } from "../../theme/mui/theme";

type WideDrawerProps = Omit<DrawerProps, "right" | "onClose"> & {
  close: () => void;
};

/**
 * Replace with NewDesignWideDrawer. This is component for old design.
 * @deprecated
 */

export const WideDrawer: React.FC<WideDrawerProps> = ({
  children,
  close,
  ...props
}) => {
  return (
    <StyledWideDrawer anchor="right" onClose={close} {...props}>
      <StyledToolbar>
        <IconButton onClick={close}>
          <ChevronRight />
        </IconButton>
      </StyledToolbar>
      <Divider />
      {children}
    </StyledWideDrawer>
  );
};

const StyledWideDrawer = styled(Drawer)({
  width: "50%",
  "& .MuiDrawer-paper": {
    width: "50%",
    backgroundImage,
  },
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
