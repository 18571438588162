import { ItemSchoolStatus } from "../../slices/items/types";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
  RequestedItemSchoolStatus,
} from "../../types";
import { Role } from "../../permissions/permissions";

export default {
  title: "ITS Astra",

  roles: {
    admin: "Admin",
    orderManager: "Order manager",
    inquiryManager: "Inquiry manager",
    principal: "Principal",
    headOfDepartment: "Head of department",
    teacher: "Teacher",
  } as Record<Role, string>,

  actions: {
    edit: "Edit",
    submit: "Submit",
    activate: "Activate",
    deactivate: "Deactivate",
  },

  columns: {
    id: "ID",
    idC: "ID (C)",
    name: "Name",
    public: "Public",
    actions: "Actions",
    createdAt: "Created at",
    updatedAt: "Updated at",
  },

  units: {
    cm: "cm",
    m3: "m³",
    kg: "kg",
    pcs: "pcs",
  },

  currencies: {
    qar: "QAR",
  },

  schoolInquiryStatuses: {
    new: "New",
    inProgress: "In progress",
    pendingApproval: "Pending approval",
    readyForSending: "Ready for sending",
    sentToAstra: "Sent to Astra",
    cancelled: "Canceled",
  } as Record<InquirySchoolStatus, string>,

  schoolDepartmentInquiryStatuses: {
    new: "New",
    inProgress: "In Progress",
    pendingApproval: "Pending Approval",
    readyForSending: "Ready for Sending",
    approvedByHead: "Approved by Head",
    needReviewByHead: "Need review by Head",
    approvedByPrincipal: "Approved by Principal",
    sentToAstra: "Sent to Astra",
    cancelled: "Canceled",
  } as Record<DepartmentInquirySchoolStatus, string>,

  assignmentSchoolStatuses: {
    new: "New",
    inProgress: "In Progress",
    readyForSending: "Ready for Sending",
    approvedByTeacher: "Approved by Teacher",
    needReviewByTeacher: "Need Review",
    approvedByHead: "Approved by Head",
    sentToPrincipal: "Sent to Principal",
    approvedByPrincipal: "Approved by Principal",
    sentToAstra: "Sent to Astra",
    cancelled: "Canceled",
  } as Record<AssignmentSchoolStatus, string>,

  itemSchoolStatuses: {
    new: "New",
    approvedByHead: "Approved by Head",
    needReviewByHead: "Need review by Head",
    approvedByPrincipal: "Approved by Principal",
    cancelled: "Canceled",
  } as Record<ItemSchoolStatus, string>,

  requestedItemSchoolStatuses: {
    new: "New",
    approvedByTeacher: "Approved by Teacher",
    needReviewByTeacher: "Need review by Teacher",
    approvedByHead: "Approved by Head",
    approvedByPrincipal: "Approved by Principal",
    cancelled: "Canceled",
  } as Record<RequestedItemSchoolStatus, string>,

  alternativeSchoolStatuses: {
    readyForApproval: "Ready for Approval",
  },

  schoolLevel: {
    earlyYears: "Early years",
    primarySchool: "Primary school",
    secondarySchool: "Secondary school",
  },

  noDescription: "No description",
  loading: "Loading",
  dragAndDrop: "Drag and drop a file here or click to select a file",
  successUpload: "Successfully uploaded",
  at: "at",
  activate: "Activate",
  deactivate: "Deactivate",
  name: "Name",
  statistic: "Statistics",
  itemsPerPage: "Items per page",
  na: "N/A",
  priceUponRequest: "Price upon request",
  commodityCodeRequiresApproval:
    "This product's commodity code requires approval",
  noFile: "No file was selected for upload. Try to upload file again.",
  wrongFormat: "You are trying to upload a file in the wrong format.",
  fileUploaded: "File successfully uploaded to server.",
  downloadFile: "Download File",
  emptySelect: "There is no result",
  noUndo: "You won't be able to undo this action",
  add: "Add",

  totalItems_one: "{{items}} ({{count}} unit)",
  totalItems_other: "{{items}} ({{count}} units)",

  from: "from",
  to: "to",

  datagridPaginationCount: "{{from}}-{{to}} of {{count}} items",
  datagridPaginationCountOne: "{{from}}-{{to}} of {{count}} item",

  inStock: "In stock",
};
