import {
  MatchOption,
  MessageResponseDto,
  PaginatedResponseDto,
  PaginationData,
  SearchableRequestDto,
} from "../../types";
import { Supplier } from "../suppliers/types";
import { Country } from "../countries/types";
import { Commoditycode } from "../commoditycodes/types";
import { CategoryFlattened } from "../categories/types";
import { Currency } from "../currencies/types";
import { Tag } from "../tags/types";
import { Entity, SupplierProductCode } from "../../entities";
import { Inventory } from "../inventory/types";

export type ProductBase = {
  supplierProductCode: string;
  name: string | null;
  description: string | null;
  packSize: number | null;
  color: ProductColors | "" | null;
  schoolLevel: SchoolLevel[] | null;
  replacementCommodityCode: string | null;
  supplierPrice: number | null;
  retailPrice: number | null;
  weight: string | null;
  volume: string | null;
  sizeW: string | null;
  sizeD: string | null;
  sizeH: string | null;
  popularity: number;
  availability: boolean;
};

type Image = Entity & {
  url: string;
  productId: number;
};

export type Match = Entity & {
  supplierProductCodeOne: SupplierProductCode;
  supplierProductCodeTwo: SupplierProductCode;
  match: MatchOption;
};

export type Product = Entity &
  ProductBase & {
    categories: CategoryFlattened[];
    supplier: Supplier;
    matches: Match[];
    images: Image[];
    country: Country | null;
    commodityCode: Commoditycode | null;
    supplierCurrency: Currency;
    retailCurrency: Currency;
    tags: Tag[];
    inventoryEntries: Inventory[];
  };

export const allSchoolLevels = [
  "earlyYears",
  "primarySchool",
  "secondarySchool",
] as const;

export const allowedProductColors = [
  "white",
  "black",
  "multi",
  "green",
  "blue",
  "tan",
  "purple",
  "yellow",
  "brown",
  "gray",
  "pink",
  "red",
  "orange",
  "beige",
  "cream",
] as const;

export type SchoolLevel = typeof allSchoolLevels[number];
export type ProductColors = typeof allowedProductColors[number];

export type MatchRequestDto = {
  match: MatchOption;
  supplierId: number;
  supplierProductCode: string;
};

type ProductRequestDtoBase = ProductBase & {
  supplierId: number;
  categoriesIds: number[];
  countryId: number | null;
  commodityCodeId: number | null;
  supplierCurrencyId: number | null;
  retailCurrencyId: number | null;
  tagsNames: string[];
  matches: MatchRequestDto[];
  schoolLevel?: SchoolLevel[] | null;
};

export type AddProductForm = ProductRequestDtoBase & {
  images: Array<{
    s3key: string;
    fileList: FileList;
  }>;
};

export type AddProductRequestDto = ProductRequestDtoBase & {
  images: string[];
};

export type AddProductResponseDto = MessageResponseDto & {
  id: number;
};

export type EditProductForm = ProductRequestDtoBase & {
  id: number;
  images: Array<{
    s3key: string | null;
    fileList: FileList | null;
  }>;
};

export type EditProductRequestDto = ProductRequestDtoBase & {
  id: number;
  images: string[];
};

export type GetProductsRequestDto = PaginationData & {
  search?: string;
  supplierIds?: string[] | null;
  activities?: string[] | null;
};

export type GetProductsResponseDto = PaginatedResponseDto<
  Pick<
    Product,
    | "id"
    | "name"
    | "retailPrice"
    | "supplierPrice"
    | "retailCurrency"
    | "commodityCode"
    | "replacementCommodityCode"
    | "availability"
  > & {
    supplierProductCode: SupplierProductCode;
    images: string[];
    inStock: number;
  }
>;

export type GetProductsAutocompleteRequestDto = SearchableRequestDto;

export type GetProductsAutocompleteResponseDto = Array<
  Pick<Product, "id"> & {
    supplierName: string;
    supplierProductCode: string;
    name: string;
  }
>;

export type GetProductsToAssignRequestDto = {
  itemId: number;
  search: string;
  suppliersIds: string[];
};

export type GetProductsToAssignResponseDto = Array<
  Pick<
    Product,
    | "id"
    | "name"
    | "supplierPrice"
    | "supplierCurrency"
    | "retailPrice"
    | "retailCurrency"
    | "color"
  > & {
    supplierProductCode: SupplierProductCode;
    images: string[];
    commodityCodeRequiresApproval: boolean;
    inStock: number;
  }
>;

export type GetProductRequestDto = {
  id: number;
  rawImages?: boolean;
};

export type GetProductResponseDto = Omit<
  Product,
  "images" | "inventoryEntries"
> & {
  images: string[];
  inventoryEntries: Array<{
    id: number;
    quantity: number;
    countryName: string;
  }>;
};

export type GetProductPreviewsRequestDto = number;

export type GetProductPreviewResponseDto = Array<{
  s3Key: string;
  url: string;
}>;
