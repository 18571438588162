import React from "react";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import {
  DateField,
  DateFieldProps,
} from "../../../components/fields/DateField";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { useGetSchoolsQuery } from "../../../slices/schools/api";
import { useGetUsersQuery } from "../../../slices/users/api";
import { displayFullName } from "../../../utils/displayFullName";
import { PaperPageSelect } from "../../../components/layout/PaperPage";

type DateFieldProp = {
  value: DateFieldProps["value"];
  onChange: DateFieldProps["onChange"];
};

type StringFieldProp = {
  value: string | null;
  onChange: (value: string) => void;
};

export type FiltersProps = {
  from: DateFieldProp;
  to: DateFieldProp;
  schools: StringFieldProp;
  users: StringFieldProp;
  onReset: () => void;
};

export const Filters = ({
  from,
  to,
  users,
  schools,
  onReset,
}: FiltersProps) => {
  const { t } = useTranslation("pages");

  const { data: schoolsData, isLoading: isSchoolsDataLoading } =
    useGetSchoolsQuery();

  const { data: usersData, isLoading: isUsersDataLoading } = useGetUsersQuery();

  return (
    <Box
      sx={{ display: "flex", gap: 2, marginBottom: 2, flexDirection: "column" }}
    >
      <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
        <Autocomplete
          sx={{ flex: "100%" }}
          PaperComponent={PaperPageSelect}
          multiple
          id="by-schools"
          options={(schoolsData ?? []).map(({ id }) => id.toString())}
          noOptionsText={t("emptySelect", { ns: "common" })}
          loading={isSchoolsDataLoading}
          loadingText={t("loading", { ns: "common" })}
          getOptionLabel={(option) =>
            schoolsData?.find(({ id }) => id.toString() === option)?.name || ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("searches.filters.school")}
              placeholder={t("searches.filters.school")}
            />
          )}
          onChange={(event, value) => schools.onChange(value.join(",_,"))}
          value={schools.value ? schools.value.split(",_,") : []}
        />
        <Autocomplete
          style={{ flex: "100%" }}
          PaperComponent={PaperPageSelect}
          multiple
          id="by-users"
          options={(usersData ?? [])
            .filter(({ school: userSchool }) => {
              if (!schools.value || schools.value.length === 0) {
                return true;
              }

              return schools.value
                .split(",_,")
                .includes(userSchool ? userSchool.id.toString() : "-1");
            })
            .map(({ id }) => id.toString())}
          noOptionsText={t("emptySelect", { ns: "common" })}
          loading={isUsersDataLoading}
          loadingText={t("loading", { ns: "common" })}
          getOptionLabel={(option) => {
            const user = usersData?.find(({ id }) => id.toString() === option);

            return user
              ? displayFullName({
                  firstName: user.firstName,
                  lastName: user.lastName,
                })
              : "";
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("searches.filters.user")}
              placeholder={t("searches.filters.user")}
            />
          )}
          onChange={(event, value) => users.onChange(value.join(",_,"))}
          value={users.value ? users.value.split(",_,") : []}
        />
        <IconButton sx={{ width: "40px", height: "40px" }} onClick={onReset}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
        <DateField label={t("searches.filters.from")} {...from} />
        <DateField label={t("searches.filters.to")} {...to} />
        <Box width={"85px"} height={"40px"} />
      </Box>
    </Box>
  );
};
