import React from "react";
import {
  Button,
  ContainerProps,
  Paper,
  PaperProps,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dotted } from "../spinners/Dotted";
import { PaperBreadcrumbs, PaperBreadcrumbsProps } from "./PaperBreadcrumbs";
import { ContainerBase } from "./ContainerBase";
import { PaperBase } from "./PaperBase";

const PaperPageBaseStyled = styled(PaperBase)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

type PaperPageProps = ContainerProps & {
  breadcrumbs?: Omit<PaperBreadcrumbsProps, "paperProps" | "breadcrumbsProps">;
};

export const PaperPage = ({
  children,
  breadcrumbs,
  ...props
}: PaperPageProps) => {
  return (
    <ContainerBase {...props}>
      {breadcrumbs && (
        <PaperBreadcrumbs
          paperProps={{ sx: { marginBottom: 2 } }}
          {...breadcrumbs}
        />
      )}
      <PaperPageBaseStyled>{children}</PaperPageBaseStyled>
    </ContainerBase>
  );
};

type PaperPageErrorProps = Omit<ContainerProps, "children"> & {
  refetch: () => void;
  disabled?: boolean;
  message: string;
};

export const PaperPageError = ({
  refetch,
  disabled,
  message,
  ...props
}: PaperPageErrorProps) => {
  const { t } = useTranslation();

  return (
    <ContainerBase {...props}>
      <PaperPageBaseStyled sx={{ alignItems: "center" }}>
        <Typography
          sx={{
            color: "error.main",
            marginBottom: "10px",
          }}
        >
          {message}
        </Typography>
        <Button
          color="error"
          variant="outlined"
          onClick={refetch}
          disabled={disabled}
        >
          {t("tryAgain")}
        </Button>
      </PaperPageBaseStyled>
    </ContainerBase>
  );
};

/**
 * Meant to be rendered instead of PaperPage when you want to display a full page
 * loading spinner.
 *
 * In case you don't want to full page loading spinner, don't use PaperPage at
 * all, but use a combination of ContainerBase and PaperBase. And then
 * render SpinnerBase inside PaperBase.
 */
export const PaperPageSpinner = (props: Omit<ContainerProps, "children">) => (
  <ContainerBase {...props}>
    <PaperPageBaseStyled
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Dotted />
    </PaperPageBaseStyled>
  </ContainerBase>
);

export const PaperPageSelect = styled(Paper)(() => ({
  border: "1px solid rgb(0, 0, 0, 0.13)",
}));

export const PaperAutocompleteWithOptionalBorder = ({
  inLoading,
  isOptions,
  ...props
}: {
  inLoading: boolean;
  isOptions: boolean;
} & PaperProps) => (
  <Paper
    {...props}
    sx={{
      border: inLoading || isOptions ? "1px solid rgb(0, 0, 0, 0.13)" : "none",
    }}
  />
);

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: "10px",
}));

export const PaperMessageError: React.FC<{ message: string }> = ({
  message,
}) => {
  return (
    <ErrorMessage sx={{ paddingTop: "20px" }} variant="body1">
      {message}
    </ErrorMessage>
  );
};
