import React, { useCallback, useMemo, useState } from "react";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../../components/layout/PaperPage";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { propToString } from "../../../../utils/propToString";
import {
  Box,
  IconButton,
  Link as MaterialLink,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../utils/usePermissions";
import {
  useChangeItemInquiryStatusMutation,
  useGetItemsQuery,
  useSubmitMutation,
} from "../../../../slices/items/api";
import {
  ItemFlattened,
  ItemInquiryStatistics,
  itemInquiryStatuses,
  ItemServerCrudResponse,
} from "../../../../slices/items/types";
import { useLocalStorage } from "usehooks-ts";
import { DEFAULT_PAGE_SIZE } from "../../../../consts";
import { InquiryStatistics } from "./InquiryStatistics";
import { SelectItemInquiryStatusCell } from "../customCells/SelectItemInquiryStatusCell";
import { useSearchDebounce } from "../../../../utils/useSearchDebounce";
import {
  InvalidFileUploadType,
  uploadAndDownloadFileToServer,
} from "../../../../utils/downloadFileFromServer";
import { useFeedError } from "../../../../utils/feedHooks";
import { GroupsSwitch } from "../GroupsSwitch";
import { ProductLink } from "../ProductLink";
import { Actions } from "./Actions";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Add,
  AssignmentOutlined,
  ExitToAppOutlined,
} from "@mui/icons-material";
import { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import { useItemsTableBaseUrl } from "../../hooks";
import { MultipleSelectFilter } from "../../../../components/toolbar/MultipleSelectFilter";
import { CustomDataGridServerSide } from "../../../../components/CustomDataGrid/CustomDataGridServerSide";
import { useMessageHandlers } from "../../../../utils/useMessageHandlers";
import { CircleButton } from "../../../../components/toolbar/CircleButton";
import { ConfirmationButton } from "../../../../components/ConfirmationButton";
import { useHeaderTitle } from "../../../../slices/ui/useHeaderTitle";
import { InStockIndicator } from "../../../../components/InStockIndicator";

const tableName = "matching";

const defaultStatistics: ItemInquiryStatistics = {
  new: 0,
  matched: 0,
  submitted: 0,
  confirmed: 0,
  delivered: 0,
  discontinued: 0,
  canceled: 0,
};

export const MatchingTable = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { handleSuccess, handleError } = useMessageHandlers();
  const feedError = useFeedError();
  const { baseUrl, inquiryId, detailId } = useItemsTableBaseUrl();

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    `table-${tableName}-${detailId}.sortModel`,
    [
      {
        field: "dueDate",
        sort: "asc",
      },
    ]
  );

  const [page, setPage] = useLocalStorage(
    `table-${tableName}-${detailId}.page`,
    0
  );

  const [pageSize, setPageSize] = useLocalStorage(
    `table-${tableName}-${detailId}.pageSize`,
    DEFAULT_PAGE_SIZE
  );

  const [search, setSearch] = useLocalStorage(
    `table-${tableName}-${detailId}.search`,
    ""
  );
  const debouncedSearch = useSearchDebounce(search);

  const [inquiryStatusesFilter, setInquiryStatusesFilter] = useLocalStorage<
    string[]
  >(`filter.itemsByInquiryStatuses-${detailId}`, []);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const selectionModelChangeHandler = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const { isLoading, isFetching, data, error, refetch } = useGetItemsQuery({
    page,
    pageSize,
    sortModel,
    departmentInquiryId: detailId,
    search: debouncedSearch,
    inquiryStatusFilter: inquiryStatusesFilter,
    orderStatusFilter: [],
  });

  const [, { isLoading: isChangeInquiryStatusLoading }] =
    useChangeItemInquiryStatusMutation();

  const na = t("na", { ns: "common" });
  const title = data?.parentName;

  useHeaderTitle(title ?? t("items.title"));

  const columns = useMemo<
    GridColDef<ItemServerCrudResponse["data"][number]>[]
  >(() => {
    const widthPercentForNames = can("editInquiryStatus") ? 0.18 : 0.21;

    return [
      {
        field: "internalLineNumber",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 75,
        flex: 0.05,
        cellClassName: "id-width-fix",
      },
      {
        field: "customerLineNumber",
        headerName: t("columns.idC", { ns: "common" }),
        type: "number",
        minWidth: 75,
        flex: 0.05,
        cellClassName: "id-width-fix",
      },
      {
        field: "item",
        headerName: t("items.fields.item"),
        flex: widthPercentForNames,
        valueGetter: ({ row: { supplier, supplierProductCode } }) =>
          `${supplier ?? na} ${supplierProductCode ?? na}`,
        renderCell: ({ row: { id, name, supplier, supplierProductCode } }) => {
          const nameFormatted = name ?? na;

          return (
            <Box>
              <Box>
                {supplier ?? na} {supplierProductCode ?? na}
              </Box>
              <Tooltip title={nameFormatted}>
                <MaterialLink component={Link} to={`${baseUrl}/${id}/details`}>
                  {nameFormatted}
                </MaterialLink>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "match",
        headerName: t("items.fields.match"),
        flex: 0.1,
        renderCell: (params) => (
          <>{params.row.match ?? t("na", { ns: "common" })}</>
        ),
      },
      {
        field: "product",
        headerName: t("items.fields.product"),
        flex: 0.2,
        renderCell: ({ row: { product } }) => {
          return (
            <Box>
              <Box>
                {product?.supplierProductCode?.supplier?.code ?? na}{" "}
                {product?.supplierProductCode?.code ?? na}
              </Box>
              <ProductLink
                product={product}
                inquiryId={inquiryId}
                detailId={detailId}
                tableName={tableName}
              />
            </Box>
          );
        },
      },
      {
        field: propToString<ItemFlattened>().inquiryStatus,
        headerName: t("items.fields.inquiryStatus"),
        flex: 0.2,
        hide: !can("editInquiryStatus"),
        editeable: true,
        renderCell(params) {
          return (
            <SelectItemInquiryStatusCell
              gridCellProps={params}
              inquiryId={inquiryId}
            />
          );
        },
      },
      {
        field: "inStock",
        headerName: t("inStock", { ns: "common" }),
        type: "actions",
        flex: 0.05,
        minWidth: 50,
        renderCell: ({ row: { inStock } }) =>
          inStock > 0 ? (
            <InStockIndicator quantity={inStock} />
          ) : (
            t("na", { ns: "common" })
          ),
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.05,
        minWidth: 100,
        renderCell: ({ id, row }) => (
          <Actions
            id={typeof id === "string" ? parseInt(id) : id}
            inquiryStatus={row.inquiryStatus}
            orderStatus={row.orderStatus}
            hasAssignedProduct={!!row.product}
          />
        ),
      },
    ];
  }, [baseUrl, can, detailId, inquiryId, na, t]);

  const onImportClick = useCallback(() => {
    uploadAndDownloadFileToServer(
      `/import/items`,
      `Items Import Report ${new Date().toLocaleString("en-GB")}.txt`,
      (errorType: InvalidFileUploadType) => {
        switch (errorType) {
          case "noFile":
            feedError(t("noFile", { ns: "common" }));
            break;
          case "wrongFormat":
            feedError(t("wrongFormat", { ns: "common" }));
            break;
        }
      },
      () => {
        location.reload();
      },
      [{ name: "departmentId", value: detailId.toString() }],
      "xlsx"
    );
  }, [detailId, feedError, t]);

  const [submitItems, { isLoading: isSubmitLoading }] = useSubmitMutation();
  const onBulkSubmit = useCallback(() => {
    submitItems({
      itemsIds: selectionModel.map((id) =>
        typeof id === "number" ? id : parseInt(id)
      ),
      inquiryId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [handleError, handleSuccess, inquiryId, selectionModel, submitItems]);

  const actionButtons = useMemo(() => {
    if (selectionModel.length > 0) {
      const searchQuery = `?ids=${selectionModel.join(",")}`;

      return (
        <Stack direction="row">
          <Tooltip
            title={t("items.toolbarActions.assignPurchaseOrder") as string}
          >
            <IconButton
              component={Link}
              to={{
                pathname: `${baseUrl}/assignPurchaseOrder`,
                search: searchQuery,
              }}
              disabled={!can("assignItemPurchaseOrder")}
            >
              <AssignmentOutlined />
            </IconButton>
          </Tooltip>
          <CircleButton
            tooltip={t("items.toolbarActions.bulkSubmit.label")}
            onClick={onBulkSubmit}
            loading={isSubmitLoading}
            confirmation={{
              title: t("items.toolbarActions.bulkSubmit.title"),
              description: t("items.toolbarActions.bulkSubmit.description"),
            }}
            disabled={!can("submitItem")}
          >
            <ExitToAppOutlined />
          </CircleButton>
        </Stack>
      );
    }

    return (
      <Stack direction="row">
        <Tooltip title={t("items.add") as string}>
          <IconButton
            component={Link}
            to={`${baseUrl}/add`}
            disabled={!can("importItems")}
          >
            <Add />
          </IconButton>
        </Tooltip>
        <ConfirmationButton
          onClick={onImportClick}
          component={IconButton}
          confirmationTitle={t("items.importConfirm")}
          confirmationDescription={t("items.importConfirmDescription")}
          disabled={!can("importItems")}
        >
          <Tooltip title={t("items.importFile") as string}>
            <FileUploadOutlinedIcon />
          </Tooltip>
        </ConfirmationButton>
      </Stack>
    );
  }, [
    selectionModel,
    t,
    baseUrl,
    can,
    onImportClick,
    onBulkSubmit,
    isSubmitLoading,
  ]);

  const filters = useMemo(
    () => (
      <MultipleSelectFilter
        size="small"
        id="by-item-inquiry-statuses"
        label={t("items.filters.inquiryStatus")}
        options={itemInquiryStatuses.map((status) => ({
          label: t(`items.statuses.inquiry.${status}`),
          value: status,
        }))}
        onChange={setInquiryStatusesFilter}
        value={inquiryStatusesFilter}
      />
    ),
    [t, setInquiryStatusesFilter, inquiryStatusesFilter]
  );

  const resetFilters = useCallback(() => {
    setInquiryStatusesFilter([]);
    setSelectionModel([]);
  }, [setInquiryStatusesFilter]);

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("items.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage
      breadcrumbs={{
        paths: [
          { href: "/inquiries", text: t("inquiries.title") },
          { href: `/inquiries/${inquiryId}/details`, text: data.inquiryName },
        ],
        current: title,
        endAdornment: (
          <GroupsSwitch {...{ inquiryId, detailId }} opened={tableName} />
        ),
      }}
    >
      {can("editInquiryStatus") && (
        <InquiryStatistics
          statistics={data.inquiryStatistics ?? defaultStatistics}
          setInquiryStatusesFilter={(filters) => {
            setInquiryStatusesFilter(filters);
            setSearch("");
          }}
        />
      )}
      <StyledDataGrid
        autoHeight
        buttons={actionButtons}
        columns={columns}
        rows={data.data}
        disableSelectionOnClick
        disableColumnMenu
        rowCount={data.rowCount}
        page={page}
        pageSize={pageSize}
        sortModel={sortModel}
        search={search}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortModelChange={setSortModel}
        setSearch={setSearch}
        loading={isFetching || isChangeInquiryStatusLoading}
        getRowClassName={(params) =>
          params.row.priority === "high" ? "item-priority-high" : ""
        }
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={selectionModelChangeHandler}
        filters={filters}
        resetCustomFilters={resetFilters}
      />
    </PaperPage>
  );
};

const StyledDataGrid = styled(CustomDataGridServerSide)({
  ".id-width-fix div": {
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    textAlign: "end",
  },
});
