import React, { useCallback, useMemo, useState } from "react";
import { useGetTagsQuery } from "../../../../../slices/tags/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useSearchDebounce } from "../../../../../utils/useSearchDebounce";
import { ControlledCustomField } from "../../../../../components/controlledFields/ControlledCustomField";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  AddProductRequestDto,
  EditProductRequestDto,
} from "../../../../../slices/products/types";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { FormProps } from "../Form";
import { PaperPageSelect } from "../../../../../components/layout/PaperPage";

export const SelectTags = ({
  fieldsErrors,
}: Pick<FormProps, "fieldsErrors">) => {
  const { t } = useTranslation("pages");
  const { control, formState } = useFormContext<
    AddProductRequestDto | EditProductRequestDto
  >();

  const [search, setSearch] = useState("");
  const debouncedSearch = useSearchDebounce(search);
  const { data, isFetching } = useGetTagsQuery(
    debouncedSearch.length > 0 ? debouncedSearch : skipToken
  );

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map(({ name }) => name.toLowerCase());
  }, [data]);

  const inputChangeHandler = useCallback((event, value: string) => {
    setSearch(value);
  }, []);

  const tagError = useMemo(() => {
    const fieldErrors = formState.errors.tagsNames;

    if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
      return fieldErrors.find(
        (error) => typeof error === "object" && "message" in error
      )?.message;
    }
  }, [formState.errors.tagsNames]);

  return (
    <Grid item gridColumn="span 12">
      <ControlledCustomField
        control={control}
        name="tagsNames"
        fieldsErrors={fieldsErrors}
        render={(errorMessage, { field: { onChange, value } }) => (
          <Autocomplete
            PaperComponent={PaperPageSelect}
            onChange={(event, value) =>
              value.length === 0
                ? onChange([])
                : onChange([
                    ...value.slice(0, value.length - 1),
                    value[value.length - 1].toLowerCase(),
                  ])
            }
            value={Array.isArray(value) ? (value as string[]) : []}
            onInputChange={inputChangeHandler}
            inputValue={search}
            options={options}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            multiple
            loading={isFetching}
            loadingText={t("loading", { ns: "common" })}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("products.fields.tags")}
                error={!!errorMessage || !!tagError}
                helperText={errorMessage ?? tagError}
                fullWidth
              />
            )}
          />
        )}
      />
    </Grid>
  );
};
