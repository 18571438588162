import React, { useCallback } from "react";
import { Link, LinkProps, useHistory } from "react-router-dom";
import { Box, BoxProps, Typography } from "@mui/material";
import { ChevronLeft } from "../astraIcons/ChevronLeft";
import { RouteState } from "../../types";

export type BackProps = Pick<BoxProps, "sx"> & {
  children: string;
  to?: string;
};

export const Back = ({ children, to, ...props }: BackProps) => {
  const history = useHistory<RouteState>();

  const clickHandler = useCallback<NonNullable<LinkProps["onClick"]>>(
    (event) => {
      if (!to || !!history.location.state?.justGoBack) {
        history.goBack();
        event.preventDefault();
      }
    },
    [history, to]
  );

  return (
    <Box {...props}>
      <Box
        component={Link}
        onClick={clickHandler}
        to={to ?? ""}
        sx={{
          display: "flex",
          alignItems: "center",
          color: "primary.main",
          textDecoration: "underline",

          "&:hover": {
            textDecoration: "none",
            cursor: "pointer",
          },

          span: {
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "500",
            marginTop: "2px",
          },
        }}
      >
        <ChevronLeft />
        <Typography component="span">{children}</Typography>
      </Box>
    </Box>
  );
};
