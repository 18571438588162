import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { Link } from "react-router-dom";
import { usePermissions } from "../../../utils/usePermissions";
import { useTranslation } from "react-i18next";

type SwitchProps = {
  inquiryId: number;
  detailId: number;
  opened: "matching" | "ordering";
};

export const GroupsSwitch = ({ inquiryId, detailId, opened }: SwitchProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  return (
    <ButtonGroup
      size="small"
      sx={{
        position: "absolute",
        right: 0,
        marginRight: 2,
      }}
    >
      {can("getItemsForMatching") && (
        <Button
          component={Link}
          to={`/inquiries/${inquiryId}/details/${detailId}/items/matching`}
          disabled={opened === "matching"}
        >
          {t("items.matching")}
        </Button>
      )}
      {can("getItemsForOrdering") && (
        <Button
          component={Link}
          to={`/inquiries/${inquiryId}/details/${detailId}/items/ordering`}
          disabled={opened === "ordering"}
        >
          {t("items.ordering")}
        </Button>
      )}
    </ButtonGroup>
  );
};
