import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import {
  useEditSupplierMutation,
  useGetSuppliersQuery,
} from "../../../slices/suppliers/api";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { SupplierForm, supplierFormSchemaCreator } from "./SupplierForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";

type EditSupplierForm = {
  id: number;
  name: string;
  code: string;
  active: boolean;
  public: boolean;
};

export const Edit: React.FC = () => {
  const { id, closeDrawer } = useTableUrlTools("/suppliers");
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(supplierFormSchemaCreator);
  const formMethods = useForm<EditSupplierForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isLoading: isDataLoading,
    data,
    error: dataLoadingError,
  } = useGetSuppliersQuery({});
  const [editSupplier, { isLoading: isEditLoading }] =
    useEditSupplierMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("suppliers.errors.edit")
  );

  const supplierData = useMemo(
    () => (data && id ? data.find((supplier) => supplier.id === id) : null),
    [data, id]
  );

  const onSubmit = useCallback(
    (form: EditSupplierForm) =>
      editSupplier(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("suppliers.editSuccess"), `/suppliers/${id}`);
          closeDrawer();
        })
        .catch(catchFormError),
    [catchFormError, closeDrawer, editSupplier, feedSuccess, id, t]
  );

  useEffect(() => {
    if (dataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, dataLoadingError]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!supplierData) {
    return null;
  }

  return (
    <Form
      heading={t("suppliers.editSupplier")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <SupplierForm
          supplierData={supplierData}
          fieldsErrors={fieldsErrors}
          isActiveCheckboxShown={true}
        />
      </FormProvider>
    </Form>
  );
};
