import React, { useCallback, useEffect } from "react";
import * as yup from "yup";
import { Form } from "../../components/wideDrawer/Form";
import { useTranslation } from "react-i18next";
import {
  useAssignItemsPurchaseOrderMutation,
  useGetItemQuery,
} from "../../slices/items/api";
import { useFeedSuccess } from "../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../utils/formHelpers";
import { useForm } from "react-hook-form";
import { AssignItemsPurchaseOrderRequestDto } from "../../slices/items/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema, useYup } from "../../utils/validation";
import { Grid } from "@mui/material";
import { SelectPurchaseOrder } from "./SelectPurchaseOrder";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Spinner } from "../../components/wideDrawer/Spinner";
import { MessageResponseDto, Noop } from "../../types";
import { useQueryIds } from "../../utils/useQueryIds";

const schemaCreator = createYupSchema((t) =>
  yup.object({
    purchaseOrderId: yup
      .number()
      .positive(t("required"))
      .required(t("required")),
  })
);

export type AssignPurchaseOrderForm = AssignItemsPurchaseOrderRequestDto;

type AssignPurchaseOrderProps = {
  inquiryId: number;
  closeDrawer: Noop;
};

export const AssignPurchaseOrder = ({
  inquiryId,
  closeDrawer,
}: AssignPurchaseOrderProps) => {
  const { t } = useTranslation("pages");
  const ids = useQueryIds();

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("items.errors.assignItemsPurchaseOrder")
  );
  const schema = useYup(schemaCreator);
  const { control, handleSubmit, reset } = useForm<AssignPurchaseOrderForm>({
    resolver: yupResolver(schema),
  });

  const [assignItemsPO, { isLoading: isAssigningPO }] =
    useAssignItemsPurchaseOrderMutation();
  const { data: item } = useGetItemQuery(
    !ids || ids.length > 1 ? skipToken : ids[0]
  );

  useEffect(() => {
    if (ids && ids.length === 1 && item && item.purchaseOrder) {
      reset({
        purchaseOrderId: item.purchaseOrder.id,
      });
    }
  }, [ids, item, reset]);

  const onSubmit = useCallback(
    ({ purchaseOrderId }: AssignPurchaseOrderForm) => {
      if (!ids || ids.length === 0) {
        return null;
      }

      assignItemsPO({ itemsIds: ids, inquiryId, purchaseOrderId })
        .unwrap()
        .then((res: MessageResponseDto) => {
          feedSuccess(res.message);
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [assignItemsPO, catchFormError, closeDrawer, feedSuccess, ids, inquiryId]
  );

  if (!item && ids && ids.length <= 1) {
    return <Spinner />;
  }

  if (!ids) {
    return null;
  }

  return (
    <Form
      heading={t("items.assignPurchaseOrder")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isAssigningPO}
      formError={formError}
    >
      <Grid
        container
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
      >
        <SelectPurchaseOrder
          control={control}
          inquiryId={inquiryId}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
    </Form>
  );
};
