import React from "react";
import { colors } from "../../../theme/astra/colors";
import { Box, Card, Stack, Typography } from "@mui/material";

type InquiryLabelBoxProps = {
  inquiryName: string;
  schoolName: string;
  children: React.ReactNode;
};

export const InquiryLabelBox = ({
  inquiryName,
  schoolName,
  children,
}: InquiryLabelBoxProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        border: "1px solid #CFDFEF",
        backgroundColor: colors.whitishGray,
      }}
    >
      <Box sx={{ display: "flex", flex: "1" }}>
        <Box sx={{ padding: "18px 0px 0px 28px" }}>
          <Typography
            component="h3"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.dustBlue2,
              lineHeight: "18px",
            }}
          >
            {inquiryName}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: "10px",
              fontWeight: "400",
              color: colors.dustBlue4,
              lineHeight: "10px",
            }}
          >
            {schoolName}
          </Typography>
        </Box>
      </Box>
      <Stack sx={{ paddingInline: "12px", paddingBlock: "20px" }}>
        {children}
      </Stack>
    </Card>
  );
};
