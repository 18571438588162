import { Actions } from "../permissions/actions";
import { useCallback } from "react";
import { useSelector } from "../store";
import {
  selectPermissions,
  selectSelfRole,
  selectSelfStatus,
} from "../slices/auth/selectors";

export const usePermissions = (): ((action: Actions) => boolean) => {
  const permissions = useSelector(selectPermissions);
  const role = useSelector(selectSelfRole);
  const active = useSelector(selectSelfStatus);

  return useCallback(
    (action: Actions) => {
      if (!role || !permissions) {
        return false;
      }

      if (!active) {
        return permissions["disabled"].includes(action);
      }

      return (
        permissions["authorized"].includes(action) ||
        permissions[role].includes(action)
      );
    },
    [permissions, role, active]
  );
};
