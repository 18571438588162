import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { NewDesignDisplayContainer } from "../../../components/wideDrawer/NewDesignDisplayContainer";
import { useGetUserQuery } from "../../../slices/users/api";
import { usePermissions } from "../../../utils/usePermissions";
import { useSelector } from "../../../store";
import { UserDetails } from "./Details/UserDetails";
import { selectSelfId } from "../../../slices/auth/selectors";

export const Details: React.FC = () => {
  const { t } = useTranslation("pages");
  const { id, closeDrawer } = useTableUrlTools("/users");
  const selfId = useSelector(selectSelfId);
  const { data, error } = useGetUserQuery(id ?? -1);
  const can = usePermissions();

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  const canEdit = useMemo(
    () =>
      can("editUsers") ||
      can("editUsersOfOwnSchool") ||
      (can("editSelf") && id === selfId),
    [can, id, selfId]
  );

  if (!data) {
    return null;
  }

  return (
    <NewDesignDisplayContainer
      heading={`${data.firstName} ${data.lastName}`}
      editHref={`/users/${id}/edit`}
      editLabel={t("users.edit")}
      editingDisabled={!canEdit}
    >
      <UserDetails user={data} />
    </NewDesignDisplayContainer>
  );
};
