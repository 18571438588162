import React from "react";
import { Box, Drawer, DrawerProps, IconButton, styled } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { colors } from "../../theme/astra/colors";

type NewDesignWideDrawerProps = Omit<DrawerProps, "right" | "onClose"> & {
  close: () => void;
};

export const NewDesignWideDrawer: React.FC<NewDesignWideDrawerProps> = ({
  children,
  close,
  ...props
}) => {
  return (
    <StyledWideDrawer anchor="right" onClose={close} {...props}>
      <Box
        sx={{
          margin: "10px",
          backgroundColor: "#FFFFFF", // White
          borderRadius: "4px",
        }}
      >
        <IconButton onClick={close} sx={{ top: "15px", left: "15px" }}>
          <ChevronLeft />
        </IconButton>
        {children}
      </Box>
    </StyledWideDrawer>
  );
};

const StyledWideDrawer = styled(Drawer)({
  width: "465px",
  "& .MuiDrawer-paper": {
    width: "465px",
    backgroundColor: colors.white,
  },
});
