import { createAsyncThunk } from "@reduxjs/toolkit";
import { ShopList } from "./types";
import { saveObjectToLocalStorage } from "../../utils/localStorageTools";
import { shopLocalStorageKeys } from "./localStorageKeys";
import { ThunkApi } from "../../store";
import { selectShopList } from "./selectors";

export const setShopList = createAsyncThunk<
  ShopList,
  Partial<ShopList>,
  ThunkApi
>("setShopList", (list, { getState }) => {
  const currentList = selectShopList(getState());

  const newList = {
    ...currentList,
    ...list,
  };

  saveObjectToLocalStorage(shopLocalStorageKeys.list, newList);

  return newList;
});
