import React, { useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GetInventoryEntryWithChangesResponseDto } from "../../../../slices/inventory/types";
import { useTranslation } from "react-i18next";
import { displayFullName } from "../../../../utils/displayFullName";
import { useDisplayDateTime } from "../../../../utils/useDisplayDateTime";

type ChangesProps = {
  changes: GetInventoryEntryWithChangesResponseDto["changes"];
};

export const Changes = ({ changes }: ChangesProps) => {
  const { t } = useTranslation("pages");

  const displayDate = useDisplayDateTime(false);

  const table = useMemo(() => {
    if (changes.length === 0) {
      return null;
    }

    return changes.map((changeRecord) => (
      <TableRow key={changeRecord.id}>
        <TableCell>{displayDate(changeRecord.date)}</TableCell>
        <TableCell>
          {displayFullName({
            firstName: changeRecord.user.firstName,
            lastName: changeRecord.user.lastName,
          })}
        </TableCell>
        <TableCell>{changeRecord.quantity}</TableCell>
        <TableCell>{changeRecord.comment}</TableCell>
      </TableRow>
    ));
  }, [changes, displayDate]);

  if (changes.length === 0) {
    return null;
  }

  return (
    <Paper
      sx={{
        margin: 2,
        padding: 2,
      }}
    >
      <Typography
        dir="auto"
        variant="h5"
        component="h3"
        sx={{ marginBottom: 2 }}
      >
        {t("inventory.changesHistory")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("inventory.changesTable.date")}</TableCell>
            <TableCell>{t("inventory.changesTable.user")}</TableCell>
            <TableCell>{t("inventory.changesTable.quantity")}</TableCell>
            <TableCell sx={{ width: "40%" }}>
              {t("inventory.changesTable.comment")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{table}</TableBody>
      </Table>
    </Paper>
  );
};
