import React, { useCallback, useState } from "react";
import { Box, ButtonBase, Menu, useTheme } from "@mui/material";
import { User } from "../../astraIcons/User";
import { Logout } from "./Logout";
import { ChangePassword } from "./ChangePassword";

export const UserMenu = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const clickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeHandler = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <ButtonBase
        sx={{
          width: "97px",
          height: "100%",
          borderLeft: `1px solid ${theme.palette.secondary.light}`,
        }}
        onClick={clickHandler}
      >
        <Box
          sx={{
            width: "32px",
            height: "32px",
            borderRadius: "100%",
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <User />
        </Box>
      </ButtonBase>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeHandler}
        sx={{
          zIndex: 10002,
          marginTop: "-7px",
        }}
      >
        <ChangePassword onClick={closeHandler} />
        <Logout onClick={closeHandler} />
      </Menu>
    </>
  );
};
