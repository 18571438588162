import React, { useCallback, useMemo } from "react";
import { useGetCategoriesQuery } from "../../slices/categories/api";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { buildCategoriesTree, buildCategoryName } from "../../utils/categories";
import { PaperPageSelect } from "../layout/PaperPage";
import { useTranslation } from "react-i18next";

type Option = {
  id: number;
  label: string;
};

type AutocompletePropsWithOptions = AutocompleteProps<
  Option,
  true,
  false,
  false
>;

type AutocompleteCategoryProps = Omit<
  AutocompletePropsWithOptions,
  | "onChange"
  | "renderInput"
  | "options"
  | "id"
  | "loading"
  | "value"
  | "multiple"
> & {
  id: string;
  label: string;
  value?: number[];
  onChange?: (categoryId: number[]) => void;
  error?: boolean;
  helperText?: string;
};

export const AutocompleteCategory = ({
  id,
  label,
  onChange,
  error,
  helperText,
  value,
  ...props
}: AutocompleteCategoryProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCategoriesQuery();

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .filter(
        ({ id }) =>
          !data.some(({ parentCategoryId }) => id === parentCategoryId)
      )
      .map((flatCategory) => {
        const category = buildCategoriesTree(flatCategory, data);

        if (category) {
          return {
            id: flatCategory.id,
            label: buildCategoryName(category),
          };
        }
      })
      .filter((category): category is Option => !!category);
  }, [data]);

  const chosenOptions = useMemo(
    () => options.filter(({ id }) => value?.includes(id)),
    [options, value]
  );

  const changeHandler = useCallback<
    NonNullable<AutocompletePropsWithOptions["onChange"]>
  >(
    (event, value) => {
      if (onChange) {
        onChange(value.map(({ id }) => id));
      }
    },
    [onChange]
  );

  return (
    <Autocomplete
      PaperComponent={PaperPageSelect}
      loading={isLoading}
      loadingText={t("loading", { ns: "common" })}
      id={`autocomplete-${id}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      options={options}
      noOptionsText={t("emptySelect", { ns: "common" })}
      multiple
      onChange={changeHandler}
      value={chosenOptions}
      {...props}
    />
  );
};
