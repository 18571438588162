import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useEditInquiryMutation,
  useGetInquiryQuery,
} from "../../../../slices/inquiries/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useYup } from "../../../../utils/validation";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { BasicDetailsForm, basicDetailsSchemaCreator } from "./BasicDetails";
import { useInquiryDetailsUrlTools } from "../../hooks";
import { useBasicDetailsUrlTools } from "../useBasicDetailsUrlTools";
import { EditInquiryDetailsRequestDto } from "../../../../slices/inquiries/types";
import { EditInquiryDetailsForm } from "./types";

export const Edit: React.FC = () => {
  const { t } = useTranslation("pages");
  const { inquiryId: id, baseUrl } = useInquiryDetailsUrlTools();

  const { closeDrawer } = useBasicDetailsUrlTools(`${baseUrl}/details`);

  const feedSuccess = useFeedSuccess();
  const schema = useYup(basicDetailsSchemaCreator);
  const formMethods = useForm<EditInquiryDetailsRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isFetching: isInquiriesDataLoading,
    data: inquiry,
    error: inquiriesDataLoadingError,
  } = useGetInquiryQuery(id ?? skipToken);
  const [editInquiry, { isLoading: isEditLoading }] = useEditInquiryMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("inquiries.errors.edit")
  );

  const onSubmit = useCallback(
    ({ dueDate, ...form }: EditInquiryDetailsForm) => {
      if (!id) {
        return null;
      }
      editInquiry({
        ...form,
        id,
        dueDate: dueDate && dueDate.valueOf() + 12 * 3600 * 1000,
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("inquiries.successes.edit"));
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [catchFormError, closeDrawer, editInquiry, feedSuccess, id, t]
  );

  useEffect(() => {
    if (inquiriesDataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, inquiriesDataLoadingError]);

  if (isInquiriesDataLoading) {
    return <Spinner />;
  }

  if (!inquiry) {
    return null;
  }

  return (
    <Form
      heading={t("inquiries.edit")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <BasicDetailsForm inquiryData={inquiry} fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
