import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetProductQuery } from "../../../slices/products/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { ProductDetails } from "../../../components/ProductDetails";
import { useItemsTableBaseUrl, useProductsDetailsDrawerTools } from "../hooks";
import { useGetCategoriesQuery } from "../../../slices/categories/api";
import { Spinner } from "../../../components/wideDrawer/Spinner";

export const ProductDetailsInItem = () => {
  const { t } = useTranslation("pages");
  const { baseUrl } = useItemsTableBaseUrl();
  const { closeDrawer } = useTableUrlTools(baseUrl);
  const productId = useProductsDetailsDrawerTools(baseUrl);
  const can = usePermissions();

  const na = t("products.NA");
  const {
    data: product,
    isLoading: isProductLoading,
    error: productError,
  } = useGetProductQuery(productId ? { id: productId } : skipToken);
  const { data: categories, isLoading: areCategoriesLoading } =
    useGetCategoriesQuery();

  useEffect(() => {
    if (productError) {
      closeDrawer();
    }
  }, [productError, closeDrawer]);

  if (isProductLoading || areCategoriesLoading) {
    return <Spinner />;
  }

  if (!product || !categories) {
    return null;
  }

  return (
    <DisplayContainer
      heading={product.name ?? na}
      editHref={`/products/${productId}/edit`}
      editLabel={t("products.edit")}
      editingDisabled={!can("editProduct")}
    >
      <ProductDetails product={product} categories={categories} />
    </DisplayContainer>
  );
};
