import * as yup from "yup";
import { createYupSchema } from "../../../../utils/validation";
import { matchOptions } from "../../../../types";
import { SUPPLIER_PRODUCT_CODE_REGEXP } from "../../../../consts";

export const productFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    supplierProductCode: yup
      .string()
      .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
        excludeEmptyString: true,
        message: t("supplierProductCode"),
      })
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    matches: yup.array().of(
      yup.object({
        dbId: yup.number().positive().optional(),
        match: yup.string().oneOf([...matchOptions]),
        supplierId: yup
          .number()
          .positive(t("required"))
          .required(t("required")),
        supplierProductCode: yup
          .string()
          .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
            excludeEmptyString: true,
            message: t("supplierProductCode"),
          })
          .max(256, t("tooLong", { maxLength: 256 }))
          .required(t("required")),
      })
    ),
    name: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    description: yup
      .string()
      .max(10000, t("tooLong", { maxLength: 10000 }))
      .nullable(),
    packSize: yup
      .number()
      .typeError(t("number"))
      .positive(t("packSizePositive"))
      .nullable(),
    color: yup.string().nullable(),
    replacementCommodityCode: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    supplierPrice: yup
      .number()
      .typeError(t("number"))
      .positive(t("positivePrice"))
      .nullable(),
    supplierCurrencyId: yup
      .number()
      .positive(t("required"))
      .when("supplierPrice", {
        is: (value: number | null) => typeof value === "number" && value > 0,
        then: (schema) =>
          schema.required(
            t("requiredWhenRelatedFieldIsNotEmpty", {
              field: t("products.fields.supplierPrice", {
                ns: "pages",
              }).toLowerCase(),
            })
          ),
        otherwise: (schema) => schema.nullable(),
      }),
    retailPrice: yup
      .number()
      .typeError(t("number"))
      .positive(t("positivePrice"))
      .nullable(),
    retailCurrencyId: yup
      .number()
      .positive(t("wrongId"))
      .when("retailPrice", {
        is: (value: number | null) => typeof value === "number" && value > 0,
        then: (schema) =>
          schema.required(
            t("requiredWhenRelatedFieldIsNotEmpty", {
              field: t("products.fields.retailPrice", {
                ns: "pages",
              }).toLowerCase(),
            })
          ),
        otherwise: (schema) => schema.nullable(),
      }),
    weight: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    volume: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    sizeW: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    sizeD: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    sizeH: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .nullable(),
    supplierId: yup.number().positive(t("required")).required(t("required")),
    countryId: yup.number().positive(t("required")).nullable(),
    commodityCodeId: yup.number().positive(t("required")).nullable(),
    tagsNames: yup
      .array()
      .of(yup.string().max(256, t("tagTooLong", { maxLength: 256 })))
      .test("unique", t("tagsMustBeUnique"), (value) => {
        if (!value) {
          return true;
        }

        return (
          value.length === new Set(value.map((tag) => tag?.toLowerCase())).size
        );
      })
      .required(t("required")),
    images: yup.array().of(
      yup.object({
        s3key: yup.string().nullable(true),
        fileList: yup.mixed().nullable(true),
      })
    ),
    active: yup.boolean(),
  })
);

export const editProductFormSchemaCreator = createYupSchema((t) =>
  yup
    .object({
      id: yup.number().positive(t("required")).required(t("required")),
    })
    .concat(productFormSchemaCreator(t))
);
