import { DEFAULT_SORT_MODEL } from "../consts";
import { GridSortModel } from "@mui/x-data-grid";

export const parseSortModel = (sortModel?: GridSortModel) => ({
  sortField:
    sortModel && sortModel.length > 0
      ? sortModel[0].field
      : DEFAULT_SORT_MODEL[0].field,
  sort:
    sortModel && sortModel.length > 0
      ? (sortModel[0].sort as string)
      : (DEFAULT_SORT_MODEL[0].sort as string),
});
