import React from "react";
import { useGetPrincipalsQuery } from "../../slices/users/api";
import { LoadingTextField } from "../LoadingTextField";
import { MenuItem, TextFieldProps } from "@mui/material";
import { displayFullName } from "../../utils/displayFullName";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "react-i18next";

type SelectPrincipalProps = Omit<TextFieldProps, "select"> & {
  schoolId?: number;
};

export const SelectPrincipal = ({
  schoolId,
  value = "",
  ...props
}: SelectPrincipalProps) => {
  const { data = [], isLoading } = useGetPrincipalsQuery(schoolId ?? skipToken);
  const { t } = useTranslation();

  return (
    <LoadingTextField select loading={isLoading} value={value} {...props}>
      {data.map(({ id, firstName, lastName }) => (
        <MenuItem key={id} value={id}>
          {displayFullName({ firstName, lastName })}
        </MenuItem>
      ))}
      {data.length < 1 && (
        <MenuItem key={"empty-list"} disabled>
          {t("emptySelect", { ns: "common" })}
        </MenuItem>
      )}
    </LoadingTextField>
  );
};
