import React, { useCallback, useMemo, useState } from "react";
import { Autocomplete, Box, Chip, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchDebounce } from "../../../utils/useSearchDebounce";
import { useGetRelevanceTagsQuery } from "../../../slices/tags/api";
import { Tag as TagIcon } from "../../../components/astraIcons/Tag";
import { useSelector } from "../../../store";
import { selectShopList } from "../../../slices/shop/selectors";

export type TagsFieldProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

export const TagsField = ({ value, onChange }: TagsFieldProps) => {
  const { t } = useTranslation("pages");
  const theme = useTheme();

  const { departmentId } = useSelector(selectShopList);

  const [search, setSearch] = useState("");
  const debouncedSearch = useSearchDebounce(search);

  const { data: tags = [], isFetching: areTagsFetching } =
    useGetRelevanceTagsQuery({
      departmentId: departmentId,
      search: debouncedSearch,
      tags: value,
    });

  const inputChangeHandler = useCallback((_event, value: string) => {
    setSearch(value);
  }, []);

  const changeHandler = useCallback(
    (_event, value: string[]) => {
      onChange(value);
    },
    [onChange]
  );

  const options = useMemo(
    () => tags.map(({ name }) => name).filter((tag) => !value.includes(tag)),
    [tags, value]
  );

  const isEmpty = value.length < 1;

  return (
    <Autocomplete
      size="medium"
      options={options}
      getOptionLabel={(option) => option}
      inputValue={search}
      onInputChange={inputChangeHandler}
      value={value}
      onChange={changeHandler}
      loading={areTagsFetching}
      loadingText={t("loading", { ns: "common" })}
      multiple
      clearOnBlur
      selectOnFocus
      renderTags={(tags, getTagProps) =>
        tags.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({ index })}
            key={option}
            size="small"
            variant="filled"
            sx={{
              margin: "0 !important",
              marginLeft: "8px !important",
            }}
          />
        ))
      }
      renderInput={({
        InputProps: { startAdornment, ...InputProps },
        ...params
      }) => (
        <TextField
          variant="outlined"
          placeholder={t("shopCatalog.filters.tagsPlaceholder")}
          InputProps={{
            ...InputProps,
            startAdornment: (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    svg: {
                      color: isEmpty
                        ? "inherit"
                        : `${theme.palette.pink.main} !important`,
                    },
                  }}
                >
                  <TagIcon />
                </Box>
                {startAdornment}
              </>
            ),
          }}
          {...params}
        />
      )}
      sx={{
        ".MuiOutlinedInput-root": {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: "16px",
        },
        ".MuiOutlinedInput-input": {
          paddingTop: "8px !important",
          paddingRight: "16px !important",
          paddingBottom: "8px !important",
          paddingLeft: isEmpty ? "4px !important" : "8px !important",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        ":hover, .Mui-focused": {
          zIndex: 1,
          position: "relative",
        },
      }}
    />
  );
};
