import React from "react";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Block } from "./Block";
import { NumericFormat } from "react-number-format";
import { numberPriceFromSample } from "../../../utils/numberPriceFromSample";
import { useDisplayPrice } from "../../../utils/useDisplayPrice";
import { useSelector } from "../../../store";
import { selectSelfSchoolCurrency } from "../../../slices/auth/selectors";

type PriceField = {
  value: number | null;
  onChange: (value: number | null) => void;
};

export type PriceRangeProps = {
  minPrice: PriceField;
  maxPrice: PriceField;
};

export const PriceRange = ({
  minPrice: { value: minPriceValue, onChange: onMinPriceChange },
  maxPrice: { value: maxPriceValue, onChange: onMaxPriceChange },
}: PriceRangeProps) => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();
  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  return (
    <Block
      label={`${t("shopCatalog.filters.priceRange")}, ${
        selfCurrency?.code || "QAR"
      }`}
    >
      <Box sx={{ display: "flex" }}>
        <NumericFormat
          value={minPriceValue && minPriceValue / 100}
          renderText={(value) => displayPrice(value)}
          onChange={(event) =>
            onMinPriceChange(numberPriceFromSample(event.target.value) * 100)
          }
          thousandSeparator=" "
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
          customInput={TextField}
          variant="outlined"
          size="medium"
          placeholder={t("from", { ns: "common" })}
          sx={{
            backgroundColor: "common.white",

            ".MuiOutlinedInput-notchedOutline": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },

            ":hover, .Mui-focused": {
              zIndex: 1,
              position: "relative",
            },
          }}
        />
        <NumericFormat
          value={maxPriceValue && maxPriceValue / 100}
          renderText={(value) => displayPrice(value)}
          onChange={(event) =>
            onMaxPriceChange(numberPriceFromSample(event.target.value) * 100)
          }
          thousandSeparator=" "
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
          customInput={TextField}
          variant="outlined"
          size="medium"
          placeholder={t("to", { ns: "common" })}
          sx={{
            backgroundColor: "common.white",
            marginLeft: "-1px",

            ".MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }}
        />
      </Box>
    </Block>
  );
};
