import React from "react";
import { Typography, TypographyProps } from "@mui/material";

type DescriptionProps = Omit<
  TypographyProps<"div">,
  "dangerouslySetInnerHTML" | "variant"
> & {
  content: string;
};

export const Description = ({ content, ...props }: DescriptionProps) => {
  return (
    <Typography
      component="div"
      variant="body2"
      dir="auto"
      dangerouslySetInnerHTML={{ __html: content }}
      {...props}
    />
  );
};
