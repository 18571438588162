import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { createYupSchema, useYup } from "../../../utils/validation";
import { useForm, useWatch } from "react-hook-form";
import { AddItemToAssignmentForm } from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import * as yup from "yup";
import { useAddItemToAssignmentMutation } from "../../../slices/schoolInquiries/api";
import { SUPPLIER_PRODUCT_CODE_REGEXP } from "../../../consts";
import { Box, Button, Divider, Grid } from "@mui/material";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { NewDesignForm } from "../../../components/wideDrawer/NewDesignForm";
import { StyledFieldTitle } from "../../SignIn/StyledComponents";
import { NewDesignControlledNumericField } from "../../../components/controlledFields/NewDesignControlledNumericField";
import { NewDesignFreeAutocompleteItemName } from "../../../components/prefilledSelectors/NewDesignFreeAutocompleteItemName";
import { NewDesignFreeAutocompleteSupplierCode } from "../../../components/prefilledSelectors/NewDesignFreeAutocompleteSupplierCode";
import { NewDesignSelectSupplier } from "../../../components/prefilledSelectors/NewDesignSelectSupplier";
import { colors } from "../../../theme/astra/colors";
import { useHistory } from "react-router-dom";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";

export const AddItem = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const history = useHistory();

  const { closeDrawer, inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const schema = useYup(itemSchemaCreator);
  const { handleSubmit, control, setValue } = useForm<AddItemToAssignmentForm>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiryDetails.errors.addAssignment")
  );

  const selectedSupplierId = useWatch({
    control,
    name: "supplierId",
  });

  const setName = useCallback(
    (name: string) => {
      setValue("name", name);
    },
    [setValue]
  );

  const setSupplier = useCallback(
    (id: number) => {
      setValue("supplierId", id);
    },
    [setValue]
  );

  const setSupplierProductCode = useCallback(
    (spc: string) => {
      setValue("supplierProductCode", spc);
    },
    [setValue]
  );

  const [addItem, { isLoading: isAdding }] = useAddItemToAssignmentMutation();

  const submitHandler = useCallback(
    (form: AddItemToAssignmentForm) => {
      if (departmentInquiryId && assignmentId) {
        addItem({ inquiryId, departmentInquiryId, assignmentId, ...form })
          .unwrap()
          .then((res) => {
            feedSuccess(res.message);
            closeDrawer();
          })
          .catch(catchFormError);
      }
    },
    [
      addItem,
      assignmentId,
      catchFormError,
      closeDrawer,
      departmentInquiryId,
      feedSuccess,
      inquiryId,
    ]
  );

  const goToEShopHandler = useCallback(() => {
    history.push({
      pathname: "/shop",
      state: {
        shopCurrentList:
          inquiryId && departmentInquiryId
            ? [inquiryId, departmentInquiryId]
            : undefined,
        restoreParams: true,
        scrollToTop: true,
      },
    });
  }, [departmentInquiryId, history, inquiryId]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "20px 32px 0px 32px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{ marginTop: "20px" }}
          onClick={goToEShopHandler}
        >
          {t("schoolInquiryDetails.addItemForm.addFromEshopLabel")}
        </Button>
        <Divider
          sx={{ fontSize: "13px", color: colors.dustBlue2, fontWeight: 500 }}
        >
          {t("schoolInquiryDetails.addItemForm.addManuallyLabel")}
        </Divider>
      </Box>
      <NewDesignForm
        heading={t("schoolInquiryDetails.addItem")}
        onSubmit={handleSubmit(submitHandler)}
        loading={isAdding}
        formError={formError}
        submitText={t("schoolInquiryDetails.addItem")}
      >
        <Grid
          container
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
        >
          <Grid item gridColumn="span 12">
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {`${t("items.fields.supplier")} *`}
            </StyledFieldTitle>
            <ControlledCustomField
              control={control}
              name="supplierId"
              fieldsErrors={fieldsErrors}
              render={(errorMessage, { field }) => {
                return (
                  <NewDesignSelectSupplier
                    id="item"
                    label={`${t("items.fields.supplier")} *`}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    fullWidth
                    publicOnly
                    selectProps={field}
                    size="small"
                    value={field.value}
                  />
                );
              }}
            />
          </Grid>
          <Grid item gridColumn="span 12">
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {`${t("items.fields.supplierProductCode")} *`}
            </StyledFieldTitle>
            <ControlledCustomField
              control={control}
              name="supplierProductCode"
              fieldsErrors={fieldsErrors}
              render={(errorMessage, { field: { value, onChange } }) => {
                return (
                  <NewDesignFreeAutocompleteSupplierCode
                    id="item"
                    onChange={onChange}
                    setName={setName}
                    value={(value as string) ?? ""}
                    supplierId={selectedSupplierId}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    label={`${t("items.fields.supplierProductCode")} *`}
                  />
                );
              }}
            />
          </Grid>
          <Grid item gridColumn="span 12">
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {`${t("items.fields.productName")} *`}
            </StyledFieldTitle>
            <ControlledCustomField
              control={control}
              name="name"
              fieldsErrors={fieldsErrors}
              render={(errorMessage, { field: { value, onChange } }) => {
                return (
                  <NewDesignFreeAutocompleteItemName
                    id="item"
                    onChange={onChange}
                    setSupplier={setSupplier}
                    setSupplierProductCode={setSupplierProductCode}
                    value={(value as string) ?? ""}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    label={`${t("items.fields.productName")} *`}
                  />
                );
              }}
            />
          </Grid>
          <Grid item gridColumn="span 12">
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {`${t("items.fields.quantity")} *`}
            </StyledFieldTitle>
            <NewDesignControlledNumericField
              fullWidth
              control={control}
              name="quantity"
              label={t("items.fields.quantity")}
              fieldsErrors={fieldsErrors}
              defaultValue={1}
              positiveOnly
            />
          </Grid>
        </Grid>
      </NewDesignForm>
    </>
  );
};

const itemSchemaCreator = createYupSchema((t) =>
  yup.object({
    supplierId: yup.number().min(0, t("required")).required(t("required")),
    supplierProductCode: yup
      .string()
      .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
        excludeEmptyString: true,
        message: t("supplierProductCode"),
      })
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    name: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    quantity: yup
      .number()
      .typeError(t("number"))
      .min(1, t("numMustBeGreater", { minNum: 0 }))
      .required(t("required")),
  })
);
