import React from "react";
import { createSvgIcon } from "@mui/material";

export const ArrowLeft = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.73214 17.7925C10.0318 18.0785 10.5065 18.0675 10.7925 17.7679C11.0785 17.4682 11.0675 16.9935 10.7679 16.7075L6.62192 12.75L19.25 12.75C19.6642 12.75 20 12.4142 20 12C20 11.5858 19.6642 11.25 19.25 11.25L6.62192 11.25L10.7679 7.29252C11.0675 7.00651 11.0785 6.53177 10.7925 6.23214C10.5065 5.93252 10.0318 5.92148 9.73214 6.20748L4.23214 11.4575C4.08388 11.599 4 11.795 4 12C4 12.205 4.08388 12.401 4.23214 12.5425L9.73214 17.7925Z"
      fill="currentColor"
    />
  </svg>,
  "ArrowLeft"
);
