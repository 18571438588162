import { createYupSchema } from "../../../../utils/validation";
import * as yup from "yup";

export const assignmentSchemaCreator = createYupSchema((t) =>
  yup.object({
    budget: yup
      .number()
      .typeError(t("number"))
      .positive()
      .nullable()
      .optional(),
    budgetCurrencyId: yup.number().positive(t("required")).optional(),
  })
);

export const addAssignmentSchemaCreator = createYupSchema((t) =>
  assignmentSchemaCreator(t).concat(
    yup.object({
      teacherId: yup.number().positive(t("required")).required(t("required")),
    })
  )
);
