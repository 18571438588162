import React from "react";
import { Helmet } from "react-helmet";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { Edit } from "./Drawers/Edit";
import { Add } from "./Drawers/Add";
import { usePermissions } from "../../utils/usePermissions";
import { ProductDetailsInItem } from "./tables/ProductDetailsInItem";
import { CommentDrawer } from "../../components/legacyLayout/Comments/CommentDrawer";
import { Box } from "@mui/material";
import { MatchingTable } from "./tables/MatchingTable/MatchingTable";
import { OrderingTable } from "./tables/OrderingTable/OrderingTable";
import { useItemsTableBaseUrl, useProductsDetailsDrawerTools } from "./hooks";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { useRouteMatch } from "react-router-dom";
import { AssignPurchaseOrder } from "./Drawers/AssignPurchaseOrder";
import { AssignMainOrder } from "./Drawers/AssignMainOrder";

export const Items: React.FC = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { tableName, detailId, baseUrl } = useItemsTableBaseUrl();
  const { editDrawerOpen, addDrawerOpen, closeDrawer } =
    useTableUrlTools(baseUrl);
  const productDetailsDrawerMatcher = useProductsDetailsDrawerTools(baseUrl);
  const assignPurchaseOrderMatcher = useRouteMatch(
    `${baseUrl}/assignPurchaseOrder`
  );
  const assignMainOrderMatcher = useRouteMatch(`${baseUrl}/assignMainOrder`);

  return (
    <Box sx={{ marginRight: "240px" }}>
      <Helmet>
        <title>{getTitle(t("items.title"))}</title>
      </Helmet>
      {can("getItems") &&
        can("getItemsForMatching") &&
        tableName === "matching" && <MatchingTable />}
      {can("getItems") &&
        can("getItemsForOrdering") &&
        tableName === "ordering" && <OrderingTable />}
      {can("addItem") && (
        <WideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add />
        </WideDrawer>
      )}
      {can("editItem") && (
        <WideDrawer open={editDrawerOpen} close={closeDrawer}>
          <Edit />
        </WideDrawer>
      )}
      {can("getProduct") && (
        <WideDrawer open={!!productDetailsDrawerMatcher} close={closeDrawer}>
          <ProductDetailsInItem />
        </WideDrawer>
      )}
      {can("assignItemPurchaseOrder") && (
        <WideDrawer open={!!assignPurchaseOrderMatcher} close={closeDrawer}>
          <AssignPurchaseOrder />
        </WideDrawer>
      )}
      {can("assignItemMainOrder") && (
        <WideDrawer open={!!assignMainOrderMatcher} close={closeDrawer}>
          <AssignMainOrder />
        </WideDrawer>
      )}
      <CommentDrawer
        commentableType="department_inquiry"
        commentableId={detailId}
      />
    </Box>
  );
};
