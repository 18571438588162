import React, { useEffect } from "react";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { useTranslation } from "react-i18next";
import { useGetSearchRecordQuery } from "../../../slices/searches/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { EntityDetailsGrid } from "../../../components/EntityDetailsGrid";
import { Typography } from "@mui/material";

type DetailsProps = {
  id?: number;
  onClose: () => void;
};

export const Details = ({ onClose, id }: DetailsProps) => {
  const { t } = useTranslation("pages");
  const displayDateTime = useDisplayDateTime();

  const na = t("na", { ns: "common" });

  const { data, error, isLoading } = useGetSearchRecordQuery(id ?? skipToken);

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error, onClose]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!id || !data) {
    return null;
  }

  return (
    <DisplayContainer heading={`${t("searches.detailsTitle")} ${id}`}>
      <EntityDetailsGrid
        blocks={[
          [
            {
              label: t("searches.columns.date"),
              value: displayDateTime(data.createdAt),
              xs: 6,
            },
            {
              label: t("searches.columns.numberOfResults"),
              value: `${data.results}`,
              xs: 6,
            },
            {
              label: t("searches.columns.search"),
              value: data.search || na,
              xs: 12,
            },
          ],
          "divider",
          [
            {
              xs: 12,
              view: (
                <>
                  <Typography
                    variant="h5"
                    component="h3"
                    sx={{ marginBottom: 2 }}
                  >
                    {t("searches.columns.filters")}
                  </Typography>
                  <EntityDetailsGrid
                    blocks={[
                      [
                        {
                          label: t("searches.filters.tags"),
                          xs: 6,
                          value:
                            data.tags.length > 0 ? data.tags.join(", ") : na,
                        },
                        {
                          label: t("searches.filters.priceRange"),
                          xs: 6,
                          value: `${t("searches.filters.from")} ${
                            data.minPrice || t("searches.defaults.priceFrom")
                          } ${t("searches.filters.to")} ${
                            data.maxPrice || t("searches.defaults.priceTo")
                          }`,
                        },
                        {
                          label: t("searches.filters.schoolLevels"),
                          xs: 6,
                          value:
                            data.schoolLevels.length > 0
                              ? data.schoolLevels
                                  .map((sl) =>
                                    t(`schoolLevel.${sl}`, { ns: "common" })
                                  )
                                  .join(", ")
                              : na,
                        },
                        {
                          label: t("searches.filters.suppliers"),
                          xs: 6,
                          value:
                            data.suppliers.length > 0
                              ? data.suppliers.join(", ")
                              : na,
                        },
                      ],
                    ]}
                  />
                </>
              ),
            },
          ],
        ]}
      />
    </DisplayContainer>
  );
};
