import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import {
  useEditCategoryMutation,
  useGetCategoriesQuery,
} from "../../../slices/categories/api";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { CategoryForm, categoryFormSchemaCreator } from "./CategoryForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditCategoryRequestDto } from "../../../slices/categories/types";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";

export const Edit: React.FC = () => {
  const { id, closeDrawer } = useTableUrlTools("/categories");
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(categoryFormSchemaCreator);
  const formMethods = useForm<EditCategoryRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isLoading: isDataLoading,
    data,
    error: dataLoadingError,
  } = useGetCategoriesQuery();
  const [editCategory, { isLoading: isEditLoading }] =
    useEditCategoryMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("categories.errors.edit")
  );

  const categoryData = useMemo(() => {
    if (!data || !id) {
      return null;
    }
    const category = data.find((category) => category.id === id);
    if (!category) {
      return null;
    }

    return category;
  }, [data, id]);

  const onSubmit = useCallback(
    ({ parentCategoryId: parentId, ...form }: EditCategoryRequestDto) => {
      const parentCategoryId = form.level === "1" ? -1 : parentId;
      editCategory({ ...form, parentCategoryId })
        .unwrap()
        .then(() => {
          feedSuccess(t("categories.editSuccess"), `/categories/${id}`);
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [catchFormError, closeDrawer, editCategory, feedSuccess, id, t]
  );

  useEffect(() => {
    if (dataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, dataLoadingError]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!categoryData) {
    return null;
  }

  return (
    <Form
      heading={t("categories.editCategory")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <CategoryForm categoryData={categoryData} fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
