import React from "react";
import { Circular } from "./spinners/Circular";
import { IconButton, IconButtonProps } from "./astraComponents/IconButton";

type LoadingIconButtonProps = IconButtonProps & {
  loading?: boolean;
  size?: number;
};

export const LoadingIconButton = ({
  loading,
  size,
  children,
  ...props
}: LoadingIconButtonProps) => {
  return (
    <IconButton {...props}>
      {loading ? <Circular size={size ?? 20} /> : children}
    </IconButton>
  );
};
