import React, { useCallback } from "react";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { TextFieldCircular } from "../spinners/TextFieldCircular";

type Option = {
  label: string;
  value: string;
};

type NewDesignMultipleSelectFilterProps = Omit<
  TextFieldProps,
  "id" | "onChange"
> & {
  id: string;
  label: string;
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  loading?: boolean;
  selectStyles?: { borderRadius: string };
};

export const NewDesignMultipleSelectFilter = ({
  id,
  label,
  options,
  value,
  onChange,
  loading,
  selectStyles,
  ...props
}: NewDesignMultipleSelectFilterProps) => {
  const selectChangeHandler = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;
      onChange(typeof value === "string" ? value.split(", ") : value);
    },
    [onChange]
  );

  return (
    <TextField
      id={`custom-datagrid-filter-${id}`}
      select
      SelectProps={{
        multiple: true,
        startAdornment: loading && <TextFieldCircular />,
        renderValue: (value) =>
          options
            .filter((option) => (value as string[]).includes(option.value))
            .map(({ label }) => label)
            .join(", "),
        onChange: (e) => {
          selectChangeHandler(e as SelectChangeEvent<string[]>);
        },
      }}
      value={value}
      size="small"
      label={label}
      variant="outlined"
      sx={{
        minWidth: "195px",
        display: "flex",
        flexGrow: 1,
        flexBasis: 0,
        "& .MuiOutlinedInput-notchedOutline": {
          ...selectStyles,
        },
      }}
      {...props}
    >
      {options.map(({ label, value: optionValue }) => (
        <MenuItem key={optionValue} value={optionValue}>
          <Checkbox checked={value.indexOf(optionValue) > -1} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </TextField>
  );
};
