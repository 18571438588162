import React, { memo } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Circular } from "../spinners/Circular";
import { LoadingButton } from "@mui/lab";

export type ToolbarProps = {
  addButtonText?: string;
  addButtonDisabled?: boolean;
  onAddClick?: () => void;
  additionalButtonText?: string;
  additionalButtonDisabled?: boolean;
  additionalButtonDownloadInProcess?: boolean;
  onAdditionalClick?: () => void;
  hideAddButtonPlusSymbol?: boolean;
  wideSearch?: boolean;
  searchValue: string;
  searchLabel?: string;
  onSearchChange: StandardTextFieldProps["onChange"];
  onReset: () => void;
  filters?: React.ReactNode;
  buttons?: React.ReactNode;
  contentLoading?: boolean;
};

/**
 * Replace with NewDesignToolbar. This is component for old design.
 * @deprecated
 */

export const Toolbar = memo<ToolbarProps>(
  ({
    addButtonText,
    addButtonDisabled,
    onAddClick,
    additionalButtonText,
    additionalButtonDisabled,
    additionalButtonDownloadInProcess,
    onAdditionalClick,
    searchValue,
    searchLabel,
    hideAddButtonPlusSymbol,
    onSearchChange,
    onReset,
    filters,
    buttons,
    wideSearch,
    contentLoading,
  }) => {
    const { t } = useTranslation("components");

    return (
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        {addButtonText && onAddClick && (
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={hideAddButtonPlusSymbol ? null : <Add />}
              variant="outlined"
              onClick={onAddClick}
              disabled={addButtonDisabled}
            >
              {addButtonText}
            </Button>

            {additionalButtonText &&
              onAdditionalClick &&
              (!additionalButtonDownloadInProcess ? (
                <Button
                  variant="outlined"
                  onClick={onAdditionalClick}
                  disabled={additionalButtonDisabled}
                >
                  {additionalButtonText}
                </Button>
              ) : (
                <LoadingButton loading variant="outlined" />
              ))}
          </Stack>
        )}

        {buttons}
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", flexGrow: 1 }}
          spacing={2}
        >
          {filters}
          <TextField
            variant="outlined"
            size="small"
            label={searchLabel || t("toolbar.search")}
            value={searchValue}
            onChange={onSearchChange}
            sx={{ flexGrow: wideSearch ? 1 : 0 }}
          />
          {contentLoading ? (
            <Box
              sx={{
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Circular size={25} thickness={175} />
            </Box>
          ) : (
            <IconButton onClick={onReset}>
              <Close />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  }
);
