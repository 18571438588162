import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { usePermissions } from "../../../../utils/usePermissions";
import { useGetProductQuery } from "../../../../slices/products/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { useGetCategoriesQuery } from "../../../../slices/categories/api";
import { ProductDetails } from "../../../../components/ProductDetails";

export const Details: React.FC = () => {
  const { t } = useTranslation("pages");
  const { id, closeDrawer } = useTableUrlTools("/products");
  const can = usePermissions();

  const {
    data: product,
    isLoading: isProductLoading,
    error: productError,
  } = useGetProductQuery(id ? { id } : skipToken);

  const { data: categories, isLoading: areCategoriesLoading } =
    useGetCategoriesQuery();

  useEffect(() => {
    if (productError) {
      closeDrawer();
    }
  }, [productError, closeDrawer]);

  if (isProductLoading || areCategoriesLoading) {
    return <Spinner />;
  }

  if (!product || !categories) {
    return null;
  }

  const na = t("products.NA");

  return (
    <DisplayContainer
      heading={product.name ?? na}
      editHref={`/products/${id}/edit`}
      editLabel={t("products.edit")}
      editingDisabled={!can("editProduct")}
    >
      <ProductDetails product={product} categories={categories} />
    </DisplayContainer>
  );
};
