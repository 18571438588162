import React from "react";
import { useTranslation } from "react-i18next";
import { ACTIVE_FILTER_OPTIONS } from "../../../consts";
import { NewDesignMultipleSelectFilter } from "../../toolbar/NewDesignMultipleSelectFilter";

export type NewDesignActiveFilterProps = {
  onActivitiesFilterChange: (value: string[]) => void;
  activitiesFilter: string[];
};

export const NewDesignActiveFilter: React.FC<NewDesignActiveFilterProps> =
  React.memo(
    ({
      onActivitiesFilterChange,
      activitiesFilter,
    }: NewDesignActiveFilterProps) => {
      const { t } = useTranslation();

      return (
        <NewDesignMultipleSelectFilter
          id="by-activity"
          label={t("activity")}
          options={ACTIVE_FILTER_OPTIONS.map((option) => ({
            label: option,
            value: option,
          }))}
          onChange={onActivitiesFilterChange}
          value={activitiesFilter}
          selectStyles={{ borderRadius: "4px 0px 0px 4px" }}
        />
      );
    }
  );
