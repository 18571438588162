import React, { useCallback, useMemo } from "react";
import { Link as MaterialLink, Tooltip } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useGetCommoditycodesQuery } from "../../../slices/commoditycodes/api";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { BooleanCheckboxDisplay } from "../../../components/dataDisplays/BooleanCheckboxDisplay";
import { useTranslation } from "react-i18next";
import { propToString } from "../../../utils/propToString";
import { Commoditycode } from "../../../slices/commoditycodes/types";
import { Actions } from "./Actions";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";
import { getShortText } from "../../../utils/getShortText";

export const DataTable: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const can = usePermissions();
  const { isLoading, isFetching, data, error, refetch } =
    useGetCommoditycodesQuery();

  const onAddClick = useCallback(
    () => history.push("/commoditycodes/add"),
    [history]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: propToString<Commoditycode>().id.toString(),
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.1,
      },
      {
        field: propToString<Commoditycode>().code,
        headerName: t("code"),
        flex: 1,
        renderCell: (params) => (
          <MaterialLink component={Link} to={`/commoditycodes/${params.id}`}>
            {params.value}
          </MaterialLink>
        ),
      },
      {
        field: propToString<Commoditycode>().description,
        headerName: t("description"),
        flex: 1,
        renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{getShortText(params.value)}</span>
          </Tooltip>
        ),
      },
      {
        field: propToString<Commoditycode>().requireApproval.toString(),
        headerName: t("requireApproval"),
        flex: 1,
        renderCell: (params) => (
          <BooleanCheckboxDisplay value={!!params.value} />
        ),
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => <Actions id={params.id} />,
      },
    ],
    [t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("commoditycodes.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <CustomDataGrid
        addButtonText={t("commoditycodes.addCommoditycode")}
        addButtonDisabled={!can("addCommoditycode")}
        onAddClick={onAddClick}
        tableName="commoditycodes"
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={[
          propToString<Commoditycode>().id.toString(),
          propToString<Commoditycode>().code,
          propToString<Commoditycode>().description,
        ]}
      />
    </PaperPage>
  );
};
