import React, { useMemo } from "react";
import { useSchoolInquiriesList } from "../../hooks";
import { NoInquiries } from "../NoInquiries";
import { useGetSchoolInquiriesForPrincipalsQuery } from "../../../../slices/schoolInquiries/api";
import { Box, Grid } from "@mui/material";
import { ListSkeleton } from "../ListSkeleton";
import { Pagination } from "../Pagination";
import { ContainerBase } from "../../../../components/layout/ContainerBase";
import { SchoolInquiriesToolbar } from "../SchoolInquiriesToolbar";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../utils/usePermissions";
import { Inquiry } from "./Inquiry/Inquiry";

export const PrincipalsList = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const {
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    filter,
    filterChangeHandler,
    userId,
    addHandler,
  } = useSchoolInquiriesList();

  const { data, isLoading } = useGetSchoolInquiriesForPrincipalsQuery({
    page,
    pageSize,
    filter,
    userId,
  });

  const skeletons = useMemo(() => {
    if (!isLoading) {
      return null;
    }

    return <ListSkeleton pageSize={pageSize} />;
  }, [isLoading, pageSize]);

  const content = useMemo(() => {
    if (isLoading || !data) {
      return null;
    }

    if (data.data.length === 0) {
      return <NoInquiries />;
    }

    return (
      <>
        <Grid container spacing={2}>
          {data.data.map((inquiry) => (
            <Grid key={inquiry.id} item xs={12}>
              <Inquiry inquiry={inquiry} />
            </Grid>
          ))}
        </Grid>
        <Pagination
          page={page}
          onPageChange={pageChangeHandler}
          pageSize={pageSize}
          onPageSizeChange={pageSizeChangeHandler}
          count={data.count}
        />
      </>
    );
  }, [
    data,
    isLoading,
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
  ]);

  return (
    <ContainerBase sx={{ paddingInline: "15px" }} maxWidth="xl">
      <Box sx={{ display: "flex", width: "100%" }}>
        <SchoolInquiriesToolbar
          addButtonText={t("schoolInquiries.newInquiry")}
          onAddClick={can("addSchoolInquiry") ? addHandler : undefined}
          addButtonDisabled={!can("addSchoolInquiry")}
          isLoading={isLoading}
          filter={filter}
          onFilterChange={filterChangeHandler}
          filterCount={data?.filterCount}
        />
      </Box>
      {skeletons}
      {content}
    </ContainerBase>
  );
};
