import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import { fieldError } from "../../utils/formHelpers";
import { ControlledFieldProps } from "./types";

export const NewDesignControlledTextField = <TFieldValues extends FieldValues>({
  control,
  defaultValue,
  name,
  fieldsErrors,
  label,
  disableLabel,
  ...props
}: ControlledFieldProps<TFieldValues> &
  Omit<TextFieldProps<"outlined">, "variant" | "InputLabelProps"> & {
    disableLabel?: boolean;
  }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { invalid, error } }) => {
        const [serverInvalid, serverError] = fieldError(name, fieldsErrors);
        return (
          <TextField
            variant="outlined"
            size="small"
            placeholder={disableLabel ? undefined : label ?? name}
            error={invalid || serverInvalid}
            helperText={error?.message || serverError}
            fullWidth
            InputLabelProps={{ shrink: field.value }}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
};
