import React, { useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { logOut } from "../../../slices/auth/actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../../store";
import { useHistory } from "react-router-dom";
import { Logout as LogoutIcon } from "../../astraIcons/Logout";

type LogoutProps = {
  onClick?: () => void;
};

export const Logout = ({ onClick }: LogoutProps) => {
  const { t } = useTranslation("components");
  const dispatch = useDispatch();
  const history = useHistory();

  const clickHandler = useCallback(() => {
    dispatch(logOut());
    history.push("/");

    if (onClick) {
      onClick();
    }
  }, [dispatch, history, onClick]);

  return (
    <MenuItem onClick={clickHandler}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText>{t("header.logout")}</ListItemText>
    </MenuItem>
  );
};
