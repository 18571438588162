import React from "react";
import { SendToHead } from "./SendToHead";
import { NeedReview } from "./NeedReview";
import { Approve } from "./Approve";
import { ContextMenu } from "./ContextMenu";

export const Assignment = () => {
  return (
    <>
      <SendToHead />
      <NeedReview />
      <Approve />
      <ContextMenu />
    </>
  );
};
