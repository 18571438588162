import React, { useCallback, useMemo } from "react";
import { Link as MaterialLink } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetUsersQuery } from "../../../slices/users/api";
import { FilteredCustomDataGrid } from "./FilteredCustomDataGrid";
import { Actions } from "./Actions";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";
import { User } from "../../../entities";
import { getNormalizedName } from "../../../utils/getNormalizedName";

export const DataTable: React.FC = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const can = usePermissions();

  const { isLoading, isFetching, data, error, refetch } = useGetUsersQuery();

  const onAddClick = useCallback(() => history.push("/users/add"), [history]);

  const columns = useMemo<GridColDef<User>[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        headerAlign: "center",
        align: "center",
        type: "number",
        flex: 0.02,
        minWidth: 40,
        width: 40,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "name",
        headerName: t("columns.name", { ns: "common" }),
        headerAlign: "left",
        flex: 0.3,
        valueGetter: ({ row }) => getNormalizedName(row),
        renderCell: ({ id, row }) => {
          const normalizedName = getNormalizedName(row);
          return (
            <Tooltip title={normalizedName}>
              <MaterialLink component={Link} to={`/users/${id}`}>
                {normalizedName}
              </MaterialLink>
            </Tooltip>
          );
        },
      },
      {
        field: "school",
        headerName: t("users.fields.school"),
        headerAlign: "left",
        flex: 0.3,
        renderCell: ({ row }) => row.school?.name ?? null,
      },
      {
        field: "role",
        headerName: t("users.fields.role"),
        headerAlign: "left",
        flex: 0.3,
        renderCell: ({ row }) => t(`roles.${row.role}`, { ns: "common" }),
      },
      {
        field: "actions",
        headerName: "",
        type: "actions",
        minWidth: 70,
        width: 70,
        renderCell: ({ row }) => <Actions id={row.id} active={row.active} />,
      },
    ],
    [t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("users.errors.refetch")}
      />
    );
  }

  if (isLoading) {
    return <PaperPageSpinner />;
  }

  if (!data) {
    return null;
  }

  return (
    <PaperPage>
      <FilteredCustomDataGrid
        addButtonText={t("users.add")}
        addButtonDisabled={
          !can("addUser") &&
          !can("addUsersOfOwnSchool") &&
          !can("addTeachersOfOwnSchool")
        }
        onAddClick={onAddClick}
        tableName="users"
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={["id", "firstName", "lastName", "role"]}
      />
    </PaperPage>
  );
};
