import React from "react";
import { createSvgIcon } from "@mui/material";

export const ArrowRight = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2679 6.20748C13.9682 5.92148 13.4935 5.93252 13.2075 6.23214C12.9215 6.53177 12.9325 7.00651 13.2321 7.29252L17.3781 11.25H4.75C4.33579 11.25 4 11.5858 4 12C4 12.4142 4.33579 12.75 4.75 12.75H17.3781L13.2321 16.7075C12.9325 16.9935 12.9215 17.4682 13.2075 17.7679C13.4935 18.0675 13.9682 18.0785 14.2679 17.7925L19.7679 12.5425C19.9161 12.401 20 12.205 20 12C20 11.795 19.9161 11.599 19.7679 11.4575L14.2679 6.20748Z"
      fill="currentColor"
    />
  </svg>,
  "ArrowRight"
);
