import React from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";
import { fieldError } from "../../utils/formHelpers";
import { ControlledFieldProps } from "./types";

type ControlledCustomFieldProps<TFieldValues> =
  ControlledFieldProps<TFieldValues> & {
    render: (
      errorMessage: string | undefined,
      {
        field,
        fieldState,
        formState,
      }: {
        field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<TFieldValues>;
      }
    ) => React.ReactElement;
  };

/**
 * Basically a preset for Controller for a common use in this app.
 *
 * @param control - pass this from useForm hook
 * @param defaultValue - pass this from useForm hook
 * @param name - field's name
 * @param fieldsErrors - pass this from useSubmitFormErrorHander() hook
 * @param render
 */
export const ControlledCustomField = <TFieldValues extends FieldValues>({
  control,
  defaultValue,
  name,
  fieldsErrors,
  render,
}: ControlledCustomFieldProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => {
        const {
          fieldState: { error },
        } = props;
        const [, serverError] = fieldError(name, fieldsErrors);
        return render(error?.message || serverError, props);
      }}
    />
  );
};
