import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { OutlinedInputProps, TextField } from "@mui/material";
import { fieldError } from "../../utils/formHelpers";
import { ControlledFieldProps } from "./types";

export const ControlledTextField = <TFieldValues extends FieldValues>({
  control,
  defaultValue,
  name,
  fieldsErrors,
  label,
  inputProps,
  disableLabel,
}: ControlledFieldProps<TFieldValues> & {
  inputProps?: OutlinedInputProps;
  disableLabel?: boolean;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { invalid, error } }) => {
        const [serverInvalid, serverError] = fieldError(name, fieldsErrors);
        return (
          <TextField
            variant="outlined"
            size="small"
            label={disableLabel ? undefined : label ?? name}
            error={invalid || serverInvalid}
            helperText={error?.message || serverError}
            fullWidth
            InputProps={inputProps}
            InputLabelProps={{ shrink: field.value }}
            {...field}
          />
        );
      }}
    />
  );
};
