import React, { forwardRef } from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { matchOptions } from "../../types";
import { useTranslation } from "react-i18next";

type SelectAlternativeCodeMatchProps = FormControlProps & {
  id: string;
  label: string;
  selectProps: SelectProps;
  helperText?: string;
};

export const SelectAlternativeCodeMatch: React.FC<SelectAlternativeCodeMatchProps> =
  forwardRef(
    (
      {
        id,
        label,
        selectProps: { value, ...selectProps },
        helperText,
        fullWidth = true,
        ...props
      },
      ref
    ) => {
      const { t } = useTranslation();
      return (
        <FormControl fullWidth={fullWidth} ref={ref} {...props}>
          <InputLabel id={`select-supplier-${id}-label`}>{label}</InputLabel>
          <Select
            labelId={`select-supplier-${id}-label`}
            id={`select-supplier-${id}`}
            label={label}
            value={value ?? ""}
            {...selectProps}
          >
            {matchOptions.map((matchOption) => (
              <MenuItem key={matchOption} value={matchOption}>
                {matchOption}
              </MenuItem>
            ))}
            {matchOptions.length < 1 && (
              <MenuItem key={"empty-list"} disabled>
                {t("emptySelect", { ns: "common" })}
              </MenuItem>
            )}
          </Select>
          {helperText && (
            <FormHelperText error={props.error}>{helperText}</FormHelperText>
          )}
        </FormControl>
      );
    }
  );
