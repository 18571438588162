import React from "react";
import { ListColumn, RowBase } from "../types";
import { Cell } from "./Cell";
import { EnhancedSkeleton } from "../../EnhancedSkeleton";

type RowSkeletonProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  checkbox?: boolean;
  expandableRows?: boolean;
};

export const RowSkeleton = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  checkbox,
  expandableRows,
}: RowSkeletonProps<Row, SubRow, SubRowsKey>) => {
  return (
    <>
      {expandableRows && <Cell />}
      {checkbox && (
        <Cell sx={{ justifyContent: "center" }}>
          <EnhancedSkeleton
            variant="rounded"
            sx={{ width: "20px", height: "20px" }}
          />
        </Cell>
      )}
      {columns.map((column) => (
        <Cell key={column.field} sx={column.boxSx}>
          {column.renderSkeleton ? (
            column.renderSkeleton()
          ) : (
            <EnhancedSkeleton
              variant="text"
              width={[50, 100, "%"]}
              sx={{
                lineHeight: "12px",
              }}
            />
          )}
        </Cell>
      ))}
    </>
  );
};
