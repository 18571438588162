import React from "react";
import { Box, Button, Paper, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type NewDesignDisplayContainerProps = {
  heading?: string;
  editHref?: string;
  editLabel?: string;
  editingDisabled?: boolean;
};

export const NewDesignDisplayContainer: React.FC<NewDesignDisplayContainerProps> =
  ({ children, heading, editHref, editLabel, editingDisabled }) => (
    <PaperContainer>
      {heading && <StyledHeader variant="h5">{heading}</StyledHeader>}
      {children}
      {editHref && editLabel && (
        <ButtonBox>
          <EditButton
            component={Link}
            to={editHref}
            variant="contained"
            fullWidth
            disabled={editingDisabled}
          >
            {editLabel}
          </EditButton>
        </ButtonBox>
      )}
    </PaperContainer>
  );

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: "30px",
  color: theme.palette.primary.dark,
  fontSize: "24px",
}));

const PaperContainer = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const EditButton = styled(Button, {
  shouldForwardProp: () => true,
})<Record<string, unknown>>(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginTop: "60px",
  fontSize: "13px",
}));
