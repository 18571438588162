import React, { useEffect } from "react";
import { EntityDetailsTable } from "../../../../components/EntityDetailsTable";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { useGetDNQuery } from "../../../../slices/deliveryNotes/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useDisplayDateTime } from "../../../../utils/useDisplayDateTime";

export const Details: React.FC<{ inquiryId: number }> = ({ inquiryId }) => {
  const { t } = useTranslation();
  const { id, closeDrawer } = useTableUrlTools(
    `/inquiries/${inquiryId}/details/dn`
  );
  const displayDate = useDisplayDateTime();

  const { data, error } = useGetDNQuery(id ?? skipToken);

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  if (!data) {
    return null;
  }

  return (
    <DisplayContainer
      heading={
        data.DNNumber ||
        t("deliveryNotes.detailsTitle", {
          ns: "pages",
        })
      }
    >
      <EntityDetailsTable
        entity={data}
        excludeFields={["DNNumber"]}
        customDisplays={{
          createdAt: (value) => (value ? displayDate(value) : null),
          updatedAt: (value) => (value ? displayDate(value) : null),
        }}
      />
    </DisplayContainer>
  );
};
