import React from "react";
import { useTranslation } from "react-i18next";
import { useSchoolInquiryItemsTools } from "./useSchoolInquiryItemsTools";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";
import { getTitle } from "../../utils/getTitle";
import { Helmet } from "react-helmet";
import { Content } from "./Content/Content";
import { Drawers } from "./Drawers/Drawers";
import { useScrollToTop } from "../../utils/useScrollToTop";

export const SchoolInquiryItems = () => {
  const { t } = useTranslation("pages");

  const {
    inquiryResult: { data },
  } = useSchoolInquiryItemsTools();

  const title: string = data?.name ?? t("schoolInquiryDetails.loadingTitle");

  useHeaderTitle(title);

  useScrollToTop();

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <Content />
      <Drawers />
    </>
  );
};
