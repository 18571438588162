import { styled, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FILE_TYPES_PO } from "../../../../../../consts";

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#1E90FF",
};

const acceptStyle = {
  borderColor: "#00BFFF",
};

const rejectStyle = {
  borderColor: "#e53935",
};

const errorStyle = {
  borderColor: "#e53935",
};

export const StyledDropzone: React.FC<{
  selectedFile?: string;
  error: boolean;
  errorText?: string;
  onDrop: (file: File) => void;
}> = ({ selectedFile, error, errorText, onDrop }) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: (files) => {
        const file = files[0];
        onDrop(file);
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(error ? errorStyle : {}),
    }),
    [error, isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} accept={FILE_TYPES_PO.join(",")} />
        <p>{t("dragAndDrop", { ns: "common" })}</p>
        {selectedFile && <SelectedFile>{selectedFile}</SelectedFile>}
      </div>
      {error && errorText && <ErrorMessage>{errorText}</ErrorMessage>}
    </>
  );
};

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
  textAlign: "left",
  marginTop: "2px",
  marginRight: "14px",
  marginBottom: 0,
  marginLeft: "14px",
}));

const SelectedFile = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
  textAlign: "left",
}));
