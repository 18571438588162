import React from "react";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconButton } from "../astraComponents/IconButton";
import { Close } from "../astraIcons/Close";

type AttachmentProps = {
  file?: File | null;
  onRemove?: () => void;
};

export const Attachment = ({ file, onRemove }: AttachmentProps) => {
  const theme = useTheme();
  const { t } = useTranslation("components");

  if (!file) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", gap: "8px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "16px",
          width: "100px",
          height: "100px",
          borderRadius: "4px",
          border: `1px solid ${theme.palette.secondary.dark}`,
          overflow: "hidden",

          img: {
            display: "block",
            width: "auto",
            maxWidth: "100%",
            height: "auto",
            maxHeight: "100%",
          },
        }}
      >
        <img
          src={URL.createObjectURL(file)}
          alt={t("cantFindProduct.attachment")}
        />
      </Box>
      <IconButton
        variant="transparent"
        sx={{ marginTop: "16px" }}
        onClick={onRemove}
      >
        <Close />
      </IconButton>
    </Box>
  );
};
