import React, { useCallback, useState } from "react";
import {
  Box,
  BoxProps,
  styled,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controls } from "./Controls";
import { Attachment as AttachmentIcon } from "../astraIcons/Attachment";
import { IconButton } from "../astraComponents/IconButton";
import { InvisibleFileInput } from "../InvisibleFileInput";
import { FILE_TYPES_GENERAL_ITEM_IMAGE } from "../../consts";
import { Attachment } from "./Attachment";
import {
  GetSigndedLinkResponseDto,
  getSignedLink,
  uploadFile,
} from "../../utils/S3UploadFile";
import { useAddGeneralToCartMutation } from "../../slices/shop/api";
import { useMessageHandlers } from "../../utils/useMessageHandlers";
import { useFeedError } from "../../utils/feedHooks";
import { useSelector } from "../../store";
import { selectShopList } from "../../slices/shop/selectors";
import { useCurrentAssignmentId } from "../../slices/shop/hooks";
import { NumericFieldProps } from "../fields/NumericField";

type CantFindProductProps = BoxProps;

export const CantFindProduct = ({ sx, ...props }: CantFindProductProps) => {
  const { t } = useTranslation("components");
  const { handleSuccess, handleError } = useMessageHandlers();
  const feedError = useFeedError();

  const { inquiryId, departmentId } = useSelector(selectShopList);
  const assignmentId = useCurrentAssignmentId();

  const [text, setText] = useState("");

  const textChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    []
  );

  const [quantity, setQuantity] = useState<NumericFieldProps["value"]>(1);
  const [attachment, setAttachment] = useState<File | null>(null);

  const attachmentChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAttachment(event.target.files?.[0] ?? null);
    },
    []
  );

  const removeAttachmentHandler = useCallback(() => {
    setAttachment(null);
  }, []);

  const [addGeneralToCart] = useAddGeneralToCartMutation();

  const addToCart = useCallback(
    (name: string, quantity: number, image?: string) => {
      addGeneralToCart({
        inquiryId: inquiryId,
        departmentInquiryId: departmentId,
        assignmentId,
        name,
        quantity,
        image,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [
      addGeneralToCart,
      assignmentId,
      departmentId,
      handleError,
      handleSuccess,
      inquiryId,
    ]
  );

  const submitHandler = useCallback(() => {
    // setDisabledAddButton(true);
    if (quantity === null) {
      return;
    }

    const parsedQuantity =
      typeof quantity === "string" ? parseInt(quantity) : quantity;

    if (!attachment) {
      addToCart(text, parsedQuantity);
      setText("");
      setQuantity(1);
      // setDisabledAddButton(false);
      return;
    }

    getSignedLink(attachment.name, attachment.type, "generalItemImage")
      .then((res: Response) => {
        if (!res.ok) {
          return Promise.reject();
        }
        return res.json();
      })
      .then((res: GetSigndedLinkResponseDto) => {
        if (!attachment || !res.key) {
          return Promise.reject();
        }
        return Promise.all([
          uploadFile(attachment, res.signedUrl),
          Promise.resolve(res.key),
        ]);
      })
      .then(([res, key]: [Response, string]) => {
        if (!res.ok) {
          return Promise.reject();
        }
        addToCart(text, parsedQuantity, key);
        setText("");
        setQuantity(1);
        setAttachment(null);
        // setDisabledAddButton(false);
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("shop.errors.upload"));
        // setDisabledAddButton(false);
      });
  }, [quantity, attachment, addToCart, text, feedError, t]);

  return (
    <Box
      sx={{
        width: "873px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...sx,
      }}
      {...props}
    >
      <Typography
        component="span"
        sx={{
          fontSize: "22px",
          lineHeight: "140%",
          color: "primary.dark",
        }}
      >
        {t("cantFindProduct.title")}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: "16px",
          lineHeight: "135%",
          marginTop: "10px",
          color: "secondary.main",
        }}
      >
        {t("cantFindProduct.text")}
      </Typography>
      <Box
        sx={{
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: "secondary.light",
          backgroundColor: "whitishGray.main",
          borderRadius: "4px",
          alignSelf: "stretch",
          marginTop: "30px",
          padding: "40px",
          position: "relative",
        }}
      >
        <IconButton
          component="label"
          variant="transparent"
          sx={{
            position: "absolute",
            zIndex: 1,
            top: "52px",
            right: "52px",
          }}
        >
          <AttachmentIcon />
          <InvisibleFileInput
            accept={FILE_TYPES_GENERAL_ITEM_IMAGE.join(", ")}
            onChange={attachmentChangeHandler}
          />
        </IconButton>
        <TextField
          multiline
          variant="outlined"
          placeholder={t("cantFindProduct.placeholder")}
          minRows={6}
          value={text}
          onChange={textChangeHandler}
        />
        <Attachment file={attachment} onRemove={removeAttachmentHandler} />
        <Controls
          text={text}
          quantity={quantity}
          setQuantity={setQuantity}
          onAdd={submitHandler}
        />
      </Box>
    </Box>
  );
};

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: "100%",
  padding: 0,

  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },

  "& .MuiOutlinedInput-input": {
    padding: "16px",
  },
}));
