import * as yup from "yup";
import { createYupSchema } from "../../../utils/validation";

export const schoolFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional().nullable(),
    name: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    countryId: yup.number().positive(t("required")).required(t("required")),
    currencyId: yup.number().positive(t("required")).required(t("required")),
    active: yup.boolean(),
    contactPersonName: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    address: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    phoneNumber: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    email: yup
      .string()
      .nullable()
      .optional()
      .email(t("incorrectEmail"))
      .max(256, t("tooLong", { maxLength: 256 })),
  })
);
