import React, { memo, useCallback, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { ExpandMore, HelpOutline, SettingsOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../store";
import { usePermissions } from "../../utils/usePermissions";
import { logOut } from "../../slices/auth/actions";
import { GearOptions } from "./GearOptions";
import { useHelpActive } from "../../utils/useHelpActive";
import { Role } from "../../permissions/permissions";
import { ROLES_WITH_AVAILABLE_HINTS } from "../../consts";
import { selectSelf } from "../../slices/auth/selectors";

type HeaderUserActionsProps = {
  id: number;
  userName: string;
  userRole?: Role;
  openChangeSelfPssswordDrawer: () => void;
};

export const HeaderUserActions: React.FC<HeaderUserActionsProps> = memo(
  ({ id, userName, userRole, openChangeSelfPssswordDrawer }) => {
    const { t } = useTranslation();
    const can = usePermissions();
    const history = useHistory();
    const dispatch = useDispatch();
    const { swapHelpActive, helpActiveState } = useHelpActive();
    const self = useSelector(selectSelf);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;

    const onClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);
    const close = useCallback(() => setAnchorEl(null), []);

    const [anchorGear, setAnchorGear] = useState<null | HTMLElement>(null);
    const openGear = !!anchorGear;

    const onGearClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorGear(event.currentTarget);
    }, []);
    const closeGear = useCallback(() => setAnchorGear(null), []);

    const onHelpClick = useCallback(() => {
      swapHelpActive();
    }, [swapHelpActive]);

    const onChangePassword = useCallback(() => {
      openChangeSelfPssswordDrawer();
      close();
    }, [close, openChangeSelfPssswordDrawer]);

    const onLogOutClick = useCallback(() => {
      dispatch(logOut());
      close();
      history.push("/");
    }, [dispatch, history, close]);

    const showHints = userRole && ROLES_WITH_AVAILABLE_HINTS.includes(userRole);

    const canChangePassword = useMemo(() => {
      if (!id) {
        return false;
      }

      return can("editSelf");
    }, [can, id]);

    const hintPathMatcher = useRouteMatch<{
      path: string;
      path2: string;
      path3: string;
    }>("/:path/:path2/:path3");
    const hintPath = [
      hintPathMatcher?.params?.path,
      hintPathMatcher?.params?.path2,
      hintPathMatcher?.params?.path3,
    ];

    const isGear = useMemo(() => {
      if (self?.role === "inquiryManager" || self?.role === "orderManager") {
        return false;
      }

      return (
        can("getSchools") ||
        (can("getDepartments") && can("viewDepartmentsMenu")) ||
        (can("getSuppliers") && can("viewSupplierMenu")) ||
        (can("getCategories") && can("showCategories")) ||
        can("getCommoditycodes") ||
        can("getCountries") ||
        can("viewCurrencies")
      );
    }, [can, self?.role]);

    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minWidth: "50%",
            justifyContent: "end",
          }}
        >
          {isGear && (
            <>
              <IconButton
                onClick={(event) => {
                  onGearClick(event);
                }}
              >
                <SettingsOutlined />
              </IconButton>
              <GearOptions
                open={openGear}
                anchorEl={anchorGear}
                close={closeGear}
              />
            </>
          )}
          {showHints &&
            hintPath[0]?.toLowerCase() === "schoolinquiries" &&
            hintPath[2]?.toLowerCase() === "details" && (
              <Tooltip title={t("hints.toggleHelp", { ns: "pages" }) as string}>
                <IconButton onClick={onHelpClick}>
                  <HelpOutline
                    color={helpActiveState ? "primary" : "inherit"}
                  />
                </IconButton>
              </Tooltip>
            )}
          <Button
            onClick={(event) => {
              onClick(event);
            }}
            aria-controls="row-actions-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            variant="outlined"
            style={{ paddingRight: 9 }}
          >
            {userName}
            <ExpandMore />
          </Button>
          <Menu
            id="row-actions-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={close}
            style={{ marginTop: 5 }}
          >
            <MenuItem onClick={onChangePassword} disabled={!canChangePassword}>
              {t("change password")}
            </MenuItem>
            <Divider />
            <MenuItem onClick={onLogOutClick}>{t("log out")}</MenuItem>
          </Menu>
        </Box>
      </>
    );
  }
);
