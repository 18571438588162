import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useGetDepartmentHeadsQuery } from "../../slices/users/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "react-i18next";
import { displayFullName } from "../../utils/displayFullName";

type NewDesignSelectDepartmentHeadProps = Omit<
  FormControlProps,
  "id" | "onChange"
> & {
  label: string;
  selectProps: SelectProps;
  id: string;
  schoolId?: number;
  helperText?: string;
};

export const NewDesignSelectDepartmentHead = ({
  label,
  selectProps,
  id,
  helperText,
  schoolId,
  ...props
}: NewDesignSelectDepartmentHeadProps) => {
  const { data = [], isLoading } = useGetDepartmentHeadsQuery(
    schoolId ?? skipToken
  );
  const { t } = useTranslation();

  return (
    <FormControl fullWidth {...props}>
      <Select
        labelId={`custom-department-head-select-${id}-label`}
        id={`custom-department-head-select-${id}`}
        placeholder={label}
        disabled={isLoading}
        {...selectProps}
      >
        {data.map(({ id, firstName, lastName }) => (
          <MenuItem key={id} value={id}>
            {displayFullName({ firstName, lastName })}
          </MenuItem>
        ))}
        {data.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
