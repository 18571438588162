import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { ClickableWithConfirmation } from "../ClickableWithConfirmation";

export type AdditionalButton = {
  label: string;
  handler: () => void;
  disabled?: boolean;
};

export type CustomDataGridRowActionsProps = {
  className?: string;
  confirmationChangeActivityTitle?: string;
  confirmationChangeActivityDescription?: string;
  confirmationTitle: string;
  confirmationDescription: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onChangeActivityClick?: () => void;
  changeActivityButtonText?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  canChangeActivity?: boolean;
  additionalButtons?: AdditionalButton[];
};

/**
 * You probably want to use RowActions component from this very directory
 * instead.
 */
export const CustomDataGridRowActions: React.FC<CustomDataGridRowActionsProps> =
  ({
    className,
    confirmationChangeActivityTitle,
    confirmationChangeActivityDescription,
    confirmationTitle,
    confirmationDescription,
    onEditClick,
    onDeleteClick,
    onChangeActivityClick,
    changeActivityButtonText,
    canEdit,
    canDelete,
    canChangeActivity,
    additionalButtons,
  }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;

    const onClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);
    const close = useCallback(() => setAnchorEl(null), []);

    const onEdit = useCallback(() => {
      if (onEditClick) {
        onEditClick();
      }
      close();
    }, [onEditClick, close]);

    const onChangeActivity = useCallback(() => {
      if (onChangeActivityClick) {
        onChangeActivityClick();
      }
      close();
    }, [onChangeActivityClick, close]);

    const onDelete = useCallback(() => {
      if (onDeleteClick) {
        onDeleteClick();
      }
      close();
    }, [onDeleteClick, close]);

    const onCustomButtonHandler = useCallback(
      (handler: () => void) => () => {
        handler();
        close();
      },
      [close]
    );

    return (
      <>
        <IconButton
          className={className}
          onClick={onClick}
          aria-controls="row-actions-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="row-actions-menu"
          open={open}
          anchorEl={anchorEl}
          onClose={close}
        >
          {!!onEditClick && (
            <MenuItem onClick={onEdit} disabled={!canEdit}>
              {t("edit")}
            </MenuItem>
          )}
          {additionalButtons?.map((button, id) => (
            <MenuItem
              key={`additional-button-${id}`}
              onClick={onCustomButtonHandler(button.handler)}
              disabled={button.disabled}
            >
              {t(button.label)}
            </MenuItem>
          ))}

          {!!onChangeActivityClick && (
            <ClickableWithConfirmation
              confirmationTitle={confirmationChangeActivityTitle || ""}
              confirmationDescription={
                confirmationChangeActivityDescription || ""
              }
              component={MenuItem}
              onClick={onChangeActivity}
              disabled={!canChangeActivity}
            >
              {changeActivityButtonText || t("changeActivity")}
            </ClickableWithConfirmation>
          )}

          {!!onDeleteClick && (
            <ClickableWithConfirmation
              confirmationTitle={confirmationTitle}
              confirmationDescription={confirmationDescription}
              component={MenuItem}
              onClick={onDelete}
              disabled={!canDelete}
            >
              {t("delete")}
            </ClickableWithConfirmation>
          )}
        </Menu>
      </>
    );
  };
