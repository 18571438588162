import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useGetCurrenciesQuery } from "../../../slices/currencies/api";
import { Currency } from "../../../slices/currencies/types";

export const DataTable: React.FC = () => {
  const { t } = useTranslation("pages");
  const {
    isLoading,
    data: currencies,
    isFetching,
    error,
    refetch,
  } = useGetCurrenciesQuery();

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("currencies.errors.refetch")}
      />
    );
  }

  if (isLoading || !currencies) {
    return <PaperPageSpinner />;
  }

  function Row(props: { row: Currency }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{ width: "10%" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.code}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            size="small"
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table
                  size="small"
                  aria-label="purchases"
                  sx={{ width: "30%" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("currencies.from")}</TableCell>
                      <TableCell>{t("currencies.to")}</TableCell>
                      <TableCell align="right">
                        {t("currencies.rate")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.currencyFromRates?.map((ceRate) => (
                      <TableRow key={ceRate.id}>
                        <TableCell component="th" scope="row">
                          {row.code}
                        </TableCell>
                        <TableCell>{ceRate.currencyTo.code}</TableCell>
                        <TableCell align="right">{ceRate.rate}</TableCell>
                      </TableRow>
                    ))}
                    {row.currencyToRates?.map((ceRate) => (
                      <TableRow key={ceRate.id}>
                        <TableCell>{ceRate.currencyFrom.code}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.code}
                        </TableCell>
                        <TableCell align="right">{ceRate.rate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <PaperPage>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {currencies.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PaperPage>
  );
};
