import React from "react";
import { LoadingTextField } from "../LoadingTextField";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useGetInquiryManagersQuery } from "../../slices/users/api";
import { displayFullName } from "../../utils/displayFullName";

export const SelectInquiryManager = (props: Omit<TextFieldProps, "select">) => {
  const { data: inquiryManagers = [], isLoading: areInquiryManagersLoading } =
    useGetInquiryManagersQuery();

  return (
    <LoadingTextField loading={areInquiryManagersLoading} select {...props}>
      {inquiryManagers.map((inquiryManager) => (
        <MenuItem key={inquiryManager.id} value={inquiryManager.id}>
          {displayFullName({
            firstName: inquiryManager.firstName,
            lastName: inquiryManager.lastName,
          })}
        </MenuItem>
      ))}
    </LoadingTextField>
  );
};
