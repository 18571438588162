import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import {
  useChangeDepartmentActivityMutation,
  useDeleteDepartmentMutation,
} from "../../../slices/departments/api";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { usePermissions } from "../../../utils/usePermissions";

type ActionsProps = {
  id: GridRowId;
  active: boolean;
};

export const Actions: React.FC<ActionsProps> = ({ id, active }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeDepartmentActivity] = useChangeDepartmentActivityMutation();
  const can = usePermissions();
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onEdit = useCallback(() => {
    history.push(`/departments/${intId}/edit`);
  }, [history, intId]);
  const onChangeDepartmentActivity = useCallback(() => {
    changeDepartmentActivity({ id: intId, active: !active })
      .unwrap()
      .then(() => {
        feedSuccess(t("departments.changeActivitySuccess"));
      })
      .catch((error) => {
        feedError(
          error?.data?.message ?? t("departments.errors.changeActivity")
        );
      });
  }, [changeDepartmentActivity, feedError, feedSuccess, intId, t, active]);
  const onDelete = useCallback(() => {
    deleteDepartment(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("departments.deleteSuccess"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("departments.errors.delete"));
      });
  }, [deleteDepartment, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationChangeActivityTitle={t(
        "departments.confirmChangeActivityTitle"
      )}
      confirmationChangeActivityDescription={t(
        "departments.confirmChangeActivityDescription"
      )}
      confirmationTitle={t("departments.confirmDeleteTitle")}
      confirmationDescription={t("departments.confirmDeleteDescription")}
      onEditClick={onEdit}
      onChangeActivityClick={onChangeDepartmentActivity}
      changeActivityButtonText={
        active ? t("activities.deactivate") : t("activities.activate")
      }
      onDeleteClick={onDelete}
      canEdit={can("editDepartment")}
      canDelete={can("editDepartment")}
      canChangeActivity={can("changeDepartmentActive")}
    />
  );
};
