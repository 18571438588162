import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ForgotPasswordRequestDto, ForgotPasswordResponseDto } from "./types";
import { getUrl, prepareHeaders } from "../../utils/api";

export const forgotpasswordApi = createApi({
  reducerPath: "forgotpasswordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/auth/forgotpassword"),
    prepareHeaders,
  }),
  tagTypes: ["Forgotpassword"],
  endpoints: (builder) => ({
    forgotPasswordEmail: builder.mutation<
      ForgotPasswordResponseDto,
      { email: string }
    >({
      query: (body) => ({ url: "/email", method: "POST", body }),
    }),
    forgotPasswordCode: builder.mutation<
      ForgotPasswordResponseDto,
      { email: string; code: string }
    >({
      query: (body) => ({ url: "/code", method: "POST", body }),
    }),
    resetPassword: builder.mutation<
      ForgotPasswordResponseDto,
      ForgotPasswordRequestDto
    >({
      query: (body) => ({ url: "/reset", method: "PUT", body }),
    }),
  }),
});

export const {
  useForgotPasswordEmailMutation,
  useForgotPasswordCodeMutation,
  useResetPasswordMutation,
} = forgotpasswordApi;
