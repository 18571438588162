import { useHistory, useRouteMatch } from "react-router-dom";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetSchoolInquiryQuery } from "../../slices/schoolInquiries/api";
import { useSelector } from "../../store";
import { selectSelfRole } from "../../slices/auth/selectors";
import { useLocalStorage } from "usehooks-ts";
import { SchoolInquiriesFilter } from "../../slices/schoolInquiries/types";
import { useCallback } from "react";
import { useContentTools } from "../../utils/useContentTools";

type SchoolInquiryParams = {
  inquiryId?: string;
  departmentInquiryId?: string;
  assignmentId?: string;
};

export const useAdminSchoolInquiryTools = () => {
  const selfRole = useSelector(selectSelfRole);

  const [schoolId, setSchoolId] = useLocalStorage<number | undefined>(
    "adminSchoolInquiriesSchoolId",
    undefined
  );
  const [userId, setUserId] = useLocalStorage<number | undefined>(
    "adminSchoolInquiriesUserId",
    undefined
  );
  const [userRole, setUserRole] = useLocalStorage<string | undefined>(
    "adminSchoolInquiriesUserRole",
    undefined
  );

  if (selfRole !== "admin") {
    return null;
  }

  return {
    schoolId,
    userId,
    userRole,
    setSchoolId,
    setUserId,
    setUserRole,
  };
};

export const useSchoolInquiriesTableUrlTools = () => {
  const pathMatcher = useRouteMatch<SchoolInquiryParams>([
    "/schoolinquiries/:inquiryId/department/:departmentInquiryId/assignment/:assignmentId",
    "/schoolinquiries/:inquiryId/department/:departmentInquiryId",
    "/schoolinquiries/:inquiryId",
  ]);
  const rawInquiryId = pathMatcher?.params?.inquiryId;
  const rawDepartmentInquiryId = pathMatcher?.params?.departmentInquiryId;
  const rawAssignmentId = pathMatcher?.params?.assignmentId;

  const inquiryId = parseInt(rawInquiryId || "0");
  const departmentInquiryId = parseInt(rawDepartmentInquiryId || "0");
  const assignmentId = parseInt(rawAssignmentId || "0");

  const inquiryResult = useGetSchoolInquiryQuery(inquiryId || skipToken);

  const baseUrl = "/schoolinquiries";

  return {
    ...useTableUrlTools(baseUrl),
    baseUrl,
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult,
  };
};

export const useSchoolInquiriesList = () => {
  const { baseUrl } = useSchoolInquiriesTableUrlTools();
  const history = useHistory();
  const adminTools = useAdminSchoolInquiryTools();

  const {
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    resetPageHandler,
  } = useContentTools("schoolInquiries", { defaultPageSize: 16 });

  const [filter, setFilter] = useLocalStorage<SchoolInquiriesFilter>(
    `schoolInquiries.filter`,
    "active"
  );
  const filterChangeHandler = useCallback(
    (value: SchoolInquiriesFilter) => {
      setFilter(value);
      resetPageHandler();
    },
    [resetPageHandler, setFilter]
  );

  const addHandler = useCallback(() => {
    history.push(`${baseUrl}/add`);
  }, [baseUrl, history]);

  return {
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    filter,
    filterChangeHandler,
    addHandler,
    userId: adminTools?.userId,
    userRole: adminTools?.userRole,
  };
};
