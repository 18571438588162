import React from "react";
import { MenuItem, TextField, TextFieldProps, Typography } from "@mui/material";
import { useGetAssignmentsQuery } from "../../../../slices/shop/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "../../../../store";
import { selectSelfId } from "../../../../slices/auth/selectors";

export const SelectList = ({
  sx,
  value = "",
  ...props
}: Omit<TextFieldProps, "select">) => {
  const selfId = useSelector(selectSelfId);

  const { data: assignments } = useGetAssignmentsQuery(selfId ?? skipToken);

  if (!assignments) {
    return null;
  }

  const options = assignments
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .map((assignment) =>
      assignment.departments.map((department) => {
        const key = `${assignment.id}-${department.id}`;

        return (
          <MenuItem
            key={key}
            value={key}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "12px",
              paddingBottom: "6px",
              paddingLeft: "15px",
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                color: "primary.dark",
                opacity: 0.5,
              }}
            >
              {assignment.name}
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "500",
                color: "primary.dark",
              }}
            >
              {department.name}
            </Typography>
          </MenuItem>
        );
      })
    );

  return (
    <TextField
      select
      value={value}
      sx={{
        width: "100%",
        marginTop: "19px",

        ".MuiSelect-select": {
          display: "flex",
          flexDirection: "column",
          minHeight: "60px !important",
          boxSizing: "border-box",
          paddingTop: "12px",
          paddingBottom: "10px",
          paddingLeft: "15px",
        },

        ...sx,
      }}
      {...props}
    >
      {options}
    </TextField>
  );
};
