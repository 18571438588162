import React from "react";
import { Box } from "@mui/material";
import { EnhancedSkeleton } from "../../../../components/EnhancedSkeleton";

export const DetailsSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        marginLeft: "40px",
      }}
    >
      <EnhancedSkeleton
        variant="rectangular"
        sx={{ width: "100px", height: "36px" }}
      />
      <EnhancedSkeleton
        variant="rectangular"
        sx={{ width: "36px", height: "36px" }}
      />
      <Box>
        <EnhancedSkeleton
          variant="text"
          width={[100, 150]}
          sx={{ lineHeight: "24px" }}
        />
        <EnhancedSkeleton
          variant="text"
          width={[80, 150]}
          sx={{ lineHeight: "15px" }}
        />
      </Box>
    </Box>
  );
};
