import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormHelperText, Grid, IconButton } from "@mui/material";
import { Remove } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";

import { FieldsArrayAccordion } from "../../../components/wideDrawer/FieldsArrayAccordion";
import {
  AddDepartmentRequestDto,
  EditDepartmentRequestDto,
} from "../../../slices/departments/types";
import { DepartmentFormProps } from "./DepartmentForm";
import { ControlledTextField } from "../../../components/controlledFields/ControlledTextField";
import { SelectTag } from "./SelectTag";

type RelevancesProps = Pick<DepartmentFormProps, "fieldsErrors"> & {
  helperText?: string;
};

export const TagRelevances = ({
  fieldsErrors,
  helperText,
}: RelevancesProps) => {
  const { t } = useTranslation("pages");
  const { control } = useFormContext<
    AddDepartmentRequestDto | EditDepartmentRequestDto
  >();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "relevancesTag",
  });

  const [errorMessage, setErrorMessage] = useState<string>(helperText || "");

  useEffect(() => {
    setErrorMessage(helperText || "");
  }, [helperText, setErrorMessage]);

  const addClickHandler = useCallback(() => {
    append({});
  }, [append]);

  const relevancesFields = useMemo(
    () =>
      fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <ControlledTextField
              control={control}
              name={`relevancesTag.${index}.relevance` as const}
              label={`${t("departments.fields.relevance")} ${index + 1} *`}
              defaultValue={field.relevance}
              fieldsErrors={fieldsErrors}
            />
          </Grid>
          <Grid item xs={7}>
            <SelectTag fieldsErrors={fieldsErrors} index={index} />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => remove(index)}>
              <Remove />
            </IconButton>
          </Grid>
        </React.Fragment>
      )),
    [control, fields, fieldsErrors, remove, t]
  );

  return (
    <Grid item gridColumn="span 12">
      <FieldsArrayAccordion
        id="department-form-relevances-tag"
        label={t("departments.fields.relevancesTag")}
        addLabel={t("departments.fields.addRelevance")}
        addClickHandler={addClickHandler}
      >
        <Grid container spacing={2} sx={{ marginBottom: 2, marginRight: 10 }}>
          {relevancesFields}
        </Grid>
      </FieldsArrayAccordion>
      <Grid item sx={{ marginLeft: 2 }}>
        {errorMessage && (
          <FormHelperText error={true}>{errorMessage}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};
