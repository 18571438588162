import React, { useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomDataGrid } from "../../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../../components/layout/PaperPage";
import { Actions } from "./Actions";
import { useGetPurchaseOrdersQuery } from "../../../../slices/purchaseOrders/api";
import { usePermissions } from "../../../../utils/usePermissions";
import { GroupsSwitch } from "../../GroupsSwitch";
import { useGetInquiryDetailsQuery } from "../../../../slices/inquiries/api";
import { Link as MaterialLink } from "@mui/material";
import { DownloadFileCellBtn } from "./DownloadFileCellBtn";
import {
  DEFAULT_SORT_MODEL_DESC,
  STANDART_PO_CURRENCY,
} from "../../../../consts";
import { PurchaseOrderWithStatistics } from "../../../../slices/purchaseOrders/types";
import { useInquiryDetailsUrlTools } from "../../hooks";
import { useDisplayDateTime } from "../../../../utils/useDisplayDateTime";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";

export const DataTable = () => {
  const { baseUrl: preBaseUrl, inquiryId } = useInquiryDetailsUrlTools();
  const baseUrl = `${preBaseUrl}/po`;

  const { t } = useTranslation("pages");
  const can = usePermissions();
  const displayDate = useDisplayDateTime(false);
  const displayPrice = useDisplayPrice();

  const history = useHistory();
  const { data: inquiry } = useGetInquiryDetailsQuery(inquiryId);

  const { isLoading, isFetching, data, error, refetch } =
    useGetPurchaseOrdersQuery(inquiryId);

  const onAddClick = useCallback(
    () => history.push(`${baseUrl}/add`),
    [baseUrl, history]
  );

  const columns = useMemo<GridColDef<PurchaseOrderWithStatistics>[]>(
    () => [
      {
        field: "id",
        headerName: t("purchaseOrders.fields.id"),
        flex: 0.1,
        minWidth: 60,
        type: "number",
      },
      {
        field: "referenceNumber",
        headerName: t("purchaseOrders.fields.referenceNumber"),
        flex: 1,
        renderCell: (params) => {
          if (!can("getPurchaseOrder")) {
            return <>{params.value}</>;
          }
          return (
            <MaterialLink component={Link} to={`${baseUrl}/${params.id}`}>
              {params.value}
            </MaterialLink>
          );
        },
      },
      {
        field: "date",
        headerName: t("purchaseOrders.fields.date"),
        flex: 0.5,
        renderCell: (params) => displayDate(params.value),
      },
      {
        field: "downloadFile",
        headerName: t("purchaseOrders.fields.uploadedFile"),
        flex: 0.3,
        renderCell: (params) => {
          return <DownloadFileCellBtn po_id={params.id} />;
        },
      },
      {
        field: "POItems",
        headerName: t("purchaseOrders.fields.items"),
        flex: 0.3,
        renderCell: ({ row }) => (
          <MaterialLink component={Link} to={`${baseUrl}/${row.id}/items`}>
            {row.POItems}
          </MaterialLink>
        ),
      },
      {
        field: "POValue",
        headerName: t("purchaseOrders.fields.value"),
        flex: 1,
        renderCell: (params) => {
          return `${displayPrice(params.value)} ${
            params.row?.inquiry?.school?.currency?.code || STANDART_PO_CURRENCY
          }`;
        },
      },
      {
        field: "valueCompletionRateByNH",
        headerName: t("purchaseOrders.fields.valueRateByNH"),
        flex: 0.8,
        renderCell: (params) => {
          const value = params.value * 100;
          return `${value} %`;
        },
      },
      {
        field: "valueCompletionRateByClient",
        headerName: t("purchaseOrders.fields.valueRateByClient"),
        flex: 0.8,
        renderCell: (params) => {
          const value = params.value * 100;
          return `${value} %`;
        },
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => <Actions id={params.id} />,
      },
    ],
    [baseUrl, can, displayDate, displayPrice, t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage
      breadcrumbs={{
        paths: [
          { href: "/inquiries", text: t("inquiries.title") },
          {
            href: preBaseUrl,
            text: inquiry?.name || t("inquiries.title"),
          },
        ],
        current: t("purchaseOrders.title"),
        endAdornment: <GroupsSwitch />,
      }}
    >
      <CustomDataGrid
        tableName="details-inquiries-po"
        addButtonText={t("purchaseOrders.addPurchaseOrder")}
        addButtonDisabled={!can("addPurchaseOrder")}
        onAddClick={onAddClick}
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={["referenceNumber"]}
        defaultSortModel={DEFAULT_SORT_MODEL_DESC}
      />
    </PaperPage>
  );
};
