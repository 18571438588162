import { useGetAssignmentsQuery } from "../../slices/shop/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { useDispatch, useSelector } from "../../store";
import { useLocation } from "react-router-dom";
import { RouteState } from "../../types";
import { useCallback, useEffect, useRef } from "react";
import { selectSelfId } from "../../slices/auth/selectors";
import { selectShopList } from "../../slices/shop/selectors";
import { setShopList } from "../../slices/shop/actions";

/**
 * Taken mostly from the previous implementation of the shop.
 *
 * ...I've rewritten it using redux, but the code is old and is very likely not
 * perfect in terms of the number of updates.
 * And I've done that in order to avoid prop drilling, yet it's remained here in
 * shop catalog so far.
 */
export const useCurrentList = () => {
  const dispatch = useDispatch();

  const selfId = useSelector(selectSelfId);

  // TODO PC-966 rework useEffects with default value, to not change store values if they are already valid in route
  // For now this varable will do this job
  const isWeNeedTakeDefault = useRef(true);

  const { state } = useLocation<RouteState>();
  const shopCurrentList = state?.shopCurrentList;
  const [routeInquiryId, routeDepartmentId] = shopCurrentList
    ? shopCurrentList
    : [undefined, undefined];

  const { data: assignments, isLoading: areAssignmentsLoading } =
    useGetAssignmentsQuery(selfId || skipToken);

  const { inquiryId, departmentId } = useSelector(selectShopList);

  const setInquiryId = useCallback(
    (inquiryId: number) => {
      dispatch(setShopList({ inquiryId }));
    },
    [dispatch]
  );

  const setDepartmentId = useCallback(
    (departmentId: number) => {
      dispatch(setShopList({ departmentId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      routeInquiryId &&
      routeInquiryId > 0 &&
      routeDepartmentId &&
      routeDepartmentId > 0
    ) {
      isWeNeedTakeDefault.current = false;

      dispatch(
        setShopList({
          departmentId: routeDepartmentId,
          inquiryId: routeInquiryId,
        })
      );
    }
  }, [dispatch, routeDepartmentId, routeInquiryId]);

  useEffect(() => {
    if (inquiryId && assignments?.find(({ id }) => id === inquiryId)) {
      return;
    }

    if (assignments && !areAssignmentsLoading && isWeNeedTakeDefault.current) {
      setInquiryId(assignments?.[0]?.id || -1);
    }
  }, [areAssignmentsLoading, assignments, inquiryId, setInquiryId]);

  useEffect(() => {
    if (
      departmentId &&
      assignments
        ?.find(({ id }) => id === inquiryId)
        ?.departments?.find(({ id }) => id === departmentId)
    ) {
      return;
    }

    if (assignments && !areAssignmentsLoading && isWeNeedTakeDefault.current) {
      setDepartmentId(
        assignments?.find(({ id }) => id === inquiryId)?.departments?.[0].id ||
          -1
      );
    }
  }, [
    areAssignmentsLoading,
    assignments,
    departmentId,
    inquiryId,
    setDepartmentId,
  ]);
};
