import { getDefaultHeaders, getUrl } from "./api";

const S3_SIGN_FILE_URL = `/s3/sign`;
const S3_GET_DOWNLOAD_LINK_URL = `/s3/dlink`;

export type GetSigndedLinkResponseDto = Response & {
  signedUrl: string;
  key: string;
};

export const getDownloadUrl = async (
  id: number,
  urlPostfix?: string
): Promise<Response> => {
  const completeURL = `${getUrl(
    S3_GET_DOWNLOAD_LINK_URL + (urlPostfix ? `/${urlPostfix}` : "")
  )}?id=${id}`;
  return await fetch(completeURL, {
    headers: getDefaultHeaders(true),
    method: "GET",
  });
};

export const getSignedLink: (
  fileName: string,
  fileType: string,
  urlPostfix?: string
) => Promise<Response> = async (fileName, fileType, urlPostfix) => {
  const completeURL = `${getUrl(
    S3_SIGN_FILE_URL + (urlPostfix ? `/${urlPostfix}` : "")
  )}?file-name=${fileName}&file-type=${fileType}`;
  return await fetch(completeURL, {
    headers: getDefaultHeaders(true),
    method: "GET",
  });
};

export const uploadFile = async (file: File, signedURL: string) => {
  return await fetch(signedURL, {
    method: "PUT",
    body: file,
  });
};
