import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TableName } from "../Items/hooks";
import { useGetItemQuery } from "../../slices/items/api";

type ItemDetailsParams = {
  itemId: string;
  inquiryId: string;
  detailId: string;
  tableName: TableName;
};

export const useItemDetailsUrlTools = () => {
  const history = useHistory();
  const { itemId, inquiryId, detailId, tableName } =
    useParams<ItemDetailsParams>();

  const { data } = useGetItemQuery(parseInt(itemId));
  const baseUrl = inquiryId
    ? `/inquiries/${inquiryId}/details/${detailId}/items/${tableName}/${itemId}/details`
    : `/newitems/${itemId}/details`;

  const closeDrawer = useCallback(() => {
    history.push(baseUrl);
  }, [baseUrl, history]);

  return {
    itemId: parseInt(itemId),
    inquiryId: inquiryId
      ? parseInt(inquiryId)
      : data?.departmentInquiry.inquiry.id,
    parentId: detailId
      ? parseInt(detailId)
      : data?.departmentInquiry.department.id,
    tableName,
    closeDrawer,
    baseUrl,
    isInquiryId: !!inquiryId,
  };
};
