import { Button, Divider, Grid, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFeedError } from "../../../utils/feedHooks";

type CommentFormProps = {
  addValidComment: (text: string) => void;
  isDivider: boolean;
};

export const CommentForm: React.FC<CommentFormProps> = ({
  addValidComment,
  isDivider,
}) => {
  const { t } = useTranslation("pages");
  const feedError = useFeedError();
  const [text, setText] = useState("");

  const schema = yup
    .string()
    .min(1, t("comments.tooShort", { minLength: 1 }))
    .max(512, t("comments.tooLong", { maxLength: 512 }));

  const onSubmit = useCallback(() => {
    schema
      .validate(text)
      .then(() => {
        addValidComment(text);
        setText("");
      })
      .catch((err) => {
        feedError(err.message);
      });
  }, [schema, addValidComment, feedError, text]);

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(6, 1fr)"
      gap={1}
      sx={{
        position: "absolute",
        bottom: "0px",
        padding: "0px 16px 16px 0px",
        width: "auto",
      }}
    >
      {isDivider && (
        <Grid item gridColumn="span 6">
          <Divider />
        </Grid>
      )}
      <Grid item gridColumn="span 6">
        <TextField
          variant="outlined"
          multiline
          rows={3}
          placeholder={t("comments.placeholder")}
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <Button fullWidth onClick={onSubmit}>
          {t("comments.submit")}
        </Button>
      </Grid>
    </Grid>
  );
};
