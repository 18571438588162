import { useCallback, useState } from "react";

export const useListTools = () => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const selectedIdsChangeHandler = useCallback(
    (newSelectionModel: number[]) => {
      setSelectedIds(newSelectionModel);
    },
    []
  );

  return { selectedIds, selectedIdsChangeHandler };
};
