import React from "react";
import { alpha, Box, Button, useTheme } from "@mui/material";
import { NumericField } from "../fields/NumericField";
import { useTranslation } from "react-i18next";
import { useIsCartDisabled } from "../../slices/shop/hooks";

type ControlsProps = {
  text: string;
  quantity: string | number | null;
  setQuantity: (value: string | number | null) => void;
  onAdd?: () => void;
};

export const Controls = ({
  text,
  quantity,
  setQuantity,
  onAdd,
}: ControlsProps) => {
  const { t } = useTranslation("components");
  const theme = useTheme();

  const isCartDisabled = useIsCartDisabled();

  return (
    <Box
      sx={{
        marginTop: "37px",
        display: "flex",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
      }}
    >
      <NumericField
        name={`general-product-quantity`}
        value={quantity}
        onChange={setQuantity}
        positiveOnly
        disabled={isCartDisabled}
        sx={{
          flexBasis: "110px",
          flexGrow: 0,
          flexShrink: 0,
          position: "relative",

          ".MuiOutlinedInput-root": {
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.palette.common.white,
          },

          ".MuiInputBase-input": {
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "500",
            height: "38px",
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },

          ".MuiIconButton-root": {
            width: "30px",
            height: "30px",

            svg: {
              color: `${theme.palette.primary.main} !important`,
            },

            "&.Mui-disabled svg": {
              opacity: 0.5,
            },
          },

          ".MuiInputAdornment-root": {
            position: "absolute",
          },

          ".MuiInputAdornment-positionStart": {
            left: "6px",
          },

          ".MuiInputAdornment-positionEnd": {
            right: "6px",
          },
        }}
      />
      <Button
        disabled={isCartDisabled || text.length === 0}
        variant="outlined"
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          flexGrow: 1,
          flexShrink: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          marginLeft: "-1px",
          textTransform: "none",
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
        onClick={onAdd}
      >
        + {t("cantFindProduct.add")}
      </Button>
    </Box>
  );
};
