import React, { useCallback, useState } from "react";
import { useFeedError, useFeedSuccess } from "../../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { StyledDropzone } from "../../../../components/StyledDropzone";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import {
  useGetCOQuery,
  useUploadSignedFileMutation,
} from "../../../../slices/commercialOffers/api";
import { useSelector } from "../../../../store";
import { Box, Button } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  GetSigndedLinkResponseDto,
  getSignedLink,
  uploadFile,
} from "../../../../utils/S3UploadFile";
import { FILE_TYPES_CO } from "../../../../consts";

export const UploadFile: React.FC<{ inquiryId: number }> = ({ inquiryId }) => {
  const { id, closeDrawer } = useTableUrlTools(
    `/inquiries/${inquiryId}/details/co`
  );
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const { data } = useGetCOQuery(id ?? skipToken);
  const [uploadSignedFile] = useUploadSignedFileMutation();
  const userId = useSelector((state) => state.auth.user?.id);

  const [uploadedFile, setUploadedFile] = useState<File>();

  const onSubmit = useCallback(() => {
    if (!uploadedFile) {
      return;
    }

    getSignedLink(uploadedFile.name, uploadedFile.type, "co")
      .then((res: Response) => {
        if (!res.ok) {
          return Promise.reject();
        }
        return res.json();
      })
      .then((res: GetSigndedLinkResponseDto) => {
        if (!uploadedFile || !res.key) {
          return Promise.reject();
        }
        return Promise.all([
          uploadFile(uploadedFile, res.signedUrl),
          Promise.resolve(res.key),
        ]);
      })
      .then(([res, key]: [Response, string]) => {
        if (!res.ok) {
          return Promise.reject();
        }
        return uploadSignedFile({
          uploadedFile: key,
          userId: userId || -1,
          id: id || -1,
        }).unwrap();
      })
      .then(() => {
        feedSuccess(
          t("commercialOffers.successes.upload"),
          `/inquiries/${inquiryId}/details/co/${id}`
        );
        closeDrawer();
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("commercialOffers.errors.upload"));
      });
  }, [
    closeDrawer,
    feedError,
    feedSuccess,
    id,
    inquiryId,
    t,
    uploadSignedFile,
    uploadedFile,
    userId,
  ]);

  return (
    <DisplayContainer
      heading={
        data?.CONumber ||
        t("commercialOffers.detailsTitle", {
          ns: "pages",
        })
      }
    >
      <StyledDropzone
        onDrop={(file) => {
          setUploadedFile(file);
        }}
        accept={FILE_TYPES_CO}
      />
      <Box marginTop={2}>
        <Button onClick={onSubmit} disabled={!uploadedFile}>
          {"Submit"}
        </Button>
      </Box>
    </DisplayContainer>
  );
};
