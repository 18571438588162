import React from "react";
import { useSchoolInquiryItemsTools } from "../../useSchoolInquiryItemsTools";
import { DepartmentInquiryItems } from "./DepartmentInquiryItems/DepartmentInquiryItems";
import { AssignmentItems } from "./AssignmentItems/AssignmentItems";

export const Items = () => {
  const { assignmentId } = useSchoolInquiryItemsTools();

  if (assignmentId) {
    return <AssignmentItems />;
  }

  return <DepartmentInquiryItems />;
};
