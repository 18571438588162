import { alpha, Box, useTheme } from "@mui/material";
import React from "react";
import { ShopProductCard } from "../../../../slices/shop/types";
import { Image } from "./Image";
import { Link } from "react-router-dom";
import { useShopCatalogUrlTools } from "../../useShopCatalogUrlTools";
import { useTranslation } from "react-i18next";
import { Price } from "./Price";
import { Controls } from "./Controls/Controls";
import { useInCart } from "../../../../slices/shop/hooks";

type ProductProps = {
  product: ShopProductCard;
};

export const Product = ({
  product: {
    id,
    name,
    supplier,
    supplierProductCode,
    images,
    retailPrice,
    retailCurrency,
  },
}: ProductProps) => {
  const theme = useTheme();
  const { t } = useTranslation("pages");

  const { baseUrl } = useShopCatalogUrlTools();
  const quantityInCart = useInCart(id);

  const na = t("na", { ns: "common" });

  const to = {
    pathname: `${baseUrl}/product/${id}`,
    state: { scrollToY: window.scrollY },
  };

  const controlsId = `#product-${id}-controls`;

  return (
    <Box
      sx={{
        minHeight: "467px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "common.white",
        position: "relative",

        [controlsId]: {
          visibility: "hidden",
        },

        [`&:hover ${controlsId}`]: {
          visibility: "visible",
        },

        "&:hover::before": {
          display: "block",
          content: "''",
          position: "absolute",
          zIndex: -1,
          top: "-16px",
          right: "-16px",
          bottom: "-23px",
          left: "-16px",
          boxSizing: "border-box",
          border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          borderRadius: "4px",
          boxShadow: "2px 0 16px 10px rgba(51, 144, 255, 0.04)",
        },
      }}
    >
      {quantityInCart && quantityInCart > 0 && (
        <Box
          sx={{
            borderRadius: "100%",
            width: "48px",
            height: "48px",
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.dark,
            fontSize: "10px",
            lineHeight: "24px",
            fontWeight: "500",
            boxShadow: "2px 4px 6px 0px rgba(44, 125, 223, 0.10)",
            border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
            position: "absolute",
            top: "-24px",
            right: "-24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,
          }}
        >
          {quantityInCart}
        </Box>
      )}
      <Image
        images={images}
        name={name}
        supplierProductCode={supplierProductCode}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          textDecoration: "none",
          marginTop: "26px",
        }}
      >
        <Box dir="auto" sx={{ alignSelf: "stretch" }}>
          <Box
            component={Link}
            to={to}
            sx={{
              textDecoration: "none",
              fontSize: "16px",
              lineHeight: "20px",
              color: "primary.dark",

              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            {name ?? na}
          </Box>
        </Box>
        <Box
          component={Link}
          to={to}
          sx={{
            textDecoration: "none",
            fontSize: "12px",
            lineHeight: "24px",
            color: "secondary.main",

            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {supplier?.name ?? na} {supplierProductCode ?? na}
        </Box>
      </Box>
      <Price retailPrice={retailPrice} retailCurrency={retailCurrency} />
      <Controls id={id} quantityInCart={quantityInCart} />
    </Box>
  );
};
