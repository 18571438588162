import React from "react";
import { useRouteMatch } from "react-router-dom";
import { EditDepartment } from "./EditDepartment";
import { ItemDetails } from "./ItemDetails";
import { EditAssignment } from "./EditAssignment";
import { NewDesignWideDrawer } from "../../../components/wideDrawer/NewDesignWideDrawer";
import { AddItem } from "./AddItem";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";

export const Drawers = () => {
  const { baseUrl, closeDrawer, itemId } = useSchoolInquiryItemsTools();
  const editDepartmentMatcher = useRouteMatch(`${baseUrl}/edit/department`);
  const editAssignmentMatcher = useRouteMatch(`${baseUrl}/edit/assignment`);
  const addItemMatcher = useRouteMatch(`${baseUrl}/add/item`);

  return (
    <>
      <NewDesignWideDrawer open={!!editDepartmentMatcher} close={closeDrawer}>
        <EditDepartment />
      </NewDesignWideDrawer>
      <NewDesignWideDrawer open={!!editAssignmentMatcher} close={closeDrawer}>
        <EditAssignment />
      </NewDesignWideDrawer>
      <NewDesignWideDrawer open={!!itemId} close={closeDrawer}>
        <ItemDetails />
      </NewDesignWideDrawer>
      <NewDesignWideDrawer open={!!addItemMatcher} close={closeDrawer}>
        <AddItem />
      </NewDesignWideDrawer>
    </>
  );
};
