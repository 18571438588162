import { useParams } from "react-router-dom";
import { parseNumericParam } from "../../utils/parseNumericParam";

export type ShopDetailsParams = {
  productId: string;
};

export const useShopDetailsUrlTools = () => {
  const { productId } = useParams<ShopDetailsParams>();

  return {
    productId: parseNumericParam(productId),
  };
};
