import React from "react";
import { MultipleSelectFilter } from "../../toolbar/MultipleSelectFilter";
import { useTranslation } from "react-i18next";
import { ACTIVE_FILTER_OPTIONS } from "../../../consts";

export type ActiveFilterProps = {
  onActivitiesFilterChange: (value: string[]) => void;
  activitiesFilter: string[];
};

/**
 * This is component for old design. Replace with NewDesignActiveFilter if possible.
 * @deprecated
 */

export const ActiveFilter: React.FC<ActiveFilterProps> = React.memo(
  ({ onActivitiesFilterChange, activitiesFilter }: ActiveFilterProps) => {
    const { t } = useTranslation();

    return (
      <MultipleSelectFilter
        id="by-activity"
        label={t("activity")}
        options={ACTIVE_FILTER_OPTIONS.map((option) => ({
          label: option,
          value: option,
        }))}
        onChange={onActivitiesFilterChange}
        value={activitiesFilter}
      />
    );
  }
);
