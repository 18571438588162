import React from "react";
import { alpha, Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { IconButton } from "../../../../components/astraComponents/IconButton";
import { Close } from "../../../../components/astraIcons/Close";

type FilterChipProps = {
  name: string;
  value: string;
  onRemove: () => void;
};

export const FilterChip = ({ name, value, onRemove }: FilterChipProps) => {
  return (
    <Box
      sx={{
        height: "23px",
        borderRadius: "27px",
        backgroundColor: `${alpha(colors.blue, 0.1)} !important`,
        paddingRight: "12px",
        paddingLeft: "12px",
        display: "flex",
        alignItems: "center",
        gap: "6px",

        span: {
          fontSize: "12px",
          lineHeight: "23px",
          color: colors.darkBlue,
        },
      }}
    >
      <Typography component="span">{name}</Typography>
      <Typography component="span" sx={{ fontWeight: "500" }}>
        {value}
      </Typography>
      <IconButton
        onClick={onRemove}
        variant="transparent"
        sx={{
          marginTop: "1px",
          color: colors.blue,
          width: "16px",
          height: "16px",

          svg: {
            width: "16px",
            height: "16px",
          },
        }}
      >
        <Close />
      </IconButton>
    </Box>
  );
};
