import React from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInquiryDetailsUrlTools } from "./hooks";
import { usePermissions } from "../../utils/usePermissions";

export const GroupsSwitch = () => {
  const { t } = useTranslation("pages");
  const { baseUrl, tableName } = useInquiryDetailsUrlTools();

  const can = usePermissions();

  return (
    <ButtonGroup
      size="small"
      sx={{
        position: "absolute",
        right: 0,
        marginRight: 2,
      }}
    >
      <Tooltip title={t("inquiryDetails.groups.departments.tooltip") as string}>
        <Button component={Link} to={baseUrl} disabled={!tableName}>
          {t("inquiryDetails.groups.departments.label")}
        </Button>
      </Tooltip>
      {can("editInquiry") && (
        <Tooltip title={t("inquiryDetails.groups.details.tooltip") as string}>
          <Button
            component={Link}
            to={`${baseUrl}/details`}
            disabled={tableName === "details"}
          >
            {t("inquiryDetails.groups.details.label")}
          </Button>
        </Tooltip>
      )}
      {can("getCommercialOffers") && (
        <Tooltip title={t("inquiryDetails.groups.co.tooltip") as string}>
          <Button
            component={Link}
            to={`${baseUrl}/co`}
            disabled={tableName === "co"}
          >
            {t("inquiryDetails.groups.co.label")}
          </Button>
        </Tooltip>
      )}
      {can("getPurchaseOrders") && (
        <Tooltip title={t("inquiryDetails.groups.po.tooltip") as string}>
          <Button
            component={Link}
            to={`${baseUrl}/po`}
            disabled={tableName === "po"}
          >
            {t("inquiryDetails.groups.po.label")}
          </Button>
        </Tooltip>
      )}
      {can("getMainOrders") && (
        <Tooltip title={t("inquiryDetails.groups.mo.tooltip") as string}>
          <Button
            component={Link}
            to={`${baseUrl}/mo`}
            disabled={tableName === "mo"}
          >
            {t("inquiryDetails.groups.mo.label")}
          </Button>
        </Tooltip>
      )}
      {can("getDeliveryNotes") && (
        <Tooltip title={t("inquiryDetails.groups.dn.tooltip") as string}>
          <Button
            component={Link}
            to={`${baseUrl}/dn`}
            disabled={tableName === "dn"}
          >
            {t("inquiryDetails.groups.dn.label")}
          </Button>
        </Tooltip>
      )}
    </ButtonGroup>
  );
};
