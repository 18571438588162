import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme/astra/colors";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";

export const NoInquiries = () => {
  const { t } = useTranslation("pages");
  const size = useWindowSize(); // TODO fix overall page looking, to pin astra gray logo to bottom

  return (
    <Box
      sx={{
        width: "100%",
        height: `${size.height > 400 ? size.height - 350 : 100}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: "22px",
          color: colors.darkBlue,
          fontWeight: "500",
          lineHeight: "100px",
        }}
      >
        {t("schoolInquiries.noInquiries")}
      </Typography>
    </Box>
  );
};
