import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { GetFeaturedTagsResponseDto, GetTagsResponseDto } from "./types";

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/tags"),
    prepareHeaders,
  }),
  tagTypes: ["Tag"],
  endpoints: (builder) => ({
    getTags: builder.query<GetTagsResponseDto, string>({
      query: (search) => `?search=${search}`,
    }),
    getRelevanceTags: builder.query<
      GetTagsResponseDto,
      { departmentId?: number | null; search: string; tags?: string[] }
    >({
      query: ({ departmentId, search, tags }) =>
        `/relevance?search=${search}${
          tags && tags.length > 0
            ? `&tags=${tags.map((tag) => encodeURIComponent(tag)).join(",_,")}`
            : ""
        }${departmentId ? `&departmentId=${departmentId}` : ""}`,
    }),
    getFeaturedTags: builder.query<GetFeaturedTagsResponseDto, void>({
      query: () => "/featured",
    }),
  }),
});

export const {
  useGetTagsQuery,
  useGetRelevanceTagsQuery,
  useGetFeaturedTagsQuery,
} = tagsApi;
