import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDispatch, useSelector } from "../../../store";

import {
  useChangePasswordMutation,
  useGetUserQuery,
} from "../../../slices/users/api";
import { ChangePasswordRequestDto } from "../../../slices/users/types";
import { logOut } from "../../../slices/auth/actions";

import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useFeedSuccess } from "../../../utils/feedHooks";

import { ChangePasswordForm } from "./types";

import {
  PasswordChangeForm,
  passwordChangeFormSchemaCreator,
} from "./ChangePasswordForm";
import { useYup } from "../../../utils/validation";
import { NewDesignForm } from "../../../components/wideDrawer/NewDesignForm";
import { selectSelfId } from "../../../slices/auth/selectors";

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();
  const { id, closeDrawer } = useTableUrlTools("/users");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(passwordChangeFormSchemaCreator);
  const formMethods = useForm<ChangePasswordRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;

  const selfId = useSelector(selectSelfId);
  const editingSelf = selfId === id;

  const { data } = useGetUserQuery(id ?? -1);

  const [changePassword, { isLoading: isEditLoading }] =
    useChangePasswordMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("users.errors.edit")
  );

  const getTitle = useCallback(() => {
    if (data?.firstName || data?.lastName) {
      const name =
        data.firstName && data.lastName
          ? `${data.firstName} ${data.lastName}`
          : data.firstName || data.lastName;

      return t("users.changePasswordFor", { name });
    }
    return t("users.changePassword");
  }, [data, t]);

  const onSubmit = useCallback(
    (form: ChangePasswordForm) => {
      changePassword(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("passwordChanged", { ns: "legacy" }), `/users/${id}`);
          closeDrawer();
          if (editingSelf) {
            dispatch(logOut());
          }
        })
        .catch(catchFormError);
    },
    [
      catchFormError,
      closeDrawer,
      dispatch,
      changePassword,
      editingSelf,
      feedSuccess,
      id,
      t,
    ]
  );

  if (!id || !data) {
    return null;
  }

  return (
    <NewDesignForm
      heading={getTitle()}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <PasswordChangeForm userId={id} fieldsErrors={fieldsErrors} />
      </FormProvider>
    </NewDesignForm>
  );
};
