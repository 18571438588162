import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApi } from "../../store";
import {
  getDefaultHeaders,
  getPermissions,
  getToken,
  getUrl,
  removeToken,
  setToken,
} from "../../utils/api";
import i18n from "../../i18n";
import {
  GetPermissionsResponseDto,
  GetValidateTokenResponseDto,
  PostSignInRequestDto,
  PostSignInResponseDto,
} from "./types";
import { usersApi } from "../users/api";
import { categoriesApi } from "../categories/api";
import { commentsApi } from "../comments/api";
import { commercialOffersApi } from "../commercialOffers/api";
import { commoditycodesApi } from "../commoditycodes/api";
import { countriesApi } from "../countries/api";
import { currenciesApi } from "../currencies/api";
import { deliveryNotesApi } from "../deliveryNotes/api";
import { departmentsApi } from "../departments/api";
import { unionItemsProductsInquiriesApi } from "../unionItemsProductsInquiriesApi";
import { schoolsApi } from "../schools/api";
import { suppliersApi } from "../suppliers/api";
import { tagsApi } from "../tags/api";
import { removeShopFilters } from "../../utils/filters";

export const validateToken = createAsyncThunk<
  { token: string; user: GetValidateTokenResponseDto } | null,
  void,
  ThunkApi
>("auth/validateToken", async () => {
  const storedToken = getToken();

  if (!storedToken) {
    return null;
  }

  const response = await fetch(getUrl("/auth/validate"), {
    headers: getDefaultHeaders(true),
  });

  if (response.status === 401) {
    removeToken();
    return null;
  }

  return { token: storedToken, user: await response.json() };
});

export const signIn = createAsyncThunk<
  PostSignInResponseDto,
  PostSignInRequestDto,
  ThunkApi
>("auth/signIn", async (signInArguments, { rejectWithValue }) => {
  const response = await fetch(getUrl("/auth/signin"), {
    method: "post",
    headers: getDefaultHeaders(),
    body: JSON.stringify(signInArguments),
  });

  if (response.status !== 200) {
    return rejectWithValue(await response.json());
  }

  const data = await response.json();
  setToken(data.token);
  return data;
});

export const logOut = createAsyncThunk<void, void, ThunkApi>(
  "auth/logOut",
  async (arg, { dispatch }) => {
    await fetch(getUrl("/auth/logout"), {
      method: "get",
      headers: getDefaultHeaders(true),
    });
    removeToken();

    removeShopFilters();

    dispatch(categoriesApi.util.resetApiState());
    dispatch(commentsApi.util.resetApiState());
    dispatch(commercialOffersApi.util.resetApiState());
    dispatch(commoditycodesApi.util.resetApiState());
    dispatch(countriesApi.util.resetApiState());
    dispatch(currenciesApi.util.resetApiState());
    dispatch(deliveryNotesApi.util.resetApiState());
    dispatch(departmentsApi.util.resetApiState());
    dispatch(unionItemsProductsInquiriesApi.util.resetApiState());
    dispatch(schoolsApi.util.resetApiState());
    dispatch(suppliersApi.util.resetApiState());
    dispatch(tagsApi.util.resetApiState());
    dispatch(usersApi.util.resetApiState());
    localStorage.clear();
  }
);

export const fetchPermissions = createAsyncThunk<
  GetPermissionsResponseDto,
  void,
  ThunkApi
>("auth/fetchPermissions", async (_, { rejectWithValue }) => {
  const storedPermissions = getPermissions();

  if (storedPermissions) {
    return storedPermissions;
  }

  const response = await fetch(getUrl("/auth/permissions"));

  if (response.status !== 200) {
    return rejectWithValue(i18n.t("error.unableFetchPermissions"));
  }

  return (await response.json()) as GetPermissionsResponseDto;
});
