import React, { useCallback, useMemo } from "react";
import { NewDesignContextMenu } from "../../../../../components/NewDesignContextMenu";
import { useTranslation } from "react-i18next";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useHistory } from "react-router-dom";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import {
  useCancelSchoolDepartmentInquiryMutation,
  useDeleteSchoolDepartmentInquiryMutation,
} from "../../../../../slices/schoolInquiries/api";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";

export const ContextMenu = () => {
  const { t } = useTranslation("pages");
  const preActionCheck = usePreActionCheck();
  const { handleSuccess, handleError } = useMessageHandlers();
  const history = useHistory();

  const self = useSelector(selectSelf);

  const {
    baseUrl,
    inquiryResult: { data: inquiry },
    inquiryId,
    departmentInquiryId,
  } = useSchoolInquiryItemsTools();

  const departmentInquiry = useMemo(
    () =>
      inquiry?.departmentinquiries.find(({ id }) => id === departmentInquiryId),
    [departmentInquiryId, inquiry?.departmentinquiries]
  );

  const [cancel] = useCancelSchoolDepartmentInquiryMutation();

  const [deleteSchoolDepartmentInquiry] =
    useDeleteSchoolDepartmentInquiryMutation();

  const deleteHandler = useCallback(() => {
    if (departmentInquiryId) {
      deleteSchoolDepartmentInquiry({ inquiryId, departmentInquiryId })
        .unwrap()
        .then(handleSuccess)
        .then(() => {
          history.push(baseUrl);
        })
        .catch(handleError);
    }
  }, [
    deleteSchoolDepartmentInquiry,
    departmentInquiryId,
    handleError,
    handleSuccess,
    history,
    inquiryId,
    baseUrl,
  ]);

  const editHandler = useCallback(() => {
    history.push(`${baseUrl}/edit/department`);
  }, [baseUrl, history]);

  const cancelClickHandler = useCallback(() => {
    if (departmentInquiryId) {
      cancel({ inquiryId, departmentInquiryId })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [cancel, departmentInquiryId, handleError, handleSuccess, inquiryId]);

  const deleteDisabled = useMemo(() => {
    if (!departmentInquiry || !inquiry) {
      return true;
    }

    return (
      departmentInquiry.schoolStatus !== "new" ||
      preActionCheck(inquiry.schoolStatus)
    );
  }, [departmentInquiry, inquiry, preActionCheck]);

  const editDisabled = useMemo(() => {
    if (!inquiry || !departmentInquiry) {
      return true;
    }

    if (!inquiry.budget) {
      return true;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return true;
    }

    return (
      departmentInquiry.schoolStatus !== "new" &&
      departmentInquiry.schoolStatus !== "inProgress" &&
      departmentInquiry.schoolStatus !== "pendingApproval" &&
      departmentInquiry.schoolStatus !== "readyForSending" &&
      departmentInquiry.schoolStatus !== "approvedByHead" &&
      departmentInquiry.schoolStatus !== "needReviewByHead"
    );
  }, [departmentInquiry, inquiry, preActionCheck]);

  const cancelDisabled = useMemo(() => {
    if (!inquiry || !departmentInquiry) {
      return true;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return true;
    }

    if (
      departmentInquiry.schoolStatus !== "new" &&
      departmentInquiry.schoolStatus !== "inProgress" &&
      departmentInquiry.schoolStatus !== "pendingApproval" &&
      departmentInquiry.schoolStatus !== "readyForSending" &&
      departmentInquiry.schoolStatus !== "approvedByHead"
    ) {
      return true;
    }
  }, [departmentInquiry, inquiry, preActionCheck]);

  return useMemo(() => {
    if (self?.role !== "principal") {
      return null;
    }

    return (
      <NewDesignContextMenu
        sx={{ marginLeft: "auto" }}
        buttons={[
          {
            label: t("schoolInquiryDetails.departmentControls.cancel.label"),
            handler: cancelClickHandler,
            disabled: cancelDisabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.departmentControls.cancel.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
          {
            label: t("schoolInquiryDetails.departmentControls.edit"),
            disabled: editDisabled,
            handler: editHandler,
          },
          {
            label: t("schoolInquiryDetails.departmentControls.delete.label"),
            handler: deleteHandler,
            disabled: deleteDisabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.departmentControls.delete.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
        ]}
      />
    );
  }, [
    cancelClickHandler,
    cancelDisabled,
    deleteDisabled,
    deleteHandler,
    editDisabled,
    editHandler,
    self?.role,
    t,
  ]);
};
