import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "../../store";
import { selectSelf } from "../../slices/auth/selectors";
import { useGetSchoolInquiryQuery } from "../../slices/schoolInquiries/api";
import { useCallback, useMemo } from "react";
import { parseNumericParam } from "../../utils/parseNumericParam";

type SchoolInquiryItemsParams = {
  inquiryId: string;
  departmentInquiryId?: string;
  assignmentId?: string;
  itemId?: string;
};

export const useSchoolInquiryItemsTools = () => {
  const history = useHistory();
  const self = useSelector(selectSelf);

  const {
    inquiryId: rawInquiryId,
    departmentInquiryId: rawDepartmentInquiryId,
    assignmentId: rawAssignmentId,
    itemId: rawItemId,
  } = useParams<SchoolInquiryItemsParams>();

  const inquiryId = parseInt(rawInquiryId);

  const inquiryResult = useGetSchoolInquiryQuery(inquiryId);
  const { data: inquiry } = inquiryResult;

  // If there's no departmentInquiryId in the url, we should pick the first one
  // by default
  const departmentInquiryId = useMemo(
    () =>
      parseNumericParam(rawDepartmentInquiryId) ??
      inquiry?.departmentinquiries[0]?.id,
    [inquiry?.departmentinquiries, rawDepartmentInquiryId]
  );

  const assignmentId = useMemo(() => {
    // If the user can only see the assignment he's assigned to,
    // then we should display it to him automatically since he won't see
    // the assignment picker
    if (self?.role === "teacher") {
      return inquiry?.departmentinquiries
        .find(({ id }) => id === departmentInquiryId)
        ?.assignments.find(({ user }) => user.id === self?.id)?.id;
    }

    return parseNumericParam(rawAssignmentId);
  }, [
    departmentInquiryId,
    inquiry?.departmentinquiries,
    rawAssignmentId,
    self?.id,
    self?.role,
  ]);

  const itemId = parseNumericParam(rawItemId);

  const rootUrl = `/schoolinquiries/${inquiryId}/details`;

  const baseUrl = useMemo(() => {
    if (assignmentId) {
      return `${rootUrl}/${departmentInquiryId}/assignments/${assignmentId}`;
    }

    if (itemId) {
      return `${rootUrl}/${departmentInquiryId}/items/${itemId}`;
    }

    if (departmentInquiryId) {
      return `${rootUrl}/${departmentInquiryId}/items`;
    }

    return rootUrl;
  }, [assignmentId, itemId, departmentInquiryId, rootUrl]);

  const closeDrawer = useCallback(() => history.goBack(), [history]);

  return {
    rootUrl,
    baseUrl,
    closeDrawer,
    inquiryId,
    departmentInquiryId,
    assignmentId,
    itemId,
    inquiryResult,
  };
};
