import { useParams } from "react-router-dom";

export type TableNameType = "co" | "po" | "dn" | "mo" | "details";

type InquiryDetailsParams = {
  inquiryId: string;
  tableName?: TableNameType;
};

export const useInquiryDetailsUrlTools = () => {
  const { inquiryId, tableName } = useParams<InquiryDetailsParams>();
  const baseUrl = `/inquiries/${inquiryId}/details`;

  return {
    baseUrl,
    tableName,
    inquiryId: parseInt(inquiryId),
  };
};
