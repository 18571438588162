import React, { useCallback, useMemo } from "react";
import { Link as MaterialLink } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { Actions } from "./Actions";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetSchoolsQuery } from "../../../slices/schools/api";
import { School } from "../../../slices/schools/types";

export const DataTable: React.FC = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const can = usePermissions();
  const { isLoading, isFetching, data, error, refetch } = useGetSchoolsQuery();

  const onAddClick = useCallback(() => history.push("/schools/add"), [history]);

  const columns = useMemo<GridColDef<School>[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.1,
      },
      {
        field: "name",
        headerName: t("schools.fields.name"),
        flex: 0.5,
        renderCell: (params) => (
          <MaterialLink component={Link} to={`/schools/${params.id}`}>
            {params.row.name}
          </MaterialLink>
        ),
      },
      {
        field: "country",
        headerName: t("schools.fields.country"),
        flex: 0.5,
        valueGetter: (params) => params.row.country.name,
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.1,
        minWidth: 100,
        renderCell: (params) => (
          <Actions id={params.id} active={params.row.active} />
        ),
      },
    ],
    [t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("schools.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <CustomDataGrid
        addButtonText={t("schools.add")}
        addButtonDisabled={!can("addSchool")}
        onAddClick={onAddClick}
        tableName="schools"
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={["id", "name"]}
        activeFilterIsUsed={true}
      />
    </PaperPage>
  );
};
