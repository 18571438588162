import React, { useCallback } from "react";
import {
  Checkbox,
  FormControl,
  FormControlProps,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { TextFieldCircular } from "../spinners/TextFieldCircular";

type Option = {
  label: string;
  value: string;
};

type MultipleSelectFilterProps = Omit<FormControlProps, "id" | "onChange"> & {
  id: string;
  label: string;
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  loading?: boolean;
};

export const MultipleSelectFilter = ({
  id,
  label,
  options,
  value,
  onChange,
  loading,
  ...props
}: MultipleSelectFilterProps) => {
  const selectChangeHandler = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;
      onChange(typeof value === "string" ? value.split(", ") : value);
    },
    [onChange]
  );

  return (
    <FormControl sx={{ width: "195px" }} {...props}>
      <InputLabel id={`custom-datagrid-filter-${id}-label`}>{label}</InputLabel>
      <Select
        startAdornment={loading && <TextFieldCircular />}
        labelId={`custom-datagrid-filter-${id}-label`}
        id={`custom-datagrid-filter-${id}`}
        input={<OutlinedInput label={label} />}
        renderValue={(value) =>
          options
            .filter((option) => value.includes(option.value))
            .map(({ label }) => label)
            .join(", ")
        }
        multiple
        value={value}
        onChange={selectChangeHandler}
      >
        {options.map(({ label, value: optionValue }) => (
          <MenuItem key={optionValue} value={optionValue}>
            <Checkbox checked={value.indexOf(optionValue) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
