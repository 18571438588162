import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddDepartmentRequestDto,
  EditDepartmentRequestDto,
  GetDepartmentsRequestDto,
} from "./types";
import { Department, DepartmentDetails } from "../../entities";

export const departmentsApi = createApi({
  reducerPath: "departmentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/departments"),
    prepareHeaders,
  }),
  tagTypes: ["Department"],
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], GetDepartmentsRequestDto>({
      query: (query) => {
        const params = new URLSearchParams();

        if (query.publicOnly) {
          params.append("publicOnly", "true");
        }

        return `?${params.toString()}`;
      },
      providesTags: (result, error, query) => {
        const listId = query.publicOnly ? "list-public" : "list";

        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Department" as const,
                id,
              })),
              {
                type: "Department",
                id: listId,
              },
            ]
          : [{ type: "Department", id: listId }];
      },
    }),
    getDepartment: builder.query<DepartmentDetails, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "Department", id }],
    }),
    addDepartment: builder.mutation<Department, AddDepartmentRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [
        { type: "Department", id: "list" },
        { type: "Department", id: "list-public" },
      ],
    }),
    editDepartment: builder.mutation<Department, EditDepartmentRequestDto>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Department", id: "list" },
        { type: "Department", id: "list-public" },
        { type: "Department", id },
      ],
    }),
    changeDepartmentActivity: builder.mutation<
      Department,
      { id: number; active: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}/changeActivity`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Department", id: "list" },
        { type: "Department", id: "list-public" },
        { type: "Department", id },
      ],
    }),
    deleteDepartment: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "Department", id: "list" },
        { type: "Department", id: "list-public" },
        { type: "Department", id },
      ],
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useAddDepartmentMutation,
  useEditDepartmentMutation,
  useChangeDepartmentActivityMutation,
  useDeleteDepartmentMutation,
} = departmentsApi;
