import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useHistory } from "react-router-dom";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import {
  useCancelAssignmentMutation,
  useDeleteAssignmentMutation,
  useGetAssignmentQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { NewDesignContextMenu } from "../../../../../components/NewDesignContextMenu";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";

export const ContextMenu = () => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();
  const history = useHistory();

  const self = useSelector(selectSelf);

  const {
    baseUrl,
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult: { data: inquiry },
  } = useSchoolInquiryItemsTools();

  const { data: assignment } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const [deleteAssignment] = useDeleteAssignmentMutation();
  const [cancel] = useCancelAssignmentMutation();

  const deleteHandler = useCallback(() => {
    if (departmentInquiryId && assignmentId) {
      deleteAssignment({
        inquiryId,
        departmentInquiryId,
        assignmentId,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    assignmentId,
    deleteAssignment,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  const cancelHandler = useCallback(() => {
    if (departmentInquiryId && assignmentId) {
      cancel({ inquiryId, departmentInquiryId, assignmentId })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    assignmentId,
    cancel,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  const editClickHandler = useCallback(() => {
    history.push(`${baseUrl}/edit/assignment`);
  }, [baseUrl, history]);

  const preActionCheck = usePreActionCheck();

  const deleteDisabled = useMemo(() => {
    if (!assignment || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    return (
      assignment.user.role === "headOfDepartment" ||
      assignment.schoolStatus !== "new"
    );
  }, [assignment, inquiry, preActionCheck]);

  const editDisabled = useMemo(() => {
    if (!inquiry || !assignment) {
      return true;
    }

    if (!inquiry.budget) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    return (
      assignment.schoolStatus !== "new" &&
      assignment.schoolStatus !== "inProgress" &&
      assignment.schoolStatus !== "readyForSending" &&
      assignment.schoolStatus !== "approvedByTeacher" &&
      assignment.schoolStatus !== "needReviewByTeacher"
    );
  }, [assignment, inquiry, preActionCheck]);

  const cancelDisabled = useMemo(() => {
    if (!inquiry || !assignment) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    if (
      assignment.user.role === "headOfDepartment" &&
      assignment.schoolStatus !== "new" &&
      assignment.schoolStatus !== "inProgress" &&
      assignment.schoolStatus !== "readyForSending"
    ) {
      return true;
    }

    if (
      assignment.user.role === "headOfDepartment" &&
      assignment.schoolStatus === "needReviewByTeacher" &&
      assignment.departmentInquiry.schoolStatus !== "new" &&
      assignment.departmentInquiry.schoolStatus !== "needReviewByHead"
    ) {
      return true;
    }

    if (
      assignment.schoolStatus !== "new" &&
      assignment.schoolStatus !== "inProgress" &&
      assignment.schoolStatus !== "readyForSending" &&
      assignment.schoolStatus !== "approvedByTeacher" &&
      assignment.schoolStatus !== "needReviewByTeacher"
    ) {
      return true;
    }

    return false;
  }, [assignment, inquiry, preActionCheck]);

  return useMemo(() => {
    if (self?.role !== "headOfDepartment") {
      return null;
    }

    if (
      !assignment?.departmentInquiry ||
      assignment?.departmentInquiry.schoolStatus === "cancelled" ||
      !assignmentId
    ) {
      return null;
    }

    return (
      <NewDesignContextMenu
        sx={{ marginLeft: "auto" }}
        buttons={[
          {
            label: t("schoolInquiryDetails.assignmentControls.cancel.label"),
            handler: cancelHandler,
            disabled: cancelDisabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.assignmentControls.cancel.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
          {
            label: t("schoolInquiryDetails.assignmentControls.edit"),
            disabled: editDisabled,
            handler: editClickHandler,
          },
          {
            label: t("schoolInquiryDetails.assignmentControls.delete.label"),
            handler: deleteHandler,
            disabled: deleteDisabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.assignmentControls.delete.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
        ]}
      />
    );
  }, [
    assignment?.departmentInquiry,
    assignmentId,
    cancelDisabled,
    cancelHandler,
    deleteDisabled,
    deleteHandler,
    editClickHandler,
    editDisabled,
    self?.role,
    t,
  ]);
};
