import React from "react";
import { alpha, ButtonBase } from "@mui/material";
import { ArrowRight } from "../../../../components/astraIcons/ArrowRight";
import { colors } from "../../../../theme/astra/colors";
import { ArrowLeft } from "../../../../components/astraIcons/ArrowLeft";

type ScrollButtonProps = {
  position: "left" | "right";
  visible?: boolean;
  onClick?: () => void;
};

export const ScrollButton = ({
  position,
  visible = true,
  onClick,
}: ScrollButtonProps) => {
  const isLeft = position === "left";

  return (
    <ButtonBase
      sx={{
        width: "40px",
        height: "100%",
        position: "absolute",
        top: 0,
        left: isLeft ? 0 : "calc(100% - 40px)",
        color: visible ? colors.pink : alpha(colors.pink, 0.3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        [`border${isLeft ? "Right" : "Left"}`]: `1px solid ${colors.dustBlue5}`,
        boxShadow: "2px 0px 12px 0px rgba(23, 79, 148, 0.15)",
      }}
      disabled={!visible}
      onClick={onClick}
    >
      {isLeft ? <ArrowLeft /> : <ArrowRight />}
    </ButtonBase>
  );
};
