import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useEditInquiryCOMutation,
  useGetInquiryQuery,
} from "../../../../slices/inquiries/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetSchoolsQuery } from "../../../../slices/schools/api";
import { useYup } from "../../../../utils/validation";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { useInquiryDetailsUrlTools } from "../../hooks";
import { useBasicDetailsUrlTools } from "../useBasicDetailsUrlTools";
import { EditInquiryDetailsRequestDto } from "../../../../slices/inquiries/types";
import {
  BasicCODetailsForm,
  basicCODetailsSchemaCreator,
} from "./BasicCODetails";
import { EditInquiryCODetailsForm } from "./types";

export const EditCO: React.FC = () => {
  const { t } = useTranslation("pages");
  const { inquiryId: id, baseUrl } = useInquiryDetailsUrlTools();

  const { closeDrawer } = useBasicDetailsUrlTools(`${baseUrl}/details`);

  const feedSuccess = useFeedSuccess();
  const schema = useYup(basicCODetailsSchemaCreator);
  const formMethods = useForm<EditInquiryDetailsRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isFetching: isInquiriesDataLoading,
    data: inquiry,
    error: inquiriesDataLoadingError,
  } = useGetInquiryQuery(id ?? skipToken);
  const {
    data: schoolsData,
    isLoading: isSchoolsDataLoading,
    error: schoolsDataLoadingError,
  } = useGetSchoolsQuery();
  const [editInquiry, { isLoading: isEditLoading }] =
    useEditInquiryCOMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("inquiries.errors.edit")
  );

  const formattedInquiryData = useMemo(() => {
    if (!inquiry || !schoolsData || !id) {
      return null;
    }
    const { school, shippingCost, discountValue, ...fields } = inquiry;
    return {
      ...fields,
      shippingCost: shippingCost ? shippingCost / 100 : shippingCost,
      discountValue:
        discountValue && fields.discountType === "amount"
          ? discountValue / 100
          : discountValue,
      schoolId: schoolsData.find(({ name }) => name === school.name)?.id ?? 0,
    };
  }, [inquiry, schoolsData, id]);

  const onSubmit = useCallback(
    ({ ...form }: EditInquiryCODetailsForm) => {
      if (!id) {
        return null;
      }
      editInquiry({
        ...form,
        id,
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("inquiries.successes.edit"));
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [catchFormError, closeDrawer, editInquiry, feedSuccess, id, t]
  );

  useEffect(() => {
    if (inquiriesDataLoadingError || schoolsDataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, inquiriesDataLoadingError, schoolsDataLoadingError]);

  if (isInquiriesDataLoading || isSchoolsDataLoading) {
    return <Spinner />;
  }

  if (!formattedInquiryData) {
    return null;
  }

  return (
    <Form
      heading={t("inquiries.editCO")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <BasicCODetailsForm
          inquiryData={formattedInquiryData}
          fieldsErrors={fieldsErrors}
        />
      </FormProvider>
    </Form>
  );
};
