import React, { useEffect, useState } from "react";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, styled } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./store";
import { Navigator } from "./Navigator";
import { getTitle } from "./utils/getTitle";
import { fetchPermissions, validateToken } from "./slices/auth/actions";
import { Dotted } from "./components/spinners/Dotted";
import { isTest } from "./utils/env";
import { useIntialEntries } from "./testUtils/useRouterProps";
import { Ga4 } from "./GA4";

const App: React.FC = () => {
  return (
    <AppContainer>
      <Navigator />
    </AppContainer>
  );
};

export default App;

// I made it reusable in order to not repeat myself in tests
export const AppContainer: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      store.dispatch(fetchPermissions()),
      store.dispatch(validateToken()),
    ]).then(() => setLoading(false));
  }, []);

  const app = loading ? (
    <SpinnerContainer>
      <Dotted />
    </SpinnerContainer>
  ) : (
    children
  );

  return (
    <Provider store={store}>
      <BrowserRouterWrapper>
        <Helmet>
          <title>{getTitle()}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
        </Helmet>
        <Ga4 />
        {app}
      </BrowserRouterWrapper>
    </Provider>
  );
};

const SpinnerContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  padding: 0,
  margin: 0,
});

// Use MemoryRouter for depending on URL tests
const BrowserRouterWrapper: React.FC = ({ children }) => {
  const initialEntries = useIntialEntries();

  if (isTest()) {
    return (
      <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
    );
  }

  return <BrowserRouter>{children}</BrowserRouter>;
};
