import React from "react";
import { Box, Typography } from "@mui/material";

type TitleProps = {
  inquiry: string;
  department: string;
};

export const Title = ({ inquiry, department }: TitleProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        component="span"
        sx={{
          fontSize: "14px",
          lineHeight: "14px",
          color: "secondary.light",
        }}
      >
        {inquiry}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: "500",
          color: "primary.dark",
        }}
      >
        {department}
      </Typography>
    </Box>
  );
};
