import React from "react";
import { useTranslation } from "react-i18next";
import {
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { useGetItemQuery } from "../../../slices/items/api";
import { MatchingProducts } from "./MatchingProducts/MatchingProducts";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { PaperBreadcrumbs } from "../../../components/layout/PaperBreadcrumbs";
import { Search } from "./Search/Search";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { Item } from "./Item/Item";
import { PurchaseOrder } from "./PurchaseOrder";
import { MainOrder } from "./MainOrder";
import { Box } from "@mui/material";
import { OpenNew } from "./OpenNew";
import { useHeaderTitle } from "../../../slices/ui/useHeaderTitle";
import { usePermissions } from "../../../utils/usePermissions";

export const Content = () => {
  const { t } = useTranslation("pages");
  const { itemId, tableName, isInquiryId } = useItemDetailsUrlTools();

  const can = usePermissions();

  const { isLoading, isFetching, data, error, refetch } =
    useGetItemQuery(itemId);

  useHeaderTitle(data?.name || t("items.title"));

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("itemDetails.errors.refetch")}
      />
    );
  }

  if (isLoading || isFetching) {
    return <PaperPageSpinner />;
  }

  if (!data) {
    return null;
  }

  return (
    <ContainerBase>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <PaperBreadcrumbs
          paperProps={{ sx: { marginBottom: 2 } }}
          paths={
            isInquiryId
              ? [
                  { href: "/inquiries", text: t("inquiries.title") },
                  {
                    href: `/inquiries/${data.departmentInquiry.inquiry.id}/details`,
                    text: data.inquiryName,
                  },
                  {
                    href: `/inquiries/${data.departmentInquiry.inquiry.id}/details/${data.departmentInquiry.id}/items/${tableName}`,
                    text: data.parentName,
                  },
                ]
              : [{ href: "/newitems", text: t("newitems.title") }]
          }
          current={data.name || t("na", { ns: "common" })}
        />
        <OpenNew />
      </Box>
      <Item />
      {(data.inquiryStatus === "new" || data.inquiryStatus === "matched") && (
        <>
          {data.supplier && data.supplierProductCode && <MatchingProducts />}
          <Search />
        </>
      )}
      {can("getPurchaseOrder") && <PurchaseOrder />}
      {can("getMainOrder") && <MainOrder />}
    </ContainerBase>
  );
};
