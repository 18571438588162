import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { createYupSchema } from "../../../../../../utils/validation";
import {
  fieldError,
  FieldsErrorState,
} from "../../../../../../utils/formHelpers";
import { AddMainOrderForm } from "../../../../../../slices/mainOrders/types";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { enGB } from "date-fns/locale";
import { StyledDropzone } from "./StyledDropzone";
import { FILE_TYPES_MO } from "../../../../../../consts";
import { SelectSupplier } from "../../../../../../components/prefilledSelectors/SelectSupplier";
import { ControlledCustomField } from "../../../../../../components/controlledFields/ControlledCustomField";

export const mainOrderFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    inquiryId: yup.number().positive().required(t("required")),
    supplierId: yup.number().positive().required(t("required")),
    uploadedById: yup.number().positive().required(t("required")),
    referenceNumber: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    date: yup.date().required(t("required")).typeError(t("incorrectDate")),
    uploadedFile: yup
      .mixed()
      .test("fileRequired", t("fileRequired"), (uploadedFile, context) => {
        if (!uploadedFile.file) {
          context.createError();
          return false;
        }
        return true;
      })
      .test("wrongFileType", t("wrongFileType"), (uploadedFile) => {
        if (
          !uploadedFile?.file?.type ||
          !FILE_TYPES_MO.includes(uploadedFile?.file?.type)
        ) {
          return false;
        }
        return true;
      }),
  })
);

type MainOrderFormProps = {
  inquiryId: number;
  userId: number;
  fieldsErrors?: FieldsErrorState;
};

export const MainOrderForm: React.FC<MainOrderFormProps> = ({
  inquiryId,
  userId,
  fieldsErrors,
}) => {
  const { t } = useTranslation("pages");
  const { register, control } = useFormContext<AddMainOrderForm>();

  const [uploadedFile, setUploadedFile] = useState<File>();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <input
        type="hidden"
        defaultValue={inquiryId}
        {...register("inquiryId", {
          required: true,
          valueAsNumber: true,
        })}
      />
      <input
        type="hidden"
        defaultValue={userId}
        {...register("uploadedById", {
          required: true,
          valueAsNumber: true,
        })}
      />
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="supplierId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <SelectSupplier
              label={`${t("mainOrders.fields.supplier")} *`}
              error={!!errorMessage}
              helperText={errorMessage}
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="uploadedFile"
          defaultValue={{ file: null }}
          render={({ field, fieldState: { invalid, error } }) => {
            return (
              <StyledDropzone
                selectedFile={uploadedFile?.name}
                error={invalid}
                errorText={error?.message}
                onDrop={(file) => {
                  setUploadedFile(file);
                  field.onChange({ file: file });
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="referenceNumber"
          defaultValue={""}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "referenceNumber",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("mainOrders.fields.referenceNumber")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="date"
          defaultValue={null}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "date",
              fieldsErrors
            );
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                <DatePicker
                  label={`${t("mainOrders.fields.date")} *`}
                  PopperProps={{ placement: "bottom-start" }}
                  renderInput={({ ...params }) => {
                    return (
                      <TextField
                        helperText={error?.message || serverError}
                        fullWidth
                        size="small"
                        {...params}
                        error={invalid || serverInvalid}
                      />
                    );
                  }}
                  {...field}
                />
              </LocalizationProvider>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
