import {
  DeleteRequestDto,
  DeliveryNote,
  GenerateDNRequestDto,
  GetDNResponseDto,
  GetDNsResponseDto,
  UploadSignedFileRequestDto,
} from "./types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";

export const deliveryNotesApi = createApi({
  reducerPath: "deliverNoteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/deliveryNotes"),
    prepareHeaders,
  }),
  tagTypes: ["DeliveryNote"],
  endpoints: (builder) => ({
    getDNs: builder.query<GetDNsResponseDto, number>({
      query: (inquiryId) => ({
        url: `${inquiryId}`,
        method: "GET",
      }),
      providesTags: () => [{ type: "DeliveryNote", id: "list" }],
    }),
    getDN: builder.query<GetDNResponseDto, number>({
      query: (id) => ({
        url: `one/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "DeliveryNote", id }],
      transformResponse: (res: DeliveryNote) => {
        return {
          ...res,
          inquiry: res?.inquiry?.name || "",
          inquiryId: res?.inquiry?.id || 0,
          generatedBy:
            (res?.generatedBy?.firstName || "") +
            " " +
            (res?.generatedBy?.lastName || ""),
          uploadedBy:
            (res?.uploadedBy?.firstName || "") +
            " " +
            (res?.uploadedBy?.lastName || ""),
        };
      },
    }),
    generateDN: builder.mutation<DeliveryNote, GenerateDNRequestDto>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: () => [{ type: "DeliveryNote", id: "list" }],
    }),
    uploadSignedFile: builder.mutation<
      DeliveryNote,
      UploadSignedFileRequestDto
    >({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "DeliveryNote", id: "list" },
        { type: "DeliveryNote", id },
      ],
      transformResponse: (response: DeliveryNote) => {
        return response;
      },
    }),
    deleteDN: builder.mutation<{ messsage: string }, DeleteRequestDto>({
      query: ({ id }) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "DeliveryNote", id: "list" }],
    }),
  }),
});

export const {
  useGetDNsQuery,
  useGetDNQuery,
  useGenerateDNMutation,
  useUploadSignedFileMutation,
  useDeleteDNMutation,
} = deliveryNotesApi;
