import React from "react";
import {
  AssignmentSchoolStatus,
  assignmentSchoolStatuses,
} from "../../../../../types";
import { StatusBar } from "../../StatusBar/StatusBar";
import { useTranslation } from "react-i18next";
import { ASSIGNMENT_STATUS_CHIP_STYLES } from "../../../../../consts";

type StatusesProps = {
  current: AssignmentSchoolStatus;
  assignmentLink: string;
};

export const Statuses = ({ current, assignmentLink }: StatusesProps) => {
  const { t } = useTranslation("pages");

  const statuses = assignmentSchoolStatuses
    .filter((status) => {
      if (status === "needReviewByTeacher") {
        return status === current;
      }

      return true;
    })
    .map((status) => ({
      status,
      label: t(`assignmentSchoolStatuses.${status}`, { ns: "common" }),
      link:
        status === "needReviewByTeacher" && current === status
          ? {
              to: assignmentLink,
              label: t("schoolInquiries.reviewNow"),
            }
          : null,
      colors: ASSIGNMENT_STATUS_CHIP_STYLES[status],
    }));

  return <StatusBar statuses={statuses} current={current} />;
};
