import React from "react";
import { ProductColors } from "../slices/products/types";
import { Chip, ChipProps } from "@mui/material";
import { Square } from "@mui/icons-material";

export const colorsMap: Record<ProductColors, string> = {
  white: "#FFFFFF",
  black: "#747474",
  multi: "purple",
  green: "#ddffc1",
  blue: "#8fcdf6",
  tan: "#d7c294",
  purple: "#d79af5",
  yellow: "#fff2a5",
  brown: "#c68d60",
  gray: "#cdcdcd",
  pink: "#f5b0e4",
  red: "#efa6a6",
  orange: "#ffb26c",
  beige: "#fff3e0",
  cream: "#fff5ea",
};

type ColoredChipProps = Omit<ChipProps, "icon" | "size" | "label"> & {
  colorIcon: ProductColors;
};

export const ColoredChip = ({ colorIcon }: ColoredChipProps) => (
  <Chip
    label={colorIcon}
    size="small"
    icon={
      <Square
        sx={{
          color: `${colorsMap[colorIcon]} !important`,
        }}
        fontSize="small"
      />
    }
  />
);
