import React, { useCallback, useMemo } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MatchCard } from "./MatchCard";
import { Item } from "../../../../slices/items/types";
import { ShortProductInformation } from "../../../../components/ShortProductInformation";
import { useUnassignProductMutation } from "../../../../slices/items/api";
import { SubmitSpinnerButton } from "../../../../components/SubmitSpinnerButton";
import { ColoredChip } from "../../../../components/ColoredChip";
import { useItemDetailsUrlTools } from "../../useItemDetailsUrlTools";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { InStockIndicator } from "../../../../components/InStockIndicator";

type MatchedProductProps = {
  item: Item;
};

export const MatchedProduct = ({
  item: {
    id,
    product,
    productQuantity,
    productColor,
    inquiryStatus,
    match,
    departmentInquiry,
  },
}: MatchedProductProps) => {
  const { t } = useTranslation("pages");
  const [unassignProduct, { isLoading }] = useUnassignProductMutation();
  const { baseUrl } = useItemDetailsUrlTools();

  const unassignHandler = useCallback(() => {
    unassignProduct({ id, inquiryId: departmentInquiry.inquiry.id });
  }, [unassignProduct, id, departmentInquiry.inquiry.id]);

  const content = useMemo(() => {
    if (!product || !productQuantity) {
      return (
        <Box sx={{ textAlign: "center", display: "flex", height: "100%" }}>
          <Typography variant="subtitle2" gutterBottom sx={{ margin: "auto" }}>
            {t("itemDetails.noAssignedProduct")}
          </Typography>
        </Box>
      );
    }

    return (
      <>
        <ShortProductInformation
          name={product.name}
          supplierName={product.supplierProductCode.supplier.name}
          supplierProductCode={product.supplierProductCode.code}
          retailPrice={product.retailPrice}
          retailCurrency={product.retailCurrency?.code}
          commodityCodeRequiresApproval={
            product.commodityCode?.requireApproval &&
            !product.replacementCommodityCode
          }
        />
        <Stack spacing={1} direction="row">
          <Typography variant="body1">{`${t(
            "items.fields.quantity"
          )}: ${productQuantity}`}</Typography>
          {productColor && <ColoredChip colorIcon={productColor} />}
        </Stack>
        {match && (
          <Stack spacing={1} direction="row">
            <Typography variant="body1">{`${t(
              "items.fields.match"
            )}: ${match}`}</Typography>
          </Stack>
        )}
      </>
    );
  }, [match, product, productColor, productQuantity, t]);

  const actions = useMemo(() => {
    if (product) {
      return (
        <>
          {(inquiryStatus === "new" || inquiryStatus === "matched") && (
            <SubmitSpinnerButton
              size="small"
              onClick={unassignHandler}
              loading={isLoading}
              sx={{ marginRight: 1 }}
            >
              {t("itemDetails.unassign")}
            </SubmitSpinnerButton>
          )}
          <Button
            component={Link}
            to={`${baseUrl}/product/${product.id}`}
            size="small"
          >
            {t("itemDetails.viewDetails")}
          </Button>
          <InStockIndicator
            quantity={product.inStock}
            sx={{
              marginLeft: "auto !important",
              marginRight: "2px",
            }}
          />
        </>
      );
    }
  }, [inquiryStatus, baseUrl, unassignHandler, isLoading, product, t]);

  return (
    <MatchCard
      label={t("itemDetails.matchedProductLabel")}
      actions={actions}
      images={
        product?.images.map((url) => ({
          src: url as unknown as string,
        })) ?? []
      }
    >
      {content}
    </MatchCard>
  );
};
