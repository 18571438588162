import React from "react";
import { Details } from "./Departments/Details";
import { Helmet } from "react-helmet";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../utils/usePermissions";
import { Box } from "@mui/material";
import { CommercialOffers } from "./CommercialOffers/CommercialOffers";
import { PurchaseOrders } from "./PurchaseOrders/PurchaseOrders";
import { CommentDrawer } from "../../components/legacyLayout/Comments/CommentDrawer";
import { DeliveryNotes } from "./DeliveryNotes/DeliveryNotes";
import { MainOrders } from "./MainOrders/MainOrders";
import { useInquiryDetailsUrlTools } from "./hooks";
import { BasicDetails } from "./BasicDetails/BasicDetails";

export const InquiryDetails: React.FC = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { tableName, inquiryId } = useInquiryDetailsUrlTools();

  return (
    <Box sx={{ marginRight: "240px" }}>
      <Helmet>
        <title>{getTitle(t("inquiryDetails.title"))}</title>
      </Helmet>
      {can("getInquiries") && !tableName && <Details />}
      {can("getCommercialOffers") && tableName === "co" && <CommercialOffers />}
      {can("getPurchaseOrders") && tableName === "po" && <PurchaseOrders />}
      {can("getMainOrders") && tableName === "mo" && <MainOrders />}
      {can("getDeliveryNotes") && tableName === "dn" && <DeliveryNotes />}
      {can("getInquiries") && can("editInquiry") && tableName === "details" && (
        <BasicDetails />
      )}
      <CommentDrawer commentableType="inquiry" commentableId={inquiryId} />
    </Box>
  );
};
