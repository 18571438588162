import React from "react";
import { useTranslation } from "react-i18next";
import { MultipleSelectFilter } from "../../../components/toolbar/MultipleSelectFilter";
import { AVAILABLE_FILTER_OPTIONS } from "../../../consts";

export type ActiveFilterProps = {
  onAvailabilitiesFilterChange: (value: string[]) => void;
  availabilitiesFilter: string[];
  activeLabel?: string;
};

export const AvailableFilter: React.FC<ActiveFilterProps> = React.memo(
  ({
    onAvailabilitiesFilterChange,
    availabilitiesFilter,
  }: ActiveFilterProps) => {
    const { t } = useTranslation("pages");

    return (
      <MultipleSelectFilter
        id="by-activity"
        label={t("products.filters.availability")}
        options={AVAILABLE_FILTER_OPTIONS.map((option) => ({
          label: option,
          value: option,
        }))}
        onChange={onAvailabilitiesFilterChange}
        value={availabilitiesFilter}
      />
    );
  }
);
