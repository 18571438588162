import { useSelector } from "../store";

import { selectSelfRole } from "../slices/auth/selectors";

export const useIsSchoolTeam = () => {
  const selfRole = useSelector(selectSelfRole);

  return (
    selfRole === "principal" ||
    selfRole === "teacher" ||
    selfRole === "headOfDepartment"
  );
};
