import { createContext, useContext } from "react";
import * as H from "history";

export type InitialEntries = H.LocationDescriptor[] | undefined;

const TestingRouterContext = createContext<InitialEntries>(undefined);

export const { Provider } = TestingRouterContext;

export const useIntialEntries = () => {
  return useContext(TestingRouterContext);
};
