import React, { memo } from "react";
import { SpinnerDotted, SpinnerDottedProps } from "spinners-react";

export const Dotted = memo<SpinnerDottedProps>(
  ({ size = 50, thickness = 100, speed = 50, color = "#1e90ff", ...props }) => (
    <SpinnerDotted
      size={size}
      thickness={thickness}
      speed={speed}
      color={color}
      {...props}
    />
  )
);
