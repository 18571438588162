import { createSlice } from "@reduxjs/toolkit";
import { ShopState } from "./types";
import { shopLocalStorageKeys } from "./localStorageKeys";
import { getObjectFromLocalStorage } from "../../utils/localStorageTools";
import { setShopList } from "./actions";

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    cartOpen: false,
    list: getObjectFromLocalStorage(shopLocalStorageKeys.list) ?? {
      inquiryId: -1,
      departmentId: -1,
    },
  } as ShopState,
  reducers: {
    toggleCart: (state) => {
      state.cartOpen = !state.cartOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setShopList.fulfilled, (state, { payload }) => {
      state.list = payload;
    });
  },
});

export const {
  actions: { toggleCart },
} = shopSlice;
