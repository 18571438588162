import React from "react";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { Editor } from "@tiptap/react";
import { useTranslation } from "react-i18next";
import { useTableControlsDrpdown } from "./useTableContol";

import { ArrowDown } from "../../../astraIcons/ArrowDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";

type TableControlsProps = {
  editor: Editor;
};

export const TableControls = ({ editor }: TableControlsProps) => {
  const { t } = useTranslation("components");

  const { dropDownAnchor, handleDropDownClick, handleDropDownClose } =
    useTableControlsDrpdown();

  return (
    <>
      <ButtonGroup
        size="small"
        variant="outlined"
        aria-label={t("wysiwygField.formattingButtons")}
      >
        <Button onClick={() => editor.chain().focus().insertTable().run()}>
          {t("wysiwygField.table.createTable")}
        </Button>
        <Button
          size="small"
          variant="outlined"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleDropDownClick}
        >
          {t("wysiwygField.table.tableControls")}
          <ArrowDown
            sx={{
              marginBlock: "-10px",
              marginRight: "-10px",
            }}
          />
        </Button>
      </ButtonGroup>
      <Menu
        id="table-controls-drop-menu"
        anchorEl={dropDownAnchor}
        keepMounted
        open={Boolean(dropDownAnchor)}
        onClose={handleDropDownClose}
      >
        <MenuItem
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          disabled={!editor.can().addColumnAfter()}
        >
          <AddIcon />
          {t("wysiwygField.table.addColumnAfter")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          disabled={!editor.can().addColumnBefore()}
        >
          <AddIcon />
          {t("wysiwygField.table.addColumnBefore")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().addRowBefore().run()}
          disabled={!editor.can().addRowBefore()}
        >
          <AddIcon />
          {t("wysiwygField.table.addRowAbove")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().addRowAfter().run()}
          disabled={!editor.can().addRowAfter()}
        >
          <AddIcon />
          {t("wysiwygField.table.addRowBelow")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().deleteColumn().run()}
          disabled={!editor.can().deleteColumn()}
        >
          <RemoveIcon />
          {t("wysiwygField.table.removeColumn")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().deleteRow().run()}
          disabled={!editor.can().deleteRow()}
        >
          <RemoveIcon />
          {t("wysiwygField.table.removeRow")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().toggleHeaderCell().run()}
          disabled={!editor.can().toggleHeaderCell()}
        >
          <ViewHeadlineIcon />
          {t("wysiwygField.table.toggleHeaderCell")}
        </MenuItem>
        <MenuItem
          onClick={() => editor.chain().focus().deleteTable().run()}
          disabled={!editor.can().deleteTable()}
        >
          <PlaylistRemoveIcon />
          {t("wysiwygField.table.removeTable")}
        </MenuItem>
      </Menu>
    </>
  );
};
