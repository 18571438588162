import { useParams, useRouteMatch } from "react-router-dom";

export type TableName = "matching" | "ordering";

type ItemsTableParams = {
  inquiryId: string;
  detailId: string;
  tableName: TableName;
};

export const useItemsTableBaseUrl = () => {
  const { inquiryId, detailId, tableName } = useParams<ItemsTableParams>();
  const baseUrl = `/inquiries/${inquiryId}/details/${detailId}/items/${tableName}`;

  return {
    baseUrl,
    tableName,
    inquiryId: parseInt(inquiryId),
    detailId: parseInt(detailId),
  };
};

export const useProductsDetailsDrawerTools = (baseUrl: string) => {
  const productDetailsDrawerMatcher = useRouteMatch<{ id: string }>(
    `${baseUrl}/products/:id`
  );

  return productDetailsDrawerMatcher?.params?.id
    ? parseInt(productDetailsDrawerMatcher?.params?.id)
    : null;
};
