import React from "react";
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  SelectProps,
} from "@mui/material";

import { categoryLevels } from "../../slices/categories/types";
import { CATEGORY_LEVELS_SELECTOR_LABELS } from "../../consts";
import { useTranslation } from "react-i18next";

type SelectCategoryLevelProps = Omit<FormControlProps, "id" | "onChange"> & {
  id: string;
  label: string;
  helperText?: string;
  selectProps: SelectProps;
};

export const SelectCategoryLevel: React.FC<SelectCategoryLevelProps> = ({
  id,
  label,
  helperText,
  selectProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth {...props}>
      <InputLabel id={`custom-datagrid-filter-${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`custom-datagrid-filter-${id}-label`}
        id={`custom-datagrid-filter-${id}`}
        label={label}
        {...selectProps}
      >
        {categoryLevels.map((level) => (
          <MenuItem key={level} value={level}>
            {CATEGORY_LEVELS_SELECTOR_LABELS[level]}
          </MenuItem>
        ))}
        {categoryLevels.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
