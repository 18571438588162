import React from "react";
import { Helmet } from "react-helmet";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { Edit } from "./Drawers/Edit";
import { usePermissions } from "../../utils/usePermissions";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { DataTable } from "./DataTable/DataTable";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";

export const baseUrl = "/newitems";

export const NewItems: React.FC = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { editDrawerOpen, closeDrawer } = useTableUrlTools(baseUrl);

  const title = t("newitems.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getItems") && <DataTable />}
      {can("editItem") && (
        <WideDrawer open={editDrawerOpen} close={closeDrawer}>
          <Edit />
        </WideDrawer>
      )}
    </>
  );
};
