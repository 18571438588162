import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Logo } from "../assets/logo.svg";

export const Footer = () => {
  return (
    <Box
      sx={{
        paddingTop: "32px",
        paddingBottom: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        svg: {
          width: "auto",
          height: "20px",
          opacity: 0.2,
        },
      }}
    >
      <Logo />
    </Box>
  );
};
