import { abilityCheckCreator } from "../utils/abilityCheckCreator";

export const useCanViewSchoolInquiries = abilityCheckCreator([
  "admin",
  "principal",
  "headOfDepartment",
  "teacher",
]);

export const useCanViewShop = abilityCheckCreator([
  "admin",
  "principal",
  "headOfDepartment",
  "teacher",
]);
