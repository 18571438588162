import { createYupSchema } from "../../../utils/validation";
import * as yup from "yup";
import { SUPPLIER_PRODUCT_CODE_REGEXP } from "../../../consts";

export const inventoryEntrySchema = createYupSchema((t) =>
  yup.object({
    supplierId: yup.number().positive(t("required")).required(t("required")),
    supplierProductCode: yup
      .string()
      .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
        excludeEmptyString: true,
        message: t("supplierProductCode"),
      })
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    countryId: yup.number().positive(t("required")).required(t("required")),
    quantity: yup
      .number()
      .typeError(t("number"))
      .min(0, t("zeroOrBigger"))
      .integer(t("wholeNumber"))
      .required(t("required")),
    comment: yup
      .string()
      .optional()
      .nullable()
      .max(255, t("tooLong", { maxLength: 255 })),
  })
);
