import React from "react";
import { ControlledFieldProps } from "./types";
import { Controller, FieldValues } from "react-hook-form";
import { fieldError } from "../../utils/formHelpers";
import { ColorField, ColorFieldProps } from "../fields/ColorField";

type ControlledColorFieldProps<TFieldValues> =
  ControlledFieldProps<TFieldValues> &
    Omit<ColorFieldProps, "label" | "error" | "helperText" | "value">;

export const ControlledColorField = <TFieldValues extends FieldValues>({
  control,
  name,
  fieldsErrors,
  label,
  ...props
}: ControlledColorFieldProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, ...field },
        fieldState: { invalid, error },
      }) => {
        const [serverInvalid, serverError] = fieldError("name", fieldsErrors);

        return (
          <ColorField
            label={label ?? name}
            error={invalid || serverInvalid}
            helperText={error?.message || serverError}
            value={value}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
};
