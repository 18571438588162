import { ItemInquiryStatus, ItemOrderStatus } from "./types";

/**
 * This code duplicates some backend logic.
 * Make sure to check both sides when changing something.
 * Backend counterpart is a property in items.service.ts
 */
export const inquiryStatusesPaths: Record<
  ItemInquiryStatus,
  ItemInquiryStatus[] | null
> = {
  new: ["canceled"],
  matched: ["submitted", "canceled"],
  submitted: ["matched", "canceled"],
  confirmed: null,
  delivered: null,
  discontinued: ["new"],
  canceled: ["new"],
};

/**
 * This code duplicates some backend logic.
 * Make sure to check both sides when changing something.
 * Backend counterpart is a property in items.service.ts
 */
export const orderStatusesPaths: Record<
  ItemOrderStatus,
  ItemOrderStatus[] | null
> = {
  new: ["discontinued"],
  placed: ["confirmed", "discontinued"],
  confirmed: ["readyToDispatch"],
  readyToDispatch: ["shipped"],
  shipped: ["clearance"],
  clearance: ["warehouse"],
  warehouse: ["delivered"],
  delivered: null,
  discontinued: null,
};

/**
 * This code duplicates some backend logic.
 * Make sure to check both sides when changing something.
 * Backend counterpart is a property in items.service.ts
 */
export const canSetStatusManually = (
  map: Record<string, string[] | null>,
  currentStatus: string,
  newManualStatus: string
) => {
  const allowedPaths = map[currentStatus];
  return allowedPaths !== null && allowedPaths.includes(newManualStatus);
};
