import React, { memo } from "react";
import {
  ItemInquiryStatus,
  ItemOrderStatus,
} from "../../../../slices/items/types";
import { ContextMenu } from "../../../../components/ContextMenu";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFeedError, useFeedSuccess } from "../../../../utils/feedHooks";
import { usePermissions } from "../../../../utils/usePermissions";
import { useDeleteItemMutation } from "../../../../slices/items/api";
import { useItemsTableBaseUrl } from "../../hooks";

type ActionsProps = {
  id: number;
  inquiryStatus: ItemInquiryStatus;
  orderStatus?: ItemOrderStatus;
  hasAssignedProduct?: boolean;
};

export const Actions = memo(
  ({ id, inquiryStatus, orderStatus, hasAssignedProduct }: ActionsProps) => {
    const { t } = useTranslation("pages");
    const history = useHistory();

    const feedSuccess = useFeedSuccess();
    const feedError = useFeedError();
    const can = usePermissions();

    const { baseUrl, inquiryId } = useItemsTableBaseUrl();

    const [deleteItem] = useDeleteItemMutation();

    return (
      <ContextMenu
        buttons={[
          {
            label: t("actions.edit", { ns: "common" }),
            handler: () => {
              history.push(`${baseUrl}/${id}/edit`);
            },
            disabled:
              !can("editItem") ||
              (inquiryStatus !== "new" && inquiryStatus !== "matched") ||
              hasAssignedProduct,
          },
          {
            label: t("items.actions.delete.label"),
            confirmation: {
              title: t("items.actions.delete.confirmationTitle"),
              description: t("items.actions.delete.confirmationDescription"),
            },
            handler: () => {
              deleteItem({ id, inquiryId })
                .unwrap()
                .then(() => {
                  feedSuccess(t("items.successes.delete"));
                })
                .catch((error) => {
                  feedError(error?.data?.message ?? t("items.errors.delete"));
                });
            },
            disabled: !can("deleteItem") || inquiryStatus !== "new",
          },
          {
            label: t("items.actions.assignPurchaseOrder.label"),
            handler: () => {
              history.push(`${baseUrl}/assignPurchaseOrder?ids=${id}`);
            },
            disabled:
              !can("assignItemPurchaseOrder") ||
              (inquiryStatus !== "submitted" &&
                inquiryStatus !== "confirmed" &&
                orderStatus !== "new"),
          },
        ]}
      />
    );
  }
);
