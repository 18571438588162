import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { Menu } from "./Menu";
import { UserMenu } from "./UserMenu/UserMenu";

export const Header = () => {
  return (
    <Box
      sx={{
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "secondary.light",
        position: "fixed",
        height: "64px",
        width: "100vw",
        top: 0,
        backgroundColor: "common.white",
        zIndex: 10000,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          marginLeft: "32px",
          marginRight: "auto",
          height: "32px",
          flexGrow: 0,
          svg: {
            height: "100%",
            width: "auto",
          },
        }}
      >
        <Logo />
      </Box>
      <Menu />
      <UserMenu />
    </Box>
  );
};
