import React, { memo } from "react";
import { Badge, Box, Button, Stack, styled } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/astra/colors";
import { SchoolInquiriesFilter } from "../../../slices/schoolInquiries/types";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";

export type SchoolInquiriesToolbarProps = {
  addButtonText?: string;
  addButtonDisabled?: boolean;
  onAddClick?: () => void;
  additionalButtonText?: string;
  additionalButtonDisabled?: boolean;
  additionalButtonDownloadInProcess?: boolean;
  onAdditionalClick?: () => void;
  hideAddButtonPlusSymbol?: boolean;
  filters?: React.ReactNode;
  buttons?: React.ReactNode;
  isLoading?: boolean;
  filter: SchoolInquiriesFilter;
  onFilterChange: (value: SchoolInquiriesFilter) => void;
  filterCount?: {
    all: number;
    active: number;
    cancelled: number;
    completed: number;
  };
};

export const SchoolInquiriesToolbar = memo<SchoolInquiriesToolbarProps>(
  ({
    addButtonText,
    addButtonDisabled,
    onAddClick,
    hideAddButtonPlusSymbol,
    isLoading,
    filter,
    onFilterChange,
    filterCount,
  }) => {
    const { t } = useTranslation("components");

    return (
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            paddingBottom: "20px",
          }}
        >
          {isLoading ? (
            <>
              {Array.from(new Array(4)).map((_value, index) => (
                <EnhancedSkeleton
                  key={index}
                  variant="rectangular"
                  width={[90, 150, "px"]}
                  height={38}
                />
              ))}
            </>
          ) : (
            <>
              <FilterBadge
                badgeContent={filterCount?.active || "0"}
                sx={{
                  color:
                    filter === "active" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "active" ? colors.darkBlue : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("active")}
                >
                  {t("schoolInquiries.inquiryFilters.active", { ns: "pages" })}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.completed || "0"}
                sx={{
                  color:
                    filter === "completed" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "completed"
                        ? colors.darkBlue
                        : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("completed")}
                >
                  {t("schoolInquiries.inquiryFilters.completed", {
                    ns: "pages",
                  })}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.cancelled || "0"}
                sx={{
                  color:
                    filter === "cancelled" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "cancelled"
                        ? colors.darkBlue
                        : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("cancelled")}
                >
                  {t("schoolInquiries.inquiryFilters.cancelled", {
                    ns: "pages",
                  })}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.all || "0"}
                sx={{
                  color: filter === "all" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "all" ? colors.darkBlue : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("all")}
                >
                  {t("schoolInquiries.inquiryFilters.all", {
                    ns: "pages",
                  })}
                </Button>
              </FilterBadge>
            </>
          )}
        </Box>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
          {addButtonText && onAddClick && (
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={hideAddButtonPlusSymbol ? null : <Add />}
                variant="outlined"
                onClick={onAddClick}
                disabled={addButtonDisabled}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {addButtonText}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
);

const FilterBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    marginTop: "8px",
    marginRight: "20px",
  },
});
