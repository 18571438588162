import React, { useEffect } from "react";
import { useGetProductQuery } from "../../../slices/products/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { ProductDetails as ProductDetailsGrid } from "../../../components/ProductDetails";
import { useGetCategoriesQuery } from "../../../slices/categories/api";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { Noop } from "../../../types";
import { useTranslation } from "react-i18next";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";

type ProductDetailsProps = {
  closeDrawer: Noop;
  id: number;
};

export const ProductDetails = ({ closeDrawer, id }: ProductDetailsProps) => {
  const { t } = useTranslation("common");
  const { baseUrl } = useItemDetailsUrlTools();
  const {
    data: product,
    isLoading: isProductLoading,
    error: productError,
  } = useGetProductQuery(id ? { id } : skipToken);
  const { data: categories, isLoading: areCategoriesLoading } =
    useGetCategoriesQuery();

  useEffect(() => {
    if (productError) {
      closeDrawer();
    }
  }, [productError, closeDrawer]);

  if (isProductLoading || areCategoriesLoading) {
    return <Spinner />;
  }

  if (!product || !categories) {
    return null;
  }

  return (
    <DisplayContainer
      heading={`${product.supplier.name}  ${product.supplierProductCode}`}
      editHref={`${baseUrl}/product/${id}/edit`}
      editLabel={t("actions.edit")}
    >
      <ProductDetailsGrid product={product} categories={categories} />
    </DisplayContainer>
  );
};
