import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useSelector } from "../../../../../store";
import { useChangeItemsStatusMutation } from "../../../../../slices/schoolInquiries/api";
import { Assignment, DepartmentInquiry } from "../../../../../entities";
import {
  Item,
  ItemSchoolStatus,
  itemSchoolStatuses,
} from "../../../../../slices/items/types";
import { MenuItem, TextField } from "@mui/material";
import { ITEM_SCHOOL_STATUS_CHIP_STYLES } from "../../../../../consts";
import { itemSchoolStatusesPaths } from "../../../../../slices/schoolInquiries/utils";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { NewDesignStatusChip } from "../../../../../components/NewDesignStatusChip";
import { InquirySchoolStatus } from "../../../../../types";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";

type SelectStatusProps = {
  inquiryId: number;
  inquirySchoolStatus?: InquirySchoolStatus;
  departmentInquiry?: Pick<
    DepartmentInquiry,
    "id" | "schoolStatus" | "assignments"
  >;
  assignments?: Array<Pick<Assignment, "id" | "schoolStatus" | "user">>;
  item?: Pick<Item, "id" | "schoolStatus">;
  isBulkSelected?: boolean;
};

export const SelectStatus = ({
  inquiryId,
  inquirySchoolStatus,
  departmentInquiry,
  assignments,
  item,
  isBulkSelected,
}: SelectStatusProps) => {
  const { t } = useTranslation("common");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [changeItemsStatus, { isLoading: isChanging }] =
    useChangeItemsStatusMutation();

  const [localValue, setLocalValue] = useState(item?.schoolStatus || "new");

  const changeHandler = useCallback((event) => {
    setLocalValue(event.target.value);
  }, []);

  useEffect(() => {
    if (item?.schoolStatus) {
      setLocalValue(item.schoolStatus);
    }
  }, [item?.schoolStatus]);

  useEffect(() => {
    if (isBulkSelected) {
      return;
    }

    const departmentInquiryId = departmentInquiry?.id;
    const itemId = item?.id;

    if (
      assignments &&
      departmentInquiryId &&
      itemId &&
      item.schoolStatus !== localValue
    ) {
      const timeoutId = setTimeout(() => {
        changeItemsStatus({
          inquiryId,
          departmentInquiryId,
          assignmentsIds: assignments.map(({ id }) => id),
          itemsIds: [itemId],
          schoolStatus: localValue,
        })
          .unwrap()
          .then(handleSuccess)
          .catch((e) => {
            handleError(e);
            setLocalValue(item.schoolStatus);
          });
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    assignments,
    changeItemsStatus,
    departmentInquiry?.id,
    handleError,
    handleSuccess,
    inquiryId,
    item?.id,
    item?.schoolStatus,
    localValue,
    isBulkSelected,
  ]);

  const preActionCheck = usePreActionCheck();

  const { availabilityMap, hideSelect } = useMemo(() => {
    const keyValueMap: Array<[ItemSchoolStatus, boolean | undefined]> =
      itemSchoolStatuses.map((status) => [
        status,
        item && departmentInquiry && self
          ? itemSchoolStatusesPaths[status]({
              item,
              departmentInquiry,
              self,
            }) && item.schoolStatus !== status
          : false,
      ]);

    return {
      availabilityMap: Object.fromEntries(keyValueMap),
      hideSelect:
        keyValueMap.every(([, availability]) => !availability) ||
        !inquirySchoolStatus ||
        preActionCheck(inquirySchoolStatus),
    };
  }, [departmentInquiry, inquirySchoolStatus, item, preActionCheck, self]);

  if (!departmentInquiry || !item || !self) {
    return null;
  }

  const { schoolStatus } = item;

  if (hideSelect) {
    return (
      <NewDesignStatusChip
        label={t(`itemSchoolStatuses.${schoolStatus}`)}
        chipStyle={ITEM_SCHOOL_STATUS_CHIP_STYLES[schoolStatus]}
        detached
      />
    );
  }

  return (
    <TextField
      size="small"
      fullWidth
      select
      value={localValue}
      onChange={changeHandler}
      variant="standard"
    >
      {itemSchoolStatuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          disabled={!availabilityMap[status] || isChanging}
        >
          <NewDesignStatusChip
            label={t(`itemSchoolStatuses.${status}`)}
            chipStyle={ITEM_SCHOOL_STATUS_CHIP_STYLES[status]}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};
