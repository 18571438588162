import React from "react";
import { createSvgIcon } from "@mui/material";

export const ArrowDown = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.23966 10.2004C8.5432 9.91855 9.01775 9.93613 9.2996 10.2397L12 13.1478L14.7004 10.2397C14.9823 9.93613 15.4568 9.91855 15.7603 10.2004C16.0639 10.4823 16.0815 10.9568 15.7996 11.2603L12.5496 14.7603C12.4077 14.9132 12.2086 15 12 15C11.7915 15 11.5923 14.9132 11.4504 14.7603L8.20041 11.2603C7.91856 10.9568 7.93613 10.4823 8.23966 10.2004Z"
      fill="currentColor"
    />
  </svg>,
  "ArrowDown"
);
