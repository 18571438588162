import React, { useCallback, useMemo, useState } from "react";
import { IconButton, IconButtonProps, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { ClickableWithConfirmation } from "./ClickableWithConfirmation";
import { Circular } from "./spinners/Circular";

type Confirmation = {
  title: string;
  description: string;
};

type Button = {
  label: string;
  handler: () => void;
  disabled?: boolean;
  confirmation?: Confirmation;
  loading?: boolean;
};

type ContextMenuProps = Omit<
  IconButtonProps,
  "onClick" | "aria-controls" | "aria-expanded" | "aria-haspopup"
> & {
  buttons: Button[];
};

/**
 * Had to add a context menu without edit and delete. Creating a new component
 * for that seemed easier than customizing already bloated CustomDataGridRowActions.
 *
 * This is component for old design. Replace with NewDesignContextMenu if possible
 * @deprecated
 */
export const ContextMenu = ({ buttons, ...props }: ContextMenuProps) => {
  const idBase = "row-actions-menu";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handlerClosed = useCallback((handler: () => void) => {
    handler();
    setAnchorEl(null);
  }, []);

  const clickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeHandler = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const buttonsElements = useMemo(
    () =>
      buttons.map(
        ({ label, handler, disabled, confirmation, loading }, index) => {
          const content = loading ? <Circular size={24} /> : label;

          return confirmation ? (
            <ClickableWithConfirmation
              key={`${idBase}-button-${index}`}
              onClick={() => handlerClosed(handler)}
              confirmationTitle={confirmation.title}
              confirmationDescription={confirmation.description}
              component={MenuItem}
              disabled={disabled}
            >
              {content}
            </ClickableWithConfirmation>
          ) : (
            <MenuItem
              key={`${idBase}-button-${index}`}
              onClick={() => handlerClosed(handler)}
              disabled={disabled}
            >
              {content}
            </MenuItem>
          );
        }
      ),
    [buttons, handlerClosed]
  );

  return (
    <>
      <IconButton
        onClick={clickHandler}
        aria-controls={idBase}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        {...props}
      >
        <MoreVert />
      </IconButton>
      <Menu id={idBase} open={open} anchorEl={anchorEl} onClose={closeHandler}>
        {buttonsElements}
      </Menu>
    </>
  );
};
