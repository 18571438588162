import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Control, Controller, useWatch } from "react-hook-form";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import { SelectSchool } from "../../../../components/prefilledSelectors/SelectSchool";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { SelectPrincipal } from "../../../../components/prefilledSelectors/SelectPrincipal";
import { ControlledTextField } from "../../../../components/controlledFields/ControlledTextField";
import { ControlledNumericCurrencyField } from "../../../../components/controlledFields/ControlledNumericCurrencyField";
import { SelectCurrency } from "../../../../components/prefilledSelectors/SelectCurrency";
import { SchoolInquiryForm } from "../../../../slices/schoolInquiries/types";
import { useSelector } from "../../../../store";
import { StyledFieldTitle } from "../../../SignIn/StyledComponents";
import { selectSelfRole } from "../../../../slices/auth/selectors";

type FormProps = {
  control: Control<SchoolInquiryForm>;
  fieldsErrors?: FieldsErrorState;
};

export const Form = ({ control, fieldsErrors }: FormProps) => {
  const { t } = useTranslation("pages");
  const selfRole = useSelector(selectSelfRole);

  const schoolId = useWatch({ control, name: "schoolId" });
  const isBudget = useWatch({ control, name: "isBudget" });

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("schoolInquiries.fields.school")} *`}
        </StyledFieldTitle>
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="schoolId"
          render={(errorMessage, { field }) => (
            <SelectSchool
              fullWidth
              placeholder={`${t("schoolInquiries.fields.school")} *`}
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("schoolInquiries.fields.principal")} *`}
        </StyledFieldTitle>
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="userId"
          render={(errorMessage, { field }) => (
            <SelectPrincipal
              schoolId={schoolId}
              fullWidth
              placeholder={`${t("schoolInquiries.fields.principal")} *`}
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
              disabled={selfRole === "principal"}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("schoolInquiries.fields.name")} *`}
        </StyledFieldTitle>
        <ControlledTextField
          control={control}
          fieldsErrors={fieldsErrors}
          name="name"
          label={`${t("schoolInquiries.fields.name")} *`}
          inputProps={{ placeholder: `${t("schoolInquiries.fields.name")} *` }}
          disableLabel
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="isBudget"
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox checked={value ?? false} onChange={onChange} />
              }
              label={t("schoolInquiries.fields.isBudget") as string}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle
          sx={{
            marginTop: "0px !important",
            color: !isBudget ? "#a3a3a3" : "#5E7DA0",
          }}
        >
          {`${t("schoolInquiries.fields.budget")}`}
        </StyledFieldTitle>
        <Box sx={{ display: "flex", gap: 0 }}>
          <ControlledNumericCurrencyField
            fullWidth
            control={control}
            fieldsErrors={fieldsErrors}
            disabled={!isBudget}
            name="budget"
            positiveOnly
            disableLabel
            placeholder={t("schoolInquiries.fields.budget")}
            sx={{
              flex: "3",
              ".MuiOutlinedInput-root": {
                height: "40px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "4px 0 0 4px",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "4px 0 0 4px",
              },
            }}
          />
          <ControlledCustomField
            control={control}
            name="budgetCurrencyId"
            render={(errorMessage, { field }) => (
              <SelectCurrency
                onlySchoolCurrency
                placeholder={`${t("schoolInquiries.fields.budgetCurrency")}`}
                fullWidth
                disabled={!isBudget}
                error={!!errorMessage}
                helperText={errorMessage}
                {...field}
                sx={{
                  minWidth: "100px",
                  flex: "1",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0 4px 4px 0",
                  },
                }}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item gridColumn="span 12" sx={{ marginTop: "-10px" }}>
        <Typography sx={{ color: !isBudget ? "#a3a3a3" : "#263238" }}>
          {t("schoolInquiries.fields.budgetHint")}
        </Typography>
      </Grid>
    </Grid>
  );
};
