import {
  AddInquiryRequestDto,
  EditInquiryCODetailsRequestDto,
  EditInquiryDetailsRequestDto,
  GetInquiriesAutocompleteRequestDto,
  GetInquiriesAutocompleteResponseDto,
  InquiryDetails,
  InquiryDetailsFlattened,
  InquiryFlattened,
  InquiryServerCrudResponse,
  InquiryStatistics,
} from "./types";
import { GridSortModel } from "@mui/x-data-grid";
import { DEFAULT_SORT_MODEL } from "../../consts";
import { MessageResponseDto } from "../../types";
import { unionItemsProductsInquiriesApi } from "../unionItemsProductsInquiriesApi";
import { Inquiry } from "../../entities";

export type InquiriesRowsRequest = {
  page: number;
  pageSize: number;
  search: string;
  sortModel?: GridSortModel;
  statusFilter?: string[];
};

export type InquiryRowsResponse = {
  data: Inquiry[];
  rowCount: number;
  statistics: InquiryStatistics;
};

const baseUrl = "inquiries";

const inquiriesApi = unionItemsProductsInquiriesApi.injectEndpoints({
  endpoints: (builder) => ({
    getInquiries: builder.query<
      InquiryServerCrudResponse,
      InquiriesRowsRequest
    >({
      query: ({ page, pageSize, search, sortModel, statusFilter }) => {
        const params: Record<string, string> = {
          page: JSON.stringify(page),
          pageSize: JSON.stringify(pageSize),
          search,
        };

        if (sortModel) {
          params.sortField =
            sortModel.length > 0
              ? sortModel[0].field
              : DEFAULT_SORT_MODEL[0].field;

          params.sort =
            sortModel.length > 0
              ? JSON.stringify(sortModel[0].sort)
              : JSON.stringify(DEFAULT_SORT_MODEL[0].sort);
        }

        if (statusFilter) {
          params.statuses =
            statusFilter.length > 0
              ? statusFilter
                  .reduce((prev, curr) => `${prev}_${curr}`, "")
                  .substring(1)
              : "";
        }

        const urlSearchParams = new URLSearchParams(params);
        return `${baseUrl}?${urlSearchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Inquiry" as const,
                id,
              })),
              { type: "Inquiry", id: "list", rowCount: result.rowCount },
            ]
          : [{ type: "Inquiry", id: "list", rowCount: 0 }],
    }),
    getInquiriesAutocomplete: builder.query<
      GetInquiriesAutocompleteResponseDto,
      GetInquiriesAutocompleteRequestDto
    >({
      query: ({ search }) => {
        const urlSearchParams = new URLSearchParams({
          search,
        });

        return `${baseUrl}/autocomplete?${urlSearchParams.toString()}`;
      },
    }),
    getInquiry: builder.query<InquiryFlattened, number>({
      query: (id) => `${baseUrl}/${id}`,
      providesTags: (result, error, id) => [{ type: "Inquiry", id }],
    }),
    getInquiryDetails: builder.query<InquiryDetailsFlattened, number>({
      query: (id) => `${baseUrl}/details/${id}`,
      transformResponse: ({
        relatedDepartmentsInquiries,
        ...inquiry
      }: InquiryDetails) => ({
        ...inquiry,
        relatedDepartmentsInquiries: relatedDepartmentsInquiries.map(
          ({ department, ...departmentInquiry }) => ({
            ...departmentInquiry,
            name: department.name,
          })
        ),
      }),
      providesTags: (result, error, id) => [{ type: "DetailedInquiry", id }],
    }),
    addInquiry: builder.mutation<Inquiry, AddInquiryRequestDto>({
      query: ({ budget, ...body }) => ({
        url: baseUrl,
        method: "POST",
        body: {
          ...body,
          budget: budget ? Math.trunc(Number(budget) * 100) : null,
        },
      }),
      invalidatesTags: [{ type: "Inquiry", id: "list" }],
    }),
    editInquiry: builder.mutation<Inquiry, EditInquiryDetailsRequestDto>({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Inquiry", id: "list" },
        { type: "Inquiry", id },
        { type: "DetailedInquiry", id },
      ],
    }),
    editInquiryCO: builder.mutation<Inquiry, EditInquiryCODetailsRequestDto>({
      query: ({ id, discountType, discountValue, shippingCost, ...body }) => ({
        url: `${baseUrl}/co/${id}`,
        method: "PUT",
        body: {
          ...body,
          discountType: discountType ? discountType : null,
          shippingCost: shippingCost
            ? Math.trunc(Number(shippingCost) * 100)
            : null,
          discountValue:
            discountValue && discountType === "amount"
              ? Math.trunc(Number(discountValue) * 100)
              : discountValue,
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Inquiry", id: "list" },
        { type: "Inquiry", id },
        { type: "DetailedInquiry", id },
      ],
    }),
    deleteInquiry: builder.mutation<MessageResponseDto, number>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "Inquiry", id: "list" },
        { type: "Inquiry", id },
      ],
    }),
    loseInquiry: builder.mutation<MessageResponseDto, number>({
      query: (id) => ({ url: `${baseUrl}/lose/${id}`, method: "PATCH" }),
      invalidatesTags: (result, error, id) => [
        { type: "Inquiry", id: "list" },
        { type: "Inquiry", id },
      ],
    }),
    loseDepartmentInquiry: builder.mutation<
      MessageResponseDto,
      { departmentId: number; inquiryId: number }
    >({
      query: ({ departmentId }) => ({
        url: `${baseUrl}/losedepartment/${departmentId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { inquiryId }) => [
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "Item", id: "list" },
      ],
    }),
  }),
});

export const {
  useGetInquiriesQuery,
  useGetInquiriesAutocompleteQuery,
  useGetInquiryQuery,
  useGetInquiryDetailsQuery,
  useAddInquiryMutation,
  useEditInquiryMutation,
  useEditInquiryCOMutation,
  useDeleteInquiryMutation,
  useLoseInquiryMutation,
  useLoseDepartmentInquiryMutation,
} = inquiriesApi;
