import React from "react";
import { Box, Grid } from "@mui/material";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { ControlledNumericCurrencyField } from "../../../../components/controlledFields/ControlledNumericCurrencyField";
import { SelectCurrency } from "../../../../components/prefilledSelectors/SelectCurrency";
import { Control } from "react-hook-form";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import {
  AddSchoolDepartmentInquiryForm,
  EditSchoolDepartmentInquiryForm,
  SchoolDepartmentInquiryForm,
} from "../../../../slices/schoolInquiries/types";
import { useTranslation } from "react-i18next";
import { NewDesignSelectDepartment } from "../../../../components/prefilledSelectors/NewDesignSelectDepartment";
import { NewDesignSelectDepartmentHead } from "../../../../components/prefilledSelectors/NewDesignSelectDepartmentHead";
import { StyledFieldTitle } from "../../../SignIn/StyledComponents";

type DepartmentFormProps = {
  fieldsErrors?: FieldsErrorState;
  schoolId: number;
  isBudget: boolean;
} & (
  | { editing?: false; control: Control<AddSchoolDepartmentInquiryForm> }
  | { editing: true; control: Control<EditSchoolDepartmentInquiryForm> }
);

const addFormFieldsGuard = (
  control: DepartmentFormProps["control"],
  editing?: boolean
): control is Control<AddSchoolDepartmentInquiryForm> => !editing;

const dummyGuard = (
  control: DepartmentFormProps["control"]
): control is Control<SchoolDepartmentInquiryForm> => true;

export const DepartmentForm = ({
  control,
  fieldsErrors,
  schoolId,
  isBudget,
  editing,
}: DepartmentFormProps) => {
  const { t } = useTranslation("pages");

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {addFormFieldsGuard(control, editing) && (
        <>
          <Grid item gridColumn="span 12">
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {`${t("schoolInquiryDetails.departmentFields.department")} *`}
            </StyledFieldTitle>
            <ControlledCustomField
              control={control}
              fieldsErrors={fieldsErrors}
              name="departmentId"
              render={(errorMessage, { field }) => (
                <NewDesignSelectDepartment
                  id="add-department"
                  fullWidth
                  label={`${t(
                    "schoolInquiryDetails.departmentFields.department"
                  )} *`}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  selectProps={field}
                  size="small"
                  publicOnly
                />
              )}
            />
          </Grid>
          <Grid item gridColumn="span 12">
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {`${t(
                "schoolInquiryDetails.departmentFields.departmentTeacher"
              )} *`}
            </StyledFieldTitle>
            <ControlledCustomField
              control={control}
              fieldsErrors={fieldsErrors}
              name="departmentHeadId"
              render={(errorMessage, { field }) => (
                <NewDesignSelectDepartmentHead
                  id="add-departmnet"
                  schoolId={schoolId}
                  fullWidth
                  label={`${t(
                    "schoolInquiryDetails.departmentFields.departmentTeacher"
                  )} *`}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  selectProps={field}
                  size="small"
                />
              )}
            />
          </Grid>
        </>
      )}
      {dummyGuard(control) && (
        <Grid item gridColumn="span 12">
          <StyledFieldTitle
            sx={{
              marginTop: "0px !important",
              color: !isBudget ? "#a3a3a3" : "#5E7DA0",
            }}
          >
            {`${t("schoolInquiries.fields.budget")}`}
          </StyledFieldTitle>
          <Box sx={{ display: "flex", gap: 0 }}>
            <ControlledNumericCurrencyField
              fullWidth
              control={control}
              fieldsErrors={fieldsErrors}
              disabled={!isBudget}
              name="budget"
              positiveOnly
              disableLabel
              placeholder={t("schoolInquiries.fields.budget")}
              sx={{
                flex: "3",
                ".MuiOutlinedInput-root": {
                  height: "40px",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "4px 0 0 4px",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: "4px 0 0 4px",
                },
              }}
            />
            <ControlledCustomField
              control={control}
              name="budgetCurrencyId"
              render={(errorMessage, { field }) => (
                <SelectCurrency
                  onlySchoolCurrency
                  placeholder={`${t(
                    "schoolInquiryDetails.departmentFields.budgetCurrency"
                  )}`}
                  fullWidth
                  disabled={!isBudget}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  {...field}
                  sx={{
                    minWidth: "100px",
                    flex: "1",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0 4px 4px 0",
                    },
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
