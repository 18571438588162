import React, { useCallback, useState } from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";

type ConfirmationButtonProps = {
  confirmationTitle: string;
  confirmationDescription: string;
  proceedButtonText?: string;
  loading?: boolean;
};

/**
 * It's still not perfect, it needs to be independent of the onClick handler.
 * I'll manage that later.
 */
export const ConfirmationButton = <Component extends React.ElementType>({
  component,
  onClick,
  confirmationTitle,
  confirmationDescription,
  loading,
  proceedButtonText,
  ...props
}: Omit<React.ComponentPropsWithRef<Component>, "component"> & {
  component: Component;
} & ConfirmationButtonProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleClick = useCallback(() => {
    setConfirmationOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setConfirmationOpen(false);
  }, []);

  const handleProceed = useCallback(() => {
    setConfirmationOpen(false);
    onClick();
  }, [onClick]);

  return (
    <>
      {React.createElement(component, { ...props, onClick: handleClick })}
      <ConfirmationDialog
        open={confirmationOpen}
        title={confirmationTitle}
        description={confirmationDescription}
        handleCancel={handleCancel}
        handleProceed={onClick && handleProceed}
        loading={loading}
        proceedButtonText={proceedButtonText}
      />
    </>
  );
};
