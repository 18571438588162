import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { productFormSchemaCreator } from "./Form/schema";
import { Form as ProductForm } from "./Form/Form";
import { AddProductForm, Match } from "../../../slices/products/types";
import { useAddProductMutation } from "../../../slices/products/api";
import { useYup } from "../../../utils/validation";
import { productFormDefaultValues } from "./Form/defaultValues";
import { useSearchDebounce } from "../../../utils/useSearchDebounce";
import { getDefaultHeaders, getUrl } from "../../../utils/api";
import { mapMatches, uploadProductImages } from "./utils";

export const Add: React.FC = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(productFormSchemaCreator);
  const formMethods = useForm<AddProductForm>({
    defaultValues: productFormDefaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, control, setValue } = formMethods;
  const [addProduct, { isLoading }] = useAddProductMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("products.errors.add")
  );

  const { closeDrawer } = useTableUrlTools("/products");

  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const onSubmit = useCallback(
    ({ images, ...form }: AddProductForm) => {
      const files = images.map(({ fileList }) => fileList[0]);

      setIsUploadingImages(true);
      uploadProductImages(files)
        .then((s3keys) => {
          setIsUploadingImages(false);
          return addProduct({
            ...form,
            images: s3keys,
            availability: true,
          }).unwrap();
        })
        .then(({ id }) => {
          feedSuccess(t("products.successes.add"), `/products/${id}`);
          closeDrawer();
          reset();
        })
        .catch((error) => {
          setIsUploadingImages(false);
          catchFormError(error);
        });
    },
    [addProduct, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  const [loadingMatches, setLoadingMatches] = useState(false);
  const supplierId = useWatch({
    control,
    name: "supplierId",
  });
  const supplierProductCode = useWatch({
    control,
    name: "supplierProductCode",
  });
  const debouncedSupplierProductCode = useSearchDebounce(supplierProductCode);
  useEffect(() => {
    if (supplierId && debouncedSupplierProductCode) {
      setLoadingMatches(true);
      fetch(
        getUrl(
          `/matches?supplierId=${supplierId}&supplierProductCode=${debouncedSupplierProductCode}`
        ),
        { headers: getDefaultHeaders(true) }
      )
        .then((response) => {
          setLoadingMatches(false);

          if (response.ok) {
            return response.json();
          }
        })
        .then((matches: Match[]) => {
          if (matches && matches.length > 0) {
            setValue(
              "matches",
              mapMatches(matches, supplierId, debouncedSupplierProductCode)
            );
          } else {
            setValue("matches", []);
          }
        });
    }
  }, [debouncedSupplierProductCode, setValue, supplierId]);

  const isSubmitting = isUploadingImages || isLoading;

  return (
    <Form
      heading={t("products.add")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <ProductForm
          fieldsErrors={fieldsErrors}
          loadingMatches={loadingMatches}
        />
      </FormProvider>
    </Form>
  );
};
