import { ListColumn, RowBase } from "./types";
import { useMemo } from "react";

/**
 * For inner use
 */
export const useListGrid = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  displayCheckbox,
  displayExpandableRows,
}: {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  displayCheckbox?: boolean;
  displayExpandableRows?: boolean;
}) => {
  const gridTemplateColumns = useMemo(() => {
    const value = columns.map((column) =>
      column.width ? column.width : "auto"
    );

    if (displayCheckbox) {
      value.unshift("48px");
    }

    if (displayExpandableRows) {
      value.unshift("48px");
    }

    return value.join(" ");
  }, [columns, displayCheckbox, displayExpandableRows]);

  return {
    display: "grid",
    gridTemplateColumns,
  };
};
