import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "../../store";
import { selectShopList } from "./selectors";
import { selectSelfId } from "../auth/selectors";
import { useGetAssignmentsQuery, useGetCartQuery } from "./api";
import { useMemo } from "react";

export const useShopList = () => {
  const selfId = useSelector(selectSelfId);
  const { inquiryId, departmentId } = useSelector(selectShopList);

  const { data: assignments } = useGetAssignmentsQuery(selfId ?? skipToken);

  return useMemo(() => {
    if (!assignments) {
      return null;
    }

    const inquiries = assignments.map(({ id, name, dueDate }) => ({
      id,
      name,
      dueDate,
    }));
    const departments =
      assignments
        .find(({ id }) => id === inquiryId)
        ?.departments.map(({ id, name, budget }) => ({ id, name, budget })) ||
      [];

    const inquiry = inquiries.find(({ id }) => id === inquiryId);
    const department = departments.find(({ id }) => id === departmentId);

    return {
      inquiry,
      department,
    };
  }, [assignments, departmentId, inquiryId]);
};

export const useCurrentAssignmentId = () => {
  const selfId = useSelector(selectSelfId);
  const { inquiryId, departmentId } = useSelector(selectShopList);

  const { data: assignments } = useGetAssignmentsQuery(selfId ?? skipToken);

  return useMemo(() => {
    return (
      assignments
        ?.find(({ id }) => id === inquiryId)
        ?.departments.find(({ id }) => id === departmentId)?.assignmentId ?? 0
    );
  }, [assignments, departmentId, inquiryId]);
};

export const useAssignmentBudget = (): number | undefined => {
  const selfId = useSelector(selectSelfId);
  const { inquiryId, departmentId } = useSelector(selectShopList);

  const { data: assignments } = useGetAssignmentsQuery(selfId ?? skipToken);

  return useMemo(() => {
    return assignments
      ?.find(({ id }) => id === inquiryId)
      ?.departments.find(({ id }) => id === departmentId)?.budget;
  }, [assignments, departmentId, inquiryId]);
};

export const useTotalAmount = () => {
  const assignmentId = useCurrentAssignmentId();

  const { data: items, isLoading } = useGetCartQuery(assignmentId ?? skipToken);

  const result = useMemo(() => {
    return items?.reduce(
      (totalAmount, { product: { retailPrice, retailCurrency }, quantity }) => {
        if (!retailPrice || !retailCurrency) {
          return totalAmount;
        }

        // TODO change it after prices normalize
        return totalAmount + Math.round(retailPrice) * quantity;
      },
      0
    );
  }, [items]);

  return { result, isLoading };
};

export const useInCart = (productId?: number) => {
  const assignmentId = useCurrentAssignmentId();

  const { data: items } = useGetCartQuery(assignmentId ?? skipToken);

  return useMemo(() => {
    if (!productId) {
      return undefined;
    }

    return items?.find((cartItem) => cartItem.product.id === productId)
      ?.quantity;
  }, [items, productId]);
};

export const useIsCartDisabled = () => {
  const selfId = useSelector(selectSelfId);

  const { data: assignments } = useGetAssignmentsQuery(selfId ?? skipToken);

  if (!assignments) {
    return true;
  }

  return assignments?.length === 0;
};
