import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useAddDepartmentMutation } from "../../../slices/departments/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { DepartmentForm, departmentFormSchemaCreator } from "./DepartmentForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";

type AddDepartmentForm = {
  name: string;
  public: boolean;
  relevancesCategory: {
    relevance: number;
    categoryId: number;
  }[];
  relevancesTag: {
    relevance: number;
    tagName: string;
  }[];
};

export const Add: React.FC = () => {
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(departmentFormSchemaCreator);
  const formMethods = useForm<AddDepartmentForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addDepartment, { isLoading }] = useAddDepartmentMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("departments.errors.add")
  );

  const { closeDrawer } = useTableUrlTools("/departments");
  const onSubmit = useCallback(
    (form: AddDepartmentForm) => {
      return addDepartment({
        ...form,
        active: true,
      })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("departments.addSuccess"), `/departments/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [addDepartment, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <Form
      heading={t("departments.addDepartment")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <DepartmentForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
