import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import { Box, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import { Close } from "@mui/icons-material";
import { customColors } from "../theme/mui/theme";

const autoHideDuration = 6000;

type CloseNotificationProps = {
  closeHandler: () => void;
  children?: React.ReactNode;
};

const CloseNotification = ({
  closeHandler,
  children,
}: CloseNotificationProps) => {
  const closeButton = (
    <IconButton onClick={closeHandler} sx={{ color: customColors.white }}>
      <Close />
    </IconButton>
  );

  return children ? (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      {children}
      {closeButton}
    </Box>
  ) : (
    closeButton
  );
};

export const useFeedError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return useCallback(
    (message: string) =>
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration,
        persist: false,
        action: (key) => (
          <CloseNotification closeHandler={() => closeSnackbar(key)} />
        ),
      }),
    [closeSnackbar, enqueueSnackbar]
  );
};

export const useFeedSuccess = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return useCallback(
    (message: string, link?: string) =>
      enqueueSnackbar(message, {
        variant: "success",
        persist: false,
        autoHideDuration,
        action: (key) => (
          <CloseNotification closeHandler={() => closeSnackbar(key)}>
            {link ? (
              <Button
                variant="outlined"
                component={Link}
                to={link}
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  borderColor: "rgba(255, 255, 255, 0.7)",
                  ":hover": {
                    color: customColors.white,
                    borderColor: customColors.white,
                  },
                }}
              >
                {i18n.t("open")}
              </Button>
            ) : null}
          </CloseNotification>
        ),
      }),
    [closeSnackbar, enqueueSnackbar]
  );
};
