import React, { useCallback, useEffect, useState } from "react";
import { Dialog as ModalComponent } from "../../../../components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, TextFieldProps } from "@mui/material";
import { SelectList } from "./SelectList";
import { useDispatch } from "../../../../store";
import { setShopList } from "../../../../slices/shop/actions";
import { useShopList } from "../../../../slices/shop/hooks";

export const ChangeList = () => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const [value, setValue] = useState("");
  const list = useShopList();
  useEffect(() => {
    const assignmentId = list?.inquiry?.id;
    const departmentId = list?.department?.id;

    if (assignmentId && departmentId) {
      setValue(`${assignmentId}-${departmentId}`);
    }
  }, [list?.department?.id, list?.inquiry?.id]);

  const listChangeHandler = useCallback<
    NonNullable<TextFieldProps["onChange"]>
  >((event) => {
    setValue(event.target.value);
  }, []);

  const submitHandler = useCallback(() => {
    const [inquiryId, departmentId] = value
      .split("-")
      .map((id) => parseInt(id));

    dispatch(setShopList({ inquiryId, departmentId }));
    setOpen(false);
  }, [dispatch, value]);

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginLeft: "auto", alignSelf: "flex-end" }}
        onClick={openHandler}
      >
        {t("shopCatalog.currentList.changeList")}
      </Button>
      <ModalComponent
        open={open}
        onClose={closeHandler}
        title={t("shopCatalog.currentList.changeTitle")}
        about={t("shopCatalog.currentList.changeAbout")}
      >
        <SelectList value={value} onChange={listChangeHandler} />
        <Button
          onClick={submitHandler}
          variant="contained"
          sx={{
            width: "100%",
            marginTop: "52px",
          }}
        >
          {t("shopCatalog.currentList.changeSubmit")}
        </Button>
      </ModalComponent>
    </>
  );
};
