import React from "react";
import { Box } from "@mui/material";
import { Dotted } from "../spinners/Dotted";

/**
 * SpinnerBase is meant to be used inside ContainerBase and PaperBase.
 * In case of using PaperPage it's better to use PaperPageSpinner.
 */
export const SpinnerBase = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Dotted />
    </Box>
  );
};
