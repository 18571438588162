import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { useGetInventoryEntryWithChangesHistoryQuery } from "../../../../slices/inventory/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import { usePermissions } from "../../../../utils/usePermissions";
import { EntityDetailsTable } from "../../../../components/EntityDetailsTable";
import { Changes } from "./Changes";

export const Details = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { id, closeDrawer } = useTableUrlTools("/inventory");

  const {
    data: inventoryEntry,
    isLoading: isInventoryEntryLoading,
    isError: inventoryEntryLoadingError,
  } = useGetInventoryEntryWithChangesHistoryQuery(id ? { id } : skipToken);

  useEffect(() => {
    if (inventoryEntryLoadingError) {
      closeDrawer();
    }
  }, [inventoryEntryLoadingError, closeDrawer]);

  if (isInventoryEntryLoading) {
    return <Spinner />;
  }

  if (!inventoryEntry) {
    return null;
  }

  console.log(inventoryEntry);

  return (
    <>
      <DisplayContainer
        heading={`${inventoryEntry.supplierName} ${inventoryEntry.supplierProductCode}`}
        editHref={`/inventory/${id}/edit`}
        editLabel={t("inventory.editInventory")}
        editingDisabled={!can("editInventory")}
      >
        <EntityDetailsTable
          entity={inventoryEntry}
          excludeFields={["supplierId", "productId", "changes", "countryId"]}
        />
      </DisplayContainer>
      <Changes changes={inventoryEntry.changes} />
    </>
  );
};
