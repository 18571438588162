import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { Remove } from "./Remove";
import { Add } from "./Add";
import { useIsCartDisabled } from "../../../../../slices/shop/hooks";

type ControlsProps = {
  id: number;
  quantityInCart?: number | undefined;
};

export const Controls = ({ id, quantityInCart }: ControlsProps) => {
  const isCartDisabled = useIsCartDisabled();

  const control = useMemo(() => {
    if (isCartDisabled) {
      return null;
    }

    if (quantityInCart) {
      return <Remove id={id} quantityInCart={quantityInCart} />;
    }

    return <Add id={id} />;
  }, [id, isCartDisabled, quantityInCart]);

  return (
    <Box
      id={`product-${id}-controls`}
      sx={{
        width: "100%",
        height: "38px",
        marginTop: "8px",
        display: "flex",
      }}
    >
      {control}
    </Box>
  );
};
