import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InquiryFlattened } from "../../../slices/inquiries/types";
import { capitalize } from "../../../utils/capitalize";
import { useDisplayPrice } from "../../../utils/useDisplayPrice";

type InquiryCODataProps = {
  data: Partial<InquiryFlattened>;
};

export const InquiryCOData: React.FC<InquiryCODataProps> = ({ data }) => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      sx={{ alignSelf: "left", marginBottom: 1 }}
    >
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiryDetails.details.shippingCost")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {displayPrice(data.shippingCost) || t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiryDetails.details.shippingCostCurrency")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.shippingCostCurrency?.code || t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.paymentTerms")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {capitalize(data.paymentTerms || t("na", { ns: "common" }))}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.deliveryTerms")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {capitalize(data.deliveryTerms || t("na", { ns: "common" }))}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.leadTime")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.leadTime || t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.validityOffer")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.validityOffer || t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 6">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.shippingCostMessage")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.shippingCostMessage || t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.discountType")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {capitalize(data.discountType || t("na", { ns: "common" }))}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.discountValue")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.discountValue
            ? data.discountType === "rate"
              ? `${data.discountValue}%`
              : `${displayPrice(data.discountValue)}`
            : t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.discountCurrency")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {data.discountCurrency?.code || t("na", { ns: "common" })}
        </Typography>
      </Grid>
    </Grid>
  );
};
