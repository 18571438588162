import React from "react";
import { Box, Link as MaterialLink, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Item } from "../../../slices/items/types";
import { useTranslation } from "react-i18next";
import { ApprovalOutlined } from "@mui/icons-material";

type ProductLinkProps = Pick<Item, "product"> & {
  inquiryId: number;
  detailId: number;
  tableName: string;
};

export const ProductLink = ({
  product,
  inquiryId,
  detailId,
  tableName,
}: ProductLinkProps) => {
  const { t } = useTranslation("pages");

  if (!product) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {product.commodityCode?.requireApproval &&
        !product.replacementCommodityCode && (
          <Tooltip
            title={<>{t("commodityCodeRequiresApproval", { ns: "common" })}</>}
          >
            <ApprovalOutlined
              fontSize="small"
              color="warning"
              sx={{ marginRight: 0.5 }}
            />
          </Tooltip>
        )}
      <MaterialLink
        component={Link}
        to={`/inquiries/${inquiryId}/details/${detailId}/items/${tableName}/products/${product.id}`}
      >
        {product.name ?? t("na", { ns: "common" })}
      </MaterialLink>
    </Box>
  );
};
