import React from "react";
import { Helmet } from "react-helmet";

export const Ga4 = () => {
  const token = process.env.REACT_APP_GA4;

  if (typeof token !== "string" || token.length === 0) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA4}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${process.env.REACT_APP_GA4}');`}
      </script>
    </Helmet>
  );
};
