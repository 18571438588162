import React, { useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Edit } from "../../astraIcons/Edit";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../../store";
import { setChangePasswordDrawer } from "../../../slices/ui/slice";

type ChangePasswordProps = {
  onClick?: () => void;
};

export const ChangePassword = ({ onClick }: ChangePasswordProps) => {
  const { t } = useTranslation("components");
  const dispatch = useDispatch();

  const clickHandler = useCallback(() => {
    dispatch(setChangePasswordDrawer(true));

    if (onClick) {
      onClick();
    }
  }, [dispatch, onClick]);

  return (
    <MenuItem onClick={clickHandler}>
      <ListItemIcon>
        <Edit />
      </ListItemIcon>
      <ListItemText>{t("header.changePassword")}</ListItemText>
    </MenuItem>
  );
};
