import React, { useCallback, useState } from "react";
import { Checkbox } from "@mui/material";
import { ListColumn, RowBase } from "../../types";
import { Cell } from "../Cell";
import { ExpandButton } from "./ExpandButton";
import { SubRow } from "./SubRow";

type RowProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = {
  first?: boolean;
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  row: Row;
  selected?: boolean | undefined | null;
  onSelectedChange?: (id: number) => void;
  subRowsKey?: SubRowsKey;
};

export const Row = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  first,
  columns,
  row,
  selected,
  onSelectedChange,
  subRowsKey,
}: RowProps<Row, SubRow, SubRowsKey>) => {
  const [expanded, setExpanded] = useState(false);

  const expandHandler = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const checkboxChangeHandler = useCallback(() => {
    if (onSelectedChange) {
      onSelectedChange(row.id);
    }
  }, [onSelectedChange, row.id]);

  const displayCheckboxCell = typeof selected === "boolean";

  // @ts-ignore
  const subRows: SubRow[] | undefined = subRowsKey && row[subRowsKey];

  return (
    <>
      {subRowsKey && (
        <Cell first={first}>
          {subRows && subRows.length > 0 && (
            <ExpandButton expanded={expanded} onClick={expandHandler} />
          )}
        </Cell>
      )}
      {displayCheckboxCell && (
        <Cell
          first={first}
          sx={{
            justifyContent: "center",
          }}
        >
          <Checkbox checked={selected} onChange={checkboxChangeHandler} />
        </Cell>
      )}
      {columns.map((column) => {
        // @ts-ignore
        const cell = row[column.field];

        return (
          <Cell
            key={`${row.id}-${column.field}`}
            first={first}
            sx={column.boxSx}
          >
            {column.renderCell
              ? column.renderCell(row, {
                  expanded,
                })
              : cell}
          </Cell>
        );
      })}
      {subRows &&
        subRows.length > 0 &&
        expanded &&
        subRows.map((subRow) => (
          <SubRow
            key={subRow.id}
            columns={columns}
            row={row}
            subRow={subRow}
            displayCheckboxCell={displayCheckboxCell}
          />
        ))}
    </>
  );
};
