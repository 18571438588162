import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { Comment, CommentableType } from "../../../slices/comments/types";
import {
  useAddCommentMutation,
  useGetCommentsQuery,
} from "../../../slices/comments/api";
import { PaperPageError, PaperPageSpinner } from "../../layout/PaperPage";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { OneComment } from "./OneComment";
import { usePermissions } from "../../../utils/usePermissions";
import { CommentForm } from "./CommentForm";

type CommentDrawerProps = {
  commentableType: CommentableType;
  commentableId: number;
};

export const CommentDrawer: React.FC<CommentDrawerProps> = ({
  commentableType,
  commentableId,
}) => {
  const { t } = useTranslation("pages");
  const user = useSelector((state) => state.auth.user);
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const can = usePermissions();

  const [addComment] = useAddCommentMutation();
  const [countFrom, setCountFrom] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [changeScroll, setChangeScroll] = useState(true);
  const [comments, setComments] = useState<Comment[]>([]);

  const { isLoading, isFetching, data, error, refetch } = useGetCommentsQuery({
    commentableType,
    commentableId,
    countFrom,
  });

  const refGrid = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (data) {
      setComments((value) => [...data.data, ...value]);
    }
    if (refGrid.current) {
      setScrollHeight(refGrid.current.scrollHeight);
    }
  }, [data, setComments]);

  useEffect(() => {
    if (refGrid.current && changeScroll) {
      refGrid.current.scrollTop = refGrid.current.scrollHeight - scrollHeight;
    }
  }, [scrollHeight, comments, changeScroll]);

  const addValidComment = useCallback(
    (text) => {
      addComment({
        commentableType,
        commentableId,
        text,
        userId: user?.id || 0,
      })
        .unwrap()
        .then((result) => {
          feedSuccess(t("comments.successes.add"));
          setChangeScroll(true);
          const currentComment = user ? { ...result, user } : result;
          setComments((comments) => [...comments, currentComment]);
          setScrollHeight(0);
        })
        .catch((error) => {
          feedError(error?.data?.message?.text[0] || t("comments.errors.add"));
        });
    },
    [
      addComment,
      feedSuccess,
      feedError,
      t,
      commentableType,
      commentableId,
      user,
    ]
  );

  const onLoadMore = useCallback(() => {
    setCountFrom(comments.length);
    setChangeScroll(true);
  }, [setCountFrom, comments]);

  const onDelete = useCallback(
    (deletedId) => {
      setChangeScroll(false);
      setComments((comments) => comments.filter(({ id }) => deletedId !== id));
    },
    [setComments]
  );

  return (
    <StyledDrawer variant="persistent" open={true} anchor="right">
      <Typography variant="subtitle2" gutterBottom>
        {t("comments.title")}
      </Typography>
      <Divider />
      {error && (
        <PaperPageError
          refetch={refetch}
          disabled={isFetching}
          message={t("comments.errors.refetch")}
        />
      )}
      {!error && (isLoading || !data) && <PaperPageSpinner />}
      <Grid
        container
        ref={refGrid}
        sx={{
          height: "calc(100% - 170px)",
          overflow: "auto",
          alignItems: "flex-end",
        }}
      >
        <Grid container item>
          {data && data.restCount > 0 && (
            <Grid item sx={{ marginTop: "10px", width: "100%" }}>
              <Button fullWidth onClick={onLoadMore}>
                {t("comments.loadMore")}
              </Button>
            </Grid>
          )}
          {comments.map((comment: Comment) => (
            <OneComment
              key={comment.id}
              id={comment.id}
              user={comment.user}
              createdAt={comment.createdAt}
              text={comment.text}
              canDelete={can("deleteComment")}
              onDeleteComment={onDelete}
            />
          ))}
        </Grid>
      </Grid>
      <CommentForm
        addValidComment={addValidComment}
        isDivider={comments.length < 1}
      />
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)(() => ({
  width: "240px",
  "& .MuiDrawer-paper": {
    width: "240px",
    marginTop: 65,
    height: "calc(100% - 65px)",
    padding: 16,
  },
}));
