import React, { memo } from "react";
import {
  Button,
  ButtonGroup,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";

type Block = {
  label: string;
  percentage: number;
  onClick?: () => void;
  color?: string;
};

type StatisticsProps = {
  label?: string;
  blocks: Block[];
  stackProps?: StackProps;
};

export const Statistics = memo(
  ({ label, blocks, stackProps }: StatisticsProps) => {
    return (
      <Stack {...stackProps}>
        {!!label && (
          <Typography variant="subtitle2" gutterBottom>
            {label}
          </Typography>
        )}
        <ButtonGroup size="small">
          {blocks.map(({ label, percentage, onClick, color }) => (
            <Button
              key={label}
              onClick={onClick}
              sx={{
                color: color ?? "text",
                fontSize: "11px",
                marginLeft: 0,
                marginRight: 0,
                "&:hover": {
                  cursor: onClick ? "pointer" : "initial",
                },
              }}
            >
              {label} ({percentage})
            </Button>
          ))}
        </ButtonGroup>
      </Stack>
    );
  }
);
