import React from "react";
import { Box, BoxProps } from "@mui/material";

/**
 * https://mui.com/material-ui/react-button/#file-upload
 */
export const InvisibleFileInput = ({
  sx,
  ...props
}: Omit<BoxProps<"input">, "component" | "type">) => {
  return (
    <Box
      component="input"
      type="file"
      sx={{
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        ...sx,
      }}
      {...props}
    />
  );
};
