import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import { WysiwygFieldProps } from "./WysiwygField";
import { useState } from "react";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

type UseConfiguredEditorProps = Pick<
  WysiwygFieldProps,
  "defaultValue" | "onChange" | "onFocus" | "onBlur"
>;

export const useConfiguredEditor = ({
  defaultValue,
  onChange,
  onFocus,
  onBlur,
}: UseConfiguredEditorProps) => {
  const [focused, setFocused] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Table.configure({
        resizable: false,
        lastColumnResizable: false,
      }),
      TableHeader,
      TableRow,
      CustomTableCell,
    ],
    content: defaultValue ?? "",
    editorProps: {
      attributes: {
        class: "wysiwyg-field",
      },
    },
    onFocus: () => {
      setFocused(true);

      if (onFocus) {
        onFocus();
      }
    },
    onBlur: () => {
      setFocused(false);

      if (onBlur) {
        onBlur();
      }
    },
  });

  // todo: this needs to be removed and used via onUpdate event in useEditor hook
  //  but tiptap is currently in beta and it's twitch that way so far
  if (onChange && editor) {
    onChange(editor.getHTML());
  }

  return { editor, focused };
};
