import React from "react";
import { GetProductResponseDto } from "../../../../slices/products/types";
import { Box, Typography } from "@mui/material";

type InventoryProps = {
  inventoryEntries: GetProductResponseDto["inventoryEntries"];
};

export const Inventory = ({ inventoryEntries }: InventoryProps) => {
  return (
    <Box
      sx={{
        marginTop: 0.5,
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      {inventoryEntries.map((inventoryEntry) => (
        <Box key={inventoryEntry.id} sx={{ display: "flex" }}>
          <Typography
            variant="body2"
            component="span"
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: "150px",
            }}
          >
            {inventoryEntry.countryName}
          </Typography>
          <Typography variant="body2" component="span">
            {inventoryEntry.quantity}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
