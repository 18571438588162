import React, { memo } from "react";
import { ContextMenu } from "../../../components/ContextMenu";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { usePermissions } from "../../../utils/usePermissions";
import { baseUrl } from "../NewItems";

type ActionsProps = {
  id: number;
};

export const Actions = memo(({ id }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const can = usePermissions();

  return (
    <ContextMenu
      buttons={[
        {
          label: t("actions.edit", { ns: "common" }),
          handler: () => {
            history.push(`${baseUrl}/${id}/edit`);
          },
          disabled: !can("editItem"),
        },
      ]}
    />
  );
});
