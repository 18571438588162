export const getObjectFromLocalStorage = <Value extends object>(
  key: string
): Value | null => {
  const raw = localStorage.getItem(key);

  if (!raw || raw === "null") {
    return null;
  }

  return JSON.parse(raw);
};

export const saveObjectToLocalStorage = (key: string, value: object | null) => {
  localStorage.setItem(key, JSON.stringify(value));
};
