import { useLocalStorage } from "usehooks-ts";
import { useCallback, useMemo, useState } from "react";
import { useSearchDebounce } from "./useSearchDebounce";
import { Noop } from "../types";
import { GridSortModel } from "@mui/x-data-grid";
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT_MODEL } from "../consts";
import { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";

type UseContentToolsOptions = {
  defaultPageSize?: number;
  resets?: Noop;
};

export const useContentTools = (
  localStorageLabel: string,
  options: UseContentToolsOptions = {}
) => {
  const { defaultPageSize = 4, resets } = options;
  const defaultPage = 1;

  const [page, setPage] = useLocalStorage(
    `${localStorageLabel}.page`,
    defaultPage
  );
  const pageChangeHandler = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const [pageSize, setPageSize] = useLocalStorage(
    `${localStorageLabel}.pageSize`,
    defaultPageSize
  );
  const pageSizeChangeHandler = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(defaultPage);
    },
    [setPageSize, setPage]
  );

  const [search, setSearch] = useLocalStorage(
    `${localStorageLabel}.search`,
    ""
  );
  const searchChangeHandler = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );
  const debouncedSearchCallback = useCallback(() => {
    setPage(defaultPage);
  }, [setPage]);
  const debouncedSearch = useSearchDebounce(search, debouncedSearchCallback);

  const resetHandler = useCallback(() => {
    setSearch("");
    setPage(defaultPage);

    if (resets) {
      resets();
    }
  }, [resets, setSearch, setPage]);

  const resetPageHandler = useCallback(() => {
    setPage(defaultPage);
  }, [setPage]);

  return {
    page,
    setPage,
    pageChangeHandler,
    pageSize,
    setPageSize,
    pageSizeChangeHandler,
    search,
    debouncedSearch,
    setSearch,
    searchChangeHandler,
    resetHandler,
    resetPageHandler,
  };
};

type UseTableToolsOptions = UseContentToolsOptions & {
  defaultSortModel?: GridSortModel;
};

export const useTableTools = (
  localStorageLabel: string,
  options: UseTableToolsOptions = {}
) => {
  const {
    resets,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    defaultSortModel = DEFAULT_SORT_MODEL,
  } = options;
  const defaultPage = 0;

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    `table-${localStorageLabel}.sortModel`,
    defaultSortModel
  );
  const sortModelChangeHandler = useCallback(
    (model: GridSortModel) => setSortModel(model),
    [setSortModel]
  );

  const [page, setPage] = useLocalStorage(
    `table-${localStorageLabel}.page`,
    defaultPage
  );
  const pageChangeHandler = useCallback(
    (newPage: number) => setPage(newPage),
    [setPage]
  );

  const [pageSize, setPageSize] = useLocalStorage(
    `table-${localStorageLabel}.pageSize`,
    defaultPageSize
  );
  const pageSizeChangeHandler = useCallback(
    (newPageSize: number) => setPageSize(newPageSize),
    [setPageSize]
  );

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const selectionModelChangeHandler = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectionModel(newSelectionModel);
    },
    [setSelectionModel]
  );

  const selectedIds = useMemo(
    () =>
      selectionModel.map((id) => (typeof id === "string" ? parseInt(id) : id)),
    [selectionModel]
  );

  const [search, setSearch] = useLocalStorage(
    `table-${localStorageLabel}.search`,
    ""
  );
  const searchChangeHandler = useCallback(
    (event) => setSearch(event.target.value),
    [setSearch]
  );
  const debouncedSearchCallback = useCallback(() => {
    setPage(defaultPage);
  }, [setPage]);
  const debouncedSearch = useSearchDebounce(search, debouncedSearchCallback);

  const resetHandler = useCallback(() => {
    setSearch("");
    setPage(defaultPage);
    setPageSize(defaultPageSize);
    setSortModel(defaultSortModel);

    if (resets) {
      resets();
    }
  }, [
    defaultPageSize,
    defaultSortModel,
    resets,
    setPage,
    setPageSize,
    setSearch,
    setSortModel,
  ]);

  return {
    sortModel: sortModel.length > 0 ? sortModel : defaultSortModel,
    sortModelChangeHandler,
    page,
    setPage,
    pageChangeHandler,
    pageSize,
    setPageSize,
    pageSizeChangeHandler,
    selectionModel,
    selectedIds,
    selectionModelChangeHandler,
    search,
    debouncedSearch,
    setSearch,
    searchChangeHandler,
    resetHandler,
  };
};
