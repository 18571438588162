import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { PaperMessageError } from "./layout/PaperPage";
import { FILE_TYPES_CO } from "../consts";
import { useTranslation } from "react-i18next";
import { styled, Typography } from "@mui/material";

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#1E90FF",
};

const acceptStyle = {
  borderColor: "#00BFFF",
};

const rejectStyle = {
  borderColor: "#e53935",
};

const validator = (file: File) => {
  if (!file?.type || !FILE_TYPES_CO.includes(file.type)) {
    return "wrongFileType";
  }
  if (file.size / 1024 / 1024 > 10) {
    return "wrongFileSize";
  }
  return null;
};

export const StyledDropzone: React.FC<{
  onDrop: (file: File) => void;
  accept?: string | string[];
}> = ({ onDrop, accept }) => {
  const { t } = useTranslation();

  const acceptString =
    (Array.isArray(accept) ? accept.join(",") : accept) || "";

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: (files) => {
        const file = files[0];
        const errMes = validator(file);

        if (!errMes) {
          setErrorMessage(undefined);
          setUpload(files[0].name);
          onDrop(file);
        } else {
          setErrorMessage(t(errMes, { ns: "validationErrors" }));
        }
      },
    });

  const [errorMessage, setErrorMessage] = useState<string>();
  const [upload, setUpload] = useState<string>("");

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} accept={acceptString} />
        <p>{t("dragAndDrop", { ns: "common" })}</p>
        {!errorMessage && upload && <SelectedFile>{upload}</SelectedFile>}
      </div>
      {errorMessage && <PaperMessageError message={errorMessage} />}
    </>
  );
};

const SelectedFile = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
  textAlign: "left",
}));
