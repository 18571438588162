import React, { useCallback } from "react";
import { Chip, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useChangeItemInquiryStatusMutation } from "../../../../slices/items/api";
import {
  ItemInquiryStatus,
  itemInquiryStatuses,
} from "../../../../slices/items/types";
import { useFeedError, useFeedSuccess } from "../../../../utils/feedHooks";
import { capitalize } from "../../../../utils/capitalize";
import { ITEM_INQUIRY_STATUS_COLORS } from "../../../../consts";
import {
  canSetStatusManually,
  inquiryStatusesPaths,
} from "../../../../slices/items/utils";

type SelectItemInquiryStatusCellProps = {
  gridCellProps: GridRenderCellParams;
  inquiryId: number;
};

export const SelectItemInquiryStatusCell: React.FC<SelectItemInquiryStatusCellProps> =
  ({ gridCellProps, inquiryId }) => {
    const { t } = useTranslation("pages");
    const feedSuccess = useFeedSuccess();
    const feedError = useFeedError();
    const [changeInquiryStatus] = useChangeItemInquiryStatusMutation();

    const changeHandler = useCallback(
      (event: SelectChangeEvent<ItemInquiryStatus>) => {
        const status = event.target.value as ItemInquiryStatus;

        changeInquiryStatus({
          id: gridCellProps.row.id as number,
          status,
          inquiryId,
        })
          .unwrap()
          .then(() => {
            feedSuccess(t("items.successes.changeItemInquiryStatus"));
          })
          .catch((error: { data?: { message?: string } }) => {
            feedError(
              error?.data?.message ?? t("items.errors.changeItemInquiryStatus")
            );
          });
      },
      [
        changeInquiryStatus,
        feedError,
        feedSuccess,
        gridCellProps.row.id,
        inquiryId,
        t,
      ]
    );

    return (
      <Select
        id={`select-cell-item-inquiry-status-${gridCellProps.row.id}`}
        value={gridCellProps.value}
        onChange={changeHandler}
        size="small"
        variant="standard"
        autoWidth={false}
        fullWidth={true}
      >
        {itemInquiryStatuses.map((status) => (
          <MenuItem
            key={status}
            value={status}
            disabled={
              !canSetStatusManually(
                inquiryStatusesPaths,
                gridCellProps.value,
                status
              )
            }
          >
            <Chip
              label={<b>{capitalize(status)}</b>}
              size="small"
              variant={"filled"}
              sx={{
                backgroundColor: ITEM_INQUIRY_STATUS_COLORS[status],
                color: "white",
                "&:hover": { cursor: "pointer" },
              }}
            />
          </MenuItem>
        ))}
      </Select>
    );
  };
