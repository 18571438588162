import React, { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AddItemRequestDto,
  EditItemRequestDto,
  ItemDto,
} from "../../../slices/items/types";
import { FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { createYupSchema } from "../../../utils/validation";
import { SelectSupplier } from "../../../components/prefilledSelectors/SelectSupplier";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { ControlledNumericField } from "../../../components/controlledFields/ControlledNumericField";
import { FreeAutocompleteSupplierCode } from "../../../components/prefilledSelectors/FreeAutocompleteSupplierCode";
import { SUPPLIER_PRODUCT_CODE_REGEXP } from "../../../consts";
import { FreeAutocompleteItemName } from "../../../components/prefilledSelectors/FreeAutocompleteItemName";
import { ControlledTextField } from "../../../components/controlledFields/ControlledTextField";

export const itemFormSchemaCreator = createYupSchema((t) =>
  yup.object().shape(
    {
      id: yup.number().positive().optional(),
      departmentInquiryId: yup
        .number()
        .positive(t("required"))
        .required(t("required")),
      name: yup
        .string()
        .max(256, t("tooLong", { maxLength: 256 }))
        .required(t("required")),
      supplierProductCode: yup
        .string()
        .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
          excludeEmptyString: true,
          message: t("supplierProductCode"),
        })
        .max(256, t("tooLong", { maxLength: 256 }))
        .when("supplierId", {
          is: (value: number | null) => typeof value === "number" && value > 0,
          then: (schema) => schema.required(t("required")),
          otherwise: (schema) => schema.nullable().optional(),
        }),

      quantity: yup
        .number()
        .typeError(t("number"))
        .min(1, t("numMustBeGreater", { minNum: 0 }))
        .required(t("required")),
      supplierId: yup
        .number()
        .typeError(t("number"))
        .when("supplierProductCode", {
          is: (value: string | null) =>
            typeof value === "string" && value.length > 0,
          then: (schema) => schema.required(t("required")),
          otherwise: (schema) => schema.nullable().optional(),
        }),
      customerLineNumber: yup
        .number()
        .typeError(t("customId"))
        .positive(t("customId"))
        .nullable()
        .transform((_, val) => {
          if (!val) {
            return null;
          }
          return val === Number(val).toString() ? Number(val) : val;
        }),
    },
    [["supplierProductCode", "supplierId"]]
  )
);

type ItemFormProps = {
  departmentInquiryId: number;
  itemData?: ItemDto;
  fieldsErrors?: FieldsErrorState;
};

/**
 * Needs to be inside <FormProvider/>
 **/
export const ItemForm: React.FC<ItemFormProps> = ({
  itemData,
  fieldsErrors,
  departmentInquiryId,
}) => {
  const { t } = useTranslation("pages");
  const { register, control, setValue } = useFormContext<
    AddItemRequestDto | EditItemRequestDto
  >();

  const watchSupplierIdSelect = useWatch({
    control,
    defaultValue: itemData?.supplierId,
    name: "supplierId",
  });
  const setName = useCallback(
    (name: string) => {
      setValue("name", name);
    },
    [setValue]
  );
  const setSupplier = useCallback(
    (id: number) => {
      setValue("supplierId", id);
    },
    [setValue]
  );
  const setSPC = useCallback(
    (spc: string) => {
      setValue("supplierProductCode", spc);
    },
    [setValue]
  );

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {itemData && (
        <input
          type="hidden"
          defaultValue={itemData.id}
          {...register("id", { required: true, valueAsNumber: true })}
        />
      )}
      <input
        type="hidden"
        defaultValue={departmentInquiryId}
        {...register("departmentInquiryId", {
          required: true,
          valueAsNumber: true,
        })}
      />
      <Grid item gridColumn="span 5">
        <ControlledCustomField
          control={control}
          name="supplierId"
          fieldsErrors={fieldsErrors}
          defaultValue={itemData && itemData.supplierId}
          render={(errorMessage, { field }) => (
            <SelectSupplier
              label={t("items.fields.supplier")}
              error={!!errorMessage}
              helperText={errorMessage}
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 7">
        <ControlledCustomField
          control={control}
          name="supplierProductCode"
          fieldsErrors={fieldsErrors}
          defaultValue={itemData && itemData.supplierProductCode}
          render={(errorMessage, { field: { value, onChange } }) => {
            return (
              <FreeAutocompleteSupplierCode
                id="item"
                onChange={onChange}
                setName={setName}
                value={(value as string) ?? ""}
                supplierId={watchSupplierIdSelect}
                error={!!errorMessage}
                helperText={errorMessage}
                label={t("items.fields.supplierProductCode")}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="name"
          fieldsErrors={fieldsErrors}
          defaultValue={itemData && itemData.name}
          render={(errorMessage, { field: { value, onChange } }) => {
            return (
              <FreeAutocompleteItemName
                id="item"
                onChange={onChange}
                setSupplier={setSupplier}
                setSupplierProductCode={setSPC}
                value={(value as string) ?? ""}
                error={!!errorMessage}
                helperText={errorMessage}
                label={`${t("items.fields.name")} *`}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 5">
        <ControlledNumericField
          control={control}
          name="quantity"
          label={`${t("items.fields.quantity")} *`}
          fieldsErrors={fieldsErrors}
          defaultValue={(itemData && itemData.quantity) || 1}
          positiveOnly
          fullWidth
        />
      </Grid>
      <Grid item gridColumn="span 7">
        <ControlledTextField
          control={control}
          name="customerLineNumber"
          label={t("items.fields.customerLineNumber")}
          defaultValue={itemData && itemData.customerLineNumber}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
    </Grid>
  );
};
