import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddSchoolRequestDto,
  EditSchoolRequestDto,
  GetSchoolsResponseDto,
  School,
} from "./types";

export const schoolsApi = createApi({
  reducerPath: "schoolsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/schools"),
    prepareHeaders,
  }),
  tagTypes: ["School"],
  endpoints: (builder) => ({
    getSchools: builder.query<GetSchoolsResponseDto, void>({
      query: () => "",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "School" as const,
                id,
              })),
              { type: "School", id: "list" },
            ]
          : [{ type: "School", id: "list" }],
    }),
    getSchool: builder.query<School, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "School", id }],
    }),
    addSchool: builder.mutation<School, AddSchoolRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [{ type: "School", id: "list" }],
    }),
    editSchool: builder.mutation<School, EditSchoolRequestDto>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (result, error, { id }) => [
        { type: "School", id: "list" },
        { type: "School", id },
      ],
    }),
    changeSchoolActiveStatus: builder.mutation<
      School,
      { id: number; active: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}/changeActiveStatus`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "School", id: "list" },
        { type: "School", id },
      ],
    }),
    deleteSchool: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "School", id: "list" },
        { type: "School", id },
      ],
    }),
  }),
});

export const {
  useGetSchoolsQuery,
  useGetSchoolQuery,
  useAddSchoolMutation,
  useEditSchoolMutation,
  useDeleteSchoolMutation,
  useChangeSchoolActiveStatusMutation,
} = schoolsApi;
