import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { ControlledFieldProps } from "./types";
import { fieldError } from "../../utils/formHelpers";
import {
  LegacyNumericField,
  LegacyNumericFieldProps,
} from "../fields/NumericField";

type ControlledNumericFieldProps<TFieldValues> =
  ControlledFieldProps<TFieldValues> &
    Omit<
      LegacyNumericFieldProps,
      "size" | "label" | "error" | "helperText" | "value" | "onChange" | "name"
    >;

export const ControlledNumericField = <TFieldValues extends FieldValues>({
  control,
  defaultValue,
  name,
  fieldsErrors,
  label,
  ...props
}: ControlledNumericFieldProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, name },
        fieldState: { invalid, error },
      }) => {
        const [serverInvalid, serverError] = fieldError(name, fieldsErrors);
        return (
          <LegacyNumericField
            size="small"
            label={label ?? name}
            error={invalid || serverInvalid}
            helperText={error?.message || serverError}
            value={value}
            onChange={onChange}
            name={name}
            {...props}
          />
        );
      }}
    />
  );
};
