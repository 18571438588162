import React, { useCallback, useMemo, useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useGetInquiriesAutocompleteQuery } from "../../slices/inquiries/api";
import { useSearchDebounce } from "../../utils/useSearchDebounce";
import { useTranslation } from "react-i18next";

type SelectInquiryValue = {
  id: number;
  name: string;
  school: {
    id: number;
    name: string;
  };
};

type InquiryAutocompleteProps = AutocompleteProps<
  SelectInquiryValue,
  true,
  false,
  false
>;

type SelectInquiryProps = Pick<
  TextFieldProps,
  "label" | "error" | "helperText"
> &
  Omit<
    InquiryAutocompleteProps,
    | "value"
    | "onChange"
    | "multiple"
    | "options"
    | "noOptionsText"
    | "loading"
    | "inputValue"
    | "onInputChange"
    | "renderInput"
  > & {
    value?: number[];
    onChange?: (value: number[]) => void;
  };

export const SelectInquiry = ({
  label,
  error,
  helperText,
  value = [],
  onChange,
}: SelectInquiryProps) => {
  const { t } = useTranslation("components");

  const [search, setSearch] = useState("");
  const debouncedSearch = useSearchDebounce(search ?? "");

  const inputChangeHandler = useCallback<
    NonNullable<InquiryAutocompleteProps["onInputChange"]>
  >((event, value) => {
    if (event) {
      setSearch(value);
    }
  }, []);

  const { data: inquiries = [], isFetching: areInquiriesFetching } =
    useGetInquiriesAutocompleteQuery({
      search: debouncedSearch,
    });

  const calculatedValue = useMemo<SelectInquiryValue[]>(
    () => inquiries.filter((option) => value.includes(option.id)),
    [inquiries, value]
  );

  const changeHandler = useCallback<
    NonNullable<InquiryAutocompleteProps["onChange"]>
  >(
    (_event, newValue) => {
      if (!onChange) {
        return;
      }

      const newIdsList = newValue.map((option) => option.id);

      if (search.length > 0) {
        onChange([...value, ...newIdsList]);
        return;
      }

      onChange(newIdsList);
    },
    [onChange, search.length, value]
  );

  return (
    <Autocomplete
      multiple
      autoComplete
      options={inquiries}
      filterOptions={(option) => option}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.school.name} — ${option.name}`}
      noOptionsText={t("emptySelect", { ns: "common" })}
      loading={areInquiriesFetching}
      value={calculatedValue}
      onChange={changeHandler}
      inputValue={search}
      onInputChange={inputChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
