import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Tooltip,
} from "@mui/material";
import { ShortProductInformation } from "../../../components/ShortProductInformation";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { MatchOption } from "../../../types";
import { usePermissions } from "../../../utils/usePermissions";
import { ImageSliderFixedHeight } from "../../../components/ImageSliderFixedHeight";
import {
  useAssignProductMutation,
  useGetItemQuery,
} from "../../../slices/items/api";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { Product } from "../../../slices/products/types";
import { SupplierProductCode } from "../../../entities";
import { InStockIndicator } from "../../../components/InStockIndicator";

type ResultProps = {
  data: Pick<
    Product,
    "id" | "name" | "supplierPrice" | "retailPrice" | "retailCurrency" | "color"
  > & {
    supplierProductCode: SupplierProductCode;
    images: string[];
    commodityCodeRequiresApproval?: boolean;
    inStock: number;
  };
  match?: MatchOption;
};

export const Result = ({
  data: {
    id,
    name,
    images,
    retailPrice,
    retailCurrency,
    supplierPrice,
    supplierProductCode,
    commodityCodeRequiresApproval,
    color,
    inStock,
  },
  match = "A",
}: ResultProps) => {
  const { t } = useTranslation("pages");

  const can = usePermissions();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const { baseUrl, itemId } = useItemDetailsUrlTools();

  const { data: item } = useGetItemQuery(itemId);

  const [assignProduct] = useAssignProductMutation();

  const onSubmit = useCallback(() => {
    assignProduct({
      productId: id,
      inquiryId: item?.departmentInquiry.inquiry.id || 0,
      itemId,
      productQuantity: item?.quantity || 1,
      productColor: color || "",
      match,
    })
      .unwrap()
      .then(({ message, additional }) => {
        feedSuccess(
          message ?? t("itemDetails.successes.assign"),
          `${baseUrl}/product/${id}`
        );
        if (additional) {
          feedSuccess(additional);
        }
      })
      .catch(feedError);
  }, [
    assignProduct,
    id,
    item?.departmentInquiry.inquiry.id,
    item?.quantity,
    itemId,
    color,
    match,
    feedError,
    feedSuccess,
    t,
    baseUrl,
  ]);

  const assignParams = useMemo(() => {
    const params = new URLSearchParams();

    params.append("match", match);

    if (name) {
      params.append("name", name);
    }

    return params;
  }, [match, name]);

  const disableAssignPrice = !retailPrice || !supplierPrice;

  return (
    <Card variant="outlined" sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <CardContent sx={{ flexGrow: 1, flexShrink: 1 }}>
            <ShortProductInformation
              {...{
                name,
                supplierName: supplierProductCode.supplier.name,
                supplierProductCode: supplierProductCode.code,
                retailPrice,
                retailCurrency: retailCurrency?.code,
              }}
              commodityCodeRequiresApproval={commodityCodeRequiresApproval}
              disableAssignPrice={disableAssignPrice}
            />
          </CardContent>
          {images && images.length > 0 && (
            <Box
              sx={{
                marginTop: "16px",
                marginRight: "16px",
                width: "151px",
                height: "151px",
                marginLeft: "auto",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <ImageSliderFixedHeight
                images={images.map((url) => ({ src: url }))}
                height={151}
              />
            </Box>
          )}
        </Box>
        <CardActions
          sx={{
            padding: 2,
            justifyContent: "flex-end",
          }}
        >
          <InStockIndicator
            quantity={inStock}
            sx={{
              marginRight: "auto",
              marginLeft: "2px",
            }}
          />
          {disableAssignPrice && (
            <Button
              component={Link}
              to={`${baseUrl}/product/${id}/edit`}
              size="small"
              disabled={!can("editProduct")}
            >
              {t("actions.edit", { ns: "common" })}
            </Button>
          )}
          <Tooltip
            title={disableAssignPrice ? t("itemDetails.cantAssignPrice") : ""}
          >
            <Button
              onClick={onSubmit}
              size="small"
              disabled={!can("matchItem") || disableAssignPrice}
            >
              {t("itemDetails.quickAssign")}
            </Button>
          </Tooltip>
          <Tooltip
            title={disableAssignPrice ? t("itemDetails.cantAssignPrice") : ""}
          >
            <span>
              <Button
                component={Link}
                to={{
                  pathname: `${baseUrl}/assign/${id}`,
                  search: `?${assignParams.toString()}`,
                }}
                size="small"
                disabled={!can("matchItem") || disableAssignPrice}
              >
                {t("itemDetails.assign")}
              </Button>
            </span>
          </Tooltip>
          <Button
            component={Link}
            to={`${baseUrl}/product/${id}`}
            size="small"
            disabled={!can("getProduct")}
          >
            {t("itemDetails.viewDetails")}
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
