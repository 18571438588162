import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import { GetProductsResponseDto } from "../../../slices/products/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Actions } from "./Actions";
import { ShortProductInformation } from "../../../components/ShortProductInformation";
import { ImageSliderFixedHeight } from "../../../components/ImageSliderFixedHeight";
import { InStockIndicator } from "../../../components/InStockIndicator";

type ProductProps = {
  data: GetProductsResponseDto["data"][number];
};

export const Product = ({
  data: {
    id,
    supplierProductCode,
    images,
    name,
    retailPrice,
    retailCurrency,
    commodityCode,
    replacementCommodityCode,
    availability,
    inStock,
  },
}: ProductProps) => {
  const { t } = useTranslation("pages");

  const cardRef = useRef<HTMLDivElement>(null);
  const [sliderHeight, setSliderHeight] = useState<number | null>(null);
  useEffect(() => {
    const calculateSliderHeight = () => {
      setSliderHeight(cardRef.current?.clientWidth ?? null);
    };

    calculateSliderHeight();

    window.addEventListener("resize", calculateSliderHeight);
    return () => window.removeEventListener("resize", calculateSliderHeight);
  }, []);

  return (
    <Card
      ref={cardRef}
      variant="outlined"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {images.length > 0 && sliderHeight !== null && (
        <ImageSliderFixedHeight
          images={images.map((url) => ({
            src: url,
            alt: name ?? t("products.imgAlt", { supplierProductCode }),
          }))}
          height={sliderHeight}
        />
      )}
      <CardContent>
        <ShortProductInformation
          {...{
            name,
            supplierName: supplierProductCode.supplier.name,
            supplierProductCode: supplierProductCode.code,
            retailPrice,
            retailCurrency: retailCurrency?.code,
          }}
          commodityCodeRequiresApproval={
            commodityCode?.requireApproval && !replacementCommodityCode
          }
        />
      </CardContent>
      <CardActions sx={{ marginTop: "auto", marginLeft: 1 }}>
        <Button component={Link} to={`/products/${id}`} size="small">
          {t("products.viewMore")}
        </Button>
        <Actions id={id} availability={availability} />
        {inStock > 0 && (
          <InStockIndicator
            quantity={inStock}
            sx={{
              marginLeft: "auto !important",
              marginTop: "3px",
              marginRight: "8px",
            }}
          />
        )}
      </CardActions>
    </Card>
  );
};
