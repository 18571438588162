import { User } from "../entities";

export const getNormalizedName = (user: User | null): string => {
  const firstName = user?.firstName ?? "";
  const lastName = user?.lastName ?? "";

  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || "Name not found";
};
