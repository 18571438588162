import React, { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Actions } from "./Actions";
import { Link } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import { GetInventoryEntriesResponseDto } from "../../../slices/inventory/types";

export const useColumns = () => {
  const { t } = useTranslation("pages");

  return useMemo<GridColDef<GetInventoryEntriesResponseDto["data"][number]>[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.1,
      },
      {
        field: "supplierName",
        headerName: t("inventory.fields.supplier"),
        flex: 0.2,
      },
      {
        field: "supplierProductCode",
        headerName: t("inventory.fields.code"),
        flex: 0.2,
      },
      {
        field: "productName",
        headerName: t("inventory.fields.name"),
        flex: 0.5,
        renderCell: ({ row: { id, productName } }) => (
          <MaterialLink component={Link} to={`/inventory/${id}`}>
            {productName}
          </MaterialLink>
        ),
      },
      {
        field: "quantity",
        headerName: t("inventory.fields.quantity"),
        flex: 0.3,
      },
      {
        field: "countryName",
        headerName: t("inventory.fields.country"),
        flex: 0.3,
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.1,
        minWidth: 100,
        renderCell: ({ row: { id } }) => <Actions id={id} />,
      },
    ],
    [t]
  );
};
