import React from "react";
import { EntityDetailsGrid } from "./EntityDetailsGrid";
import { MainOrderFlattened } from "../slices/mainOrders/types";
import { useTranslation } from "react-i18next";
import { useDisplayDateTime } from "../utils/useDisplayDateTime";
import { displayFullName } from "../utils/displayFullName";

type MainOrderDetailsGridProps = {
  mainOrder: MainOrderFlattened;
};

export const MainOrderDetailsGrid = ({
  mainOrder,
}: MainOrderDetailsGridProps) => {
  const { t } = useTranslation("pages");
  const displayDateTime = useDisplayDateTime();
  const displayDate = useDisplayDateTime(false);

  return (
    <EntityDetailsGrid
      blocks={[
        [
          {
            label: t("columns.id", { ns: "common" }),
            value: mainOrder.id.toString(),
            xs: 3,
          },
          {
            label: t("mainOrders.fields.inquiry"),
            value: mainOrder.inquiry.name.toString(),
            xs: 3,
          },
          {
            label: t("mainOrders.fields.supplier"),
            value: mainOrder.supplier,
            xs: 3,
          },
          {
            label: t("mainOrders.fields.date"),
            value: displayDate(mainOrder.date),
            xs: 3,
          },
        ],
        [
          {
            label: t("mainOrders.fields.uploadedBy"),
            value: displayFullName(mainOrder.uploadedBy),
            xs: 3,
          },
          {
            label: t("mainOrders.fields.uploadedFileKey"),
            value: mainOrder.uploadedFileKey,
            xs: 9,
          },
        ],
        "divider",
        [
          {
            label: t("columns.createdAt", { ns: "common" }),
            value: displayDateTime(mainOrder.createdAt),
            xs: 3,
          },
          {
            label: t("columns.updatedAt", { ns: "common" }),
            value: displayDateTime(mainOrder.updatedAt),
            xs: 9,
          },
        ],
      ]}
    />
  );
};
