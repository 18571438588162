import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useGetAssignmentQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { TableControls } from "./TableControls";
import { useListTools } from "../../../../../components/List/useListTools";
import { RequestedItemSchoolStatus } from "../../../../../types";
import { RequestedItemQuantityField } from "../RequestedItemQuantityField";
import { SelectRequestedItemStatus } from "../SelectRequestedItemStatus";
import { Actions } from "./Actions";
import { useSchoolInquiryItemsListColumns } from "../useSchoolInquiryItemsListColumns";
import { List } from "../../../../../components/List/List";
import { EnhancedSkeleton } from "../../../../../components/EnhancedSkeleton";
import { useDisplayTotalAmount } from "../useDisplayTotalAmount";

export const AssignmentItems = () => {
  const { t } = useTranslation("pages");

  const canBulkSubmit = useCanBulkSubmit();
  const preActionCheck = usePreActionCheck();
  const displayTotalAmount = useDisplayTotalAmount();

  const { selectedIds, selectedIdsChangeHandler } = useListTools();

  const {
    inquiryResult: { data: inquiry },
    baseUrl,
    inquiryId,
    departmentInquiryId,
    assignmentId,
  } = useSchoolInquiryItemsTools();

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    isFetching: isAssignmentFetching,
  } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const columns = useSchoolInquiryItemsListColumns<{
    schoolStatus: RequestedItemSchoolStatus;
  }>({
    baseUrl,
    columns: useMemo(
      () => [
        {
          order: 4,
          column: {
            field: "quantity",
            name: t("schoolInquiryDetails.departmentColumns.quantity"),
            width: "150px",
            renderCell: (row) =>
              departmentInquiryId &&
              assignmentId && (
                <RequestedItemQuantityField
                  id={row.id}
                  value={row.quantity}
                  requestedItemSchoolStatus={row.schoolStatus}
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={assignmentId}
                  assignmentSchoolStatus={assignment?.schoolStatus}
                  assignmentUserId={assignment?.user.id}
                  loading={isAssignmentFetching}
                  disabled={
                    assignment?.departmentInquiry?.schoolStatus ===
                      "cancelled" ||
                    !inquiry ||
                    preActionCheck(inquiry.schoolStatus)
                  }
                />
              ),
          },
        },
        {
          order: 8,
          column: {
            field: "totalAmount",
            name: t("schoolInquiryDetails.departmentColumns.totalAmount"),
            renderCell: ({
              supplierProductCode,
              productRetailPrice,
              productRetailCurrency,
              totalAmount,
            }) =>
              displayTotalAmount({
                supplierProductCode,
                productRetailPrice,
                productRetailCurrency,
                totalAmount,
              }),
          },
        },
        {
          order: 9,
          column: {
            field: "status",
            name: t("schoolInquiryDetails.departmentColumns.status"),
            width: "250px",
            renderCell: (row) =>
              assignment && (
                <SelectRequestedItemStatus
                  inquiryId={inquiryId}
                  inquirySchoolStatus={inquiry?.schoolStatus}
                  departmentInquiryId={assignment.departmentInquiry.id}
                  departmentInquirySchoolStatus={
                    assignment.departmentInquiry.schoolStatus
                  }
                  assignmentId={assignment.id}
                  assignmentSchoolStatus={assignment.schoolStatus}
                  assignmentUserId={assignment.user.id}
                  requestedItemId={row.id}
                  requestedItemSchoolStatus={row.schoolStatus}
                  isBulkSelected={selectedIds.includes(row.id)}
                />
              ),
          },
        },
        {
          order: 10,
          column: {
            field: "actions",
            name: "",
            width: "64px",
            boxSx: {
              justifyContent: "center",
            },
            renderSkeleton: () => (
              <EnhancedSkeleton
                variant="circular"
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "100%",
                }}
              />
            ),
            renderCell: (row) => (
              <Actions
                item={row}
                inquiryId={inquiryId}
                inquirySchoolStatus={inquiry?.schoolStatus}
                departmentInquiryId={departmentInquiryId}
                assignmentId={assignmentId}
                assignmentUserId={assignment?.user.id}
                assignmentSchoolStatus={assignment?.schoolStatus}
                departmentSchoolStatus={
                  assignment?.departmentInquiry?.schoolStatus
                }
              />
            ),
          },
        },
      ],
      [
        assignment,
        assignmentId,
        departmentInquiryId,
        displayTotalAmount,
        inquiry,
        inquiryId,
        isAssignmentFetching,
        preActionCheck,
        selectedIds,
        t,
      ]
    ),
  });

  return (
    <>
      <TableControls selectedIds={selectedIds} />
      <List
        selectedIds={canBulkSubmit ? selectedIds : undefined}
        onSelectedIdsChange={selectedIdsChangeHandler}
        columns={columns}
        rows={assignment?.items ?? []}
        displaySkeleton={isAssignmentLoading}
      />
    </>
  );
};
