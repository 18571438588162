import React from "react";
import { useTranslation } from "react-i18next";
import { useGetNextNewItemQuery } from "../../../slices/items/api";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { Box, Link as MaterialLink, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useItemsTableBaseUrl } from "../../Items/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { baseUrl } from "../../NewItems/NewItems";

export const OpenNew = () => {
  const { t } = useTranslation("pages");
  const { itemId, inquiryId, isInquiryId } = useItemDetailsUrlTools();
  const { baseUrl: inquiryBaseUrl } = useItemsTableBaseUrl();

  const { data: nextItemData, isLoading: isLoadingNext } =
    useGetNextNewItemQuery(
      inquiryId ? { id: itemId, isInInquiry: isInquiryId } : skipToken
    );

  if (
    isLoadingNext ||
    !nextItemData ||
    (nextItemData.newCount === 1 && nextItemData.nextId === itemId) ||
    nextItemData.newCount === 0 ||
    nextItemData.nextId === -1
  ) {
    return null;
  }

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", padding: "16px" }}
    >
      <Typography align="center" paddingRight={1}>
        {nextItemData.currentIndex === -1
          ? t("itemDetails.openNew", { count: nextItemData.newCount })
          : t("itemDetails.openNewWithCurrent", {
              count: nextItemData.newCount,
              current: nextItemData.currentIndex,
            })}
      </Typography>
      <MaterialLink
        component={Link}
        to={`${isInquiryId ? inquiryBaseUrl : baseUrl}/${
          nextItemData.nextId
        }/details`}
      >
        {t("itemDetails.nextNew")}
      </MaterialLink>
    </Box>
  );
};
