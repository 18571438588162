import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useDeleteCategoryMutation } from "../../../slices/categories/api";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { usePermissions } from "../../../utils/usePermissions";

type ActionsProps = {
  id: GridRowId;
};

export const Actions: React.FC<ActionsProps> = ({ id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const can = usePermissions();
  const [deleteCategory] = useDeleteCategoryMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onEdit = useCallback(() => {
    history.push(`/categories/${intId}/edit`);
  }, [history, intId]);
  const onDelete = useCallback(() => {
    deleteCategory(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("categories.deleteSuccess"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("categories.errors.delete"));
      });
  }, [deleteCategory, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationChangeActivityTitle={t(
        "categories.confirmChangeActivityTitle"
      )}
      confirmationChangeActivityDescription={t(
        "categories.confirmChangeActivityDescription"
      )}
      confirmationTitle={t("categories.confirmDeleteTitle")}
      confirmationDescription={t("categories.confirmDeleteDescription")}
      onEditClick={onEdit}
      onDeleteClick={onDelete}
      canEdit={can("editCategory")}
      canDelete={can("editCategory")}
    />
  );
};
