import React, { useCallback, useMemo } from "react";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { Link as MaterialLink, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useChangeItemInquiryStatusMutation,
  useGetNewItemsQuery,
} from "../../../slices/items/api";
import { ItemFlattened } from "../../../slices/items/types";
import { useLocalStorage } from "usehooks-ts";
import { DEFAULT_PAGE_SIZE } from "../../../consts";
import { useSearchDebounce } from "../../../utils/useSearchDebounce";
import { Actions } from "./Actions";
import { MultipleSelectFilter } from "../../../components/toolbar/MultipleSelectFilter";
import { CustomDataGridServerSide } from "../../../components/CustomDataGrid/CustomDataGridServerSide";
import { baseUrl } from "../NewItems";
import { useSelector } from "../../../store";
import { selectSelf } from "../../../slices/auth/selectors";

export const DataTable = () => {
  const { t } = useTranslation("pages");

  const self = useSelector(selectSelf);

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    `table-newitems.sortModel`,
    []
  );

  const [page, setPage] = useLocalStorage(`table-newitems.page`, 0);

  const [pageSize, setPageSize] = useLocalStorage(
    `table-newitems.pageSize`,
    DEFAULT_PAGE_SIZE
  );

  const [search, setSearch] = useLocalStorage(`table-newitems.search`, "");
  const debouncedSearch = useSearchDebounce(search);

  const [inqViewFilter, setInqViewFilter] = useLocalStorage<string[]>(
    `filter.itemsByInquiryStatuses-newitems`,
    []
  );

  const { isLoading, isFetching, data, error, refetch } = useGetNewItemsQuery({
    page,
    pageSize,
    sortModel,
    search: debouncedSearch,
    inqView: self?.role === "inquiryManager" ? ["Astra"] : inqViewFilter,
  });

  const [, { isLoading: isChangeInquiryStatusLoading }] =
    useChangeItemInquiryStatusMutation();

  const columns = useMemo<
    GridColDef<ItemFlattened & { inqView: string }>[]
  >(() => {
    const widthPercentForNames = 0.2;

    return [
      {
        field: "supplier",
        headerName: t("newitems.fields.supplier"),
        flex: 0.1,
        valueGetter: (params) =>
          params.value?.name || t("na", { ns: "common" }),
      },
      {
        field: "supplierProductCode",
        headerName: t("newitems.fields.supplierProductCode"),
        flex: 0.1,
        valueGetter: (params) => params.value || t("na", { ns: "common" }),
      },
      {
        field: "name",
        headerName: t("newitems.fields.name"),
        flex: widthPercentForNames,
        renderCell: (params) => (
          <MaterialLink
            component={Link}
            to={`${baseUrl}/${params.row.id}/details`}
          >
            {params.value || t("na", { ns: "common" })}
          </MaterialLink>
        ),
      },
      {
        field: "inqView",
        headerName: t("newitems.fields.inqView"),
        flex: 0.1,
        sortable: false, // TODO sorting by inqView
        renderCell: (params) => <>{params.value}</>,
      },
      {
        field: "inquiry",
        headerName: t("newitems.fields.inquiry"),
        flex: 0.1,
        renderCell: (params) => (
          <>
            {params.row?.departmentInquiry?.inquiry?.name ||
              t("na", { ns: "common" })}
          </>
        ),
      },
      {
        field: "department",
        headerName: t("newitems.fields.department"),
        flex: 0.1,
        renderCell: ({ row }) => {
          const { department, inquiry } = row.departmentInquiry;
          if (!department || !inquiry) {
            return <>{t("na", { ns: "common" })}</>;
          }
          const toUrl =
            row.inqView === t("newitems.inqView.school")
              ? `/schoolinquiries/${inquiry.id}/details/${row.departmentInquiry.id}/items`
              : `/inquiries/${inquiry.id}/details/${row.departmentInquiry.id}/items/matching`;

          return (
            <MaterialLink component={Link} to={toUrl}>
              {row?.departmentInquiry?.department?.name}
            </MaterialLink>
          );
        },
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.05,
        minWidth: 100,
        renderCell: ({ id }) => (
          <Actions id={typeof id === "string" ? parseInt(id) : id} />
        ),
      },
    ];
  }, [t]);

  const filters = useMemo(() => {
    if (!self || self.role === "inquiryManager") {
      return null;
    }

    return (
      <MultipleSelectFilter
        size="small"
        id="by-item-inquiry-statuses"
        label={t("newitems.filters.inqView")}
        options={[
          {
            label: t("newitems.inqView.school"),
            value: t("newitems.inqView.school"),
          },
          {
            label: t("newitems.inqView.astra"),
            value: t("newitems.inqView.astra"),
          },
        ]}
        onChange={setInqViewFilter}
        value={inqViewFilter}
      />
    );
  }, [self, t, setInqViewFilter, inqViewFilter]);

  const resetFilters = useCallback(() => {
    setInqViewFilter([]);
  }, [setInqViewFilter]);

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("newitems.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <StyledDataGrid
        autoHeight
        columns={columns}
        rows={data.data}
        disableSelectionOnClick
        disableColumnMenu
        rowCount={data.rowCount}
        page={page}
        pageSize={pageSize}
        sortModel={sortModel}
        search={search}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortModelChange={setSortModel}
        setSearch={setSearch}
        loading={isFetching || isChangeInquiryStatusLoading}
        filters={filters}
        resetCustomFilters={resetFilters}
      />
    </PaperPage>
  );
};

const StyledDataGrid = styled(CustomDataGridServerSide)({
  ".id-width-fix div": {
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    textAlign: "end",
  },
});
