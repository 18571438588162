import React from "react";
import { FormHelperText, useTheme } from "@mui/material";
import { useConfiguredEditor } from "./useConfiguredEditor";
import { TopBar } from "./TopBar/TopBar";
import { FieldBox } from "./FieldBox";
import { StyledEditorContent } from "./StyledEditorContent";

export type WysiwygFieldProps = {
  label?: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  tableEditing?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const WysiwygField = ({
  label,
  error,
  helperText,
  tableEditing,
  onChange,
  onFocus,
  onBlur,
  defaultValue,
}: WysiwygFieldProps) => {
  const theme = useTheme();

  const { editor, focused } = useConfiguredEditor({
    defaultValue,
    onChange,
    onFocus,
    onBlur,
  });

  if (!editor) {
    return null;
  }

  return (
    <>
      <FieldBox variant="outlined" focused={focused} error={error} sx={{}}>
        <TopBar
          editor={editor}
          label={label}
          error={error}
          focused={focused}
          tableEditing={tableEditing}
        />
        <StyledEditorContent editor={editor} />
      </FieldBox>
      {helperText && (
        <FormHelperText sx={{ paddingLeft: theme.spacing(2) }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};
