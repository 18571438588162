import { useHistory, useRouteMatch } from "react-router-dom";
import { useCallback } from "react";

export const useBasicDetailsUrlTools = (baseUrl: string) => {
  const history = useHistory();

  const editDrawerMatcher = useRouteMatch(`${baseUrl}/edit`);
  const editCODrawerMatcher = useRouteMatch(`${baseUrl}/editco`);

  const closeDrawer = useCallback(
    () => history.push(baseUrl),
    [baseUrl, history]
  );

  return {
    editDrawerOpen: !!editDrawerMatcher,
    editCODrawerOpen: !!editCODrawerMatcher,
    closeDrawer,
  };
};
