import React from "react";
import { alpha, Box, useTheme } from "@mui/material";

type QuantityInCartProps = {
  quantity?: number;
};

export const QuantityInCart = ({ quantity }: QuantityInCartProps) => {
  const theme = useTheme();

  if (!quantity) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "100%",
        width: "48px",
        height: "48px",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
        fontSize: "10px",
        lineHeight: "24px",
        fontWeight: "500",
        boxShadow: "2px 4px 6px 0px rgba(44, 125, 223, 0.10)",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
        position: "absolute",
        top: "-24px",
        right: "-24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {quantity}
    </Box>
  );
};
