import React from "react";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { PurchaseOrderDetailsGrid } from "../../../components/PurchaseOrderDetailsGrid";
import { useGetPurchaseOrdersQuery } from "../../../slices/purchaseOrders/api";
import { Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SpinnerBase } from "../../../components/layout/SpinnerBase";
import { PaperBase } from "../../../components/layout/PaperBase";
import { useGetItemQuery } from "../../../slices/items/api";
import { usePermissions } from "../../../utils/usePermissions";
import { Link } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const PurchaseOrder = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { baseUrl, itemId } = useItemDetailsUrlTools();

  const { data: item, isLoading: isItemLoading } = useGetItemQuery(itemId);
  const { data: purchaseOrders = [], isLoading: arePurchaseOrdersLoading } =
    useGetPurchaseOrdersQuery(item?.departmentInquiry.inquiry.id ?? skipToken);

  if (isItemLoading || arePurchaseOrdersLoading) {
    return (
      <Box>
        <SpinnerBase />
      </Box>
    );
  }

  const canAssignPurchaseOrder =
    can("assignItemPurchaseOrder") &&
    (item?.inquiryStatus === "submitted" ||
      (item?.inquiryStatus === "confirmed" && item?.orderStatus === "new"));

  const purchaseOrder = purchaseOrders.find(
    ({ id }) => id === item?.purchaseOrder?.id
  );

  if (!purchaseOrder && !canAssignPurchaseOrder) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h5" component="h3" sx={{ marginBottom: 2 }}>
        {t("itemDetails.purchaseOrder")}
      </Typography>
      {!purchaseOrder && canAssignPurchaseOrder && (
        <Button
          component={Link}
          to={`${baseUrl}/assignPurchaseOrder?ids=${itemId}`}
        >
          {t("itemDetails.assign")}
        </Button>
      )}
      {purchaseOrder && (
        <PurchaseOrderDetailsGrid purchaseOrder={purchaseOrder} />
      )}
    </Box>
  );
};

const Box = styled(PaperBase)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
