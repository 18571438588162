import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Link as MaterialLink,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSchoolInquiriesTableUrlTools } from "../../../hooks";
import { Link, useHistory } from "react-router-dom";
import { useSendSchoolDepartmentInquiryToPrincipalMutation } from "../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES } from "../../../../../consts";
import { Add } from "@mui/icons-material";
import { colors } from "../../../../../theme/astra/colors";
import { Assignment } from "./Assignment";
import { useSelector } from "../../../../../store";
import {
  selectSelfId,
  selectSelfRole,
  selectSelfSchoolCurrency,
} from "../../../../../slices/auth/selectors";
import { ReactComponent as ShopCartIcon } from "../../../../../assets/newDesignItems/shopping-cart.svg";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { InquirySchoolStatus, RouteState } from "../../../../../types";
import { GetSchoolInquiriesForHeadsResponseDto } from "../../../../../slices/schoolInquiries/types";
import { NewDesignStatusChip } from "../../../../../components/NewDesignStatusChip";
import { useDisplayPrice } from "../../../../../utils/useDisplayPrice";
import { Statuses } from "./Statuses";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";

type DepartmentInquiryProps = {
  inquiryId: number;
  inquirySchoolStatus: InquirySchoolStatus;
  inquiryBudget: number | null;
  departmentInquiry: GetSchoolInquiriesForHeadsResponseDto["data"][number]["departmentInquiries"][number];
};

export const DepartmentInquiry = ({
  inquiryId,
  inquirySchoolStatus,
  inquiryBudget,
  departmentInquiry: {
    id,
    schoolStatus,
    assignments,
    budget,
    department,
    totalAmount,
    items,
    indefiniteBudget,
  },
}: DepartmentInquiryProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();
  const history = useHistory<RouteState>();
  const displayPrice = useDisplayPrice();

  const { baseUrl } = useSchoolInquiriesTableUrlTools();
  const selfId = useSelector(selectSelfId);
  const selfRole = useSelector(selectSelfRole);
  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const goToDepartmentList = useCallback(() => {
    history.push(`${baseUrl}/${inquiryId}/details/${id}/items`, {
      justGoBack: true,
      scrollToTop: true,
    });
  }, [baseUrl, id, history, inquiryId]);

  const addAssignmentHandler = useCallback(() => {
    history.push(`${baseUrl}/${inquiryId}/department/${id}/addAssignment`, {
      justGoBack: true,
    });
  }, [baseUrl, id, history, inquiryId]);

  const preActionCheck = usePreActionCheck();

  const isAddingTeachersDisabled = useMemo(() => {
    return (
      preActionCheck(inquirySchoolStatus, schoolStatus) ||
      (schoolStatus !== "new" &&
        schoolStatus !== "inProgress" &&
        schoolStatus !== "pendingApproval")
    );
  }, [inquirySchoolStatus, preActionCheck, schoolStatus]);

  const isSendingToPrincipalDisabled = useMemo(() => {
    if (!id) {
      return true;
    }

    if (preActionCheck(inquirySchoolStatus)) {
      return true;
    }

    const allItemsFit = items.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByHead" ||
        schoolStatus === "approvedByPrincipal" ||
        schoolStatus === "cancelled"
    );

    return (
      schoolStatus !== "readyForSending" &&
      !(schoolStatus === "needReviewByHead" && allItemsFit)
    );
  }, [id, inquirySchoolStatus, items, preActionCheck, schoolStatus]);

  const showHelpForSendingToPrincipal = useMemo(() => {
    if (!id) {
      return true;
    }

    if (preActionCheck(inquirySchoolStatus)) {
      return false;
    }

    const allItemsFit = items.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByHead" ||
        schoolStatus === "approvedByPrincipal" ||
        schoolStatus === "cancelled"
    );

    return (
      schoolStatus === "new" ||
      schoolStatus === "inProgress" ||
      schoolStatus === "pendingApproval" ||
      (schoolStatus === "needReviewByHead" && !allItemsFit)
    );
  }, [id, inquirySchoolStatus, items, preActionCheck, schoolStatus]);

  const [sendDepartmentInquiry, { isLoading: isSending }] =
    useSendSchoolDepartmentInquiryToPrincipalMutation();

  const sendToPrincipalHandler = useCallback(() => {
    if (id) {
      sendDepartmentInquiry({
        inquiryId,
        departmentInquiryId: id,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [id, handleError, handleSuccess, inquiryId, sendDepartmentInquiry]);

  const selfAssignment = assignments.find(
    (assignment) => assignment.user.id === selfId
  );
  const otherAssignments = assignments.filter(
    (assignment) => assignment.user.id !== selfId
  );

  const sortedAssignments = selfAssignment
    ? [selfAssignment, ...otherAssignments]
    : otherAssignments;

  return (
    <Row>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          border,
          backgroundColor: colors.white,
        }}
      >
        <CardMedia
          sx={{
            position: "relative",
            padding: 2,
            display: "flex",
            gap: 1,
            flexDirection: "column",
            borderBottom: border,
            paddingInline: "32px",
            paddingBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flex: "1" }}>
              <Box sx={{ marginRight: "10px" }}>
                <ShopCartIcon />
              </Box>
              <Box sx={{ marginRight: "40px" }}>
                <Title>{department.name}</Title>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewDesignStatusChip
                  chipStyle={
                    DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[schoolStatus]
                  }
                  label={t(`schoolDepartmentInquiryStatuses.${schoolStatus}`, {
                    ns: "common",
                  })}
                />
              </Box>
            </Box>
            <Box
              sx={{
                flex: "1",
                display: "flex",
                gap: "8px",
                alignItems: "baseline",
                flexDirection: "column",
                marginLeft: "40px",
              }}
            >
              <Typography sx={{ fontSize: "14px", color: colors.dustBlue1 }}>
                {t("schoolInquiries.budgetInquiryTitle")},{" "}
                {selfCurrency?.code?.toUpperCase() ||
                  t("currencies.qar", { ns: "common" })}
                :
              </Typography>
              <Box sx={{ display: "flex", gap: "3px", alignItems: "baseline" }}>
                <BudgetPrice>
                  {displayPrice(totalAmount) || 0}
                  {indefiniteBudget && "+"}
                </BudgetPrice>
                <BudgetCurrency>
                  {selfCurrency?.code?.toUpperCase() ||
                    t("currencies.qar", { ns: "common" })}
                </BudgetCurrency>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: colors.dustBlue2,
                    fontWeight: "500",
                  }}
                >
                  /
                </Typography>
                <BudgetPrice>{displayPrice(budget) || "N/A"}</BudgetPrice>
                <BudgetCurrency>
                  {selfCurrency?.code?.toUpperCase() ||
                    t("currencies.qar", { ns: "common" })}
                </BudgetCurrency>
              </Box>
            </Box>
            <Box
              sx={{
                flex: "1",
                display: "flex",
                gap: "20px",
                justifyContent: "end",
              }}
            >
              <AddTeacherButton
                startIcon={<Add />}
                variant="outlined"
                onClick={addAssignmentHandler}
                disabled={isAddingTeachersDisabled}
              >
                {t("schoolInquiryDetails.addTeacher")}
              </AddTeacherButton>
              <ConfirmationButton
                component={SendToAstraButton}
                variant="contained"
                proceedButtonText={
                  showHelpForSendingToPrincipal
                    ? t(
                        "schoolInquiryDetails.departmentControls.sendToPrincipal.approveAllFirstButtonLabel"
                      )
                    : undefined
                }
                onClick={
                  showHelpForSendingToPrincipal
                    ? goToDepartmentList
                    : sendToPrincipalHandler
                }
                confirmationTitle={
                  showHelpForSendingToPrincipal
                    ? t(
                        "schoolInquiryDetails.departmentControls.sendToPrincipal.approveAllFirst"
                      )
                    : t(
                        "schoolInquiryDetails.departmentControls.sendToPrincipal.confirmationTitle"
                      )
                }
                confirmationDescription={
                  showHelpForSendingToPrincipal
                    ? t(
                        "schoolInquiryDetails.departmentControls.sendToPrincipal.checkListsFirst"
                      )
                    : t("noUndo", { ns: "common" })
                }
                loading={isSending}
                disabled={
                  (isSendingToPrincipalDisabled &&
                    !showHelpForSendingToPrincipal) ||
                  selfRole === "admin"
                }
              >
                {t(
                  "schoolInquiryDetails.departmentControls.sendToPrincipal.label"
                )}
              </ConfirmationButton>
            </Box>
          </Box>
          <Statuses
            current={schoolStatus}
            inquiryId={inquiryId}
            departmentInquiryId={id}
            assignments={assignments}
          />
          <MaterialLink
            component={Link}
            to={{
              pathname: `/schoolinquiries/${inquiryId}/details/${id}/items`,
              state: {
                justGoBack: true,
                scrollToTop: true,
              },
            }}
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              position: "absolute",
              bottom: "15px",
              left: "40px",
            }}
          >
            {t("schoolInquiryDetails.viewAllItems")}
          </MaterialLink>
        </CardMedia>
        <Stack
          sx={{
            marginTop: "10px",
            paddingInline: "12px",
            paddingBlock: "20px",
          }}
        >
          <Stack>
            {sortedAssignments.map((assignment) => (
              <Assignment
                assignment={assignment}
                inquiryId={inquiryId}
                inquirySchoolStatus={inquirySchoolStatus}
                inquiryBudget={inquiryBudget}
                departmentInquiryId={id}
                departmentInquirySchoolStatus={schoolStatus}
                key={`assignment-list-${id}-assignment-${assignment.id}`}
              />
            ))}
          </Stack>
        </Stack>
      </Card>
    </Row>
  );
};

const AddTeacherButton = styled(Button)({
  color: colors.dustBlue1,
  backgroundColor: colors.white,
  borderColor: colors.dustBlue4,
  textTransform: "capitalize",
});

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  lineHeight: "28px",
}));

const BudgetPrice = styled(Typography)({
  fontSize: "16px",
  color: colors.darkBlue,
  fontWeight: "500",
});

const BudgetCurrency = styled(Typography)({
  fontSize: "12px",
  color: colors.darkBlue,
  fontWeight: "500",
});

const Row = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
  padding: "10px 16px",
});

const border = "1px solid #CFDFEF";
