import React, { useCallback, useMemo } from "react";
import { Autocomplete, FormControlProps, TextField } from "@mui/material";
import { PaperPageSelect } from "../layout/PaperPage";
import { useSearchDebounce } from "../../utils/useSearchDebounce";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetProductsQuery } from "../../slices/products/api";
import { useTranslation } from "react-i18next";

type FreeAutocompleteItemNameProps = Omit<
  FormControlProps,
  "id" | "onChange" | "value"
> & {
  id: string;
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  setSupplier?: (id: number) => void;
  setSupplierProductCode?: (spc: string) => void;
  error?: boolean;
  helperText?: string;
};

export const FreeAutocompleteItemName: React.FC<FreeAutocompleteItemNameProps> =
  ({
    id,
    label,
    error = false,
    helperText,
    onChange,
    setSupplier,
    setSupplierProductCode,
    value,
  }) => {
    const { t } = useTranslation();
    const debouncedValue = useSearchDebounce(value || "");

    const { data, isFetching } = useGetProductsQuery(
      debouncedValue.length > 0
        ? {
            page: 1,
            pageSize: 20,
            search: debouncedValue,
            activities: ["Available"],
          }
        : skipToken
    );

    const options = useMemo(() => {
      if (!data?.data) {
        return [];
      }

      return data?.data
        .filter((product) => product.supplierProductCode.supplier.public)
        .map(
          ({ name, supplierProductCode }) =>
            `${supplierProductCode.supplier.name} - ${supplierProductCode.code} - ${name}`
        );
    }, [data]);

    const changeHandler = useCallback(
      (event, value: string | null) => {
        if (!value) {
          if (onChange) {
            onChange("");
          }
          return;
        }

        const [supplierName, supplierProductCodeStr, ...nameArray] =
          value.split(" - ");
        const name = nameArray.join(" - ");
        const product = data?.data.find(
          (product) =>
            product.supplierProductCode.code === supplierProductCodeStr &&
            product.supplierProductCode.supplier.name === supplierName
        );
        const supplierId = product?.supplierProductCode.supplier.id;
        const spcCode = product?.supplierProductCode.code;

        if (onChange) {
          onChange(name);
        }

        if (setSupplier && supplierId) {
          setSupplier(supplierId);
        }
        if (setSupplierProductCode && spcCode) {
          setSupplierProductCode(spcCode);
        }
      },
      [data, onChange, setSupplier, setSupplierProductCode]
    );

    const inputChangeHandler = useCallback(
      (event, value: string | null) => {
        if (onChange) {
          onChange(value || "");
        }
      },
      [onChange]
    );

    return (
      <Autocomplete
        freeSolo
        fullWidth
        id={`select-product-name-${id}`}
        PaperComponent={PaperPageSelect}
        options={options}
        noOptionsText={t("emptySelect", { ns: "common" })}
        value={value}
        onChange={changeHandler}
        onInputChange={inputChangeHandler}
        loading={isFetching}
        loadingText={t("loading", { ns: "common" })}
        renderInput={(params) => {
          // TODO change to correct rerender of component
          // Problem: after rerender, if value not changed (2 products with same name), value in field shows as option name, not current field value
          params.inputProps.value = value;
          return (
            <TextField
              {...params}
              label={label}
              error={error}
              helperText={helperText}
            />
          );
        }}
      />
    );
  };
