import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import { useGetPurchaseOrdersQuery } from "../../../../slices/purchaseOrders/api";
import { usePermissions } from "../../../../utils/usePermissions";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { useInquiryDetailsUrlTools } from "../../hooks";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { PurchaseOrderDetailsGrid } from "../../../../components/PurchaseOrderDetailsGrid";

type DetailsProps = {
  inquiryId: number;
};

export const Details = ({ inquiryId }: DetailsProps) => {
  const { t } = useTranslation("pages");
  const { baseUrl } = useInquiryDetailsUrlTools();
  const { id, closeDrawer } = useTableUrlTools(`${baseUrl}/po`);
  const can = usePermissions();

  const { data, isLoading, error } = useGetPurchaseOrdersQuery(inquiryId);

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  const purchaseOrder = useMemo(
    () => data && data.find((entry) => entry.id === id),
    [data, id]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!purchaseOrder || !can("getPurchaseOrder")) {
    return null;
  }

  return (
    <DisplayContainer
      heading={`${t("purchaseOrders.detailsTitle")}: ${
        purchaseOrder.referenceNumber
      }`}
    >
      <PurchaseOrderDetailsGrid purchaseOrder={purchaseOrder} />
    </DisplayContainer>
  );
};
