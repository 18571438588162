import React from "react";
import { createSvgIcon } from "@mui/material";

export const Tag = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 9C15.5 9.27614 15.2761 9.5 15 9.5C14.7239 9.5 14.5 9.27614 14.5 9C14.5 8.72386 14.7239 8.5 15 8.5C15.2761 8.5 15.5 8.72386 15.5 9Z"
      stroke="currentColor"
    />
    <path
      d="M12 4.75H19.25V12L12.5536 18.6708C11.7545 19.4668 10.4556 19.445 9.68373 18.6226L5.28997 13.941C4.54044 13.1424 4.57269 11.8895 5.3623 11.1305L12 4.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Tag"
);
