import * as yup from "yup";
import { createYupSchema } from "../../../../utils/validation";

export const schoolInquirySchemaCreator = createYupSchema((t) =>
  yup.object({
    name: yup
      .string()
      .min(1, t("required"))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    schoolId: yup.number().min(0, t("required")).required(t("required")),
    userId: yup.number().min(0, t("required")).required(t("required")),
    isBudget: yup.boolean(),
    budget: yup.number().typeError(t("number")).nullable().optional(),
    budgetCurrencyId: yup.number().min(0, t("required")).optional(),
  })
);
