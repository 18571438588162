import React from "react";
import { Box } from "@mui/material";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";

type ListSkeletonProps = {
  pageSize: number;
};

export const ListSkeleton = ({ pageSize }: ListSkeletonProps) => {
  const skeletons = Array.from(new Array(pageSize)).map((_value, index) => (
    <Box
      key={index}
      sx={{
        paddingTop: "16px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <EnhancedSkeleton
          variant="text"
          width={[75, 275, "px"]}
          sx={{ lineHeight: "18px" }}
        />
        <EnhancedSkeleton
          variant="text"
          width={[75, 275, "px"]}
          sx={{ lineHeight: "10px" }}
        />
      </Box>
      <EnhancedSkeleton
        variant="rectangular"
        height={[200, 500, "px"]}
        sx={{ marginTop: "36px" }}
      />
    </Box>
  ));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {skeletons}
    </Box>
  );
};
