import React from "react";
import { ShoppingCart } from "../../../components/astraIcons/ShoppingCart";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ActiveShoppingList = () => {
  const { t } = useTranslation("pages", {
    keyPrefix: "shopCatalog.currentList",
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        marginLeft: "-4px",
      }}
    >
      <ShoppingCart />
      <Typography
        component="span"
        sx={{
          fontSize: "12px",
          lineHeight: "20px",
          fontWeight: "500",
          color: "primary.dark",
        }}
      >
        {t("activeShoppingList")}
      </Typography>
    </Box>
  );
};
