import { useEffect, useRef, useState } from "react";

export const useCalculateHeight = () => {
  const ref = useRef<HTMLElement>(null);

  const [calculatedHeight, setCalculatedHeight] = useState<number | null>(null);

  useEffect(() => {
    const calculateSliderHeight = () => {
      setCalculatedHeight(ref.current?.clientWidth ?? null);
    };

    calculateSliderHeight();

    window.addEventListener("resize", calculateSliderHeight);
    return () => window.removeEventListener("resize", calculateSliderHeight);
  }, []);

  return { ref, calculatedHeight };
};
