import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useAddSupplierMutation } from "../../../slices/suppliers/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { SupplierForm, supplierFormSchemaCreator } from "./SupplierForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";

type AddSupplierForm = {
  name: string;
  code: string;
  public: boolean;
};

export const Add: React.FC = () => {
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(supplierFormSchemaCreator);
  const formMethods = useForm<AddSupplierForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addSupplier, { isLoading }] = useAddSupplierMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("suppliers.errors.add")
  );

  const { closeDrawer } = useTableUrlTools("/suppliers");
  const onSubmit = useCallback(
    (form: AddSupplierForm) =>
      addSupplier({ ...form, active: true })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("suppliers.addSuccess"), `/suppliers/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError),
    [addSupplier, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <Form
      heading={t("suppliers.addSupplier")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <SupplierForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
