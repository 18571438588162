import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { ControlledFieldProps } from "./types";
import { fieldError } from "../../utils/formHelpers";
import { NumericField, NumericFieldProps } from "../fields/NumericField";

type NewDesignControlledNumericFieldProps<TFieldValues> =
  ControlledFieldProps<TFieldValues> &
    Omit<
      NumericFieldProps,
      "size" | "label" | "error" | "helperText" | "value" | "onChange" | "name"
    >;

export const NewDesignControlledNumericField = <
  TFieldValues extends FieldValues
>({
  control,
  defaultValue,
  name,
  fieldsErrors,
  label,
  ...props
}: NewDesignControlledNumericFieldProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, name },
        fieldState: { invalid, error },
      }) => {
        const [serverInvalid, serverError] = fieldError(name, fieldsErrors);
        return (
          <NumericField
            size="small"
            placeholder={label ?? name}
            error={invalid || serverInvalid}
            helperText={error?.message || serverError}
            value={value}
            onChange={onChange}
            name={name}
            {...props}
          />
        );
      }}
    />
  );
};
