import React, { useMemo } from "react";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import {
  GridColDef,
  GridComparatorFn,
  GridSortModel,
  gridStringOrNumberComparator,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Chip,
  IconButton,
  Link as MaterialLink,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Actions } from "./Actions";
import { useTranslation } from "react-i18next";
import { FilteredCustomDataGrid } from "./FilteredCustomDataGrid";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetInquiriesQuery } from "../../../slices/inquiries/api";
import { InquiryFlattened } from "../../../slices/inquiries/types";
import { useLocalStorage } from "usehooks-ts";
import { DEFAULT_PAGE_SIZE, INQUIRY_STATUS_COLORS } from "../../../consts";
import { capitalize } from "../../../utils/capitalize";
import { StatisticsBar } from "./StatisticsBar";
import { useSearchDebounce } from "../../../utils/useSearchDebounce";
import { Box } from "@mui/system";
import { Add, Error as HighPriorityIcon } from "@mui/icons-material";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { InquiryStatus } from "../../../types";
import { useSelector } from "../../../store";
import { selectSelfRole } from "../../../slices/auth/selectors";

const tableName = "inquiries";

interface PriorityDueDateCellValue {
  priority: string;
  dueDate: Date;
}

export const DateTable: React.FC = () => {
  const { t } = useTranslation("pages");

  const selfRole = useSelector(selectSelfRole);

  const can = usePermissions();
  const displayDate = useDisplayDateTime(false);

  const [pageSize, setPageSize] = useLocalStorage(
    `table-${tableName}.pageSize`,
    DEFAULT_PAGE_SIZE
  );

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    `table-${tableName}.sortModel`,
    [
      {
        field: "dueDate",
        sort: "asc",
      },
    ]
  );

  const [page, setPage] = useLocalStorage(`table-${tableName}.page`, 0);

  const [search, setSearch] = useLocalStorage(`table-${tableName}.search`, "");
  const debouncedSearch = useSearchDebounce(search);

  const [statusesFilter, setStatusesFilter] = useLocalStorage<string[]>(
    "filter.inquiriesByStatuses",
    selfRole === "orderManager"
      ? [
          "underProcess",
          "submitted",
          "confirmed",
          "ordering",
          "inDelivery",
          "delivered",
          "lost",
        ]
      : ["new", "underProcess"]
  );

  const { isLoading, isFetching, data, error, refetch } = useGetInquiriesQuery({
    page,
    pageSize,
    sortModel,
    search: debouncedSearch,
    statusFilter: statusesFilter,
  });

  const rows = data?.data;

  const priorityDueDateSortComparator: GridComparatorFn = (
    v1,
    v2,
    param1,
    param2
  ) => {
    const priorityComparatorResult = gridStringOrNumberComparator(
      (v1 as PriorityDueDateCellValue).priority,
      (v2 as PriorityDueDateCellValue).priority,
      param1,
      param2
    );

    if (priorityComparatorResult !== 0) {
      return priorityComparatorResult;
    }

    return gridStringOrNumberComparator(
      (v1 as PriorityDueDateCellValue).dueDate,
      (v2 as PriorityDueDateCellValue).dueDate,
      param1,
      param2
    );
  };

  const columns = useMemo<GridColDef<InquiryFlattened>[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.1,
        sortable: false,
      },
      {
        field: "school",
        headerName: t("inquiries.fields.school"),
        flex: 0.3,
        valueFormatter: ({ value }) => value.name,
        sortable: false,
      },
      {
        field: "name",
        headerName: t("inquiries.fields.name"),
        flex: 0.3,
        renderCell: (params) => (
          <MaterialLink component={Link} to={`/inquiries/${params.id}/details`}>
            {params.value}
          </MaterialLink>
        ),
        sortable: false,
      },
      {
        field: "status",
        headerName: t("inquiries.fields.status"),
        flex: 0.2,
        renderCell: (params) => (
          <Chip
            label={<b>{t(`inquiries.statuses.${params.value}`)}</b>}
            size="small"
            variant={"filled"}
            sx={{
              backgroundColor:
                INQUIRY_STATUS_COLORS[params.value as InquiryStatus],
              color: "white",
            }}
          />
        ),
        sortable: false,
      },
      {
        field: "priority",
        headerName: t("inquiries.fields.priority"),
        flex: 0.2,
        renderCell: (params) => {
          return (
            <>
              {capitalize(params.row.priority)}
              {params.row.priority === "high" && (
                <Box sx={{ paddingTop: "5px" }}>
                  <HighPriorityIcon fontSize="small" color="error" />
                </Box>
              )}
            </>
          );
        },
        valueGetter: (params: GridValueGetterParams) => ({
          priority: params.row.priority,
          dueDate: params.row.dueDate,
        }),
        valueFormatter: (params) => params.value.priority,
        sortComparator: priorityDueDateSortComparator,
        sortable: false,
      },
      {
        field: "dueDate",
        headerName: t("inquiries.fields.dueDate"),
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) => ({
          priority: params.row.priority,
          dueDate: params.row.dueDate,
        }),
        sortComparator: priorityDueDateSortComparator,
        renderCell: (params) => displayDate(params.value.dueDate),
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => (
          <Actions id={params.id} status={params.row.status} />
        ),
      },
    ],
    [displayDate, t]
  );

  const actionButtons = useMemo(() => {
    return (
      <Stack direction="row">
        <Tooltip title={t("inquiries.add") as string}>
          <IconButton
            component={Link}
            to={`inquiries/add`}
            disabled={!can("addInquiry")}
          >
            <Add />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }, [t, can]);

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (isLoading || !rows) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <StatisticsBar
        statistics={data.statistics}
        setStatusesFilter={setStatusesFilter}
      />
      <FilteredCustomDataGrid
        buttons={actionButtons}
        columns={columns}
        rows={rows}
        disableSelectionOnClick
        disableColumnMenu
        rowCount={data.rowCount}
        page={page}
        pageSize={pageSize}
        sortModel={sortModel}
        search={search}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortModelChange={setSortModel}
        setSearch={setSearch}
        statusesFilter={statusesFilter}
        setStatusesFilter={setStatusesFilter}
        loading={isFetching}
      />
    </PaperPage>
  );
};
