import React, { memo } from "react";
import { Grid, Paper } from "@mui/material";
import { Product } from "./Product";
import { GetProductsResponseDto } from "../../../slices/products/types";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

type ListProps = {
  data: GetProductsResponseDto["data"];
  itemsPerRow: number;
};

export const List = memo(({ data, itemsPerRow }: ListProps) => {
  const { t } = useTranslation("pages");

  const content = data.map((product) => (
    <Grid key={product.id} item gridColumn="span 1">
      <Product data={product} />
    </Grid>
  ));

  return (
    <>
      <Grid
        container
        display="grid"
        gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`}
        gap={2}
      >
        {content}
      </Grid>
      {data.length === 0 && (
        <PaperOuterNoRows>
          <PaperInnerNoRows>{t("products.noProducts")}</PaperInnerNoRows>
        </PaperOuterNoRows>
      )}
    </>
  );
});

const PaperInnerNoRows = styled(Paper)({
  margin: "auto",
});

const PaperOuterNoRows = styled(Paper)({
  display: "flex",
  height: "80%",
});
