import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form } from "../../../components/wideDrawer/Form";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { createYupSchema, useYup } from "../../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Paper, Typography } from "@mui/material";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { ControlledNumericField } from "../../../components/controlledFields/ControlledNumericField";
import * as yup from "yup";
import {
  useAssignProductMutation,
  useGetItemQuery,
} from "../../../slices/items/api";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { SelectAlternativeCodeMatch } from "../../../components/prefilledSelectors/SelectAlternativeCodeMatch";
import { ControlledColorField } from "../../../components/controlledFields/ControlledColorField";
import { allowedProductColors } from "../../../slices/products/types";
import { MatchOption, matchOptions, Noop } from "../../../types";
import { AssignProductRequestDto } from "../../../slices/items/types";
import { useLocation } from "react-router-dom";
import { useGetProductQuery } from "../../../slices/products/api";
import { Spinner } from "../../../components/wideDrawer/Spinner";

type AssignForm = Omit<AssignProductRequestDto, "productId" | "itemId">;

type AssignProps = {
  baseUrl: string;
  closeDrawer: Noop;
  productId: number;
  itemId: number;
};

export const Assign = ({
  closeDrawer,
  productId,
  itemId,
  baseUrl,
}: AssignProps) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(assignSchema);

  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("itemDetails.errors.assign")
  );
  const [assignProduct, { isLoading: isAssignLoading }] =
    useAssignProductMutation();
  const { data: product, isLoading: isDataLoading } = useGetProductQuery({
    id: productId,
  });
  const { isFetching: isItemsDataLoading, data: item } =
    useGetItemQuery(itemId);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { handleSubmit, control, reset } = useForm<AssignForm>({
    defaultValues: {
      productQuantity: item?.quantity || 1,
      productColor: product?.color ?? "",
      match: searchParams.get("match") as MatchOption,
    },
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset({
      productQuantity: item?.quantity || 1,
      productColor: product?.color ?? "",
      match: searchParams.get("match") as MatchOption,
    });
  }, [product, reset, searchParams, item]);

  const onSubmit = useCallback(
    (form: AssignForm) => {
      assignProduct({
        productId,
        inquiryId: item?.departmentInquiry.inquiry.id || 0,
        itemId,
        ...form,
      })
        .unwrap()
        .then(({ message, additional }) => {
          feedSuccess(
            message ?? t("itemDetails.successes.assign"),
            `${baseUrl}/product/${productId}`
          );
          if (additional) {
            feedSuccess(additional);
          }
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [
      assignProduct,
      baseUrl,
      catchFormError,
      closeDrawer,
      feedSuccess,
      item?.departmentInquiry.inquiry.id,
      itemId,
      productId,
      reset,
      t,
    ]
  );

  if (isDataLoading || isItemsDataLoading || !product || !item) {
    return <Spinner />;
  }

  return (
    <Form
      heading={t("itemDetails.assignFormTitleShort")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isAssignLoading}
      formError={formError}
    >
      <Grid
        container
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
      >
        <Grid item gridColumn="span 12">
          <Paper>
            <Typography variant="subtitle2" gutterBottom>
              {t("itemDetails.itemLabel")}
            </Typography>
            <Typography variant="body1">
              {item.supplier?.name || t("na", { ns: "common" })}{" "}
              {item.supplierProductCode || t("na", { ns: "common" })}
            </Typography>
            <Typography variant="body1">{item.name}</Typography>
            <Typography variant="body1" gutterBottom>
              {t("itemDetails.assignFormFields.quantity")}: {item.quantity}
            </Typography>
          </Paper>
          <Paper>
            <Typography variant="subtitle2" gutterBottom sx={{ marginTop: 2 }}>
              {t("products.productLabel")}
            </Typography>
            <Typography variant="body1">
              {product.supplier.name} {product.supplierProductCode}
            </Typography>
            <Typography variant="body1">
              {product.name || t("products.NA")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("products.fields.packSize")}:{" "}
              {product.packSize || t("products.NA")}
            </Typography>
          </Paper>
        </Grid>
        <Grid item gridColumn="span 3">
          <ControlledCustomField
            control={control}
            name="match"
            fieldsErrors={fieldsErrors}
            render={(errorMessage, { field }) => (
              <SelectAlternativeCodeMatch
                id={`assign-form-match`}
                label={`${t("itemDetails.assignFormFields.match")} *`}
                size="small"
                selectProps={field}
                error={!!errorMessage}
              />
            )}
          />
        </Grid>
        <Grid item gridColumn="span 4">
          <ControlledNumericField
            control={control}
            name="productQuantity"
            label={`${t("itemDetails.assignFormFields.quantity")} *`}
            fieldsErrors={fieldsErrors}
            positiveOnly
          />
        </Grid>
        {!!product.color && (
          <Grid item gridColumn="span 5">
            <ControlledColorField
              control={control}
              name="productColor"
              label={`${t("itemDetails.assignFormFields.color")} *`}
              fieldsErrors={fieldsErrors}
              multiple={false}
              colorsWhitelist={[product.color]}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

const assignSchema = createYupSchema((t) =>
  yup.object({
    productQuantity: yup
      .number()
      .typeError(t("number"))
      .positive(t("quantityPositive"))
      .nullable(),
    color: yup
      .array()
      .of(yup.string().oneOf([...allowedProductColors]))
      .nullable(),
    match: yup.string().oneOf([...matchOptions]),
  })
);
