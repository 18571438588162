import { Box, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "../../../../../store";
import { selectIsCartOpen } from "../../../../../slices/shop/selectors";
import { colors } from "../../../../../theme/astra/colors";

type BadgeProps = {
  quantity?: number;
};

export const Badge = ({ quantity }: BadgeProps) => {
  const theme = useTheme();

  const isCartOpen = useSelector(selectIsCartOpen);

  if (!quantity || isCartOpen) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-7px",
        right: "-7px",
        width: "16px",
        height: "16px",
        backgroundColor: colors.whitishGray,
        color: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.secondary.light}`,
        fontSize: "10px",
        lineHeight: "135%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        borderRadius: "100%",
      }}
    >
      {quantity}
    </Box>
  );
};
