import { GridSortModel } from "@mui/x-data-grid";
import { Role } from "./permissions/permissions";
import {
  ItemInquiryStatus,
  ItemOrderStatus,
  ItemSchoolStatus,
} from "./slices/items/types";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
  InquiryStatus,
  RequestedItemSchoolStatus,
  StatusColor,
} from "./types";

export const FETCH_ERROR = "FETCH_ERROR";

export const CATEGORY_LEVELS_SELECTOR_LABELS = {
  "1": "Level 1",
  "2": "Level 2",
};

export const DEFAULT_CATEGORY_COLOR = "#ffffff";

export const ACTIVE_FILTER_OPTIONS = ["Active", "Inactive"];
export const DEFAULT_ACTIVE_FILTER = ["Active"];

export const AVAILABLE_FILTER_OPTIONS = ["Available", "Unavailable"];
export const DEFAULT_AVAILABLE_FILTER = ["Available"];

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_SORT_MODEL: GridSortModel = [
  {
    field: "id",
    sort: "asc",
  },
];
export const DEFAULT_SORT_MODEL_LAST_ON_TOP: GridSortModel = [
  {
    field: "createdAt",
    sort: "desc",
  },
];

export const DEFAULT_INQUIRY_SORT_MODEL: GridSortModel = [
  {
    field: "dueDate",
    sort: "asc",
  },
];

export const DEFAULT_SORT_MODEL_DESC: GridSortModel = [
  {
    field: "id",
    sort: "desc",
  },
];

export const TIME_ZONE = "Asia/Qatar";

export const INQUIRY_STATUSES_FOR_ORDER_TABLE = [
  "confirmed",
  "delivered",
  "discontinued",
];

export const INQUIRY_STATUS_COLORS: Record<InquiryStatus, string> = {
  new: "#616161",
  underProcess: "#448AFF",
  submitted: "#2979FF",
  confirmed: "#2962FF",
  ordering: "#AA00FF",
  inDelivery: "#43A047",
  delivered: "#2E7D32",
  lost: "#EF5350",
};

export const ITEM_INQUIRY_STATUS_COLORS: Record<ItemInquiryStatus, string> = {
  new: "#616161",
  matched: "#448AFF",
  submitted: "#2979FF",
  confirmed: "#2979FF",
  delivered: "#2E7D32",
  discontinued: "#EF5350",
  canceled: "#EF5350",
};

export const ITEM_ORDER_STATUS_COLORS: Record<ItemOrderStatus, string> = {
  new: "#616161",
  placed: "#E040FB",
  confirmed: "#D500F9",
  readyToDispatch: "#43A047",
  shipped: "#43A047",
  clearance: "#43A047",
  warehouse: "#43A047",
  delivered: "#2E7D32",
  discontinued: "#EF5350",
};

export const ITEM_SCHOOL_STATUS_COLORS: Record<ItemSchoolStatus, string> = {
  new: "#616161",
  approvedByHead: "#673AB7",
  needReviewByHead: "#EF6C00",
  approvedByPrincipal: "#512DA8",
  cancelled: "#EF5350",
};

export const REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES: Record<
  RequestedItemSchoolStatus,
  StatusColor
> = {
  new: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  approvedByTeacher: {
    backgroundColor: "#F849931A",
    color: "#F84993",
  },
  needReviewByTeacher: {
    backgroundColor: "#FBB5001A",
    color: "#E58A00",
  },
  approvedByHead: {
    backgroundColor: "#00B9D81A",
    color: "#00A3BE",
  },
  approvedByPrincipal: {
    backgroundColor: "#33C34A1A",
    color: "#33C34A",
  },
  cancelled: {
    backgroundColor: "#FF1A1A1A",
    color: "#D30000",
  },
};

export const ITEM_SCHOOL_STATUS_CHIP_STYLES: Record<
  ItemSchoolStatus,
  StatusColor
> = {
  new: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  approvedByHead: {
    backgroundColor: "#00B9D81A",
    color: "#00A3BE",
  },
  needReviewByHead: {
    backgroundColor: "#FBB5001A",
    color: "#E58A00",
  },
  approvedByPrincipal: {
    backgroundColor: "#33C34A1A",
    color: "#33C34A",
  },
  cancelled: {
    backgroundColor: "#FF1A1A1A",
    color: "#D30000",
  },
};

export const INQUIRY_SCHOOL_STATUS_CHIP_STYLES: Record<
  InquirySchoolStatus,
  StatusColor
> = {
  new: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  inProgress: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  pendingApproval: {
    backgroundColor: "#F1D5431A",
    color: "#B18A00",
  },
  readyForSending: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  sentToAstra: {
    backgroundColor: "#A735AD1A",
    color: "#A735AD",
  },
  cancelled: {
    backgroundColor: "#FF1A1A1A",
    color: "#D30000",
  },
};

export const DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES: Record<
  DepartmentInquirySchoolStatus,
  StatusColor
> = {
  new: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  inProgress: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  pendingApproval: {
    backgroundColor: "#F1D5431A",
    color: "#B18A00",
  },
  readyForSending: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  approvedByHead: {
    backgroundColor: "#00B9D81A",
    color: "#00A3BE",
  },
  needReviewByHead: {
    backgroundColor: "#FBB5001A",
    color: "#E58A00",
  },
  approvedByPrincipal: {
    backgroundColor: "#33C34A1A",
    color: "#33C34A",
  },
  sentToAstra: {
    backgroundColor: "#A735AD1A",
    color: "#A735AD",
  },
  cancelled: {
    backgroundColor: "#FF1A1A1A",
    color: "#D30000",
  },
};

export const ASSIGNMENT_STATUS_CHIP_STYLES: Record<
  AssignmentSchoolStatus,
  StatusColor
> = {
  new: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  inProgress: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  readyForSending: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E8F1",
    color: "#5E7DA0",
  },
  approvedByTeacher: {
    backgroundColor: "#F849931A",
    color: "#F84993",
  },
  needReviewByTeacher: {
    backgroundColor: "#FBB5001A",
    color: "#E58A00",
  },
  approvedByHead: {
    backgroundColor: "#00B9D81A",
    color: "#00A3BE",
  },
  sentToPrincipal: {
    backgroundColor: "#00B9D81A",
    color: "#00A3BE",
  },
  approvedByPrincipal: {
    backgroundColor: "#33C34A1A",
    color: "#33C34A",
  },
  sentToAstra: {
    backgroundColor: "#A735AD1A",
    color: "#A735AD",
  },
  cancelled: {
    backgroundColor: "#f8e4e5",
    color: "#D30000",
  },
};

export const SUPPLIER_PRODUCT_CODE_REGEXP = /^[A-z0-9-.#]*$/i;

export const FILE_TYPES_DN = ["application/pdf"];
export const FILE_TYPES_CO = ["application/pdf"];
export const FILE_TYPES_PO = ["application/pdf"];
export const FILE_TYPES_MO = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const FILE_TYPES_GENERAL_ITEM_IMAGE = [
  "image/png",
  "image/jpeg",
  "image/jpg",
];

export const STANDART_PO_CURRENCY = "QAR";

export const FORGOT_PASSWORD_CODE_LENGTH = 6;

export const SCHOOL_USER_ROLES: Role[] = [
  "principal",
  "teacher",
  "headOfDepartment",
];

export const USER_ROLES_REDIRECT_TO_SCHOOL_INQUIRY = [
  "principal",
  "teacher",
  "headOfDepartment",
];

export const MAX_PRODUCTS_IN_SHOP = 1000;

export const ROLES_WITH_AVAILABLE_HINTS: Role[] = [
  "teacher",
  "principal",
  "headOfDepartment",
];
