import React, { useCallback } from "react";
import { Alert, Collapse, Paper, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PostCodeForgotPasswordDto } from "../../../slices/auth/types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fieldError,
  onFieldChange,
  useSubmitFormErrorHander,
} from "../../../utils/formHelpers";
import { useForgotPasswordCodeMutation } from "../../../slices/auth/api";
import {
  FormHelperTextProps,
  InputLabelProps,
  NewDesignInputProps,
  StyledFieldTitle,
  StyledHelpBox,
  StyledSubmitSpinnerButton,
  StyledTitleBox,
} from "../StyledComponents";
import { BackToSignIn } from "./BackToSignIn";
import { useWindowSize } from "usehooks-ts";

const codeFormSchema = yup.object({
  code: yup
    .string()
    .matches(/^\d+$/, i18n.t("incorrectCode", { ns: "validationErrors" }))
    .min(6, i18n.t("incorrectCode", { ns: "validationErrors" }))
    .max(6, i18n.t("incorrectCode", { ns: "validationErrors" })),
});

export const CodeForm: React.FC<{
  email: string;
  setCode: (code: string) => void;
}> = ({ email, setCode }) => {
  const { t } = useTranslation("pages");
  const size = useWindowSize();

  const { control, handleSubmit } = useForm<PostCodeForgotPasswordDto>({
    resolver: yupResolver(codeFormSchema),
  });

  const [forgotPasswordCode, { isLoading }] = useForgotPasswordCodeMutation();
  const { catchFormError, formError, fieldsErrors, setFieldsErrors } =
    useSubmitFormErrorHander(t("error.signIn", { ns: "legacy" }));

  const setCodeFormInput = useCallback((code) => setCode(code), [setCode]);

  const onSubmit = useCallback(
    (fields: PostCodeForgotPasswordDto) => {
      forgotPasswordCode({ email, code: fields.code })
        .unwrap()
        .then(() => {
          setCodeFormInput(fields.code);
        })
        .catch((error: unknown) => {
          catchFormError(error);
        });
    },
    [catchFormError, email, forgotPasswordCode, setCodeFormInput]
  );

  return (
    <Paper elevation={0}>
      <Stack
        spacing={4}
        sx={{
          marginTop: `${size.height < 556 ? "20" : (size.height - 516) / 2}px`,
          marginBottom: `${size.height < 556 ? "20" : "0"}px`,
          paddingTop: "65px",
          paddingInline: "110px",
          paddingBottom: "120px",
          border: "solid 1px #E7F1FF",
          borderRadius: "4px",
        }}
        component="form"
        autoComplete="on"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={0} sx={{ marginTop: "30px" }}>
          <StyledTitleBox>{t("forgotpassword.enterCode")}</StyledTitleBox>
          <StyledHelpBox sx={{ marginTop: "13px", marginBottom: "6px" }}>
            {t("forgotpassword.sendCodeMessage", { email })}
          </StyledHelpBox>
        </Stack>
        {formError && (
          <Collapse in={!!formError}>
            <Alert severity="error">{formError}</Alert>
          </Collapse>
        )}
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {t("forgotpassword.code")}
        </StyledFieldTitle>
        <Controller
          name="code"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "code",
              fieldsErrors
            );
            return (
              <TextField
                sx={{ marginTop: "0px !important" }}
                variant="outlined"
                size="small"
                placeholder={t("forgotpassword.code")}
                {...field}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                onChange={(args) => (
                  onFieldChange("code", fieldsErrors, setFieldsErrors),
                  field.onChange(args)
                )}
                InputLabelProps={InputLabelProps}
                InputProps={NewDesignInputProps}
                FormHelperTextProps={FormHelperTextProps}
              />
            );
          }}
        />
        <StyledSubmitSpinnerButton
          loading={isLoading}
          fullWidth
          sx={{ marginTop: "-16px" }}
        >
          {t("submit", { ns: "legacy" })}
        </StyledSubmitSpinnerButton>
        <BackToSignIn />
      </Stack>
    </Paper>
  );
};
