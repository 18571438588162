import React from "react";
import { CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { backgroundImage, theme } from "../../theme/mui/theme";
import { SnackbarProvider } from "notistack";
import { GlobalContainer } from "../../components/layout/GlobalContainer";
import { Header } from "../../components/legacyLayout/Header";
import { useSelector } from "../../store";
import { useDrawerOpen } from "../../utils/useDrawerOpen";
import { isAuthenticated } from "../../slices/auth/selectors";
import { ChangeSelfPasswordDrawer } from "../../components/legacyLayout/CurrentUser/ChangeSelfPasswordDrawer";

type MainProps = {
  children: React.ReactNode;
};

export const DeprecatedLayout = ({ children }: MainProps) => {
  const authenticated = useSelector(isAuthenticated);
  const { drawerOpenState, openDrawer, closeDrawer } = useDrawerOpen();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <GlobalContainer
          sx={{
            backgroundImage,
            paddingLeft: drawerOpenState && authenticated ? "240px" : 0,
          }}
        >
          <Header
            authenticated={authenticated}
            drawerOpenState={drawerOpenState}
            openDrawer={openDrawer}
            closeDrawer={closeDrawer}
          />
          <Toolbar />
          {children}
          <ChangeSelfPasswordDrawer />
        </GlobalContainer>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
