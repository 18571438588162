import { Assignment } from "../../entities";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useSelector } from "../../store";
import { selectSelf } from "../auth/selectors";
import {
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
} from "../../types";

export const useDisplayAssignmentSchoolStatus = () => {
  const { t } = useTranslation("common");
  const self = useSelector(selectSelf);

  return useCallback(
    (assignment: Pick<Assignment, "schoolStatus" | "user">) => {
      if (!self) {
        return "";
      }

      if (
        self.role === "headOfDepartment" &&
        assignment.user.id === self.id &&
        assignment.schoolStatus === "readyForSending"
      ) {
        return t(`alternativeSchoolStatuses.readyForApproval`);
      }

      return t(`assignmentSchoolStatuses.${assignment.schoolStatus}`);
    },
    [self, t]
  );
};

/**
 * All action possibility checks need a rewrite, so they're only checked
 * on the server.
 */
export const usePreActionCheck = () => {
  return useCallback(
    (
      inquirySchoolStatus: InquirySchoolStatus,
      departmentInquirySchoolStatus?: DepartmentInquirySchoolStatus
    ) => {
      return (
        inquirySchoolStatus === "sentToAstra" ||
        inquirySchoolStatus === "cancelled" ||
        departmentInquirySchoolStatus === "cancelled"
      );
    },
    []
  );
};
