import React from "react";
import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { useGetCommoditycodesQuery } from "../../slices/commoditycodes/api";
import { PaperPageSelect } from "../layout/PaperPage";
import { useTranslation } from "react-i18next";

type SelectCommodityCodeProps = {
  label: string;
  autocompleteProps: {
    onChange: (item: number | null) => void;
    value: number;
  };
  textFieldProps: Omit<TextFieldProps, "label" | "placeholder">;
  helperText?: string;
};

export const SelectCommodityCode = ({
  label,
  autocompleteProps,
  textFieldProps,
}: SelectCommodityCodeProps) => {
  const { t } = useTranslation();
  const { data = [], isLoading } = useGetCommoditycodesQuery();

  return (
    <Autocomplete
      PaperComponent={PaperPageSelect}
      loading={isLoading}
      loadingText={t("loading", { ns: "common" })}
      options={data.map((code) => code.id)}
      noOptionsText={t("emptySelect", { ns: "common" })}
      getOptionLabel={(option) => {
        const commodityCode = data.find(
          (commoditycode) => commoditycode.id === option
        );

        if (!commodityCode) {
          return "";
        }

        return commodityCode.description
          ? `${commodityCode.code} (${commodityCode.description})`
          : commodityCode.code;
      }}
      renderInput={(params) => (
        <TextField
          label={label}
          placeholder="Favorites"
          {...params}
          {...textFieldProps}
        />
      )}
      onChange={(event, item) => {
        autocompleteProps.onChange(item);
      }}
      value={autocompleteProps.value || -1}
    />
  );
};
