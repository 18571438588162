import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { ScrollButton } from "./ScrollButton";

type SliderProps = {
  children: React.ReactNode;
};

/**
 * Reimplemented SmallTabs. Could be a shared component potentially.
 */
export const Slider = ({ children }: SliderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [showLeftArrow, setShowLeftArrow] = useState(true);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const calculateButtons = useCallback(() => {
    const container = ref.current;

    if (!container) {
      return;
    }

    const width = container.offsetWidth;
    const scrollWidth = container.scrollWidth;
    const offsetX = container.scrollLeft;

    setShowLeftArrow(offsetX > 0);
    setShowRightArrow(width + offsetX !== scrollWidth);
  }, []);

  useLayoutEffect(() => {
    const container = ref.current;

    if (container) {
      const observer = new ResizeObserver(() => {
        calculateButtons();
      });

      observer.observe(container);
      return () => observer.unobserve(container);
    }
  }, [calculateButtons]);

  const scrollHandler = useCallback(() => {
    calculateButtons();
  }, [calculateButtons]);

  const getChildrenOffsets = useCallback(
    (container: HTMLDivElement) =>
      Array.from(container.childNodes as NodeListOf<HTMLElement>).reduce<
        Array<{
          width: number;
          offset: number;
        }>
      >((accumulator, child, index) => {
        if (index === 0) {
          return [
            {
              width: child.offsetWidth,
              offset: 0,
            },
          ];
        }

        const previousOffset = accumulator[accumulator.length - 1];

        return [
          ...accumulator,
          {
            width: child.offsetWidth,
            offset: previousOffset.width + previousOffset.offset,
          },
        ];
      }, []),
    []
  );

  const leftClickHandler = useCallback(() => {
    const container = ref.current;

    if (!container) {
      return;
    }

    const childrenOffsets = getChildrenOffsets(container).reverse();
    const offsetX = container.scrollLeft;

    for (const { offset } of childrenOffsets) {
      if (offset < offsetX - 10) {
        container.scrollTo({
          left: offset,
          behavior: "smooth",
        });
        break;
      }
    }
  }, [getChildrenOffsets]);

  const rightClickHandler = useCallback(() => {
    const container = ref.current;

    if (!container) {
      return;
    }

    const childrenOffsets = getChildrenOffsets(container);
    const offsetX = container.scrollLeft;

    for (const { offset } of childrenOffsets) {
      if (offset > offsetX + 10) {
        container.scrollTo({
          left: offset,
          behavior: "smooth",
        });
        break;
      }
    }
  }, [getChildrenOffsets]);

  const buttonsVisible = showLeftArrow || showRightArrow;

  return (
    <Box
      sx={{
        height: "94px",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "secondary.light",
        display: "flex",
        position: "relative",
        paddingLeft: buttonsVisible ? "40px" : 0,
        paddingRight: buttonsVisible ? "40px" : 0,
        overflow: "hidden",
      }}
    >
      {buttonsVisible && (
        <ScrollButton
          position="left"
          visible={showLeftArrow}
          onClick={leftClickHandler}
        />
      )}
      <Box
        ref={ref}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          overscrollBehaviorX: "auto",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
        }}
        onScroll={scrollHandler}
      >
        {children}
      </Box>
      {buttonsVisible && (
        <ScrollButton
          position="right"
          visible={showRightArrow}
          onClick={rightClickHandler}
        />
      )}
    </Box>
  );
};
