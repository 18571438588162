import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { useSchoolInquiryItemsTools } from "../../useSchoolInquiryItemsTools";
import { EnhancedSkeleton } from "../../../../components/EnhancedSkeleton";
import { SelectList } from "./SelectList";

export const CurrentList = () => {
  const {
    inquiryResult: { data: inquiry, isLoading: isInquiryLoading },
    departmentInquiryId,
  } = useSchoolInquiryItemsTools();

  const departmentInquiry = useMemo(() => {
    return inquiry?.departmentinquiries.find(
      (departmentInquiry) => departmentInquiry.id === departmentInquiryId,
      []
    );
  }, [departmentInquiryId, inquiry]);

  const skeletons = useMemo(() => {
    if (!isInquiryLoading) {
      return null;
    }

    return (
      <>
        <EnhancedSkeleton
          variant="text"
          width={[100, 150]}
          sx={{ lineHeight: "23px" }}
        />
        <EnhancedSkeleton
          variant="rectangular"
          width={[230, 300]}
          sx={{ height: "44px" }}
        />
      </>
    );
  }, [isInquiryLoading]);

  const content = useMemo(() => {
    if (!inquiry || !departmentInquiry) {
      return null;
    }

    return (
      <>
        <Typography
          component="span"
          sx={{
            color: colors.darkBlue,
            fontSize: "22px",
            lineHeight: "23px",
            fontWeight: "500",
          }}
        >
          {departmentInquiry.department.name}
        </Typography>
        <SelectList />
      </>
    );
  }, [departmentInquiry, inquiry]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {skeletons}
      {content}
    </Box>
  );
};
