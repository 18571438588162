import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

type Block = {
  xs: number;
  label?: string;
};

type TextBlock = Block & {
  value: string;
};

type ComponentBlock = Block & {
  view: React.ReactNode;
};

type Row =
  | Array<(TextBlock | ComponentBlock) & { style?: "body1" | "body2" }>
  | "divider";

type EntityDetailsGridProps = {
  blocks: Row[];
};
/*
 * This is component for old design. Replace with NewDesignEntityDetailsGrid if possible
 * @deprecated
 */
export const EntityDetailsGrid = ({ blocks }: EntityDetailsGridProps) => (
  <Grid container spacing={2}>
    {blocks.map((row, i) => {
      if (row === "divider") {
        return (
          <Grid item xs={12} key={`devider-${i}`}>
            <Divider />
          </Grid>
        );
      }

      return row.map(({ xs, label, style, ...block }, index) => {
        if ("view" in block) {
          return (
            <Grid key={index} item xs={xs}>
              {label && <Typography variant="subtitle2">{label}</Typography>}
              {block.view}
            </Grid>
          );
        }

        return (
          <Grid key={label} item xs={xs}>
            {label && (
              <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
                {label}
              </Typography>
            )}
            <Typography variant={style || "body2"}>{block.value}</Typography>
          </Grid>
        );
      });
    })}
  </Grid>
);
