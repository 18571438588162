import React, { useCallback, useMemo } from "react";
import { Button, styled } from "@mui/material";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useSelector } from "../../../../../store";
import {
  useGetAssignmentQuery,
  useSendAssignmentToHeadMutation,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { colors } from "../../../../../theme/astra/colors";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";

export const SendToHead = () => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const {
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult: { data: inquiry },
  } = useSchoolInquiryItemsTools();

  const { data: assignment } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const preActionCheck = usePreActionCheck();

  const disabled = useMemo(() => {
    if (!assignment || !self || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    return (
      assignment.schoolStatus !== "readyForSending" &&
      !(
        assignment.schoolStatus === "needReviewByTeacher" &&
        assignment.items.every(
          ({ schoolStatus }) =>
            schoolStatus === "approvedByTeacher" ||
            schoolStatus === "approvedByHead" ||
            schoolStatus === "approvedByPrincipal" ||
            schoolStatus === "cancelled"
        )
      )
    );
  }, [assignment, inquiry, preActionCheck, self]);

  const help = useMemo(() => {
    if (!assignment || !self || !inquiry) {
      return false;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return false;
    }

    return (
      assignment.schoolStatus === "new" ||
      assignment.schoolStatus === "inProgress" ||
      (assignment.schoolStatus === "needReviewByTeacher" &&
        !assignment.items.every(
          ({ schoolStatus }) =>
            schoolStatus === "approvedByTeacher" ||
            schoolStatus === "approvedByHead" ||
            schoolStatus === "approvedByPrincipal" ||
            schoolStatus === "cancelled"
        ))
    );
  }, [assignment, inquiry, preActionCheck, self]);

  const [sendAssignmentToHead, { isLoading: isSending }] =
    useSendAssignmentToHeadMutation();

  const sendToHeadClickHandler = useCallback(() => {
    if (disabled || help) {
      return;
    }

    if (departmentInquiryId && assignmentId) {
      sendAssignmentToHead({
        inquiryId,
        departmentInquiryId,
        assignmentId,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    assignmentId,
    departmentInquiryId,
    disabled,
    handleError,
    handleSuccess,
    help,
    inquiryId,
    sendAssignmentToHead,
  ]);

  if (
    !assignment ||
    self?.role !== "teacher" ||
    assignment.user.id !== self?.id
  ) {
    return null;
  }

  return (
    <ConfirmationButton
      component={SendToAstraButton}
      onClick={help ? undefined : sendToHeadClickHandler}
      proceedButtonText={help ? "Ok" : undefined}
      variant="contained"
      disabled={disabled && !help}
      confirmationTitle={
        help
          ? t(
              "schoolInquiryDetails.assignmentControls.sendToHead.approveAllFirst"
            )
          : t(
              "schoolInquiryDetails.assignmentControls.sendToHead.confirmationTitle"
            )
      }
      confirmationDescription={
        help
          ? t(
              "schoolInquiryDetails.assignmentControls.sendToHead.approveItemsFirst"
            )
          : t("noUndo", { ns: "common" })
      }
      loading={isSending}
      sx={{
        textTransform: "capitalize",
      }}
    >
      {t("schoolInquiryDetails.assignmentControls.sendToHead.label")}
    </ConfirmationButton>
  );
};

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});
