import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import {
  useGetAssignmentQuery,
  useSendAssignmentToReviewMutation,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { Button } from "@mui/material";
import { useSelector } from "../../../../../store";
import { Hint } from "../../../../../components/hints/Hint";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";

export const NeedReview = () => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const {
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult: { data: inquiry },
  } = useSchoolInquiryItemsTools();

  const { data: assignment } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const preActionCheck = usePreActionCheck();

  const disabled = useMemo(() => {
    if (!self || !assignment || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    return (
      assignment.schoolStatus !== "approvedByTeacher" &&
      assignment.schoolStatus !== "approvedByHead"
    );
  }, [assignment, inquiry, preActionCheck, self]);

  const [sendToReview, { isLoading: isSending }] =
    useSendAssignmentToReviewMutation();

  const sendToReviewHandler = useCallback(() => {
    if (disabled) {
      return;
    }

    if (departmentInquiryId && assignmentId) {
      sendToReview({
        inquiryId,
        departmentInquiryId,
        assignmentId,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    disabled,
    departmentInquiryId,
    assignmentId,
    sendToReview,
    inquiryId,
    handleSuccess,
    handleError,
  ]);

  if (self?.role !== "headOfDepartment") {
    return null;
  }

  return (
    <Hint
      helpText={
        assignment?.user.id === self?.id
          ? t("hints.schoolInquiryDetails.assignmentControls.needReviewSelf")
          : t("hints.schoolInquiryDetails.assignmentControls.needReview")
      }
    >
      <ConfirmationButton
        component={Button}
        variant="outlined"
        loading={isSending}
        onClick={sendToReviewHandler}
        confirmationTitle={t(
          "schoolInquiryDetails.assignmentControls.sendToReview.confirmationTitle"
        )}
        confirmationDescription={t("noUndo", { ns: "common" })}
        disabled={disabled}
        sx={{
          textTransform: "capitalize",
          fontSize: "13px",
        }}
      >
        {t("schoolInquiryDetails.assignmentControls.sendToReview.label")}
      </ConfirmationButton>
    </Hint>
  );
};
