import React from "react";
import { Box, Typography } from "@mui/material";

type BlockProps = {
  label: string;
  children?: React.ReactNode;
};

export const Block = ({ label, children }: BlockProps) => {
  return (
    <Box>
      <Typography
        component="h5"
        sx={{
          marginBottom: "12px",
          fontSize: "12px",
          lineHeight: "24px",
          fontWeight: "400",
          color: "primary.dark",
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};
