import { useParams } from "react-router-dom";

type ShopParams = {
  l1category?: string;
  l2category?: string;
  l3category?: string;
  productId?: string;
};

export const useShopCatalogUrlTools = () => {
  const {
    l1category: l1,
    l2category: l2,
    l3category: l3,
    productId,
  } = useParams<ShopParams>();

  const baseUrl =
    "/shop" +
    (l1 ? `/${l1}` : "") +
    (l2 ? `/${l2}` : "") +
    (l3 ? `/${l3}` : "") +
    (productId ? `/product/${productId}` : "");

  const categoriesIds: number[] = [];

  if (l1) {
    categoriesIds.push(parseInt(l1));
  }
  if (l2) {
    categoriesIds.push(parseInt(l2));
  }
  if (l3) {
    categoriesIds.push(parseInt(l3));
  }

  const level = categoriesIds.length;
  const currentCategoryId = level > 0 ? categoriesIds[level - 1] : null;

  return {
    baseUrl,
    currentCategoryId,
  };
};
