import React from "react";
import { createSvgIcon } from "@mui/material";

export const Edit = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9875 5.17675C16.304 4.49333 15.196 4.49333 14.5126 5.17675L5.21968 14.4696C5.12106 14.5683 5.0519 14.6924 5.01995 14.8282L4.01995 19.0782C3.96046 19.331 4.03602 19.5966 4.21968 19.7803C4.40335 19.964 4.66896 20.0395 4.92179 19.98L9.17179 18.98C9.30756 18.9481 9.43172 18.8789 9.53034 18.7803L18.8232 9.48741C19.5067 8.80399 19.5067 7.69595 18.8232 7.01253L18.316 7.51981L18.8232 7.01253L16.9875 5.17675ZM15.5732 6.23741C15.6709 6.13978 15.8292 6.13978 15.9268 6.23741L17.7626 8.07319C17.8602 8.17083 17.8602 8.32912 17.7626 8.42675L8.62055 17.5688L5.75757 18.2424L6.43121 15.3794L15.5732 6.23741L15.0429 5.70708L15.5732 6.23741ZM13.75 18.5C13.3358 18.5 13 18.8358 13 19.25C13 19.6642 13.3358 20 13.75 20H19.25C19.6642 20 20 19.6642 20 19.25C20 18.8358 19.6642 18.5 19.25 18.5H13.75Z"
      fill="currentColor"
    />
  </svg>,
  "Edit"
);
