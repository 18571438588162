import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import {
  AddCountryRequestDto,
  EditCountryRequestDto,
} from "../../../slices/countries/types";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { usePermissions } from "../../../utils/usePermissions";
import { createYupSchema } from "../../../utils/validation";

export const countryFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    name: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    code: yup
      .string()
      .min(2, t("tooShort", { minLength: 2 }))
      .max(3, t("tooLong", { maxLength: 3 }))
      .required(t("required")),
    active: yup.boolean(),
  })
);

type CountryFormProps = {
  countryData?: EditCountryRequestDto;
  fieldsErrors?: FieldsErrorState;
  isActiveCheckboxShown?: boolean;
};

export const CountryForm: React.FC<CountryFormProps> = ({
  countryData,
  fieldsErrors,
  isActiveCheckboxShown = false,
}) => {
  const { t } = useTranslation();
  const can = usePermissions();
  const { register, control } = useFormContext<
    AddCountryRequestDto | EditCountryRequestDto
  >();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {countryData && (
        <input
          type="hidden"
          defaultValue={countryData.id}
          {...register("id", {
            valueAsNumber: true,
          })}
        />
      )}
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          defaultValue={countryData && countryData.name}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="code"
          defaultValue={countryData && countryData.code}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "code",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("code")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      {isActiveCheckboxShown && (
        <Grid item gridColumn="span 3">
          <Controller
            control={control}
            name="active"
            defaultValue={countryData ? countryData.active : true}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={countryData ? countryData.active : true}
                  />
                }
                disabled={!can("changeCountryActive")}
                label={t("countries.activeStatus") as string}
                {...field}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
