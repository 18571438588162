import React from "react";
import { Typography } from "@mui/material";

type DescriptionProps = {
  description?: string | null;
};

export const Description = ({ description }: DescriptionProps) => {
  if (!description || description.length === 0) {
    return null;
  }

  return (
    <Typography
      component="span"
      sx={{
        display: "block",
        marginTop: "32px",
        fontSize: "14px",
        lineHeight: "24px",
        color: "primary.dark",

        "p:first-child": {
          marginTop: 0,
        },

        "p:last-child": {
          marginBottom: 0,
        },
      }}
      dir="auto"
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
  );
};
