import React, { useCallback } from "react";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingTextField } from "../../../components/LoadingTextField";
import { displayFullName } from "../../../utils/displayFullName";
import { Role } from "../../../permissions/permissions";
import { User } from "../../../entities";

type SelectSchoolUserProps = {
  setUserId: (id: number) => void;
  setUserRole: (role: Role) => void;
  data: User[];
  isLoading: boolean;
};

export const SelectSchoolUser = ({
  data,
  isLoading,
  value = "",
  setUserId,
  setUserRole,
  ...props
}: Omit<TextFieldProps, "select"> & SelectSchoolUserProps) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    (event) => {
      const selectedUser = data.find(
        (user) => user.id === Number(event?.target?.value)
      );

      if (selectedUser) {
        setUserId(selectedUser.id);
        setUserRole(selectedUser.role);
      }
    },
    [data, setUserId, setUserRole]
  );

  return (
    <LoadingTextField
      select
      loading={isLoading}
      value={value}
      onChange={onChange}
      {...props}
    >
      {data.map(({ id, firstName, lastName, role }) => (
        <MenuItem key={id} value={id}>
          {`(${role}) ${displayFullName({ firstName, lastName })}`}
        </MenuItem>
      ))}
      {data.length < 1 && (
        <MenuItem key={"empty-list"} disabled>
          {t("emptySelect", { ns: "common" })}
        </MenuItem>
      )}
    </LoadingTextField>
  );
};
