import React from "react";
import { createSvgIcon } from "@mui/material";

export const CheckboxChecked = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.16663"
      y="1.16666"
      width="13.6667"
      height="13.6667"
      rx="3.5"
      fill="white"
      stroke="#5E7DA0"
    />
    <path
      d="M12.4733 4.80667C12.4114 4.74418 12.3376 4.69458 12.2564 4.66074C12.1752 4.62689 12.088 4.60947 12 4.60947C11.912 4.60947 11.8249 4.62689 11.7436 4.66074C11.6624 4.69458 11.5886 4.74418 11.5267 4.80667L6.56001 9.78L4.47334 7.68667C4.40899 7.62451 4.33303 7.57563 4.2498 7.54283C4.16656 7.51003 4.07768 7.49394 3.98822 7.49549C3.89877 7.49703 3.8105 7.51619 3.72844 7.55185C3.64639 7.58751 3.57217 7.63898 3.51001 7.70333C3.44785 7.76768 3.39897 7.84364 3.36617 7.92688C3.33337 8.01011 3.31728 8.099 3.31883 8.18845C3.32038 8.2779 3.33953 8.36618 3.37519 8.44823C3.41085 8.53028 3.46233 8.60451 3.52667 8.66667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3387 6.30362 11.3726C6.38486 11.4064 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4064 6.81639 11.3726C6.89763 11.3387 6.97137 11.2892 7.03334 11.2267L12.4733 5.78667C12.541 5.72424 12.595 5.64847 12.632 5.56414C12.6689 5.4798 12.688 5.38873 12.688 5.29667C12.688 5.2046 12.6689 5.11353 12.632 5.02919C12.595 4.94486 12.541 4.86909 12.4733 4.80667Z"
      fill="#164173"
    />
  </svg>,
  "CheckboxChecked"
);

export const CheckboxHalfchecked = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.16663"
      y="1.16666"
      width="13.6667"
      height="13.6667"
      rx="3.5"
      fill="white"
      stroke="#5E7DA0"
    />
    <path
      d="M11.3333 7.33334H4.66667C4.48986 7.33334 4.32029 7.40358 4.19526 7.52861C4.07024 7.65363 4 7.8232 4 8.00001C4 8.17682 4.07024 8.34639 4.19526 8.47141C4.32029 8.59644 4.48986 8.66668 4.66667 8.66668H11.3333C11.5101 8.66668 11.6797 8.59644 11.8047 8.47141C11.9298 8.34639 12 8.17682 12 8.00001C12 7.8232 11.9298 7.65363 11.8047 7.52861C11.6797 7.40358 11.5101 7.33334 11.3333 7.33334Z"
      fill="#164173"
    />
  </svg>,
  "CheckboxHalfchecked"
);

export const CheckboxUnchecked = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.16663"
      y="1.16667"
      width="13.6667"
      height="13.6667"
      rx="3.5"
      fill="white"
      stroke="#5E7DA0"
    />
  </svg>,
  "CheckboxUnchecked"
);
