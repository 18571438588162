import React, { memo } from "react";

export type DisplayDateTimeProps = {
  timestamp?: string | number | Date;
};

export const DisplayDateWithoutTime = memo<DisplayDateTimeProps>(
  ({ timestamp }) => {
    if (!timestamp) {
      return null;
    }

    const date = timestamp ? new Date(timestamp) : new Date();

    const year = date.getFullYear();
    const month = date
      .toLocaleString("en-US", { month: "long" })
      .substring(0, 3);
    const day = date.getDate();

    return (
      <>
        {year}-{month}-{day}
      </>
    );
  }
);
