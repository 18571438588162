import React, { useCallback } from "react";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconButton } from "../../../../../components/astraComponents/IconButton";
import { Trash } from "../../../../../components/astraIcons/Trash";
import { useRemoveFromCartMutation } from "../../../../../slices/shop/api";
import { useSelector } from "../../../../../store";
import { selectShopList } from "../../../../../slices/shop/selectors";
import { useCurrentAssignmentId } from "../../../../../slices/shop/hooks";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";

type RemoveProps = {
  id: number;
  quantityInCart: number;
};

export const Remove = ({ id, quantityInCart }: RemoveProps) => {
  const theme = useTheme();
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const { inquiryId, departmentId } = useSelector(selectShopList);
  const assignmentId = useCurrentAssignmentId();

  const [removeFromCart] = useRemoveFromCartMutation();

  const removeClickHandler = useCallback(() => {
    removeFromCart({
      inquiryId,
      departmentInquiryId: departmentId,
      assignmentId,
      productId: id,
      quantity: quantityInCart,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentId,
    departmentId,
    handleError,
    handleSuccess,
    id,
    inquiryId,
    quantityInCart,
    removeFromCart,
  ]);

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${theme.palette.primary.dark}`,
          color: theme.palette.primary.dark,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
          }}
        >
          {t("shopCatalog.list.added")}
        </Typography>
      </Box>
      <IconButton
        onClick={removeClickHandler}
        variant="outlined"
        size="small"
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "57px",
          marginLeft: "-1px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderColor: theme.palette.primary.dark,
          backgroundColor: "transparent",
          color: theme.palette.primary.dark,

          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.dark, 0.1),
          },

          "&:active": {
            backgroundColor: alpha(theme.palette.primary.dark, 0.25),
          },
        }}
      >
        <Trash />
      </IconButton>
    </>
  );
};
