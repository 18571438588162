import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Cell } from "./Cell";
import { colors } from "../../../../../theme/astra/colors";
import { ReactComponent as WalletIcon } from "../../../../../assets/newDesignItems/wallet.svg";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../../../../../utils/useDisplayPrice";
import { useSelector } from "../../../../../store";
import { selectSelfSchoolCurrency } from "../../../../../slices/auth/selectors";

type BudgetProps = {
  spentBudget: number | null;
  assignedBudget: number | null;
  itemsWithoutPrice?: number | null;
};

export const Budget = ({
  spentBudget,
  assignedBudget,
  itemsWithoutPrice,
}: BudgetProps) => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();

  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const currency: string =
    selfCurrency?.code ?? t("currencies.qar", { ns: "common" });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <Box
        sx={{
          border: "1px solid #E0E8F1",
          borderRadius: "50px",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "16px",
        }}
      >
        <WalletIcon />
      </Box>
      <Cell
        label={t("schoolInquiries.spentBudget", { ns: "pages" })}
        value={displayPrice(spentBudget) ?? "0"}
        currency={currency}
      />
      {itemsWithoutPrice && itemsWithoutPrice > 0 ? (
        <>
          <Typography
            component="span"
            sx={{
              color: colors.darkBlue,
              fontSize: "12px",
              lineHeight: "20px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            +
          </Typography>
          <Cell
            label={t("schoolInquiryDetails.itemsWithoutPrice", {
              count: itemsWithoutPrice,
            })}
            value={`${itemsWithoutPrice}`}
          />
        </>
      ) : null}
      <Divider
        orientation="vertical"
        sx={{
          height: "40px",
          borderColor: colors.dustBlue5,
          marginLeft: "16px",
          marginRight: "16px",
        }}
      />
      <Cell
        label={t("schoolInquiries.assignedBudget", { ns: "pages" })}
        value={displayPrice(assignedBudget) ?? t("na", { ns: "common" })}
        currency={currency}
      />
    </Box>
  );
};
