import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActiveShoppingList } from "./ActiveShoppingList";
import { Title } from "./Title";
import { Block } from "./Block";
import { useIsCartDisabled, useShopList } from "../../../slices/shop/hooks";
import { ChangeList } from "./ChangeList/ChangeList";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { useSelector } from "../../../store";
import { selectSelfSchoolCurrency } from "../../../slices/auth/selectors";
import { useDisplayPrice } from "../../../utils/useDisplayPrice";

export const CurrentList = () => {
  const { t } = useTranslation("pages");
  const displayDate = useDisplayDateTime(false);
  const diaplayPrice = useDisplayPrice();

  const list = useShopList();
  const isCartDisabled = useIsCartDisabled();
  const schoolCurrency = useSelector(selectSelfSchoolCurrency);

  if (isCartDisabled) {
    return null;
  }

  const na = t("na", { ns: "common" });

  return (
    <Box sx={{ marginTop: "40px" }}>
      <ActiveShoppingList />
      <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
        <Title
          inquiry={list?.inquiry?.name ?? na}
          department={list?.department?.name ?? na}
        />
        <Block
          label={t("shopCatalog.currentList.dueDate")}
          value={
            list?.inquiry?.dueDate ? displayDate(list.inquiry.dueDate) : na
          }
        />
        <Block
          label={t("shopCatalog.currentList.budget")}
          value={`${
            list?.department?.budget ? diaplayPrice(list.department.budget) : na
          } ${
            schoolCurrency
              ? schoolCurrency?.code?.toUpperCase()
              : t("currencies.qar", {
                  ns: "common",
                })
          }`}
        />
        <ChangeList />
      </Box>
    </Box>
  );
};
