import React, { useCallback } from "react";
import { SchoolLevelsProps } from "../SchoolLevels";
import { useTranslation } from "react-i18next";
import { FilterChip } from "./FilterChip";
import { SchoolLevel } from "../../../../slices/products/types";

export const SchoolLevelsChip = ({ value, onChange }: SchoolLevelsProps) => {
  const { t } = useTranslation("pages");

  const removeHandler = useCallback(() => {
    const newValue = { ...value };

    for (const key of Object.keys(newValue)) {
      newValue[key as SchoolLevel] = false;
    }

    onChange(newValue);
  }, [onChange, value]);

  const values = Object.entries(value)
    .filter(([, value]) => value)
    .map(([name]) => t(`schoolLevel.${name}`, { ns: "common" }));

  if (values.length === 0) {
    return null;
  }

  return (
    <FilterChip
      name={`${t("shopCatalog.filters.schoolLevels")}:`}
      value={values.join(", ")}
      onRemove={removeHandler}
    />
  );
};
