import React from "react";
import { Box, styled } from "@mui/material";
import { Dotted } from "../spinners/Dotted";

export const Spinner = () => (
  <SpinnerBox>
    <Dotted />
  </SpinnerBox>
);

const SpinnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  padding: theme.spacing(2),
}));
