import React from "react";
import { useTranslation } from "react-i18next";
import { Control, Controller, useWatch } from "react-hook-form";
import { InventoryEntryForm } from "./types";
import { Box } from "@mui/material";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import { AutocompleteCountry } from "../../../components/prefilledSelectors/AutocompleteCountry";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { SelectSupplier } from "../../../components/prefilledSelectors/SelectSupplier";
import { FreeAutocompleteSupplierCode } from "../../../components/prefilledSelectors/FreeAutocompleteSupplierCode";
import { ControlledNumericField } from "../../../components/controlledFields/ControlledNumericField";
import { ControlledTextField } from "../../../components/controlledFields/ControlledTextField";

type FormProps = {
  fieldsErrors?: FieldsErrorState;
  control: Control<InventoryEntryForm>;
  editing?: boolean;
};

export const Form = ({ fieldsErrors, control, editing }: FormProps) => {
  const { t } = useTranslation("pages");

  const watchSupplierIdSelect = useWatch({
    control,
    name: "supplierId",
  });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 2,
      }}
    >
      <Box sx={{ gridColumn: "span 6" }}>
        <ControlledCustomField
          control={control}
          name="supplierId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <SelectSupplier
              label={`${t("inventory.fields.supplier")} *`}
              error={!!errorMessage}
              helperText={errorMessage}
              fullWidth
              disabled={editing}
              {...field}
            />
          )}
        />
      </Box>
      <Box sx={{ gridColumn: "span 6" }} gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="supplierProductCode"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, onChange } }) => (
            <FreeAutocompleteSupplierCode
              id="item"
              onChange={onChange}
              value={(value as string) ?? ""}
              supplierId={watchSupplierIdSelect}
              error={!!errorMessage}
              helperText={errorMessage}
              disabled={editing}
              label={`${t("inventory.fields.code")} *`}
            />
          )}
        />
      </Box>
      <Box sx={{ gridColumn: "span 12" }}>
        <Controller
          control={control}
          name="countryId"
          render={({
            field: { onChange, value },
            fieldState: { invalid, error },
          }) => {
            const [serverInvalid, serverError] = fieldError(
              "countryId",
              fieldsErrors
            );
            return (
              <AutocompleteCountry
                id="inventory-entry-country-selector"
                label={`${t("inventory.fields.country")} *`}
                onChange={onChange}
                value={value}
                error={invalid ?? serverInvalid}
                helperText={error?.message ?? serverError}
                disabled={editing}
              />
            );
          }}
        />
      </Box>
      <Box sx={{ gridColumn: "span 12" }}>
        <ControlledNumericField
          control={control}
          name="quantity"
          label={`${t("inventory.fields.quantity")} *`}
          fieldsErrors={fieldsErrors}
          positiveOnly
          fullWidth
        />
      </Box>
      <Box sx={{ gridColumn: "span 12" }}>
        <ControlledTextField
          control={control}
          name="comment"
          label={t("inventory.fields.comment")}
          fieldsErrors={fieldsErrors}
        />
      </Box>
    </Box>
  );
};
