import React from "react";
import { Badge, Tooltip } from "@mui/material";
import { useHelpActive } from "../../utils/useHelpActive";

export type HintProps = {
  children: React.ReactNode;
  helpText: string;
  hide?: boolean;
};

export const Hint = ({ helpText, children, hide }: HintProps) => {
  const { helpActiveState } = useHelpActive();

  if (hide) {
    return <>{children}</>;
  }

  return (
    <Badge
      color="default"
      sx={{
        ".MuiBadge-badge": {
          backgroundColor: "#f5f5f5",
          border: "1px solid #263238",
        },
      }}
      invisible={!helpActiveState}
      badgeContent={
        <Tooltip title={helpText}>
          <span
            style={{
              width: "20px",
              height: "20px",
              margin: "-10px",
              padding: "3px 0px 0px 7px",
              cursor: "help",
            }}
          >
            ?
          </span>
        </Tooltip>
      }
    >
      {children}
    </Badge>
  );
};
