import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InquiryFlattened } from "../../../../slices/inquiries/types";
import { fieldError, FieldsErrorState } from "../../../../utils/formHelpers";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { enGB } from "date-fns/locale";
import { EditInquiryDetailsForm } from "./types";
import { SelectInquiryPriority } from "../../../../components/prefilledSelectors/SelectInquiryPriority";
import { SelectSchool } from "../../../../components/prefilledSelectors/SelectSchool";
import { TagsSelectDepartments } from "../../../../components/prefilledSelectors/TagsSelectDepartments";
import { createYupSchema } from "../../../../utils/validation";
import { SelectPrincipal } from "../../../../components/prefilledSelectors/SelectPrincipal";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { SelectInquiryManager } from "../../../../components/prefilledSelectors/SelectInquiryManager";

export const basicDetailsSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    name: yup
      .string()
      .min(1, t("required"))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    priority: yup.string().required(t("required")),
    dueDate: yup.date().required(t("required")).typeError(t("incorrectDate")),
    schoolId: yup.number().min(0, t("required")).required(t("required")),
    departments: yup.array(),
    inquiryManagersIds: yup
      .array()
      .of(yup.number().positive())
      .optional()
      .nullable(),
  })
);

export type BasicDetailsFormProps = {
  inquiryData?: InquiryFlattened;
  fieldsErrors?: FieldsErrorState;
};

/**
 * Needs to be inside <FormProvider/>
 **/
export const BasicDetailsForm: React.FC<BasicDetailsFormProps> = ({
  inquiryData,
  fieldsErrors,
}) => {
  const { t } = useTranslation("pages");
  const { control } = useFormContext<EditInquiryDetailsForm>();
  const schoolId = useWatch({ control, name: "schoolId" });

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 6">
        <Controller
          control={control}
          name="schoolId"
          defaultValue={inquiryData?.school.id}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "schoolId",
              fieldsErrors
            );
            return (
              <SelectSchool
                fullWidth
                label={`${t("inquiries.fields.school")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="userId"
          defaultValue={inquiryData?.user?.id}
          render={(errorMessage, { field }) => (
            <SelectPrincipal
              schoolId={schoolId ?? inquiryData?.school.id}
              fullWidth
              label={t("inquiries.fields.principal")}
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="inquiryManagersIds"
          defaultValue={
            inquiryData
              ? inquiryData.inquiryManagers.map(
                  (inquiryManager) => inquiryManager.id
                )
              : []
          }
          render={(errorMessage, { field }) => (
            <SelectInquiryManager
              fullWidth
              SelectProps={{ multiple: true }}
              error={!!errorMessage}
              helperText={errorMessage}
              label={t("inquiries.fields.inquiryManagers")}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          defaultValue={inquiryData ? inquiryData.name : ""}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("inquiries.fields.name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="priority"
          defaultValue={inquiryData ? inquiryData.priority : "normal"}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "roles",
              fieldsErrors
            );
            return (
              <SelectInquiryPriority
                label={`${t("inquiries.fields.priority")} *`}
                id="add-inquiry"
                size="small"
                selectProps={field}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="dueDate"
          defaultValue={
            inquiryData && inquiryData.dueDate
              ? new Date(inquiryData.dueDate)
              : null
          }
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "dueDate",
              fieldsErrors
            );
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                <DatePicker
                  label={`${t("inquiries.fields.dueDate")} *`}
                  PopperProps={{ placement: "bottom-start" }}
                  renderInput={({ ...params }) => {
                    return (
                      <TextField
                        helperText={error?.message || serverError}
                        fullWidth
                        size="small"
                        {...params}
                        error={invalid || serverInvalid}
                      />
                    );
                  }}
                  {...field}
                />
              </LocalizationProvider>
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="relatedDepartments"
          defaultValue={inquiryData ? inquiryData.relatedDepartments : []}
          render={({
            field: { value, ...field },
            fieldState: { invalid, error },
          }) => {
            const [serverInvalid, serverError] = fieldError(
              "departments",
              fieldsErrors
            );
            return (
              <TagsSelectDepartments
                id="add-inquiry"
                label={`${t("inquiries.fields.departments")}`}
                size="small"
                autocompleteProps={{
                  value: Array.isArray(value) ? value : [],
                  ...field,
                }}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
