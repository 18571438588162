import React, { useCallback } from "react";
import { Box, ClickAwayListener, Divider, useTheme } from "@mui/material";
import { Header } from "./Header";
import { Items } from "./Items/Items";
import { colors } from "../../../theme/astra/colors";
import { Checkout } from "./Checkout";
import { useDispatch, useSelector } from "../../../store";
import { selectIsCartOpen } from "../../../slices/shop/selectors";
import { toggleCart } from "../../../slices/shop/slice";

export const Cart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isCartOpen = useSelector(selectIsCartOpen);

  const clickAwayHandler = useCallback(() => {
    if (isCartOpen) {
      dispatch(toggleCart());
    }
  }, [dispatch, isCartOpen]);

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Box
        sx={{
          position: "fixed",
          right: 0,
          top: "63px",
          height: "calc(100vh - 63px)",
          backgroundColor: "common.white",
          display: "flex",
          alignItems: "stretch",
          flexDirection: "column",
          zIndex: 10001,
          boxShadow: "2px 0px 30px 0px rgba(38, 118, 215, 0.10)",
          clipPath: "inset(0 0 0 -30px)",
          ...(isCartOpen
            ? {
                width: "408px",
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }
            : {
                width: "97px",
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }),
        }}
      >
        <Header />
        <Box
          sx={{
            height: "calc(100% - 95px)",
            flexGrow: 1,
            flexShrink: 1,
            borderLeft: `1px solid ${theme.palette.secondary.light}`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Items />
          <Divider sx={{ borderColor: colors.dustBlue5 }} />
          <Checkout />
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
