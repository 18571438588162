import React from "react";
import { useItemsTableBaseUrl } from "../hooks";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { AssignPurchaseOrder as AssignPurchaseOrderDrawer } from "../../../drawers/AssignPurchaseOrder/AssignPurchaseOrder";

export const AssignPurchaseOrder = () => {
  const { baseUrl, inquiryId } = useItemsTableBaseUrl();
  const { closeDrawer } = useTableUrlTools(baseUrl);

  return (
    <AssignPurchaseOrderDrawer
      inquiryId={inquiryId}
      closeDrawer={closeDrawer}
    />
  );
};
