import { useCallback } from "react";

/**
 * There is no need for it to be a hook right now, but in future we may start
 * needing to change the way prices are rendered without reloading the app.
 */
export const useDisplayPrice = () => {
  return useCallback((price?: number | string | null): string | null => {
    if (!price && price !== 0) {
      return null;
    }

    const parsedPrice = typeof price === "string" ? parseInt(price) : price;

    return Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    })
      .format(parsedPrice / 100)
      .replaceAll(",", " ");
  }, []);
};
