import React, { memo } from "react";
import { ContextMenu } from "../../../components/ContextMenu";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { useDeleteCOMutation } from "../../../slices/commercialOffers/api";
import { useHistory } from "react-router-dom";

type ActionsProps = {
  inquiryId: number;
  coId: number;
};

export const Actions = memo(({ inquiryId, coId }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const history = useHistory();
  const [deleteCO] = useDeleteCOMutation();

  return (
    <ContextMenu
      buttons={[
        {
          label: t("commercialOffers.actions.upload.label"),
          handler: () => {
            history.push(`/inquiries/${inquiryId}/details/co/${coId}/edit`);
          },
          disabled: !can("editCommercialOffer"),
        },
        {
          label: t("commercialOffers.actions.delete.label"),
          handler: () => {
            deleteCO({ id: coId })
              .unwrap()
              .then(() => {
                feedSuccess(t("commercialOffers.successes.deleted"));
              })
              .catch((error) => {
                feedError(
                  error?.data?.message ?? t("commercialOffers.errors.deleted")
                );
              });
          },
          disabled: !can("deleteCommercialOffer"),
          confirmation: {
            title: t("commercialOffers.actions.delete.confirmationTitle"),
            description: t(
              "commercialOffers.actions.delete.confirmationDescription"
            ),
          },
        },
      ]}
    />
  );
});
