import React, { useCallback, useMemo } from "react";
import {
  Box,
  MenuItem,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useSelector } from "../../../../store";
import { selectSelf } from "../../../../slices/auth/selectors";
import { useSchoolInquiryItemsTools } from "../../useSchoolInquiryItemsTools";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../theme/astra/colors";
import { displayFullName } from "../../../../utils/displayFullName";
import {
  ASSIGNMENT_STATUS_CHIP_STYLES,
  DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES,
} from "../../../../consts";
import { NewDesignStatusChip } from "../../../../components/NewDesignStatusChip";
import { useDisplayAssignmentSchoolStatus } from "../../../../slices/schoolInquiries/hooks";
import { useHistory } from "react-router-dom";

export const SelectList = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();

  const self = useSelector(selectSelf);

  const {
    rootUrl,
    inquiryResult: { data: inquiry },
    departmentInquiryId,
    assignmentId,
  } = useSchoolInquiryItemsTools();

  const changeHandler = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (event) => {
      const value = event.target.value;

      if (value === "departmentInquiry") {
        history.push(`${rootUrl}/${departmentInquiryId}/items`);
      }

      if (value.startsWith("assignment")) {
        history.push(
          `${rootUrl}/${departmentInquiryId}/assignments/${parseInt(
            value.replace("assignment-", "")
          )}`
        );
      }
    },
    [departmentInquiryId, history, rootUrl]
  );

  const options = useMemo(() => {
    if (!inquiry) {
      return null;
    }

    const departmentInquiry = inquiry.departmentinquiries.find(
      (departmentInquiry) => departmentInquiry.id === departmentInquiryId
    );

    if (!departmentInquiry) {
      return [];
    }

    const departmentInquiryOption = (
      <Option key="departmentInquiry" value="departmentInquiry">
        <OptionLabel as="span">
          {t("schoolInquiryDetails.listOfAllItems")}
        </OptionLabel>
        <NewDesignStatusChip
          chipStyle={
            DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[
              departmentInquiry.schoolStatus
            ]
          }
          label={t(
            `schoolDepartmentInquiryStatuses.${departmentInquiry.schoolStatus}`,
            {
              ns: "common",
            }
          )}
        />
      </Option>
    );

    const assignmentsOptions = departmentInquiry.assignments.map(
      (assignment) => {
        const id = `assignment-${assignment.id}`;

        return (
          <Option key={id} value={id}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <OptionLabel as="span">
                {displayFullName(assignment.user)}
              </OptionLabel>
              <Typography
                component="span"
                sx={{
                  color: colors.dustBlue1,
                  fontSize: "10px !important",
                  lineHeight: "23px",
                  fontWeight: "400",
                }}
              >
                •
              </Typography>
              <Typography
                component="span"
                sx={{
                  color: colors.dustBlue1,
                  fontSize: "10px !important",
                  lineHeight: "23px",
                  fontWeight: "400",
                }}
              >
                {t(`roles.${assignment.user.role}`, { ns: "common" })}
              </Typography>
            </Box>
            <NewDesignStatusChip
              chipStyle={ASSIGNMENT_STATUS_CHIP_STYLES[assignment.schoolStatus]}
              label={displayAssignmentSchoolStatus({
                schoolStatus: assignment.schoolStatus,
                user: assignment.user,
              })}
            />
          </Option>
        );
      }
    );

    return [departmentInquiryOption, ...assignmentsOptions];
  }, [departmentInquiryId, displayAssignmentSchoolStatus, inquiry, t]);

  if (self?.role !== "headOfDepartment") {
    return (
      <Typography
        component="span"
        sx={{
          color: colors.dustBlue1,
          fontSize: "13px !important",
          lineHeight: "23px",
          fontWeight: "400",
          marginTop: "-4px",
        }}
      >
        {t("schoolInquiryDetails.listOfAllItems")}
      </Typography>
    );
  }

  return (
    <TextField
      select
      size="small"
      onChange={changeHandler}
      value={assignmentId ? `assignment-${assignmentId}` : "departmentInquiry"}
      SelectProps={{ autoWidth: true }}
      sx={{
        ".MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          gap: "20px",
        },
      }}
    >
      {options}
    </TextField>
  );
};

const Option = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
});

const OptionLabel = styled(Typography)({
  color: colors.dustBlue1,
  fontSize: "13px !important",
  lineHeight: "23px",
  fontWeight: "400",
});
