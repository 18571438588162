import React from "react";
import { Control } from "react-hook-form";
import { FieldsErrorState } from "../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import { useGetMainOrdersQuery } from "../../slices/mainOrders/api";
import { Grid, MenuItem, TextField } from "@mui/material";
import { ControlledCustomField } from "../../components/controlledFields/ControlledCustomField";
import { AssignMainOrderForm } from "./AssignMainOrder";

type SelectMainOrderProps = {
  control: Control<AssignMainOrderForm>;
  fieldsErrors?: FieldsErrorState;
  inquiryId: number;
};

export const SelectMainOrder = ({
  control,
  fieldsErrors,
  inquiryId,
}: SelectMainOrderProps) => {
  const { t } = useTranslation("pages");
  const { data } = useGetMainOrdersQuery(inquiryId);

  if (!data) {
    return null;
  }

  return (
    <Grid item gridColumn="span 12">
      <ControlledCustomField
        control={control}
        fieldsErrors={fieldsErrors}
        name="mainOrderId"
        render={(errorMessage, { field: { onChange, value } }) => (
          <TextField
            id="item-main-order"
            label={t("items.fields.mainOrder")}
            select
            fullWidth
            error={!!errorMessage}
            helperText={errorMessage}
            onChange={onChange}
            value={value ?? ""}
          >
            {data.map(({ id, referenceNumber }) => (
              <MenuItem key={id} value={id}>
                {referenceNumber}
              </MenuItem>
            ))}
            {data.length < 1 && (
              <MenuItem key={"empty-list"} disabled>
                {t("emptySelect", { ns: "common" })}
              </MenuItem>
            )}
          </TextField>
        )}
      />
    </Grid>
  );
};
