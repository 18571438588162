import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme/astra/colors";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";

export const InquiryTitle = () => {
  const {
    inquiryResult: { data, isLoading },
  } = useSchoolInquiryItemsTools();

  const skeletons = isLoading && (
    <>
      <EnhancedSkeleton
        variant="text"
        width={[80, 180, "px"]}
        sx={{ lineHeight: "21px" }}
      />
      <EnhancedSkeleton
        variant="text"
        width={[80, 180, "px"]}
        sx={{ lineHeight: "10px" }}
      />
    </>
  );

  const content = data && (
    <>
      <Typography
        component="h1"
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: colors.dustBlue1,
          lineHeight: "21px",
        }}
      >
        {data.name}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: colors.dustBlue2,
          lineHeight: "10px",
        }}
      >
        {data.school.name}
      </Typography>
    </>
  );

  return (
    <Box
      sx={{
        marginTop: "50px",
        marginRight: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {skeletons}
      {content}
    </Box>
  );
};
