import React from "react";
import { createSvgIcon } from "@mui/material";

export const Attachment = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_448_36973)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7086 1.86257C12.7073 0.891355 14.0618 0.345734 15.4742 0.345734C16.8866 0.345734 18.2411 0.891355 19.2398 1.86257C20.2385 2.83378 20.7996 4.15103 20.7996 5.52453C20.7996 6.89803 20.2385 8.21528 19.2398 9.18649L10.0286 18.1442C9.74423 18.4208 9.40658 18.6403 9.03497 18.7901C8.66335 18.9399 8.26504 19.017 7.86278 19.017C7.05037 19.0172 6.27118 18.7035 5.69662 18.1449C5.12205 17.5864 4.79918 16.8288 4.79903 16.0387C4.79888 15.2487 5.12146 14.4909 5.69582 13.9322L14.6318 5.24056L15.7646 6.34219L6.82702 15.0322C6.69118 15.1643 6.58342 15.3212 6.50991 15.4938C6.43639 15.6664 6.39855 15.8514 6.39855 16.0382C6.39855 16.225 6.43639 16.41 6.50991 16.5826C6.58342 16.7552 6.69118 16.912 6.82702 17.0441C6.96286 17.1762 7.12412 17.281 7.3016 17.3525C7.47908 17.424 7.66931 17.4608 7.86142 17.4608C8.05352 17.4608 8.24375 17.424 8.42123 17.3525C8.59871 17.281 8.75998 17.1762 8.89582 17.0441L18.107 8.08642C18.453 7.74999 18.7274 7.35058 18.9146 6.91101C19.1019 6.47144 19.1982 6.00031 19.1982 5.52453C19.1982 5.04874 19.1019 4.57761 18.9146 4.13804C18.7274 3.69847 18.453 3.29907 18.107 2.96263C17.7611 2.6262 17.3504 2.35933 16.8983 2.17725C16.4463 1.99518 15.9619 1.90146 15.4726 1.90146C14.9834 1.90146 14.4989 1.99518 14.0469 2.17725C13.5949 2.35933 13.1842 2.6262 12.8382 2.96263L3.35342 12.1879C2.25313 13.2846 1.64158 14.7588 1.65128 16.2909C1.66098 17.8231 2.29116 19.2898 3.40524 20.3733C4.51933 21.4567 6.02756 22.0695 7.60309 22.0789C9.17861 22.0884 10.6945 21.4937 11.8222 20.4237L21.0334 11.4644L22.1662 12.566L12.9534 21.5237C11.5217 22.866 9.60534 23.6081 7.61671 23.5903C5.62808 23.5725 3.72609 22.7963 2.31996 21.4287C0.913838 20.061 0.115948 18.2113 0.0979669 16.2774C0.0799854 14.3435 0.843349 12.48 2.22382 11.0879L11.7086 1.86101V1.86257Z"
        fill="#164173"
      />
    </g>
    <defs>
      <clipPath id="clip0_448_36973">
        <rect
          width="24"
          height="23.3394"
          fill="white"
          transform="translate(0 0.34549)"
        />
      </clipPath>
    </defs>
  </svg>,
  "Attachment"
);
