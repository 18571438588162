import React, { useCallback, useMemo, useState } from "react";
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { Circular } from "./spinners/Circular";
import { ReactComponent as MoreVertButton } from "../assets/newDesignItems/three-dot-menu-button.svg";
import { ConfirmationButton } from "./ConfirmationButton";

type Confirmation = {
  title: string;
  description: string;
};

type Button = {
  label: string;
  handler: () => void;
  disabled?: boolean;
  confirmation?: Confirmation;
  loading?: boolean;
};

type NewDesignContextMenuProps = Omit<
  IconButtonProps,
  "onClick" | "aria-controls" | "aria-expanded" | "aria-haspopup"
> & {
  buttons: Button[];
};

/**
 * Had to add a context menu without edit and delete. Creating a new component
 * for that seemed easier than customizing already bloated CustomDataGridRowActions.
 */
export const NewDesignContextMenu = ({
  buttons,
  ...props
}: NewDesignContextMenuProps) => {
  const idBase = "row-actions-menu";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handlerClosed = useCallback((handler: () => void) => {
    handler();
    setAnchorEl(null);
  }, []);

  const clickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeHandler = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const buttonsElements = useMemo(
    () =>
      buttons.map(
        ({ label, handler, disabled, confirmation, loading }, index) => {
          const content = loading ? <Circular size={24} /> : label;

          return confirmation ? (
            <NewDesignConfirmationButton
              key={`${idBase}-button-${index}`}
              onClick={() => handlerClosed(handler)}
              confirmationTitle={confirmation.title}
              confirmationDescription={confirmation.description}
              component={MenuItem}
              disabled={disabled}
            >
              {content}
            </NewDesignConfirmationButton>
          ) : (
            <NewDesignMenuItem
              key={`${idBase}-button-${index}`}
              onClick={() => handlerClosed(handler)}
              disabled={disabled}
            >
              {content}
            </NewDesignMenuItem>
          );
        }
      ),
    [buttons, handlerClosed]
  );

  return (
    <>
      <IconButton
        onClick={clickHandler}
        aria-controls={idBase}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        {...props}
      >
        <MoreVertButton />
      </IconButton>
      <Menu id={idBase} open={open} anchorEl={anchorEl} onClose={closeHandler}>
        {buttonsElements}
      </Menu>
    </>
  );
};

const NewDesignMenuItem = styled(MenuItem)({
  fontSize: "13px",
  fontWeight: "400",
});

const NewDesignConfirmationButton = styled(ConfirmationButton)({
  fontSize: "13px",
  fontWeight: "400",
});
