import { useCallback } from "react";
import { SupplierProductCode } from "../../../../entities";
import { Currency } from "../../../../slices/currencies/types";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";
import { useSelector } from "../../../../store";
import { selectSelfSchoolCurrency } from "../../../../slices/auth/selectors";

export const useDisplayTotalAmount = () => {
  const { t } = useTranslation("pages");

  const displayPrice = useDisplayPrice();

  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const na = t("na", { ns: "common" });
  const priceUponRequest = t("priceUponRequest", { ns: "common" });

  return useCallback(
    ({
      supplierProductCode,
      productRetailPrice,
      productRetailCurrency,
      totalAmount,
    }: {
      supplierProductCode: SupplierProductCode | null;
      productRetailPrice: number | null | undefined;
      productRetailCurrency: Currency | null | undefined;
      totalAmount: number;
    }) => {
      const isPricePresent = productRetailPrice && productRetailCurrency;

      if (supplierProductCode && !isPricePresent) {
        return priceUponRequest;
      }

      const totalAmountFormatted = totalAmount ? displayPrice(totalAmount) : na;

      if (!supplierProductCode) {
        return totalAmountFormatted;
      }

      return `${totalAmountFormatted} ${selfCurrency?.code ?? na}`;
    },
    [displayPrice, na, priceUponRequest, selfCurrency?.code]
  );
};
