export default {
  required: "This field can not be empty",
  incorrectEmail: "Invalid format of email address",
  incorrectDate: "Wrong date or format (need dd/mm/yyyy)",
  incorrectName: "Should contain only letters and dots",
  incorrectCode: "Incorrect code",
  tooShort: "Shouldn't be shorter than {{minLength}} characters",
  tooLong: "Shouldn't be longer than {{maxLength}} characters",
  tooBig: "The value shouldn't be higher than {{maxNum}}",
  numMustBeGreater: "Should be greater than {{minNum}}",
  numMustBeLess: "Should be less than {{maxNum}}",
  zeroOrBigger: "Must be 0 or greater",
  shouldBeInt: "Should be a positive integer",
  categoriesMustBeUnique: "Categories should be unique",
  roleRequired: "User must have a role",
  atLeastOneNumAndChar: "At least one character and one number are required",
  passwordMustMatch: "Passwords must match",
  positivePrice: "Price must be a positive number",
  packSizePositive: "Pack size must be a positive number",
  quantityPositive: "Quantity must be a positive number",
  wrongId: "Unexpected error, wrong id",
  supplierProductCode: "Only letters, digits, dashes and dots are allowed",
  number: "Must be a number",
  requiredWhenRelatedFieldIsNotEmpty: "Required when {{field}} is not empty",
  tagsMustBeUnique: "Tags should be unique",
  tagTooLong: "Each tag shouldn't be longer than {{maxLength}} characters",
  wrongFileType: "Wrong file type",
  wrongFileSize: "File size is too big",
  fileRequired: "File should be attached",
  customId: "Custom id must be positive number",
  discountValueWithouthDiscountType:
    "The type of discount should be Amount or Rate",
  wholeNumber: "Must be a whole number",
};
