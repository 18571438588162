import React, { forwardRef } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlProps,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useGetDepartmentsQuery } from "../../slices/departments/api";
import { PaperPageSelect } from "../layout/PaperPage";
import { useTranslation } from "react-i18next";
import { Department } from "../../entities";

type TagsSelectDepartmentsProps = FormControlProps & {
  id: string;
  label: string;
  autocompleteProps: {
    onChange: (item: Department[]) => void;
    value: Department[];
  };
  helperText?: string;
};

export const TagsSelectDepartments: React.FC<TagsSelectDepartmentsProps> =
  forwardRef(({ id, label, helperText, autocompleteProps, ...props }, ref) => {
    const { t } = useTranslation();
    const { data = [], isLoading } = useGetDepartmentsQuery({});

    const activeDepartments = data.filter((department) => department.active);

    return (
      <FormControl fullWidth ref={ref} {...props}>
        <Autocomplete
          PaperComponent={PaperPageSelect}
          multiple
          id={`tags-select-departments-${id}`}
          options={activeDepartments}
          noOptionsText={t("emptySelect", { ns: "common" })}
          loading={isLoading}
          loadingText={t("loading", { ns: "common" })}
          getOptionLabel={(option) => option.name}
          filterOptions={(options) => {
            return options.filter(
              (option) =>
                !autocompleteProps.value
                  .map((filterDepartmentId) => filterDepartmentId.id)
                  .includes(option.id)
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={props.error}
              label={label}
              placeholder="Favorites"
            />
          )}
          onChange={(event, item) => {
            autocompleteProps.onChange(item);
          }}
          value={autocompleteProps.value}
        />
        {helperText && (
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  });
