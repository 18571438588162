import React from "react";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";
import { getTitle } from "../../utils/getTitle";
import { Helmet } from "react-helmet";
import { usePermissions } from "../../utils/usePermissions";
import { DataTable } from "./DataTable/DataTable";
import { Drawers } from "./Drawers/Drawers";

export const Inventory = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const title = t("schools.title");

  useHeaderTitle(title);

  if (!can("viewInventory")) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <DataTable />
      <Drawers />
    </>
  );
};
