import { Box, Typography, styled } from "@mui/material";
import { SubmitAbsoluteSpinnerButton } from "../../components/SubmitAbsoluteSpinnerButton";
import { Link } from "react-router-dom";

const fontFamilyPoppins = "Poppins";

export const StyledTitleBox = styled(Box)({
  fontFamily: fontFamilyPoppins,
  fontSize: "28px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: "#164173",
});

export const StyledFieldTitle = styled(Typography)({
  fontFamily: fontFamilyPoppins,
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#164173",
});

export const StyledHelpBox = styled(Box)({
  fontFamily: fontFamilyPoppins,
  fontSize: "13px",
  lineHeight: "16px",
  display: "flex",
  color: "#5E7DA0",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
});

export const StyledSubmitSpinnerButton = styled(SubmitAbsoluteSpinnerButton)({
  fontFamily: fontFamilyPoppins,
  fontSize: "13px",
  fontWeight: 500,
  color: "#FFF",
  background: "#2191FF",
  border: "1px solid #8EC7FF",
  height: "40px",
  marginTop: "12px",
  borderRadius: "4px",
  ":hover": {
    background: "#2191FF",
  },
});

export const StyledLink = styled(Link)({
  fontFamily: fontFamilyPoppins,
  fontSize: "13px",
  color: "#2191FF",
  textAlign: "right",
  marginTop: "10px !important",
});

export const StyledTextBeforeLink = styled(Typography)({
  fontFamily: fontFamilyPoppins,
  fontSize: "13px",
  color: "#5E7DA0",
  textAlign: "right",
  marginTop: "10px !important",
});

export const InputLabelProps = {
  sx: {
    fontFamily: fontFamilyPoppins,
    fontSize: "16px",
    color: "#797A7A",
  },
};

export const NewDesignInputProps = {
  sx: {
    fontFamily: fontFamilyPoppins,
    fontSize: "13px",
    borderRadius: "4px",
    "> *": { borderRadius: "4px" },
  },
};

export const InputProps = {
  sx: {
    fontFamily: fontFamilyPoppins,
    fontSize: "16px",
    borderRadius: "8px",
    "> *": { borderRadius: "8px" },
  },
};

export const FormHelperTextProps = {
  sx: {
    fontFamily: fontFamilyPoppins,
    fontSize: "14px",
  },
};
