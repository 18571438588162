import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { ItemFlattened } from "../../../../slices/items/types";
import { useInquiryDetailsUrlTools } from "../../hooks";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { useLocalStorage } from "usehooks-ts";
import {
  DEFAULT_PAGE_SIZE,
  ITEM_ORDER_STATUS_COLORS,
} from "../../../../consts";
import { useGetItemsQuery } from "../../../../slices/items/api";
import { DrawerDataGrid } from "../../../../components/wideDrawer/DrawerDataGrid";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { useGetMainOrdersQuery } from "../../../../slices/mainOrders/api";
import { Chip } from "@mui/material";

const tableName = "main-order-items";

export const Items = () => {
  const { t } = useTranslation("pages");

  const { baseUrl, inquiryId } = useInquiryDetailsUrlTools();
  const { id } = useTableUrlTools(`${baseUrl}/mo`);

  const columns = useMemo<GridColDef<ItemFlattened>[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        flex: 0.1,
        cellClassName: "id-width-fix",
      },
      {
        field: "supplier",
        headerName: t("items.fields.supplier"),
        flex: 0.225,
      },
      {
        field: "supplierProductCode",
        headerName: t("items.fields.supplierProductCode"),
        flex: 0.225,
      },
      {
        field: "name",
        headerName: t("items.fields.name"),
        flex: 0.225,
      },
      {
        field: "orderStatus",
        headerName: t("items.fields.orderStatus"),
        flex: 0.225,
        renderCell: ({ row: { orderStatus } }) =>
          orderStatus && (
            <Chip
              label={<b>{t(`items.statuses.order.${orderStatus}`)}</b>}
              sx={{
                backgroundColor: ITEM_ORDER_STATUS_COLORS[orderStatus],
                color: "white",
              }}
            />
          ),
      },
    ],
    [t]
  );

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    `table-${tableName}-${inquiryId}.sortModel`,
    [
      {
        field: "dueDate",
        sort: "asc",
      },
    ]
  );

  const [page, setPage] = useLocalStorage(
    `table-${tableName}-${inquiryId}.page`,
    0
  );

  const [pageSize, setPageSize] = useLocalStorage(
    `table-${tableName}-${inquiryId}.pageSize`,
    DEFAULT_PAGE_SIZE
  );

  const { data: mainOrders, isLoading: areMainOrdersLoading } =
    useGetMainOrdersQuery(inquiryId);

  const mainOrder = mainOrders?.find(
    ({ id: mainOrderId }) => mainOrderId === id
  );

  const { isLoading: areItemsLoading, data: items } = useGetItemsQuery(
    id
      ? {
          page,
          pageSize,
          sortModel,
          mainOrderId: id,
        }
      : skipToken
  );

  if (areMainOrdersLoading || areItemsLoading) {
    return <Spinner />;
  }

  if (!mainOrder || !items) {
    return null;
  }

  return (
    <DrawerDataGrid
      title={`${t("mainOrders.detailsTitle")}: ${mainOrder.referenceNumber}`}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      pagination
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      page={page}
      onPageChange={setPage}
      columns={columns}
      rows={items.data}
    />
  );
};
