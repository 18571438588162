import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NewDesignEntityDetailsGrid } from "../../components/NewDesignEntityDetailsGrid";
import { ShopProduct } from "../../slices/shop/types";
import { useIsSchoolTeam } from "../../utils/useIsSchoolTeam";

type InfoProps = {
  product: ShopProduct;
};

export const Info = ({
  product: { country, weight, volume, sizeW, sizeH, sizeD, color, packSize },
}: InfoProps) => {
  const { t } = useTranslation("pages");
  const isSchoolUser = useIsSchoolTeam();

  const na = t("na", { ns: "common" });

  const [expanded, setExpanded] = useState(false);

  return (
    <Box sx={{ marginTop: "32px" }}>
      <Accordion
        expanded={expanded}
        onChange={(_event, expanded) => setExpanded(expanded)}
      >
        <AccordionSummary>{t("shopDetails.productInfo")}</AccordionSummary>
        <AccordionDetails>
          <Box sx={{ marginTop: "16px" }}>
            <NewDesignEntityDetailsGrid
              compact={false}
              blocks={[
                [
                  {
                    label: t("shopDetails.infoCells.weight"),
                    value: `${weight ?? na} ${t("units.kg", { ns: "common" })}`,
                    xs: 4,
                  },
                  {
                    label: t("shopDetails.infoCells.wdhCm"),
                    value: `${sizeW ?? na} x ${sizeD ?? na} x ${sizeH ?? na}`,
                    xs: 4,
                  },
                  {
                    label: t("shopDetails.infoCells.volume"),
                    value: `${volume ?? na} ${t("units.m3", { ns: "common" })}`,
                    xs: 4,
                  },
                ],
                isSchoolUser
                  ? []
                  : [
                      {
                        label: t("shopDetails.infoCells.country"),
                        value: country?.name ?? na,
                        xs: 4,
                      },
                      {
                        label: t("shopDetails.infoCells.color"),
                        value: color ?? na,
                        xs: 4,
                      },
                      {
                        label: t("shopDetails.infoCells.packSize"),
                        value: `${packSize ?? na} ${t("units.pcs", {
                          ns: "common",
                        })}`,
                        xs: 4,
                      },
                    ],
              ]}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
