import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useGetSuppliersQuery } from "../../slices/suppliers/api";
import { useTranslation } from "react-i18next";

type NewDesignSelectSupplierProps = Omit<
  FormControlProps,
  "id" | "onChange"
> & {
  label: string;
  selectProps: SelectProps;
  id: string;
  helperText?: string;
  publicOnly?: boolean;
  value: string | number;
};

export const NewDesignSelectSupplier = ({
  publicOnly,
  label,
  id,
  helperText,
  selectProps,
  value,
  ...props
}: NewDesignSelectSupplierProps) => {
  const { t } = useTranslation();

  const { data: suppliers = [], isLoading } = useGetSuppliersQuery({
    publicOnly,
  });

  /*
  FIXED for PC-895
  The error message does not disappear if we set the supplier through the name

  TODO fix this to work with react-hook-form properly. If possible, leave the Select component as input to avoid design problems
  */
  if (value) {
    helperText = undefined;
  }

  return (
    <FormControl fullWidth {...props} error={!!helperText}>
      <Select
        labelId={`custom-assignment-teacher-select-${id}-label`}
        id={`custom-assignment-teacher-select-${id}`}
        placeholder={label}
        disabled={isLoading}
        {...selectProps}
        value={value || ""}
      >
        {suppliers.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
        {suppliers.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
