import React from "react";
import { TextFieldProps } from "@mui/material";
import { Circular } from "./Circular";

type TextFieldCircularProps = {
  size?: TextFieldProps["size"];
};

export const TextFieldCircular = ({
  size = "small",
}: TextFieldCircularProps) => {
  const spinnerSize = size === "small" ? 15 : 25;
  const thickness = size === "small" ? 175 : 175;

  return <Circular size={spinnerSize} thickness={thickness} />;
};
