export default {
  error: {
    unspecific: "Something went wrong",
    signIn: "Couldn't sign in",
    incorrectLoginPassword: "Login or password are incorrect",
    unableFetchPermissions: "Unable to fetch permissions",
  },

  tryAgain: "Try again",
  name: "Name",
  code: "Code",
  ok: "OK",
  requireApproval: "Require Approval",
  description: "Description",
  level: "Level",
  parentCategory: "Parent Category",
  actions: "Actions",
  id: "ID",
  at: "at",
  edit: "Edit",
  changeSelfPassword: "Change self password",
  passwordChanged: "Password has been changed",
  changeActivity: "Change Activity",
  "change password": "Change password",
  delete: "Delete",
  open: "Open",
  proceed: "Proceed",
  cancel: "Cancel",
  "sign in": "Sign In",
  "sign up": "Sign up",
  "log out": "Log out",
  email: "Email",
  emailNew: "E-mail",
  password: "Password",
  newPassword: "New password",
  passwordConfirmation: "Confirm new password",
  submit: "Submit",
  search: "Search",
  activity: "Activity",

  invitation: {
    title: "Welcome!",
    titleWN: "Welcome {{name}}!",
    message1: "We are delighted you're using our application!",
    message2:
      "If you have some questions, please, contact us. We're glad to assist you!",
    proceed: "Close",
  },

  activities: {
    deactivate: "Deactivate",
    activate: "Activate",
  },

  countries: {
    title: "Countries",
    addCountry: "Add country",
    editCountry: "Edit country",
    activeStatus: "Active",
    addSuccess: "Country has been added",
    editSuccess: "Country has been edited",
    changeActivitySuccess: "Country activity has been changed",
    deleteSuccess: "Country has been removed",
    confirmDeleteTitle: "Are you sure you want to delete the country?",
    confirmDeleteDescription: "You won't be able to undo this action",
    confirmChangeActivityTitle:
      "Are you sure you want to change the country activity?",
    confirmChangeActivityDescription: "You will be able to undo this action",
    errors: {
      refetch: "Unable to load countries, try again",
      add: "Something went wrong adding a country, try again",
      edit: "Something went wrong editing the country, try again",
      changeActivity:
        "Something went wrong changing activity the country, try again",
      delete: "Something went wrong deleting the country, try again",
    },
  },

  suppliers: {
    title: "Suppliers",
    addSupplier: "Add supplier",
    editSupplier: "Edit supplier",
    activeStatus: "Active",
    public: "Public",
    addSuccess: "Supplier has been added",
    editSuccess: "Supplier has been edited",
    changeActivitySuccess: "Supplier activity has been changed",
    deleteSuccess: "Supplier has been removed",
    confirmDeleteTitle: "Are you sure you want to delete the supplier?",
    confirmDeleteDescription: "You won't be able to undo this action",
    confirmChangeActivityTitle:
      "Are you sure you want to change the supplier activity?",
    confirmChangeActivityDescription: "You will be able to undo this action",
    errors: {
      refetch: "Unable to load suppliers, try again",
      add: "Something went wrong adding a supplier, try again",
      edit: "Something went wrong editing the supplier, try again",
      changeActivity:
        "Something went wrong changing activity the supplier, try again",
      delete: "Something went wrong deleting the supplier, try again",
    },
  },

  departments: {
    title: "Departments",
    addDepartment: "Add department",
    editDepartment: "Edit department",
    activeStatus: "Active",
    publicStatus: "Public",
    addSuccess: "Department has been added",
    editSuccess: "Department has been edited",
    changeActivitySuccess: "Department activity has been changed",
    deleteSuccess: "Department has been removed",
    confirmDeleteTitle: "Are you sure you want to delete the department?",
    confirmDeleteDescription: "You won't be able to undo this action",
    confirmChangeActivityTitle:
      "Are you sure you want to change the department activity?",
    confirmChangeActivityDescription: "You will be able to undo this action",
    errors: {
      refetch: "Unable to load departments, try again",
      add: "Something went wrong adding a department, try again",
      edit: "Something went wrong editing the department, try again",
      changeActivity:
        "Something went wrong changing activity the department, try again",
      delete: "Something went wrong deleting the department, try again",
    },
  },

  commoditycodes: {
    title: "Commodity codes",
    addCommoditycode: "Add commodity code",
    editCommoditycode: "Edit commodity code",
    addSuccess: "Commodity code has been added",
    editSuccess: "Commodity code has been edited",
    deleteSuccess: "Commodity code has been removed",
    requireApproval: "Require approval",
    confirmDeleteTitle: "Are you sure you want to delete the commodity code?",
    confirmDeleteDescription: "You won't be able to undo this action",
    errors: {
      refetch: "Unable to load commodity codes, try again",
      add: "Something went wrong adding a commodity code, try again",
      edit: "Something went wrong editing the commodity code, try again",
      delete: "Something went wrong deleting the commodity code, try again",
    },
  },

  categories: {
    title: "Categories",
    addCategory: "Add category",
    editCategory: "Edit category",
    addSuccess: "Category has been added",
    editSuccess: "Category has been edited",
    deleteSuccess: "Category has been removed",
    confirmDeleteTitle: "Are you sure you want to delete the category?",
    confirmDeleteDescription: "You won't be able to undo this action",
    errors: {
      refetch: "Unable to load categories, try again",
      add: "Something went wrong adding a category, try again",
      edit: "Something went wrong editing the category, try again",
      delete: "Something went wrong deleting the category, try again",
    },
  },
};
