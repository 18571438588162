import React, { useCallback } from "react";
import {
  allSchoolLevels,
  GetProductResponseDto,
  Match,
} from "../slices/products/types";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography } from "@mui/material";
import { EntityDetailsGrid } from "./EntityDetailsGrid";
import { buildCategoriesTree, buildCategoryName } from "../utils/categories";
import { ApprovalOutlined } from "@mui/icons-material";
import { CategoryFlattened } from "../slices/categories/types";
import { ImageSliderFixedHeight } from "./ImageSliderFixedHeight";
import { Description } from "./Description";
import { BooleanCheckboxDisplay } from "./dataDisplays/BooleanCheckboxDisplay";
import { useDisplayPrice } from "../utils/useDisplayPrice";
import { useIsSchoolTeam } from "../utils/useIsSchoolTeam";
import { Inventory } from "../containers/Products/Drawers/Details/Inventory";

type ProductDetailsProps = {
  product: GetProductResponseDto;
  categories: CategoryFlattened[];
};

export const ProductDetails = ({
  product,
  categories,
}: ProductDetailsProps) => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();
  const isSchoolUser = useIsSchoolTeam();

  const na = t("products.NA");
  const categoriesIds = product.categories.map(({ id }) => id);

  const matchMapper = useCallback(
    ({ supplierProductCodeOne, supplierProductCodeTwo }: Match) => {
      const first =
        product.supplierProductCode === supplierProductCodeOne.code &&
        product.supplier.id === supplierProductCodeOne.supplier.id;

      return `${
        first
          ? supplierProductCodeTwo.supplier.name
          : supplierProductCodeOne.supplier.name
      } ${first ? supplierProductCodeTwo.code : supplierProductCodeOne.code}`;
    },
    [product.supplier.id, product.supplierProductCode]
  );

  const matchesA = product.matches
    .filter(({ match }) => match === "A")
    .map(matchMapper);
  const matchesB = product.matches
    .filter(({ match }) => match === "B")
    .map(matchMapper);

  return (
    <EntityDetailsGrid
      blocks={[
        [
          {
            label: t("products.fields.productCode"),
            value: `${product.supplier.name}  ${product.supplierProductCode}`,
            xs: 3,
          },
          {
            label: t("products.fields.description"),
            view: (
              <Description
                content={product.description ?? na}
                sx={{
                  "p:first-child": { marginTop: 0.5 },
                  "p:last-child": { marginBottom: 0 },
                }}
              />
            ),
            xs: 9,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.weight"),
            value: (product.weight ?? na) + ` ${t("products.units.weight")}`,
            xs: 3,
          },
          {
            label: t("products.fields.volume"),
            value: (product.volume ?? na) + ` ${t("products.units.volume")}`,
            xs: 3,
          },
          {
            label: t("products.fields.WDH"),
            value: `${product.sizeW ?? na} ${t("products.units.WDH")} x ${
              product.sizeD ?? na
            } ${t("products.units.WDH")} x ${product.sizeH ?? na} ${t(
              "units.cm",
              { ns: "common" }
            )}`,
            xs: 3,
          },
        ],
        isSchoolUser
          ? []
          : [
              {
                label: t("products.fields.packSize"),
                value: product.packSize?.toString() ?? na,
                xs: 3,
              },
              {
                label: t("products.fields.color"),
                value: product.color ?? na,
                xs: 3,
              },
              {
                label: t("products.fields.country"),
                value: product.country?.name ?? na,
                xs: 6,
              },
            ],
        "divider",
        [
          {
            label: t("products.fields.supplierPrice"),
            value:
              `${displayPrice(product.supplierPrice) ?? na}` +
              ` ${product.supplierCurrency?.code ?? na}`,
            xs: 3,
          },
          {
            label: t("products.fields.retailPrice"),
            value:
              `${displayPrice(product.retailPrice) ?? na}` +
              ` ${product.retailCurrency?.code ?? na}`,
            xs: 3,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.categories"),
            value:
              categories
                .map((flatCategory) => {
                  const category = buildCategoriesTree(
                    flatCategory,
                    categories
                  );

                  if (category) {
                    return {
                      id: category.id,
                      name: buildCategoryName(category),
                    };
                  }
                })
                .filter(
                  (category) => category && categoriesIds.includes(category.id)
                )
                .map((category) => category?.name)
                .join("\n") || na,
            xs: 6,
          },
          {
            label: t("products.fields.tags"),
            value: product.tags.map(({ name }) => name).join(", ") || na,
            xs: 6,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.commodityCode"),
            view: product.commodityCode ? (
              <Box
                sx={{ display: "flex", alignItems: "center", marginTop: 0.5 }}
              >
                {product.commodityCode.requireApproval && (
                  <Tooltip
                    title={
                      t("commodityCodeRequiresApproval", {
                        ns: "common",
                      }) as string
                    }
                  >
                    <ApprovalOutlined
                      sx={{ marginRight: 0.5 }}
                      color="warning"
                    />
                  </Tooltip>
                )}
                <Typography variant="body2">
                  {`${product.commodityCode.code || na} - ${
                    product.commodityCode.description || na
                  }`}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                {na}
              </Typography>
            ),
            xs: 6,
          },
          {
            label: t("products.fields.replacementCommodityCode"),
            value: product.replacementCommodityCode ?? na,
            xs: 6,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.schoolLevel"),
            view: (
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  marginTop: 0.5,
                }}
              >
                {allSchoolLevels.map((level) => (
                  <Box
                    key={`container-detail-school-level-${level}`}
                    sx={{ display: "flex", gap: "5px" }}
                  >
                    <BooleanCheckboxDisplay
                      key={`detail-school-level-${level}`}
                      value={!!product.schoolLevel?.includes(level)}
                    />
                    <Typography>
                      {t(`schoolLevel.${level}`, { ns: "common" })}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ),
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.matches.plural"),
            value: `A: ${matchesA.length > 0 ? matchesA.join(", ") : na}, B: ${
              matchesB.length > 0 ? matchesB.join(", ") : na
            }`,
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.popularity"),
            value: product.popularity.toString() ?? "0",
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.images.plural"),
            view:
              product.images.length > 0 ? (
                <ImageSliderFixedHeight
                  images={product.images.map((url) => ({ src: url }))}
                  height={300}
                  sx={{
                    width: "300px",
                    height: "300px",
                  }}
                />
              ) : (
                <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                  {na}
                </Typography>
              ),
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.availability"),
            view: <BooleanCheckboxDisplay value={product.availability} />,
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("products.fields.inventory"),
            view: <Inventory inventoryEntries={product.inventoryEntries} />,
            xs: 12,
          },
        ],
      ]}
    />
  );
};
