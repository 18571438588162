import { styled } from "@mui/material";
import { EditorContent } from "@tiptap/react";

export const StyledEditorContent = styled(EditorContent)({
  // Table cells select highlight
  "td, th": { position: "relative" },
  ".selectedCell": {
    "&:after": {
      zIndex: 2,
      position: "absolute",
      content: '""',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: "rgba(200, 200, 255, 0.4)",
      pointerEvents: "none",
    },
  },
});
