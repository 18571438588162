import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Grid, styled, TextField } from "@mui/material";
import {
  AddCategoryRequestDto,
  CategoryLevel,
  EditCategoryRequestDto,
} from "../../../slices/categories/types";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { SelectCategoryLevel } from "../../../components/prefilledSelectors/SelectLevelCategory";
import { AutocompleteParentCategory } from "../../../components/prefilledSelectors/AutocompleteParentCategory";
import { createYupSchema } from "../../../utils/validation";
import { MuiColorInput } from "mui-color-input";
import { DEFAULT_CATEGORY_COLOR } from "../../../consts";

export const categoryFormSchemaCreator = createYupSchema((t) =>
  yup.object().shape({
    id: yup.number().positive().optional(),
    name: yup
      .string()
      .required(t("required"))
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 })),
    level: yup.string().required(t("required")),
    parentCategoryId: yup
      .number()
      .required(t("required"))
      .min(0, t("required"))
      .when("level", {
        is: (value: CategoryLevel) => value === "1",
        then: yup.number().default(-1).notRequired().min(-1),
      }),
    color: yup.string().optional(),
  })
);

type CategoryFormProps = {
  categoryData?: EditCategoryRequestDto;
  fieldsErrors?: FieldsErrorState;
};

export const CategoryForm: React.FC<CategoryFormProps> = ({
  categoryData,
  fieldsErrors,
}) => {
  const { t } = useTranslation();
  const { register, control } = useFormContext<
    AddCategoryRequestDto | EditCategoryRequestDto
  >();

  const watchCategoryLevelSelect = useWatch({
    control,
    defaultValue: categoryData?.level || "1",
    name: "level",
  });

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {categoryData && (
        <input
          type="hidden"
          defaultValue={categoryData.id}
          {...register("id", {
            valueAsNumber: true,
          })}
        />
      )}
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          defaultValue={categoryData && categoryData.name}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="level"
          defaultValue={(categoryData && categoryData.level) || "1"}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "level",
              fieldsErrors
            );
            return (
              <SelectCategoryLevel
                selectProps={field}
                label={`${t("level")} *`}
                id="add-category"
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                size="small"
              />
            );
          }}
        />
      </Grid>
      {watchCategoryLevelSelect !== "1" && (
        <Grid item gridColumn="span 12">
          <Controller
            control={control}
            name="parentCategoryId"
            defaultValue={(categoryData && categoryData.parentCategoryId) || -1}
            render={({ field, fieldState: { invalid, error } }) => {
              const [serverInvalid, serverError] = fieldError(
                "parentCategoryId",
                fieldsErrors
              );
              return (
                <AutocompleteParentCategory
                  label={`${t("parentCategory")} *`}
                  id="add-category"
                  error={invalid || serverInvalid}
                  helperText={error?.message || serverError}
                  size="small"
                  itemLevel={watchCategoryLevelSelect}
                  utilOnChange={field.onChange}
                  currentId={categoryData?.id}
                  defaultValue={
                    (categoryData && categoryData.parentCategoryId) || -1
                  }
                />
              );
            }}
          />
        </Grid>
      )}
      {watchCategoryLevelSelect === "1" && (
        <Grid item gridColumn="span 12">
          <Controller
            control={control}
            name="color"
            defaultValue={
              (categoryData && categoryData.color) || DEFAULT_CATEGORY_COLOR
            }
            render={({ field, fieldState: { invalid, error } }) => {
              const [serverInvalid, serverError] = fieldError(
                "color",
                fieldsErrors
              );
              return (
                <MuiColorInputStyled
                  {...field}
                  sx={{ width: "100%" }}
                  format="hex"
                  isAlphaHidden={true}
                  fallbackValue={DEFAULT_CATEGORY_COLOR}
                  error={invalid || serverInvalid}
                  helperText={error?.message || serverError}
                />
              );
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const MuiColorInputStyled = styled(MuiColorInput)`
  & .MuiColorInput-Button:hover {
    border: none;
  }
`;
