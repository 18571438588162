import React from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { Paper, Typography } from "@mui/material";

type DrawerDataGridProps = DataGridProps & {
  title?: string;
};

export const DrawerDataGrid = ({ title, ...props }: DrawerDataGridProps) => {
  return (
    <Paper
      sx={{
        margin: 2,
        padding: 2,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {title && (
        <Typography variant="h5" component="h3" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
      )}
      <DataGrid {...props} />
    </Paper>
  );
};
