import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useDeleteCommoditycodeMutation } from "../../../slices/commoditycodes/api";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { usePermissions } from "../../../utils/usePermissions";

type ActionsProps = {
  id: GridRowId;
};

export const Actions: React.FC<ActionsProps> = ({ id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const can = usePermissions();
  const [deleteCommoditycode] = useDeleteCommoditycodeMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  const onEdit = useCallback(() => {
    history.push(`/commoditycodes/${intId}/edit`);
  }, [history, intId]);
  const onDelete = useCallback(() => {
    deleteCommoditycode(intId)
      .unwrap()
      .then(() => {
        feedSuccess(t("commoditycodes.deleteSuccess"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("commoditycodes.errors.delete"));
      });
  }, [deleteCommoditycode, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationChangeActivityTitle={t(
        "commoditycodes.confirmChangeActivityTitle"
      )}
      confirmationChangeActivityDescription={t(
        "commoditycodes.confirmChangeActivityDescription"
      )}
      confirmationTitle={t("commoditycodes.confirmDeleteTitle")}
      confirmationDescription={t("commoditycodes.confirmDeleteDescription")}
      onEditClick={onEdit}
      onDeleteClick={onDelete}
      canEdit={can("editCommoditycode")}
      canDelete={can("editCommoditycode")}
    />
  );
};
