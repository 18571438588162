import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { schoolFormSchemaCreator } from "./schema";
import { AddSchoolRequestDto } from "../../../slices/schools/types";
import { Form as SchoolForm } from "./Form";
import { useAddSchoolMutation } from "../../../slices/schools/api";
import { useYup } from "../../../utils/validation";

export const Add: React.FC = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(schoolFormSchemaCreator);
  const formMethods = useForm<AddSchoolRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const [addSchool, { isLoading }] = useAddSchoolMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schools.errors.add")
  );

  const { closeDrawer } = useTableUrlTools("/schools");
  const onSubmit = useCallback(
    (form: AddSchoolRequestDto) =>
      addSchool({ ...form, active: true })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("schools.successes.add"), `/schools/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError),
    [addSchool, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <Form
      heading={t("schools.add")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <SchoolForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
