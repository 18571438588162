import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { usePermissions } from "../../../utils/usePermissions";
import {
  useChangeProductAvailabilityMutation,
  useDeleteProductMutation,
} from "../../../slices/products/api";
import { ContextMenu } from "../../../components/ContextMenu";

type ActionsProps = {
  id: number;
  availability: boolean;
};

export const Actions = memo(({ id, availability }: ActionsProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const can = usePermissions();
  const [deleteProduct] = useDeleteProductMutation();
  const [changeProductAvailability] = useChangeProductAvailabilityMutation();
  const intId = typeof id === "string" ? parseInt(id) : id;

  return (
    <ContextMenu
      sx={{ marginLeft: "auto" }}
      buttons={[
        {
          label: t("actions.edit", { ns: "common" }),
          handler: () => {
            history.push(`/products/${intId}/edit`);
          },
          disabled: !can("editProduct"),
        },
        {
          label: availability
            ? t("products.actions.changeAvailability.makeUnavailable")
            : t("products.actions.changeAvailability.makeAvailable"),
          confirmation: {
            title: t("products.actions.changeAvailability.confirmationTitle"),
            description: t(
              "products.actions.changeAvailability.confirmationDescription"
            ),
          },
          handler: () => {
            changeProductAvailability({ id, availability: !availability })
              .unwrap()
              .then(() => {
                feedSuccess(t("products.successes.changeAvailability"));
              })
              .catch((error) => {
                feedError(
                  error?.data?.message ??
                    t("products.errors.changeAvailability")
                );
              });
          },
          disabled: !can("changeProductActive"),
        },
        {
          label: t("products.actions.delete.label"),
          handler: () => {
            deleteProduct(intId)
              .unwrap()
              .then(() => {
                feedSuccess(t("products.successes.delete"));
              })
              .catch((error) => {
                feedError(error?.data?.message ?? t("products.errors.delete"));
              });
          },
          confirmation: {
            title: t("products.actions.delete.confirmationTitle"),
            description: t("products.actions.delete.confirmationDescription"),
          },
          disabled: !can("deleteProduct"),
        },
      ]}
    />
  );
});
