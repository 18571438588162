import React, { useCallback, useMemo } from "react";
import { CustomDataGridProps } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { Role, roles } from "../../../permissions/permissions";
import { useLocalStorage } from "usehooks-ts";
import { useGetSchoolsQuery } from "../../../slices/schools/api";
import { useSelector } from "../../../store";
import { NewDesignCustomDataGrid } from "../../../components/CustomDataGrid/NewDesignCustomDataGrid";
import { NewDesignMultipleSelectFilter } from "../../../components/toolbar/NewDesignMultipleSelectFilter";
import { selectSelfRole } from "../../../slices/auth/selectors";
import { useIsSchoolTeam } from "../../../utils/useIsSchoolTeam";

const principalRoles = ["principal", "headOfDepartment", "teacher"] as Role[];

export type FilteredCustomDataGridProps = Omit<CustomDataGridProps, "filters">;

export const FilteredCustomDataGrid = ({
  rows,
  ...props
}: FilteredCustomDataGridProps) => {
  const isSchoolTeam = useIsSchoolTeam();

  const { t } = useTranslation("pages");
  const selfRole = useSelector(selectSelfRole);

  const rolesOptions = useMemo(() => {
    if (selfRole === "principal") {
      return principalRoles;
    }

    return roles;
  }, [selfRole]);

  const [userRolesFilterValueRaw, setUserRolesFilterValue] = useLocalStorage<
    string[]
  >("filter.usersByRole", []);

  const userRolesFilterValue = useMemo(() => {
    if (selfRole === "principal") {
      return userRolesFilterValueRaw.filter((role) =>
        principalRoles.includes(role as Role)
      );
    }

    return userRolesFilterValueRaw;
  }, [selfRole, userRolesFilterValueRaw]);

  const { data: schoolsData = [], isLoading: isSchoolsDataLoading } =
    useGetSchoolsQuery();

  const [schoolsRaw, setSchools] = useLocalStorage<string[]>(
    "filter.usersBySchool",
    []
  );

  const schools = useMemo(() => {
    if (selfRole === "principal") {
      return [];
    }

    return schoolsRaw;
  }, [schoolsRaw, selfRole]);

  const isNotPrincipal = selfRole !== "principal";

  const filters = useMemo(
    () => (
      <>
        <NewDesignMultipleSelectFilter
          id="by-role"
          label={t("users.fields.role")}
          options={rolesOptions.map((role) => ({
            label: t(`roles.${role}`, { ns: "common" }),
            value: role,
          }))}
          onChange={(value) => setUserRolesFilterValue(value)}
          value={userRolesFilterValue}
          selectStyles={{
            borderRadius: !isNotPrincipal ? "0px 4px 4px 0px" : "0",
          }}
        />
        {isNotPrincipal && (
          <NewDesignMultipleSelectFilter
            id="by-school"
            label={t("users.fields.school")}
            loading={isSchoolsDataLoading}
            options={schoolsData.map(({ id, name }) => ({
              label: name,
              value: id.toString(),
            }))}
            onChange={(value) => setSchools(value)}
            value={schools}
            selectStyles={{
              borderRadius: isNotPrincipal ? "0px 4px 4px 0px" : "0",
            }}
          />
        )}
      </>
    ),
    [
      t,
      rolesOptions,
      userRolesFilterValue,
      isNotPrincipal,
      isSchoolsDataLoading,
      schoolsData,
      schools,
      setUserRolesFilterValue,
      setSchools,
    ]
  );

  const filteredRowsByRole = useMemo(
    () =>
      rows.filter((row) => {
        const rolesFiltered =
          userRolesFilterValue.length < 1 ||
          userRolesFilterValue.includes(row.role);

        const schoolsFiltered =
          schools.length < 1 || schools.includes(row.school?.id.toString());

        return rolesFiltered && schoolsFiltered;
      }),
    [rows, userRolesFilterValue, schools]
  );

  const resetFilters = useCallback(() => {
    setUserRolesFilterValue([]);
  }, [setUserRolesFilterValue]);

  return (
    <NewDesignCustomDataGrid
      activeFilterIsUsed
      rows={filteredRowsByRole}
      filters={filters}
      resetCustomFilters={resetFilters}
      {...props}
      autoHeight={isSchoolTeam}
      sx={
        isSchoolTeam
          ? {
              minHeight: "420px",
            }
          : undefined
      }
    />
  );
};
