import React, { useCallback, useMemo } from "react";
import { DataGrid, DataGridProps, GridSortModel } from "@mui/x-data-grid";
import { Toolbar, ToolbarProps } from "../toolbar/Toolbar";
import {
  DEFAULT_ACTIVE_FILTER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_MODEL,
} from "../../consts";
import { ActiveFilter } from "./StandaloneFilterComponents/ActiveFilter";

export type CustomDataGridServerSideProps = Omit<
  DataGridProps,
  | "pagination"
  | "paginationMode"
  | "sortingMode"
  | "onSortModelChange"
  | "onPageChange"
  | "onPageSizeChange"
  | "filterMode"
> &
  Pick<
    ToolbarProps,
    | "addButtonText"
    | "addButtonDisabled"
    | "onAddClick"
    | "additionalButtonText"
    | "additionalButtonDisabled"
    | "onAdditionalClick"
    | "buttons"
  > & {
    onPageChange: (value: number) => void;
    onPageSizeChange: (value: number) => void;
    onSortModelChange: (value: GridSortModel) => void;
    setSearch: (value: string) => void;
    search: string;
    setActivitiesFilter?: (value: string[]) => void;
    activitiesFilter?: string[];
    filters?: React.ReactNode;
    resetCustomFilters?: () => void;
    activeFilterIsUsed?: boolean;
    defaultSortModel?: GridSortModel;
    toolbar?: boolean;
  };

export const CustomDataGridServerSide = ({
  addButtonText,
  addButtonDisabled,
  onAddClick,
  additionalButtonText,
  additionalButtonDisabled,
  onAdditionalClick,
  rows,
  rowsPerPageOptions = [1, 5, 10, 25, 50, 100],
  filters,
  buttons,
  resetCustomFilters,
  activeFilterIsUsed,
  page,
  pageSize,
  sortModel,
  search,
  activitiesFilter,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  setSearch,
  setActivitiesFilter,
  defaultSortModel,
  toolbar = true,
  ...props
}: CustomDataGridServerSideProps) => {
  const onSearchChange = useCallback(
    (event) => setSearch(event.target.value),
    [setSearch]
  );

  const onActivitiesFilterChange = useCallback(
    (newActivitiesFilter: string[]) =>
      setActivitiesFilter && setActivitiesFilter(newActivitiesFilter),
    [setActivitiesFilter]
  );

  const completeFilters = useMemo(
    () => (
      <>
        {activeFilterIsUsed && (
          <ActiveFilter
            onActivitiesFilterChange={onActivitiesFilterChange}
            activitiesFilter={activitiesFilter || []}
          />
        )}
        {filters}
      </>
    ),
    [filters, onActivitiesFilterChange, activitiesFilter, activeFilterIsUsed]
  );

  const reset = useCallback(() => {
    setSearch("");
    onPageChange(0);
    onPageSizeChange(DEFAULT_PAGE_SIZE);
    onSortModelChange(defaultSortModel || DEFAULT_SORT_MODEL);
    onActivitiesFilterChange(DEFAULT_ACTIVE_FILTER);
    if (resetCustomFilters) {
      resetCustomFilters();
    }
  }, [
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    setSearch,
    resetCustomFilters,
    onActivitiesFilterChange,
    defaultSortModel,
  ]);

  return (
    <>
      {toolbar && (
        <Toolbar
          addButtonText={addButtonText}
          addButtonDisabled={addButtonDisabled}
          onAddClick={onAddClick}
          additionalButtonText={additionalButtonText}
          additionalButtonDisabled={additionalButtonDisabled}
          onAdditionalClick={onAdditionalClick}
          searchValue={search}
          onSearchChange={onSearchChange}
          onReset={reset}
          filters={completeFilters}
          buttons={buttons}
          wideSearch
        />
      )}
      <DataGrid
        sx={{ minHeight: "360px" }}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        pagination
        rowsPerPageOptions={rowsPerPageOptions}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        page={page}
        onPageChange={onPageChange}
        filterMode="server"
        rows={rows}
        {...props}
      />
    </>
  );
};
