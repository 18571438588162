import React from "react";
import { Box, BoxProps, Tooltip, Typography, useTheme } from "@mui/material";
import { InventoryOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type InStockIndicatorProps = BoxProps & {
  quantity: number;
};

export const InStockIndicator = ({
  quantity,
  sx,
  ...props
}: InStockIndicatorProps) => {
  const { t } = useTranslation("pages");
  const theme = useTheme();

  if (quantity === 0) {
    return null;
  }

  return (
    <Tooltip title={t("inStock", { ns: "common" })}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
          color: theme.palette.success.main,
          ...sx,
        }}
        {...props}
      >
        <InventoryOutlined sx={{ fontSize: "16px" }} />
        <Typography
          variant="caption"
          component="span"
          sx={{ marginTop: "2px" }}
        >
          {quantity}
        </Typography>
      </Box>
    </Tooltip>
  );
};
