import { Actions } from "./actions";

export const roles = [
  "admin",
  "inquiryManager",
  "orderManager",
  "principal",
  "teacher",
  "headOfDepartment",
] as const;
export type Role = typeof roles[number];

type PseudoRoles = "authorized" | "disabled";

export type Permissions = Record<Role | PseudoRoles, Actions[]>;
