import React from "react";
import { Helmet } from "react-helmet";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { Add } from "./Drawers/Add";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { Edit } from "./Drawers/Edit";
import { Details } from "./Drawers/Details";
import { DataTable } from "./DataTable/DataTable";
import { usePermissions } from "../../utils/usePermissions";
import { ChangePassword } from "./Drawers/ChangePassword";
import { useRouteMatch } from "react-router-dom";
import { NewDesignWideDrawer } from "../../components/wideDrawer/NewDesignWideDrawer";

const baseUrl = "/users";

export const Users: React.FC = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { addDrawerOpen, editDrawerOpen, detailsDrawerOpen, closeDrawer } =
    useTableUrlTools(baseUrl);
  const changePasswordDrawerMatcher = useRouteMatch(
    `${baseUrl}/:id/changepassword`
  );

  const canViewUsers = can("viewUsers") || can("viewUsersMenu");
  const canAddUsers =
    can("addUser") ||
    can("addUsersOfOwnSchool") ||
    can("addTeachersOfOwnSchool");
  const canEditUsers =
    can("editUsers") ||
    can("editUsersOfOwnSchool") ||
    can("editTeachersOfOwnSchool");
  const canChangePassword = can("editSelf");

  return (
    <>
      <Helmet>
        <title>{getTitle(t("users.title"))}</title>
      </Helmet>
      {canViewUsers && <DataTable />}
      {canAddUsers && (
        <NewDesignWideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add />
        </NewDesignWideDrawer>
      )}
      {canEditUsers && (
        <NewDesignWideDrawer open={editDrawerOpen} close={closeDrawer}>
          <Edit />
        </NewDesignWideDrawer>
      )}
      {canChangePassword && (
        <NewDesignWideDrawer
          open={!!changePasswordDrawerMatcher}
          close={closeDrawer}
        >
          <ChangePassword />
        </NewDesignWideDrawer>
      )}
      {canViewUsers && (
        <NewDesignWideDrawer open={detailsDrawerOpen} close={closeDrawer}>
          <Details />
        </NewDesignWideDrawer>
      )}
    </>
  );
};
