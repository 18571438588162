import * as yup from "yup";
import { deliveryTerms } from "../../../../types";
import { createYupSchema } from "../../../../utils/validation";

export const schemaCreator = createYupSchema((t) =>
  yup.object({
    note: yup
      .string()
      .max(10000, t("tooLong", { maxLength: 10000 }))
      .nullable(),
    paymentTerms: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    deliveryTerms: yup
      .string()
      .oneOf([...deliveryTerms, undefined, null])
      .nullable()
      .optional(),
  })
);
