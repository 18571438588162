import React, { useMemo } from "react";
import { Tag } from "./Tag";
import { useGetFeaturedTagsQuery } from "../../../slices/tags/api";
import { useShopCatalogParams } from "../useShopCatalogParams";
import { Slider } from "./Slider/Slider";
import { TagSkeleton } from "./TagSkeleton";

type FeaturedTagsProps = {
  selectedTags: string[];
  onChange: ReturnType<typeof useShopCatalogParams>["tagsChangeHandler"];
};

/**
 * TODO
 *  Needs scrolling
 */
export const FeaturedTags = ({ selectedTags, onChange }: FeaturedTagsProps) => {
  const { data: tags, isLoading: areTagsLoading } = useGetFeaturedTagsQuery();

  const skeletons = useMemo(() => {
    if (!areTagsLoading) {
      return null;
    }

    return (
      <Slider>
        {Array.from(new Array(12)).map((_value, index) => (
          <TagSkeleton key={index} />
        ))}
      </Slider>
    );
  }, [areTagsLoading]);

  const content = useMemo(() => {
    if (!tags || areTagsLoading) {
      return null;
    }

    return (
      <Slider>
        {tags.map(({ label, name, icon, iconHover, iconSelected }) => (
          <Tag
            key={label}
            selected={selectedTags.includes(name)}
            label={label}
            onClick={() => onChange([name])}
            {...{ icon, iconHover, iconSelected }}
          />
        ))}
      </Slider>
    );
  }, [areTagsLoading, onChange, selectedTags, tags]);

  return (
    <>
      {skeletons}
      {content}
    </>
  );
};
