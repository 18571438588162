import { Match } from "../../../slices/products/types";
import { getDefaultHeaders, getUrl } from "../../../utils/api";
import { productsBaseUrl } from "../../../slices/products/api";
import { GetSigndedLinkResponseDto } from "../../../utils/S3UploadFile";

export const mapMatches = (
  matches: Match[],
  supplierId: number,
  supplierProductCode: string
) =>
  matches.map(({ match, supplierProductCodeOne, supplierProductCodeTwo }) => {
    const first =
      supplierProductCode === supplierProductCodeOne.code &&
      supplierId === supplierProductCodeOne.supplier.id;

    return {
      match,
      supplierId: first
        ? supplierProductCodeTwo.supplier.id
        : supplierProductCodeOne.supplier.id,
      supplierProductCode: first
        ? supplierProductCodeTwo.code
        : supplierProductCodeOne.code,
    };
  });

type SignedUrl = Array<{
  file: File;
  signedLink: GetSigndedLinkResponseDto;
}>;

export const uploadProductImages = async (files: File[]) => {
  const signedLinks: SignedUrl = [];

  for (const file of files) {
    const response = await fetch(
      getUrl(
        `/${productsBaseUrl}/getSignedUrlForImage?fileName=${file.name}&fileType=${file.type}`
      ),
      {
        headers: getDefaultHeaders(true),
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error();
    }

    signedLinks.push({
      file,
      signedLink: await response.json(),
    });
  }

  for (const {
    signedLink: { signedUrl },
    file,
  } of signedLinks) {
    await fetch(signedUrl, {
      method: "PUT",
      body: file,
    });
  }

  return signedLinks.map(({ signedLink: { key } }) => key);
};
