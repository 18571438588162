import React, { useMemo } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { SearchRecordRow } from "../../../slices/searches/types";
import { useTranslation } from "react-i18next";
import { OpenInNew, PlagiarismOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import { displayFullName } from "../../../utils/displayFullName";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";

export const useColumns = () => {
  const { t } = useTranslation("pages");
  const displayDate = useDisplayDateTime();

  return useMemo<GridColDef<SearchRecordRow>[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        filterable: false,
        flex: 0.05,
      },
      {
        field: "createdAt",
        headerName: t("searches.columns.date"),
        type: "date",
        filterable: false,
        flex: 0.15,
        valueGetter: ({ row: { createdAt } }) => displayDate(createdAt),
      },
      {
        field: "user",
        headerName: t("searches.columns.user"),
        filterable: false,
        flex: 0.1,
        renderCell: ({ row: { user } }) => (
          <MaterialLink component={Link} to={`/searches/${user.id}/user`}>
            {displayFullName(user)}
          </MaterialLink>
        ),
      },
      {
        field: "search",
        headerName: t("searches.columns.search"),
        filterable: false,
        sortable: false,
        flex: 0.1,
        valueGetter: ({ row: { search } }) =>
          search ?? t("na", { ns: "common" }),
      },
      {
        field: "numberOfFilters",
        headerName: t("searches.columns.numberOfFilters"),
        filterable: false,
        sortable: false,
        flex: 0.1,
      },
      {
        field: "results",
        headerName: t("searches.columns.numberOfResults"),
        filterable: false,
        sortable: false,
        flex: 0.1,
      },
      {
        field: "details",
        headerName: t("searches.columns.actions"),
        type: "actions",
        flex: 0.1,
        renderCell: ({ row: { id, url } }) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={t("searches.tooltips.url") as string}>
              <IconButton
                component={Link}
                to={url.length > 0 ? `/shop?${url}&page=1` : `/shop?page=1`}
              >
                <OpenInNew />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("searches.tooltips.details") as string}>
              <IconButton component={Link} to={`/searches/${id}`}>
                <PlagiarismOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [displayDate, t]
  );
};
