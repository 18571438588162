import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import {
  AddSchoolRequestDto,
  EditSchoolRequestDto,
  School,
} from "../../../slices/schools/types";
import { AutocompleteCountry } from "../../../components/prefilledSelectors/AutocompleteCountry";
import { usePermissions } from "../../../utils/usePermissions";
import { ControlledTextField } from "../../../components/controlledFields/ControlledTextField";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { SelectCurrency } from "../../../components/prefilledSelectors/SelectCurrency";

type FormProps = {
  data?: School;
  fieldsErrors?: FieldsErrorState;
  isActiveCheckboxShown?: boolean;
};

export const Form = ({
  data,
  fieldsErrors,
  isActiveCheckboxShown,
}: FormProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { register, control } = useFormContext<
    AddSchoolRequestDto | EditSchoolRequestDto
  >();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      {data && (
        <input
          type="hidden"
          defaultValue={data.id}
          {...register("id", {
            valueAsNumber: true,
          })}
        />
      )}
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          defaultValue={data && data.name}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("schools.fields.name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="countryId"
          defaultValue={data?.country.id}
          render={({
            field: { onChange, value },
            fieldState: { invalid, error },
          }) => {
            const [serverInvalid, serverError] = fieldError(
              "countryId",
              fieldsErrors
            );
            return (
              <AutocompleteCountry
                id="school"
                label={`${t("schools.fields.country")} *`}
                onChange={onChange}
                value={value}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      </Grid>
      {isActiveCheckboxShown && (
        <Grid item gridColumn="span 12">
          <Controller
            control={control}
            name="active"
            defaultValue={data ? data.active : true}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={data ? data.active : true} />
                }
                disabled={!can("changeSchoolActiveStatus")}
                label={t("schools.fields.active") as string}
                {...field}
              />
            )}
          />
        </Grid>
      )}
      <Grid item gridColumn="span 12">
        <ControlledTextField
          control={control}
          name="contactPersonName"
          label={t("schools.fields.contactPersonName")}
          fieldsErrors={fieldsErrors}
          defaultValue={data?.contactPersonName}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledTextField
          control={control}
          name="address"
          label={t("schools.fields.address")}
          fieldsErrors={fieldsErrors}
          defaultValue={data?.address}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledTextField
          control={control}
          name="phoneNumber"
          label={t("schools.fields.phoneNumber")}
          fieldsErrors={fieldsErrors}
          defaultValue={data?.phoneNumber}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledTextField
          control={control}
          name="email"
          label={t("schools.fields.email")}
          fieldsErrors={fieldsErrors}
          defaultValue={data?.email}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="currencyId"
          fieldsErrors={fieldsErrors}
          defaultValue={data?.currency.id}
          render={(errorMessage, { field }) => (
            <SelectCurrency
              id="school-currency"
              label={`${t("schools.fields.currency")} *`}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
