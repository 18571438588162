import React from "react";
import { Chip, ChipProps } from "@mui/material";
import { StatusColor } from "../types";

export type NewDesignStatusChipProps = Omit<ChipProps, "variant"> & {
  chipStyle: StatusColor;
  detached?: boolean;
};

export const NewDesignStatusChip = ({
  label,
  chipStyle,
  detached,
  sx,
  ...props
}: NewDesignStatusChipProps) => (
  <Chip
    label={label}
    variant="filled"
    sx={{
      fontWeight: "500",
      backgroundColor: chipStyle.backgroundColor
        ? `${chipStyle.backgroundColor} !important`
        : "#F9EBF9",
      color: chipStyle.color ? `${chipStyle.color} !important` : "#C03CC7",
      border: chipStyle.border ? `${chipStyle.border} !important` : "none",
      "&:hover": { cursor: detached ? "default" : "pointer" },
      fontSize: "13px",
      ...sx,
    }}
    {...props}
  />
);
