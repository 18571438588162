import React from "react";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectSelfRole } from "../../../slices/auth/selectors";
import { useSelector } from "../../../store";
import { LoadingTextField } from "../../../components/LoadingTextField";
import { GetSchoolsResponseDto } from "../../../slices/schools/types";

export const SelectSchool = ({
  value = "",
  disabled,
  data,
  isLoading,
  ...props
}: Omit<TextFieldProps, "select"> & {
  setSchoolId: (id: number) => void;
  data: GetSchoolsResponseDto;
  isLoading: boolean;
}) => {
  const selfRole = useSelector(selectSelfRole);
  const { t } = useTranslation();

  const activeSchools = data.filter((school) => school.active);

  return (
    <LoadingTextField
      select
      loading={isLoading}
      disabled={
        disabled ||
        (selfRole === "principal" && activeSchools.length === 1) ||
        selfRole === "headOfDepartment"
      }
      value={value}
      {...props}
    >
      {activeSchools.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
      {activeSchools.length < 1 && (
        <MenuItem key={"empty-list"} disabled>
          {t("emptySelect", { ns: "common" })}
        </MenuItem>
      )}
    </LoadingTextField>
  );
};
