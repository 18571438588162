import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddSupplierRequestDto,
  EditSupplierRequestDto,
  GetSuppliersRequestDto,
  Supplier,
} from "./types";

export const suppliersApi = createApi({
  reducerPath: "suppliersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/suppliers"),
    prepareHeaders,
  }),
  tagTypes: ["Supplier"],
  endpoints: (builder) => ({
    getSuppliers: builder.query<Supplier[], GetSuppliersRequestDto>({
      query: (query) => {
        const params = new URLSearchParams();

        if (query.publicOnly) {
          params.append("publicOnly", "true");
        }

        return `?${params.toString()}`;
      },
      providesTags: (result, error, query) => {
        const listId = query.publicOnly ? "list-public" : "list";

        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Supplier" as const,
                id,
              })),
              { type: "Supplier", id: listId },
            ]
          : [{ type: "Supplier", id: listId }];
      },
    }),
    getSupplier: builder.query<Supplier, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "Supplier", id }],
    }),
    addSupplier: builder.mutation<Supplier, AddSupplierRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [{ type: "Supplier", id: "list" }],
    }),
    editSupplier: builder.mutation<Supplier, EditSupplierRequestDto>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Supplier", id: "list" },
        { type: "Supplier", id },
      ],
    }),
    changeSupplierActivity: builder.mutation<
      Supplier,
      { id: number; active: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}/changeActivity`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Supplier", id: "list" },
        { type: "Supplier", id },
      ],
    }),
    deleteSupplier: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "Supplier", id: "list" },
        { type: "Supplier", id },
      ],
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useAddSupplierMutation,
  useEditSupplierMutation,
  useChangeSupplierActivityMutation,
  useDeleteSupplierMutation,
} = suppliersApi;
