import * as yup from "yup";
import { createYupSchema } from "../../../../utils/validation";

export const addDepartmentInquirySchemaCreator = createYupSchema((t) =>
  yup.object({
    departmentId: yup.number().positive(t("required")).required(t("required")),
    departmentHeadId: yup
      .number()
      .positive(t("required"))
      .required(t("required")),
    budget: yup
      .number()
      .typeError(t("number"))
      .positive()
      .nullable()
      .optional(),
    budgetCurrencyId: yup.number().positive(t("required")).optional(),
  })
);

export const editDepartmentInquirySchemaCreator = createYupSchema((t) =>
  yup.object({
    budget: yup
      .number()
      .typeError(t("number"))
      .positive()
      .nullable()
      .optional(),
    budgetCurrencyId: yup.number().positive(t("required")).optional(),
  })
);
