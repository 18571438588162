import { Box, styled } from "@mui/material";
import { colors } from "../../../theme/astra/colors";

type CellProps = {
  filler?: boolean;
  first?: boolean;
};

export const Cell = styled(Box, {
  shouldForwardProp: (propName) => {
    const properties: PropertyKey[] = ["filler", "first"];
    return !properties.includes(propName);
  },
})<CellProps>(({ filler, first }) => ({
  paddingTop: "24px",
  paddingBottom: "24px",
  paddingLeft: "12px",
  paddingRight: "12px",
  color: colors.darkBlue,
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
  display: "flex",
  alignItems: "center",
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: filler || first ? "transparent" : colors.dustBlue5,
}));
