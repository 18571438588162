import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouteState } from "../types";

export const useScrollToTop = () => {
  const location = useLocation<RouteState>();
  const scrollToTop = location.state?.scrollToTop;

  useLayoutEffect(() => {
    if (scrollToTop === true) {
      window.scrollTo(0, 0);
    }
  }, [scrollToTop]);
};
