export type ColumnsExtension<ColumnDefinition> = Array<{
  order?: number | null;
  column: ColumnDefinition;
}>;

export const createExtendableColumns = <ColumnDefinition>(
  baseColumns: ColumnDefinition[]
) => {
  return (
    columns: ColumnsExtension<ColumnDefinition> = []
  ): ColumnDefinition[] =>
    [
      ...baseColumns.map((column, index) => ({ column, order: index })),
      ...columns,
    ]
      .sort(({ order: firstOrder }, { order: secondOrder }) => {
        if (firstOrder && secondOrder) {
          return firstOrder - secondOrder;
        }

        if (firstOrder && !secondOrder) {
          return 1;
        }

        if (!firstOrder && secondOrder) {
          return -1;
        }

        return 0;
      })
      .map(({ column }) => column);
};
