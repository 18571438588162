import React, { useMemo } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetRelevanceTagsQuery } from "../../../slices/tags/api";
import { TagsFieldProps } from "./TagsField";
import { Plus } from "../../../components/astraIcons/Plus";
import { useSelector } from "../../../store";
import { selectShopList } from "../../../slices/shop/selectors";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";

export const SuggestedTags = ({
  value: selectedTags,
  onChange: setTags,
}: TagsFieldProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "shopCatalog.filters" });

  const { departmentId } = useSelector(selectShopList);

  const { data: suggestedTags = [], isLoading: areSuggestedTagsLoading } =
    useGetRelevanceTagsQuery({
      departmentId: departmentId,
      search: "",
      tags: selectedTags,
    });

  const skeletons = useMemo(() => {
    if (!areSuggestedTagsLoading) {
      return null;
    }

    return Array.from(new Array(5)).map((_value, index) => (
      <EnhancedSkeleton
        key={index}
        variant="rectangular"
        sx={{ borderRadius: "16px" }}
        width={[50, 150]}
        height="32px"
      />
    ));
  }, [areSuggestedTagsLoading]);

  const content = useMemo(() => {
    const filteredTags = suggestedTags
      .slice(0, 5)
      .filter(({ name }) => !selectedTags.includes(name));

    if (filteredTags.length < 1) {
      return null;
    }

    return filteredTags.map(({ label, name }) => (
      <Chip
        key={name}
        size="medium"
        variant="outlined"
        label={label ?? name}
        icon={<Plus />}
        onClick={() => setTags([...selectedTags, name])}
      />
    ));
  }, [selectedTags, setTags, suggestedTags]);

  if (!areSuggestedTagsLoading && suggestedTags.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginTop: "8px",
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: "12px",
          lineHeight: "24px",
          flexShrink: 0,
          flexGrow: 0,
          color: "secondary.main",
        }}
      >
        {t("suggestedTags")}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
        {skeletons}
        {content}
      </Box>
    </Box>
  );
};
