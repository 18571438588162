import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { RouteState } from "../types";

export type PushParamValue = {
  paramName: string;
  value: string;
};

export const useUrlSearchParamsTools = (
  localStorageKey: string,
  storedParamsValidator?: (urlSearchParams: URLSearchParams) => boolean
) => {
  const { search: searchParams, state } = useLocation<RouteState>();
  const history = useHistory();

  const shouldRestoreParams = state?.restoreParams;

  const [storedParams, setStoredParams] = useLocalStorage<string | null>(
    `urlSearchParams.${localStorageKey}`,
    null
  );

  const urlSearchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(searchParams);

    if (storedParams && shouldRestoreParams) {
      const urlSearchParams = new URLSearchParams(storedParams);

      if (!storedParamsValidator || storedParamsValidator(urlSearchParams)) {
        history.push({ search: urlSearchParams.toString() });
        return urlSearchParams;
      }
    }

    return urlSearchParams;
  }, [
    history,
    searchParams,
    shouldRestoreParams,
    storedParams,
    storedParamsValidator,
  ]);

  const pushParam = useCallback(
    (arg: PushParamValue | PushParamValue[]) => {
      const setParam = ({ paramName, value }: PushParamValue) => {
        if (urlSearchParams.get(paramName) === value) {
          return;
        }

        if (value === "") {
          urlSearchParams.delete(paramName);
        } else {
          urlSearchParams.set(paramName, value);
        }
      };

      if (Array.isArray(arg)) {
        for (const param of arg) {
          setParam(param);
        }
      } else {
        setParam(arg);
      }

      const serialized = urlSearchParams.toString();

      history.push({ search: serialized });
      setStoredParams(serialized);
    },
    [history, setStoredParams, urlSearchParams]
  );

  return { urlSearchParams, pushParam };
};
