import { useFeedError, useFeedSuccess } from "./feedHooks";
import { useCallback } from "react";
import { MessageResponseDto } from "../types";

export const useMessageHandlers = () => {
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const handleSuccess = useCallback(
    (res: MessageResponseDto) => {
      feedSuccess(res.message);
    },
    [feedSuccess]
  );

  const handleError = useCallback(
    (res) => {
      feedError(res?.data?.message ?? res?.message);
    },
    [feedError]
  );

  return { handleSuccess, handleError };
};
