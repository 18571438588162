import React from "react";
import * as yup from "yup";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import { createYupSchema } from "../../../utils/validation";
import { StyledFieldTitle } from "../../../containers/SignIn/StyledComponents";

export type ChangeSelfPasswordFormType = {
  id: number;
  password: string;
  passwordConfirm: string;
};

export const changeSelfFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    password: yup
      .string()
      .required(t("required"))
      .min(8, t("tooShort", { minLength: 8 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{0,}$/,
        t("atLeastOneNumAndChar")
      ),
    passwordConfirm: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("password"), null], t("passwordMustMatch")),
  })
);

type ChangeSelfPasswordFormProps = {
  selfId: number;
  fieldsErrors?: FieldsErrorState;
};

/**
 * Needs to be inside <FormProvider/>
 **/
export const ChangeSelfPasswordForm: React.FC<ChangeSelfPasswordFormProps> = ({
  selfId,
  fieldsErrors,
}) => {
  const { t } = useTranslation();
  const { register, control } = useFormContext<ChangeSelfPasswordFormType>();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <input
        type="hidden"
        defaultValue={selfId}
        {...register("id", { required: true, valueAsNumber: true })}
      />
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("newPassword")} *`}
        </StyledFieldTitle>
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "password",
              fieldsErrors
            );
            return (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder={`${t("newPassword")} *`}
                type="password"
                autoComplete="new-password"
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("passwordConfirmation")} *`}
        </StyledFieldTitle>
        <Controller
          control={control}
          name="passwordConfirm"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "password",
              fieldsErrors
            );
            return (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder={`${t("passwordConfirmation")} *`}
                type="password"
                autoComplete="new-password"
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
