import { colors, createTheme, ThemeOptions } from "@mui/material";
import background from "../../background.svg";

export const customColors = {
  white: "#FFFFFF",
  clearChill: "#2191FF",
  clearSky: "#8EC7FF",
  dissolvedDenim: "#E2EBF4",
};

export const backgroundImage = `url(${background})`;

export const defaultThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      contrastText: customColors.white,
      dark: "#00008B",
      main: "#1E90FF",
      light: "#00BFFF",
    },
    secondary: {
      contrastText: customColors.white,
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400],
    },
    success: {
      contrastText: customColors.white,
      dark: colors.green[900],
      main: colors.green[600],
      light: colors.green[400],
    },
    info: {
      contrastText: customColors.white,
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400],
    },
    warning: {
      contrastText: customColors.white,
      dark: colors.orange[900],
      main: colors.orange[600],
      light: colors.orange[400],
    },
    error: {
      contrastText: customColors.white,
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    background: {
      default: "#F4F6F8",
      paper: customColors.white,
    },
  },
  zIndex: {
    snackbar: 99999999999,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {},
    },
    MuiMenu: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: "1px solid rgb(0, 0, 0, 0.13)",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiAccordion: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
};

export const theme = createTheme(defaultThemeOptions);
