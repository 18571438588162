import React from "react";
import {
  FancyPagination,
  FancyPaginationProps,
} from "../../../components/FancyPagination";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const pageSizeOptions = [4, 8, 16];

type PaginationProps = Pick<
  FancyPaginationProps,
  "page" | "onPageChange" | "pageSize" | "onPageSizeChange" | "count"
>;

export const Pagination = ({ count, ...props }: PaginationProps) => {
  const { t } = useTranslation("pages");

  return (
    <Box sx={{ marginTop: "auto", paddingTop: 2 }}>
      <FancyPagination
        count={count}
        {...props}
        pageSizeOptions={pageSizeOptions}
        stackSx={{ justifyContent: "flex-end" }}
        countLabel={t("schoolInquiries.countLabel", { count })}
      />
    </Box>
  );
};
