import React from "react";

export const useTableControlsDrpdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    dropDownAnchor: anchorEl,
    handleDropDownClick: handleClick,
    handleDropDownClose: handleClose,
  };
};
