import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { Editor } from "@tiptap/react";

type ActionButtonProps = Omit<ButtonProps, "sx"> & {
  editor: Editor;
  name?: string;
};

export const ActionButton = ({
  editor,
  name,
  children,
  ...props
}: ActionButtonProps) => {
  const textDecoration = (() => {
    if (name) {
      return editor.isActive(name) ? "underline" : "none";
    }

    return "none";
  })();

  return (
    <Button sx={{ textDecoration }} {...props}>
      {children}
    </Button>
  );
};
