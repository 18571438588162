import { DefaultValues } from "react-hook-form/dist/types/form";
import { AddProductForm } from "../../../../slices/products/types";

export const productFormDefaultValues: DefaultValues<AddProductForm> = {
  supplierId: undefined,
  supplierProductCode: undefined,
  name: null,
  packSize: null,
  color: "",
  countryId: undefined,
  commodityCodeId: undefined,
  replacementCommodityCode: null,
  supplierPrice: null,
  retailPrice: null,
  weight: null,
  volume: null,
  sizeW: null,
  sizeD: null,
  sizeH: null,
  tagsNames: [],
  matches: [],
  images: [],
};
