import { Entity } from "../../entities";

export type CategoryLevel = "1" | "2";
export const categoryLevels: CategoryLevel[] = ["1", "2"];

export type ParentCategory = Entity & {
  name: string;
  level: CategoryLevel;
};

export type Category = Entity & {
  name: string;
  level: CategoryLevel;
  parentCategory: Category;
  color: string;
};

export type CategoryFlattened = Omit<Category, "parentCategory"> & {
  parentCategory: string;
  parentCategoryId: number;
  firstParentCategory: string;
  nameForSort: string;
};

export type AddCategoryRequestDto = Omit<
  Category,
  "createdAt" | "updatedAt" | "id" | "parentCategory"
> & {
  parentCategoryId: number;
};

export type EditCategoryRequestDto = Omit<
  Category,
  "createdAt" | "updatedAt" | "parentCategory"
> & {
  parentCategoryId: number;
};
