import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import {
  useEditCommoditycodeMutation,
  useGetCommoditycodesQuery,
} from "../../../slices/commoditycodes/api";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import {
  CommoditycodeForm,
  commoditycodeFormSchemaCreator,
} from "./CommoditycodeForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";

type EditCommoditycodeForm = {
  id: number;
  code: string;
  description: string;
  requireApproval: boolean;
};

export const Edit: React.FC = () => {
  const { id, closeDrawer } = useTableUrlTools("/commoditycodes");
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(commoditycodeFormSchemaCreator);
  const formMethods = useForm<EditCommoditycodeForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isLoading: isDataLoading,
    data,
    error: dataLoadingError,
  } = useGetCommoditycodesQuery();
  const [editCommoditycode, { isLoading: isEditLoading }] =
    useEditCommoditycodeMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("commoditycodes.errors.edit")
  );

  const commoditycodeData = useMemo(
    () =>
      data && id ? data.find((commoditycode) => commoditycode.id === id) : null,
    [data, id]
  );

  const onSubmit = useCallback(
    (form: EditCommoditycodeForm) =>
      editCommoditycode(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("commoditycodes.editSuccess"), `/commoditycodes/${id}`);
          closeDrawer();
        })
        .catch(catchFormError),
    [catchFormError, closeDrawer, editCommoditycode, feedSuccess, id, t]
  );

  useEffect(() => {
    if (dataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, dataLoadingError]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!commoditycodeData) {
    return null;
  }

  return (
    <Form
      heading={t("commoditycodes.editCommoditycode")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <CommoditycodeForm
          commoditycodeData={commoditycodeData}
          fieldsErrors={fieldsErrors}
        />
      </FormProvider>
    </Form>
  );
};
