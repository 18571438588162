import React, { useCallback } from "react";
import { Alert, Collapse, Paper, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PostEmailForgotPasswordDto } from "../../../slices/auth/types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fieldError,
  useSubmitFormErrorHander,
} from "../../../utils/formHelpers";
import { useForgotPasswordEmailMutation } from "../../../slices/auth/api";
import {
  FormHelperTextProps,
  InputLabelProps,
  NewDesignInputProps,
  StyledFieldTitle,
  StyledHelpBox,
  StyledSubmitSpinnerButton,
  StyledTitleBox,
} from "../StyledComponents";
import { BackToSignIn } from "./BackToSignIn";
import { useWindowSize } from "usehooks-ts";

const emailFormSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t("incorrectEmail", { ns: "validationErrors" }))
    .required(i18n.t("required", { ns: "validationErrors" })),
});

export const EmailForm: React.FC<{ setEmail: (email: string) => void }> = ({
  setEmail,
}) => {
  const size = useWindowSize();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<PostEmailForgotPasswordDto>({
    resolver: yupResolver(emailFormSchema),
  });

  const setUserEmail = useCallback(
    (newEmail) => setEmail(newEmail),
    [setEmail]
  );

  const [forgotPasswordEmail, { isLoading }] = useForgotPasswordEmailMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("error.signIn")
  );

  const onSubmit = useCallback(
    async (fields: PostEmailForgotPasswordDto) => {
      forgotPasswordEmail({ email: fields.email })
        .unwrap()
        .then(() => {
          setUserEmail(fields.email);
        })
        .catch((error: unknown) => {
          catchFormError(error);
        });
    },
    [catchFormError, forgotPasswordEmail, setUserEmail]
  );

  return (
    <Paper elevation={0}>
      <Stack
        spacing={4}
        sx={{
          marginTop: `${size.height < 556 ? "20" : (size.height - 516) / 2}px`,
          marginBottom: `${size.height < 556 ? "20" : "0"}px`,
          paddingTop: "65px",
          paddingInline: "110px",
          paddingBottom: "120px",
          border: "solid 1px #E7F1FF",
          borderRadius: "4px",
        }}
        component="form"
        autoComplete="on"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={0} sx={{ marginTop: "30px" }}>
          <StyledTitleBox>
            {t("forgotpassword.forgotpasswordLink", { ns: "pages" })}
          </StyledTitleBox>
          <StyledHelpBox sx={{ marginTop: "13px", marginBottom: "6px" }}>
            {t("forgotpassword.enterEmail", { ns: "pages" })}
          </StyledHelpBox>
        </Stack>
        {formError && (
          <Collapse in={!!formError}>
            <Alert severity="error">{formError}</Alert>
          </Collapse>
        )}
        <StyledFieldTitle sx={{ marginTop: "7px" }}>
          {t("emailNew")}
        </StyledFieldTitle>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "email",
              fieldsErrors
            );
            return (
              <TextField
                sx={{ marginTop: "0px !important" }}
                variant="outlined"
                size="small"
                placeholder={t("emailNew")}
                {...field}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                InputLabelProps={InputLabelProps}
                InputProps={NewDesignInputProps}
                FormHelperTextProps={FormHelperTextProps}
              />
            );
          }}
        />
        <StyledSubmitSpinnerButton
          loading={isLoading}
          fullWidth
          sx={{ marginTop: "-16px" }}
        >
          {t("forgotpassword.sendPin", { ns: "pages" })}
        </StyledSubmitSpinnerButton>
        <BackToSignIn />
      </Stack>
    </Paper>
  );
};
