import React from "react";
import { Grid } from "@mui/material";
import { Control } from "react-hook-form";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { SendToAstraForm as SendToAstraFormType } from "../../../../slices/schoolInquiries/types";
import { useTranslation } from "react-i18next";
import { SelectDeliveryTerms } from "./SelectDeliveryTerms";
import { StyledFieldTitle } from "../../../SignIn/StyledComponents";
import { NewDesignControlledTextField } from "../../../../components/controlledFields/NewDesignControlledTextField";

type SendToAstraFormProps = {
  control: Control<SendToAstraFormType>;
  fieldsErrors?: FieldsErrorState;
};

export const SendToAstraForm = ({
  control,
  fieldsErrors,
}: SendToAstraFormProps) => {
  const { t } = useTranslation("pages");

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 12">
        <StyledFieldTitle
          sx={{ marginTop: "0px !important", whiteSpace: "nowrap" }}
        >
          {`${t(
            "schoolInquiryDetails.inquiryControls.drawer.fields.paymentTerms"
          )}`}
        </StyledFieldTitle>
        <NewDesignControlledTextField
          control={control}
          name="paymentTerms"
          label={t(
            "schoolInquiryDetails.inquiryControls.drawer.fields.paymentTerms"
          )}
          fieldsErrors={fieldsErrors}
          disableLabel
        />
      </Grid>
      <SelectDeliveryTerms fieldsErrors={fieldsErrors} control={control} />
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("schoolInquiryDetails.inquiryControls.drawer.fields.note")}`}
        </StyledFieldTitle>
        <NewDesignControlledTextField
          control={control}
          name="note"
          fieldsErrors={fieldsErrors}
          disableLabel
          minRows={6}
          multiline
        />
      </Grid>
    </Grid>
  );
};
