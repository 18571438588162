import { useAdminSchoolInquiryTools } from "../../containers/SchoolInquiries/hooks";
import { useGetSchoolQuery } from "../schools/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useSelector } from "../../store";
import { selectSelfSchoolCurrency } from "../auth/selectors";
import { useMemo } from "react";
import { useGetCurrenciesQuery } from "./api";

export const useGetSchoolCurrency = () => {
  const adminTools = useAdminSchoolInquiryTools();

  const selfSchoolCurrency = useSelector(selectSelfSchoolCurrency);

  const { data: school, isLoading: isSchoolLoading } = useGetSchoolQuery(
    adminTools?.schoolId || skipToken
  );
  const { data: currencies, isLoading: areCurrenciesLoading } =
    useGetCurrenciesQuery();

  return useMemo(
    () => ({
      result: currencies?.find(
        ({ code }) =>
          code ===
          (adminTools?.schoolId
            ? school?.currency.code
            : selfSchoolCurrency?.code)
      ),
      isLoading: isSchoolLoading || areCurrenciesLoading,
    }),
    [
      adminTools?.schoolId,
      areCurrenciesLoading,
      currencies,
      isSchoolLoading,
      school?.currency.code,
      selfSchoolCurrency?.code,
    ]
  );
};
