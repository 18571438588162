import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { Role, roles } from "../../permissions/permissions";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store";

import { selectSelfRole } from "../../slices/auth/selectors";

export type SelectRoleProps = Omit<FormControlProps, "id" | "onChange"> & {
  id: string;
  label: string;
  helperText?: string;
  selectProps: SelectProps;
  availableRoles?: Readonly<Role[]> | Role[];
};

export const SelectRole = ({
  id,
  label,
  helperText,
  selectProps,
  availableRoles = roles,
  ...props
}: SelectRoleProps) => {
  const { t } = useTranslation();
  const selfRole = useSelector(selectSelfRole);

  const filteredRoles = roles.map((role) => {
    const available = availableRoles.includes(role);

    if (!available && selfRole !== role) {
      return null;
    }

    return (
      <MenuItem
        key={role}
        value={role}
        disabled={!available && selfRole === role}
      >
        {t(`roles.${role}`, { ns: "common" })}
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth {...props}>
      <Select
        labelId={`custom-datagrid-filter-${id}-label`}
        id={`custom-datagrid-filter-${id}`}
        placeholder={label}
        disabled={selfRole === "headOfDepartment" || props.disabled}
        {...selectProps}
      >
        {filteredRoles}
        {roles.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
