import React, { useCallback } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { IconButton } from "./astraComponents/IconButton";
import { Close } from "./astraIcons/Close";

type ModalProps = Omit<MuiDialogProps, "onClose"> & {
  onClose?: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "closeIconClick"
  ) => void;
};

export const Dialog = ({
  title,
  about,
  children,
  onClose,
  ...props
}: ModalProps) => {
  const theme = useTheme();

  const closeClickHandler = useCallback(() => {
    if (onClose) {
      onClose({}, "closeIconClick");
    }
  }, [onClose]);

  return (
    <MuiDialog
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: { maxWidth: "595px" },
      }}
      {...props}
    >
      {onClose && (
        <IconButton
          variant="transparent"
          sx={{
            position: "absolute",
            top: "16px",
            right: "24px",
            color: theme.palette.primary.dark,
          }}
          onClick={closeClickHandler}
        >
          <Close />
        </IconButton>
      )}
      {title && (
        <DialogTitle
          sx={{
            fontSize: "20px",
            lineHeight: "140%",
            color: theme.palette.primary.dark,
            padding: 0,
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 0 }}>
        {about && (
          <Typography
            component="span"
            sx={{
              marginTop: "1px",
              fontSize: "14px",
              lineHeight: "135%",
              color: theme.palette.secondary.main,
            }}
          >
            {about}
          </Typography>
        )}
        {children}
      </DialogContent>
    </MuiDialog>
  );
};
