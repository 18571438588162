import React, { useCallback, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { Trash } from "../../../../../components/astraIcons/Trash";
import { colors } from "../../../../../theme/astra/colors";
import { PriceCurrency } from "../../../../../components/PriceCurrency";
import { CartItem } from "../../../../../slices/shop/types";
import { useTranslation } from "react-i18next";
import photoPlaceholder from "../../../../../assets/no-photo.svg";
import { useSelector } from "../../../../../store";
import { selectIsCartOpen } from "../../../../../slices/shop/selectors";
import {
  NumericField,
  NumericFieldProps,
} from "../../../../../components/fields/NumericField";
import { Badge } from "./Badge";
import { LoadingIconButton } from "../../../../../components/LoadingIconButton";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { CroppedTextWithTrailing } from "./CroppedTextWithTrailing";

type ItemProps = Pick<
  CartItem["product"],
  "id" | "images" | "name" | "retailCurrency"
> &
  Pick<
    CartItem,
    | "quantity"
    | "requestedItemSchoolStatus"
    | "assignmentSchoolStatus"
    | "totalAmount"
  > & {
    quantityInputValue: NumericFieldProps["value"];
    onQuantityInputValueChange: (value: NumericFieldProps["value"]) => void;
    onQuantityChange: (currentQuantity: number, newQuantity: number) => void;
    isChangingQuantity?: boolean;
    onRemoveItem: () => void;
    isRemoving?: boolean;
  };

export const Item = ({
  id,
  images,
  name,
  totalAmount,
  retailCurrency,
  requestedItemSchoolStatus,
  assignmentSchoolStatus,
  quantity,
  quantityInputValue,
  onQuantityInputValueChange,
  onQuantityChange,
  isChangingQuantity,
  onRemoveItem,
  isRemoving,
}: ItemProps) => {
  const { t } = useTranslation("layout");
  const theme = useTheme();

  const self = useSelector(selectSelf);
  const isCartOpen = useSelector(selectIsCartOpen);

  const quantityChangeHandler = useCallback<NumericFieldProps["onChange"]>(
    (value, type) => {
      onQuantityInputValueChange(value);

      if (value === null) {
        return;
      }

      const newQuantity = typeof value === "string" ? parseInt(value) : value;

      if (type === "click") {
        onQuantityChange(quantity, newQuantity);
        return;
      }
    },
    [onQuantityChange, onQuantityInputValueChange, quantity]
  );

  const quantityBlurHandler = useCallback(() => {
    if (quantityInputValue === null) {
      return;
    }

    const newQuantity =
      typeof quantityInputValue === "string"
        ? parseInt(quantityInputValue)
        : quantityInputValue;

    onQuantityChange(quantity, newQuantity);
  }, [onQuantityChange, quantityInputValue, quantity]);

  const changingQuantityDisabled = useMemo(() => {
    if (self?.role !== "headOfDepartment" && self?.role !== "teacher") {
      return true;
    }

    if (
      assignmentSchoolStatus !== "new" &&
      assignmentSchoolStatus !== "inProgress" &&
      assignmentSchoolStatus !== "readyForSending" &&
      assignmentSchoolStatus !== "needReviewByTeacher"
    ) {
      return true;
    }

    if (self?.role === "headOfDepartment") {
      return (
        requestedItemSchoolStatus !== "new" &&
        requestedItemSchoolStatus !== "approvedByHead" &&
        requestedItemSchoolStatus !== "needReviewByTeacher"
      );
    }

    if (self?.role === "teacher") {
      return (
        requestedItemSchoolStatus !== "new" &&
        requestedItemSchoolStatus !== "approvedByTeacher" &&
        requestedItemSchoolStatus !== "needReviewByTeacher"
      );
    }
  }, [assignmentSchoolStatus, requestedItemSchoolStatus, self?.role]);

  const image = images[0];

  return (
    <Box
      sx={{
        paddingTop: isCartOpen ? "24px" : "20px",
        paddingRight: "16px",
        paddingBottom: isCartOpen ? "24px" : "20px",
        paddingLeft: "16px",
        display: "flex",
        gap: "16px",

        "&:first-child": {
          paddingTop: "24px",
        },

        "&:last-child": {
          paddingBottom: "24px",
        },
      }}
    >
      <Box
        sx={{
          width: isCartOpen ? "auto" : "64px",
          height: "64px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            position: "relative",
            flexGrow: 0,
            flexShrink: 0,
            width: "64px",
            height: "64px",
            border: `1px solid ${colors.dustBlue5}`,
            borderRightWidth: isCartOpen ? 0 : "1px",
            borderRadius: isCartOpen ? "4px 0 0 4px" : "4px",
          }}
        >
          <Badge quantity={quantity} />
          <Box
            component="img"
            src={image ?? photoPlaceholder}
            sx={{
              width: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
              maxWidth: image ? "100%" : "75%",
              maxHeight: image ? "100%" : "75%",
            }}
          />
        </Box>
        {isCartOpen && (
          <NumericField
            name={`cart-product-${id}-quantity`}
            value={quantityInputValue}
            onChange={quantityChangeHandler}
            onBlur={quantityBlurHandler}
            positiveOnly
            disabled={changingQuantityDisabled || isChangingQuantity}
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              width: "80px",
              position: "relative",

              ".MuiOutlinedInput-root": {
                padding: 0,
              },

              ".MuiOutlinedInput-input": {
                height: "64px",
                padding: 0,
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "500",
              },

              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "0 4px 4px 0",
                borderColor: colors.dustBlue5,
              },

              ".MuiIconButton-root": {
                width: "24px",
                height: "24px",

                svg: {
                  width: "16px",
                  height: "16px",
                  color: `${theme.palette.primary.main} !important`,
                },

                "&.Mui-disabled svg": {
                  color: `${colors.dustBlue5} !important`,
                },
              },

              ".MuiInputAdornment-root": {
                position: "absolute",
              },

              ".MuiInputAdornment-positionStart": {
                left: "6px",
              },

              ".MuiInputAdornment-positionEnd": {
                right: "6px",
              },
            }}
          />
        )}
      </Box>
      {isCartOpen && (
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CroppedTextWithTrailing text={name || ""} />
          <Box
            sx={{
              marginTop: "4px",
              position: "relative",
            }}
          >
            <PriceCurrency
              hideCurrencyWhenNoBudget
              price={totalAmount}
              currency={
                retailCurrency?.code ?? t("currencies.qar", { ns: "common" })
              }
              sx={{ color: "primary.dark" }}
              priceProps={{
                sx: {
                  fontSize: "18px",
                  lineHeight: "24px",
                },
              }}
              currencyProps={{
                sx: {
                  fontSie: "10px",
                  lineHeight: "24px",
                },
              }}
            />
            <LoadingIconButton
              variant="transparent"
              sx={{
                color: "secondary.light",
                position: "absolute",
                right: "-5px",
                bottom: "-5px",

                "&:hover": {
                  color: "primary.main",
                },
              }}
              onClick={onRemoveItem}
              loading={isRemoving}
              disabled={changingQuantityDisabled || isChangingQuantity}
            >
              <Trash />
            </LoadingIconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};
