import { useCallback, useMemo } from "react";

const locale = "en-US";

export const useDisplayDateTime = (includeTime = true) => {
  const monthFormat = useMemo(
    () => new Intl.DateTimeFormat(locale, { month: "short" }),
    []
  );

  const timeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        timeStyle: "short",
      }),
    []
  );

  return useCallback(
    (timestamp: string | number | Date) => {
      const date = new Date(timestamp);

      const dateString = [
        date.getFullYear(),
        monthFormat.format(date),
        date.getDate(),
      ].join("-");

      if (includeTime) {
        return `${dateString}, ${timeFormat.format(date)}`;
      }

      return dateString;
    },
    [includeTime, monthFormat, timeFormat]
  );
};
