import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getTitle } from "../../utils/getTitle";
import { Content } from "./Content/Content";
import { Drawers } from "./Drawers/Drawers";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";

export const Searches = () => {
  const { t } = useTranslation("pages");

  const title = t("searches.longTitle");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <Content />
      <Drawers />
    </>
  );
};
