import React, { memo } from "react";
import { Skeleton, SkeletonProps } from "@mui/material";
import { randomInt } from "../utils/randomInt";

type Range = [min: number, max: number, unit?: string];

type EnhancedSkeletonProps = {
  width?: SkeletonProps["width"] | Range;
  height?: SkeletonProps["height"] | Range;
};

export const EnhancedSkeleton = memo(
  ({
    width,
    height,
    ...props
  }: Omit<SkeletonProps, "width" | "height"> & EnhancedSkeletonProps) => {
    return (
      <Skeleton
        width={
          typeof width === "object"
            ? `${randomInt(width[0], width[1])}${width[2] ?? "px"}`
            : width
        }
        height={
          typeof height === "object"
            ? `${randomInt(height[0], height[1])}${height[2] ?? "px"}`
            : height
        }
        {...props}
      />
    );
  }
);
