import React, { memo } from "react";
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Circular } from "./spinners/Circular";

export type SubmitAbsoluteSpinnerButtonProps = Omit<
  ButtonProps,
  "type" | "variant"
> & {
  loading?: boolean;
};
/*
 * This is component for old design. Replace with NewDesignContextMenu if possible
 * @deprecated
 */

export const SubmitAbsoluteSpinnerButton =
  memo<SubmitAbsoluteSpinnerButtonProps>(
    ({ loading, disabled, children, ...props }) => {
      return (
        <ButtonBox>
          <Button
            variant="outlined"
            type="submit"
            disabled={loading || disabled}
            {...props}
          >
            {children}
          </Button>
          {loading && (
            <SpinnerBox>
              <Circular size={25} thickness={175} />
            </SpinnerBox>
          )}
        </ButtonBox>
      );
    }
  );

const ButtonBox = styled(Box)({
  display: "flex",
  position: "relative",
});

const SpinnerBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  margin: 0,
  marginTop: "7px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});
