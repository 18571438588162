import React from "react";
import { Box } from "@mui/material";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";

export const ProductSkeleton = () => {
  return (
    <Box
      sx={{
        minHeight: "467px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <EnhancedSkeleton
        variant="rectangular"
        sx={{
          borderRadius: "4px",
          width: "100%",
          height: "auto",
          aspectRatio: "1 / 1",
        }}
      />
      <EnhancedSkeleton
        variant="text"
        sx={{
          marginTop: "26px",
          fontSize: "16px",
          lineHeight: "20px",
        }}
        width={[50, 100, "%"]}
      />
      <EnhancedSkeleton
        variant="text"
        sx={{
          fontSize: "12px",
          lineHeight: "24px",
        }}
        width={[30, 70, "%"]}
      />
      <EnhancedSkeleton
        variant="text"
        sx={{
          marginTop: "auto",
          marginBottom: "46px",
          fontSize: "22px",
          lineHeight: "24px",
        }}
        width={[20, 50, "%"]}
      />
    </Box>
  );
};
