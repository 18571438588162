import { useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";

type YupSchemaCreator<Schema> = (t: TFunction<"validationErrors">) => Schema;

export const createYupSchema = <Schema>(creator: YupSchemaCreator<Schema>) =>
  creator;

export const useYup = <Schema>(creator: YupSchemaCreator<Schema>): Schema => {
  const { t } = useTranslation("validationErrors");
  return useMemo(() => creator(t), [creator, t]);
};
