import React from "react";
import { theme } from "../../theme/astra/theme";
import { Box, CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { GlobalContainer } from "../../components/layout/GlobalContainer";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer";
import { Cart } from "./Cart/Cart";
import { useIsCartDisabled } from "../../slices/shop/hooks";
import { ChangeSelfPasswordDrawer } from "../../components/legacyLayout/CurrentUser/ChangeSelfPasswordDrawer";

type ShopProps = {
  children: React.ReactNode;
  cart?: boolean;
};

export const MainLayout = ({ children, cart = false }: ShopProps) => {
  const isCartDisabled = useIsCartDisabled();
  const isCartVisible = !isCartDisabled && cart;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}
      <SnackbarProvider maxSnack={3}>
        <GlobalContainer header>
          <Header />
          <Box sx={{ paddingRight: isCartVisible ? "97px" : 0 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {children}
              <Footer />
            </Box>
            {isCartVisible && <Cart />}
          </Box>
          <ChangeSelfPasswordDrawer />
        </GlobalContainer>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        letterSpacing: "0.15px",
      },

      ".SnackbarContainer-root": {
        width: "auto !important",
      },

      ".SnackbarContainer-bottom": {
        bottom: "26px !important",
      },

      ".SnackbarContainer-left": {
        left: "32px !important",
      },
    }}
  />
);
