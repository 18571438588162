import React from "react";
import { ChevronDown } from "../../../astraIcons/ChevronDown";
import {
  IconButton,
  IconButtonProps,
} from "../../../astraComponents/IconButton";
import { colors } from "../../../../theme/astra/colors";

type ExpandButtonProps = Omit<IconButtonProps, "variant" | "sx"> & {
  expanded?: boolean;
};

export const ExpandButton = ({ expanded, ...props }: ExpandButtonProps) => {
  return (
    <IconButton
      variant="transparent"
      sx={{
        width: "24px",
        height: "24px",

        ".MuiSvgIcon-root": {
          width: "8px",
          transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
          color: colors.darkBlue,
        },
      }}
      {...props}
    >
      <ChevronDown />
    </IconButton>
  );
};
