import React, { useCallback } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Search as SearchIcon } from "../../../components/astraIcons/Search";

export type SearchFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

export const SearchField = ({ value, onChange }: SearchFieldProps) => {
  const { t } = useTranslation("pages", { keyPrefix: "shopCatalog.filters" });

  const changeHandler = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    ({ target: { value } }) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <TextField
      value={value}
      onChange={changeHandler}
      variant="outlined"
      size="medium"
      placeholder={t("searchPlaceholder")}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
      sx={{
        ".MuiOutlinedInput-root": {
          paddingLeft: "16px",
        },

        ".MuiOutlinedInput-input": {
          paddingLeft: "4px",
        },

        ".MuiOutlinedInput-notchedOutline": {
          borderRadius: 0,
          marginLeft: "-1px",
        },

        ":hover, .Mui-focused": {
          zIndex: 1,
          position: "relative",
        },
      }}
    />
  );
};
