import React from "react";
import { Box, BoxProps } from "@mui/material";
import Carousel from "react-material-ui-carousel";

export type Image = {
  src: string;
  alt?: string;
};

export type ImageSliderProps = {
  className?: string;
  sx?: BoxProps["sx"];
  images: Image[];
  height: number;
};

export const ImageSliderFixedHeight = ({
  images,
  height,
  sx,
  ...props
}: ImageSliderProps) => {
  return (
    <Box sx={{ height: `${height}px`, ...sx }} {...props}>
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysInvisible={images.length === 1}
        height={height}
      >
        {images.map(({ src, alt }) => (
          <Box
            key={src}
            component="img"
            src={src}
            alt={alt}
            sx={{
              width: "inherit",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        ))}
      </Carousel>
    </Box>
  );
};
