import React, { useCallback } from "react";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { NavigationDrawer } from "./NavigationDrawer";
import { useDispatch, useSelector } from "../../store";
import { Menu } from "@mui/icons-material";
import { HeaderUserActions } from "./HeaderUserActions";
import { getNormalizedName } from "../../utils/getNormalizedName";
import { getShortText } from "../../utils/getShortText";
import { selectTitle } from "../../slices/ui/selectors";
import { setChangePasswordDrawer } from "../../slices/ui/slice";

type HeaderProps = {
  authenticated: boolean;
  drawerOpenState: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
};

/**
 * This is used in old design. Shouldn't be used anymore, except for the
 * places it's used already.
 *
 * Will be removed eventually.
 * @deprecated
 * @component
 */
export const Header = ({
  authenticated,
  drawerOpenState,
  openDrawer,
  closeDrawer,
}: HeaderProps) => {
  const dispatch = useDispatch();

  const title = useSelector(selectTitle);

  const userSelfData = useSelector((state) => state.auth.user);

  const userName = getNormalizedName(userSelfData);
  const intId = userSelfData?.id;

  const openChangeSelfPssswordDrawer = useCallback(() => {
    dispatch(setChangePasswordDrawer(true));
  }, [dispatch]);

  return (
    <>
      <HeaderContainer
        position="fixed"
        drawerOpen={authenticated && drawerOpenState}
      >
        <StyledToolbar>
          <ToolbarLeftBox>
            {authenticated && !drawerOpenState && (
              <StyledIconButton
                onClick={() => {
                  openDrawer();
                }}
              >
                <Menu />
              </StyledIconButton>
            )}
            <Link to="/">
              <Img src={logo} />
            </Link>
            {title && (
              <Typography
                variant="h6"
                sx={{
                  paddingLeft: "20px",
                  color: "text.primary",
                }}
              >
                {getShortText(title, 30)}
              </Typography>
            )}{" "}
          </ToolbarLeftBox>
          {authenticated && intId && (
            <HeaderUserActions
              id={intId}
              userName={userName}
              userRole={userSelfData?.role}
              openChangeSelfPssswordDrawer={openChangeSelfPssswordDrawer}
            />
          )}
        </StyledToolbar>
        <Divider />
      </HeaderContainer>
      <NavigationDrawer
        open={authenticated && drawerOpenState}
        closeDrawer={closeDrawer}
      />
    </>
  );
};

const HeaderContainer = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})<{
  drawerOpen?: boolean;
}>(({ theme, drawerOpen }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  width: drawerOpen ? "calc(100% - 240px)" : "100%",
  marginLeft: drawerOpen ? "240px" : 0,
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const ToolbarLeftBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const StyledIconButton = styled(IconButton)({
  marginRight: "20px",
});

const Img = styled("img")({
  display: "block",
  maxHeight: "40px",
});
