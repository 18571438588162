import React, { useCallback, useMemo } from "react";
import {
  CustomDataGridServerSide,
  CustomDataGridServerSideProps,
} from "../../../components/CustomDataGrid/CustomDataGridServerSide";
import { MultipleSelectFilter } from "../../../components/toolbar/MultipleSelectFilter";
import { useTranslation } from "react-i18next";
import { DEFAULT_INQUIRY_SORT_MODEL } from "../../../consts";
import { inquiryStatuses } from "../../../types";

type CustomDataGridWithFiltersProps = Omit<
  CustomDataGridServerSideProps,
  "filters"
> & {
  statusesFilter: string[];
  setStatusesFilter: (value: string[]) => void;
};

export const FilteredCustomDataGrid: React.FC<CustomDataGridWithFiltersProps> =
  ({ rows, statusesFilter, setStatusesFilter, ...props }) => {
    const { t } = useTranslation("pages");

    const filters = useMemo(
      () => (
        <MultipleSelectFilter
          size="small"
          id="by-inquiry-statuses"
          label={t("inquiries.filters.status")}
          options={inquiryStatuses.map((status) => ({
            label: t(`inquiries.statuses.${status}`),
            value: status,
          }))}
          onChange={setStatusesFilter}
          value={statusesFilter}
        />
      ),
      [statusesFilter, setStatusesFilter, t]
    );

    const resetFilters = useCallback(() => {
      setStatusesFilter([]);
    }, [setStatusesFilter]);

    return (
      <CustomDataGridServerSide
        rows={rows}
        filters={filters}
        resetCustomFilters={resetFilters}
        defaultSortModel={DEFAULT_INQUIRY_SORT_MODEL}
        {...props}
      />
    );
  };
