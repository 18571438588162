import React from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../../../entities";
import { NewDesignEntityDetailsGrid } from "../../../../components/NewDesignEntityDetailsGrid";
import { Box, Typography } from "@mui/material";

type UserDetailsProps = {
  user: User;
};

export const UserDetails = ({ user }: UserDetailsProps) => {
  const { t } = useTranslation("pages");

  return (
    <NewDesignEntityDetailsGrid
      compact
      blocks={[
        [
          {
            label: t("users.fields.email"),
            value: user.email,
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("users.fields.role"),
            value: t(`roles.${user.role}`, { ns: "common" }),
            xs: 12,
          },
        ],
        "divider",
        [
          {
            label: t("users.fields.status"),
            view: (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#164173", //Dark blue
                  }}
                >
                  {user.active
                    ? t("users.statusValues.active")
                    : t("users.statusValues.inactive")}
                </Typography>
              </Box>
            ),
            xs: 12,
          },
          ...(user.role === "inquiryManager"
            ? [
                {
                  label: t("users.fields.inquiries"),
                  value:
                    user.inquiries?.map((inquiry) => inquiry.name).join(", ") ??
                    "",
                  xs: 12,
                },
              ]
            : []),
        ],
      ]}
    />
  );
};
