import React, { useMemo } from "react";
import { useSchoolInquiriesList } from "../../hooks";
import { useGetSchoolInquiriesForTeachersQuery } from "../../../../slices/schoolInquiries/api";
import { NoInquiries } from "../NoInquiries";
import { ListSkeleton } from "../ListSkeleton";
import { Box, Grid } from "@mui/material";
import { Pagination } from "../Pagination";
import { ContainerBase } from "../../../../components/layout/ContainerBase";
import { SchoolInquiriesToolbar } from "../SchoolInquiriesToolbar";
import { InquiryLabelBox } from "../InquiryLabelBox";
import { DepartmentInquiry } from "./DepartmentInquiry/DepartmentInquiry";

export const TeachersList = () => {
  const {
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    filter,
    filterChangeHandler,
    userId,
  } = useSchoolInquiriesList();

  const { data, isLoading } = useGetSchoolInquiriesForTeachersQuery({
    page,
    pageSize,
    filter,
    userId,
  });

  const skeletons = useMemo(() => {
    if (!isLoading) {
      return null;
    }

    return <ListSkeleton pageSize={pageSize} />;
  }, [isLoading, pageSize]);

  const content = useMemo(() => {
    if (isLoading || !data) {
      return null;
    }

    if (data.data.length === 0) {
      return <NoInquiries />;
    }

    return (
      <>
        <Grid container spacing={2}>
          {data.data.map((inquiry) => (
            <Grid key={inquiry.id} item xs={12}>
              <InquiryLabelBox
                inquiryName={inquiry.name}
                schoolName={inquiry.school.name}
              >
                {inquiry.departmentInquiries.map((departmentInquiry) => (
                  <DepartmentInquiry
                    key={`department-list-${inquiry.id}-department-${departmentInquiry.id}-teacher`}
                    inquiryId={inquiry.id}
                    inquirySchoolStatus={inquiry.schoolStatus}
                    departmentInquiry={departmentInquiry}
                  />
                ))}
              </InquiryLabelBox>
            </Grid>
          ))}
        </Grid>
        <Pagination
          page={page}
          onPageChange={pageChangeHandler}
          pageSize={pageSize}
          onPageSizeChange={pageSizeChangeHandler}
          count={data.count}
        />
      </>
    );
  }, [
    data,
    isLoading,
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
  ]);

  return (
    <ContainerBase sx={{ paddingInline: "15px" }} maxWidth="xl">
      <Box sx={{ display: "flex", width: "100%" }}>
        <SchoolInquiriesToolbar
          isLoading={isLoading}
          filter={filter}
          onFilterChange={filterChangeHandler}
          filterCount={data?.filterCount}
        />
      </Box>
      {skeletons}
      {content}
    </ContainerBase>
  );
};
