import { SuppliersProps } from "../Suppliers";
import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { FilterChip } from "./FilterChip";
import { useGetSuppliersQuery } from "../../../../slices/suppliers/api";
import { SuppliersFilterValue } from "../../types";

export const SuppliersChip = ({ value, onChange }: SuppliersProps) => {
  const { t } = useTranslation("pages");

  const { data: suppliers } = useGetSuppliersQuery({
    publicOnly: true,
  });

  const removeHandler = useCallback(() => {
    if (suppliers) {
      const newValue: SuppliersFilterValue = {};

      for (const { id } of suppliers) {
        newValue[id] = false;
      }

      onChange(newValue);
    }
  }, [onChange, suppliers]);

  if (!suppliers) {
    return null;
  }

  const values = suppliers
    .filter(({ id }) => value[id])
    .map(({ name }) => name);

  if (values.length === 0) {
    return null;
  }

  return (
    <FilterChip
      name={`${t("shopCatalog.filters.suppliers")}:`}
      value={values.join(", ")}
      onRemove={removeHandler}
    />
  );
};
