import { Paper, styled } from "@mui/material";

type FieldBoxProps = {
  focused?: boolean;
  error?: boolean;
};

export const FieldBox = styled(Paper)<FieldBoxProps>(
  ({ theme, focused, error }) => {
    const borderColor = (() => {
      if (error) {
        return theme.palette.error.main;
      }

      if (focused) {
        return theme.palette.primary.main;
      }

      return theme.palette.grey[400];
    })();

    const textAreaPadding = focused
      ? `calc(${theme.spacing(2)} - 1px)`
      : theme.spacing(2);

    return {
      borderWidth: focused ? "2px" : "1px",
      borderColor,

      ".wysiwyg-field": {
        paddingLeft: textAreaPadding,
        paddingRight: textAreaPadding,
        paddingBottom: textAreaPadding,
        outline: "none",

        p: {
          "&:fist-child": {
            marginTop: 0,
          },
          "&:last-child": {
            marginBottom: 0,
          },
        },

        table: {
          borderCollapse: "collapse",
          tableLayout: "fixed",
          width: "100%",
          overflow: "hidden",
          margin: 0,

          p: {
            margin: 0,
          },

          "th, td": {
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: theme.palette.grey[400],
            verticalAlign: "top",
            boxSizing: "border-box",

            "> *": {
              margin: 0,
            },
          },

          th: {
            backgroundColor: theme.palette.grey[100],
          },
        },
      },
    };
  }
);
