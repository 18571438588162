import { useTableTools as useTableToolsCore } from "../../../utils/useContentTools";
import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";

const localStorageLabel = "searches";

export const useTableTools = () => {
  const [from, setFrom] = useLocalStorage<string | null>(
    `table-${localStorageLabel}.from`,
    null
  );
  const fromChangeHandler = useCallback(
    (date: Date | null) => {
      try {
        setFrom(date?.toISOString() ?? null);
      } catch (e) {
        /* Do nothing when date is incorrect. Waiting until user input somethink normal */
      }
    },
    [setFrom]
  );

  const [to, setTo] = useLocalStorage<string | null>(
    `table-${localStorageLabel}.to`,
    null
  );

  const [users, setUsers] = useLocalStorage<string | null>(
    `table-${localStorageLabel}.users`,
    null
  );

  const [schools, setSchools] = useLocalStorage<string | null>(
    `table-${localStorageLabel}.schools`,
    null
  );

  const toChangeHandler = useCallback(
    (date: Date | null) => {
      try {
        setTo(date?.toISOString() ?? null);
      } catch (e) {
        /* Do nothing when date is incorrect. Waiting until user input somethink normal */
      }
    },
    [setTo]
  );

  const resets = useCallback(() => {
    setFrom(null);
    setTo(null);
    setSchools(null);
    setUsers(null);
  }, [setFrom, setSchools, setTo, setUsers]);

  return {
    ...useTableToolsCore(localStorageLabel, {
      resets,
      defaultSortModel: [
        {
          field: "createdAt",
          sort: "desc",
        },
      ],
    }),
    from: from ? new Date(from) : null,
    fromChangeHandler,
    to: to ? new Date(to) : null,
    toChangeHandler,
    users,
    setUsers,
    schools,
    setSchools,
  };
};
