import React, { useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { Actions } from "./Actions";
import { useGetMainOrdersQuery } from "../../../slices/mainOrders/api";
import { usePermissions } from "../../../utils/usePermissions";
import { GroupsSwitch } from "../GroupsSwitch";
import { useGetInquiryDetailsQuery } from "../../../slices/inquiries/api";
import { Link as MaterialLink } from "@mui/material";
import { DownloadFileCellBtn } from "./DownloadFileCellBtn";
import { DEFAULT_SORT_MODEL_DESC } from "../../../consts";
import { useInquiryDetailsUrlTools } from "../hooks";
import { MainOrderFlattened } from "../../../slices/mainOrders/types";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";

export const DataTable = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const history = useHistory();
  const displayDate = useDisplayDateTime(false);
  const { baseUrl, inquiryId } = useInquiryDetailsUrlTools();

  const { data: inquiry } = useGetInquiryDetailsQuery(inquiryId);
  const { isLoading, isFetching, data, error, refetch } =
    useGetMainOrdersQuery(inquiryId);

  const onAddClick = useCallback(
    () => history.push(`${baseUrl}/mo/add`),
    [baseUrl, history]
  );

  const columns = useMemo<GridColDef<MainOrderFlattened>[]>(
    () => [
      {
        field: "id",
        headerName: t("mainOrders.fields.id"),
        flex: 0.1,
        minWidth: 60,
        type: "number",
      },
      {
        field: "supplier",
        headerName: t("mainOrders.fields.supplier"),
        flex: 1,
      },
      {
        field: "referenceNumber",
        headerName: t("mainOrders.fields.referenceNumber"),
        flex: 1,
        renderCell: (params) => {
          if (!can("getMainOrder")) {
            return <>{params.value}</>;
          }
          return (
            <MaterialLink component={Link} to={`${baseUrl}/mo/${params.id}`}>
              {params.value}
            </MaterialLink>
          );
        },
      },
      {
        field: "itemsCount",
        headerName: t("mainOrders.fields.items"),
        flex: 1,
        renderCell: ({ row }) => (
          <MaterialLink component={Link} to={`${baseUrl}/mo/${row.id}/items`}>
            {row.items}
          </MaterialLink>
        ),
      },
      {
        field: "date",
        headerName: t("mainOrders.fields.date"),
        flex: 1,
        renderCell: (params) => displayDate(params.value),
      },
      {
        field: "items",
        headerName: t("mainOrders.fields.items"),
        flex: 0.5,
      },
      {
        field: "downloadFile",
        headerName: t("mainOrders.fields.uploadedFile"),
        flex: 1,
        renderCell: (params) => {
          return (
            <DownloadFileCellBtn
              mo_id={params.id}
              // @ts-ignore no idea
              filekey={params.row?.uploadedFileKey}
            />
          );
        },
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => <Actions id={params.id} />,
      },
    ],
    [baseUrl, can, displayDate, t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage
      breadcrumbs={{
        paths: [
          { href: "/inquiries", text: t("inquiries.title") },
          {
            href: baseUrl,
            text: inquiry?.name || t("inquiries.title"),
          },
        ],
        current: t("mainOrders.title"),
        endAdornment: <GroupsSwitch />,
      }}
    >
      <CustomDataGrid
        tableName="details-inquiries-mo"
        addButtonText={t("mainOrders.addMainOrder")}
        addButtonDisabled={!can("addMainOrder")}
        onAddClick={onAddClick}
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={["referenceNumber", "supplier"]}
        defaultSortModel={DEFAULT_SORT_MODEL_DESC}
      />
    </PaperPage>
  );
};
