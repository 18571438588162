import React from "react";
import { Divider, Grid, SxProps, Typography } from "@mui/material";
import { EnhancedSkeleton } from "./EnhancedSkeleton";

type Block = {
  xs: number;
  label?: string;
};

type TextBlock = Block & {
  value: string;
  loading?: false;
};

type ComponentBlock = Block & {
  view: React.ReactNode;
  loading?: false;
};

type LoadingBlock = Block & {
  loading: true;
};

type Row =
  | Array<
      (TextBlock | ComponentBlock | LoadingBlock) & {
        style?: "body1" | "body2";
      }
    >
  | "divider";

type NewDesignEntityDetailsGridProps = {
  blocks: Row[];
  compact?: boolean;
  headerStyles?: SxProps;
  valueStyles?: SxProps;
};

export const NewDesignEntityDetailsGrid = ({
  blocks,
  compact,
  headerStyles,
  valueStyles,
}: NewDesignEntityDetailsGridProps) => (
  <Grid container spacing={2}>
    {blocks.map((row, i) => {
      if (row === "divider") {
        return (
          <Grid item xs={12} key={`devider-${i}`}>
            <Divider />
          </Grid>
        );
      }

      return row.map(({ xs, label, style, ...block }, index) => {
        if ("view" in block) {
          return (
            <Grid key={index} item xs={xs}>
              {label && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: "block",
                    color: "secondary.dark",
                    fontSize: compact ? "12px" : "14px",
                    lineHeight: "24px",
                    ...headerStyles,
                  }}
                  component="span"
                >
                  {label}
                </Typography>
              )}
              {block.view}
            </Grid>
          );
        }

        return (
          <Grid key={label} item xs={xs}>
            {label && (
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: 0.5,
                  color: "secondary.dark",
                  fontSize: compact ? "12px" : "14px",
                  lineHeight: "24px",
                  display: "block",
                  ...headerStyles,
                }}
                component="span"
              >
                {label}
              </Typography>
            )}
            <Typography
              variant={style || "body2"}
              sx={{
                fontSize: "13px",
                color: "primary.dark",
                display: "block",
                ...valueStyles,
              }}
              component="span"
            >
              {"loading" in block ? (
                <EnhancedSkeleton variant="text" width={[50, 100, "%"]} />
              ) : (
                block.value
              )}
            </Typography>
          </Grid>
        );
      });
    })}
  </Grid>
);
