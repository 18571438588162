import { useHistory, useRouteMatch } from "react-router-dom";
import { useCallback } from "react";

export const useTableUrlTools = (baseUrl: string) => {
  const history = useHistory();

  const addDrawerMatcher = useRouteMatch(`${baseUrl}/add`);
  const editDrawerMatcher = useRouteMatch(`${baseUrl}/:id/edit`);
  const detailsDrawerMather = useRouteMatch({
    path: `${baseUrl}/:id`,
    exact: true,
  });

  const idMatcher = useRouteMatch<{ id: string }>(`${baseUrl}/:id`);

  const closeDrawer = useCallback(
    () => history.push(baseUrl),
    [baseUrl, history]
  );

  return {
    addDrawerOpen: !!addDrawerMatcher,
    editDrawerOpen: !!editDrawerMatcher,
    detailsDrawerOpen: !!detailsDrawerMather && !addDrawerMatcher,
    id: idMatcher?.params?.id ? parseInt(idMatcher?.params?.id) : null,
    closeDrawer,
  };
};
