import { Box, Skeleton } from "@mui/material";
import React from "react";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";

export const TagSkeleton = () => {
  return (
    <Box
      sx={{
        minWidth: "120px",
        flexGrow: 1,
        flexShrink: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "15px",
        paddingLeft: "15px",
      }}
    >
      <Skeleton variant="circular" width="21px" height="20px" />
      <EnhancedSkeleton
        variant="text"
        width={[40, 70]}
        sx={{ fontSize: "12px", lineHeight: "24px" }}
      />
    </Box>
  );
};
