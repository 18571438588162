import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider, useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTranslation } from "react-i18next";
import {
  useEditCountryMutation,
  useGetCountriesQuery,
} from "../../../slices/countries/api";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { CountryForm, countryFormSchemaCreator } from "./CountryForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";

type EditCountryForm = {
  id: number;
  name: string;
  code: string;
  active: boolean;
};

export const Edit: React.FC = () => {
  const { id, closeDrawer } = useTableUrlTools("/countries");
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(countryFormSchemaCreator);
  const formMethods = useForm<EditCountryForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;
  const {
    isLoading: isDataLoading,
    data,
    error: dataLoadingError,
  } = useGetCountriesQuery();
  const [editCountry, { isLoading: isEditLoading }] = useEditCountryMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("countries.errors.edit")
  );

  const countryData = useMemo(
    () => (data && id ? data.find((country) => country.id === id) : null),
    [data, id]
  );

  const onSubmit = useCallback(
    (form: EditCountryForm) =>
      editCountry(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("countries.editSuccess"), `/countries/${id}`);
          closeDrawer();
        })
        .catch(catchFormError),
    [catchFormError, closeDrawer, editCountry, feedSuccess, id, t]
  );

  useEffect(() => {
    if (dataLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, dataLoadingError]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!countryData) {
    return null;
  }

  return (
    <Form
      heading={t("countries.editCountry")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <CountryForm
          countryData={countryData}
          fieldsErrors={fieldsErrors}
          isActiveCheckboxShown={true}
        />
      </FormProvider>
    </Form>
  );
};
