import React from "react";
import { useGetSuppliersQuery } from "../../slices/suppliers/api";
import { MultipleSelectFilter } from "../toolbar/MultipleSelectFilter";
import { useTranslation } from "react-i18next";

type SelectSuppliersProps = {
  onChange: (supplierIds: string[]) => void;
  value: string[];
};

/**
 * TODO:
 *  Needs to be merged with SelectSupplier into a single component
 */
export const SelectSuppliers = ({ value, onChange }: SelectSuppliersProps) => {
  const { t } = useTranslation("pages");

  const {
    isLoading,
    data: suppliers,
    error: suppliersError,
  } = useGetSuppliersQuery({});

  if (suppliersError) {
    return null;
  }

  return (
    <MultipleSelectFilter
      sx={{ width: "250px" }}
      id="products-supplier-filter"
      label={t("products.filters.supplier")}
      loading={isLoading}
      options={
        suppliers
          ? suppliers.map(({ id, name }) => ({
              label: name,
              value: id.toString(),
            }))
          : []
      }
      value={value}
      onChange={onChange}
    />
  );
};
