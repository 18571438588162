import {} from "@reduxjs/toolkit/query/react";
import { unionItemsProductsInquiriesApi } from "../unionItemsProductsInquiriesApi";
import { AddMainOrderDto, MainOrder, MainOrderFlattened } from "./types";

const baseUrl = "mainOrders";

export const mainOrdersApi = unionItemsProductsInquiriesApi.injectEndpoints({
  endpoints: (builder) => ({
    getMainOrders: builder.query<MainOrderFlattened[], number>({
      query: (inquiryId) => ({
        url: `/${baseUrl}/${inquiryId}`,
        method: "GET",
      }),
      transformResponse: (response: MainOrder[]) =>
        response.map((mo) => ({
          ...mo,
          supplier: mo.supplier.name,
        })),
      providesTags: () => [{ type: "MainOrders", id: "list" }],
    }),
    addMainOrder: builder.mutation<MainOrder, AddMainOrderDto>({
      query: (body) => ({ url: `/${baseUrl}`, method: "POST", body }),
      invalidatesTags: [{ type: "MainOrders", id: "list" }],
    }),
    deleteMainOrder: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `/${baseUrl}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "MainOrders", id: "list" },
        { type: "MainOrders", id },
      ],
    }),
  }),
});

export const {
  useGetMainOrdersQuery,
  useAddMainOrderMutation,
  useDeleteMainOrderMutation,
} = mainOrdersApi;
