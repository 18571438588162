import React, { useCallback, useMemo } from "react";
import { Link as MaterialLink } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useGetSuppliersQuery } from "../../../slices/suppliers/api";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { Actions } from "./Actions";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";
import { BooleanCheckboxDisplay } from "../../../components/dataDisplays/BooleanCheckboxDisplay";

export const DataTable: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const can = usePermissions();
  const { isLoading, isFetching, data, error, refetch } = useGetSuppliersQuery(
    {}
  );

  const onAddClick = useCallback(
    () => history.push("/suppliers/add"),
    [history]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.1,
      },
      {
        field: "name",
        headerName: t("columns.name", { ns: "common" }),
        flex: 1,
        renderCell: (params) => (
          <MaterialLink component={Link} to={`/suppliers/${params.id}`}>
            {params.value}
          </MaterialLink>
        ),
      },
      {
        field: "public",
        headerName: t("columns.public", { ns: "common" }),
        flex: 1,
        renderCell: (params) => <BooleanCheckboxDisplay value={params.value} />,
      },
      {
        field: "code",
        headerName: t("code"),
        flex: 1,
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => (
          <Actions id={params.id} active={params.row.active} />
        ),
      },
    ],
    [t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("suppliers.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <CustomDataGrid
        addButtonText={t("suppliers.addSupplier")}
        addButtonDisabled={!can("addSupplier")}
        onAddClick={onAddClick}
        tableName="suppliers"
        columns={columns}
        rows={data}
        disableSelectionOnClick
        searchFields={["id", "name", "code"]}
        activeFilterIsUsed={true}
      />
    </PaperPage>
  );
};
