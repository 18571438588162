import { getDefaultHeaders, getUrl } from "./api";

export type InvalidFileUploadType = "noFile" | "wrongFormat";

export const saveBlob = (blob: Blob | MediaSource, fileName: string) => {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  a.dispatchEvent(new MouseEvent("click"));
};

export const uploadAndDownloadFileToServer = async (
  url: string,
  dFilename: string,
  onInvalidUpload: (errorType: InvalidFileUploadType) => void,
  onSucccessUpload: () => void,
  additionalParams?: { name: string; value: string }[],
  allowedFormat?: string
) => {
  const uploadFileElement = document.createElement("input");
  uploadFileElement.setAttribute("type", "file");
  uploadFileElement.setAttribute("style", "display:none;");
  uploadFileElement.setAttribute("accept", ".xlsx");

  uploadFileElement.addEventListener("change", async (event) => {
    const files = (event?.target as HTMLInputElement)?.files;

    if (!files || files.length === 0) {
      onInvalidUpload("noFile");
      return;
    }

    if (
      allowedFormat &&
      files[0].name.split(".").slice(-1)[0].toLowerCase() !==
        allowedFormat.toLowerCase()
    ) {
      onInvalidUpload("wrongFormat");
      return;
    }

    const data = new FormData();
    data.append("importFile", files[0]);

    if (additionalParams && additionalParams.length > 0)
      for (const addParam of additionalParams) {
        data.append(addParam.name, addParam.value);
      }

    const defaultHeaders = getDefaultHeaders(true);

    const resultFile = await fetch(getUrl(url), {
      headers: { Authorization: defaultHeaders["Authorization"] },
      method: "POST",
      body: data,
    });

    const resultFileBlob = await resultFile.blob();
    const resultFileURL = URL.createObjectURL(resultFileBlob);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = resultFileURL;
    hiddenElement.download = dFilename;
    hiddenElement.click();
    URL.revokeObjectURL(resultFileURL);
    hiddenElement.remove();
    uploadFileElement.remove();
    onSucccessUpload();
  });
  uploadFileElement.click();
};

export const downloadFileFromServer = async (id: number) => {
  const response = await fetch(getUrl(`/export/inquiry/${id}`), {
    headers: getDefaultHeaders(true),
  });
  const data = await response.json();
  window.location.assign(data.url);
};

export const downloadPrincipalInquiryFileFromServer = async (id: number) => {
  const response = await fetch(getUrl(`/export/principal/inquiry/${id}`), {
    headers: getDefaultHeaders(true),
  });
  const data = await response.json();
  window.location.assign(data.url);
};

export const downloadProductsFromServer = async (
  search: string,
  supplierIds: string[],
  activities: string[]
) => {
  const ids =
    supplierIds && supplierIds.length > 0 ? supplierIds.join(",") : "";
  const availabilities =
    activities && activities.length > 0 ? activities.join(",") : "";

  const response = await fetch(
    getUrl(
      `/export/products?search=${
        search || ""
      }&supplierIds=${ids}&activities=${availabilities}`
    ),
    {
      headers: getDefaultHeaders(true),
    }
  );
  const data = await response.json();
  window.location.assign(data.url);
};
