import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, styled } from "@mui/material";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import {
  useGetSchoolDepartmentInquiryQuery,
  useSendSchoolDepartmentInquiryToPrincipalMutation,
} from "../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Hint } from "../../../../../components/hints/Hint";
import { colors } from "../../../../../theme/astra/colors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";

export const SendToPrincipal = () => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const {
    inquiryId,
    departmentInquiryId,
    inquiryResult: { data: inquiry },
  } = useSchoolInquiryItemsTools();

  const { data: departmentInquiry } = useGetSchoolDepartmentInquiryQuery(
    departmentInquiryId ? { inquiryId, departmentInquiryId } : skipToken
  );

  const preActionCheck = usePreActionCheck();

  const disabled = useMemo(() => {
    if (!departmentInquiry || !inquiry) {
      return true;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return true;
    }

    const allItemsFit = departmentInquiry.items.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByHead" ||
        schoolStatus === "approvedByPrincipal" ||
        schoolStatus === "cancelled"
    );

    return (
      departmentInquiry.schoolStatus !== "readyForSending" &&
      !(departmentInquiry.schoolStatus === "needReviewByHead" && allItemsFit)
    );
  }, [departmentInquiry, inquiry, preActionCheck]);

  const help = useMemo(() => {
    if (!departmentInquiry || !inquiry) {
      return true;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return false;
    }

    const allItemsFit = departmentInquiry.items.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByHead" ||
        schoolStatus === "approvedByPrincipal" ||
        schoolStatus === "cancelled"
    );

    return (
      departmentInquiry.schoolStatus === "new" ||
      departmentInquiry.schoolStatus === "inProgress" ||
      departmentInquiry.schoolStatus === "pendingApproval" ||
      (departmentInquiry.schoolStatus === "needReviewByHead" && !allItemsFit)
    );
  }, [departmentInquiry, inquiry, preActionCheck]);

  const [sendDepartmentInquiry, { isLoading: isSending }] =
    useSendSchoolDepartmentInquiryToPrincipalMutation();

  const sendToPrincipalHandler = useCallback(() => {
    if (disabled || help) {
      return;
    }

    if (departmentInquiryId) {
      sendDepartmentInquiry({
        inquiryId,
        departmentInquiryId,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    departmentInquiryId,
    disabled,
    handleError,
    handleSuccess,
    help,
    inquiryId,
    sendDepartmentInquiry,
  ]);

  if (self?.role !== "headOfDepartment") {
    return null;
  }

  return (
    <Hint
      helpText={
        help
          ? t("hints.schoolInquiryDetails.sendTo.disabled")
          : t("hints.schoolInquiryDetails.sendTo.available")
      }
      hide={
        !departmentInquiry ||
        departmentInquiry.schoolStatus === "approvedByHead" ||
        departmentInquiry.schoolStatus === "cancelled" ||
        departmentInquiry.schoolStatus === "approvedByPrincipal"
      }
    >
      <ConfirmationButton
        component={SendToAstraButton}
        variant="contained"
        onClick={help ? undefined : sendToPrincipalHandler}
        confirmationTitle={
          help
            ? t(
                "schoolInquiryDetails.departmentControls.sendToPrincipal.approveAllFirst"
              )
            : t(
                "schoolInquiryDetails.departmentControls.sendToPrincipal.confirmationTitle"
              )
        }
        confirmationDescription={
          help
            ? t(
                "schoolInquiryDetails.departmentControls.sendToPrincipal.checkListsFirst"
              )
            : t("noUndo", { ns: "common" })
        }
        loading={isSending}
        disabled={disabled && !help}
        proceedButtonText={help ? "Ok" : undefined}
        sx={{
          textTransform: "capitalize",
        }}
      >
        {t("schoolInquiryDetails.departmentControls.sendToPrincipal.label")}
      </ConfirmationButton>
    </Hint>
  );
};

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});
