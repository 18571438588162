import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { Button } from "@mui/material";
import {
  useApproveAssignmentMutation,
  useGetAssignmentQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "../../../../../store";
import { Hint } from "../../../../../components/hints/Hint";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { colors } from "../../../../../theme/astra/colors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";

export const Approve = () => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const {
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult: { data: inquiry },
  } = useSchoolInquiryItemsTools();

  const { data: assignment } = useGetAssignmentQuery(
    departmentInquiryId && assignmentId
      ? {
          inquiryId,
          departmentInquiryId,
          assignmentId,
        }
      : skipToken
  );

  const preActionCheck = usePreActionCheck();

  const disabled = useMemo(() => {
    if (!assignment || !self || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    const allItemsFit = assignment.items.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByHead" ||
        schoolStatus === "approvedByPrincipal" ||
        schoolStatus === "cancelled"
    );

    if (self.id === assignment.user.id) {
      return (
        assignment.schoolStatus !== "new" &&
        assignment.schoolStatus !== "readyForSending" &&
        !(assignment.schoolStatus === "needReviewByTeacher" && allItemsFit)
      );
    }

    if (self.id !== assignment.user.id) {
      return !(assignment.schoolStatus === "approvedByTeacher" && allItemsFit);
    }
  }, [assignment, inquiry, preActionCheck, self]);

  const help = useMemo(() => {
    if (!assignment || !self || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return false;
    }

    const allItemsFit = assignment.items.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByHead" ||
        schoolStatus === "approvedByPrincipal" ||
        schoolStatus === "cancelled"
    );

    if (self.id === assignment.user.id) {
      return (
        assignment.schoolStatus === "inProgress" ||
        (assignment.schoolStatus === "needReviewByTeacher" && !allItemsFit)
      );
    }

    if (self.id !== assignment.user.id) {
      return (
        assignment.schoolStatus === "new" ||
        assignment.schoolStatus === "inProgress" ||
        assignment.schoolStatus === "readyForSending" ||
        (assignment.schoolStatus === "approvedByTeacher" && !allItemsFit)
      );
    }

    return false;
  }, [assignment, inquiry, preActionCheck, self]);

  const [approveAssignment, { isLoading: isApproving }] =
    useApproveAssignmentMutation();

  const approveHandler = useCallback(() => {
    if (disabled || help) {
      return;
    }

    if (departmentInquiryId && assignmentId) {
      approveAssignment({
        inquiryId,
        departmentInquiryId,
        assignmentId,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    approveAssignment,
    assignmentId,
    departmentInquiryId,
    disabled,
    handleError,
    handleSuccess,
    help,
    inquiryId,
  ]);

  if (self?.role !== "headOfDepartment") {
    return null;
  }

  return (
    <Hint
      helpText={
        help
          ? t("hints.schoolInquiryDetails.assignmentControls.approve.disabled")
          : t("hints.schoolInquiryDetails.assignmentControls.approve.available")
      }
    >
      <ConfirmationButton
        component={Button}
        variant="contained"
        loading={isApproving}
        onClick={help ? undefined : approveHandler}
        proceedButtonText={help ? t("ok", { ns: "legacy" }) : undefined}
        confirmationTitle={
          help
            ? t(
                "schoolInquiryDetails.assignmentControls.approve.approveAllFirst"
              )
            : t(
                "schoolInquiryDetails.assignmentControls.approve.confirmationTitle"
              )
        }
        confirmationDescription={
          help
            ? t(
                "schoolInquiryDetails.assignmentControls.approve.approveItemsFirst"
              )
            : t("noUndo", { ns: "common" })
        }
        disabled={disabled && !help}
        sx={{
          textTransform: "capitalize",
          color: colors.white,
          backgroundColor: colors.blue,
        }}
      >
        {t("schoolInquiryDetails.assignmentControls.approve.label")}
      </ConfirmationButton>
    </Hint>
  );
};
