import React, { useEffect } from "react";
import { EntityDetailsTable } from "../../../components/EntityDetailsTable";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetSchoolQuery } from "../../../slices/schools/api";
import { BooleanCheckboxDisplay } from "../../../components/dataDisplays/BooleanCheckboxDisplay";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";

export const Details: React.FC = () => {
  const { t } = useTranslation("pages");
  const { id, closeDrawer } = useTableUrlTools("/schools");
  const { data, error } = useGetSchoolQuery(id ?? skipToken);
  const can = usePermissions();
  const displayDate = useDisplayDateTime();

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  if (!data) {
    return null;
  }

  return (
    <DisplayContainer
      heading={data.name}
      editHref={`/schools/${id}/edit`}
      editLabel={t("schools.edit")}
      editingDisabled={!can("editSchool")}
    >
      <EntityDetailsTable
        entity={data}
        excludeFields={["name"]}
        customDisplays={{
          active: (value) => <BooleanCheckboxDisplay value={!!value} />,
          country: (value) => {
            if (typeof value === "object") {
              return value.name;
            }
          },
          currency: (value) => {
            if (typeof value === "object") {
              return value.code;
            }
          },
          createdAt: (value) => (value ? displayDate(value) : null),
          updatedAt: (value) => (value ? displayDate(value) : null),
        }}
      />
    </DisplayContainer>
  );
};
