import React, { useCallback } from "react";
import {
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useChangeItemInquiryStatusMutation } from "../../../slices/items/api";
import {
  ItemInquiryStatus,
  itemInquiryStatuses,
} from "../../../slices/items/types";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { ITEM_INQUIRY_STATUS_COLORS } from "../../../consts";
import {
  canSetStatusManually,
  inquiryStatusesPaths,
} from "../../../slices/items/utils";

type SelectItemDetailsInquiryStatusProps = Omit<
  SelectProps,
  "defaultValue" | "id" | "onChange" | "value"
> & {
  defaultValue: ItemInquiryStatus;
  id: number;
  inquiryId: number;
};

export const SelectItemDetailsInquiryStatus = ({
  defaultValue,
  id,
  inquiryId,
  ...props
}: SelectItemDetailsInquiryStatusProps) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeInquiryStatus] = useChangeItemInquiryStatusMutation();

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const newStaus = event.target.value as ItemInquiryStatus;

      if (!itemInquiryStatuses.includes(newStaus)) {
        feedError(t("itemDetails.errors.wrongStatus"));
      }

      changeInquiryStatus({
        id,
        status: newStaus,
        inquiryId,
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("itemDetails.successes.changeItemInquiryStatus"));
        })
        .catch((error: { data?: { message?: string } }) => {
          feedError(
            error?.data?.message ??
              t("itemDetails.errors.changeItemInquiryStatus")
          );
        });
    },
    [changeInquiryStatus, feedError, feedSuccess, id, inquiryId, t]
  );

  return (
    <Select
      id={`select-item-details-inquiry-status-${id}`}
      value={defaultValue}
      onChange={changeHandler}
      {...props}
    >
      {itemInquiryStatuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          disabled={
            !canSetStatusManually(inquiryStatusesPaths, defaultValue, status)
          }
        >
          <Chip
            label={<b>{t(`items.statuses.inquiry.${status}`)}</b>}
            variant="filled"
            sx={{
              backgroundColor: ITEM_INQUIRY_STATUS_COLORS[status],
              color: "white",
              "&:hover": { cursor: "pointer" },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
