import React from "react";
import { AssignMainOrder as AssignMainOrderDrawer } from "../../../drawers/AssignMainOrder/AssignMainOrder";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { Spinner } from "../../../components/wideDrawer/Spinner";

export const AssignMainOrder = () => {
  const { inquiryId, closeDrawer } = useItemDetailsUrlTools();

  if (!inquiryId) {
    return <Spinner />;
  }

  return (
    <AssignMainOrderDrawer inquiryId={inquiryId} closeDrawer={closeDrawer} />
  );
};
