import React, { memo, useCallback, useState } from "react";
import { Box, ClickAwayListener, Fade, IconButton, Paper } from "@mui/material";
import { Circular } from "../../../../../components/spinners/Circular";
import { Preview as PreviewIcon } from "@mui/icons-material";

export type PreviewProps = {
  isLoading?: boolean;
  url: string;
};

export const Preview = memo(({ isLoading, url }: PreviewProps) => {
  const [show, setShow] = useState(false);

  const previewClickHandler = useCallback(() => {
    setShow((currentState) => !currentState);
  }, []);

  const previewClickAwayHandler = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={previewClickAwayHandler}>
      <Box sx={{ position: "relative" }}>
        <IconButton onClick={previewClickHandler}>
          <PreviewIcon />
        </IconButton>
        <Fade in={show}>
          <Paper
            elevation={3}
            sx={{
              width: "300px",
              height: "300px",
              position: "absolute",
              zIndex: 10,
              top: "-64px",
              left: "64px",
              overflow: "hidden",
            }}
          >
            {!isLoading && url ? (
              <Box
                component="img"
                sx={{ display: "block", width: "100%", height: "100%" }}
                src={url}
              />
            ) : (
              <Circular />
            )}
          </Paper>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
});
