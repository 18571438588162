import React from "react";
import {
  Box,
  BoxProps,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../utils/useDisplayPrice";

type PriceCurrencyProps = BoxProps & {
  price: number | null | undefined;
  currency: string | null | undefined;
  priceProps?: TypographyProps;
  currencyProps?: TypographyProps;
  showPriceZero?: boolean;
  hideCurrencyWhenNoBudget?: boolean;
  tooltip?: boolean;
};

export const PriceCurrency = ({
  price,
  currency,
  sx,
  priceProps: pricePropsRaw,
  currencyProps: currencyPropsRaw,
  showPriceZero,
  hideCurrencyWhenNoBudget,
  tooltip,
  ...props
}: PriceCurrencyProps) => {
  const { t } = useTranslation("components");
  const na = t("na", { ns: "common" });

  const displayPrice = useDisplayPrice();

  const priceValue = (() => {
    if (price) {
      return displayPrice(price);
    }

    if (showPriceZero) {
      return 0;
    }

    return na;
  })();

  const { sx: priceSx, ...priceProps } = pricePropsRaw ?? {};
  const { sx: currencySx, ...currencyProps } = currencyPropsRaw ?? {};

  const isCurrencyHidden = hideCurrencyWhenNoBudget && !price;

  const priceElement = (
    <Typography
      component="span"
      sx={{
        fontSize: "13px",
        lineHeight: "24px",
        fontWeight: "500",
        ...priceSx,
      }}
      {...priceProps}
    >
      {priceValue}
    </Typography>
  );

  return (
    <Box sx={{ ...sx }} {...props}>
      {tooltip ? (
        <Tooltip title={priceValue}>{priceElement}</Tooltip>
      ) : (
        priceElement
      )}
      {!isCurrencyHidden && (
        <Typography
          component="span"
          sx={{
            fontSize: "10px",
            lineHeight: "33px",
            fontWeight: "500",
            marginLeft: "4px",
            ...currencySx,
          }}
          {...currencyProps}
        >
          {currency ?? na}
        </Typography>
      )}
    </Box>
  );
};
