import React from "react";
import { inquirySchoolStatus, InquirySchoolStatus } from "../../../../../types";
import { useTranslation } from "react-i18next";
import { StatusBar } from "../../StatusBar/StatusBar";
import { INQUIRY_SCHOOL_STATUS_CHIP_STYLES } from "../../../../../consts";

type StatusesProps = {
  current: InquirySchoolStatus;
  departmentInquiryLink?: string | null;
};

export const Statuses = ({ current, departmentInquiryLink }: StatusesProps) => {
  const { t } = useTranslation("pages");

  const statuses = inquirySchoolStatus
    .filter((status) => status !== "pendingApproval" || status === current)
    .map((status) => {
      const displayLink =
        departmentInquiryLink &&
        status === "pendingApproval" &&
        current === status;

      return {
        status,
        label: t(`schoolInquiryStatuses.${status}`, { ns: "common" }),
        link: displayLink
          ? {
              to: departmentInquiryLink,
              label: t("schoolInquiries.reviewNow"),
            }
          : null,
        colors: INQUIRY_SCHOOL_STATUS_CHIP_STYLES[status],
      };
    });

  return <StatusBar statuses={statuses} current={current} />;
};
