import React from "react";
import { Checkbox } from "@mui/material";

type BooleanDisplayProps = {
  value: boolean;
};

export const BooleanCheckboxDisplay: React.FC<BooleanDisplayProps> = ({
  value,
}) => {
  return <Checkbox sx={{ margin: "-10px" }} disabled checked={value} />;
};
