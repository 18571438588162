import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useGetTeachersQuery } from "../../slices/users/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { displayFullName } from "../../utils/displayFullName";
import { useTranslation } from "react-i18next";

type NewDesignSelectTeacherProps = {
  label: string;
  selectProps: SelectProps;
  id: string;
  schoolId?: number;
  helperText?: string;
};

export const NewDesignSelectTeacher = ({
  schoolId,
  selectProps,
  label,
  id,
  helperText,
  ...props
}: Omit<FormControlProps, "id" | "onChange"> & NewDesignSelectTeacherProps) => {
  const { data = [], isLoading } = useGetTeachersQuery(schoolId ?? skipToken);
  const { t } = useTranslation();

  return (
    <FormControl fullWidth {...props}>
      <Select
        labelId={`custom-assignment-teacher-select-${id}-label`}
        id={`custom-assignment-teacher-select-${id}`}
        placeholder={label}
        disabled={isLoading}
        {...selectProps}
      >
        {data.map(({ id, firstName, lastName }) => (
          <MenuItem key={id} value={id}>
            {displayFullName({ firstName, lastName })}
          </MenuItem>
        ))}
        {data.length < 1 && (
          <MenuItem key={"empty-list"} disabled>
            {t("emptySelect", { ns: "common" })}
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
