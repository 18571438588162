import { Box, Button, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  NumericField,
  NumericFieldProps,
} from "../../components/fields/NumericField";
import { useTranslation } from "react-i18next";
import { Plus } from "../../components/astraIcons/Plus";
import { useShopDetailsUrlTools } from "./useShopDetailsUrlTools";
import { useAddToCartMutation } from "../../slices/shop/api";
import { useSelector } from "../../store";
import { selectShopList } from "../../slices/shop/selectors";
import {
  useCurrentAssignmentId,
  useIsCartDisabled,
} from "../../slices/shop/hooks";
import { useMessageHandlers } from "../../utils/useMessageHandlers";

export const Add = () => {
  const { t } = useTranslation("pages");
  const theme = useTheme();
  const { handleSuccess, handleError } = useMessageHandlers();

  const { productId } = useShopDetailsUrlTools();
  const { inquiryId, departmentId } = useSelector(selectShopList);
  const assignmentId = useCurrentAssignmentId();
  const isCartDisabled = useIsCartDisabled();

  const [addToCart] = useAddToCartMutation();

  const [quantity, setQuantity] = useState<NumericFieldProps["value"]>(1);

  const addClickHandler = useCallback(() => {
    if (!quantity || !productId) {
      return null;
    }

    addToCart({
      inquiryId,
      departmentInquiryId: departmentId,
      assignmentId,
      productId,
      quantity: typeof quantity === "string" ? parseInt(quantity) : quantity,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    addToCart,
    assignmentId,
    departmentId,
    handleError,
    handleSuccess,
    inquiryId,
    productId,
    quantity,
  ]);

  return (
    <Box sx={{ display: "flex", gap: "24px", marginTop: "24px" }}>
      <NumericField
        name={`product-${productId}-quantity`}
        value={quantity}
        onChange={setQuantity}
        positiveOnly
        disabled={isCartDisabled}
        sx={{
          flexBasis: "110px",
          flexGrow: 0,
          flexShrink: 0,
          position: "relative",

          ".MuiOutlinedInput-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },

          ".MuiInputBase-input": {
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "500",
            height: "38px",
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
          },

          "& .MuiIconButton-root": {
            width: "30px",
            height: "30px",

            svg: {
              color: `${theme.palette.primary.main} !important`,
            },

            "&.Mui-disabled svg": {
              opacity: 0.5,
            },
          },

          ".MuiInputAdornment-root": {
            position: "absolute",
          },

          ".MuiInputAdornment-positionStart": {
            left: "6px",
          },

          ".MuiInputAdornment-positionEnd": {
            right: "6px",
          },
        }}
      />
      <Button
        disabled={isCartDisabled}
        variant="contained"
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          width: "242px",

          ".MuiButton-startIcon": {
            marginLeft: "-32px",

            svg: {
              fontSize: "24px",
            },
          },
        }}
        startIcon={<Plus />}
        onClick={addClickHandler}
      >
        {t("shopDetails.add")}
      </Button>
    </Box>
  );
};
