import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useYup } from "../../../utils/validation";
import { assignmentSchemaCreator } from "./AssignmentForm/schema";
import { useForm } from "react-hook-form";
import { EditAssignmentForm } from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { AssignmentForm } from "./AssignmentForm/AssignmentForm";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import {
  useDeleteAssignmentMutation,
  useEditAssignmentMutation,
  useGetAssignmentQuery,
} from "../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { displayFullName } from "../../../utils/displayFullName";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { NewDesignForm } from "../../../components/wideDrawer/NewDesignForm";
import styled from "@emotion/styled";
import { ConfirmationButton } from "../../../components/ConfirmationButton";
import { Box, Button } from "@mui/material";
import { useMessageHandlers } from "../../../utils/useMessageHandlers";
import { usePreActionCheck } from "../../../slices/schoolInquiries/hooks";

export const EditAssignment = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const { handleSuccess, handleError } = useMessageHandlers();

  const {
    closeDrawer,
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult: { data: inquiry, isLoading: isInquiryLoading },
  } = useSchoolInquiriesTableUrlTools();

  const { isFetching: isAssignmentLoading, data: assignment } =
    useGetAssignmentQuery(
      departmentInquiryId && assignmentId
        ? {
            inquiryId,
            departmentInquiryId,
            assignmentId,
          }
        : skipToken
    );

  const schema = useYup(assignmentSchemaCreator);
  const { handleSubmit, control, reset } = useForm<EditAssignmentForm>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiryDetails.errors.editAssignment")
  );

  const [editAssignment, { isLoading: isEditing }] =
    useEditAssignmentMutation();

  const onSubmit = useCallback(
    (form: EditAssignmentForm) => {
      if (departmentInquiryId && assignmentId) {
        editAssignment({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          ...form,
        })
          .unwrap()
          .then((res) => {
            feedSuccess(res.message);
            closeDrawer();
            reset();
          })
          .catch(catchFormError);
      }
    },
    [
      assignmentId,
      catchFormError,
      closeDrawer,
      departmentInquiryId,
      editAssignment,
      feedSuccess,
      inquiryId,
      reset,
    ]
  );

  useEffect(() => {
    if (assignment) {
      reset({
        budget: (assignment?.budget && assignment?.budget / 100) || undefined,
        budgetCurrencyId: assignment.budgetCurrency?.id,
      });
    }
  }, [assignment, reset]);

  const [deleteAssignment, { isLoading: isDeleting }] =
    useDeleteAssignmentMutation();

  const deleteHandler = useCallback(() => {
    if (departmentInquiryId && assignmentId) {
      deleteAssignment({
        inquiryId,
        departmentInquiryId,
        assignmentId,
      })
        .unwrap()
        .then(handleSuccess)
        .then(closeDrawer)
        .catch(handleError);
    }
  }, [
    assignmentId,
    closeDrawer,
    deleteAssignment,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  const preActionCheck = usePreActionCheck();

  const disabledDelete = useMemo(() => {
    if (!assignment || !inquiry) {
      return true;
    }

    if (
      preActionCheck(
        inquiry.schoolStatus,
        assignment.departmentInquiry.schoolStatus
      )
    ) {
      return true;
    }

    return (
      assignment.schoolStatus === "cancelled" ||
      assignment.schoolStatus === "approvedByHead" ||
      assignment.user.role === "headOfDepartment" ||
      assignment.items.length > 0
    );
  }, [assignment, inquiry, preActionCheck]);

  const disableEdit = useMemo(() => {
    if (!assignment || !inquiry) {
      return true;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return true;
    }

    return (
      assignment.schoolStatus === "cancelled" ||
      assignment.schoolStatus === "approvedByHead" ||
      !inquiry?.budget
    );
  }, [assignment, inquiry, preActionCheck]);

  if (isInquiryLoading || isAssignmentLoading) {
    return <Spinner />;
  }

  if (!assignment || !inquiry) {
    return null;
  }

  return (
    <>
      <NewDesignForm
        heading={`${t("schoolInquiryDetails.editAssignment")} ${displayFullName(
          {
            firstName: assignment.user.firstName,
            lastName: assignment.user.lastName,
          }
        )}`}
        onSubmit={handleSubmit(onSubmit)}
        loading={isEditing}
        formError={formError}
        submitText={t("schoolInquiryDetails.submitEdit")}
        disabledSubmit={disableEdit}
      >
        <AssignmentForm
          control={control}
          fieldsErrors={fieldsErrors}
          schoolId={inquiry.school.id}
          editing
          isBudget={!!inquiry.budget || false}
        />
      </NewDesignForm>
      <Box
        sx={{
          width: "100%",
          padding: "0 20px 20px 20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {assignment.user.role !== "headOfDepartment" && (
          <DeleteButton
            component={Button}
            variant="text"
            onClick={deleteHandler}
            confirmationTitle={t(
              "schoolInquiryDetails.assignmentControls.delete.confirmationTitle"
            )}
            confirmationDescription={t("noUndo", { ns: "common" })}
            loading={isDeleting}
            disabled={disabledDelete}
          >
            {t("schoolInquiryDetails.assignmentControls.delete.buttonLabel")}
          </DeleteButton>
        )}
      </Box>
    </>
  );
};

const DeleteButton = styled(ConfirmationButton)({
  textDecoration: "underline",
  color: "#D20032",
  fontSize: "13px",
  "&:hover": {
    backgroundColor: "inherit",
  },
});
