import React from "react";
import { createSvgIcon } from "@mui/material";

export const ChevronLeft = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7996 8.23966C14.0814 8.5432 14.0639 9.01775 13.7603 9.2996L10.8522 12L13.7603 14.7004C14.0639 14.9823 14.0814 15.4568 13.7996 15.7603C13.5177 16.0639 13.0432 16.0815 12.7397 15.7996L9.23966 12.5496C9.08684 12.4077 9 12.2086 9 12C9 11.7915 9.08684 11.5923 9.23966 11.4504L12.7397 8.20041C13.0432 7.91856 13.5177 7.93613 13.7996 8.23966Z"
      fill="currentColor"
    />
  </svg>,
  "ChevronLeft"
);
