import React, { useCallback } from "react";
import { Alert, Collapse, Paper, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PutResetPasswordDto } from "../../../slices/auth/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import i18n from "../../../i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fieldError,
  onFieldChange,
  useSubmitFormErrorHander,
} from "../../../utils/formHelpers";
import { useResetPasswordMutation } from "../../../slices/auth/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import {
  FormHelperTextProps,
  InputLabelProps,
  NewDesignInputProps,
  StyledFieldTitle,
  StyledHelpBox,
  StyledSubmitSpinnerButton,
  StyledTitleBox,
} from "../StyledComponents";
import { BackToSignIn } from "./BackToSignIn";
import { useWindowSize } from "usehooks-ts";

const resetPasswordFormSchema = yup.object({
  password: yup
    .string()
    .required(i18n.t("required", { ns: "validationErrors" }))
    .min(8, i18n.t("tooShort", { ns: "validationErrors", minLength: 8 }))
    .max(32, i18n.t("tooLong", { ns: "validationErrors", maxLength: 32 }))
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{0,}$/,
      i18n.t("atLeastOneNumAndChar", { ns: "validationErrors" })
    ),
  passwordConfirm: yup
    .string()
    .required(i18n.t("required", { ns: "validationErrors" }))
    .min(8, i18n.t("tooShort", { ns: "validationErrors", minLength: 8 }))
    .max(32, i18n.t("tooLong", { ns: "validationErrors", maxLength: 32 }))
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{0,}$/,
      i18n.t("atLeastOneNumAndChar", { ns: "validationErrors" })
    ),
});

export const ResetPasswordForm: React.FC<{ userEmail: string; code: string }> =
  ({ userEmail, code }) => {
    const size = useWindowSize();
    const { t } = useTranslation();
    const history = useHistory();
    const feedSuccess = useFeedSuccess();

    const { control, handleSubmit } = useForm<PutResetPasswordDto>({
      resolver: yupResolver(resetPasswordFormSchema),
    });

    const [resetPassword, { isLoading }] = useResetPasswordMutation();

    const { catchFormError, formError, fieldsErrors, setFieldsErrors } =
      useSubmitFormErrorHander(t("error.signIn"));

    const onSubmit = useCallback(
      (fields: PutResetPasswordDto) => {
        resetPassword({
          code,
          email: userEmail,
          password: fields.password,
          passwordConfirm: fields.passwordConfirm,
        })
          .unwrap()
          .then(() => {
            feedSuccess(t("forgotpassword.resetSuccess", { ns: "pages" }));
            history.push("/");
          })
          .catch((error: unknown) => {
            catchFormError(error);
          });
      },
      [catchFormError, history, userEmail, code, resetPassword, t, feedSuccess]
    );

    return (
      <Paper elevation={0}>
        <Stack
          spacing={4}
          sx={{
            marginTop: `${
              size.height < 556 ? "20" : (size.height - 516) / 2
            }px`,
            marginBottom: `${size.height < 556 ? "20" : "0"}px`,
            paddingTop: "65px",
            paddingInline: "110px",
            paddingBottom: "80px",
            border: "solid 1px #E7F1FF",
            borderRadius: "4px",
          }}
          component="form"
          autoComplete="on"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={0} sx={{ marginTop: "30px" }}>
            <StyledTitleBox>
              {t("forgotpassword.changePassword", { ns: "pages" })}
            </StyledTitleBox>
            <StyledHelpBox sx={{ marginTop: "13px", marginBottom: "6px" }}>
              {t("passwordConfirmation")}
            </StyledHelpBox>
          </Stack>
          {formError && (
            <Collapse in={!!formError}>
              <Alert severity="error">{formError}</Alert>
            </Collapse>
          )}
          <Stack spacing={0} sx={{ marginBottom: "-30px !important" }}>
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {t("newPassword")}
            </StyledFieldTitle>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => {
                const [serverInvalid, serverError] = fieldError(
                  "password",
                  fieldsErrors
                );
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="password"
                    placeholder={t("newPassword")}
                    {...field}
                    error={invalid || serverInvalid}
                    helperText={error?.message || serverError}
                    InputLabelProps={InputLabelProps}
                    InputProps={NewDesignInputProps}
                    FormHelperTextProps={FormHelperTextProps}
                  />
                );
              }}
            />
            <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
              {t("passwordConfirmation")}
            </StyledFieldTitle>
            <Controller
              name="passwordConfirm"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => {
                const [serverInvalid, serverError] = fieldError(
                  "passwordConfirm",
                  fieldsErrors
                );
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="password"
                    placeholder={t("passwordConfirmation")}
                    {...field}
                    error={invalid || serverInvalid}
                    helperText={error?.message || serverError}
                    onChange={(args) => (
                      onFieldChange(
                        "passwordConfirm",
                        fieldsErrors,
                        setFieldsErrors
                      ),
                      field.onChange(args)
                    )}
                    InputLabelProps={InputLabelProps}
                    InputProps={NewDesignInputProps}
                    FormHelperTextProps={FormHelperTextProps}
                  />
                );
              }}
            />
          </Stack>
          <StyledSubmitSpinnerButton loading={isLoading} fullWidth>
            {t("forgotpassword.submitNewPassword", { ns: "pages" })}
          </StyledSubmitSpinnerButton>
          <BackToSignIn />
        </Stack>
      </Paper>
    );
  };
