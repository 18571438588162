import React, { useCallback, useMemo } from "react";
import { ContextMenu } from "../../../../../components/ContextMenu";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useDeleteRequestedItemMutation } from "../../../../../slices/schoolInquiries/api";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
} from "../../../../../types";
import { useSelector } from "../../../../../store";

import { selectSelf } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { RequestedItem } from "../../../../../entities";

type ActionsProps = {
  item: Pick<RequestedItem, "id" | "schoolStatus">;
  inquiryId: number;
  inquirySchoolStatus?: InquirySchoolStatus;
  departmentInquiryId?: number;
  assignmentId?: number;
  assignmentUserId?: number;
  assignmentSchoolStatus?: AssignmentSchoolStatus;
  departmentSchoolStatus?: DepartmentInquirySchoolStatus;
};

export const Actions = ({
  item: { id, schoolStatus },
  inquiryId,
  inquirySchoolStatus,
  departmentInquiryId,
  assignmentId,
  assignmentUserId,
  assignmentSchoolStatus,
  departmentSchoolStatus,
}: ActionsProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [deleteRequestedItem, { isLoading: isRequestedItemDeleting }] =
    useDeleteRequestedItemMutation();

  const preActionCheck = usePreActionCheck();

  const isDeletable = useMemo(() => {
    if (assignmentUserId !== self?.id) {
      return false;
    }

    if (
      !inquirySchoolStatus ||
      preActionCheck(inquirySchoolStatus, departmentSchoolStatus)
    ) {
      return false;
    }

    if (
      assignmentSchoolStatus !== "new" &&
      assignmentSchoolStatus !== "inProgress" &&
      assignmentSchoolStatus !== "readyForSending" &&
      assignmentSchoolStatus !== "needReviewByTeacher"
    ) {
      return false;
    }

    if (self?.role === "teacher") {
      return (
        schoolStatus === "new" ||
        schoolStatus === "approvedByTeacher" ||
        schoolStatus === "needReviewByTeacher"
      );
    }

    if (self?.role === "headOfDepartment") {
      return schoolStatus === "new" || schoolStatus === "approvedByHead";
    }

    return false;
  }, [
    assignmentSchoolStatus,
    assignmentUserId,
    departmentSchoolStatus,
    inquirySchoolStatus,
    preActionCheck,
    schoolStatus,
    self?.id,
    self?.role,
  ]);

  const deleteHandler = useCallback(() => {
    if (departmentInquiryId && assignmentId) {
      deleteRequestedItem({
        inquiryId,
        departmentInquiryId,
        assignmentId,
        requestedItemId: id,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    assignmentId,
    deleteRequestedItem,
    departmentInquiryId,
    handleError,
    handleSuccess,
    id,
    inquiryId,
  ]);

  return (
    <ContextMenu
      buttons={[
        {
          label: t("schoolInquiryDetails.itemActions.delete.label"),
          handler: deleteHandler,
          loading: isRequestedItemDeleting,
          disabled: !isDeletable,
          confirmation: {
            title: t(
              "schoolInquiryDetails.itemActions.delete.confirmationTitle"
            ),
            description: t("noUndo", { ns: "common" }),
          },
        },
      ]}
    />
  );
};
