import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectCountries } from "../../../components/prefilledSelectors/SelectCountries";
import { useLocalStorage } from "usehooks-ts";

export const useFilters = () => {
  const { t } = useTranslation("pages");

  const [countriesIds, setCountriesIds] = useLocalStorage<number[]>(
    `filter.inventory-entries-by-country`,
    []
  );

  const countriesIdsChangeHandler = useCallback(
    (value: number[]) => {
      setCountriesIds(value);
    },
    [setCountriesIds]
  );

  const filters = useMemo(
    () => (
      <SelectCountries
        id="school"
        label={t("products.fields.country")}
        onChange={countriesIdsChangeHandler}
        value={countriesIds}
        sx={{ minWidth: "250px" }}
        size="small"
      />
    ),
    [countriesIds, countriesIdsChangeHandler, t]
  );

  return { filters, countriesIds };
};
