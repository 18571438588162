import React from "react";
import { AssignPurchaseOrder as AssignPurchaseOrderDrawer } from "../../../drawers/AssignPurchaseOrder/AssignPurchaseOrder";
import { useItemDetailsUrlTools } from "../useItemDetailsUrlTools";
import { Spinner } from "../../../components/wideDrawer/Spinner";

export const AssignPurchaseOrder = () => {
  const { inquiryId, closeDrawer } = useItemDetailsUrlTools();

  if (!inquiryId) {
    return <Spinner />;
  }

  return (
    <AssignPurchaseOrderDrawer
      inquiryId={inquiryId}
      closeDrawer={closeDrawer}
    />
  );
};
