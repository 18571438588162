import React, { useEffect, useMemo } from "react";
import { useGetCategoriesQuery } from "../../../slices/categories/api";
import { EntityDetailsTable } from "../../../components/EntityDetailsTable";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { Box, Typography } from "@mui/material";

const displayColor = (color: string) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 15,
          height: 15,
          backgroundColor: color,
          marginRight: 1,
          borderRadius: 1,
        }}
      />
      <Typography variant="body2">{color}</Typography>
    </Box>
  );
};

export const Details: React.FC = () => {
  const { t } = useTranslation();
  const { id, closeDrawer } = useTableUrlTools("/categories");
  const { data, error } = useGetCategoriesQuery();
  const can = usePermissions();
  const displayDate = useDisplayDateTime();

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  const category = useMemo(
    () => data && data.find((entry) => entry.id === id),
    [data, id]
  );

  if (!category) {
    return null;
  }

  return (
    <DisplayContainer
      heading={category.name}
      editHref={`/categories/${id}/edit`}
      editLabel={t("categories.editCategory")}
      editingDisabled={!can("editCategory")}
    >
      <EntityDetailsTable
        entity={category}
        excludeFields={["name"]}
        customDisplays={{
          createdAt: (value) => (value ? displayDate(value.toString()) : null),
          updatedAt: (value) => (value ? displayDate(value.toString()) : null),
          color: (value) => (value ? displayColor(value) : null),
        }}
      />
    </DisplayContainer>
  );
};
