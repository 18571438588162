import { Permissions } from "../permissions/permissions";

export const getUrl = (path: string) =>
  `${process.env.REACT_APP_PUBLIC_URL}${path}`;

type DefaultHeader = {
  "Content-Type": string;
  "Access-Control-Allow-Origin": string;
};

export function getDefaultHeaders(): DefaultHeader;
export function getDefaultHeaders(auth: false): DefaultHeader;
export function getDefaultHeaders(auth: true): DefaultHeader & {
  Authorization: string;
};

export function getDefaultHeaders(auth?: boolean) {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (auth) {
    return {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    };
  }

  return headers;
}

/**
 * Same as getDefaultHeaders but meant to be used in RTK-Query
 **/
export const prepareHeaders = (headers: Headers) => {
  const defaultHeaders = getDefaultHeaders(true);
  for (const [key, value] of Object.entries(defaultHeaders)) {
    headers.set(key, value);
  }
  return headers;
};

export const getToken = () => localStorage.getItem("token");

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const setPermissions = (permissions: Permissions) =>
  localStorage.setItem("permissions", JSON.stringify(permissions));

export const getPermissions = (): Permissions | null => {
  const permissionsStringified = localStorage.getItem("permissions");

  if (!permissionsStringified) {
    return null;
  }

  return JSON.parse(permissionsStringified);
};
