import React, { useEffect } from "react";
import { useGetDepartmentQuery } from "../../../slices/departments/api";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { BooleanCheckboxDisplay } from "../../../components/dataDisplays/BooleanCheckboxDisplay";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { buildCategoryName } from "../../../utils/categories";
import { EntityDetailsGrid } from "../../../components/EntityDetailsGrid";

export const Details: React.FC = () => {
  const { t } = useTranslation("pages");
  const { id, closeDrawer } = useTableUrlTools("/departments");
  const can = usePermissions();

  const { data: department, error } = useGetDepartmentQuery(id ?? skipToken);
  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  if (!department) {
    return null;
  }

  return (
    <DisplayContainer
      heading={department.name}
      editHref={`/departments/${id}/edit`}
      editLabel={t("departments.editDepartment", { ns: "legacy" })}
      editingDisabled={!can("editDepartment")}
    >
      <EntityDetailsGrid
        blocks={[
          [
            {
              label: t("departments.fields.id"),
              value: `${department.id}`,
              xs: 4,
              style: "body1",
            },
            {
              label: t("departments.fields.public"),
              view: <BooleanCheckboxDisplay value={department.public} />,
              xs: 4,
            },
            {
              label: t("departments.fields.active"),
              view: <BooleanCheckboxDisplay value={department.active} />,
              xs: 4,
            },
          ],
          "divider",
          [
            {
              label: t("departments.fields.relevancesCategory"),
              xs: 12,
              value:
                department?.relevancesCategory &&
                department?.relevancesCategory?.length > 0
                  ? department.relevancesCategory
                      .map(
                        (rel) =>
                          `${
                            rel.category ? buildCategoryName(rel.category) : ""
                          }: ${rel.relevance}`
                      )
                      .join(", ")
                  : `N/A`,
              style: "body1",
            },
          ],
          "divider",
          [
            {
              label: t("departments.fields.relevancesTag"),
              xs: 12,
              value:
                department?.relevancesTag &&
                department?.relevancesTag?.length > 0
                  ? department.relevancesTag
                      .map(
                        (rel) =>
                          `${rel.tag ? rel.tag.name : ""}: ${rel.relevance}`
                      )
                      .join(", ")
                  : "N/A",
              style: "body1",
            },
          ],
        ]}
      />
    </DisplayContainer>
  );
};
