import React, { useCallback } from "react";
import {
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useChangeItemOrderStatusMutation } from "../../../slices/items/api";
import {
  ItemOrderStatus,
  itemOrderStatuses,
} from "../../../slices/items/types";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { ITEM_ORDER_STATUS_COLORS } from "../../../consts";
import {
  canSetStatusManually,
  orderStatusesPaths,
} from "../../../slices/items/utils";

type SelectItemDetailsOrderStatusProps = Omit<
  SelectProps,
  "defaultValue" | "id" | "onChange" | "value"
> & {
  defaultValue: ItemOrderStatus;
  id: number;
  inquiryId: number;
};

export const SelectItemDetailsOrderStatus = ({
  defaultValue,
  id,
  inquiryId,
  ...props
}: SelectItemDetailsOrderStatusProps) => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const [changeOrderStatus] = useChangeItemOrderStatusMutation();

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const newStaus = event.target.value as ItemOrderStatus;

      if (!itemOrderStatuses.includes(newStaus)) {
        feedError(t("itemDetails.errors.wrongStatus"));
      }

      changeOrderStatus({
        id,
        status: newStaus,
        inquiryId,
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("itemDetails.successes.changeItemOrderStatus"));
        })
        .catch((error: { data?: { message?: string } }) => {
          feedError(
            error?.data?.message ??
              t("itemDetails.errors.changeItemOrderStatus")
          );
        });
    },
    [changeOrderStatus, feedError, feedSuccess, id, inquiryId, t]
  );

  return (
    <Select
      id={`select-item-details-inquiry-status-${id}`}
      value={defaultValue}
      onChange={changeHandler}
      autoWidth={false}
      {...props}
    >
      {itemOrderStatuses.map((status) => (
        <MenuItem
          key={status}
          value={status}
          disabled={
            !canSetStatusManually(orderStatusesPaths, defaultValue, status)
          }
        >
          <Chip
            label={<b>{t(`items.statuses.order.${status}`)}</b>}
            size="small"
            variant={"filled"}
            sx={{
              backgroundColor: ITEM_ORDER_STATUS_COLORS[status],
              color: "white",
              "&:hover": { cursor: "pointer" },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
