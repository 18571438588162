export const parseNumericParam = (param?: string | null, float?: boolean) => {
  if (!param) {
    return undefined;
  }

  const parsedParam = float ? parseFloat(param) : parseInt(param);

  if (Number.isNaN(parsedParam)) {
    return undefined;
  }

  return parsedParam;
};
