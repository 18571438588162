import React, { useEffect } from "react";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { PaperBase } from "../../../components/layout/PaperBase";
import { Filters } from "./Filters";
import { useTableTools } from "./useTableTools";
import { useColumns } from "./useColumns";
import { DataGrid } from "@mui/x-data-grid";
import { useGetSearchRecordsQuery } from "../../../slices/searches/api";

export const Content = () => {
  const {
    from,
    fromChangeHandler,
    to,
    toChangeHandler,
    schools,
    setSchools,
    users,
    setUsers,
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    sortModel,
    sortModelChangeHandler,
    resetHandler,
  } = useTableTools();

  useEffect(() => {
    console.log(sortModel);
  }, [sortModel]);

  const columns = useColumns();

  const { data, isLoading, isFetching } = useGetSearchRecordsQuery({
    page,
    pageSize,
    from: from?.toDateString() ?? null,
    to: to?.toDateString() ?? null,
    users,
    schools,
    ...(sortModel[0] ?? {}),
  });

  return (
    <>
      <ContainerBase>
        <PaperBase
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Filters
            schools={{ onChange: setSchools, value: schools }}
            users={{ onChange: setUsers, value: users }}
            from={{ onChange: fromChangeHandler, value: from }}
            to={{ onChange: toChangeHandler, value: to }}
            onReset={resetHandler}
          />
          <DataGrid
            loading={isLoading || isFetching}
            pageSize={pageSize}
            onPageSizeChange={pageSizeChangeHandler}
            pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            sortModel={sortModel}
            onSortModelChange={sortModelChangeHandler}
            page={page}
            onPageChange={pageChangeHandler}
            columns={columns}
            rows={data?.data ?? []}
            rowCount={data?.count}
            paginationMode="server"
            sortingMode="server"
          />
        </PaperBase>
      </ContainerBase>
    </>
  );
};
