import React, { useEffect } from "react";
import { useAdminSchoolInquiryTools } from "../hooks";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { Box } from "@mui/material";
import { SelectSchool } from "./SelectSchool";
import { SelectSchoolUser } from "./SelectSchoolUser";
import { useGetSchoolsQuery } from "../../../slices/schools/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetSchoolUsersQuery } from "../../../slices/users/api";

export const AdminPanel = () => {
  const data = useAdminSchoolInquiryTools();

  const { data: schoolsData = [], isLoading: isSchoolsLoading } =
    useGetSchoolsQuery();

  const {
    data: usersData = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetSchoolUsersQuery(data?.schoolId ?? skipToken);

  useEffect(() => {
    if (!data?.schoolId && schoolsData.length > 0) {
      data?.setSchoolId(schoolsData[0].id);
    }
  }, [data, schoolsData]);

  useEffect(() => {
    if (!data?.userId && usersData.length > 0 && !isUsersFetching) {
      data?.setUserId(usersData[0].id);
      data?.setUserRole(usersData[0].role);
    }
  }, [data, isUsersFetching, usersData]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ContainerBase sx={{ paddingInline: "15px" }} maxWidth="xl">
        <Box sx={{ display: "flex", width: "100%", gap: "20px" }}>
          <SelectSchool
            value={data.schoolId}
            data={schoolsData}
            isLoading={isSchoolsLoading}
            onChange={(e) => {
              if (Number(e.target.value)) {
                data.setSchoolId(Number(e.target.value));
                data.setUserId(undefined);
                data.setUserRole(undefined);
              }
            }}
            setSchoolId={data.setSchoolId}
            fullWidth
            placeholder="Select School"
          />
          <SelectSchoolUser
            data={usersData}
            isLoading={isUsersLoading || isUsersFetching}
            value={data.userId}
            setUserId={data.setUserId}
            setUserRole={data.setUserRole}
            fullWidth
            placeholder="Select User"
          />
        </Box>
      </ContainerBase>
    </>
  );
};
