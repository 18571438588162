import { Category, CategoryFlattened } from "../slices/categories/types";
import { AutocompleteCategory } from "../components/prefilledSelectors/AutocompleteCategory";

export type AutocompleteCategory = Omit<Category, "parentCategory"> & {
  parentCategory: AutocompleteCategory | null;
};

export const buildCategoriesTree = (
  currentCategory: CategoryFlattened | null | undefined,
  categories: CategoryFlattened[]
): AutocompleteCategory | null => {
  if (!currentCategory) {
    return null;
  }

  const { parentCategoryId, ...category } = currentCategory;

  return {
    ...category,
    parentCategory: buildCategoriesTree(
      categories.find(({ id }) => id === parentCategoryId),
      categories
    ),
  };
};

export const buildCategoryName = ({
  name,
  parentCategory,
}: AutocompleteCategory): string => {
  const prefix = parentCategory
    ? `${buildCategoryName(parentCategory)} / `
    : "";

  return `${prefix}${name}`;
};
