import React, { useCallback, useMemo, useState } from "react";
import { PaperPageError } from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";
import { useTranslation } from "react-i18next";
import { Toolbar } from "../../../components/toolbar/Toolbar";
import { Link } from "react-router-dom";
import { useGetProductsQuery } from "../../../slices/products/api";
import { List } from "./List";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useLocalStorage } from "usehooks-ts";
import { SelectSuppliers } from "../../../components/prefilledSelectors/SelectSuppliers";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { PaperBase } from "../../../components/layout/PaperBase";
import { SpinnerBase } from "../../../components/layout/SpinnerBase";
import { downloadProductsFromServer } from "../../../utils/downloadFileFromServer";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { FancyPagination } from "../../../components/FancyPagination";
import { useContentTools } from "../../../utils/useContentTools";
import { Add, FileDownloadOutlined } from "@mui/icons-material";
import { Circular } from "../../../components/spinners/Circular";
import { DEFAULT_AVAILABLE_FILTER } from "../../../consts";
import { AvailableFilter } from "./AvailableFilter";

const localStorageLabel = "products";
const itemsPerRow = 4;

export const Content = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const [supplierIds, setSupplierIds] = useLocalStorage<string[]>(
    `${localStorageLabel}.supplierIds`,
    []
  );

  const [availabilitiesFilter, setAvailabilitiesFilter] = useLocalStorage<
    string[]
  >(
    `${localStorageLabel}.filter.availabilitiesFilter`,
    DEFAULT_AVAILABLE_FILTER
  );
  const onAvailabilitiesFilterChange = useCallback(
    (newAvailabilitiesFilter: string[]) =>
      setAvailabilitiesFilter(newAvailabilitiesFilter),
    [setAvailabilitiesFilter]
  );

  const resets = useCallback(() => {
    setSupplierIds([]);
    onAvailabilitiesFilterChange(DEFAULT_AVAILABLE_FILTER);
  }, [setSupplierIds, onAvailabilitiesFilterChange]);

  const {
    page,
    setPage,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    search,
    debouncedSearch,
    searchChangeHandler,
    resetHandler,
  } = useContentTools(localStorageLabel, {
    defaultPageSize: itemsPerRow * 2,
    resets,
  });

  const supplierIdsChangeHandler = useCallback(
    (supplierIds: string[]) => {
      setSupplierIds(supplierIds);
      setPage(1);
    },
    [setPage, setSupplierIds]
  );

  const [exportInProcess, setExportInProcess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const exportHandler = useCallback(async () => {
    setShowConfirmation(true);
  }, [setShowConfirmation]);

  const onConfirmClick = useCallback(async () => {
    setShowConfirmation(false);
    setExportInProcess(true);
    await downloadProductsFromServer(
      debouncedSearch,
      supplierIds,
      availabilitiesFilter
    );
    setExportInProcess(false);
  }, [
    setExportInProcess,
    setShowConfirmation,
    debouncedSearch,
    supplierIds,
    availabilitiesFilter,
  ]);

  const filters = useMemo(
    () => (
      <>
        <AvailableFilter
          onAvailabilitiesFilterChange={onAvailabilitiesFilterChange}
          availabilitiesFilter={availabilitiesFilter}
        />
        <SelectSuppliers
          onChange={supplierIdsChangeHandler}
          value={supplierIds}
        />
      </>
    ),
    [
      supplierIds,
      supplierIdsChangeHandler,
      availabilitiesFilter,
      onAvailabilitiesFilterChange,
    ]
  );

  const { data, isLoading, isFetching, refetch, error } = useGetProductsQuery({
    page,
    pageSize,
    search: debouncedSearch,
    supplierIds,
    activities: availabilitiesFilter,
  });

  const actionButtons = useMemo(() => {
    return (
      <Stack direction="row">
        <Tooltip title={t("products.add") as string}>
          <IconButton
            component={Link}
            to={`products/add`}
            disabled={!can("addProduct")}
          >
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("products.export") as string}>
          <IconButton
            disabled={!can("exportProducts") && !exportInProcess}
            onClick={exportHandler}
          >
            {!exportInProcess ? (
              <FileDownloadOutlined />
            ) : (
              <Circular size={20} />
            )}
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }, [t, can, exportInProcess, exportHandler]);

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("products.errors.refetch")}
      />
    );
  }

  return (
    <>
      <ContainerBase>
        <PaperBase
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Toolbar
            searchValue={search}
            onSearchChange={searchChangeHandler}
            onReset={resetHandler}
            addButtonDisabled={!can("addProduct")}
            filters={filters}
            buttons={actionButtons}
            wideSearch
            contentLoading={isFetching && !isLoading}
          />
          {isLoading && <SpinnerBase />}
          {!isLoading && data && (
            <>
              <List data={data.data} itemsPerRow={itemsPerRow} />
              <Box sx={{ marginTop: "auto", paddingTop: 2 }}>
                <FancyPagination
                  count={data.count}
                  page={page}
                  onPageChange={pageChangeHandler}
                  pageSizeOptions={[
                    itemsPerRow * 2,
                    itemsPerRow * 4,
                    itemsPerRow * 8,
                    itemsPerRow * 16,
                    itemsPerRow * 32,
                  ]}
                  pageSize={pageSize}
                  onPageSizeChange={pageSizeChangeHandler}
                  stackSx={{ justifyContent: "flex-end" }}
                  countLabel={t("products.countLabel", { count: data.count })}
                />
              </Box>
            </>
          )}
          <ConfirmationDialog
            open={showConfirmation}
            title={t("products.actions.export.confirmationTitle")}
            description={t("products.actions.export.confirmationDescription")}
            handleCancel={() => setShowConfirmation(false)}
            handleProceed={onConfirmClick}
          />
        </PaperBase>
      </ContainerBase>
    </>
  );
};
