import { AssignmentSchoolStatus, RequestedItemSchoolStatus } from "../../types";
import {
  Assignment,
  DepartmentInquiry,
  RequestedItem,
  User,
} from "../../entities";
import { Item, ItemSchoolStatus } from "../items/types";

/**
 * This code duplicates some backend logic.
 * Make sure to check both sides when changing something.
 * Frontend counterpart is a const in src/services/schoolInquiries.service.ts
 */
export const requestedItemSchoolStatusesPaths: Record<
  RequestedItemSchoolStatus,
  (context: {
    item: Pick<RequestedItem, "schoolStatus">;
    assignment: Pick<Assignment, "schoolStatus"> & {
      user: Pick<User, "id">;
    };
    departmentInquiry: Pick<DepartmentInquiry, "schoolStatus">;
    self: Pick<User, "id" | "role">;
  }) => boolean
> = {
  new: ({ item, assignment, self }) => {
    if (item.schoolStatus !== "cancelled") {
      return false;
    }

    if (self.role === "headOfDepartment" && assignment.user.id !== self.id) {
      return false;
    }

    if (self.role === "headOfDepartment" && assignment.user.id === self.id) {
      return (
        assignment.schoolStatus === "new" ||
        assignment.schoolStatus === "inProgress" ||
        assignment.schoolStatus === "readyForSending" ||
        assignment.schoolStatus === "needReviewByTeacher"
      );
    }

    if (self.role === "teacher") {
      return (
        assignment.schoolStatus === "new" ||
        assignment.schoolStatus === "inProgress" ||
        assignment.schoolStatus === "readyForSending" ||
        assignment.schoolStatus === "needReviewByTeacher"
      );
    }

    return false;
  },
  approvedByTeacher: ({ item, assignment, self }) => {
    if (self.role === "headOfDepartment") {
      return false;
    }

    if (self.role === "teacher" && item.schoolStatus === "new") {
      return (
        assignment.schoolStatus === "new" ||
        assignment.schoolStatus === "inProgress" ||
        assignment.schoolStatus === "readyForSending" ||
        assignment.schoolStatus === "needReviewByTeacher"
      );
    }

    if (
      self.role === "teacher" &&
      item.schoolStatus === "needReviewByTeacher"
    ) {
      return assignment.schoolStatus === "needReviewByTeacher";
    }

    return false;
  },
  needReviewByTeacher: ({ item, assignment, departmentInquiry, self }) => {
    if (self.role !== "headOfDepartment" || assignment.user.id === self.id) {
      return false;
    }

    if (item.schoolStatus === "approvedByTeacher") {
      return assignment.schoolStatus === "approvedByTeacher";
    }

    if (item.schoolStatus === "approvedByHead") {
      return (
        (assignment.schoolStatus === "approvedByHead" &&
          (departmentInquiry.schoolStatus === "needReviewByHead" ||
            departmentInquiry.schoolStatus === "pendingApproval")) ||
        assignment.schoolStatus === "approvedByTeacher"
      );
    }

    return false;
  },
  approvedByHead: ({ item, assignment, self }) => {
    if (self.role !== "headOfDepartment") {
      return false;
    }

    if (assignment.user.id === self.id) {
      return (
        item.schoolStatus === "new" &&
        (assignment.schoolStatus === "new" ||
          assignment.schoolStatus === "inProgress" ||
          assignment.schoolStatus === "readyForSending" ||
          assignment.schoolStatus === "needReviewByTeacher")
      );
    }

    if (assignment.user.id !== self.id) {
      return (
        item.schoolStatus === "approvedByTeacher" &&
        assignment.schoolStatus === "approvedByTeacher"
      );
    }

    return false;
  },
  approvedByPrincipal: () => {
    // This status is only set with linked Item's status
    return false;
  },
  cancelled: () => {
    // This status is only set with linked Item's status
    return false;
  },
};

export const assignmentSchoolStatusesPaths: Record<
  AssignmentSchoolStatus,
  (context: {
    assignment: Pick<Assignment, "schoolStatus" | "user">;
    self: Pick<User, "id" | "role">;
  }) => boolean | "help"
> = {
  new: () => {
    return false;
  },
  /**
   * Automatic. Calculated each time assignment's list is changed.
   *
   * No need to calculate this on front-end.
   */
  inProgress: () => {
    return false;
  },
  /**
   * Automatic. Calculated each time assignment's list is changed.
   *
   * No need to calculate this on front-end.
   */
  readyForSending: () => {
    return false;
  },
  /**
   * Manual.
   */
  approvedByTeacher: ({ self, assignment }) => {
    if (self.role !== "teacher") {
      return false;
    }

    if (
      assignment.schoolStatus === "new" ||
      assignment.schoolStatus === "inProgress" ||
      assignment.schoolStatus === "needReviewByTeacher"
    ) {
      return "help";
    }

    return assignment.schoolStatus === "readyForSending";
  },
  /**
   * Manual.
   */
  needReviewByTeacher: ({ self, assignment }) => {
    if (self.role !== "headOfDepartment") {
      return false;
    }

    return (
      assignment.schoolStatus === "approvedByTeacher" ||
      assignment.schoolStatus === "approvedByHead"
    );
  },
  /**
   * Semi-automatic. Calculated when the department's status is being changed.
   */
  approvedByHead: ({ self, assignment }) => {
    if (self.role !== "headOfDepartment") {
      return false;
    }

    if (
      self.id === assignment.user.id &&
      assignment.schoolStatus !== "readyForSending"
    ) {
      return false;
    }

    if (
      self.id !== assignment.user.id &&
      assignment.schoolStatus !== "approvedByTeacher"
    ) {
      return false;
    }

    return true;
  },
  /**
   * Automatic. Calculated when the department's status is being changed.
   */
  sentToPrincipal: () => {
    return false;
  },
  /**
   * Automatic. Calculated when the department's status is being changed.
   */
  approvedByPrincipal: () => {
    return false;
  },
  /**
   * Automatic. Calculated when the department's status is being changed.
   */
  sentToAstra: () => {
    return false;
  },
  /**
   * Manual.
   */
  cancelled: ({ self, assignment }) => {
    if (self.role !== "headOfDepartment") {
      return false;
    }

    return (
      assignment.schoolStatus === "new" ||
      assignment.schoolStatus === "inProgress" ||
      assignment.schoolStatus === "readyForSending" ||
      assignment.schoolStatus === "approvedByTeacher" ||
      assignment.schoolStatus === "approvedByHead"
    );
  },
};

/**
 * This code duplicates some backend logic.
 * Make sure to check both sides when changing something.
 * Frontend counterpart is a const in src/services/schoolInquiries.service.ts
 */
export const itemSchoolStatusesPaths: Record<
  ItemSchoolStatus,
  (context: {
    item: Pick<Item, "schoolStatus">;
    departmentInquiry: Pick<DepartmentInquiry, "schoolStatus" | "assignments">;
    self: Pick<User, "id" | "role">;
  }) => boolean
> = {
  new: () => {
    return false;
  },
  approvedByHead: ({ departmentInquiry, self, item }) => {
    if (self.role === "principal") {
      return false;
    }

    if (self.role === "headOfDepartment") {
      if (
        item.schoolStatus === "needReviewByHead" &&
        departmentInquiry.schoolStatus !== "pendingApproval" &&
        departmentInquiry.schoolStatus !== "needReviewByHead"
      ) {
        return false;
      }

      if (
        item.schoolStatus !== "new" &&
        item.schoolStatus !== "needReviewByHead"
      ) {
        return false;
      }

      return !departmentInquiry.assignments.some(
        ({ schoolStatus }) =>
          schoolStatus !== "approvedByHead" && schoolStatus !== "cancelled"
      );
    }

    return false;
  },
  needReviewByHead: ({ self, item, departmentInquiry }) => {
    if (self.role !== "principal") {
      return false;
    }

    return (
      (item.schoolStatus === "approvedByHead" ||
        item.schoolStatus === "approvedByPrincipal") &&
      departmentInquiry.schoolStatus === "approvedByHead"
    );
  },
  approvedByPrincipal: ({ self, item, departmentInquiry }) => {
    return (
      self.role === "principal" &&
      item.schoolStatus === "approvedByHead" &&
      departmentInquiry.schoolStatus === "approvedByHead"
    );
  },
  cancelled: ({ self, item, departmentInquiry }) => {
    if (self.role !== "headOfDepartment") {
      return false;
    }

    if (item.schoolStatus === "new") {
      return true;
    }

    return (
      item.schoolStatus === "needReviewByHead" &&
      departmentInquiry.schoolStatus === "needReviewByHead"
    );
  },
};
