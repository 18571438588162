import React from "react";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { InquiryTitle } from "./InquiryTitle";
import { Box } from "@mui/material";
import { Items } from "./Items/Items";
import { useTranslation } from "react-i18next";
import { Back } from "../../../components/layout/Back";
import { colors } from "../../../theme/astra/colors";
import { Controls } from "./Controls/Controls";
import { CurrentList } from "./CurrentList/CurrentList";
import { Details } from "./Details/Details";

export const Content = () => {
  const { t } = useTranslation("pages");

  return (
    <ContainerBase
      sx={{
        maxWidth: "100%",
        paddingTop: "30px",
      }}
      maxWidth="xl"
    >
      <Back to="/schoolinquiries" sx={{ marginLeft: "-8px" }}>
        {t("schoolInquiryDetails.backToInquiries")}
      </Back>
      <InquiryTitle />
      <Box
        sx={{
          width: "1px",
          height: "31px",
          backgroundColor: colors.dustBlue5,
          marginTop: "5px",
          marginBottom: "8px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <CurrentList />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            marginBottom: "3px",
          }}
        >
          <Details />
          <Controls />
        </Box>
      </Box>
      <Items />
    </ContainerBase>
  );
};
