import React, { memo, useCallback } from "react";
import {
  MenuItem,
  Pagination,
  Stack,
  StackProps,
  TextField,
  Typography,
} from "@mui/material";

export type FancyPaginationProps = {
  count: number;
  page: number;
  onPageChange: (page: number) => void;
  pageSizeOptions: number[];
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  stackSx?: StackProps["sx"];
  countLabel?: string;
};

export const FancyPagination = memo(
  ({
    count,
    page,
    onPageChange,
    pageSizeOptions,
    pageSize,
    onPageSizeChange,
    stackSx,
    countLabel,
  }: FancyPaginationProps) => {
    const pagesCount = Math.ceil(count / pageSize);

    const pageChangeHandler = useCallback(
      (event, page: number) => {
        onPageChange(page);
      },
      [onPageChange]
    );

    const pageSizeChangeHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onPageSizeChange(parseInt(event.target.value));
      },
      [onPageSizeChange]
    );

    return (
      <Stack
        spacing={2}
        direction="row"
        sx={{ alignItems: "center", ...stackSx }}
      >
        {countLabel && (
          <Typography variant="overline" sx={{ marginBottom: "-3px" }}>
            {count} {countLabel}
          </Typography>
        )}
        <Pagination
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={pageChangeHandler}
          count={pagesCount}
          showFirstButton={pagesCount > 10}
          showLastButton={pagesCount > 10}
        />
        <TextField
          sx={{
            ".MuiOutlinedInput-input": {
              paddingTop: "5px",
              paddingBottom: "4px",
              fontSize: "14px",
            },
          }}
          select
          value={pageSize}
          onChange={pageSizeChangeHandler}
        >
          {pageSizeOptions.map((pageSizeOption) => (
            <MenuItem key={pageSizeOption} value={pageSizeOption}>
              {pageSizeOption}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }
);
