import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useYup } from "../../../utils/validation";
import { assignmentSchemaCreator } from "./AssignmentForm/schema";
import { useForm } from "react-hook-form";
import { EditAssignmentForm } from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { AssignmentForm } from "./AssignmentForm/AssignmentForm";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import {
  useEditAssignmentMutation,
  useGetAssignmentQuery,
} from "../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { displayFullName } from "../../../utils/displayFullName";
import { NewDesignForm } from "../../../components/wideDrawer/NewDesignForm";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";

export const EditAssignment = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();

  const {
    closeDrawer,
    inquiryId,
    departmentInquiryId,
    assignmentId,
    inquiryResult: { data: inquiry, isLoading: isInquiryLoading },
  } = useSchoolInquiryItemsTools();

  const { isFetching: isAssignmentLoading, data: assignment } =
    useGetAssignmentQuery(
      departmentInquiryId && assignmentId
        ? {
            inquiryId,
            departmentInquiryId,
            assignmentId,
          }
        : skipToken
    );

  const schema = useYup(assignmentSchemaCreator);
  const { handleSubmit, control, reset } = useForm<EditAssignmentForm>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiryDetails.errors.editAssignment")
  );

  const [editAssignment, { isLoading: isEditing }] =
    useEditAssignmentMutation();

  const onSubmit = useCallback(
    (form: EditAssignmentForm) => {
      if (departmentInquiryId && assignmentId) {
        editAssignment({
          inquiryId,
          departmentInquiryId,
          assignmentId,
          ...form,
        })
          .unwrap()
          .then((res) => {
            feedSuccess(res.message);
            closeDrawer();
            reset();
          })
          .catch(catchFormError);
      }
    },
    [
      assignmentId,
      catchFormError,
      closeDrawer,
      departmentInquiryId,
      editAssignment,
      feedSuccess,
      inquiryId,
      reset,
    ]
  );

  useEffect(() => {
    if (assignment) {
      reset({
        budget: (assignment?.budget && assignment?.budget / 100) || undefined,
        budgetCurrencyId: assignment.budgetCurrency?.id,
      });
    }
  }, [assignment, reset]);

  if (isInquiryLoading || isAssignmentLoading) {
    return <Spinner />;
  }

  if (!assignment || !inquiry) {
    return null;
  }

  return (
    <NewDesignForm
      heading={`${t("schoolInquiryDetails.editAssignment")} ${displayFullName({
        firstName: assignment.user.firstName,
        lastName: assignment.user.lastName,
      })}`}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditing}
      formError={formError}
      submitText={t("schoolInquiryDetails.submitEdit")}
    >
      <AssignmentForm
        control={control}
        fieldsErrors={fieldsErrors}
        schoolId={inquiry.school.id}
        editing
        isBudget={!!inquiry.budget || false}
      />
    </NewDesignForm>
  );
};
