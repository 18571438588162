import React, { useCallback, useState } from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";

export type ClickableWithConfirmationProps = {
  confirmationTitle: string;
  confirmationDescription: string;
  component: React.ElementType;
  onClick: () => void;
  disabled?: boolean;
};

/**
 * Replace with ConfirmationButton when possible. Avoid using this in new code.
 * @deprecated
 */
export const ClickableWithConfirmation = ({
  confirmationTitle,
  confirmationDescription,
  children,
  component,
  onClick,
  disabled,
  ...props
}: ClickableWithConfirmationProps & React.PropsWithChildren<unknown>) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleClick = useCallback(() => setConfirmationOpen(true), []);
  const handleCancel = useCallback(() => setConfirmationOpen(false), []);
  const handleProceed = useCallback(() => {
    setConfirmationOpen(false);
    onClick();
  }, [onClick]);

  return (
    <>
      <ConfirmationDialog
        open={confirmationOpen}
        title={confirmationTitle}
        description={confirmationDescription}
        handleCancel={handleCancel}
        handleProceed={handleProceed}
      />
      {React.createElement(
        component,
        { ...props, onClick: handleClick, disabled },
        children
      )}
    </>
  );
};
